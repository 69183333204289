// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { fetchBrands, fetchTags, ajaxPerso, handelize } from '../../../fnc'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const refConst = require("../../../constants.js").v

const styleList = {
	container: (provided) => ({
		...provided,
		minWidth: "250px"
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}


function CreateNew(props){

	console.info('props', props);
	console.info('props.getUser', props.getUser());
	

	var keyIdListMeta = "aPlusListIds"
	/**
	 * cssPropStyleImg properties will be converted on camerCase format for our admin page view, but sent as seen here for our shopify template.
	 */
	var cssPropStyleImg = {
		"border-radius": "300px"
	}

	const [nbrGrpInline, setNbrGrpInline] = useState(2);
	const [fieldModifierOn, setFieldModifierOn] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [description, setDescription] = useState(null);
	const [brands, setBrands] = useState([]);
	const [brandSelected, setBrandSelected] = useState(null);
	const [types, setTypes] = useState([]);
	const [typesSelected, setTypesSelected] = useState(null);
	const [groupInline, setGroupInline] = useState([]); // Contain the object that's use to construc the html.
	const [tagList, setTagList] = useState([]);
	const [tagSelected, setTagSelected] = useState(null);
	const [tagBase, setTagBase] = useState(true);
	// const [splitters, setSplitters] = useState({tag: 'a+ta|', type: '|a+ty|'});
	const [submited, setSubmited] = useState(false);
	const [isInViewMode, setIsInViewMode] = useState(false);

	const [idAplusOpen, setIdAplusOpen] = useState(null)
	const [aplusObj, setAplusObj] = useState(null)

	const inpuTitle = useRef(null)
	const inputUrl = useRef(null)
	const inputImg = useRef(null)

	const refInputImgCdn = useRef(null);


	useEffect(() => {
		
		setIdAplusOpen(props.parent.match.params.id)

		if(props.parent.match.params.id && props.aPlusList.length > 0){
			console.info('props.parent.match.params.idVersion', props.parent.match.params.idVersion);
			console.info('props.history', props.history);
			
			let objJson = null,
				repIsActive = false;

			let objAplus = props.aPlusList.find(o => {
				if(o.id == props.parent.match.params.id)
					return o;
				return false;
			})

			console.info('objJson', objJson);
			
			if(objAplus){

				objJson = objAplus.value_json
				repIsActive = objAplus.is_active

				/**
				 * Load the history as primary data if a idVersion exist.
				 */
				if(props.parent.match.params.idVersion){
					setIsInViewMode(true)
					if(props.history){
						let objHistory = props.history.find(o => {
							if(o.id == props.parent.match.params.idVersion)
								return o;
							return false;
						})
						if(objHistory){
							objJson = objHistory.prev_value_json
							repIsActive = objHistory.prev_is_active
						}
					}
				}

				setGroupInline(objJson)
				setDescription(objAplus.desciption);
				setIsActive(repIsActive)
				setTagSelected(objAplus.random_meta_key_generated);
				setBrandSelected(objAplus.vendor);
			}
		}
	}, [props.parent.match.params.id, props.aPlusList, props.parent.match.params.idVersion])

	/* useEffect(() => {
		console.info('propsprops', props);
		console.info('props.aPlusList', props.aPlusList);
		console.info('props.aPlusIdList', props.aPlusIdList);
		console.info('aplusObj', aplusObj);
		console.info('idAplusOpen', idAplusOpen);

		if(props.aPlusList.length > 0 && props.parent.match.params.id){

			let dataAPlus = props.aPlusList.find(o => {
				if(o.id == props.parent.match.params.id)	return o;
				return false;
			})

			console.info('dataAPlus', dataAPlus);
			console.info('props.aPlusIdList.length', props.aPlusIdList.length);

			if(dataAPlus){

				setDescription(dataAPlus.desciption);
				setIsActive(dataAPlus.is_active)
				setGroupInline(dataAPlus.value_json);
				setTagSelected(dataAPlus.random_meta_key_generated);
				setBrandSelected(dataAPlus.vendor);
			}
		}
	}, [props.keyMetaTmp, props.aPlusList, props.aPlusIdList]) */

	useEffect(() => {
		
		if(!props.shopIdSelected)
			return;

		fetchBrands(props.shopIdSelected, {}, () => {
			props.loading(true);
		}, (r) => {
			console.info('Fetched brands:', r);
			props.loading(false);

			if(r.success){
				setBrands(r.data);
			}
		})

		fetchTags(props.shopIdSelected, () => {
			props.loading(true);
		}, (r) => {
			console.info('Fetched tags:', r);
			props.loading(false);

			if(r.success){
				let cpTag = tagList.concat(r.data)
				setTagList(cpTag.slice(0));
			}
		})

		fetchTypes(props.shopIdSelected, (r) => {
			console.info('Fetched Types:', r);
			props.loading(false);

			if(r.success){
				setTypes(r.data);
			}
		})
			
	}, [props.shopIdSelected])

	useEffect(() => {
		if(fieldModifierOn)
			modalViewAlter(fieldModifierOn.subGrp, fieldModifierOn.isImg)
	}, [fieldModifierOn])

	const modalConfirmation = (callback) => {
		props.modal({
			show: true,
			title: "Confirmation - Deletion",
			html: () => {
				return <div>
					{
						<div className="text-center">
							<p className="text-center text-danger font-weight-bold">Are you sure ?</p>
						</div>
					}
				</div>
			}
			,ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					if(callback)	callback()
					close()
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	const submitImgChange = (subGrp) => {

		// Format: subCatIcon_3m__hard-hats.png

		let errors = [];
		if(refInputImgCdn.current.files.length != 1)
			errors.push("Please select one image only")

		console.info("refInputImgrefInputImg", refInputImgCdn.current.files[0]);

		if(errors.length > 0){
			props.info({error: errors})
		}else{
			props.loading(true);

			var formData = new FormData();
			formData.append(`img`, refInputImgCdn.current.files[0], refInputImgCdn.current.files[0].name);
			formData.append("api", "shopify");
			formData.append("trigger", "manual_api_storage_img_replacer");
			formData.append("uid", props.getUser().uid);
			formData.append("shop", props.shopIdSelected);
			formData.append("imgName", refInputImgCdn.current.files[0].name);
			formData.append("retrieveUrl", true);

			ajaxPerso( formData, r => {

				console.info("Result from submitChangeReq:", r);
				props.loading(false);

				if(r.errors){
					props.info({error: JSON.stringify(r.errors)})
				}else if(r.insertResult && r.insertResult.length > 0){
					props.info({success: "Image added."})
					inputImg.current.value = r.insertResult[0].url.substring(0, r.insertResult[0].url.lastIndexOf("?"))
					subGrp.urlImg = inputImg.current.value
				}

			}, null, {
				contentType: false,
				processData: false,
				cache: false,
				async: true,
				// timeout: 60000,
			});
		}
	}

	const modalViewAlter = (subGrp, isImg, reload) => {

		if(inpuTitle.current)
			inpuTitle.current.value = subGrp.title;
		if(inputUrl.current)
			inputUrl.current.value = subGrp.urlLink;
		if(inputImg.current)
			inputImg.current.value = subGrp.urlImg;

		if(!("styleImg" in subGrp)){
			subGrp.styleImg = Object.assign({}, cssPropStyleImg)
		}

		console.info('subGrpsubGrp', subGrp);

		let store = null;
		refConst.stores.some(o => {
			if(o.id == props.shopIdSelected){
				store = o;
				return true;
			}
		})

		console.info("storestorestore", store);
		

		props.modal({
			show: true,
			title: "Settings",
			html: () => {
				return <div>
					<form>
						{
							<div>
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<select value={subGrp.type} onChange={(e) => {
											subGrp.type = e.target.value
											setGroupInline(groupInline.slice(0))
											setFieldModifierOn(Object.assign({}, fieldModifierOn))
										}}>
											{
												["text", "link"].map(type => {
													return <option key={`${type}_settingType`} value={type}>{type[0].toUpperCase()+type.substr(1)}</option>
												})
											}
										</select>
									</div>
									<input type="text" ref={inpuTitle} defaultValue={subGrp.title} className="form-control" placeholder="Title" onChange={(e) => {
										subGrp.title = e.target.value
									}}/>
								</div>
								{
									subGrp.type == "link"? <input type="text" ref={inputUrl} defaultValue={subGrp.urlLink} className="form-control" placeholder="Url" onChange={(e) => {
										subGrp.urlLink = e.target.value
									}}/> : null
								}
								<hr />
								<div className="border rounded p-3 bg-light">
									{
										store && store.website? <div className="pb-3">
											Add a file to <b>{store.website.substring(store.website.lastIndexOf("//")+2)}</b> CDN
										</div> : ""
									}
									<div className="d-flex flex-row">
										<div className="custom-file ml-3">
											<input ref={refInputImgCdn} type="file" className="custom-file-input" id="validatedCustomFile" required/>
											<label className="custom-file-label" htmlFor="validatedCustomFile">{ refInputImgCdn.current && refInputImgCdn.current.files.length === 1? refInputImgCdn.current.files[0].name + " (" + (Math.round(refInputImgCdn.current.files[0].size/1000)) + "Kb)" : "Choose file..." }</label>
										</div>
									</div>
									<div className="mt-3 text-center">
										<button name="Change" className="btn btn-success" disabled={props.loading()} onClick={() => submitImgChange(subGrp)}>Generate Image URL</button>
									</div>
								</div>
								<div className="input-group mt-3 mb-3">
									<div className="input-group-prepend">
										<span className="input-group-text" id="basic-addon1">Url img</span>
									</div>
									<input type="text" ref={inputImg} defaultValue={subGrp.urlImg} className="form-control" placeholder="Url Img" onChange={(e) => {
										subGrp.urlImg = e.target.value
									}}/>
								</div>
								<div className={"custom-control custom-switch mb-3 w-100"}>
									<input type="checkbox" className="custom-control-input" id="customSwitchSpecialPromo" checked={subGrp.styleImg["border-radius"] !== "0"} onChange={(val) => {
										let styleImg = Object.assign({}, subGrp.styleImg)
										styleImg["border-radius"] = $(val.target).is(':checked')? "300px" : "0"
										subGrp.styleImg = styleImg
										setFieldModifierOn(Object.assign({}, fieldModifierOn))
									}}/>
									<label className="custom-control-label noselect pointer" htmlFor="customSwitchSpecialPromo">Border Radius</label>
								</div>
							</div>
						}
					</form>
				</div>
			}
			, exit: (popup, close) => {
				close()
				setFieldModifierOn(null)
			}
			, ok: {
				title: "Done",
				fnc: (popup, close) => {
					setGroupInline(groupInline.slice(0))
					setFieldModifierOn(null)
					close()
				}
			}, return: {
				title: "Close",
				fnc: (popup, close) => {
					setFieldModifierOn(null)
					close()
				}
			}
		})
	}

	const fetchTypes = (storeId, callback) => {
		
		if(!storeId)	throw("fetchTypes Please indicate a store ID");
		props.loading(true)
		
		ajaxPerso({
			"api": "shopify",
			"trigger": "fetchTypes",
			"shop": storeId
		}, callback);
	}

	const submitAplus = (callback) => {
		
		if(!props.shopIdSelected)	return false;

		console.info('groupInline', groupInline);
		
		ajaxPerso( {
			"api": "shopify",
			"trigger": "add_update_aplus",
			"id_marketplace": props.shopIdSelected,
			"id_account": props.getUser().id,
			"random_meta_key_generated": tagSelected? tagSelected : 'a+|0|' + Date.now(),
			"desciption": description,
			"vendor": brandSelected,
			"value_json": JSON.stringify(groupInline),
			"is_active": isActive? 1 : 0
		}, (r) => {
			console.info('Trigger [add_update_aplus]: ', r);
			if(r.success){
				props.loadEverything(() => {
					setSubmited(false)
					props.info({success: "Meta Updated"})
					props.parent.history.push("/AplusV2")
					if(callback)	callback(r.json);
				});
			}else{
				setSubmited(false)
				props.info({error: r.error})
			}
		})
	}

	const deleteMeta = (idMeta, callback) => {
		
		if(!props.shopIdSelected)	return false;

		ajaxPerso( {
			"api": "shopify",
			"trigger": "del_meta",
			"id": idMeta,
			"shop": props.shopIdSelected
		}, (r) => {
			console.info('Fetched deleteMeta['+idMeta+']: ', r);
			if(r.success){
				if(callback) callback(r)
			}else{
				props.info({error: r.error})
			}
		})
	}

	const showGrpChoice = () => {
		let output = [];

		for (let i = 0; i < groupInline.length; i++) {
			output.push(<div className="w-25 border border-info p-3"  key={`${i}_showGrpChoice`}>
				<form onSubmit={() => {
				}}>
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text" id={`${i}_inputGroupFileLines`}>Lines</span>
						</div>
						<input type="number" disabled={isInViewMode} className="form-control" id={`${i}_inputGroupFileLines`} placeholder="Now many line [1-3]" aria-describedby="inputGrpCountInline" min="0" max="3" defaultValue={groupInline[i].childs.length} onChange={(r) => {
							let cp = groupInline.slice(0);
							if(cp[i].childs.length < r.target.value){
								for (let v = cp[i].childs.length; v < parseInt(r.target.value); v++) {
									cp[i].childs.push([])
								}
							}else{
								for (let v = parseInt(r.target.value); v < cp[i].childs.length; v++) {
									cp[i].childs.splice(-1,1)
								}
							}
							setGroupInline(cp)
						}}/>
					</div>
				</form>
				<form onSubmit={() => {
				}}>
					{
						groupInline[i].childs.map((grpInline, posLine) => {
							return <div className="input-group mt-2" key={`${i}_${posLine}showGrpChoice`}>
								<div className="input-group-prepend">
									<label className="input-group-text" htmlFor={`${i}_inputGroupFileSub`}>Sub Grp</label>
								</div>
								<input type="number" disabled={isInViewMode} className="form-control" id={`${i}_inputGroupFileSub`} placeholder="Now many line [1-3]" aria-describedby="inputGrpCountInline" min="1" max="3" defaultValue={grpInline.length} onChange={(r) => {
									let cp = groupInline.slice(0);
									if(grpInline.length < r.target.value){
										for (let v = grpInline.length; v < parseInt(r.target.value); v++) {
											grpInline.push({
												type: "text",
												urlImg: "https://picsum.photos/1200",
												urlLink: null,
												title: null,
												styleImg: Object.assign({}, cssPropStyleImg)
											})
										}
									}else{
										for (let v = parseInt(r.target.value); v < grpInline.length; v++) {
											grpInline.splice(-1,1)
										}
									}
									setGroupInline(cp)
								}}/>
							</div>
						})
					}
				</form>
				<form onSubmit={() => {

				}}>
					<div className="input-group mt-2">
						<div className="input-group-prepend">
							<label className="input-group-text" htmlFor={`${i}_inputGroupFileDesc`}>Description</label>
						</div>
						<input type="text" disabled={isInViewMode} className="form-control" id={`${i}_inputGroupFileDesc`} placeholder="Block Desc" aria-describedby="inputGrpCountInline" defaultValue={groupInline[i].description} onChange={(r) => {
							let cp = groupInline.slice(0);
							if(!r.target.value)	delete groupInline[i].description
							else
								groupInline[i].description = r.target.value
							setGroupInline(cp)
						}}/>
					</div>
				</form>
			</div>)
		}
		return <div className="w-100 d-inline-flex spaceEvenly">{output}</div>;
	}

	/* const getKeyName = () => {
		return !tagBase? `${brandSelected}${splitters[tagBase? 'tag' : 'type']}${typesSelected}` : `${brandSelected}${splitters[tagBase? 'tag' : 'type']}${tagSelected}`
	} */

	const showGrpChoiceView = (readyToSave) => {
		let output = [];

		for (let i = 0; i < groupInline.length; i++) {
			let hasLinesCtn = false
			let newBlock = <div className="cubeInlineCtPl" key={`${i}_showGrpChoiceView`}>
				<div className="ctnAplusLines">
					{
						groupInline[i].childs.map((lineList, posLine) => {
							if(posLine === 0)
								hasLinesCtn = true;
							return 	<div className="lineMicroPL" key={`${i}_${posLine}_groupInline`}>
								{
									lineList? lineList.map((subGrp, posSub) => {

										let styles = {}

										for (const cssKey in subGrp.styleImg) {
											if (Object.hasOwnProperty.call(subGrp.styleImg, cssKey)) {
												var cssValue = subGrp.styleImg[cssKey];
												var cssNewKey = cssKey
												while(cssNewKey.indexOf("-") !== -1){
													// Transform border-radius-left into borderRadiusLeft
													cssNewKey = cssNewKey.replace(/(-.)/, (val) => val.toUpperCase().replace('-', ''))
												}
												styles[cssNewKey] = cssValue
											}
										}
									
										return <div className="microCubePL" key={`${i}_${posLine}_${posSub}_groupInline`}>
											<div className="imgCtnPl centerFlexMulti">
												<img className="imgMicroPL" src={ subGrp.urlImg } style={styles}></img>
												{readyToSave? '' : <button onClick={() => {setFieldModifierOn({subGrp: subGrp, isImg: true})}}><FontAwesomeIcon icon={faCog}/></button>}
											</div>
											<div className="labelMicroPL">
												{ subGrp.type == "link"? <a href={ subGrp.urlLink } target="_blank" rel="noreferrer">{ subGrp.title }</a> : subGrp.title }
												{readyToSave? '' : <button className="ml-2" onClick={() => {setFieldModifierOn({subGrp: subGrp, isImg: false})}}><FontAwesomeIcon icon={faCog}/></button>}
											</div>
										</div>}) : ""
								}
							</div>
						})
					}
				</div>
				{groupInline[i].description? <p className="descriptBlockPL">{groupInline[i].description}</p> : ""}
			</div>

			if(!readyToSave || hasLinesCtn)
				output.push(newBlock)
		}
		return <div className="ctnContentPlus centerFlexMulti">{output}</div>;
	}

	const showKeyCreating = () => {

		let output = <div className="border p-3 ml-5 w-50">
			{/* <div className="custom-control custom-switch mb-3 w-100">
				<input type="checkbox" className="custom-control-input" id="customSwitchTagBase" checked={tagBase} onChange={(e) => { setTagBase(e.target.checked) }}/>
				<label className="custom-control-label noselect pointer" htmlFor="customSwitchTagBase">Tag base</label>
			</div> */}
			<div className="">
				<Select
					styles={styleList}
					isDisabled={isInViewMode}

					placeholder="Select a vendor"
					onChange={(selectedOption) => {
						setBrandSelected(selectedOption.value.trim())
					}}
					options={brands.map((o) => {
						return {"value": o? o.name : '', "label": o? o.name : '-'}
					})}
					value={[brandSelected? {"value": brandSelected, "label": brandSelected} : null]}
					// defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
					// defaultInputValue={oLvl4.obj.value? oLvl4.obj.value : "-"}
					isSearchable={true}
				/>
				<div className="input-group mt-3">
					<div className="input-group-prepend">
						<span className="input-group-text" id="inputGroupFileText">Description</span>
					</div>
					<textarea className="form-control" disabled={isInViewMode} id="inputGroupFileText" placeholder="Optional info about this A+ content, for example type the product name" aria-describedby="inputGroupFileText"  defaultValue={description} onChange={(r) => {
						setDescription(r.target.value.trim())
					}}/>
				</div>
				{/* {
					!tagBase? <Select
						className="ml-3"
						styles={styleList}
						placeholder="Select a type"
						onChange={(selectedOption) => {
							setTypesSelected(selectedOption.value.trim())
						}}
						options={types.map((type) => {
							return {"value": type, "label": type}
						})}
						value={[typesSelected? {"value": typesSelected, "label": typesSelected} : null]}
						// defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
						// defaultInputValue={oLvl4.obj.value? oLvl4.obj.value : "-"}
						isSearchable={true}
					/> : ''

				} */}
				{/* {
					tagBase? <CreatableSelect
						className="ml-3"
						styles={ styleList }
						placeholder="Select or Create a tag"
						onChange={(args, actionMeta) => {
	
							let found = tagList.find(t => {
								if(t == args.value)	return t
								return false;
							})
	
							if(actionMeta.action === "create-option"){
								if(!found)
									tagList.push(args.value)
	
								setTagList(tagList.slice(0))
							}
							
							setTagSelected(args.value)
						}}
						options={
							tagList? tagList.map(t => {
								return {
									value: t,
									label: t
								}
							}) : null
						}
						value={[tagSelected? {"value": tagSelected, "label": tagSelected} : null]}
						isSearchable={true}
					/> : ''
				} */}
			</div>
			{/* <div className="mt-2">
				meta key will be: <span className="badge badge-primary">{getKeyName()}</span>{getKeyName().length > 30? <span className="badge badge-danger ml-3">{`${getKeyName().length}/30`}</span> : ''}
			</div> */}
		</div>
		return output
	}
		
	return (<div className={"className" in props? props.className : ""}>
		<div className="alert alert-primary w-100 text-wrap" role="alert">
			In order to get the right formatting and the correct resizing effect on the website, you will need to make sure that all your images have the same <u>height</u> and <u>width</u>.
		</div>
		<form onSubmit={(e) => {
			e.preventDefault();
			let cp = groupInline.slice(0);
			if(cp.length < nbrGrpInline){
				for (let i = cp.length; i < nbrGrpInline; i++) {
					cp.push({childs: [], description: null})
				}
			}else{
				for (let i = nbrGrpInline; i <= cp.length; i++) {
					cp.splice(-1,1)
				}
			}
			setGroupInline(cp)
		}} className="d-flex flex-row align-items-start mt-4">
			<div className="input-group w-50">
				<div className="input-group-prepend">
					<span className="input-group-text" id="inputGroupFile04">Group inline</span>
				</div>
				<input type="number" disabled={isInViewMode} className="form-control" id="inputGroupFile04" placeholder="Now many inline block" aria-describedby="inputGrpCountInline" min="1" max="4" defaultValue={nbrGrpInline} onChange={(r) => {
					setNbrGrpInline(parseInt(r.target.value))
				}}/>
				<div className="input-group-append">
					<button className={(isInViewMode? "d-none" : "") + " btn btn-outline-secondary"} type="submit" id="inputGrpCountInline">Start</button>
				</div>
				<div className="custom-control custom-switch noselect mb-3 w-100 mt-3">
					<input type="checkbox" disabled={isInViewMode} className="custom-control-input" id="customSwitchFree" checked={isActive? true : false} onChange={(e) => { setIsActive(e.target.checked) }}/>
					<label className="custom-control-label" htmlFor="customSwitchFree">Active</label>
				</div>
			</div>
			{
				showKeyCreating()
			}
		</form>
		<div className={(isInViewMode? "d-none" : "") + " text-center mt-5"}>
			{
				props.keyMetaTmp? <button type="button" className="btn btn-danger btn-lg mr-3" onClick={() => modalConfirmation(deleteMeta)}>Delete</button> : ""
			}
			<button type="button" className="btn btn-success btn-lg ml-3" style={{minWidth: "150px"}} disabled={submited} onClick={() => {
				setSubmited(true)
				submitAplus((json) => {
					/* if(json)
						updateMetaIds(json) */
				})
			}}>Save</button>
		</div>
		<div className="position-relative">
			<div className="position-absolute w-100 mt-5 overflow-auto" style={{left: "0px"}}>
				{
					showGrpChoice()
				}
				{
					showGrpChoiceView()
				}
			</div>
		</div>
	</div>
	);
}

export default CreateNew;