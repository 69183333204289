// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { fetchBrands, fetchTags, ajaxPerso } from '../../../fnc'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const refConst = require("../../../constants.js").v

const styleList = {
	container: (provided) => ({
		...provided,
		minWidth: "250px"
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}


function CreateNew(props){

	var keyIdListMeta = "aPlusListIds"
	/**
	 * cssPropStyleImg properties will be converted on camerCase format for our admin page view, but sent as seen here for our shopify template.
	 */
	var cssPropStyleImg = {
		"border-radius": "300px"
	}

	const [nbrGrpInline, setNbrGrpInline] = useState(2);
	const [fieldModifierOn, setFieldModifierOn] = useState(null);
	const [brands, setBrands] = useState([]);
	const [brandSelected, setBrandSelected] = useState(null);
	const [types, setTypes] = useState([]);
	const [typesSelected, setTypesSelected] = useState(null);
	const [groupInline, setGroupInline] = useState([]); // Contain the object that's use to construc the html.
	const [tagList, setTagList] = useState([]);
	const [tagSelected, setTagSelected] = useState(null);
	const [tagBase, setTagBase] = useState(true);
	const [splitters, setSplitters] = useState({tag: 'a+ta|', type: '|a+ty|'});
	const [submited, setSubmited] = useState(false);

	const inpuTitle = useRef(null)
	const inputUrl = useRef(null)
	const inputImg = useRef(null)

	useEffect(() => {
		console.info('props.aPlusList', props.aPlusList);
		console.info('props.aPlusIdList', props.aPlusIdList);

		if(!props.keyMetaTmp){
			setGroupInline([]);
		}else if(props.aPlusList.length > 0 && props.aPlusIdList.length > 0){

			if(!props.aPlusIdList)	return;

			let linkFound = props.aPlusList.find(o => {
				// console.info(`${o.key} == ${props.keyMetaTmp}`);
				
				if(o.key == props.keyMetaTmp)	return o;
				return false;
			})

			console.info('linkFound', linkFound);
			console.info('props.aPlusIdList.length', props.aPlusIdList.length);

			if(linkFound && props.aPlusIdList.length > 0){

				let idBlockFound = props.aPlusIdList.find(o => {
					// console.info(`${o.keyMeta} ==>> ${props.keyMetaTmp}`);

					if(o.keyMeta == props.keyMetaTmp)	return o;
					return false;
				})

				if(idBlockFound){
		
					setTagBase(idBlockFound.tag? true : false);
					setTypesSelected(idBlockFound.type);
					setBrandSelected(idBlockFound.brand)
					setGroupInline(JSON.parse(linkFound.value));
					if(idBlockFound.tag){
						setTagSelected(idBlockFound.tag.substr(splitters.tag.length));
						tagList.push(idBlockFound.tag.substr(splitters.tag.length))
						setTagList(tagList.slice(0))
					}
				}
			}
		}
	}, [props.keyMetaTmp, props.aPlusList, props.aPlusIdList])

	useEffect(() => {
		
		if(!props.shopIdSelected)
			return;

		fetchBrands(props.shopIdSelected, {}, () => {
			props.loading(true);
		}, (r) => {
			console.info('Fetched brands:', r);
			props.loading(false);

			if(r.success){
				setBrands(r.data);
			}
		})

		fetchTags(props.shopIdSelected, () => {
			props.loading(true);
		}, (r) => {
			console.info('Fetched tags:', r);
			props.loading(false);

			if(r.success){
				let cpTag = tagList.concat(r.data)
				setTagList(cpTag.slice(0));
			}
		})

		fetchTypes(props.shopIdSelected, (r) => {
			console.info('Fetched Types:', r);
			props.loading(false);

			if(r.success){
				setTypes(r.data);
			}
		})
			
	}, [props.shopIdSelected])

	useEffect(() => {
		if(fieldModifierOn)
			modalViewAlter(fieldModifierOn.subGrp, fieldModifierOn.isImg)
	}, [fieldModifierOn])

	const modalConfirmation = (callback) => {
		props.modal({
			show: true,
			title: "Confirmation - Deletion",
			html: () => {
				return <div>
					{
						<div className="text-center">
							<p className="text-center text-danger font-weight-bold">Are you sure ?</p>
						</div>
					}
				</div>
			}
			,ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					if(callback)	callback()
					close()
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	const modalViewAlter = (subGrp, isImg, reload) => {

		if(inpuTitle.current)
			inpuTitle.current.value = subGrp.title;
		if(inputUrl.current)
			inputUrl.current.value = subGrp.urlLink;
		if(inputImg.current)
			inputImg.current.value = subGrp.urlImg;

		if(!("styleImg" in subGrp)){
			subGrp.styleImg = Object.assign({}, cssPropStyleImg)
		}

		console.info('subGrpsubGrp', subGrp);
		

		props.modal({
			show: true,
			title: "Settings",
			html: () => {
				return <div>
					<form>
						{
							<div>
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<select value={subGrp.type} onChange={(e) => {
											subGrp.type = e.target.value
											setGroupInline(groupInline.slice(0))
											setFieldModifierOn(Object.assign({}, fieldModifierOn))
										}}>
											{
												["text", "link"].map(type => {
													return <option key={`${type}_settingType`} value={type}>{type[0].toUpperCase()+type.substr(1)}</option>
												})
											}
										</select>
									</div>
									<input type="text" ref={inpuTitle} defaultValue={subGrp.title} className="form-control" placeholder="Title" onChange={(e) => {
										subGrp.title = e.target.value
									}}/>
								</div>
								{
									subGrp.type == "link"? <input type="text" ref={inputUrl} defaultValue={subGrp.urlLink} className="form-control" placeholder="Url" onChange={(e) => {
										subGrp.urlLink = e.target.value
									}}/> : null
								}
								<hr />
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<span className="input-group-text" id="basic-addon1">Url img</span>
									</div>
									<input type="text" ref={inputImg} defaultValue={subGrp.urlImg} className="form-control" placeholder="Url Img" onChange={(e) => {
										subGrp.urlImg = e.target.value
									}}/>
								</div>
								<div className={"custom-control custom-switch mb-3 w-100"}>
									<input type="checkbox" className="custom-control-input" id="customSwitchSpecialPromo" checked={subGrp.styleImg["border-radius"] !== "0"} onChange={(val) => {
										let styleImg = Object.assign({}, subGrp.styleImg)
										styleImg["border-radius"] = $(val.target).is(':checked')? "300px" : "0"
										subGrp.styleImg = styleImg
										setFieldModifierOn(Object.assign({}, fieldModifierOn))
									}}/>
									<label className="custom-control-label noselect pointer" htmlFor="customSwitchSpecialPromo">Border Radius</label>
								</div>
							</div>
						}
					</form>
				</div>
			}
			, exit: (popup, close) => {
				close()
				setFieldModifierOn(null)
			}
			, ok: {
				title: "Done",
				fnc: (popup, close) => {
					setGroupInline(groupInline.slice(0))
					setFieldModifierOn(null)
					close()
				}
			}, return: {
				title: "Close",
				fnc: (popup, close) => {
					setFieldModifierOn(null)
					close()
				}
			}
		})
	}

	const fetchTypes = (storeId, callback) => {
		
		if(!storeId)	throw("fetchTypes Please indicate a store ID");
		props.loading(true)
		
		ajaxPerso({
			"api": "shopify",
			"trigger": "fetchTypes",
			"shop": storeId
		}, callback);
	}

	const updateMetaIds = (json) => {

		let linkFound = props.aPlusIdList.length > 0? props.aPlusIdList.find(o => {
			if(o.keyMeta == json.key)	return o;
			return false;
		}) : false

		console.info('props.aPlusIdList', props.aPlusIdList);
		console.info('json', json);

		props.aPlusIdList.map(o => {
			delete o.uid_creator
			delete o.uid_updated
		})
		
		if(!linkFound){
			props.aPlusIdList.push({
				type: tagBase? null : typesSelected,
				tag: tagBase? (tagSelected? splitters.tag + tagSelected : null) : null,
				brand: brandSelected,
				keyMeta: json.key.toString(),
				// uid_creator: props.getUser().uid
			})
		}else{
			linkFound.type = tagBase? null : typesSelected
			linkFound.tag = tagBase? (tagSelected? splitters.tag + tagSelected : null) : null
			linkFound.brand = brandSelected
			// linkFound.uid_updated = props.getUser().uid
		}

		createMetaId( props.aPlusIdList, () => {
			props.loadEverything(() => {
				props.info({success: "Meta Updated"})
				props.parent.history.push("/Aplus")
			});
		})
	}

	const createMetaId = (aPlusIdList, callback) => {
		ajaxPerso( {
			"api": "shopify",
			"trigger": "createUpdate_meta",
			"data": JSON.stringify({
				namespace: "aPlus_list",
				key: keyIdListMeta, // Fixed key so that it overwrite
				value: JSON.stringify(aPlusIdList),
				type: "json"
			}),
			"shop": props.shopIdSelected
		}, (r) => {
			console.info('Trigger [createMetaId]: ', r);
			if(r.success){
				if(callback)	callback();
			}else{
				props.info({error: r.errorList})
			}
		})
	}

	const createMetaTmp = (callback) => {
		
		if(!props.shopIdSelected)	return false;

		let linkFound = props.aPlusList.length > 0? props.aPlusList.find(o => {
			if(o.key == props.keyMetaTmp)	return o;
			return false;
		}) : false

		console.info('groupInline', groupInline);
		
		ajaxPerso( {
			"api": "shopify",
			"trigger": "createUpdate_meta",
			"data": JSON.stringify({
				namespace: "aPlus_content",
				// key: !tagBase? `${brandSelected}${splitters[tagBase? 'tag' : 'type']}${typesSelected}` : `${brandSelected}${splitters[tagBase? 'tag' : 'type']}${tagSelected}`,
				key: linkFound? linkFound.key : Date.now(),
				// value: JSON.stringify(ReactDOMServer.renderToString(outputReact)),
				value: JSON.stringify(groupInline),
				// value_type: "string"
				type: "json"
			}),
			"shop": props.shopIdSelected
		}, (r) => {
			console.info('Trigger [createMetaTmp]: ', r);
			if(r.success){
				props.loadEverything(() => {
					setSubmited(false)
					props.info({success: "Meta Updated"})
					props.parent.history.push("/Aplus")
					if(callback)	callback(r.json);
				});
			}else{
				setSubmited(false)
				props.info({error: r.errorList})
			}
		})
	}

	const deleteMetaAndCleaning = () => {

		let linkFound = props.aPlusList.find(o => {
			if(o.key == props.keyMetaTmp)	return o;
			return false;
		})
		
		if(linkFound)
			deleteMeta(linkFound.id, (r) => {

				console.info('Fetched Create or Update Meta: ', r);
				// We control if it's the only tmp, if so we need to delete the aPlus_list meta (Cleaning phase).

				let newList = [];
				props.aPlusIdList.forEach(o => {
					if("keyMeta" in o && o.keyMeta != props.keyMetaTmp)
						newList.push(o)
				});
				
				if(props.aPlusIdList_id && newList.length === 0){
					deleteMeta(props.aPlusIdList_id, (r) => {
						console.info('Fetched Create or Update Meta: ', r);
						console.info('aPlus_list removed.');
					})
				}else{
					createMetaId( newList, () => {
						props.info({success: "Meta Updated"})
					})
				}

				props.loadEverything(() => {
					props.info({success: "Meta deleted"})
					props.parent.history.push("/Aplus")
				});
			})
	}

	const deleteMeta = (idMeta, callback) => {
		
		if(!props.shopIdSelected)	return false;

		ajaxPerso( {
			"api": "shopify",
			"trigger": "del_meta",
			"id": idMeta,
			"shop": props.shopIdSelected
		}, (r) => {
			console.info('Fetched deleteMeta['+idMeta+']: ', r);
			if(r.success){
				if(callback) callback(r)
			}else{
				props.info({error: r.error})
			}
		})
	}

	const resizeImg = () => {
		
		/* setTimeout(() => {
			var sizeForAvg = [];
			$(".imgMicroPL").each((index, node) => {
				sizeForAvg.push($(node).height())
			})
	
			console.info('sizeForAvg', sizeForAvg);
			
			if(sizeForAvg.length > 0){
				// console.info('$size', sizeForAvg.reduce( (v,v2) => v+v2) / sizeForAvg.length);
				var sizeForAvg = sizeForAvg.reduce( (v,v2) => v+v2) / sizeForAvg.length
		
				if(sizeForAvg > 0)
					$(".imgMicroPL").each((index, node) => {
						$(node).css({
							height: sizeForAvg + "px",
							width: sizeForAvg + "px"
						})
					})
			}
		}, 200) */
	}

	const showGrpChoice = () => {
		let output = [];

		for (let i = 0; i < groupInline.length; i++) {
			output.push(<div className="w-25 border border-info p-3"  key={`${i}_showGrpChoice`}>
				<form onSubmit={() => {
				}}>
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text" id={`${i}_inputGroupFileLines`}>Lines</span>
						</div>
						<input type="number" className="form-control" id={`${i}_inputGroupFileLines`} placeholder="Now many line [1-3]" aria-describedby="inputGrpCountInline" min="0" max="3" defaultValue={groupInline[i].childs.length} onChange={(r) => {
							let cp = groupInline.slice(0);
							if(cp[i].childs.length < r.target.value){
								for (let v = cp[i].childs.length; v < parseInt(r.target.value); v++) {
									cp[i].childs.push([])
								}
							}else{
								for (let v = parseInt(r.target.value); v < cp[i].childs.length; v++) {
									cp[i].childs.splice(-1,1)
								}
							}
							setGroupInline(cp)
						}}/>
					</div>
				</form>
				<form onSubmit={() => {
				}}>
					{
						groupInline[i].childs.map((grpInline, posLine) => {
							return <div className="input-group mt-2" key={`${i}_${posLine}showGrpChoice`}>
								<div className="input-group-prepend">
									<label className="input-group-text" htmlFor={`${i}_inputGroupFileSub`}>Sub Grp</label>
								</div>
								<input type="number" className="form-control" id={`${i}_inputGroupFileSub`} placeholder="Now many line [1-3]" aria-describedby="inputGrpCountInline" min="1" max="3" defaultValue={grpInline.length} onChange={(r) => {
									let cp = groupInline.slice(0);
									if(grpInline.length < r.target.value){
										for (let v = grpInline.length; v < parseInt(r.target.value); v++) {
											grpInline.push({
												type: "text",
												urlImg: "https://picsum.photos/1200",
												urlLink: null,
												title: null,
												styleImg: Object.assign({}, cssPropStyleImg)
											})
										}
									}else{
										for (let v = parseInt(r.target.value); v < grpInline.length; v++) {
											grpInline.splice(-1,1)
										}
									}
									setGroupInline(cp)
								}}/>
							</div>
						})
					}
				</form>
				<form onSubmit={() => {

				}}>
					<div className="input-group mt-2">
						<div className="input-group-prepend">
							<label className="input-group-text" htmlFor={`${i}_inputGroupFileDesc`}>Description</label>
						</div>
						<input type="text" className="form-control" id={`${i}_inputGroupFileDesc`} placeholder="Block Desc" aria-describedby="inputGrpCountInline" defaultValue={groupInline[i].description} onChange={(r) => {
							let cp = groupInline.slice(0);
							if(!r.target.value)	delete groupInline[i].description
							else
								groupInline[i].description = r.target.value
							setGroupInline(cp)
						}}/>
					</div>
				</form>
			</div>)
		}
		return <div className="w-100 d-inline-flex spaceEvenly">{output}</div>;
	}

	const getKeyName = () => {
		return !tagBase? `${brandSelected}${splitters[tagBase? 'tag' : 'type']}${typesSelected}` : `${brandSelected}${splitters[tagBase? 'tag' : 'type']}${tagSelected}`
	}

	const showGrpChoiceView = (readyToSave) => {
		let output = [];

		for (let i = 0; i < groupInline.length; i++) {
			let hasLinesCtn = false
			let newBlock = <div className="cubeInlineCtPl" key={`${i}_showGrpChoiceView`}>
				<div className="ctnAplusLines">
					{
						groupInline[i].childs.map((lineList, posLine) => {
							if(posLine === 0)
								hasLinesCtn = true;
							return 	<div className="lineMicroPL" key={`${i}_${posLine}_groupInline`}>
								{
									lineList? lineList.map((subGrp, posSub) => {

										let styles = {}

										for (const cssKey in subGrp.styleImg) {
											if (Object.hasOwnProperty.call(subGrp.styleImg, cssKey)) {
												var cssValue = subGrp.styleImg[cssKey];
												var cssNewKey = cssKey
												while(cssNewKey.indexOf("-") !== -1){
													// Transform border-radius-left into borderRadiusLeft
													cssNewKey = cssNewKey.replace(/(-.)/, (val) => val.toUpperCase().replace('-', ''))
												}
												styles[cssNewKey] = cssValue
											}
										}
									
										return <div className="microCubePL" key={`${i}_${posLine}_${posSub}_groupInline`}>
											<div className="imgCtnPl centerFlexMulti">
												<img className="imgMicroPL" src={ subGrp.urlImg } style={styles}></img>
												{readyToSave? '' : <button onClick={() => {setFieldModifierOn({subGrp: subGrp, isImg: true})}}><FontAwesomeIcon icon={faCog}/></button>}
											</div>
											<div className="labelMicroPL">
												{ subGrp.type == "link"? <a href={ subGrp.urlLink } target="_blank" rel="noreferrer">{ subGrp.title }</a> : subGrp.title }
												{readyToSave? '' : <button className="ml-2" onClick={() => {setFieldModifierOn({subGrp: subGrp, isImg: false})}}><FontAwesomeIcon icon={faCog}/></button>}
											</div>
										</div>}) : ""
								}
							</div>
						})
					}
				</div>
				{groupInline[i].description? <p className="descriptBlockPL">{groupInline[i].description}</p> : ""}
			</div>

			if(!readyToSave || hasLinesCtn)
				output.push(newBlock)
		}
		return <div className="ctnContentPlus centerFlexMulti">{output}</div>;
	}

	const showKeyCreating = () => {

		let output = <div className="d-flex flex-column border p-3 ml-5">
			<div className="custom-control custom-switch mb-3 w-100">
				<input type="checkbox" className="custom-control-input" id="customSwitchTagBase" checked={tagBase} onChange={(e) => { setTagBase(e.target.checked) }}/>
				<label className="custom-control-label noselect pointer" htmlFor="customSwitchTagBase">Tag base</label>
			</div>
			<div className="d-flex flex-row">
				<Select
					styles={styleList}
					placeholder="Select a vendor"
					onChange={(selectedOption) => {
						setBrandSelected(selectedOption.value.trim())
					}}
					options={brands.map((o) => {
						return {"value": o? o.name : '', "label": o? o.name : '-'}
					})}
					value={[brandSelected? {"value": brandSelected, "label": brandSelected} : null]}
					// defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
					// defaultInputValue={oLvl4.obj.value? oLvl4.obj.value : "-"}
					isSearchable={true}
				/>
				{
					!tagBase? <Select
						className="ml-3"
						styles={styleList}
						placeholder="Select a type"
						onChange={(selectedOption) => {
							setTypesSelected(selectedOption.value.trim())
						}}
						options={types.map((type) => {
							return {"value": type, "label": type}
						})}
						value={[typesSelected? {"value": typesSelected, "label": typesSelected} : null]}
						// defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
						// defaultInputValue={oLvl4.obj.value? oLvl4.obj.value : "-"}
						isSearchable={true}
					/> : ''

				}
				{
					tagBase? <CreatableSelect
						/* isClearable */
						className="ml-3"
						styles={ styleList }
						placeholder="Select or Create a tag"
						onChange={(args, actionMeta) => {
	
							let found = tagList.find(t => {
								if(t == args.value)	return t
								return false;
							})
	
							if(actionMeta.action === "create-option"){
								if(!found)
									tagList.push(args.value)
	
								setTagList(tagList.slice(0))
							}
							
							setTagSelected(args.value)
						}}
						options={
							tagList? tagList.map(t => {
								return {
									value: t,
									label: t
								}
							}) : null
						}
						value={[tagSelected? {"value": tagSelected, "label": tagSelected} : null]}
						isSearchable={true}
					/> : ''
				}
			</div>
			{/* <div className="mt-2">
				meta key will be: <span className="badge badge-primary">{getKeyName()}</span>{getKeyName().length > 30? <span className="badge badge-danger ml-3">{`${getKeyName().length}/30`}</span> : ''}
			</div> */}
		</div>
		return <div>{output}</div>;
	}
		
	return (<div className={"className" in props? props.className : ""}>
		<div className="alert alert-primary w-100 text-wrap" role="alert">
			In order to get the right formatting and the correct resizing effect on the website, you will need to make sure that all your images have the same <u>height</u> and <u>width</u>.
		</div>
		<form onSubmit={(e) => {
			e.preventDefault();
			let cp = groupInline.slice(0);
			if(cp.length < nbrGrpInline){
				for (let i = cp.length; i < nbrGrpInline; i++) {
					cp.push({childs: [], description: null})
				}
			}else{
				for (let i = nbrGrpInline; i <= cp.length; i++) {
					cp.splice(-1,1)
				}
			}
			setGroupInline(cp)
		}} className="d-flex flex-row align-items-start mt-4">
			<div className="input-group">
				<div className="input-group-prepend">
					<span className="input-group-text" id="inputGroupFile04">Group inline</span>
				</div>
				<input type="number" className="form-control" id="inputGroupFile04" placeholder="Now many inline block" aria-describedby="inputGrpCountInline" min="1" max="4" defaultValue={nbrGrpInline} onChange={(r) => {
					setNbrGrpInline(parseInt(r.target.value))
				}}/>
				<div className="input-group-append">
					<button className="btn btn-outline-secondary" type="submit" id="inputGrpCountInline">Start</button>
				</div>
			</div>
			{
				showKeyCreating()
			}
		</form>
		<div className="text-center mt-5">
			{
				props.keyMetaTmp? <button type="button" className="btn btn-danger btn-lg mr-3" onClick={() => modalConfirmation(deleteMetaAndCleaning)}>Delete</button> : ""
			}
			<button type="button" className="btn btn-success btn-lg ml-3" style={{minWidth: "150px"}} disabled={submited} onClick={() => {
				setSubmited(true)
				createMetaTmp((json) => {
					if(json)
						updateMetaIds(json)
				})
			}}>Save</button>
		</div>
		<div className="position-relative">
			<div className="position-absolute w-100 mt-5 overflow-auto" style={{left: "0px"}}>
				{
					showGrpChoice()
				}
				{
					showGrpChoiceView()
				}
			</div>
		</div>
	</div>
	);
}

export default CreateNew;