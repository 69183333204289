// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $, { isArray } from 'jquery'; 
import PropTypes from 'prop-types';
const refConst = require("../../../constants.js").v
//var moment = require('moment');

function Filter(props){
	
	const parseFilters = () => {
		var filterListFrPr = [];
		for (const index in props) {
			if (props.hasOwnProperty(index)) {
				filterListFrPr.push(props[index])
			}
		}
		return filterListFrPr
	}

	const [filterList, setFilterList] = useState(parseFilters());

	useEffect(() => {
		setFilterList(parseFilters())
	}, [props])


	const showTags = () => {
		console.info('filterList', filterList);
		
		var listOut = [];
		filterList.forEach(o => {
			/* 
			console.info(o.key, o.get());
			console.info(`TESTTT: ${o.key} - ${o.get()} -> ${o.get() != o.initialValue}`, o.initialValue);
			return false; */
			
			if((o.get() || o.get() === false) && o.get() != o.initialValue){
				
				let listFilters = [];
				if(!Array.isArray(o.get()))
					listFilters.push(o.get())
				else	listFilters = o.get();
				
				// console.info('listFilters', listFilters);

				listFilters.forEach(filter => {
					if(typeof filter === 'object')	filter = filter.label;
					
					listOut.push(<button key={o.key + '_'+ filter + '_btnTag'} type="button" className="btn btn-primary btn-sm ml-3" onClick={() => {
						let cpInit = o.initialValue; // Reset Plain value with the initial value received.

						// if it's an array (multi choice from Select), we delete the right element.
						if(Array.isArray(o.get())){
							o.get().splice(o.get().indexOf(filter), 1)
							cpInit = o.get();
						}

						o.set(cpInit)
					}}>{o.key}: <span className="font-weight-bold">{filter}</span> | x</button>)
				})
			}
		})
		return listOut
	}

	return (
		<div className="d-flex align-items-center mb-4">
			{
				showTags()
			}
		</div>
	);
}

export default Filter;