import React, { Component } from 'react';

class Backup extends Component {
  render() {
    return (
      <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main ctnPage">
          <h1 className="page-header">Backup</h1>
          
      </div>
    );
  }
}

export default Backup;
