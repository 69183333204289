// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import {UnControlled as CodeMirror} from 'react-codemirror2'
import {ajaxPerso} from "../../../fnc.js"
require('codemirror/lib/codemirror.css');
require('codemirror/theme/monokai.css');
require('codemirror/addon/hint/show-hint.css');

require('codemirror/addon/hint/show-hint.js');
require('codemirror/addon/hint/html-hint.js');
require('codemirror/mode/htmlmixed/htmlmixed');
require('codemirror/keymap/sublime');
const refConst = require("../../../constants.js").v

const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}


function TmpFrame(props){
	const params = new URLSearchParams(props.parent.location.search);

	const [tmp, setTmp] = useState({
		"name": "",
		"body": "",
		"is_active": 1
	});

	const [listShop, setListShop] = useState([]);
	const [selectedShop, setSelectedShop] = useState(false);

	useEffect(() => {
		console.info('url', props.parent);
		loadDataFrame()
	}, [])

	/* useEffect(() => {
		fetchBrands({}, (r) => {
			console.log('Stores', r);
			if(r.success)
				setListShop(r.body)
		})
	}, []) */

	/* const fetchBrands = (args, callback) => {
		
		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getBrand"
		}, args), callback, "json").fail(function(jqXHR, textStatus, errorThrown) {
			props.info({error: textStatus})
			console.warn( textStatus );
		});
	} */

	const loadDataFrame = () => {
		let idObjOpen = props.parent.match.params.idObjOpen
		if(idObjOpen){
			props.loading(true)
			let objArgs = {}
			if(idObjOpen){
				objArgs["id"] = idObjOpen
			}
	
			props.loadTemplate(objArgs, (r) => {
				r.is_active = parseInt(r.is_active)
				setTmp(r)
			})
		}
	}

	const modalViewTmp = () => {

		props.modal({
			show: true,
			title: "Watch the template with data",
			html: () => {
				return <div>
					<div dangerouslySetInnerHTML={{__html: tmp.body}}></div>
				</div>
			}
			,return: {
				title: "Close"/* ,
				fnc: (popup) => {
				} */
			}
		})
	}

	const sendRequest = () => {

		if(!tmp.name){
			props.info({error: "Please fill the template name."})
		}else{

			ajaxPerso( {
				"api": "eio",
				"trigger": "setTemplate",
				"data": JSON.stringify(tmp)
			}, (r) => {
				console.info('r', r);
				let success = [],
					errors = []

				if(r.data.ok)
					success.push(["<div>Result: " + tmp.name + " updated</div>"])
				
				if(r.data.error)
					errors.push("<div>Error: " + r.data.error + "</div>")
				
				props.info({success: success, error: errors})
				
			})
		}
	}

	const deleteTmp = () => {

		if(!tmp.name){
			props.info({error: "Please fill the template name."})
		}else{
			console.info('Delete', tmp);
			props.modal({
				show: true,
				title: "Confirmation",
				html: () => {
					return <div>
						Are you sure you want to delete the following template: <span className="font-weight-bold">{tmp.name}</span>
					</div>
				}
				,ok: {
					title: "I'm sure",
					fnc: (popup, close) => {
						
						if(tmp.id){
							ajaxPerso( {
								"api": "eio",
								"trigger": "delTemplate",
								"id": tmp.id
							}, (r) => {
								console.info('Execute delTemplate:', r);
								
								if(r.success){
									close()
									props.parent.history.push("/template?mode=tmplist");
								}
							})
						}else{
							console.info('Please fill the input');
						}
					}
				},
				return: {
					title: "Close"
				}
			})
		}
	}
		
	return (<div>
		<p className="h1 mb-4">Template Manager - Frame</p>
		<div className="d-flex justify-content-between">
			<button type="button" className="btn btn-info bg-info btn-lg mb-4" onClick={ modalViewTmp }>View</button>
			<button type="button" className="btn btn-info bg-danger btn-lg mb-4" onClick={ deleteTmp }>Delete</button>
			<button type="button" className="btn btn-primary btn-lg mb-4" onClick={ () => {
				sendRequest();
			} }>{!params.get("mode")? "Update" : "Save"}</button>
		</div>
		{/* <div className="form-group mb-3">
			<label htmlFor="inputGroupSelect01">Brand associated</label>
			<Select
				styles={styleList}
				onChange={(selectedOption) => setSelectedShop(selectedOption.value)}
				options={listShop.map((obj) => {
					return {value: obj? obj.name : '', label: obj? obj.name + (obj.prefix? ' ('+obj.prefix+')' : '') : '-'}
				})}
				value={[{value: selectedShop? selectedShop : "-", label: selectedShop? selectedShop : "-"}]}
				defaultValue={[{value: selectedShop? selectedShop : "-", label: selectedShop? selectedShop : "-"}]}
				isSearchable={true}
				//isMulti={true}
			/>
		</div> */}
		<div className="input-group mb-3">
			<div className="input-group-prepend">
				<span className="input-group-text" id="basic-addon1">Template name</span>
			</div>
			<input type="text" className="form-control" placeholder="Template name" aria-label="Template name" aria-describedby="basic-addon1" disabled={false} value={tmp.name || ""} onChange={ (e) => {
				let cp = Object.assign({}, tmp)
				cp.name = e.target.value
				setTmp(cp)
			}}></input>
		</div>
		<div className="input-group mb-3 noselect">
			<div className="custom-control custom-switch noselect mb-2">
				<input type="checkbox" className="custom-control-input" id="customSwitch1" checked={tmp.is_active === 1? true : false} onChange={(e) => {
					let cp = Object.assign({}, tmp)
					cp.is_active = e.target.checked? 1 : 0
					setTmp(cp)
					//setIsActive(e.target.checked? 1 : 0)
				}}/>
				<label className="custom-control-label" htmlFor="customSwitch1">Active</label>
			</div>
		</div>
		<CodeMirror
			value={ tmp.body }
			options={{
				mode: 'htmlmixed',
				theme: 'monokai',
				lineNumbers: true,
				extraKeys: {"Ctrl-Space": "autocomplete"},
				keymap: "sublime",
				indentWithTabs: true,
			}}
			onChange={(editor, data, value) => {
				tmp.body = value
			}}
		/>
	</div>
	);
}

export default TmpFrame;