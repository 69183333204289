import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import SheetCapture from './ctn/SheetCapture';
import $ from 'jquery';
import { utils, writeFile } from 'xlsx';
import Select, { components, createFilter } from 'react-select';
import { capitalizeFLetter, ajaxPerso, socketPerso } from '../fnc'
import moment from 'moment';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
const refConst = require("../constants.js").v

const {info, log, warn} = console
const initialState = {
	dataSheet: null,
	resTrigger: null,
	submited: false,
	idTask: null
};


export default function Compare(props){

	const apiProperty = [{
		api: "shopify",
		name: "EIO.com",
		id: refConst.id_store_eio,
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjH_9MuY646871R7lUA", // shopify_eio_write
		]),
		shop: refConst.id_store_eio,
		fnc: () => triggerFnc("eio", "shopify", {shop: refConst.id_store_eio }),
		triggers: [{
			name: "selling when out of stock",
			trigger: "alter_product",
			isVariant: true,
			required: ["sku", "inventory_policy"],
			valuesAllowed: ["continue", "deny"]
		}, {
			name: "origin",
			trigger: "change_origin_tag",
			required: ["sku", "origin"],
			alert: <div className="alert alert-primary text-break text-wrap" role="alert">
				The origin on your excel file can be the country name &quot;Germany&quot; or the country code &quot;DEU&quot; or just the code &quot;DE&quot;, the case doesn&apos;t matter.
				<br/>The Country of Origin in the product dataset v1 and v2 will also be updated.
			</div>
		}/* , {
			name: "Files",
			trigger: "bulkUpdateFile",
			triggerArgs: {
				createFor: [refConst.id_store_eio, refConst.id_store_hausfTools].join(','),
			},
			required: ["sku", "fileUrl"]
		} */]
	}, {
		api: "shopify",
		name: "Haus of Tools",
		id: refConst.id_store_hausfTools,
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
		]),
		shop: refConst.id_store_hausfTools,
		fnc: () => triggerFnc("hausOfTools", "shopify", { shop: refConst.id_store_hausfTools }),
		triggers: [{
			name: "selling when out of stock",
			trigger: "alter_product",
			isVariant: true,
			required: ["sku", "inventory_policy"],
			valuesAllowed: ["continue", "deny"]
		}, {
			name: "origin",
			trigger: "change_origin_tag",
			required: ["sku", "origin"],
			alert: <div className="alert alert-primary text-break text-wrap" role="alert">
				The origin on your excel file can be the country name &quot;Germany&quot; or the country code &quot;DEU&quot;, the case doesn&apos;t matter.
				<br/>The Country of Origin in the product dataset v1 and v2 will also be updated.
			</div>
		}/* , {
			name: "Files",
			trigger: "bulkUpdateFile",
			triggerArgs: {
				createFor: [refConst.id_store_eio, refConst.id_store_hausfTools].join(','),
			},
			required: ["sku", "fileUrl"]
		} */]
	}].filter(o => {
		if(o.hasAccess)	return o
	})

	const [selectedTrigger, setSelectedTrigger] = useState(null);
	const [selectedStore, setSelectedStore] = useState(apiProperty.length > 0? apiProperty[0].name : null);
	const [dataSheet, setDataSheet] = useState(initialState.dataSheet);
	const [resTrigger, setResTrigger] = useState(initialState.resTrigger);
	const [submited, setSubmited] = useState(initialState.submited);
	const [items, setItems] = useState([]);
	const [idTask, setIdTask] = useState(initialState.idTask);

	useEffect(() => {

		socket.on('bulkAlterDataEvent', function(idTask){
			console.info("Id Task", idTask);
			setIdTask(idTask)
		})

	}, [])

	useEffect(() => {
		info('dataSheet', dataSheet);

		if(dataSheet && dataSheet.listObj.length > 0){

			let apiProp = getApiProperty(selectedStore)

			console.info('apiProp', apiProp);
			
			fetchItems({
				api: apiProp.api,
				shop: apiProp.shop
			}, (r) => {
				props.loading(false)
				console.info(`Fetching getItems ${apiProp.name}:`, r);
				if(r.success){
					r.data.forEach(o => {
						/**
						 *  We need to convert our object/array to a single value to avoid any issue when generating the xlsx file
						 */
						o.images = o.images_json? o.images_json[0].node? o.images_json[0].node.originalSrc : o.images_json[0].src : null
						o.origin = o.tags.find(t => {
							if(/^Origin_/.test(t))	return t
						})
						delete o.images_json
					})
					setItems(r.data)
				}
			})
		}

	}, [dataSheet])

	useEffect(() => {
		resetPage()
	}, [selectedStore])

	useEffect(() => {
		props.loading(submited)
	}, [submited])

	useEffect(() => {
		// force file download
		dlDetails();
	}, [resTrigger])
	
	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let dlDetails = () => {
		if(!resTrigger)	return false

		var book  = utils.book_new()
		console.info('api', getApiProperty(selectedStore).api);

		let updatedList = resTrigger.skuUpdated.map(sku => {
			return {
				"sku": sku
			}
		});
		
		switch (getApiProperty(selectedStore).api.toLowerCase()) {
		case "shopify":

			utils.book_append_sheet(book, utils.json_to_sheet(items), "Product EIO DB Before Update");
			utils.book_append_sheet(book, utils.json_to_sheet(updatedList), "Details updated");

			if("notUpdated" in resTrigger && resTrigger.notUpdated.length > 0)
				utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.notUpdated), "Not Updated");
			if("errors" in resTrigger && resTrigger.errors.length > 0)
				utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.errors), "Errors");
			break;
		default:
			break;
		}
		
		if(resTrigger.skuUpdated.length > 0 || resTrigger.notUpdated.length > 0 || resTrigger.errors.length > 0)
			writeFile(book, `Result_${selectedStore}_SHOPIFY_ALTER_UPDATE_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
	}

	const modalConfirmation = (callback) => {
		props.modal({
			show: true,
			title: "Shopify Origin Update",
			html: () => {
				return <div>
					<div>
						<p className="text-center text-danger font-weight-bold">Are you sure you want to start this update ?</p>
					</div>
				</div>
			}
			,ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					if(callback)	callback()
					close()
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	const showDifference = () => {
		if(dataSheet && resTrigger !== null){

			return <div>
				<table className="table table-sm">
					<thead>
						<tr>
							<th scope="col">Result</th>
							<th scope="col">Quantity</th>
						</tr>
					</thead>
					<tbody>
						<tr className="table-success"><td>Updated</td><td>{resTrigger.skuUpdated.length}</td></tr>
						{"notUpdated" in resTrigger? <tr className="table-danger"><td>Errors</td><td>{resTrigger.notUpdated.length}</td></tr> : null}
						{"errors" in resTrigger? <tr className="table-danger"><td>Errors</td><td>{resTrigger.errors.length}</td></tr> : null}
						<tr className="border-top"><td className="align-middle">Details</td><td><button type="button" className="btn btn-primary btn-sm" onClick={() => dlDetails()}>Download</button></td></tr>
					</tbody>
				</table>
			</div>
		}
	}

	const triggerPageFnc = () => {
		if(!dataSheet){
			props.info({error: "Please select a file, a sheet and a column to compare it with your marketplace."})
		}else{
			setSubmited(true)
			getApiProperty(selectedStore).fnc()
		}
	}

	const triggerFnc = (storeName, api, shopObj) => {

		let infoTrigger = getApiProperty(selectedStore).triggers[selectedTrigger]
		console.info('dataSheetdataSheet', dataSheet);
		console.info('trigger', getApiProperty(selectedStore).triggers[selectedTrigger]);

		if(dataSheet.listObj.length === 0){
			props.info({error: "Please select  sku/origin from a sheet."})
			return;
		}

		SubmitReq(api, Object.assign({
			data: JSON.stringify(dataSheet.listObj),
			trigger: infoTrigger.trigger,
			is_variant: "isVariant" in infoTrigger? infoTrigger.isVariant : false,
		}, shopObj, ("triggerArgs" in infoTrigger? infoTrigger.triggerArgs : {})))
	}

	const submitData = (api, args, callback) => {
		let type = getApiPropertyRequired(selectedStore)

		if(!type){
			props.info({error: "No type requived corresponding a test."})
		}else{
	
			console.info('aaaaaaaaaaaa', Object.assign({
				"api": api
			}, args));

			let type = getApiPropertyRequired(selectedStore)
			console.info("SASDASd", type);
			// return;

			socketPerso("task", {
				url: refConst.urlServer,
				// token: refConst.tokenEio,
				internalReq: Object.assign({
					"method": "POST",
					"api": api,
				}, args),
				task: {
					op: "update",
					type: "shopify_alter_data",
					args: {
						uid: props.getUser().uid,
						name: `[${args.shop}] Bulk Alter Data ${dataSheet.listObj.length} items on ${type.trigger}`
					}
				},
				eventIdTaskName: "bulkAlterDataEvent"
			}, (r) => {
				setSubmited(false)
			})
			
			/* ajaxPerso( Object.assign({
				"api": api
			}, args), callback, function(jqXHR, textStatus) {
				setSubmited(false)
				console.warn( textStatus );
			}); */
		}
	}

	const fetchItems = (args, callback) => {
		props.loading(true)

		let listSku = dataSheet.listObj.map((o) => {
			return 	o.sku;
		})

		ajaxPerso(Object.assign({
			"trigger": "getItems",
			"propertyList": ["id", "sku", "title", "images_json", "tags", "published_scope", "selling_when_out_of_stock"].join(','),
			"skuList": listSku.join(','),
		}, args), callback);
	}

	const SubmitReq = (api, args) => {
		submitData(api, args, (r) => {
			setSubmited(false)

			console.info(`Fetching alter products ${api}:`, r);
			if(r.success){
				setResTrigger(r)
				// if(r.success !== true)	props.info({"success": r.success})
				if(r.success === true)
					r.success = "Items updated";
			}
			props.info(r)
		})
	}

	let addGrid = () => {
		
		if(items.length === 0)	return;
		console.info('itemsitems', items);

		// We make sure that we have no object for the render in our grid
		items.map(o => {
			for (const key in o) {
				if (Object.hasOwnProperty.call(o, key)) {
					if(typeof o[key] === "object")
						o[key] = JSON.stringify(o[key])
				}
			}
		})

		return <DataGrid
			headerRenderer={stickyHeader}
			columns={Object.keys(items[0]).map(k => (
				Object.assign({
					key: k,
					name: k
				}, defaultColumnProperties)
			))}
			rows={items}
		/>
	}

	const defaultColumnProperties = {
		resizable: true,
		headerRenderer: stickyHeader
	}

	let stickyHeader = (item) => {
		// console.info('itemitemitemitem', item);
		return <span className="mr-2">{item.column.name}</span>;
	}

	const getApiPropertyRequired = (store) => {
		let type = null
		info("test", dataSheet.required)
		info("test2", getApiProperty(store).triggers)
		
		let reqSheet = Array.isArray(dataSheet.required)? dataSheet.required : [dataSheet.required]

		getApiProperty(store).triggers.forEach(r => {
			reqSheet.forEach(r2 => {
				if(r.required.indexOf(r2.toLowerCase()) !== -1){
					type = r
					return false;
				}
			})
		})
		return type
	}

	const getApiProperty = (store) => {
		return apiProperty.find(s => {
			if(s.name === store)	return s
		})
	}

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage">
			<h1 className="page-header mb-4">{props.title}</h1>
			<div className="container p-3 bg-light border mb-3">
				<p className="font-weight-bolder">Store to compare to</p>
				<div className="d-flex flex-row align-items-center">
					{
						<Select
							className="w-50"
							styles={refConst.styleList}
							onChange={(selectedOption) => {
								setSelectedStore(selectedOption? selectedOption.value : initialState)
							}}
							options={(() => {
								return apiProperty.map(b => {
									return {
										value: b.name,
										label: `${b.name}`
									}
								})
							})()}
							value={selectedStore? [{value: selectedStore, label: selectedStore}] : null}
							placeholder={"Brand"}
							isSearchable={true}
							isMulti={false}
						/>
					}
				</div>
			</div>
			<div id="mainMenu" className="container mb-3 bg-light border p-3">
				{
					selectedTrigger? <div>
						{getApiProperty(selectedStore).triggers[selectedTrigger].alert}
					</div> : ''
				}
				<p className="font-weight-bolder">Select the primary type of data to use for your update</p>
				{
					getApiProperty(selectedStore).triggers.map((s, pos) => {
						return <SheetCapture key={s.required + "_sheetCapture"} className={"ml-4"} dataSheet={dataSheet} onChange={(val) => {
							setDataSheet(val)
						}} onClick={() => {
							setSelectedTrigger(pos)
						}} isActive={selectedTrigger === pos} name={<div>
							<div>{s.name}</div>
							<div>From Sheet [by: {s.required.join(', ')}]</div>
						</div>} valuesAllowed={"valuesAllowed" in s? s.valuesAllowed : null} {...props} returnAll={true} required={Array.isArray(s.required)? s.required : [s.required]}/>
					})
				}
			</div>
			{
				dataSheet? <div className="container mt-4 bg-light p-3 border">
					<p className="font-weight-bolder">Informations</p>
					<div className="row">
						<div className="col-sm">
							Line number:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.sheet.length > 0? dataSheet.sheet.length-1 : 0 }</span>
						</div>
					</div>
					<div className="row">
						<div className="col-sm">
							The column selected:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.columnName }</span>
						</div>
					</div>
					<div className="row">
						<div className="col-sm">
							Items found:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ items.length }</span>
						</div>
					</div>
					<div className="row">
						<div className="col-sm">
							Items:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ addGrid() }</span>
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							<button type="button" className="btn btn-primary btn-lg" disabled={items.length === 0 || !dataSheet || submited? true : false} onClick={() => {modalConfirmation(triggerPageFnc)}}>Update</button>
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							{ showDifference() }
						</div>
					</div>
				</div> : ''
			}
		</div>
	);
}