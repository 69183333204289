// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import Select from 'react-select';
import {ajaxPerso, fetchShopifyItems, handelize} from "../../../fnc.js"

const refConst = require("../../../constants.js").v

var initialState = {
	_isMounted: false
}

const namespace = "inventory";
const keyMeta = "linkedTo";

function LinkToSection(props) {

	const [idMeta, setIdMeta] = useState(null); // Get the id from the meta attached to the open product.
	const [idItemsLinkedTo, setIdItemsLinkedTo] = useState(null);
	const [itemSelectedToLinkTo, setItemSelectedToLinkTo] = useState([]); // Get the id's freshly selected from the search result.
	const [resSearchLinkTo, setResSearchLinkTo] = useState([]);
	const [resLinkedTo, setResLinkedTo] = useState({original: [], data: []}); // Get the result of the fetch base on the id's pulled from the meta attached to the opened product.
	const inputSearchItemLinkTo = useRef(null)
	
	useEffect(() => {
		initialState._isMounted = true;
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		if(props.parent.match.params.idItem)
			loadMetaItemOpen()
	}, [props.parent.match.params.idItem])

	useEffect(() => {
		if(idItemsLinkedTo)
			fetchItemsLinkedTo(idItemsLinkedTo.map(o => o.id))
	}, [idItemsLinkedTo])


	const meta = {
		fetch: (urlPrefix, callback) => {

			ajaxPerso( {
				"api": "shopify",
				"trigger": "fetch_meta",
				"shop": props.shop.id,
				"urlPrefix": urlPrefix? urlPrefix : "",
				"namespace": namespace,
				"key": keyMeta
			}, callback)
		},
		create: (urlPrefix, dataObj, callback) => {
			console.info('Will create this:', dataObj);
	
			if(dataObj.length > 0){
				/* console.info('META', {
					"api": "shopify",
					"trigger": "createUpdate_meta",
					"shop": props.shop.id,
					"urlPrefix": urlPrefix? urlPrefix : "",
					"data": JSON.stringify({
						namespace: namespace,
						key: keyMeta,
						value: JSON.stringify(dataObj),
						type: "json"
					}),
				}); */
				
				ajaxPerso( {
					"api": "shopify",
					"trigger": "createUpdate_meta",
					"shop": props.shop.id,
					"urlPrefix": urlPrefix? urlPrefix : "",
					"data": JSON.stringify({
						namespace: namespace,
						key: keyMeta,
						value: JSON.stringify(dataObj),
						type: "json"
					}),
				}, callback)
			}
		},
		delete: (idMeta, callback) => {
	
			ajaxPerso( {
				"api": "shopify",
				"trigger": "del_meta",
				"shop": props.shop.id,
				"id": idMeta,
				"verbose": false
			}, callback)
		}
	}

	const loadMetaItemOpen = () => {
		meta.fetch(`products/${props.parent.match.params.idItem}/`, (r) => {
			console.info(`Fetched fetch_meta:`, r)
			if(r.success){
				setIdMeta(r.res.length > 0? r.res[0].id : null)
				setIdItemsLinkedTo(r.res.length > 0? JSON.parse(r.res[0].value) : null)
			}
		})
	}

	const fetchItemsToLinkTo = (e) => {
		e.preventDefault()

		if(inputSearchItemLinkTo.current.value.trim().length === 0){
			props.info({error: "Please fill the search input."})
			return
		}
		
		fetchShopifyItems({
			"search": inputSearchItemLinkTo.current.value.trim(),
			"shop": props.shop.id,
			"fromNbr": 0,
			"nbrItem": 20,
			"trigger": "getItems_search"
		}, (r) => {
			console.info('Fetched "fetchShopifyItems": ', r);
			let newData = {};
			for (const type in r) {
				if (Object.hasOwnProperty.call(r, type)) {
					const obj = r[type];
					newData[type] = obj.success && obj.data.length > 0? obj.data : []
				}
			}
			setResSearchLinkTo(newData)
		})
	}

	const fetchItemsLinkedTo = (listIdItems) => {

		fetchShopifyItems({
			shop: props.shop.id,
			idShopifyList: listIdItems.join(',')
		}, (r) => {
			console.info('Fetched "fetchShopifyItems": ', r);

			let resList = r.success && r.data.length > 0? r.data.map(d => {

				let imgSrc = d.images_json[0].node? d.images_json[0].node.originalSrc : d.images_json[0].src

				return {
					id_product_shopify: d.id_product_shopify,
					image_src: imgSrc,
					title: d.title,
					sku: d.sku,
					handle: d.handle,
				}
			}) : [] 

			setResLinkedTo( { original: resList.slice(0), data: resList } )
		})
	}

	const showFormLinkTo = () => {
		/**
		 * Search engine
		 */
		let output = <form className="" onSubmit={(e) => fetchItemsToLinkTo(e)}>
			<div className="input-group mb-3">
				<input type="text" className="form-control" placeholder="Seach Product" aria-label="Seach Product" aria-describedby="button-searchProduct" ref={inputSearchItemLinkTo}/>
				<div className="input-group-append">
					<button type="submit" className="btn btn-outline-secondary" id="button-searchProduct">Search</button>
				</div>
			</div>
		</form>;
		
		return output
	}

	const deleteMetaFromItems = () => {
		/**
		 * Will delete the meta from the items unselected.
		 */
		if(resLinkedTo.data.length != resLinkedTo.original.length){
			let idProductRequireToDelete = []
			resLinkedTo.original.forEach(o => {

				let found = resLinkedTo.data.find(o2 => {
					if(o.id_product_shopify == o2.id_product_shopify)	return o2
				})

				if(!found){
					idProductRequireToDelete.push(o.id_product_shopify)
				}
			});

			if(idProductRequireToDelete.length === 0){
				props.info({success: "Nothing required to delete."})
			}else{

				let infoUser = {success: [], error: []}
				idProductRequireToDelete.forEach(idItem => {
					
					meta.fetch(`products/${idItem}/`, (r) => {
						console.info(`Fetched fetch_meta:`, r)
						if(r.success && r.res.length > 0){
							
							let idM = r.res[0].id
	
							console.info(`[Del] item ${idItem} -> idMeta`, idM);

							meta.delete(idM, (r) => {
								console.info('Fetch del_meta', r);
								
								if(r.success){
									infoUser.success.push(`Meta (${idM}) deleted for item ${idItem}.`)
								}else{
									infoUser.error.push(r.error)
								}
								props.info(infoUser)
							})
							
						}else{
							infoUser.error.push(`Meta not found for Item: ${idItem}, ${r.error}`)
						}
						props.info(infoUser)
					})
				})
			}
		}
	}

	const deleteCurrentOpenItemMeta = () => {
		/**
		 * Will delete the meta from the current product if no item where linked and that we have no item previously linked and that a meta exist.
		 */
		console.info('resLinkedTo', resLinkedTo);
		console.info('itemSelectedToLinkTo', itemSelectedToLinkTo);
		console.info('idMeta', idMeta);
		
		if(resLinkedTo.data.length === 0 && itemSelectedToLinkTo.length == 0 && idMeta){
			let infoUser = {success: [], error: []}
			meta.delete(idMeta, (r) => {
				console.info('Fetch deleteTagFromGrp', r);
				
				if(r.success){
					infoUser.success.push(`Meta (${idMeta}) deleted for item ${props.parent.match.params.idItem}.`)
					setItemSelectedToLinkTo([])
					loadMetaItemOpen()
				}else{
					infoUser.error.push(r.error)
				}
				props.info(infoUser)
			})
			
		}
	}

	const createMeta = () => {
		/**
		 * This function will create a meta for each product selected included the main one open.
		 *  - Each product list will contain every other id product, which means that the it from the 
		 *    main product won't be in the list.
		 * 	This function will also delete the meta if an attached items has been unselected.
		 *  Will also delete the meta from the opened main item if no items are in the linked or selected.
		 */
		if(!props.title || props.title.length === 0){
			props.info({error: "You need to fill the title in order to generate a handle for this metafield."})
			return;
		}

		let idItemOpen = parseInt(props.parent.match.params.idItem)
		let allIds = []

		if(itemSelectedToLinkTo.length > 0 || resLinkedTo.data.length > 0){
			allIds.push({
				id: idItemOpen,
				handle: handelize(props.title)
			})
			console.info('handelize(props.title', handelize(props.title));
			
		}

		deleteMetaFromItems()
		deleteCurrentOpenItemMeta()
		setResLinkedTo({original: [], data: []})

		itemSelectedToLinkTo.forEach(itemToLinkTo => {
			allIds.push(itemToLinkTo) // Already has the {id and handle} format
		})

		resLinkedTo.data.forEach(o => {
			allIds.push({
				id: o.id_product_shopify,
				handle: o.handle
			})
		})

		// let uniqueIds = [...new Set(allIds)];
		// console.info('allIds', uniqueIds);

		let resList = {success: [], error: []};
		/* let countedDone = 0,
			cycled = 0; */
		allIds.forEach(objItem => {

			// We make a new list that will contain only every other item than the listing about to be modified.
			let newList = []
			allIds.forEach(oC => {
				if(oC.id !== objItem.id)	newList.push(oC)
			});
			
			meta.create(`products/${objItem.id}/`, newList, (r) => {
				if(r.success){
					resList.success.push(`Meta added for item ${objItem.id}`);
				}else{
					resList.error.push(`Error when adding Meta for item ${objItem.id}`);
				}
				// Once we update the meta for the current opened item, we load it again cause it could have been updated or created so we will need the correct ID,
				// this will also load the correct items linked to in the view.
				if(idItemOpen === objItem.id){
					// setIdMeta(r.json? r.json.id : null)
					setItemSelectedToLinkTo([])
					loadMetaItemOpen()
				}
				// countedDone++;
			})
		});

		/* let intVar = setInterval(() => {
			cycled++;
			if(cycled > 100 || countedDone === uniqueIds.length){
				clearInterval(intVar)
				loadMetaItemOpen()
			}
		}, 80) */
	}
	
	const showItemsSelectionLinkedTo = () => {
		
		let outputHeader = []
		let outputBody = []
		let pos = 0,
			idItemOpen = parseInt(props.parent.match.params.idItem);

		let idListLinked = resLinkedTo.data.map(o => {
			return o.id_product_shopify
		})

		for (const type in resSearchLinkTo) {
			if (Object.hasOwnProperty.call(resSearchLinkTo, type)) {
				const list = resSearchLinkTo[type];

				outputHeader.push(<li key={type+"_searchResultHeader"} className="nav-item" role="presentation">
					<a className={`nav-link` + (pos === 0? ' active' : '')} id={`tabMenuLinkTo_${type}_tab`} data-toggle="tab" href={`#tabMenuLinkTo_${type}`} role="tab" aria-controls={`tabMenuLinkTo_${type}`} aria-selected="true">{type}</a>
				</li>)

				outputBody.push(<div key={type+"_searchResultBody"} className={`tab-pane fade show` + (pos === 0? ' active' : '')} id={`tabMenuLinkTo_${type}`} role="tabpanel" aria-labelledby={`tabMenuLinkTo_${type}_tab`}>
					<div className="ulGrpLinkTo shadow w-100 noselect text-wrap" style={{top: "-35px", left: "0px", zIndex: "9876", maxHeight: "200px", overflowX: "auto"}}>
						{
							Array.isArray(list)? list.map(d => {

								if(idListLinked.indexOf(d.id_product_shopify) !== -1)
									return null;

								let imgSrc = d.images_json && d.images_json.length > 0? d.images_json[0].node? d.images_json[0].node.originalSrc : d.images_json[0].src : null
								
								return <div key={d.id_product_shopify+"_searchResult"} style={{marginBottom: "1px"}} className={"d-flex flex-row align-items-center middeSizeText cursor p-0 " + (itemSelectedToLinkTo.find((oF, pos) => {
									if(oF.id === d.id_product_shopify) return oF
									return false;
								})? "bg-primary text-light" : "")}>
									{d.images_json && d.images_json.length > 0? <img src={imgSrc} height="45px" width="45px" className="ml-3"/> : ''}
									<div className="p-1 d-inline-block" onClick={() => {
										let currentId = parseInt(d.id_product_shopify);

										let posFound = null;
										let found = itemSelectedToLinkTo.find((oF, pos) => {
											if(oF.id === currentId){
												posFound = pos;
												return oF
											}
											return false;
										})
										
										if(found)
											itemSelectedToLinkTo.splice(posFound, 1)
										else
											itemSelectedToLinkTo.push({
												"id": parseInt(currentId),
												"handle": d.handle
											})

										// resLinkedTo.push(d)
										//setResLinkedTo(resLinkedTo.slice(0))
										setItemSelectedToLinkTo(itemSelectedToLinkTo.slice(0))
									}}>[<u>{d.sku}</u>] <span className="ml-2">{d.title}</span></div>
								</div>
							}) : ''
						}
					</div>
				</div>)
				pos++;
			}
		}
		
		return <div>
			<ul className="nav nav-tabs" id="myTab" role="tablist">
				{outputHeader}
			</ul>
			<div className="tab-content" id="myTabContent">
				{outputBody}
				{ itemSelectedToLinkTo.length > 0? <div className="mt-3 text-center d-flex flex-row justify-content-between">
					<button type="button" className="btn bg-warning btn-sm" onClick={(e) => { setItemSelectedToLinkTo([]) }}>Unselect all</button>
					<button type="button" className="btn btn-primary btn-sm" onClick={(e) => { createMeta(e) }}>Attache new selection</button>
				</div> : '' }
			</div>
			<div className="mt-3" id="">
				Product Attached: <span className="smallText">(Click on blue area to remove)</span>
				<div className="mt-2">
					{resLinkedTo.data.map((d, pos) => {

						if(d.id_product_shopify === idItemOpen)
							return null;

						return <div key={`resLinkedTo_`+d.id_product_shopify} className={"text-wrap d-flex flex-row justify-content-center border-bottom border-left border-right " + (pos === 0? " border-top" : "")}>
							{d.image_src? <img src={d.image_src} height="45px" width="45px"/> : ''}
							<div className="bg-primary d-flex flex-row align-items-center pointer text-light middeSizeText"  style={{width: "83%"}}  onClick={() => {

								resLinkedTo.data.splice(pos, 1)
								setResLinkedTo(Object.assign({}, resLinkedTo))

							}}>
								<span className="p-1">
									[<u>{d.sku}</u>] 
									<span className="ml-2">{d.title}</span>
								</span>
							</div>
							<div className="d-flex justify-content-center align-items-center" style={{width: "17%"}}>
								<a className="" target="_blank" rel="noopener noreferrer" href={ "https://" + props.shop.account_name + "/products/" + d.handle}>View</a>
							</div>
						</div>
					})}
				</div>
				{ resLinkedTo.data.length !== resLinkedTo.original.length? 
					<div className="mt-3 d-flex flex-row justify-content-between">
						<button type="button" className="btn btn-danger btn-sm" onClick={() => { fetchItemsLinkedTo(idItemsLinkedTo.map(o => o.id)) }}>Reset</button>
						<button type="button" className="btn btn-primary btn-sm" onClick={(e) => { createMeta(e) }}>Save change</button>
					</div> : '' 
				}
			</div>
		</div>
	}
	
	return (<div>
		{showFormLinkTo()}
		{showItemsSelectionLinkedTo()}
	</div>);
}

export default LinkToSection