// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { fetchShopifyItems } from '../../fnc'

var initialState = {
	_isMounted: false,
	showEnded: false
}

const refConst = require("../../constants.js").v

var _isMounted = false
let modalOpenUniq = false

export default function ShopifySearchProduct(props){

	const searchTypes = [
		{"show": "Sku", "real": "searchSku"},
		{"show": "Title", "real": "searchTitle"},
		{"show": "Vendor", "real": "searchVendor"}
	];
	const [searchResult, setSearchResult] = useState([]);
	const [searchBy, setSearchBy] = useState(searchTypes[0]);
	const [refreshView, setRefreshView] = useState(0);
	const refInputSearch = useRef(null)

	useEffect(() => {
		_isMounted = true;
		return () => _isMounted = false
	}, [])

	useEffect(() => {
		if(modalOpenUniq)
			selectProductModal(true);
	}, [searchResult, searchBy, props.itemSelected])

	const submitSearch = (e) => {
		e.preventDefault();
		console.info("refInputSearch", refInputSearch);
		if(!refInputSearch || refInputSearch.current.value.length === 0){
			props.info({error: `Please fill up the search box.`})
		}else{

			fetchShopifyItems( {
				[searchBy.real]: refInputSearch.current.value,
				"fromNbr": 0,
				"nbrItem": 50,
				"shop": props.shopId
			}, (r) => {
				console.info(`Fetch fetchShopifyItems by ${searchBy.real}`, r);
				setSearchResult(r.success? r.data : [])
			})
		}
	}

	const selectProductModal = (refresh) => {

		props.modal({
			show: refresh? false : true,
			title: "Select a product",
			html: (close) => {
				return <div>
					<form className="input-group mb-3" onSubmit={submitSearch}>
						<div className="input-group-prepend">
							<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">Search by {searchBy.show}</button>
							<div className="dropdown-menu">
								{
									searchTypes.map(typeO => {
										return <a className="dropdown-item" key={`searchBy_${typeO.show}`} onClick={() => {
											setSearchBy(typeO)
										}}>
											Search by {typeO.show}
										</a>
									})
								}
							</div>
						</div>
						<input type="text" className="form-control" ref={refInputSearch} />
						<div className="input-group-append">
							<button className="btn btn-outline-secondary" type="submit">Search</button>
						</div>
					</form>
					<div>
						{
							searchResult.length > 0? searchResult.map(d => {

								let imgSrc = d.images_json && d.images_json.length > 0? d.images_json[0].node? d.images_json[0].node.originalSrc : d.images_json[0].src : null
								
								return <div 
									key={d.id_product_shopify+"_searchResult"}
									style={{marginBottom: "1px"}}
									className={"d-flex flex-row align-items-center middeSizeText cursor p-0 lineHoverColor " + ("itemSelected" in props && props.itemSelected == parseInt(d.id_variant_shopify)? "bg-primary text-light" : "")}
									onClick={() => {
										if("updateItem" in props){
											props.updateItem(d, close)
											setRefreshView(refreshView+1)
										}
								}}>
									{d.images_json && d.images_json.length > 0? <img src={imgSrc} height="45px" width="45px" className="ml-3"/> : ''}
									<div className="p-1 d-inline-block ml-3">
										[<u>{d.sku}</u>] 
										<span className="ml-2">${d.price}</span> 
										{d.compare_at_price? <span title={d.compare_at_price} className="badge badge-danger ml-2">Sale</span> : ""}
										<span className="ml-2">{d.title}</span>
									</div>
								</div>
							}) : <div className="text-center">No result</div>
						}
					</div>
				</div>
			}
			,ok: {
				title: "Validate",
				fnc: (popup, close) => {
					setSearchResult([])
					modalOpenUniq = false
					close()
				}
			},
			return: {
				title: "Close",
				fnc: (popup, close) => {
					setSearchResult([])
					modalOpenUniq = false
					close()
				}
			}
		})
	}
		
	return (<button
			className={("className" in props? props.className : "")}
			onClick={() => {
				if("startFnc" in props)	props.startFnc()
				if("onClick" in props)	props.onClick()
				modalOpenUniq = true
				selectProductModal();
			}}>{props.title}</button>);
}