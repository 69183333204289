// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import Select from 'react-select';
import { parseAjaxReturn, fetchStores, ajaxPerso, fetchBrands, getSupplier } from '../../../fnc'
import {
	NavLink,
	Redirect
} from 'react-router-dom';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/monokai.css');
require('codemirror/addon/hint/show-hint.css');

require('codemirror/addon/hint/show-hint.js');
require('codemirror/addon/hint/html-hint.js');
require('codemirror/mode/htmlmixed/htmlmixed');
require('codemirror/keymap/sublime');
const refConst = require("../../../constants.js").v

const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

function EditBrand(props){
	const params = new URLSearchParams(props.parent.location.search);

	const [selectedModalViewTmp, setSelectedModalViewTmp] = useState(false);
	// const [brand, setBrand] = useState([]);
	const [listBrands, setListBrands] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState(false);
	const [listStores, setListStores] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [selectedSupplier, setSelectedSupplier] = useState(false);
	const [selectedStore, setSelectedStore] = useState(false);

	const [manualEntry, setManualEntry] = useState({
		is_active: 1,
		name: "",
		prefix: ""
	});
	//const [brandSelected, setBrandSelected] = useState(false);


	useEffect(() =>{
		getSupplier({}, (r) => {
			console.info('getSupplier', r);
			props.loading(false)
			if(r.data)
				r.data.sort((obj1, obj2) => {
					if(obj1.name > obj2.name)
						return 1
					return -1
				})
			setSuppliers(r.success? r.data : [])
		})
	}, [])
	
	useEffect(() =>{
		console.log("selectedBrand", selectedBrand);
		let brandObj = listBrands.find(obj => {
			console.log(obj.id + " -- " + selectedBrand.value);
			if(parseInt(obj.id) === selectedBrand.value)	return obj
		})

		if(brandObj){
			setManualEntry({
				"is_active": parseInt(brandObj.is_active),
				"name": brandObj.name,
				"prefix": brandObj.prefix,
				"id": parseInt(props.parent.match.params.idBrand) || null,
				"id_marketplace": selectedStore && selectedStore.value? selectedStore.value : null
			})
		}
	}, [selectedBrand])

	useEffect(() => {
		let idBrand = parseInt(props.parent.match.params.idBrand)

		console.info("idBrandidBrand", idBrand);
		console.info("listBrandslistBrands", listBrands);

		let objSelected = listBrands.find(o => {
			if(parseInt(o.id) === idBrand)	return o
		})

		if(objSelected){
			setSelectedStore({value: parseInt(objSelected.id_marketplace), label: objSelected.store_name})
			console.log("test", objSelected);
			
			setManualEntry({
				"is_active": parseInt(objSelected.is_active),
				"name": objSelected.name,
				"prefix": objSelected.prefix,
				"id": idBrand,
				"id_marketplace": parseInt(objSelected.id_marketplace)
			})
		}
		
	}, [props.parent.match.params.idBrand, listBrands])

	useEffect(() =>{
		console.info('selectedStore.value', selectedStore);
		
		setManualEntry(Object.assign(manualEntry, {id_marketplace: selectedStore.value}))
		
	}, [selectedStore])

	useEffect(() => {
		//let idBrand = props.parent.match.params.idBrand

		//props.fetchBrands( { "idBrand": idBrand }, (r) => {
		/* props.fetchBrands( {}, (r) => {
			console.info('Fetched brands:', r);
			props.loading(false)

			if(r.success)
				setBrand(r.data)
		}) */
		fetchBrands( null, {}, null, (r) => {
			console.info('Fetched brands:', r);
			props.loading(false)

			if(r.success){
				/* let wantedBrand = []
				r.data.forEach(obj => {
					if(parseInt(obj.id_marketplace) === refConst.id_store_finale)
						wantedBrand.push(obj)
				});
				setListBrands(wantedBrand) */
				setListBrands(r.data)
			}
		})

		fetchStores((r) => {
			console.info('Fetched stores:', r);
			props.loading(false)

			if(r.success){
				let wantedBrand = []
				r.data.forEach(obj => {
					if(obj.id !== "5")
						wantedBrand.push(obj)
				});
				setListStores(wantedBrand)
			}
		}, {})
	}, [])

	const sendRequest = () => {
		if(selectedStore){
			/* let args = {
				"idBrand": selectedBrand.value,
				"idStore": selectedStore.value
			} */

			ajaxPerso( {
				"api": "eio",
				"trigger": "setBrand",
				"data": JSON.stringify(manualEntry)
			}, (r) => {
				console.info('Update setBrand:', r);
				if(r.ok){
					props.info({success: parseAjaxReturn(r)})
					//props.info({success: "Brand succefully added."})
					props.parent.history.push("/brand");
				}else
					props.info({error: parseAjaxReturn(r)})
			})
			
		}else
			props.info({error: "Please select a store to associate the brand to."})
	}

	const deleteBrand = () => {
		let idBrand = parseInt(props.parent.match.params.idBrand)

		if(idBrand){
			props.modal({
				show: true,
				title: "Confirmation",
				html: () => {
					return <div>
						Are you sure you want to delete the following template: <span className="font-weight-bold">{manualEntry.name} - {selectedStore.label}</span>
					</div>
				}
				,ok: {
					title: "I'm sure",
					fnc: (popup, close) => {
						ajaxPerso( {
							"api": "eio",
							"trigger": "delBrand",
							"id": idBrand
						}, (r) => {
							console.info('Delete delBrand:', r);
							if(r.success && r.res.processed){
								props.info({success: parseAjaxReturn(r)})
								props.parent.history.push("/brand");
							}else
								props.info({error: parseAjaxReturn(r)})
							close()
						})
					}
				},
				return: {
					title: "Close"
				}
			})
		}else
			props.info({error: "No brand selected."})
	}
	
	return (<div>
		<p className="h1 mb-4">Brand manager</p>
		<div className="d-flex justify-content-between">
			<button type="button" className="btn btn-primary btn-lg mb-4" onClick={ () => {
				sendRequest();
			} }>Save</button>
			<NavLink to={{
				pathname: '/MappingBrand',
				// pathname: props.parent.match.params.idBrand? `/template/${props.parent.match.params.idBrand}` : '/MappingBrand',
				// search: props.parent.match.params.idBrand? `mode=tmpdatagroup` : ""
			}}><button type="button" className="btn btn-primary">TMP - Group Data</button></NavLink>
			{ props.parent.match.params.idBrand? <button type="button" className="btn btn-danger btn-lg mb-4" onClick={ () => {
				deleteBrand();
			} }>Delete</button> : "" }
		</div>
		<div className="form-group mb-3">
			<label htmlFor="inputGroupSelect01">Base on a Finale brand</label>
			
			<Select
				styles={ styleList }
				onChange={(args) => {
					setSelectedBrand(args)
				}}
				options={
					listBrands.map(obj => {
						return {
							value: parseInt(obj.id),
							label: obj.store_name + ": " + obj.name
						}
					})
				}
				value={
					selectedBrand? selectedBrand : {value: '', label: '-'}
				}
				/* defaultValue={
					selectedBrand? selectedBrand : {value: '', label: '-'}
				} */
				isSearchable={true}
			/>
		</div>
		<div className="form-group mb-3">
			<label htmlFor="inputGroupSelect01">Finale Supplier Associated</label>
			
			<Select
				styles={ styleList }
				onChange={(args) => {
					setSelectedSupplier(args)
					manualEntry.id_supplier = args.value
				}}
				options={
					suppliers.map(obj => {
						return {
							value: parseInt(obj.id_finale),
							label: obj.name
						}
					})
				}
				value={
					selectedSupplier? selectedSupplier : {value: '', label: '-'}
				}
				/* defaultValue={
					selectedBrand? selectedBrand : {value: '', label: '-'}
				} */
				isSearchable={true}
			/>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-sm">
					<label htmlFor="inputGroupSelectName">Name*</label>
					<input type="text" className="form-control" value={manualEntry.name} id="inputGroupSelectName" aria-describedby="emailHelp" placeholder="Enter email"onChange={(e) => { 
						manualEntry.name = e.target.value
						setManualEntry(Object.assign({}, manualEntry))
					}}></input>
				</div>
				<div className="col-sm">
					<label htmlFor="inputGroupSelectPrefix">Prefix</label>
					<input type="text" className="form-control" value={manualEntry.prefix || ""} id="inputGroupSelectPrefix" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => {
						manualEntry.prefix = e.target.value
						setManualEntry(Object.assign({}, manualEntry))
					}}></input>
				</div>
			</div>
			<div className="row">
				<div className="col-sm">
					<div className="custom-control custom-switch noselect mb-3 w-100">
						<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={manualEntry.is_active === 1? true : false} onChange={(e) => { 
							manualEntry.is_active = e.target.checked? 1 : 0
							setManualEntry(Object.assign({}, manualEntry))
						}}/>
						<label className="custom-control-label" htmlFor="customSwitchFree">is active</label>
					</div>
				</div>
				<div className="col-sm">

				</div>
			</div>
		</div>
		<div className="form-group mb-3">
			<label htmlFor="inputGroupSelect01">Store associated*</label>
			<Select
				styles={ styleList }
				onChange={(args) => {
					setSelectedStore(args)
				}}
				options={
					listStores.map(obj => {
						return{
							value: parseInt(obj.id),
							label: obj.name
						}
					})
				}
				value={
					selectedStore? selectedStore : {value: '', label: '-'}
				}
				defaultValue={
					selectedStore? selectedStore : {value: '', label: '-'}
				}
				isSearchable={true}
			/>
		</div>
	</div>);
}

export default EditBrand;