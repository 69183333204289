// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
import Select from 'react-select';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import {
	NavLink
} from 'react-router-dom';
import Chart from 'chart.js/auto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import { faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons'
import Filter from './filter'
import Loading from '../Loading';
import {ajaxPerso} from "../../../fnc.js"
const refConst = require("../../../constants.js").v
//var moment = require('moment');

const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999,
	}),
	container: (provided) => ({
		...provided,
		width: "100%"
	}),
	control: provided => ({
		...provided,
		margin: 8,
		marginBottom: 0,
	})
}

function DecreasingSells(props){

	const [version, setVersion] = useState("1.2.0");
	const [loadingOn, setLoadingOn] = useState(true);
	const [colorList, setColorList] = useState([]);
	const [selectedLabel, setSelectedLabel] = useState([]);
	const [listlabel, setListLabel] = useState([]);
	const [chart, setChart] = useState(null);
	const [warningList, setWarningList] = useState([]);
	const [choiceSorting, setChoiceSorting] = useState({});
	const [oldestEntryInStock, setOldestEntryInStock] = useState(null);

	const [monthBack, setMonthBack] = useState(2);
	const [avgWarning, setAvgWarning] = useState(30);
	const [dayInWeek, setDayInWeek] = useState(7);
	const [minSellsDay, setMinSellsDay] = useState(60);

	const [top, setTop] = useState(100);
	const [previousSearch, setPreviousSearch] = useState({
		"top": top,
		"monthBack": monthBack,
		"avgWarning": avgWarning,
		"dayInWeek": dayInWeek,
		"minSellsDay": minSellsDay
	});

	useEffect(() => {
		console.log("historyQty", warningList);
		
		if(document.querySelector("#graphDecreasingSells") && warningList.length > 0 && colorList.length > 0){

			let dataValue = [];
			// let skuList = [];
			let labelLeft = [];
			let itemsGraphData = [];
			let color = false;
			let listLabelSelect = [];

			warningList.forEach((o, index) => {

				dataValue = [];
				color = colorList[itemsGraphData.length]

				let i = 1;
				for (const dateRange in o.weeklyIncrease) {
					if (o.weeklyIncrease.hasOwnProperty(dateRange)) {

						dataValue.push( o.weeksSellsQty[i] )

						if(index === 0)
							labelLeft.push( dateRange )
						i++
					}
				}

				/* if(index === 0)
					labelLeft.push( o.lastWeekDates[0] + '/' + o.lastWeekDates[1] )
				dataValue.push( o.decrease ) */

				// Show the items selected in list but nothing else
				let isVisible = selectedLabel.find(objLabel => {
					if(objLabel.value == o.sku + " - (" + o.storeName + ")")
						return objLabel
				})

				itemsGraphData.push({
					label: o.sku + " - (" + o.storeName + ")",
					data: dataValue,
					backgroundColor: color,
					fill: false,
					borderColor: color,
					hidden: !isVisible,
					spanGaps: true,
					tension: 0.3,
					stepped: true,
					pointRadius: 6
				})

				listLabelSelect.push(o.sku + " - (" + o.storeName + ")")
			})
			
			setListLabel(listLabelSelect)

			styleList.option = (styleProvided, state) => {
				let findLabel = listLabelSelect.indexOf(state.value)

				if(findLabel !== -1){
					styleProvided.backgroundColor = state.isDisabled? 
						null : (
							state.isSelected? colorList[findLabel] : (
								state.isFocused? colorList[findLabel] : null
							)
						)
					styleProvided.color = state.isDisabled? 
						null : (
							state.isSelected? "white" : (
								state.isFocused? "white" : colorList[findLabel]
							)
						)
				}

				styleProvided.textShadow = state.isDisabled? 
					null : (
						state.isSelected? "0px 0px 4px black" : (
							state.isFocused? "0px 0px 4px black" : null
						)
					)
				styleProvided.cursor = "pointer"
				return {...styleProvided}
			}

		
			/* console.log("sumTest", sumTest);
			// console.log("skuList", skuList);
			console.log("dataValue", itemsGraphData);
			console.log("chart", chart); */

			/**
			 * Show only label and data from selected sku in the list
			 */
			let filtersFnc = (legend, chartData) => {
				if(selectedLabel.find(o => {
					//console.log(o.value + " === " + legend.text + " --- " + (o.value == legend.text));
					if(o.value == legend.text)
						return o
				}))
					return true
				return false
			}

			if(!chart){
				
				console.warn("Create new one");
				
				setChart(new Chart(document.querySelector("#graphDecreasingSells"), {
					type: 'line',
					data: {
						labels: labelLeft,
						datasets: itemsGraphData,
					},
					options: {
						plugins: {
							legend: {
								align: 'center',
								labels: {
									filter: filtersFnc
								}
							}
						},
						aspectRatio: 4,
						// cutoutPercentage: sumCount / 2
					}
				}))
			}else{
				console.warn("Update chart");

				//chart.destroy()
				chart.data = {
					labels: labelLeft,
					datasets: itemsGraphData,
				};
				chart.options.plugins.legend.labels.filter = filtersFnc;
				chart.update();
				setChart(chart)
			}
		}
	}, [warningList, selectedLabel, colorList])

	useEffect(() => {
		if(
			previousSearch.top != top || 
			previousSearch.monthBack != monthBack || 
			previousSearch.avgWarning != avgWarning || 
			previousSearch.dayInWeek != dayInWeek || 
			previousSearch.minSellsDay != minSellsDay
		){
			loadDecreasingSells();
			setPreviousSearch({
				"top": top,
				"monthBack": monthBack,
				"avgWarning": avgWarning,
				"dayInWeek": dayInWeek,
				"minSellsDay": minSellsDay
			})
		}
	}, [top, monthBack, avgWarning, dayInWeek, minSellsDay])

	useEffect(() => {
		loadDecreasingSells();
	}, [])

	const loadDecreasingSells = (args, callback) => {
		fetchDecreasingSells(args, (r) => {
			if(r.success){
				setWarningList(r.warning)

				let colors = [];
				for (let i = 0; i < r.warning.length; i++) {
					colors.push( "#"+((1<<24)*Math.random()|0).toString(16) );
				}
				setColorList(colors)

			}else{
				props.info({"error": r.error})
			}
			setLoadingOn(false)
		})
	}

	const fetchDecreasingSells = (args, callback) => {
			
		ajaxPerso( Object.assign({
			"trigger": "get_sales_list_for_past_month",
			"api": "shipstation",
			"month": monthBack,
			"daysInWeek": dayInWeek,
			"warningAfterDecrease": avgWarning,
			"sellsDaysMinimum": minSellsDay,
			"limit": top
		}, args), callback);
	}

	const listValue = () => {
		let tempOrderedTab = warningList.slice(0);

		for (const key in choiceSorting) {
			if (choiceSorting.hasOwnProperty(key)) {
				tempOrderedTab.sort((o1, o2) => {
					//console.log(o1[key] + " " + choiceSorting[key] + " " +  o2[key]);
					
					if(choiceSorting[key] === "<" && o1[key] < o2[key])	return 1
					if(choiceSorting[key] === ">" && o1[key] > o2[key])	return 1
					return -1
				})
			}
		}

		/* console.info('choiceSorting', choiceSorting);
		console.info('historyQty', tempOrderedTab); */
		
		return <ul className="list-group">
			{
				tempOrderedTab.map((o, index) => {
					return(<li key={o.sku + "_" + o.storeName} className="list-group-item">
						<div className="container">
							{
								(index === 0? <div className="row font-weight-bold mb-3 border-bottom">
									<div className="col-sm" style={{maxWidth: "70px"}}>
										N:
									</div>
									<div className={(choiceSorting.sku? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('sku') === -1)
											choiceSorting.sku = '>'
										else if(choiceSorting.sku === '>')
											choiceSorting.sku = '<';
										else
											delete choiceSorting.sku

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										SKU: { choiceSorting.sku? <FontAwesomeIcon icon={ choiceSorting.sku === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.decrease? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('decrease') === -1)
											choiceSorting.decrease = '>'
										else if(choiceSorting.decrease === '>')
											choiceSorting.decrease = '<';
										else
											delete choiceSorting.decrease

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Decrease { choiceSorting.decrease? <FontAwesomeIcon icon={ choiceSorting.decrease === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.avgPack1? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('avgPack1') === -1)
											choiceSorting.avgPack1 = '>'
										else if(choiceSorting.avgPack1 === '>')
											choiceSorting.avgPack1 = '<';
										else
											delete choiceSorting.avgPack1

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										AVG first weeks { choiceSorting.avgPack1? <FontAwesomeIcon icon={ choiceSorting.avgPack1 === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.avgPack2? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('avgPack2') === -1)
											choiceSorting.avgPack2 = '>'
										else if(choiceSorting.avgPack2 === '>')
											choiceSorting.avgPack2 = '<';
										else
											delete choiceSorting.avgPack2

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										AVG 3 last weeks { choiceSorting.avgPack2? <FontAwesomeIcon icon={ choiceSorting.avgPack2 === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.weeksCalculated? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('weeksCalculated') === -1)
											choiceSorting.weeksCalculated = '>'
										else if(choiceSorting.weeksCalculated === '>')
											choiceSorting.weeksCalculated = '<';
										else
											delete choiceSorting.weeksCalculated

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Weeks Calculated { choiceSorting.weeksCalculated? <FontAwesomeIcon icon={ choiceSorting.weeksCalculated === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.store? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('store') === -1)
											choiceSorting.store = '>'
										else if(choiceSorting.store === '>')
											choiceSorting.store = '<';
										else
											delete choiceSorting.store

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Store { choiceSorting.store? <FontAwesomeIcon icon={ choiceSorting.store === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
								</div> : "")

							}
							<div className="row">
								<div className="col-sm" style={{maxWidth: "70px"}}>
									{index+1}
								</div>
								<div className="col-sm">
									<a href={refConst.finaleLinkProduct + btoa('/eio/api/product/' + o.sku)} target="_blank" rel="noopener noreferrer">{o.sku}</a>
								</div>
								<div className="col-sm">
									{o.decrease}%
								</div>
								<div className="col-sm">
									{
										(function(){
											let sum = 0.0;
											Object.keys(o.firstPack).forEach((val, index) => {
												sum += o.firstPack[val]
											})
											//console.info('sum', sum);
											
											return (sum / Object.keys(o.firstPack).length).toFixed(2)
										})()
									}
									%
								</div>
								<div className="col-sm">
									{
										(function(){
											let sum = 0.0;
											Object.keys(o.secondPack).forEach((val, index) => {
												sum += o.secondPack[val]
											})
											//console.info('sum', sum);
											
											return (sum / Object.keys(o.secondPack).length).toFixed(2)
										})()
									}
									%
								</div>
								<div className="col-sm">
									{o.weeksCalculated}
								</div>
								<div className="col-sm" style={{maxWidth: "120px", overflowY: "auto"}}>
									{o.storeName}
								</div>
							</div>
						</div>
						
					</li>)
				})
			}
		</ul>
	}

	return (
		<div className={props.className + " main ctnPage d-flex flex-column flex-wrap border pr-3 pl-3 pb-3 pt-1 position-relative"}>
			<p className="h5">Decreasing sells</p>
			<Filter
				returnDatePiker={false}
				//from={fromTo.from}
				//setFrom={setFrom}
				setFromTo={false}
				fromTo={false}
				/* to={to}
				setTo={setTo} */
				top={top}
				setTop={setTop}
				moreFilters={
					<span>
						<span className="d-flex flex-row">
							<div className="input-group ml-3">
								<div className="input-group-prepend">
									<span className="input-group-text" id="basic-addon1">Month back:</span>
								</div>
								<input value={monthBack} onChange={(val => { setMonthBack(val.target.value) })} type="number" step="1.00" className="form-control" placeholder="Month back" />
							</div>
							{/* <div className="input-group ml-3">
								<div className="input-group-prepend">
									<span className="input-group-text" id="basic-addon1">Day in a week:</span>
								</div>
								<input value={dayInWeek} onChange={(val => { setDayInWeek(val.target.value) })} type="number" step="1.00" className="form-control" placeholder="Day in a week" />
							</div> */}
						</span>
						<span className="d-flex flex-row mt-3">
							<div className="input-group ml-3">
								<div className="input-group-prepend">
									<span className="input-group-text" id="basic-addon1">AVG for warning:</span>
								</div>
								<input value={avgWarning} onChange={(val => { setAvgWarning(val.target.value) })} type="number" step="1.00" className="form-control" placeholder="AVG for warning" />
							</div>
							{/* <div className="input-group ml-3">
								<div className="input-group-prepend">
									<span className="input-group-text" id="basic-addon1">Min sells day:</span>
								</div>
								<input value={minSellsDay} onChange={(val => { setMinSellsDay(val.target.value) })} type="number" step="1.00" className="form-control" placeholder="Min sells day" />
							</div> */}
						</span>
					</span>
				}
			/>
			<div className="mt-4" >
				<Select
					styles={styleList}
					onChange={(selectedOption) => {
						console.log(selectedOption);
						setSelectedLabel(selectedOption.slice(0))
					}}
					options={(() => {
						return listlabel.map(label => {
							return {
								value: label,
								label: label
							}
						})
					})()}
					value={selectedLabel}
					defaultValue={selectedLabel}
					isSearchable={true}
					isMulti={true}
				/>
			</div>
			<div className="mt-4" >
				<canvas id="graphDecreasingSells" className="w-100"></canvas>
			</div>
			{ oldestEntryInStock? <span className="mt-2 font-weight-light">Oldest item found at: {oldestEntryInStock.date_last_sold}</span> : '' }
			<div>
				<div className="mt-4" style={{maxHeight: "400px", overflowX: "auto"}}>
					{ listValue() }
					{/* <canvas id="ctnChartBestSelling" className="w-100"></canvas> */}
				</div>
			</div>
			<Loading loading={loadingOn} centered={true} />
			<h6 className="position-absolute text-secondary" style={{right: "15px", bottom: "0px", fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6>
		</div>
	);
}

export default DecreasingSells;