// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { capitalizeFLetter, ajaxPerso, clipboard } from '../../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import loading from '../../Rolling.svg';
//const refConst = require("../../constants.js").v

export default function Report3M(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-Mj_haqzismzadLiujp1" // order_view
	]

	const initialState = {
		submited: false
	}
	
	const [version] = useState("0.2.0");
	const [data, setData] = useState(null);
	const [info, setInfo] = useState(null);
	const [found, setFound] = useState(null);
	const search = useRef(null)

	useEffect(() => {
		modalSheet(true);
	}, [data, found])

	let init = () => {
		
		loadData()

		modalSheet(false)
	}

	let loadData = () => {
		props.loading(true)
		fetchData3M({
			vendor: "3m",
			type: "price"
		}, (r) => {
			props.loading(false)
			console.info(`Fetched fetchData3M`, r)
			setData(r.success? r.data : null)
		})

		fetchInfo3M({}, (data) => {
			props.loading(false)
			console.info(`Fetched fetchInfo3M`, data)
			setInfo(data? data : null)
		})
	}

	let fetchData3M = (args, callbackItem) => {
		
		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "download_brand_report",
		}, args), callbackItem);
	}

	let fetchInfo3M = (args, callbackItem) => {
		
		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "fetch_3M_info",
		}, args), callbackItem);
	}

	let submitSearch = (e) => {

		if(!data || !search.current.value)	return;

		props.loading(true)

		let stockNumber = search.current.value.toLowerCase().indexOf("3m-") !== -1? search.current.value.toLowerCase().substr("3m-".length) : search.current.value

		console.info("value Search", stockNumber);

		let found = data.filter(o => {
			if(o.mmmStockNumber.indexOf(stockNumber) !== -1){
				return o
			}
		})

		// If we didn't find anything then we look up for an UPC
		if(found.length === 0)
			found = data.filter(o => {
				if(parseInt(o.upc) === parseInt(stockNumber)){
					return o
				}
			})

		console.info("Found", found);
		setFound(found.length > 0? found : null)

		props.loading(false)

		if(!found || found.length === 0){
			props.info({error: "0 item found with this criteria."})
		}
	}

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				// const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let modalSheet = (refresh) => {

		props.modal({
			show: refresh? false : true,
			title: <span>3M Search <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			html: (popup) => {

				return <div className="text-center">
					{
						info? <div className={(info? "" : "d-none" ) + " float-left"} style={{fontSize: "12px", textAlign: "left"}}>
							<div>Last update:</div>
								<div><span className="d-inline-block" style={{minWidth: "80px"}}>Price API:</span><span style={{minWidth: "120px"}}>{info.lastFile_price_Created}</span></div>
								<div><span className="d-inline-block" style={{minWidth: "80px"}}>Product API:</span><span>{info.lastFile_availability_Created}</span></div>
						</div> : ""
					}
					<h1 className="page-header mb-3">Search into the 3M dataset</h1>
					{data === null? <div className="p-2 mb-3 badge badge-warning">
						<img src={loading} alt="Loading" className="mr-2" style={{height: "30px"}}></img>Data Loading, Please wait...
					</div> : ""}
					<div>
						<form onSubmit={ (e) => { submitSearch(e); 	e.preventDefault(); } }>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<button className="btn btn-outline-secondary" onClick={() => {search.current.value = ""}} type="button" id="button-addon1">Clear</button>
								</div>
								<input type="text" ref={search} placeholder="Search per 3M ID (w or /w prefix) or UPC" /* value="7000045543" */ className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" ></input>
								<div className="input-group-append">
									<button className="btn btn-outline-secondary bg-primary text-white" type="submit" id="button-addon2">Search</button>
								</div>
							</div>
						</form>
					</div>
					<div>
						{
							found? found.map(o => {
								return <div key={o.mmmStockNumber} className="d-flex flex-row justify-content-between border mb-3">
									<div className="p-2 text-left">
										<h3 className="d-flex align-items-center">
											sku: 3M-{o.mmmStockNumber}
											<FontAwesomeIcon className="ml-2" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(`3M-${o.mmmStockNumber}`, props)}}/>
											<span style={{fontSize: "14px"}} className={"badge text-light ml-2 " + (o.discontinued? "bg-danger" : "bg-success")}>{(o.discontinued? "discontinued" : "active")}</span>
										</h3>
										{"upc" in o? <div className="pl-2 pr-2">
											UPC: <u>{o.upc}</u> <FontAwesomeIcon className="ml-2" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(o.upc, props)}}/>
										</div> : ""}
										{"productType" in o? <div className="pl-2 pr-2">Product Type: <u>{o.productType}</u></div> : ""}
										{
											"pricingData" in o? <div className="p-3">
												<div className="font-weight-bold">Pricing data:</div>
												{
													o.pricingData.map(p => {
														return <div key={`${o.mmmStockNumber}_${p.unit.code}`} className="d-flex flex-row pl-1">
															<div className="mr-3">{p.unit.name}:</div>
															<div>
																${p.currentPrice.netPrice}
																{"minOrderQty" in p? <span className="ml-2">(Min Order: {p.minOrderQty})</span> : ""}
																<FontAwesomeIcon className="ml-2" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(p.currentPrice.netPrice, props)}}/>
															</div>
														</div>
													})
												}
											</div> : ""
										}
									</div>
									<div className="p-2 d-flex flex-column ">
										{
											"pricing" in o && !Array.isArray(o.pricing)? [
												<div key={`${o.mmmStockNumber}_net`} className="d-flex align-items-center">
													<span className="d-inline-block mr-3">Cost:</span>
													<h3 className="m-0">
														${o.pricing.netPrice}
													</h3>
													<FontAwesomeIcon className="ml-2" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(o.pricing.netPrice, props)}}/>
												</div>,
												<div key={`${o.mmmStockNumber}_list`} className="d-flex align-items-center">
													<span className="d-inline-block mr-3">List:</span>
													<h3 className="m-0">
														${o.pricing.listPrice}
													</h3>
													<FontAwesomeIcon className="ml-2" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(o.pricing.listPrice, props)}}/>
												</div>,
												<div key={`${o.mmmStockNumber}_effective`} className="d-flex align-items-center">
													<span className="d-inline-block mr-3">From:</span>
													<h3 className="m-0">
														{o.pricing.effectiveDate.replace(/(\d{4})(\d{2})(\d{2})/i, '$2-$3-$1')}
													</h3>
													<FontAwesomeIcon className="ml-2" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(o.pricing.effectiveDate, props)}}/>
												</div>
											] : ""
										}
									</div>
								</div>
							}) : ""
						}	
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: null, return: {
				title: "Exit",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}
	
	if(!props.securityClearance(props, access))	return "";

	return (
		<button type="button" className={(props.className || "") +  " btn btn-outline-primary"} onClick={() => init()}>
			{ props.name }
		</button>
	);
}