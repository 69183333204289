// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Loading from './Loading';
import Select from 'react-select';
import { fetchMenu, menuOrganized, capitalizeFLetter, fetchBrands, ajaxPerso, socketPerso, fetchMapping, findWherePosted } from '../../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'

const refConst = require("../../constants.js").v

var initialState = {
	// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
	storeFrom: null,
	masterVendorSelected: null,
	masterCollectionSelected: null,
	storeTo: null,
	itemsValid: [],
	items: [],
	categoriesStoreTo: {},
	brandsStoreTo: [],
	itemsCreated: {}, // {sku: {handle: '', id: ''}}
	submited: false,
	_isMounted: false
}

export default function CreateItem(props){

	const [masterVendorSelected, setMasterVendorSelected] = useState(initialState.masterVendorSelected);
	const [masterCollectionSelected, setMasterCollectionSelected] = useState(initialState.masterCollectionSelected);
	const [submited, setSubmited] = useState(initialState.submited);
	const [itemsCreated, setItemsCreated] = useState(Object.assign({}, initialState.itemsCreated));
	const [brandsStoreTo, setBrandsStoreTo] = useState(initialState.brandsStoreTo);
	const [storeFrom, setStoreFrom] = useState(initialState.storeFrom);
	const [storeTo, setStoreTo] = useState(initialState.storeTo);
	const [itemsValid, setItemsValid] = useState(initialState.itemsValid.slice(0));
	const [items, setItems] = useState(initialState.items.slice(0));
	const [categoriesStoreTo, setCategoriesStoreTo] = useState(initialState.categoriesStoreTo);
	const [version, setVersion] = useState("0.9.2");
	const [mapping, setMapping] = useState(null);

	useEffect(() => {
		initialState._isMounted = true;

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		if(masterVendorSelected){
			let brandInfo = brandsStoreTo.find(bf => {
				if( bf.id === masterVendorSelected.value)	return bf
			})
			
			items.forEach(o => {
				o.vendorNewItem =brandInfo.name
				o.prefix = brandInfo.prefix
			})
		}else{
			items.forEach(o => {
				delete o.vendorNewItem
				delete o.prefix
			})
		}

		setItems(items.slice(0))
	}, [masterVendorSelected])

	useEffect(() => {
		items.forEach(o => {
			o.shopify_collection = masterCollectionSelected? masterCollectionSelected : initialState.masterCollectionSelected
		})
		setItems(items.slice(0))
	}, [masterCollectionSelected])

	useEffect(() => {
		console.info('DEBUG', [storeFrom, itemsValid]);
		
		if(storeFrom && itemsValid.length > 0)
			getItems(storeFrom, itemsValid);
	}, [storeFrom, itemsValid])

	useEffect(() => {
		specialInit();
		modaleCreateItem(true);
	}, [categoriesStoreTo, brandsStoreTo, storeFrom, storeTo, itemsValid, items, submited, itemsCreated, masterVendorSelected, masterCollectionSelected])

	useEffect(() => {
		if(storeTo){
			fetchMenu( {
				"shop": storeTo
			}, () => {

			}, (r) => {
				if(!initialState._isMounted)	return false

				if(r.success){
					console.log("Fetched Categories for " + storeTo, r);
					let results = r.data
					let menu = menuOrganized(results)
		
					setCategoriesStoreTo(menu)
				}
			});

			fetchBrands( storeTo, {}, () => {
				props.loading(true);
			}, (r) => {
				if(!initialState._isMounted)	return false
				props.loading(false);
				if(r.success){
					console.log("Fetched brands for " + storeTo, r);
					let results = r.data
					setBrandsStoreTo(results)
				}
			});
		}
	}, [storeTo])

	/* useEffect(() => {
		if(!storeTo)	return
		try {
			getCategoriesForStore(storeTo)
		} catch (error) {
			console.warn(error)
		}
	}, [itemReadyForStoreSelected]) */

	/* let fetchCategoriesForStore = (shopId, callbackPresence) => {
		ajaxPerso( {
			"api": "eio",
			"trigger": "getMenu",
			"shop": shopId,
			"groupBy":"id",
			"noLevel1": true,
			"orderBy": "name"
		}, callbackPresence);
	} */

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				let fncName = capitalizeFLetter(key)
				if("_isMounted" != fncName)
					console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	/* let getCategoriesForStore = (shopId) => {
		if(!shopId)
			throw("Please indicate a shop ID.");
		else
			fetchCategoriesForStore(shopId, (r) => {
				if(!initialState._isMounted)	return false

				if(r.success){
					setCategoriesStoreTo(r.data || [])
				}else
					props.info({"error": r.error})
			})
	} */
	
	let createItem = (id_store_from, id_store_to) => {

		let itemsSelected = props.itemsSelected
		if(itemsSelected.length === 0)
			return false

		findWherePosted({
			"idList": itemsSelected.join(',')
		}, (r) => {
			if(!initialState._isMounted)	return false

			console.info('Fetched presence:', r);
			if(r.success){

				let listMarketAv = getStoreWhereNeeded(r.data || [])
				let itemsOutList = [];
				
				if(listMarketAv && listMarketAv[id_store_to]){
					let itemsFrom = listMarketAv[id_store_from], // Can be undefined if all items selected are posted in the marketplace from.
						itemsTo = listMarketAv[id_store_to]

					console.info('itemsFrom', itemsFrom);
					console.info('itemsTo', itemsTo);
					
					// Make sure to keep items that need to be created to the marketplace selected and that are also present from the marketplace from.
					itemsTo.forEach(idItemTo => {
						if(!itemsFrom || itemsFrom.indexOf(idItemTo) === -1)	itemsOutList.push(idItemTo)
					})
				}

				setStoreFrom(id_store_from)
				setStoreTo(id_store_to)
				setItemsValid(itemsOutList)
			}
		})

		modaleCreateItem(false);
	}

	let copyValueDown = (posFrom, oToCombine) => {

		items[posFrom+1] = Object.assign(items[posFrom+1], oToCombine)
		
		setItems( items.slice(0) )
	}

	let modaleCreateItem = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: <span>Create item <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);

				let objPreSelected = refConst.shopList.find(obj => {
					if(obj.storeId === storeTo)
						return obj
				})
				
				return storeTo === null? <Loading loading={true} centered={true}/> : <div>
					<p className="h5 mb-4">Item compatible for the selected marketplace: {itemsValid.length}</p>

					<div>
						<table className="table">
							<thead>
								<tr>
									<th scope="col" className="bg-info text-white">Id</th>
									<th scope="col" className="bg-info text-white">Brand</th>
									<th scope="col" className="bg-info text-white">SKU</th>
									<th scope="col" className="bg-info text-white">Title</th>
									<th scope="col" className="bg-info text-white">Category</th>
									<th scope="col" className="bg-secondary text-white">Vendor</th>
									<th scope="col" className="bg-secondary text-white">Category</th>
									{/* {submited? <th scope="col" className="bg-primary text-white">Status</th> : null} */}
								</tr>
							</thead>
							{
								items.length > 0?
									<tbody>
										<tr>
											<td colSpan={5}></td>
											<td>
												{
													<Select
														isClearable
														styles={refConst.styleList}
														onChange={(selectedOption) => {
															setMasterVendorSelected(selectedOption? selectedOption : initialState.masterVendorSelected)
														}}
														options={(() => {
															return brandsStoreTo.map(b => {
																return {
																	value: b.id,
																	label: b.prefix? `${b.name} (${b.prefix})` : b.name
																}
															})
														})()}
														value={[masterVendorSelected? {value: masterVendorSelected.value, label: masterVendorSelected.label} : initialState.masterVendorSelected]}
														isSearchable={true}
														isMulti={false}
														placeholder={"Master Vendor"}
													/>
												}
											</td>
											<td>
												{
													<Select
														isClearable
														styles={refConst.styleList}
														onChange={(selectedOption) => {
															setMasterCollectionSelected(selectedOption? selectedOption.value : initialState.masterCollectionSelected)
														}}
														options={(() => {
															let valueReturn = []
															categoriesStoreTo.forEach(m => {
																valueReturn.push( {
																	value: m.last.name,
																	label: m.inline
																})
															})
															return valueReturn
														})()}
														value={[masterCollectionSelected? {value: masterCollectionSelected, label: masterCollectionSelected} : initialState.masterCollectionSelected]}
														isSearchable={true}
														isMulti={false}
														placeholder={"Master Collection"}
													/>
												}
											</td>
										</tr>
										{
											items.map((o, ii) => {
												let brandObj = brandsStoreTo.find(b => {
													if(b.name == o.vendor)	return	b
												})

												o.prefix = brandObj? brandObj.prefix : null; // brandObj && brandObj.prefix? o.sku_in_store.indexOf(brandObj.prefix) !== -1? o.sku_in_store.substr(brandObj.prefix.length) : o.sku_in_store : o.sku_in_store
												return	<tr key={`${o.id}_itemCreate`}>
													<th scope="row">{o.id}</th>
													<td>{o.vendor}</td>
													<td>{o.sku_in_store}</td>
													<td className="w-25 overflow-auto"><div style={{height: "30px"}}><a href={o.view_item_url_natural} target="_blank" rel="noopener noreferrer">{o.title}</a></div></td>
													<td>{o.category_name}</td>
													<td className="p-0 align-middle">
														<div className="d-flex flex-row align-items-stretch h-100">
															{
																ii+1 <= items.length-1 && o.vendorNewItem? <button className="btn" onClick={() => copyValueDown(ii, {
																	vendorNewItem: o.vendorNewItem,
																	prefix: o.prefix
																})}>
																	<FontAwesomeIcon icon={faArrowCircleDown} style={{}} title={"Copy the value down"} />
																</button> : ''
															}
															{
																<Select
																	isClearable
																	styles={refConst.styleList}
																	onChange={(selectedOption) => {
																		console.info('selectedOption', selectedOption);
																		
																		if(selectedOption){
																			let brandInfo = brandsStoreTo.find(bf => {
																				if( bf.id === selectedOption.value)	return bf
																			})
																			o.vendorNewItem = brandInfo.name
																			o.prefix = brandInfo.prefix
																			setBrandsStoreTo(brandsStoreTo.slice(0))
																		}else{
																			delete o.vendorNewItem
																			delete o.prefix
																			setItems(items.slice(0))
																		}
																	}}
																	options={(() => {
																		return brandsStoreTo.map(b => {
																			return {
																				value: b.id,
																				label: b.prefix? `${b.name} (${b.prefix})` : b.name
																			}
																		})
																	})()}
																	value={o.vendorNewItem? [{value: o.vendorNewItem, label: o.vendorNewItem}] : null}
																	isSearchable={true}
																	isMulti={false}
																	placeholder={"Vendor"}
																/>
															}
														</div>
													</td>
													<td className="p-0 align-middle">
														<div className="d-flex flex-row align-items-stretch h-100">
															{
																ii+1 <= items.length-1 && o.shopify_collection? <button className="btn" onClick={() => copyValueDown(ii, {
																	shopify_collection: o.shopify_collection
																})}>
																	<FontAwesomeIcon icon={faArrowCircleDown} style={{}} title={"Copy the value down"} />
																</button> : ''
															}
															{
																<Select
																	isClearable
																	styles={refConst.styleList}
																	onChange={(selectedOption) => {
																		if(selectedOption)
																			o.shopify_collection = selectedOption.value
																		else
																			delete o.shopify_collection
																		setItems(items.slice(0))
																	}}
																	options={(() => {
																		let valueReturn = []
																		categoriesStoreTo.forEach(m => {
																			valueReturn.push( {
																				value: m.last.name,
																				label: m.inline
																			})
																		})
																		return valueReturn
																	})()}
																	value={o.shopify_collection? [{value: o.shopify_collection, label: o.shopify_collection}] : null}
																	isSearchable={true}
																	isMulti={false}
																	placeholder={"Collection"}
																/>
															}
														</div>
													</td>
													{/* {
														submited?
															<td>
																{
																	Object.keys(itemsCreated).indexOf(o.sku_in_store) !== -1? <div>
																		<button type="button" className="btn btn-primary btn-sm bg-secondary text-white mb-1"><a href={"https://" + objPreSelected.shopName + ".myshopify.com/admin/products/" + itemsCreated[o.sku_in_store].id} className="text-white" target="_blank" rel="noopener noreferrer">Admin</a></button>
																		<button type="button" className="btn btn-primary btn-sm bg-secondary text-white"><a href={"https://" + objPreSelected.shopName + ".myshopify.com/products/" + itemsCreated[o.sku_in_store].handle} className="text-white" target="_blank" rel="noopener noreferrer">Store</a></button>
																	</div> : 'Something Wrong'
																}
															</td> : null
													} */}
												</tr>
											})
										}
									</tbody>
									: null
							}
						</table>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
				setSubmited(false)
			}
			, ok: !submited? {
				title: "Validate",
				fnc: (popup, close) => {
					// props.loading(true)
					setSubmited(true)

					let objToSend = []
					items.forEach( o => {
						console.info('o.prefix', o);
						
						if(o.shopify_collection && o.vendorNewItem)
							objToSend.push( {
								upc: o.upc,
								price: o.price,
								sku_in_store: o.prefix && o.sku_in_store.indexOf(o.prefix) !== -1? o.sku_in_store.substr(o.prefix.length) : o.sku_in_store,
								description: o.description,
								title: o.title,
								weight: o.weight,
								weight_unit: o.weight_unit,
								vendor: o.vendorNewItem,
								picture_url: o.picture_url,
								shopify_collection: o.shopify_collection,
								prefix: o.prefix? o.prefix.toUpperCase() : null
							})
					})
					console.info('Items_send', objToSend);
					//return false;

					reqCreatItems(storeTo, objToSend, (r) => {
						if(!initialState._isMounted)	return false
						props.loading(false)

						/* let success = [],
							error = [],
							itemsCreatedList = {};

						console.info('Fetch Req Create Item', r);

						if(r.data.updated.length > 0){
							r.data.updated.forEach(iu => {
								itemsCreatedList[iu.idReceived] = {handle: iu.handle, id: iu.id_response}
							})

							setItemsCreated(itemsCreatedList)

							success.push("Items inserted ("+Object.keys(itemsCreatedList).length+"): ")
							success.push(Object.keys(itemsCreatedList).join(', '))
						}

						if(r.data.notUpdated)		error.notUpdated(r.data.notUpdated)

						props.info({success: success, error: error}) */
					})
					// resetPage()
					// popup.modal('hide')
				}
			} : false, return: {
				title: "Return",
				fnc: (popup, close) => {
					setSubmited(false)
					resetPage()
					close()
				}
			}
		});
	}

	let specialInit = () => {
		/**
		 * This fnc has for goal to do some loading depending which store was selected.
		 */
		if(!storeFrom || !storeTo)	return;

		if(storeFrom === refConst.id_store_hausfTools && storeTo === refConst.id_store_eio){
			fetchMapping( "category_haus_to_eio", (res) => {
				console.info(`Fetched fetchMapping for category_haus_to_eio`, res);
				if(res.success){
					setMapping(res.data[0].data);
				}
			})
		}
	}

	let reqCreatItems = (storeId, itemList, callbackPresence) => {
		if(!storeId || !itemList)
			throw("Please indicate a storeId / itemList.");
		else{
			let ctnItem = itemList.length,
				i = 0;

			console.info('ctnItem', ctnItem);
			
			var intSend = setInterval(function(){
				console.info('itemList[i]', itemList[i]);
				console.info('ctnItemctnItem', ctnItem);
				if(ctnItem <= 0){
					console.info('ctnItem <= 0');
					clearInterval(intSend)
					return false;
				}else{
					console.info('Has to send socket'); // 67099
					
					socketPerso("task", {
						url: refConst.urlServer,
						// token: refConst.tokenEio,
						internalReq: {
							"api": "shopify",
							"trigger": "bulkCreateItems",
							"shop": storeId,
							"itemList": JSON.stringify([itemList[i]])
						},
						task: {
							op: "insert",
							type: "shopify_product",
							store: storeId,
							args: {
								uid: props.getUser().uid,
								name: `Create Product for ${itemList[i].sku_in_store}`
							}
						}
					})
					ctnItem--;
					i++;
				}

			}, Math.floor(1000)) // Shopify limit 2 requests for 1 seconde, so half second 500 Milliseconds

			/* ajaxPerso( {
				"api": "shopify",
				"trigger": "bulkCreateItems",
				"shop": storeId,
				"itemList": JSON.stringify(itemList)
			}, callbackPresence); */
		}
	}

	let fetchItems = (storeId, idProductList, callbackPresence) => {
		if(!storeId || !idProductList || idProductList.length === 0)
			throw("Please indicate a list of product ID.");
		else{

			let apiName = false;
			apiName = [refConst.id_store_eBay_EIO].indexOf(storeId) !== -1? "ebay" : false
			if(!apiName) apiName = [refConst.id_store_eio, refConst.id_store_hausfTools].indexOf(storeId) !== -1? "shopify" : false
			if(!apiName) apiName = [refConst.id_store_amazon_EIO, refConst.id_store_amazon_GOTT].indexOf(storeId) !== -1? "amazon" : false
			if(!apiName) apiName = [refConst.id_store_walmart_eio].indexOf(storeId) !== -1? "walmart" : false

			ajaxPerso( {
				"api": apiName,
				"trigger": "getItems",
				"shop": storeId,
				"idProductListJson": JSON.stringify(idProductList),
			}, callbackPresence);
		}
	}

	let getItems = (storeId, idProductList) => {
		fetchItems(storeId, idProductList, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.info('Fetched Ebay Items', r);
				setItems(r.data.map(o => {
					o.shopify_collection = null
					return o
				}))
			}else{
				props.info({"error": r.error})
			}
		})
	}
	

	let getStoreWhereNeeded = (itemInStore) => {

		/**
			Return {
				idStore1: [idItemToCreate], idStore2: [idItemToCreate] ...
			}
		*/

		let listStoreAllowedToCreate = {};

		itemInStore.forEach(o => {
			props.stores.forEach(s => {
				if(o.list_id_store.length > 0){
					// not existing yet in this store
					if(o.list_id_store.indexOf(s.id) === -1){
						if(!listStoreAllowedToCreate[s.id])	listStoreAllowedToCreate[s.id] = []
						listStoreAllowedToCreate[s.id].push(o.id)
					}
				}else{
					if(!listStoreAllowedToCreate[s.id])	listStoreAllowedToCreate[s.id] = []
					listStoreAllowedToCreate[s.id].push(o.id)
				}
			})
		})

		console.info('itemInStore', itemInStore);
		console.info('listStoreAllowedToCreate', listStoreAllowedToCreate);
		return listStoreAllowedToCreate
	}

	let storeFromToNames = () => {
		if(props.stores.length === 0)	return ""
		
		let storeTo, storeFrom = null;
		storeTo = props.stores.find(o => {
			if(o.id === props.storeTo)	return o
		})
		storeFrom = props.stores.find(o => {
			if(o.id === props.storeFrom)	return o
		})

		return storeFrom.name + " -> " + storeTo.name
	}

	return (
		<div className="list-group-item list-group-item-action p-2 text-center" onClick={() => createItem(props.storeFrom, props.storeTo)}>
			{
				storeFromToNames()
			}
		</div>
	);
}