// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { capitalizeFLetter, ajaxPerso, clipboard } from '../../fnc'
import $ from 'jquery';
import wiziwig from './wiziwig'
const refConst = require("../../constants.js").v

export default function WarrantyPages(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // 	admin_access
		"-NWsHL4ps0kprpxuohoL" // 	warranty_page_creator
	]

	const initialState = {
		selectedBrand: null
	}
	
	const [version] = useState("0.2.1");
	const [data, setData] = useState(null);
	const [refreshView, setRefreshView] = useState(0);
	const [selectedBrand, setSelectedBrand] = useState(initialState.selectedBrand);

	/* useEffect(() => {
		loadData()
	}, []) */

	useEffect(() => {
		if(!selectedBrand && data)
			setSelectedBrand(data.brandRequiredToCreate[0])
		modalSheet(true);
	}, [data, selectedBrand, refreshView])

	useEffect(() => {
		wiziwig("#editor_warranty_page", "Write the description here")

		/* $(document).off('show.bs.modal').on('show.bs.modal', '.modal', function() {
			const zIndex = 1040 + 10 * $('.modal:visible').length;
			// console.info("showModal", $(this));
			$(this).css('z-index', zIndex);
			setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
		}); */

		/**
		 * Solve the issue of: when clicking on the x to close the submodal from the menu "link", it originally closed the main modal.
		 */
		$('.link-dialog [data-dismiss="modal"]').off('click').on('click', function(e) {
			e.preventDefault();
			// console.info("hideModal", $(this));
			$(this).parents(".link-dialog").modal("hide")
			return false;
		});
	})

	let init = () => {
		loadData()
		modalSheet(false)
	}

	let loadData = () => {
		fetchWarrantyPages({
			shop: refConst.id_store_hausfTools,
		}, (r) => {
			props.loading(false)
			console.info(`Fetched fetchWarrantyPages`, r)
			setData(r.success? r : null)
		})
	}

	let fetchWarrantyPages = (args, callbackItem) => {
		
		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "get_vendor_list_without_warranty_page",
		}, args), callbackItem, null, {
			cache: false
		});
	}

	let submitNewPage = (args, callbackItem) => {
		
		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "create_page",
		}, args), callbackItem);
	}

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				// const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let modalSheet = (refresh) => {

		props.modal({
			show: refresh? false : true,
			title: <span>Warranty Page Creation <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			html: (popup) => {

				return <div className="text-center">
					<form onSubmit={ (e) => { submitSearch(e); 	e.preventDefault(); } }>
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<div className="input-group-text">Select the brand to create</div>
							</div>
							<select className="form-control" onChange={(e) => {
								if(!$("#editor_warranty_page").summernote('isEmpty'))
									$("#editor_warranty_page").summernote("code", "");
								setSelectedBrand(JSON.parse(e.currentTarget.value))
							}}>
								{
									data? data.brandRequiredToCreate.map(v => {
										return <option key={`${v.title}_brandRequiredToCreate`} value={JSON.stringify(v)}>{v.vendor}</option>
									}) : ""
								}
							</select>
						</div>
						<div className="alert alert-primary" role="alert">
							the title and handle will follow our special format.
						</div>
						<div className="text-left">
							<textarea type="text" className="form-control" id={"editor_warranty_page"}></textarea>
						</div>
					</form>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: {
				title: "Submit",
				fnc: (popup, close) => {

					props.loading(true)
					
					let val = $("#editor_warranty_page").val()
					if(!val || !selectedBrand)	return;

					console.info("TESTSSS", {
						title: selectedBrand.title,
						handle: selectedBrand.handle,
						body: val,
					});

					submitNewPage({
						shop: refConst.id_store_hausfTools,
						data: JSON.stringify({
							title: selectedBrand.title,
							handle: selectedBrand.handle,
							body: val,
						})
					}, (r) => {

						console.info("Submited submitNewPage", r);
						props.loading(false)

						if(r.success){

							props.info({success: `Page created [${r.data.page.id}][${r.data.page.title}]`})
							
							if(!$("#editor_warranty_page").summernote('isEmpty'))
								$("#editor_warranty_page").summernote("code", "");
							
							data.brandRequiredToCreate.splice(selectedBrand.pos, 1)
							setSelectedBrand(initialState.selectedBrand)
							setRefreshView(refreshView+1)

						}else
							props.info({error: `Errors: [${JSON.stringify(r.errors)}]`})
					})
				}
			}, return: {
				title: "Exit",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
		// $('#editor_warranty_page').summernote("code", "");
	}
	
	if(!props.securityClearance(props, access))	return "";

	console.info("datadata", data);

	return (
		<button type="button" className={(data? "" : "d-none ") + (props.className || "") +  " btn btn-outline-dark"} onClick={() => init()}>
			<span className="mr-2">Warranty Page</span>
			{data? <span className="badge badge-dark">{data.listVendors.length-data.brandRequiredToCreate.length}/{data.listVendors.length}</span> : ""} 
		</button>
	);
}