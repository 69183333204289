import React, { Component } from 'react';

import $ from 'jquery'; 
import UserList from './ctn/admin/UserList.js';
import UserGraph from './ctn/admin/UserGraph.js';
//import Filter from './ctn/eBay/Filter.js'
//import ItemsTable from './ctn/eBay/ItemsTable.js'
//import Pagination from './ctn/eBay/Pagination.js'
//import Modal from './ctn/eBay/Modal.js'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import DB from '../db.js'
import Select from 'react-select';
import {ajaxPerso} from "../fnc.js"
const refConst = require("../constants.js").v

class Admin extends Component {
	constructor(props) {
		super(props);
		
		if(!this.props.securityClearance(this.props))	return;

		this.state = {
			users: [],
			permissions: []
		};		

		this.defaultLevel = "user"

		//this.sku = React.createRef()

		// Bind Allow access by "this" into the function
		this.loadUsers = this.loadUsers.bind(this);
		// this.changeLevel = this.changeLevel.bind(this);
		this.changeLevel_v2 = this.changeLevel_v2.bind(this);
		this.getUsers = this.getUsers.bind(this);
	}

	/* componentWillMount() {
		this.initialState = this.state
	} */

	componentDidUpdate(prevProps){
	}

	componentDidMount() {
		this.loadUsers();
		this.loadPermission();
		//this.updateItem();
	}

	loadUsers(){
		let self = this
		DB.load("users/", {
		}, (data, nbr) => {
			let tab = [],
				dv = data.val()

			// console.info('Users list', dv);
			
			for (const key in dv)
				if (dv.hasOwnProperty(key)){
					const obj = dv[key];
					obj.uid = key; // Important to base on the real Firebase key, the other one save in the object can be wrong
					obj.level = ("level" in obj? obj.level : this.defaultLevel)
					tab.push(obj)
				}

			console.info(tab);
			self.setState({"users": tab})
		});
	}

	loadPermission(){
		let self = this
		DB.load("permissions/", {
		}, (data, nbr) => {
			let listOut = [],
				dv = data.val()

			for (const key in dv)
				if (dv.hasOwnProperty(key)){
					const obj = dv[key];
					listOut.push(obj);
				}

			self.setState({permissions: listOut})
		});
	}

	/* changeLevel(uid, newPermUid){
		console.info(newPermUid);

		if(level == newLevel){
			console.warn("Same level choosen");
			return false;
		}

		let self = this
		
		DB.load("permissions/"+uid, {
			'once': true
		}, (data) => {
			let dv = data.val()
			dv.level  = newLevel;
			dv.uid  = uid; // We base on the real key generated by firebase
			DB.add(dv, "users/", (e, key) => {
				if(!e)
					self.props.info({"success": "User privilege updated."})
			})
		});
	} */

	changeLevel_v2(uid, newPermUid){
		let self = this

		if(!newPermUid) newPermUid = [];

		ajaxPerso({
			"api": "account",
			"trigger": "changeUserPermission",
			"uidUser": uid,
			"listUidPermissions": newPermUid.map(o => {
				return o.value
			}).join(',')
		}, function( r ) {
			console.info('Triggered: changeUserPermission', r);
			if(r.error)
				console.warn(r.error);
			else if(r.success)
				self.props.info({success: "Updated"})
		})
	}

	getLevel(uid){

		if(!uid)
			return;

		let users = this.state.users,
			level = ''

		users.forEach((obj) => {
			if(obj.uid === uid)
				level = ("level" in obj? obj.level : this.defaultLevel)
		})

		return level
	}

	getUsers(uid){
		
		if(typeof uid !== "undefined"){
			let users = this.state.users
			
			for (let index = 0; index < users.length; index++) {

				if( users[index].uid == uid )
					return users[index];
			}

		}else
			return this.state.users;
	}

	/*triggerChangeLevel(uid){

		if(!uid)
			return;

		let users = this.state.users

		users.forEach((obj) => {
			if(obj.uid === uid)
				users
		})
	}*/

	render() {
		if(!this.props.securityClearance(this.props))	return "";
		return (
			this.props.parent.match.params.idUser && this.state.users.length > 0?
				<UserGraph 
					{...this.props}
					getUsers={this.getUsers}
					getLevel={this.getLevel}
					getLevels={this.props.getLevels}
					match={this.props.parent.match}
					loading={this.props.loading}
					userData={this.getUsers(this.props.parent.match.params.idUser)}
					permissions={this.state.permissions}
				/>
				:
				<UserList 
					{...this.props}
					getUsers={this.getUsers} 
					getLevel={this.getLevel} 
					getLevels={this.props.getLevels} 
					match={this.props.parent.match}
					loading={this.props.loading}
					changeLevel={this.changeLevel_v2}
					permissions={this.state.permissions}
				/>
		);
	}
}

export default Admin;
