import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select'
import {ajaxPerso} from "../../fnc.js"
const refConst = require("../../constants.js").v

class BrandList extends Component {
	constructor(props) {
		super(props);

		this._isMounted = false
		this.state = {
			brands: [],
			selected: null,
			reqName: null
		};
		
		// Bind Allow access by "this" into the function
		this.addBrand = this.addBrand.bind(this);
		this.loadDataReq = this.loadDataReq.bind(this);
	}

	componentDidMount() {
		this.loadDataReq(this.props, true)
	}

	componentDidUpdate(prevProps) {
		// this.loadDataReq(prevProps)
	}

	loadDataReq(prevProps, force){
		console.info(arguments);
		
		if(!this.props.reqName || (!force && prevProps.reqName === this.props.reqName))
			return false

		console.info("Req launched");
		let self = this
		ajaxPerso( {
			"trigger": this.props.reqName,
			"api": this.props.api,
		}, function( data ) {
			console.info('Fetched ' + self.props.reqName, data);
			
			if(this._isMounted)	return false

			if(data.body){
				self.state.brands = data.body
				self.setState(self.state);
			}
		});
	}

	addBrand(e){
		let valBrand = document.querySelector("#inputBrand").value

		let self = this
		ajaxPerso( {
			"trigger": "insertBrandInDb",
			"api": refConst.eioApi,
			"data": JSON.stringify({
				table: "brand",
				calumn: "name",
				data: valBrand
			})
		}, function( data ) {
			if(this._isMounted)	return false

			if(data.lastInsertId){
				self.state.brands.push({"id": data.lastInsertId, "name": valBrand})
				self.setState(self.state);
			}
		});
	}

	
	render() {
		return (
			<div className={(this.props.hidden? "d-none" : "") + ""}>
				<p>If the brand is not in the right list, Please add it by the left input.</p>
				<div className="input-group mb-3 d-flex justify-content-star">
					<input type="text" className={(this.props.hasInput? "" : "hide" ) + " form-control"} aria-label="Text input with dropdown button" id="inputBrand"></input>
					<Select
						placeholder={"placeholder" in this.props? this.props.placeholder : "Select a brand"}
						isClearable
						styles={{
							control: (base) => ({
								...base,
								width: 200
							})
						}}
						onChange={(selectedOption) => {
							this.setState({selected: selectedOption? selectedOption.value : null});
							this.props.changeBrand(selectedOption? selectedOption.value : null)
						}}
						options={
							this.state.brands.map(obj => {
								return {value: obj, label: obj.name}
							})
						}
					/>
					<span className={(this.state.selected? "" : "hide") + "bg-primary ml-3 p-2 text-light align-middle"}>{this.state.selected? this.state.selected.name : ''}</span>
				</div>
			</div>
		);
	}
}

export default BrandList