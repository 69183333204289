import React, { Component } from 'react';
import $ from 'jquery'; 
import {
	Link
} from 'react-router-dom';
import {ajaxPerso} from "../fnc.js"
const refConst = require("../constants.js").v


class Graphique extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stores: {

			},
			idStoreSelected: this.props.parent.match.params.idStore || null,
			storeData: null,
			brands: null,
			brandSelected: null,
			nbrLine: null
		};

		// Bind Allow access by "this" into the function
		this.addStoresView = this.addStoresView.bind(this);
		this.showSkuForStoreView = this.showSkuForStoreView.bind(this);
		this.loadStoreData = this.loadStoreData.bind(this);
		this.addBrandView = this.addBrandView.bind(this);
	}

	componentDidUpdate(prevProps){
		/*
		if(this.props.parent.match.params.idStore && prevProps.parent.match.params.idStore == this.props.parent.match.params.idStore){
			console.info('idStoreSelected Changed');
			//this.setState(Object.assign(this.state, {"idStoreSelected": this.props.parent.match.params.idStore}))
			//this.loadStoreData()
		}*/
	}

	componentDidMount() {
		let self = this;

		if(!this.props.securityClearance(this.props))	return "";

		ajaxPerso({
			"trigger": "getDbStoreDiff",
			"api": refConst.eioApi
		}, function(res){
			let objStore = {};
			if(res.success){
				res.data.forEach(line => {
					objStore[line.name] = {"qty": line.qty, "id": line.id}
				});

				self.setState({"stores": objStore})
			}
		});
		
		if(this.props.parent.match.params.idStore){
			this.loadStoreData(this.props.parent.match.params.idStore)
		}

		ajaxPerso( {
			"trigger": "formBrand",
			"api": refConst.eioApi
		}, function( data ) {
			if(data.body){
				self.setState({"brands": data.body});
			}
		});
	}

	loadStoreData() {
		let self = this;

		ajaxPerso({
			"url": refConst.eioFilePath,
			"idStoreSelected": this.state.idStoreSelected,
			"trigger": "getDbStoreSku",
			"limit": parseInt(this.state.nbrLine, 10) || 100,
			"page": 0,
			"brand": this.state.brandSelected || false
		}, function(res){
			if(res.success){
				self.setState({"storeData": res.data})
			}
		});
	}
	

	addStoresView() {
		let stores = this.state.stores,
			tabOut = []
		
		if(!stores || this.props.parent.match.params.idStore)
			return ""

		for (const storeName in stores) {
			if (stores.hasOwnProperty(storeName)) {
				const store = stores[storeName];
				tabOut.push(<li key={storeName} className="list-group-item d-flex justify-content-between align-items-center">
					<Link to={`/graphique/` + store.id } onClick={() => { this.setState({"idStoreSelected": store.id}, this.loadStoreData); } }>{storeName}</Link>
					<span className="badge badge-primary badge-pill">{store.qty}</span>
				</li>)
			}
		}
		return <ul className="list-group">{tabOut}</ul>
	}

	addBrandView() {
		let brands = this.state.brands

		if(brands)
			return brands.map((obj) => {
				return <option key={obj.id} value={obj.id}>{obj.name}</option>
			})
	}

	showSkuForStoreView() {
		if(!this.props.parent.match.params.idStore)
			return ""

		let storeData = this.state.storeData

		if(storeData)
			return <div>
				<div className="d-flex align-items-center flex-row m-3">
					<div className="w-25">
						<label>Brand</label>
						<select className="custom-select mr-sm-2" onChange={(e) => { this.setState({"brandSelected": e.target.value}, this.loadStoreData); }}>
							<option value="">-</option>
							{this.addBrandView()}
						</select>
					</div>
					<div className="w-25 ml-5">
						<label>Number</label>
						<input type="number" className="form-control d-inline" onChange={(e) => { this.setState({"nbrLine": e.target.value}, this.loadStoreData); }} placeholder="Number of line (default: 100)"></input>
					</div>
				</div>
				<table className="table">
					<thead className="bg-dark text-white">
						<tr>
							<th scope="col">ID</th>
							<th scope="col">BRAND</th>
							<th scope="col">SKU</th>
							<th scope="col">TITLE</th>
							<th scope="col">COST</th>
						</tr>
					</thead>
					<tbody>{ storeData.map((obj) => {
						return <tr key={obj.id}>
							<td>{obj.id}</td>
							<td>{obj.brandName}</td>
							<td>{obj.sku}</td>
							<td>{obj.title}</td>
							<td>{obj.cost}</td>
						</tr>
					}) }</tbody></table>
			</div>
	}
	

	render() {
		if(!this.props.securityClearance(this.props))	return "";

		return (
			<div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main ctnPage">
				<h1 className="page-header mb-3">Graphique From Finale</h1>
				{
					this.addStoresView()
				}
				{
					this.showSkuForStoreView()
				}
			</div>
		);
	}
}

export default Graphique;
