import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import Select, { components } from 'react-select';
import { fetchBrands, fetchTags, fetchPackages, capitalizeFLetter } from '../../../fnc'
import SheetCapture from '../SheetCapture';
const refConst = require("../../../constants.js").v

var initialState = {
	brands: [], // List
	tags: [],
	tagSelected: [],
	packages: [],

	localTagSelected: null,
	localBrandSelected: null,
	localPackageSelected: null,
	_isMounted: false
};

export default function Filter(props){

	var operators = [
		">",
		">=",
		"<",
		"<=",
		"="
	]

	let varToReset = [
		'localTagSelected',
		'localBrandSelected',
		'localPackageSelected',
	];

	const [localTagSelected, setLocalTagSelected] = useState(initialState.localTagSelected);
	const [localBrandSelected, setLocalBrandSelected] = useState(initialState.localBrandSelected);
	const [localPackageSelected, setLocalPackageSelected] = useState(initialState.localPackageSelected);
	
	const [tags, setTags] = useState(initialState.tags.slice(0));
	const [brands, setBrands] = useState(initialState.brands.slice(0));
	const [packages, setPackages] = useState(initialState.packages.slice(0));

	useEffect(() => {
		initialState._isMounted = true;

		fetchBrands(refConst.id_store_finale, {}, null, (r) => {
			if(!initialState._isMounted)	return false
			console.log("Fetched brands for " + refConst.id_store_finale, r);
			if(r.success){
				let results = r.data
				setBrands(results)
			}
		})
		fetchTags(null, null, (r) => {
			if(!initialState._isMounted)	return false
			console.log("Fetched tags for " + refConst.id_store_finale, r);
			if(r.success){
				let results = r.data
				setTags(results)
			}
		})
		fetchPackages(null, (r) => {
			if(!initialState._isMounted)	return false
			console.log("Fetched packages for " + refConst.id_store_finale, r);
			if(r.success){
				let results = r.data
				setPackages(results)
			}
		})
		return () => initialState._isMounted = false
	}, [])

	const resetForm = (r) => {
		varToReset.map(v => {
			console.info('Eval result', eval("set" + capitalizeFLetter(v))( JSON.parse(JSON.stringify(initialState[v])) ));
		})
	}

	useEffect(() => {
		if(!props.vari.vendorSelected && initialState._isMounted) setLocalBrandSelected(initialState.brandSelected)
	}, [props.vari.vendorSelected])

	var randomId = Math.random();
	var rightCtnEnabled = props.rightCtn? true : false
	
	return (
		<div className={(rightCtnEnabled? "d-flex flex-wrap justify-content-center " : "") + " bg-light p-3 border"}>
			<form ref={props.formFilter} className={rightCtnEnabled? "mr-3 pr-3 border-right mb-5" : ""} onSubmit={(e) => {
				e.preventDefault()

				props.setStateSafe({
					'current_page': 1,
					'brandSelected': localBrandSelected,
					'tagSelected': localTagSelected? localTagSelected.value : localTagSelected,
					'packageSelected': localPackageSelected
				}, () => {
					props.onUpdateItem()
				})
			}}>
				<div className="row">
					<div className="col">
						<div className="input-group mb-3">
							<div className="input-group-prepend"><span className="input-group-text">Search</span></div>
							<input type="text" ref={props.search} /* defaultValue={"Heights Clear"} */ className="form-control" placeholder="sku, title, vendor" aria-label="Recipient's username" aria-describedby="button-addon2"></input>
						</div>
					</div>
					{/* <div className="col">
						<div className="input-group mb-3">
							<div className="input-group-prepend"><span className="input-group-text">Items per page</span></div>
							<input type="number" ref={props.qtyPerPage} defaultValue={props.vari.limit_per_page} className="form-control" placeholder="Quantity" aria-label="Quantity" aria-describedby="button-addon2"></input>
						</div>
					</div> */}
					<div className="col">
						<div className="input-group mb-3">
							<div className="input-group-prepend"><span className="input-group-text">Started at</span></div>
							<input type="date" ref={props.startAt} onChange={props.submitSearch} className="form-control" placeholder="Start at" aria-label="Start at" aria-describedby="button-addon2"></input>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<select ref={props.operatorQtyOnHand} style={{borderTopRightRadius: "0px", borderBottomRightRadius: "0px"}} className="custom-select rounded-left" id={"inputGroupSelect" + randomId}>
									{
										operators.map((operator) => {
											return <option key={operator} value={operator}>{operator}</option>
										})
									}
								</select>
							</div>
							<input type="number" min="0" ref={props.qtyOnHand} className="form-control" placeholder="qty on hand" aria-label="Username" aria-describedby="basic-addon1"/>
						</div>
					</div>
					<div className="col">
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<select ref={props.operatorCost} style={{borderTopRightRadius: "0px", borderBottomRightRadius: "0px"}} className="custom-select rounded-left" id={"inputGroupSelect" + randomId}>
									{
										operators.map((operator) => {
											return <option key={operator} value={operator}>{operator}</option>
										})
									}
								</select>
							</div>
							<input type="number" min="0" ref={props.cost} className="form-control" placeholder="Cost" aria-label="Username" aria-describedby="basic-addon1"/>
						</div>
					</div>
					<div className="col">
						<div className="input-group mb-3 d-flex flex-row flex-nowrap">
							<div className="input-group-prepend"><span className="input-group-text">Brand</span></div>
							<Select
								isClearable
								className="noLeftRadius"
								styles={refConst.styleList}
								onChange={(selectedOption) => {
									/* props.setStateSafe({
										'brandSelected': selectedOption.value,
									}) */
									setLocalBrandSelected(selectedOption? selectedOption.value : initialState.localBrandSelected)
								}}
								options={(() => {
									return brands.map(b => {
										return {
											value: b.name,
											label: `${b.name}`
										}
									})
								})()}
								value={localBrandSelected? [{value: localBrandSelected, label: localBrandSelected}] : null}
								placeholder={"Brand"}
								isSearchable={true}
								isMulti={false}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="input-group mb-3 d-flex flex-row flex-nowrap">
							<div className="input-group-prepend"><span className="input-group-text">Tags</span></div>
							<Select
								isClearable
								className="noLeftRadius"
								styles={refConst.styleList}
								onChange={(selectedOption) => {
									setLocalTagSelected(selectedOption? selectedOption : initialState.localTagSelected)
								}}
								options={(() => {
									return tags.map(t => {
										return {
											value: t,
											label: t
										}
									})
								})()}
								value={localTagSelected? [localTagSelected] : null}
								placeholder={"Shopify Tags"}
								isSearchable={true}
								isMulti={false}
							/>
						</div>
					</div>
					<div className="col">
						<div className="input-group mb-3 d-flex flex-row flex-nowrap">
								<div className="input-group-prepend"><span className="input-group-text">Package</span></div>
							<Select
								isClearable
								styles={refConst.styleList}
								className="noLeftRadius"
								onChange={(selectedOption) => {
									console.info('selectedOption', selectedOption);
									
									/* props.setStateSafe({
										'packageSelected': selectedOption,
									}) */

									setLocalPackageSelected(selectedOption? selectedOption : initialState.localPackageSelected)
								}}
								options={(() => {
									return packages.map(t => {
										return {
											value: t.id,
											label: `[${t.carrier_name}] ${t.name}`
										}
									})
								})()}
								value={localPackageSelected? {value: localPackageSelected.value, label: localPackageSelected.label} : null}
								placeholder={"Shipstation Package"}
								isSearchable={true}
								isMulti={false}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="custom-control custom-switch m-3">
							<input type="checkbox" className="custom-control-input" id="customSwitchActive" defaultChecked={true} ref={props.active}/>
							<label className="custom-control-label noselect pointer" htmlFor="customSwitchActive">Active</label>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mt-3">
					<SheetCapture className={"mr-5"} dataSheet={props.dataSheet} onChange={(val) => {
						// props.onUpdateSelectedItemFromSheet(val)
						console.info('OnChange val:', val);
						
						props.setStateSafe({"dataSheet": val})
					}} name={"From Sheet"} {...props} required={['sku']}/>
					<button type="reset" onClick={() => {
						resetForm()
						props.onClearResult()
					}} className="btn btn-lg btn-secondary mr-5">Reset</button>
					<button type="submit" className="btn btn-lg btn-primary">Filter</button>
				</div>
			</form>
			{
				rightCtnEnabled? <div className="flex-fill">
					{ props.rightCtn }
				</div> : ''
			}
		</div>
	);
}

