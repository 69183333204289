// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons' */
import TmpFrame from './ctn/template/TmpFrame';
import TmpDataGroup from './ctn/template/TmpDataGroup';
import TmpList from './ctn/template/TmpList';
import {ajaxPerso} from "../fnc.js"
require('codemirror/lib/codemirror.css');
require('codemirror/theme/monokai.css');
require('codemirror/addon/hint/show-hint.css');

require('codemirror/addon/hint/show-hint.js');
require('codemirror/addon/hint/html-hint.js');
require('codemirror/mode/htmlmixed/htmlmixed');
require('codemirror/keymap/sublime');

const refConst = require("../constants.js").v

function Template(props){
	const params = new URLSearchParams(props.parent.location.search);
	//const [brandsObj, setBrandsObj] = useState([]);
	const [listTemplate, setListTemplate] = useState([]);

	const loadTemplate = (objArg, callback = false) => {
		fetchTemplate( objArg, (r) => {
			props.loading(false)
			console.info('Fetched template:', r);
			if(r.success){
				let results = r.data
				setListTemplate(results)
				
				if(callback)
					callback(results)
			}
		})
	}

	const fetchBrandsObject = (args, callback) => {
		
		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getBrandTemplate"
		}, args), callback);
	}

	const fetchTmpGroupUsed = (args, callback) => {
		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getTemplateGroupUsed"
		}, args), callback);
	}

	const fetchTemplate = (args, callback) => {
		
		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getTemplate",
			"data": JSON.stringify(args || {})
		}, args), callback);
	}

	const dataGroupSection = () => {
		
		return params.get("mode") === "tmpdatagroup"?
			<TmpDataGroup
				{...props}
				listTemplate={listTemplate}
				fetchBrandsObject={fetchBrandsObject}
				loadTemplate={loadTemplate}
				fetchTmpGroupUsed={fetchTmpGroupUsed}

			/>
			: ""
	}

	const dataFrameSection = () => {
		console.log('params.get("mode")', params.get("mode"));
		
		return params.get("mode") === "tmpframe"? 
			<TmpFrame
				{...props}
				listTemplate={listTemplate}
				loadTemplate={loadTemplate}
			/> : ""
	}

	const listFrameSection = () => {

		return !params.get("mode") || params.get("mode") === "tmplist"? 
			<TmpList
				{...props}
				listTemplate={listTemplate}
				loadTemplate={loadTemplate}
			/> : ""
	}

	if(!props.securityClearance(props))	return "";

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container">
			{ dataGroupSection() }
			{ dataFrameSection() }
			{ listFrameSection() }
		</div>
	);
}

export default Template;