// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
import Select from 'react-select';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import {
	NavLink
} from 'react-router-dom';
import Chart from 'chart.js/auto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import { faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons'
import Filter from './filter'
import Loading from '../Loading';
import {ajaxPerso, fetchValueInventory} from "../../../fnc.js"
const refConst = require("../../../constants.js").v
//var moment = require('moment');

/* const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999,
	}),
	container: (provided) => ({
		...provided,
		width: "100%"
	}),
	control: provided => ({
		...provided,
		margin: 0,
		marginBottom: 0,
		minWidth: "200px"
	})
} */

function DailyValueInventory(props){

	const [version, setVersion] = useState("0.8.0");
	const [loadingOn, setLoadingOn] = useState(true);
	const [colorList, setColorList] = useState([]);
	const [chart, setChart] = useState(null);
	const [historyValueList, setHistoryValueList] = useState([]);

	const [vendorList, setVendorList] = useState([]);
	const [vendorSelected, setVendorSelected] = useState([]);
	
	const [choiceSorting, setChoiceSorting] = useState({});
	const [dateFirstLastEntry, setDateFirstLastEntry] = useState({first_date: null, last_date: null});
	const [fromTo, setFromTo] = useState({
		from: new Date(moment().subtract(1, 'days').format()), // new Date(moment().subtract(3, 'years').format()),
		to: new Date(moment().format())
	});

	const [top, setTop] = useState();
	const [previousSearch, setPreviousSearch] = useState({
		"top": top,
		"vendor": vendorSelected
	});

	useEffect(() => {
		/**
		 * When the first and last entry date are loaded, we set that value for the first request
		 */
		setFromTo({
			from: new Date(moment(dateFirstLastEntry.first_date).format()), // new Date(moment().subtract(3, 'years').format()),
			to: new Date(moment(dateFirstLastEntry.last_date).format())
		});
		if(dateFirstLastEntry.first_date && dateFirstLastEntry.last_date)
			loadValueInventory();
	}, [dateFirstLastEntry])

	useEffect(() => {
		console.log("historyQty", historyValueList);
		
		if(document.querySelector("#graphHistoryInventoryValue") && historyValueList && colorList.length > 0){

			let dataValue = [];
			// let skuList = [];
			let labelBottom = [];
			let itemsGraphData = [];
			let color = false;
			let listLabelSelect = [];
			let brandValue = {};

			for (const date in historyValueList) {
				if (historyValueList.hasOwnProperty(date)) {
					const brandList = historyValueList[date];
					
					labelBottom.push( date )

					for (const brand in brandList) {
						if (brandList.hasOwnProperty(brand)) {

							let currentValue = brandList[brand]

							if(!(brand in brandValue))
								brandValue[brand] = [currentValue]
							else
								brandValue[brand].push(currentValue)
						}
					}
				}
			}

			/* console.info('brandValue', brandValue);
			console.info('labelBottom', labelBottom); */
			
			for (const brand in brandValue) {
				if (brandValue.hasOwnProperty(brand)) {

					color = colorList[itemsGraphData.length]

					// Show the items selected in list but nothing else
					let isVisible = vendorSelected.find(objLabel => {
						if(objLabel.value == brand)
							return objLabel
					})

					itemsGraphData.push({
						label: brand,
						data: brandValue[brand],
						backgroundColor: color,
						fill: false,
						borderColor: color,
						hidden: !isVisible,
						spanGaps: true,
						tension: 0.3,
						stepped: true,
						pointRadius: 1
					})
				}
			}
			
			/**
			 * Show only label and data from selected sku in the list
			 */
			let filtersFnc = (legend, chartData) => {
				if(vendorSelected.find(o => {
					//console.log(o.value + " === " + legend.text + " --- " + (o.value == legend.text));
					if(o.value == legend.text)
						return o
				}))
					return true
				return false
			}

			console.info('itemsGraphData', itemsGraphData);
			
			if(!chart){
				
				console.warn("Create new one");
				
				setChart(new Chart(document.querySelector("#graphHistoryInventoryValue"), {
					type: 'line',
					data: {
						labels: labelBottom,
						datasets: itemsGraphData,
					},
					options: {
						plugins: {
							legend: {
								align: 'center',
								labels: {
									filter: filtersFnc
								}
							}
						},
						aspectRatio: 4,
						// cutoutPercentage: sumCount / 2
					}
				}))
			}else{
				console.warn("Update chart");

				//chart.destroy()
				chart.data = {
					labels: labelBottom,
					datasets: itemsGraphData,
				};
				chart.options.plugins.legend.labels.filter = filtersFnc;
				chart.update();
				setChart(chart)
			}
		}
	}, [historyValueList, vendorSelected, colorList])

	useEffect(() => {
		if(
			previousSearch.top != top
			// || previousSearch.vendor != vendorSelected
		){
			loadValueInventory();
			setPreviousSearch({
				"top": top,
				//"vendor": vendorSelected
			})
		}
	// }, [top, vendorSelected])
	}, [top])

	useEffect(() => {
		loadFirstEntryDate();
	}, [])

	const loadValueInventory = (args, callback) => {
		setLoadingOn(true)

		fetchValueInventory(Object.assign({
			"dateFrom": dateFirstLastEntry.first_date,
			"dateTo": dateFirstLastEntry.last_date,
			"top": top
		}, args), (r) => {
			setLoadingOn(false)
			if(r.success){
				setHistoryValueList(r.res)
				setVendorList(r.vendorList)

				let colors = [];
				for (let i = 0; i < r.vendorList.length; i++) {
					colors.push( "#"+((1<<24)*Math.random()|0).toString(16) );
				}
				setColorList(colors)
			}else{
				props.info({"error": r.error})
			}
		})
	}

	const loadFirstEntryDate = (args, callback) => {
		fetchFirstEntryDate(args, (r) => {
			setLoadingOn(false)
			if(r.success){
				setDateFirstLastEntry(r.res);
			}else{
				props.info({"error": r.res})
			}
		})
	}

	const fetchFirstEntryDate = (args, callback) => {
		ajaxPerso( Object.assign({
			"trigger": "get_daily_inventory_value_date_started",
			"api": "finale",
		}, args), callback);
	}

	const listValue = () => {

		console.log('historyValueList', historyValueList);
		
		return false
		let tempOrderedTab = historyValueList.slice(0);

		for (const key in choiceSorting) {
			if (choiceSorting.hasOwnProperty(key)) {
				tempOrderedTab.sort((o1, o2) => {
					//console.log(o1[key] + " " + choiceSorting[key] + " " +  o2[key]);
					
					if(choiceSorting[key] === "<" && o1[key] < o2[key])	return 1
					if(choiceSorting[key] === ">" && o1[key] > o2[key])	return 1
					return -1
				})
			}
		}

		/* console.info('choiceSorting', choiceSorting);
		console.info('historyQty', tempOrderedTab); */
		
		return <ul className="list-group">
			{
				tempOrderedTab.map((o, index) => {
					return(<li key={o.sku + "_" + o.storeName} className="list-group-item">
						<div className="container">
							{
								(index === 0? <div className="row font-weight-bold mb-3 border-bottom">
									<div className="col-sm" style={{maxWidth: "70px"}}>
										N:
									</div>
									<div className={(choiceSorting.sku? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('sku') === -1)
											choiceSorting.sku = '>'
										else if(choiceSorting.sku === '>')
											choiceSorting.sku = '<';
										else
											delete choiceSorting.sku

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										SKU: { choiceSorting.sku? <FontAwesomeIcon icon={ choiceSorting.sku === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.increase? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('increase') === -1)
											choiceSorting.increase = '>'
										else if(choiceSorting.increase === '>')
											choiceSorting.increase = '<';
										else
											delete choiceSorting.increase

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										increase { choiceSorting.increase? <FontAwesomeIcon icon={ choiceSorting.increase === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.avgPrevWeek? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('avgPrevWeek') === -1)
											choiceSorting.avgPrevWeek = '>'
										else if(choiceSorting.avgPrevWeek === '>')
											choiceSorting.avgPrevWeek = '<';
										else
											delete choiceSorting.avgPrevWeek

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										AVG previous weeks { choiceSorting.avgPrevWeek? <FontAwesomeIcon icon={ choiceSorting.avgPrevWeek === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.weeksCalculated? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('weeksCalculated') === -1)
											choiceSorting.weeksCalculated = '>'
										else if(choiceSorting.weeksCalculated === '>')
											choiceSorting.weeksCalculated = '<';
										else
											delete choiceSorting.weeksCalculated

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Weeks Calculated { choiceSorting.weeksCalculated? <FontAwesomeIcon icon={ choiceSorting.weeksCalculated === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.store? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('store') === -1)
											choiceSorting.store = '>'
										else if(choiceSorting.store === '>')
											choiceSorting.store = '<';
										else
											delete choiceSorting.store

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Store { choiceSorting.store? <FontAwesomeIcon icon={ choiceSorting.store === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
								</div> : "")
							}
							<div className="row">
								<div className="col-sm" style={{maxWidth: "70px"}}>
									{index+1}
								</div>
								<div className="col-sm">
									{o.sku}
								</div>
								<div className="col-sm">
									{o.increase}
								</div>
								<div className="col-sm">
									{
										(function(){
											let sum = 0.0;
											Object.keys(o.weeklyIncrease).forEach((val, index) => {
												sum += o.weeklyIncrease[val]
											})
											//console.info('sum', sum);
											
											return (sum / Object.keys(o.weeklyIncrease).length).toFixed(2)
										})()
									}
									%
								</div>
								<div className="col-sm">
									{o.weeksCalculated}
								</div>
								<div className="col-sm" style={{maxWidth: "120px", overflowY: "auto"}}>
									{o.storeName}
								</div>
							</div>
						</div>
					</li>)
				})
			}
		</ul>
	}

	

	return (
		<div className={props.className + " main ctnPage d-flex flex-column flex-wrap border pr-3 pl-3 pb-3 pt-1 position-relative"}>
			<p className="h5">Daily Value Inventory</p>
			<Filter
				returnDatePiker={true}
				onlyOneDay={false}

				setFromTo={(newFromTo) => {
					setFromTo(Object.assign({}, newFromTo))
				}}
				fromTo={fromTo}

				disabledDays={{ 
					before: new Date(moment(dateFirstLastEntry.first_date? dateFirstLastEntry.first_date : '').local().format('YYYY-MM-DD 00:00:00')),
					after: new Date(moment(dateFirstLastEntry.last_date? dateFirstLastEntry.last_date : '').local().format('YYYY-MM-DD 00:00:00')) 
				}}
				top={top}
				setTop={setTop}
				moreFilters={
					<span className="ml-4">
						<Select
							styles={refConst.styleList}
							onChange={(selectedOption) => {
								console.log(selectedOption);
								setVendorSelected(selectedOption.slice(0))
							}}
							options={(() => {
								return vendorList.map(brand => {
									return {
										value: brand,
										label: brand
									}
								})
							})()}
							value={vendorSelected}
							defaultValue={vendorSelected}
							isSearchable={true}
							isMulti={true}
						/>
					</span>
				}
			/>
			{ dateFirstLastEntry.first_date? <span className="mt-2 font-weight-light">History recorded from: {dateFirstLastEntry.first_date} to {dateFirstLastEntry.last_date}</span> : '' }
			<div className="mt-4" >
				<canvas id="graphHistoryInventoryValue" className="w-100"></canvas>
			</div>
			{/* <div>
				<div className="mt-4" style={{maxHeight: "400px", overflowX: "auto"}}>
					{ listValue() }
				</div>
			</div> */}
			<Loading loading={loadingOn} centered={true} />
			<h6 className="position-absolute text-secondary" style={{right: "15px", bottom: "0px", fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6>
		</div>
	);
}

export default DailyValueInventory;