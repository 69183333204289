// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons' */
import Select from 'react-select';
import EditBrand from './ctn/brand/editBrand';
import ListBrand from './ctn/brand/listBrand';
import {ajaxPerso} from "../fnc.js"

const refConst = require("../constants.js").v

function Brand(props){
	const params = new URLSearchParams(props.parent.location.search);

	const [brands, setBrands] = useState([]);

	useEffect(() => {

	}, [])

	if(!props.securityClearance(props))	return "";

	let idBrand = props.parent.match.params.idBrand,
		ctnOut = ""

	if(params.get("mode") === "add"){
		ctnOut = <EditBrand {...props}/>
	}else if(idBrand)
		ctnOut = <EditBrand {...props}/>
	else
		ctnOut = <ListBrand {...props}/>

	return (ctnOut);
}

export default Brand;