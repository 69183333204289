import { type } from 'jquery';
import React, {
	useMemo, useCallback
} from 'react';
import {
	useDropzone
} from 'react-dropzone';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	wordBreak: 'break-all',
	overflow: 'hidden',
	whiteSpace: "initial"
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

function StyledDropzone(props) {

	var options = {
		//accept: 'image/*',
		accept: 'image/jpeg, image/png, image/gif',
		maxSize: 20000000
	};

	switch (props.type.toLowerCase()) {
	case "img":
		options = {
			//accept: 'image/*',
			accept: 'image/jpeg, image/png, image/gif',
			maxSize: 20000000
		};	
		break;
	case "file":
		options = {
			//accept: 'image/*',
			accept: 'application/pdf',
			maxSize: 20000000
		};	
		break;
	}

	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		// Do something with the files
		if(rejectedFiles.length > 0){
			console.warn(props.type + " rejected:");
			console.warn(rejectedFiles);
			props.info({error: rejectedFiles.map(obj => {
				return "File: " + obj.name + ", size: " + (obj.size / 1000000).toFixed(3) + " MB, Limite size: " + (options.maxSize / 1000000).toFixed(3) + " MB, Status: Refused";
			}).join("")})
		}
		if(acceptedFiles.length > 0){
			console.log(acceptedFiles);
			acceptedFiles.forEach(o => {
				o.typeBin = props.type
			});
			
			console.info("acceptedFiles", acceptedFiles)
			props.setDropzone(acceptedFiles)
		}
	}, [])

	const {
		acceptedFiles,
		rejectedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone(Object.assign({onDrop}, options));

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject
	]);

	return ( 
		<div className = "container" >
			<div {
				...getRootProps({
					style
				})
			}>
				<input {
					...getInputProps()
				}
				/>
				<div style={{"whiteSpace": "initial"}}>Drag or click here to select files</div>
			</div>
		</div>
	);
}

export default StyledDropzone