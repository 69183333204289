// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import Loading from './Loading';
import { capitalizeFLetter, ajaxPerso } from '../../fnc'
import { utils, writeFile } from 'xlsx';
import moment from 'moment';

const refConst = require("../../constants.js").v

export default function DuplicateModal(props){

	
	const apiProperty = [{
		name: "ebay",
		shop: refConst.id_store_eBay_EIO,
		fnc: () => getDuplicate({
			"api": "ebay",
			"shop": refConst.id_store_eBay_EIO
		}),
		val: {
			req: "skuList",
			res: "sku_in_store"
		}
	}, {
		name: "shopify - EIO",
		shop: refConst.id_store_eio,
		fnc: () => getDuplicate({
			"api": "shopify",
			"shop": refConst.id_store_eio
		}),
		val: {
			req: "skuList",
			res: "sku"
		}
	}, {
		name: "shopify - HausOfTools",
		shop: refConst.id_store_hausfTools,
		fnc: () => getDuplicate({
			"api": "shopify",
			"shop": refConst.id_store_hausfTools
		}),
		val: {
			req: "skuList",
			res: "sku"
		}
	}, {
		name: "Amazon - EIO",
		shop: refConst.id_store_amazon_EIO,
		fnc: () => getDuplicate({
			"api": "amazon",
			"shop": refConst.id_store_amazon_EIO
		}),
		val: {
			required: "sku",
			req: "skuList",
			res: "sku_on_amazon"
		}
	}, {
		name: "Amazon - GOTT",
		shop: refConst.id_store_amazon_GOTT,
		fnc: () => getDuplicate({
			"api": "amazon",
			"shop": refConst.id_store_amazon_GOTT
		}),
		val: {
			required: "sku",
			req: "skuList",
			res: "sku_on_amazon"
		}
	}, {
		name: "walmart",
		shop: refConst.id_store_walmart_eio,
		fnc: () => getDuplicate({
			"api": "walmart",
			"shop": refConst.id_store_walmart_eio
		}),
		val: {
			required: "sku",
			req: "skuList",
			res: "sku_on_walmart"
		}
	}]

	const initialState = {
		duplicatePerStoreList: [],
		submited: false
	}
	
	const [duplicatePerStoreList, setDuplicatePerStoreList] = useState(initialState.duplicatePerStoreList);
	const [version, setVersion] = useState("0.5.0");
	const [submited, setSubmited] = useState(initialState.submited);

	useEffect(() => {
		/* apiProperty.forEach(o => {
			o.fnc();
		}) */
	}, [])

	useEffect(() => {
		modalSheet(true);
	}, [props.stores, duplicatePerStoreList])

	/* useEffect(() => {
		if(!props.stores || props.stores.length === 0){
			props.stores.forEach(s => {
				getDuplicate(s.id);
			});
		}
	}, [props.stores]) */


	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let loadAllData = () => {
		apiProperty.forEach(o => {
			o.fnc();
		})
	}

	let fetchDuplicate = (args, callbackPresence) => {
		if(!args)
			throw("Please indicate a api.");
		else
			ajaxPerso( Object.assign({
				"trigger": "get_duplicate",
			}, args), callbackPresence);
	}

	let getDuplicate = (args) => {
		fetchDuplicate(args, (r) => {
			if(r.success){
				console.info(`Fetched Duplicate for ${args.api}`, duplicatePerStoreList);
				duplicatePerStoreList.push({
					shop: args.shop,
					api: args.api,
					data: r.data
				})
				setDuplicatePerStoreList(duplicatePerStoreList.slice(0))
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let downloadTrigger = (dupObj) => {
		var book  = utils.book_new()
		if(Array.isArray(dupObj)){

			dupObj.forEach(o => {
				let sheet = utils.json_to_sheet(o.data);
				utils.book_append_sheet(book, sheet, o.api);
			})
			writeFile(book, `Result_Duplicate_ALL_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
		}else{
			if(!dupObj || dupObj.data.length === 0){
				props.info({"error": `No data for "${dupObj.api}"`})
				return false;
			}
			let sheet = utils.json_to_sheet(dupObj.data);
			utils.book_append_sheet(book, sheet, dupObj.api);
			writeFile(book, `Result_Duplicate_${dupObj.api}_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
		}
	}

	let modalSheet = (refresh) => {
		console.info('duplicatePerStoreList2', duplicatePerStoreList);
		
		props.modal({
			show: refresh? false : true,
			title: <span>Create a xlsx duplicate report <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			html: (popup) => {

				return <div className="text-center">
					{
						duplicatePerStoreList.length === apiProperty.length? <div className="p-3 border border-primary container">
							<div className="row">
								<div className="col-sm">
									Download everything
								</div>
								<div className="col-sm">
									{ (() => {
										let valTotal = 0;
										duplicatePerStoreList.forEach(o => {
											valTotal += o.data.length
										})
										return valTotal;
									})() }
									<button type="button" className="btn btn-primary btn-sm ml-3" onClick={() => downloadTrigger(duplicatePerStoreList)}>Download</button>
								</div>
							</div>
						</div> : ""
					}
					{
						apiProperty.map(o => {
							return <div key={`${o.name}_grpApiDuplicate`} className="border position-relative bg-light mb-3">
								<p className="text-capitalize font-weight-bold">{o.name}</p>
								{
									(() => {
										let foundD = duplicatePerStoreList.find(d => {
											if(d.shop == o.shop)	return d
										})
										if(foundD){
											return <div className="p-3">
												<div className="container">
													<div className="row">
														<div className="col-sm">
															Result count:
														</div>
														<div className="col-sm">
															{ foundD.data.length }
														</div>
													</div>
													<div className="row">
														<div className="col-sm">
															Download
														</div>
														<div className="col-sm">
															<button type="button" className="btn btn-primary btn-sm" onClick={() => downloadTrigger(foundD)}>Download</button>
														</div>
													</div>
												</div>
											</div>
										}else	return <Loading className={"d-block"} loading={true} centered={true}/>
									})()
								}
							</div>
						})
					}
				</div>
			}
			, exit: (popup, close) => {
				close()
				resetPage()
			}
			, ok: null, return: {
				title: "Exit",
				fnc: (popup, close) => {
					close()
					resetPage()
				}
			}
		});
	}
	
	return (
		<button type="button" className={(props.className || "") +  " btn btn-outline-primary"} onClick={() => {
			loadAllData()
			modalSheet(props.storeTo, props.storeFrom)
		}}>
			{ props.name }
		</button>
	);
}