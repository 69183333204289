// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import $ from 'jquery'; 
import {UnControlled as CodeMirror} from 'react-codemirror2'
import Mustache from 'mustache';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { parseAjaxReturn, ajaxPerso, fetchBrands } from '../../../fnc'

require('codemirror/lib/codemirror.css');
require('codemirror/theme/monokai.css');
require('codemirror/addon/hint/show-hint.css');

require('codemirror/addon/hint/show-hint.js');
require('codemirror/addon/hint/html-hint.js');
require('codemirror/mode/htmlmixed/htmlmixed');
require('codemirror/keymap/sublime');
// const refConst = require("../../../constants.js").v

const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

function TmpDataGroup(props){
	const params = new URLSearchParams(props.parent.location.search);

	const [selectedModalViewTmp, setSelectedModalViewTmp] = useState(false);
	const [brandObj, setBrandObj] = useState({});
	const [selectedTmp, setSelectedTmp] = useState([]);
	const [groupRegistered, setGroupRegistered] = useState([]);
	// const [listDataSelected, setListDataSelected] = useState(false);
	const [listBrands, setListBrands] = useState([]);
	/* const [stores, setStores] = useState([]);
	const [selectedStore, setSelectedStore] = useState(null); */
	//const [brandSelected, setBrandSelected] = useState(false);

	useEffect(() => {
		modalViewTmpWidtData()
	}, [selectedModalViewTmp])

	useEffect(() => {
		console.info('url', props.parent);
		let idObjOpen = props.parent.match.params.idObjOpen
		props.loading(true)
		
		if(idObjOpen){
			loadDataObjectBrand(idObjOpen)
		}else{
			loadDataObjectBrand()
		}
		
	}, [])

	/* useEffect(() => {
		fetchStores((r) => {
			if(r.success){
				setStores(r.data.filter(o => {
					if(o.active)	return o;
					return false;
				}))
			}else
				props.info({error: r.error})
		})
	}, []) */

	
	useEffect(() => {
		console.warn("selectedTmp", selectedTmp);
		
		if(selectedTmp){
			let cp = Object.assign({}, brandObj)
			let intVal = parseInt(selectedTmp)
			cp.id_template = intVal > 0? intVal : null
			setBrandObj(cp)
		}
	}, [selectedTmp])

	useEffect(() => {
		fetchBrands(null, {}, null, (r) => {
			console.log('brands', r);
			if(r.success)
				setListBrands(r.data)
		})
	}, [])


	const loadDataObjectBrand = (idObjOpen) => {

		let objArgs = {}
		if(idObjOpen){
			objArgs["idBrand"] = idObjOpen
		}

		props.fetchBrandsObject( objArgs, (r) => {
			props.loading(false)
			console.info('Fetched Brand Objects:', r);
			if(r.success){
				let results = r.data
				if(idObjOpen){
					/**
					 * With this call, we get only one result, no array is returned
					 */
					/* results.data = results.data
					results.model = results.model */
					setBrandObj(results)

					if(results && results.id_template)
						setSelectedTmp(results.id_template > 0? results.id_template : null)
				}
			}
		})

		props.fetchTmpGroupUsed({}, (r) => {
			console.log('Fetched TmpGroupUsed', r);
			setGroupRegistered(r)
		})

		props.loadTemplate({}, (r) => {
			if(selectedTmp.length > 0 && r.length > 0)
				setSelectedTmp(r[0].id)
		})
	}

	const modalViewTmpWidtData = () => {

		console.log("listTemplate", props.listTemplate);
		
		let tmpFound = null
		if(selectedModalViewTmp)
			tmpFound = props.listTemplate.find(obj => {
				if(selectedModalViewTmp === obj.id)
					return obj
			})
		else if(selectedTmp.length > 0)
			tmpFound = props.listTemplate.find(obj => {
				if(obj.id === selectedTmp[0])
					return obj
			})
		else
			tmpFound = props.listTemplate[0]

		console.log("tmpFound", tmpFound);

		if(tmpFound){ // tmpFound.body is NULL if no content

			if(!tmpFound.body)
				tmpFound.body = `<div>No Content For This Frame Yet</div>`

			let objValMuchtache = {}

			if(brandObj.data)
				brandObj.data.forEach(obj => {
					objValMuchtache[obj.model] = obj.value
				})

			console.info('objValMuchtache', objValMuchtache);

			let bodyWithBlock = Mustache.render(tmpFound.body, objValMuchtache, null, ['$%$', '$%$'])

			props.modal({
				show: true,
				title: "Watch the template with data",
				html: () => {
					return <div>
						<div>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<label className="input-group-text" htmlFor="inputGroupSelect01">Select frame</label>
								</div>
								<select className="custom-select" id="inputGroupSelect01" onChange={ (e) => setSelectedModalViewTmp(e.target.value) }>
									{
										props.listTemplate.map(objTmp => {
											return <option key={ objTmp.id + "_keyListTmpModal" } value={ objTmp.id }>{ objTmp.name }</option>
										})
									}
								</select>
							</div>
						</div>
						<div dangerouslySetInnerHTML={{__html: bodyWithBlock}}></div>
					</div>
				}
				,return: {
					title: "Close"
				}
			})
		}
	}

	const sendRequest = () => {
		let cpObj = Object.assign({}, brandObj)
		console.info('Request_object', cpObj);

		if(!cpObj.name){
			props.info({error: "Please fill the brand."})
		}else{

			/* console.info('JSON.stringify(cpObj)', JSON.stringify(cpObj));
			return; */

			/* cpObj.data = JSON.stringify(brandObj.data)
			cpObj.model = JSON.stringify(brandObj.model)*/
			ajaxPerso( {
				"api": "eio",
				"trigger": "setBrandObject",
				"data": JSON.stringify(cpObj)
			}, (r) => {
				console.info('sendRequest [setBrandObject]:', r);
				props.info({[r.data.error?  "error" : "success"]: parseAjaxReturn(r.data)})
			})
		}
	}

	const deleteTmp = () => {

		if(!brandObj.id){
			props.info({error: "Please fill the template name."})
		}else{
			console.info('Delete', brandObj);
			props.modal({
				show: true,
				title: "Confirmation",
				html: () => {
					return <div>
						Are you sure you want to delete the following data group: <span className="font-weight-bold">{brandObj.name}</span>
					</div>
				}
				,ok: {
					title: "I'm sure",
					fnc: (popup, close) => {
						
						if(brandObj.id){
							ajaxPerso( {
								"api": "eio",
								"trigger": "delBrandObj",
								"id": brandObj.id
							}, (r) => {
								console.info('Execute delTemplate:', r);
								
								if(r.success){
									close()
									props.parent.history.push("/MappingBrand");
								}
							})
						}else{
							console.info('Please fill the input');
						}
					}
				},
				return: {
					title: "Close"
				}
			})
		}
	}

	let brandSelected = listBrands.find((obj) => {
		if(obj.id === brandObj.id_product_brand)	return obj
	})
	console.log("brandObj", brandObj);
	
	return (<div>
		<p className="h1 mb-4">Template Manager - Group Data</p>
		<div className="d-flex justify-content-between">
			<button type="button" className="btn btn-info bg-info btn-lg mb-4" onClick={ modalViewTmpWidtData }>View</button>
			{ brandObj.id? <button type="button" className="btn btn-info bg-danger btn-lg mb-4" onClick={ deleteTmp }>Delete</button> : ''}
			<button type="button" className="btn btn-primary btn-lg mb-4" onClick={ () => {
				console.log(brandObj);
				sendRequest();
			} }>{!params.get("mode")? "Update" : "Save"}</button>
		</div>
		<div className="form-group mb-3">
			<label htmlFor="inputGroupSelect01">Brand associated</label>
			<Select
				styles={styleList}
				onChange={(e) => {
					let cp = Object.assign({}, brandObj)
					cp.id_product_brand = e.value
					setBrandObj(cp)
				}}
				options={
					listBrands.map(obj => {
						return{
							value: obj.id,
							label: obj.store_name + ": " + obj.name
						}
					})
				}
				value={
					brandSelected? { value: brandSelected.id, label: brandSelected.name } : {value: '', label: '-'}
				}
				defaultValue={
					brandSelected? { value: brandSelected.id, label: brandSelected.name } : {value: '', label: '-'}
				}
				isSearchable={true}
			/>
			{/* <Select
				styles={styleList}
				onChange={(selectedOption) => {
					setBrandSelected(selectedOption)
				}}
				options={listBrands.map((obj) => {
					return {value: obj? obj.id : '', label: obj? obj.name + (obj.prefix? ' ('+obj.prefix+')' : '') : '-'}
				})}
				value={brandSelected}
				defaultValue={brandSelected}
				isSearchable={true}
				//isMulti={true}
			/> */}
			{/* {<Select
				styles={styleList}
				className="mt-3"
				onChange={(selectedOption) => {
					brandObj.idStore = parseInt(selectedOption.value);
					setSelectedStore(selectedOption)
				}}
				options={stores.map((obj) => {
					return {"value": obj? obj.id : '', "label": obj? obj.name : '-'}
				})}
				value={selectedStore}
				defaultValue={selectedStore}
				isSearchable={true}
				placeholder="Select the store associated"
				//isMulti={true}
			/>} */}
		</div>
		<div className="input-group mb-3">
			<div className="input-group-prepend">
				<label className="input-group-text" htmlFor="inputGroupSelect01">Template associated</label>
			</div>
			<select className="custom-select" id="inputGroupSelect01" value={brandObj.id_template > 0? brandObj.id_template : ''} /* multiple */ size={1} onChange={ (e) => {
				// console.log('$(e.target).val()', $(e.target).val());
				let cp = Object.assign({}, brandObj)
				let intVal = parseInt($(e.target).val())
				cp.id_template = intVal > 0? intVal : null
				console.info('cp.id_templatecp.id_template', cp.id_template);
				
				setBrandObj(cp)
				//setSelectedTmp()
			} }>
				<option value="">-</option>
				{
					props.listTemplate.map(obj => {
						return <option key={obj.id + "_listTmp"} value={obj.id}>{obj.id + ": " + obj.name}</option>
					})
				}
			</select>
		</div>
		<div className="input-group mb-3">
			<div className="input-group-prepend">
				<span className="input-group-text" id="basic-addon1">Group name</span>
			</div>
			<input type="text" className="form-control" placeholder="Brand name" aria-label="Brand name" aria-describedby="basic-addon1" disabled={false} value={brandObj.name || ""} onChange={ (e) => {
				let cp = Object.assign({}, brandObj)
				cp.name = e.target.value
				setBrandObj(cp)
			}}></input>
		</div>
		{/* <div className="input-group mb-3">
			<div className="input-group-prepend">
				<span className="input-group-text" id="basic-addon1">Prefix</span>
			</div>
			<input type="text" className="form-control" placeholder="Brand name" aria-label="Brand name" aria-describedby="basic-addon1" disabled={false} value={brandObj.prefix || ""} onChange={ (e) => {
				let cp = Object.assign({}, brandObj)
				cp.prefix = e.target.value
				setBrandObj(cp)
			}}></input>
		</div> */}
		{/* <div className="input-group mb-3">
			<div className="input-group-prepend">
				<label className="input-group-text" htmlFor="inputGroupSelect02">Adding data</label>
			</div>
			<select className="custom-select" id="inputGroupSelect02" onChange={ (e) => setListDataSelected(e.target.value) }>
				{
					["-"].concat(groupRegistered).map(line => {
						return <option key={line + "_listGrpTmp"} value={line != "-" ? line : ""}>{line}</option>
					})
				}
			</select>
			<div className="input-group-append">
				<button className="btn btn-outline-secondary" type="button" onClick={() => {
					console.log("test", listDataSelected);
					
					if(!listDataSelected) return false
					let cp = Object.assign({}, brandObj)

					if(!("data" in cp) || !cp.data) cp.data = []
					if(!("model" in cp) || !cp.model) cp.model = []

					console.log("cp", cp);

					let alreadyIn = cp.data? cp.data.find(obj => {
						if(obj.model === listDataSelected)	return obj
					}) : false

					if(!alreadyIn){
						cp.data.push({
							model: listDataSelected,
							value: ""
						})
						cp.model.push(listDataSelected)
						//setListElemChoosen(cp)

						console.log("cp", cp);
						
						setBrandObj(cp)
					}
				}}>+</button>
			</div>
		</div> */}

		<div className="custom-control custom-switch noselect mb-2">
			<input type="checkbox" className="custom-control-input" id="customSwitch1" checked={parseInt(brandObj.is_active) === 1? true : false} onChange={(e) => {
				let cp = Object.assign({}, brandObj)
				cp.is_active = e.target.checked? 1 : 0
				setBrandObj(cp)
			}}/>
			<label className="custom-control-label" htmlFor="customSwitch1">Active</label>
		</div>
		<div className="form-group mb-3">
			<label htmlFor="inputGroupSelect01">Adding data</label>
			<CreatableSelect
				isClearable
				styles={styleList}
				onChange={(newValue, actionMeta) => {
					console.group('Value Changed');
					console.log(newValue.value);
					console.log(`action: ${actionMeta.action}`);
					console.groupEnd();

					if(actionMeta.action === "create-option"){
						let cpGrp = groupRegistered.slice(0)
						cpGrp.push(newValue.value)
						setGroupRegistered(cpGrp)
					}

					let cp = Object.assign({}, brandObj)

					if(!("data" in cp) || !cp.data) cp.data = []
					if(!("model" in cp) || !cp.model) cp.model = []

					console.log("cp", cp);

					let alreadyIn = cp.data? cp.data.find(obj => {
						if(obj.model === newValue.value)	return obj
					}) : false

					if(!alreadyIn){
						cp.data.push({
							model: newValue.value,
							value: ""
						})
						cp.model.push(newValue.value)

						console.log("cp", cp);
						setBrandObj(cp)
					}
				}}
				options={
					["-"].concat(groupRegistered).map(line => {
						return {
							value: line != "-" ? line : "",
							label: line
						}
					})
				}
				/* value={
					brandSelected? { value: brandSelected.id, label: brandSelected.name } : {value: '', label: '-'}
				}
				defaultValue={
					brandSelected? { value: brandSelected.id, label: brandSelected.name } : {value: '', label: '-'}
				} */
				isSearchable={true}
			/>
		</div>
		{
			brandObj.data? brandObj.data.map((obj) => {
				return <div key={obj.model} className="mt-3 pt-3">
					<div className="d-flex justify-content-between align-items-center">
						<h2>{obj.model}</h2>
						<button type="button" className="btn btn-danger btn-sm" onClick={() => {
							let cp = Object.assign({}, brandObj)

							cp.model.splice( cp.model.indexOf(obj.model) , 1)

							let posData = false
							cp.data.forEach((obj, index) => {
								if(obj.model === obj.model)
									posData = index
							})
							if(posData !== false)
								cp.data.splice( posData , 1)

							console.log("cp DELETE", cp);
							setBrandObj(cp)
						}}>Remove</button>
					</div>
					<CodeMirror
						value={obj.value}
						options={{
							mode: 'htmlmixed',
							theme: 'monokai',
							lineNumbers: true,
							extraKeys: {"Ctrl-Space": "autocomplete"},
							keymap: "sublime",
							indentWithTabs: true,
						}}
						onChange={(editor, data, value) => {
							obj.value = value
						}}
					/>
				</div>
			}) : ""
		}
	</div>);
}

export default TmpDataGroup;