// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { capitalizeFLetter, ajaxPerso } from '../../fnc'
const refConst = require("../../constants.js").v

var initialState = {
	grpAmazonFee: {
		type: 'ASIN',
		value: null,
		price: 0.0,
		shipping: 0.0,
		isFBA: false
	},
	_isMounted: false
}

export default function CreateItem(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-MjWHDikDqGC9nNvtYsu" // amazon_eio_read
	]

	const [modalOpen, setModalOpen] = useState(false);
	const [resAmazon, setResAmazon] = useState(null);
	const [grpAmazonFee, setGrpAmazonFee] = useState([JSON.parse(JSON.stringify(initialState.grpAmazonFee))]);	

	useEffect(() => {
		initialState._isMounted = true
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		if(modalOpen)
			modal(true);
	}, [resAmazon, grpAmazonFee])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let modal = (refresh) => {
		console.info('resAmazon', resAmazon);
		
		props.modal({
			show: refresh? false : true,
			title: "Amazon Fee Calculator",
			html: () => {
				return <div>
					{
						grpAmazonFee.map((grp, index) => {
							
							return <div key={index + '_grpInfoItemMws'}>
								<div className="d-flex flex-row align-items-center">
									<div>
										<div className="mb-3 d-flex flex-row align-items-center">
											<div className="custom-control custom-switch mr-3 noselect">
												<input type="checkbox" className="custom-control-input" id={"customSwitchMws_" + index} checked={grp.isFBA} onChange={(node) => {
													
													grpAmazonFee[index].isFBA = $(node.target).is(':checked')? true : false
													setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))

												}}/>
												<label className="custom-control-label" htmlFor={"customSwitchMws_" + index}>FBA</label>
											</div>
											<div className="input-group">
												<div className="input-group-prepend noselect">
													<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Type{(grp.type? ` (${grp.type})` : '')}</button>

													<div className="dropdown-menu">
														<a className="dropdown-item" href="#" onClick={() => {
															grpAmazonFee[index].type = 'ASIN'
															setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))
														}}>ASIN</a>
														<a className="dropdown-item" href="#" onClick={() => {
															grpAmazonFee[index].type = 'SellerSKU'
															setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))
														}}>SKU</a>
													</div>
												</div>
												<input type="text" className="form-control" aria-label="Text input with dropdown button" onChange={(val) => {
													console.log(val.target.value);
													grp.value = val.target.value
													// setFeeAmazonForm()
												}} onBlur={(val) => {

													// console.log(val.target.value);
													
													loadAmazonItemBdd({
														"shop": 133845,
														[grp.type === "ASIN"? "asinList" : "skuList"]: val.target.value
													})
												}}/>
												<div className="input-group-append">
													<button className="btn btn-outline-secondary" type="button">Submit</button>
												</div>
											</div>
										</div>
										<div className="mb-3 d-flex flex-row align-items-center">
											<div className="input-group mr-2">
												<div className="input-group-prepend">
													<span className="input-group-text">Item Price*</span>
												</div>
												<input type="text" className="form-control" onChange={(val) => {
													grp.price = val.target.value
													setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))
												}} value={grp.price}/>
											</div>
											<div className="input-group ml-2">
												<div className="input-group-prepend">
													<span className="input-group-text">Item Shipping*</span>
												</div>
												<input type="text" className="form-control" onChange={(val) => {
													grp.shipping = val.target.value
													setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))
												}} value={grp.shipping}/>
											</div>
										</div>
									</div>
									<div className="ml-4">
										<a onClick={() => {
											console.warn(grpAmazonFee.splice(index, 1))
											setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))
										}} className="pointer"><svg className="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z"/>
												<path fillRule="evenodd" d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" clipRule="evenodd"/>
											</svg>
										</a>
									</div>
								</div>
							</div>
						})
					}
					
					<div className="p-3 mb-2 bg-white text-dark d-inline-block">
						<a onClick={() => {
							grpAmazonFee.push(Object.assign(initialState.grpAmazonFee));
							setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))
						}} className="d-flex flex-row align-items-center pointer">
							<svg className="bi bi-plus-circle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd"/>
								<path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd"/>
								<path fillRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z" clipRule="evenodd"/>
							</svg>
							<span className="ml-2">Add more product</span>
						</a>
					</div>
					{
						resAmazon ? resAmazon.map((res) => {
							return <div className="mb-2 p-2 container bg-light text-dark border" key={res.value}>
								<div className="row font-weight-bold">
									<div className="col-sm">
										{res.type}
									</div>
									<div className="col-sm">
										% Selling Fee
									</div>
									<div className="col-sm">
										Fee Amount
									</div>
								</div>
								<div className="row">
									<div className="col-sm">
										{res.value}
									</div>
									<div className="col-sm">
										{res.prc? res.prc + '%' : ''}
									</div>
									<div className="col-sm">
										${res.fee} Fee
									</div>
								</div>
								<div className="row">
									{
										Object.keys(res.feeDetails).map((propertyDetail) => {
											return <div key={propertyDetail+"_"+res.value} className="col-sm">
												<div>{propertyDetail}: {res.feeDetails[propertyDetail]}</div>
											</div>
										})
									}
								</div>
							</div>
						}) : ''
					}
				</div>
			}
			, ok: {
				title: "Validate",
				fnc: (popup, close) => {
					let typeList = [],
						valList = [],
						priceList = [],
						shippingList = [],
						isFBAlist = []

					grpAmazonFee.forEach(o => {
						typeList.push(o.type)
						valList.push(o.value)
						priceList.push(o.price || 0.0)
						shippingList.push(o.shipping || 0.0)
						isFBAlist.push(o.isFBA)
					})

					console.info('shippingList', shippingList);
					console.info('priceList', priceList);
					console.info('isFBAlist', isFBAlist);
					
					loadFeeAmazon({
						"shop": 133845,
						"typeList": typeList.join(','),
						"valueList": valList.join(','),
						"priceList": priceList.join(','),
						"shippingList": shippingList.join(','),
						"fbaList": isFBAlist.join(',')
					})
				}
			}, return: {
				title: "Return",
				fnc: (popup, close) => {
					setModalOpen(false)
					close()
					/* itemsSel = items.map((obj) => {
						return obj.id
					})
					this.setState({"itemsSelected": itemsSel}) */
				}
			}
		});
	}

	const loadFeeAmazon = (args) => {
		fetchFeeAmazon(args, (r) => {
			if(!initialState._isMounted)	return false

			console.info('Fetching getMyListingFee:', r);

			if(r.success){
				if(r.res && r.res.length > 0)
					setResAmazon(r.res)
			}else{
				props.info({"error": r.errors})
			}
		})
	}

	const fetchFeeAmazon = (args, callback) => {
		// ASIN OR SKU
		ajaxPerso( Object.assign({
			"trigger": "getMyListingFee",
			"api": "amazon",
		}, args), callback)
	}

	const loadAmazonItemBdd= (args) => {
		fetchAmazonItemBdd(args, (r) => {
			if(!initialState._isMounted)	return false

			console.info('Fetching amazon->getItems:', r);

			if(r.success){
				if(r.data && r.data.length > 0){
					grpAmazonFee.forEach(o => {
						r.data.forEach((oR, iR) => {
							if(o.isFBA && oR.sku_on_amazon.indexOf('_fba') >= 0)
								o.price = r.data[iR].price
							else if(!o.isFBA && oR.sku_on_amazon.indexOf('_fba') === -1){
								o.price = r.data[iR].price
							}
						});
						if(!o.price)
							o.price = r.data[0].price
					})
					setGrpAmazonFee(JSON.parse(JSON.stringify(grpAmazonFee)))
				}
			}else{
				props.info({"error": r.errors})
			}
		})
	}

	const fetchAmazonItemBdd = (args, callback) => {
		// ASIN OR SKU
		ajaxPerso( Object.assign({
			"trigger": "getItems",
			"api": "amazon",
		}, args), callback)
	}
	
	let storeFromToNames = () => {
		
		if(!props.stores || props.stores.length === 0)	return ""
		
		let storeObj = props.stores.find(o => {
			if(o.id === parseInt(props.store))	return o
		})

		return storeObj? `${props.name} (${storeObj.name})` : props.name
	}

	if(!props.securityClearance(props, access))	return "";

	return (
		<div className={props.className} onClick={() => {
			setModalOpen(true)
			modal(false)
		}}>
			{
				storeFromToNames()
			}
		</div>
	);
}