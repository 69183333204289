// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import {
	NavLink
} from 'react-router-dom';
require('codemirror/lib/codemirror.css');
require('codemirror/theme/monokai.css');
require('codemirror/addon/hint/show-hint.css');

require('codemirror/addon/hint/show-hint.js');
require('codemirror/addon/hint/html-hint.js');
require('codemirror/mode/htmlmixed/htmlmixed');
require('codemirror/keymap/sublime');
const refConst = require("../../../constants.js").v
const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

function TmpList(props){
	const params = new URLSearchParams(props.parent.location.search);

	const [listTmp, setListTmp] = useState([]);
	
	/* useEffect(() => {
		modalViewTmpWidtData()
	}, [selectedModalViewTmp]) */

	useEffect(() => {
		let idObjOpen = props.parent.match.params.idObjOpen

		if(idObjOpen){
			loadDataTmp(idObjOpen)
		}
	}, [])

	useEffect(() => {
		props.loadTemplate({}, (r) => {
			console.info(r);
			setListTmp(r)
		})
	}, [])


	const loadDataTmp = (idObjOpen) => {
		console.info('Loading for ID: ', idObjOpen);
		
	}

	
/* 	useEffect(() => {
		console.warn("selectedTmp", selectedTmp);
		
		if(selectedTmp){
			let cp = Object.assign({}, brandObj)
			cp.ids_template = selectedTmp
			setBrandObj(cp)
		}
	}, [selectedTmp]) */

	return (<div>
		<p className="h1 mb-4">Template Manager - List</p>
		<div className="mb-3">
			<span className="ml-3"><NavLink to={{
				pathname: "/template",
				search: "?mode=tmpframe"
			}}><button type="button" className="btn btn-primary">Add New Frame</button></NavLink></span>
		</div>
		<ul className="list-group list-group-flush w-100">
			<li className="font-weight-bold list-group-item d-flex flex-row align-items-center justify-content-between"><span>Template created</span></li>
			{
				listTmp.map((obj) => {
					return <li key={obj.name + "_listBrdObj"} className="list-group-item d-flex flex-row align-items-center justify-content-between">
						<span><NavLink to={{
							pathname: "/template/" + obj.id,
							search: "?mode=tmpframe"
						}}>{obj.name}</NavLink></span>
					</li>
				})
			}
		</ul>
	</div>);
}

export default TmpList;