// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons' */
import {ajaxPerso} from "../fnc.js"
import {
	NavLink,
	Redirect
} from 'react-router-dom';
import store from 'store2';
const refConst = require("../constants.js").v
var tagRefs = [];
var refNewForm =  null;
var namespace =  "specialTagList";

function Shopify_tag(props){

	const [dataSet, setDataSet] = useState([]);
	const [grpOpened, setGrpOpened] = useState([]);
	const [tagsGrp, setTagsGrp] = useState([]);
	const [idGrpOpen, setIdGrpOpen] = useState(null);
	const [idModif, setIdModif] = useState(null);
	const [triggered, setTriggered] = useState(false);
	const [idMeta, setIdMeta] = useState(null);
	
	const newEntryRefs = {
		name: React.createRef(),
		image: React.createRef()
	};

	const newEntryGrpRefs = {
		name: React.createRef(),
		prefix: React.createRef(),
		multi: React.createRef()
	};

	if(!refNewForm)
		refNewForm =  React.createRef()

	let shopifyStores = refConst.stores.filter(o => {
		if(o.id_marketplace == 14)	return o
	})

	let objPreSelected = store("storeSelectedTag")
	let storePreSelected = shopifyStores[0]
	if(objPreSelected && objPreSelected.id_marketplace === 14)
		storePreSelected = objPreSelected
	const [shopSelected, setShopSelected] = useState(storePreSelected);

	/* useEffect(() => {
		let objPreSelected = store("storeSelectedTag")

		console.log('objPreSelectedobjPreSelected', objPreSelected);
		
		setShopSelected(objPreSelected)
	}, [])
 */
	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		if(shopSelected){
			fetchTagsGrp()
			fetchMeta()
		}
	}, [shopSelected])

	useEffect(() => {
		/* const params = new URLSearchParams(props.parent.location.search);
		console.info('params', params); */
		//console.info('parprops.parent.location.searchams', props.parent.match.params);
		// setTagRefs([])
		setIdGrpOpen(parseInt(props.parent.match.params.idGrpOpen))
		
	}, [props.parent.match.params.idGrpOpen])

	useEffect(() => {

		setIdModif(parseInt(props.parent.match.params.idTagOpen))

	}, [props.parent.match.params.idTagOpen])

	useEffect(() => {

		if(tagRefs[idModif] && tagRefs[idModif].name.current)
			tagRefs[idModif].name.current.focus()

	}, [idModif])

	/* useEffect(() => {
		$('[data-toggle]').tooltip({
			placement: "left"
		}).tooltip('update')
	}) */

	const fetchTagsGrp = (callback) => {
		props.loading(true)
		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetchTagsGroup",
			"shop": shopSelected.id
		}, (r) => {
			console.info(`Fetched fetchTagsGroup:`, r)
			props.loading(false)
			if(callback)	callback(r.success? r.res : [])
			setTagsGrp(r.success? r.res : [])
		})
	}

	const fetchMeta = (callback) => {
		props.loading(true)

		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetch_meta",
			"shop": shopSelected.id,
			"namespace": namespace
		}, (r) => {
			console.info(`Fetched fetch_meta:`, r)
			if(r.success)
				setIdMeta(r.res.length > 0? r.res[0].id : null)
			if(callback)	callback()
		})
	}

	const generateMetaData = (tagsGrp) => {
		/* let objTag = {}
		tagsGrp.forEach(g => {
			if(g.data.length > 0){
				g.data.forEach(t => {
					if(t.image_name)
						objTag[g.prefix + t.name] = {
							"image": t.image_name
						}
				})
			}
		})
		return objTag */
		let objTag = []
		tagsGrp.forEach(g => {
			if(g.data.length > 0){
				g.data.forEach(t => {
					if(t.image_name)
						objTag.push({
							"tag": g.prefix + t.name,
							"tag_grp": g.name,
							"tag_value": t.name,
							"image": t.image_name
						})
				})
			}
		})
		tagsGrp.sort((obj1, obj2) => {
			if(obj1.tag > obj2.tag)
				return 1
			return -1
		})
		return objTag
	}

	const showSeg = () => {
		if(idGrpOpen){
			return showTags()
		}else{
			return showGroup()
		}
	}

	const saveTag = (data, e, callback) => {
		e.preventDefault();

		setTriggered(true)

		if(!idGrpOpen){
			props.info({error: "No Group ID saved."})
			return false;
		}

		if(!data.name.current.value){
			props.info({error: "Please fill the name."})
			return false;
		}

		let objData = {
			"name": data.name.current.value.trim(),
			"image_name": data.image.current.value.trim(),
			"shopify_tags_group": idGrpOpen
		}

		if(idModif)
			objData.id = idModif

		sendReq({
			"trigger": "insertUpdateTag",
			"shop": shopSelected.id,
			"data": JSON.stringify(objData),
			"verbose": false
		}, e, callback)
	}

	const saveGrpTag = (data, e, callback) => {
		e.preventDefault();

		setTriggered(true)

		console.info('data', data);
		

		if(!data.name.current.value || !data.prefix.current.value){
			props.info({error: "Please fill the name and prefix."})
			return false;
		}

		let objData = {
			"id_marketplace": shopSelected.id,
			"name": data.name.current.value.trim(),
			"prefix": data.prefix.current.value.trim(),
			"multi_select": data.multi.current? data.multi.current.checked : null
		}

		if(idModif)
			objData.id = idModif

		console.info('objData', objData);
		console.info('shopSelected', shopSelected);

		sendReq({
			"trigger": "insertUpdateGrpTag",
			// "shop": shopSelected.id,
			"data": JSON.stringify(objData),
			"verbose": false
		}, e, callback)
	}

	const sendReq = (args, e, callback) => {
		ajaxPerso( Object.assign({
			"api": "shopify",
		}, args), (r) => {
			console.info('Fetch insertUpdateTag', r);
			setTriggered(false)

			if(r.success){

				setIdModif(null)

				if(callback)	callback(e)
				fetchTagsGrp((tagsGrp) => {
					// We refresh the meta only if have created or updated a tag, never if created or updated group (a group should never be modified)
					if(idGrpOpen)
						cleanMetaIfNeeded(generateMetaData(tagsGrp), () => {
							createMetaTags(generateMetaData(tagsGrp));
						});
				})
				props.info({success: "Tag Added."})
			}else{
				props.info({error: r.error})
			}
		})
	}

	const deleteTag = (data, e) => {
		e.preventDefault();

		if(!idGrpOpen || !idModif){
			props.info({error: "No Group ID saved."})
			return false;
		}

		setTriggered(true)

		ajaxPerso( {
			"api": "shopify",
			"trigger": "deleteTagFromGrp",
			"shop": shopSelected.id,
			"id": idModif,
			"verbose": false
		}, (r) => {
			console.info('Fetch deleteTagFromGrp', r);
			setTriggered(false)

			if(r.success){
				fetchTagsGrp((tagsGrp) => {
					cleanMetaIfNeeded(generateMetaData(tagsGrp));
				})
				props.info({success: "Tag deleted."})
			}else{
				props.info({error: r.error})
			}
		})
	}

	const cleanMetaIfNeeded = (dataObj, callback) => {

		if(Object.keys(dataObj).length === 0 && idMeta){
			ajaxPerso( {
				"api": "shopify",
				"trigger": "del_meta",
				"id": idMeta,
				"shop": shopSelected.id,
			}, (r) => {
				console.info('Fetched del_meta['+idMeta+']: ', r);
				setTriggered(false)

				if(!r.success){
					props.info({error: r.error})
				}else
					setIdMeta(null)
				if(callback) callback(r)
			})
		}else if(callback)	callback()
	}

	const createMetaTags = (dataObj, callback) => {
		console.info('Will create this:', dataObj);

		if(Object.keys(dataObj).length > 0){
			setTriggered(true)
			ajaxPerso( {
				"api": "shopify",
				"trigger": "createUpdate_meta",
				"shop": shopSelected.id,
				"data": JSON.stringify({
					namespace: namespace,
					key: namespace,
					value: JSON.stringify(dataObj),
					type: "json"
				}),
			}, (r) => {
				setTriggered(false)
				console.info('Trigger [createUpdate_meta]: ', r);
				if(r.success){
					setIdMeta(r.json? r.json.id : null)
					if(callback)	callback();
				}else{
					props.info({error: r.errorList})
				}
			})
		}
	}

	const showTags = () => {

		let grpObj = tagsGrp.find(o => {
			if(o.id === idGrpOpen)	return o
			return false
		})
		
		if(!grpObj)	return ''

		return <div className="container w-100">
			<div className="row">
				<div className="col-sm-1 p-3">
					ID
				</div>
				<div className="col-sm-1 p-3">
					Logo
				</div>
				<div className="col-sm-3 p-3">
					Name
				</div>
				<div className="col-md-4 p-3">
					Image Name (shopify admin files)
				</div>
				<div className="col-sm p-3">
					Option
				</div>
			</div>
			<form onSubmit={(e) => saveTag(newEntryRefs, e, (event) => {
				if(refNewForm.current)
					refNewForm.current.reset()
			})} ref={refNewForm} className="form-group">
				<div className="row bg-info">
					<div className="col-sm-1 p-3">
						
					</div>
					<div className="col-sm-3 p-3">
					</div>
					<div className="col-sm-3 p-3">
						<input className="w-100 form-control" id={`input_tag_shopify_new`} autoFocus={true} ref={newEntryRefs.name} />
					</div>
					<div className="col-md-4 p-3">
						<input className="w-100 form-control" id={`input_tag_img_shopify_new`} ref={newEntryRefs.image} />
					</div>
					<div className="col-sm p-3">
						<button type="submit" className="btn-primary btn btn-sm ml-3">Add</button>
					</div>
				</div>
			</form>
			{
				grpObj.data? grpObj.data.map((t, i) => {

					if(!tagRefs[t.id]){
						tagRefs[t.id] = {
							"name": React.createRef(),
							"image": React.createRef()
						}
					}

					let storeUrl = shopSelected.id == refConst.id_store_hausfTools? "https://hausoftools.com/cdn/shop/files/" : "https://eio.com/cdn/shop/files/"
					
					return <form key={`${t.id}_tagNew`} onSubmit={(e) => saveTag(tagRefs[t.id], e)} className="form-group">
						<div className="row">
							<div className="col-sm-1 p-3">
								{t.id}
							</div>
							<div className="col-sm-1 p-3  d-flex align-items-center">
								<img src={storeUrl+t.image_name+`.png`} alt="logo" className="w-100" onError={(e) => {
									console.info("eeeeeee", e);
									e.target.style.display = 'none';
									$(e.target).after('<span class=\'smallText d-block alignCenter\'>No Logo</span>');
									console.warn($(e.target).parents('.ctnProductLine'));
									$(e.target).parents('.ctnProductLine').css('padding-top', '50px');
								}}/>
							</div>
							<div className="col-sm-3 p-3 d-flex align-items-center">
								<input className="w-100 form-control" defaultValue={t.name} disabled={idModif === t.id? false : true} id={`input_tag_shopify_${t.id}`} ref={tagRefs[t.id].name}/>
							</div>
							<div className="col-md-4 p-3 d-flex align-items-center">
								<input className="w-100 form-control" defaultValue={t.image_name} ref={tagRefs[t.id].image} disabled={idModif === t.id? false : true} id={`input_tag_img_shopify_${t.id}`} />
							</div>
							<div className="col-sm p-3 d-flex align-items-center">
								<button type="button" className={(idModif === t.id? "bg-dark text-light" : "btn-primary") + " btn btn-sm"} onClick={(e) => {
									setIdModif(idModif === t.id? null : t.id)
								}}>{idModif === t.id? "Close" : "Modify"}</button>
								{
									idModif === t.id? <button className="bg-danger btn btn-sm ml-3 text-light" onClick={(e) => deleteTag(tagRefs[t.id], e)} disabled={triggered}>Delete</button> : ''
								}
								{
									idModif === t.id? <button type="submit" className="btn-primary btn btn-sm ml-3" disabled={triggered}>Save</button> : ''
								}
							</div>
						</div>
					</form>
				}) : 'Empty'
			}
		</div>
	}

	const showGroup = () => {

		return(
			<div className="container w-100">
				<div className="row">
					<div className="col-md-3 p-3">
						Name
					</div>
					<div className="col-md-3 p-3">
						Prefix
					</div>
					<div className="col-sm-2 p-3 text-center">
						Allow Multi Select
					</div>
					<div className="col-sm-1 p-3 text-center">
						Tags count
					</div>
					<div className="col-md-3 p-3 text-center">
						Option
					</div>
				</div>
				<form onSubmit={(e) => saveGrpTag(newEntryGrpRefs, e, (event) => {
					if(refNewForm.current)
						refNewForm.current.reset()
					setTriggered(false)
				})} ref={refNewForm} className="form-group">
					<div className="row bg-info">
						<div className="col-md-3 p-3">
							<input className="w-100 form-control" id={`input_tag_shopify_new`} autoFocus={true} ref={newEntryGrpRefs.name} />
						</div>
						<div className="col-md-3 p-3">
							<input className="w-100 form-control" id={`input_tag_img_shopify_new`} ref={newEntryGrpRefs.prefix} />
						</div>
						<div className="col-sm-2 p-3 text-center">
							<div className="custom-control custom-switch">
								<input type="checkbox" className="custom-control-input" ref={newEntryGrpRefs.multi} id={`customSwitchMultiSel_new`} />
								<label className="custom-control-label pointer" htmlFor={`customSwitchMultiSel_new`}></label>
							</div>
						</div>
						<div className="col-sm-1 p-3 text-center">
						</div>
						<div className="col-md-3 p-3 text-center">
							<button type="submit" className="btn-primary btn btn-sm ml-3">Add</button>
						</div>
					</div>
				</form>
				{
					tagsGrp.map(t => {

						if(!tagRefs[t.id]){
							tagRefs[t.id] = {
								"name": React.createRef(),
								"prefix": React.createRef(),
								"multi": React.createRef()
							}
						}

						return <form key={`${t.id}_tagGrpNew`} onSubmit={(e) => saveGrpTag(tagRefs[t.id], e)} className="form-group">
							<div key={`${t.id}_tagGrp`} className="row">
								<div className="col-md-3 p-3">
									<input className="w-100 form-control" defaultValue={t.name} ref={tagRefs[t.id].name} disabled={idModif === t.id? false : true} id={`input_tag_img_shopify_${t.id}`} />
								</div>
								<div className="col-md-3 p-3">
									<input className="w-100 form-control" defaultValue={t.prefix} ref={tagRefs[t.id].prefix} disabled={idModif === t.id? false : true} id={`input_tag_img_shopify_${t.id}`} />
								</div>
								<div className="col-sm-2 p-3 text-center d-flex align-items-center justify-content-center">
									<div className="custom-control custom-switch">
										<input type="checkbox" className="custom-control-input" disabled={idModif !== t.id} checked={t.multi_select} ref={tagRefs[t.id].multi} id={`customSwitchMultiSel_${t.id}`} />
										<label className="custom-control-label pointer" htmlFor={`customSwitchMultiSel_${t.id}`}></label>
									</div>
								</div>
								<div className="col-sm-1 p-3 text-center d-flex align-items-center justify-content-center">
									<span className="badge badge-info">{t.data.length}</span>
								</div>
								<div className="col-md-3 p-3 text-center">
									<button type="button" className={(idModif === t.id? "bg-dark text-light" : "btn-primary") + " btn btn-sm"} onClick={(e) => {
										setIdModif(idModif === t.id? null : t.id)
									}}>{idModif === t.id? "Close" : "Modify"}</button>
									<NavLink to={{
										pathname: "/Shopify_tag/"+t.id
									}}><button type="button" className="btn btn-sm bg-success text-light ml-3">Open</button></NavLink>
									{
										idModif === t.id? <button type="submit" className="btn-primary btn btn-sm ml-3 " disabled={triggered}>Save</button> : ''
									}
								</div>
							</div>
						</form>
					})
				}
			</div>
		)
	}

	if(!props.securityClearance(props))	return "";

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="alert alert-primary text-wrap w-100" role="alert">
				You can add or update a group that will represent a type of tags, then you will be able to attache tag name to it., the new tag goup will be instantly available on the Shopify listing page.
			</div>
			<div className="alert alert-danger text-wrap w-100" role="alert">
				You have to be very carefull if you are modifying a tag because all the corresponding tag used on the store will have to be updated separatly to represent your new value.
			</div>
			<div className="input-group mb-4 mt-2">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" defaultValue={ JSON.stringify(shopSelected) } onChange={(e) => {
					store("storeSelectedTag", JSON.parse(e.target.value))
					setShopSelected(JSON.parse(e.target.value))
				}}>
					{
						refConst.stores.map(obj => {
							if(obj.id_marketplace === 14)
								return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<div className="d-flex flex-row flex-nowrap w-100" style={{overflowX: "auto"}}>
				{showSeg()}
			</div>
		</div>
	);
}

export default Shopify_tag;