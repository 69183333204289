// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'

function ImgPushAndView(props) {

	/* const [userSelected, setUserSelected] = useState(false);
	const [nbrPage, setNbrPage] = useState(1);
	const [nbrPerPage, setNbrPerPage] = useState(10);
	const [imgSelected, setimgSelected] = useState([]); */

	useEffect(() => {
		props.loadImg();
	}, []);

	const eventSelectImg = (objImg, eventClick) => {
		let imgsSelected = props.imgSelected.slice(0)

		if(imgsSelected.length >= 24){
			props.info({error: "Please unselect some of your pictures [system limit 24]"})
			return false;
		}

		let pos = null;
		imgsSelected.forEach((o, i) => {
			if(o.id === objImg.id){
				pos = i;
				return false;
			}
		})
		
		if(pos !== null)
			imgsSelected.splice(pos, 1)
		else
			imgsSelected.push(objImg)

		console.log(imgsSelected);
		props.setImgSelected(imgsSelected)
	}

	const getImage = (fncCreateNode) => {
		let images = props.images,
			outT = []

		for (const idImg in images) {
			if (images.hasOwnProperty(idImg)) {
				const obj = images[idImg]
				obj.id = idImg
				
				outT.push(fncCreateNode(obj))
			}
		}
		return outT
	}

	let imgSelectedList = props.imgSelected.map(o => {
		return o.id;
	})

	return(
		<div className="m-3 border bg-white">
			<div>
				<div className="holder pl-4 pr-4 pt-2 pb-2 text-center bg-dark text-light">
					<span className={imgSelectedList.length >=1? "" : "hideNode"}>{imgSelectedList.length} selected</span>
				</div>
				<div className="listPicture overflow-auto" style={{maxHeight: '500px'}}>
					{
						props.images.map((o) => {
							return (
								<div className={(imgSelectedList.indexOf(o.id) >= 0? "bg-primary text-white": "") + " p-1 pointer lineImgEbay d-flex flex-row align-items-center " } key={o.id} onClick={(e) => {
									eventSelectImg(o, e)
								}}>
									<div className="mr-4" style={{
										backgroundImage: 'URL("'+o.url+'")',
										height: '70px',
										width: '70px',
										backgroundPosition: 'center',
										backgroundRepeat: 'no-repeat',
										backgroundSize: 'contain'
									}}></div>
									<span style={{maxWidth: '250px', overflowX: 'auto'}}>{o.name}</span>
									<span className={(imgSelectedList.indexOf(o.id) >= 0 && imgSelectedList.length >=1? "": "hideNode") + " ml-3" }>{imgSelectedList[0] === o.id? "MAIN" : imgSelectedList.indexOf(o.id) !== -1?"Pic " + imgSelectedList.indexOf(o.id) : ''}</span>
								</div>)
						})
					}
				</div>
			</div>
		</div>
	);
}

export default ImgPushAndView