import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import Select, { components, createFilter } from 'react-select';
import { capitalizeFLetter, ajaxPerso, fetchStores } from '../fnc'
import AddPromo from './ctn/shopifyPromo/AddPromo'
import SpecialPromoPage from './ctn/shopifyPromo/SpecialPromoPage'
import ListPromo from './ctn/shopifyPromo/ListPromo'
import {
	NavLink
} from 'react-router-dom';
import store from 'store2';
import moment from 'moment';

const refConst = require("../constants.js").v

const {info, log, warn} = console
const initialState = {
};

var _isMounted = false

export default function Shopify_promo(props){

	const stores = refConst.stores.filter(o => {
		if(o.id_marketplace == 14)	return o
		return false
	})
	var objPreSelected = store("shopSelected")
	const [shopSelected, setShopSelected] = useState(objPreSelected? objPreSelected : stores[0]);

	useEffect(() => {
		_isMounted = true;
		return () => _isMounted = false
	}, [])

	useEffect(() => {
		resetPage()
	}, [shopSelected])

	useEffect(() => {
		$('.tooltipInfoMap').tooltip({
			placement: "right",
			title: "When checked, the prices will be updated only if smaller than your new price (map)."
		}).tooltip('update')
	})

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage">
			<h1 className="page-header mb-4">Promo</h1>
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" value={ JSON.stringify(shopSelected) } onChange={(e) => {
					$('.hasTooltip').tooltip('dispose') // Need to be affective before the rerender of the new form cause nodes will be different.
					store("shopSelected", JSON.parse(e.target.value))
					setShopSelected(JSON.parse(e.target.value))
				}}>
					{
						refConst.stores.map(obj => {
							if(obj.id_marketplace == 14)
								return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<AddPromo
				{...props}
				shopSelected={shopSelected}
			/>
			<SpecialPromoPage
				{...props}
				shopSelected={shopSelected}
			/>
			<ListPromo
				{...props}
				shopSelected={shopSelected}
			/>
		</div>
	);
}