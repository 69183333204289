// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faTrashAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { ajaxPerso, confirmation_modal } from '../../../fnc'
import moment from 'moment';

import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';


var initialState = {
	_isMounted: false,
	promoList: [],
	showEnded: false,
	selectedIdVarList: []
}

const refConst = require("../../../constants.js").v

const styleList = {
	container: (provided) => ({
		...provided,
		minWidth: "250px"
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}
var _isMounted = false,
	listPromo_showEnded = false

export default function ListPromo(props){

	const [promoList, setPromoList] = useState(initialState.promoList.slice(0));
	const [showEnded, setShowEnded] = useState(initialState.showEnded);
	const [selectedIdVarList, setSelectedIdVarList] = useState(initialState.selectedIdVarList.slice(0));
	const timeRefreshPromo = 2000

	useEffect(() => {
		_isMounted = true;
		return () => _isMounted = false
	}, [])

	useEffect(() => {
		listPromo_showEnded = showEnded
	}, [showEnded])

	useEffect(() => {
		
		const interval = setInterval(() => {
			load_promo()
		}, timeRefreshPromo)

		return () => clearInterval(interval);

	}, [props.shopSelected])

	useEffect(() => {

		if(!props.securityClearance(props))	return "";
		if(!props.shopSelected)	return;

		load_promo()

	}, [props.shopSelected, showEnded])

	const load_promo = () => {

		get_promo({
			// started: 1,
			ended: listPromo_showEnded? 1 : 0,
			include_product: true
		}, (r) => {
			if(!_isMounted)	return false
			console.info('Fetched get_promo:', r);
			setPromoList(r.success? r.data : [])
		})
	}

	const get_promo = (obj, callback) => {

		if(!obj || !props.shopSelected.id)	return false
		if(!props.shopSelected)	return;
		
		ajaxPerso(Object.assign({
			"api": "shopify",
			"trigger": "get_promo",
			"special": "special_get_promo",
			"shop": props.shopSelected.id,
		}, obj), callback);
	}

	const del_promo = (e, idVariant) => {

		if(!props.shopSelected)	return;

		if(!idVariant){
			props.info({"error": "No promo ID received."})
			return;
		}

		confirmation_modal(props, (closeModal) => {

			closeModal()

			ajaxPerso({
				"api": "shopify",
				"shop": props.shopSelected.id,
				"trigger": "end_promo",
				"method": "POST",
				"id_variant_shopify": idVariant,
			}, (resPromo) => {
				console.info("Triggered 'end_promo':", resPromo);
				if(resPromo.success)
					props.info({"success": "Promo marked for removal.", "error": resPromo.error})
			})
		}, e)
	}

	const removeTempPromo = (e, promoObj) => {

		console.info("remove", promoObj);
		if(!props.shopSelected)	return;

		confirmation_modal(props, () => {

			ajaxPerso({
				"api": "shopify",
				"trigger": "end_db_promo",
				"shop": props.shopSelected.id,
				"id": promoObj.id,
			}, (r) => {
				console.info("Trigger end_db_promo", r);
	
				if(r.errors)
					props.info({error: r.errors})
				else if(r.data.deleted)
					props.info({success: "Promo removed"})
			});		
		}, e)
	}

	let promoAwaiting = promoList.filter(o => {
		if(!o.started && !o.ended)	return o
	})

	let promoActive = promoList.filter(o => {
		if(o.started)	return o
	})

	console.info("promoAwaiting", promoAwaiting);
	console.info("promoActive", promoActive);

	let listSelected = promoActive.filter(o => {
		if(selectedIdVarList.indexOf(o.id_variant_shopify) !== -1)	return o
	})
		
	return (<div className={("className" in props? props.className : "")}>
		<div style={{overflowX: "auto"}}>
			<div className="mb-3 d-flex flex-row align-items-center">
				<div className="custom-control custom-switch">
					<input type="checkbox" className="custom-control-input" id="customSwitchEnded" checked={showEnded === 1? true : false} onChange={(e) => { setShowEnded(e.target.checked? 1 : 0) }}/>
					<label className="custom-control-label noselect pointer" htmlFor="customSwitchEnded">Show Ended</label>
				</div>
				<div className="ml-3">
					{
						listSelected.length > 0? <button type="button" className="btn btn-danger" onClick={(e) => {
							confirmation_modal(props, (closeModal) => {

								closeModal()

								props.info({"success": "Removing " + listSelected.length + " promo(s), Please wait..."})
								setSelectedIdVarList(initialState.selectedIdVarList.slice(0))

								listSelected.forEach(l => {
									ajaxPerso({
										"api": "shopify",
										"shop": props.shopSelected.id,
										"trigger": "end_promo",
										"method": "POST",
										"id": l.id,
										"id_variant_shopify": l.id_variant_shopify,
									}, (resPromo) => {
										console.info("Triggered 'end_promo':", resPromo);
										if(resPromo.error){
											props.info({"error": resPromo.error})
										}
									})
								})
							}, e)
						}}>[{listSelected.length}] Remove selected</button> : ''
					}
				</div>
			</div>
			<table className={(promoAwaiting.length > 0? "" : "d-none") + " table table-bordered mb-3"}>
				<thead>
					<tr className="table-info">
						<th scope="col">#</th>
						<th scope="col">Delete</th>
						<th scope="col">Img</th>
						<th scope="col">Info Awaiting Promo</th>
						<th scope="col">Change type</th>
						<th scope="col">Change value</th>
						<th scope="col">Original price</th>
						<th scope="col">Price Calculated</th>
						<th scope="col">Starting Date</th>
						<th scope="col">Ending Date</th>
					</tr>
				</thead>
				<tbody>
					{
						promoAwaiting.map(o => {
							let imgSrc = o.images_json? o.images_json[0].node? o.images_json[0].node.originalSrc : o.images_json[0].src : null
							return <tr key={`promoList_${o.id}`}>
								<th scope="row">
									{o.id}
								</th>
								<th className="align-middle text-center">
									<button type="button" className="align-middle btn btn-danger mr-3 m-auto" title="Remove this promo" onClick={(e) => removeTempPromo(e, o)}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
											<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
										</svg>
									</button>
								</th>
								<td>{imgSrc? <img src={imgSrc} height="60"/> : ''}</td>
								<td>
									<div><a target="_blank" rel="noopener noreferrer" href={"https://" + props.shopSelected.account_name + "/products/" + o.handle} >{o.title}</a></div>
									<div>{o.sku}</div> <NavLink to={"/shopify/"+o.id_product_shopify}>Edit Product</NavLink>
								</td>
								<td>{o.change_type}</td>
								<td>{o.change_value}</td>
								<td>{o.original_price}</td>
								<td>{o.new_price_calculated}</td>
								<td>
									<div>{o.starting_date}</div>
								</td>
								<td>
									<div>{o.ending_date}</div>
								</td>
							</tr>
						})
					}
				</tbody>
			</table>
			<table className={(promoActive.length > 0? "" : "d-none") + " table table-bordered"}>
				<thead>
					<tr className="table-primary">
						<th scope="col">#</th>
						<th scope="col">Delete</th>
						<th scope="col">Img</th>
						<th scope="col">Info Active Promo</th>
						<th scope="col">Change type</th>
						<th scope="col">Change value</th>
						<th scope="col">Original price</th>
						<th scope="col">Price Calculated</th>
						<th scope="col">Starting Date</th>
						<th scope="col">Ending Date</th>
						<th scope="col">Started</th>
						<th scope="col">Ended</th>
					</tr>
				</thead>
				<tbody>
					{
						promoActive.map(o => {
							let imgSrc = o.images_json? o.images_json[0].node? o.images_json[0].node.originalSrc : o.images_json[0].src : null
							return <tr key={`promoList_${o.id}`}>
								<th scope="row">
									{o.id}
									<label className="ctn_checkbox mt-2">
										<input type="checkbox" onChange={(e) => {
											/* o.selected = e.target.checked
											setPromoList(promoList.slice(0)) */
											if(selectedIdVarList.indexOf(o.id_variant_shopify) === -1){
												selectedIdVarList.push(o.id_variant_shopify)
											}else{
												selectedIdVarList.splice(selectedIdVarList.indexOf(o.id_variant_shopify), 1)
											}
											setSelectedIdVarList(selectedIdVarList.slice(0))
										}} checked={selectedIdVarList.indexOf(o.id_variant_shopify) !== -1} value={selectedIdVarList.indexOf(o.id_variant_shopify) !== -1}/>
										<span className="checkmark"></span>
									</label>
								</th>
								<th className="align-middle text-center">
									{
										!o.ended? <button type="button" className="align-middle btn btn-danger mr-3 m-auto" title="Remove this promo" onClick={(e) => del_promo(e, o.id_variant_shopify)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
												<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
											</svg>
										</button> : ""
									}
								</th>
								<td>{imgSrc? <img src={imgSrc} height="60"/> : ''}</td>
								<td>
									<div><a target="_blank" rel="noopener noreferrer" href={"https://" + props.shopSelected.account_name + "/products/" + o.handle} >{o.title}</a></div>
									<div>{o.sku}</div> <NavLink to={"/shopify/"+o.id_product_shopify}>Edit Product</NavLink>
								</td>
								<td>{o.change_type}</td>
								<td>{o.change_value}</td>
								<td>{o.original_price}</td>
								<td>{o.new_price_calculated}</td>
								<td>
									<div>{o.starting_date}</div>
									{(() => {
										if(o.ended)	return '';

										let dayDiffS = moment(o.starting_date).local().diff(moment().local(), 'seconds')
										let hasStarted = false;

										if(dayDiffS < 0)
											hasStarted = true;

										let nbrDays = Math.abs(dayDiffS) / 3600 / 24
										let nbrHours = (nbrDays % 1) * 24
										let nbrMinutes = (nbrHours % 1) * 60
										let nbrSecondes = (nbrMinutes % 1) * 60

										if(nbrDays > 0 || nbrHours > 0 || nbrMinutes > 0 || nbrSecondes > 0)	return <div>
											{hasStarted? 'Started for' : 'Start in'} {Math.trunc(nbrDays)} Days {Math.trunc(nbrHours)}h{Math.trunc(nbrMinutes)}m{Math.trunc(nbrSecondes)}s
										</div>
										return '';
									})()}
								</td>
								<td>
									<div>{o.ending_date}</div>
									{(() => {
										if(o.ended)	return '';

										let dayDiffS = moment(o.ending_date).local().diff(moment().local(), 'seconds')
										let nbrDays = Math.abs(dayDiffS) / 3600 / 24
										let nbrHours = (nbrDays % 1) * 24
										let nbrMinutes = (nbrHours % 1) * 60
										let nbrSecondes = (nbrMinutes % 1) * 60

										if(nbrDays > 0 || nbrHours > 0 || nbrMinutes > 0 || nbrSecondes > 0)	return <div>
											End in {Math.trunc(nbrDays)} Days {Math.trunc(nbrHours)}h{Math.trunc(nbrMinutes)}m{Math.trunc(nbrSecondes)}s
										</div>
										return '';
									})()}
								</td>
								<td>{o.started}</td>
								<td>{o.ended}</td>
							</tr>
						})
					}
				</tbody>
			</table>
			{
				promoList.length === 0? <div className="w-100 text-center h5">
					No promo found
				</div> : ''
			}		
		</div>
	</div>);
}