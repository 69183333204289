// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { capitalizeFLetter, socketPerso } from '../../fnc'

const refConst = require("../../constants.js").v


export default function MarginModal(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-Mj_haqzismzadLiujp1" // order_view
	]

	const initialState = {
		required: props.required || [],
		submited: false
	}
	
	const [version, setVersion] = useState("0.5.0");
	const [required, setRequired] = useState(props.required);
	const [submited, setSubmited] = useState(initialState.submited);

	useEffect(() => {
		modalSheet(true);
	}, [required])

	useEffect(() => {
		if(JSON.stringify(props.required) !== JSON.stringify(required))
			setRequired(props.required || initialState.required)
	}, [props.required])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let submitTaskMargin = () => {

		props.info({success: `Task started, you will receive an email with the download link.`})

		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			internalReq: {
				"method": "POST",
				"api": "shipstation",
				"trigger": "get_true_margin",
				"makeReport": true,
				"userId": props.getUser().uid
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Create report for shipstation margin`
				}
			}
		})
	}

	let submitTaskTrueMargin = () => {

		props.info({success: `Task started, you will receive an email with the download link.`})

		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			internalReq: {
				"method": "POST",
				"api": "shipstation",
				"trigger": "generateCatalogMargin_v2",
				"uid": props.getUser().uid
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Create true margin report`
				}
			}
		})
	}

	let submitTaskWalmartItems = () => {

		props.info({success: `Task started, you will receive an email with the download link.`})

		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			internalReq: {
				"method": "POST",
				"api": "walmart",
				"trigger": "generate_report_catagog_file",
				"userId": props.getUser().uid
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Create report to get the Walmart Catalog`
				}
			}
		})
	}

	let submitTaskUnderPrice = () => {

		props.info({success: `Task started, you will receive an email with the download link.`})

		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			internalReq: {
				"method": "POST",
				"api": "eio",
				"trigger": "3m_warning_underpriced",
				// "sites": "shopify",
				"userId": props.getUser().uid
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Create report for 3M Under Price`
				}
			}
		})
	}

	let submitTaskShippingType = () => {

		props.info({success: `Task started, you will receive an email with the download link.`})

		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			internalReq: {
				"method": "POST",
				"api": "eio",
				"trigger": "3M_finale_shipping_type_calculator",
				"createFile": true,
				"uid": props.getUser().uid
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Create report for 3M Shipping Type`
				}
			}
		}, (passThrough) => {
			console.info("Res socket", passThrough);

			if(passThrough.errors){
				props.info({"error": passThrough.errors})
			}else{

				console.info("Success pastrhough: ", passThrough);
				/* items.forEach(o => {
					passThrough.data.forEach(pr => {
						console.info(`CHECK ${o.id_product_shopify} ${pr.id_item}`);
						if(o.id_product_shopify == pr.id_item){
							o.success = pr.success
							o.id_new_item = pr.id_new_item
							return;
						}
					})
				})
				setItems(items.slice(0)) */
			}

		})
	}

	let modalSheet = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: <span>Create xlsx report <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			html: (popup) => {

				return <div className="text-center">
					<div className="p-3 mb-3 border rounded">
						Click on confirm to create a new task - Generate a margin report
						<button type="button" className="btn btn-outline-primary ml-3" onClick={submitTaskMargin}>Generate</button>
					</div>
					<div className="p-3 mb-3 border rounded">
						Click on confirm to create a new task - Generate a true margin report based on the sales of the past 12 months
						<button type="button" className="btn btn-outline-primary ml-3" onClick={submitTaskTrueMargin}>Generate</button>
					</div>
					<div className="p-3 mb-3 border rounded">
						Click on confirm to create a new task - Generate a Walmart Catalog report
						<button type="button" className="btn btn-outline-primary ml-3" onClick={submitTaskWalmartItems}>Generate</button>
					</div>
					<div className="p-3 mb-3 border rounded">
						<div className="alert alert-warning mb-3" role="alert">
							Each price is divided by the pack/case quantity, this value is then compared to our products on each store to find out which price should be raised. a different % markup is used on each store for our calculation.
							<br/>
							This report, once generated, can be used in the repricer tool.
						</div>
						Click on confirm to create a new task - Generate a under price report for 3M
						<button type="button" className="btn btn-outline-primary ml-3" onClick={submitTaskUnderPrice}>Generate</button>
					</div>
					<div className="p-3 mb-3 border rounded">
						Click on confirm to create a new task - Generate a shipping type report for 3M
						<button type="button" className="btn btn-outline-primary ml-3" onClick={submitTaskShippingType}>Generate</button>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: null, return: {
				title: "Exit",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}
	
	if(!props.securityClearance(props, access))	return "";

	return (
		<button type="button" className={(props.className || "") +  " btn btn-outline-primary"} onClick={() => modalSheet(props.storeTo, props.storeFrom)}>
			{ props.name }
		</button>
	);
}