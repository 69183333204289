// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Loading from './Loading';
import Select from 'react-select';
import { capitalizeFLetter, ajaxPerso } from '../../fnc'
import { utils, writeFile } from 'xlsx';

const refConst = require("../../constants.js").v

var initialState = {
	pricesRules: [],
	selected: null,
}

var specialVar = {
	_isMounted: false
}

export default function CreateItem(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-Mj_haqzismzadLiujp1" // order_view
	]

	const [selected, setSelected] = useState(initialState.selected);
	const [pricesRules, setPricesRules] = useState(initialState.pricesRules);
	const [version, setVersion] = useState("0.9.0");

	useEffect(() => {
		specialVar._isMounted = true

		return () => specialVar._isMounted = false
	}, [])

	useEffect(() => {
		modal(true);
	}, [props.stores, props.store, pricesRules, selected])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let modal = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: "Promotion Report ",
			html: (popup) => {

				let objPreSelected = refConst.shopList.find(obj => {
					if(obj.storeId === props.store)
						return obj
				})
				
				return props.store === null? <Loading loading={true} centered={true}/> : <div>
					<div>
						<div className="container">
							<Select
								styles={refConst.styleList}
								onChange={(selectedOption) => {
									setSelected(selectedOption? selectedOption.value : initialState.selected)
								}}
								options={(() => {
									return pricesRules.map(o => {
										return {
											value: o.title,
											label: o.value? `${o.title} (${o.value})` : o.title
										}
									})
								})()}
								value={selected? [{value: selected, label: selected}] : null}
								isSearchable={true}
								isMulti={false}
								placeholder="Select a promo code"
							/>
						</div>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: {
				title: "Generate",
				fnc: (popup, close) => {
					props.loading(true)

					getItemsPerPromo()

					// resetPage()
					// close()
				}
			}, return: {
				title: "Return",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}

	let fetchPriceRules = (callback) => {
		ajaxPerso( {
			"api": "shopify",
			"trigger": "getPriceRules",
			"shop": props.store
		}, callback);
	}

	let getEbayitems = () => {

		if(!props.store || props.store.length === 0) return

		fetchPriceRules((r) => {
			if(!specialVar._isMounted)	return false
			if(r.success){
				console.info('Fetched prices rules', r);
				
				setPricesRules(r.data)
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let fetchItemsPerPromo = (callback) => {
		ajaxPerso( {
			"api": "shipstation",
			"trigger": "getSaledForPromoCode",
			"code": selected
		}, callback)
	}

	let getItemsPerPromo = () => {
		fetchItemsPerPromo((r) => {
			if(!specialVar._isMounted)	return false
			props.loading(false)

			if(r.success){
				console.info('Fetched prices rules', r);
				
				var book  = utils.book_new(),
					sheet = utils.json_to_sheet(r.data)

				utils.book_append_sheet(book, sheet, selected);

				writeFile(book, `report_items_sold_with_promo_${selected}.xlsx`)
			}else{
				props.info({"error": r.error})
			}
		})
	}
	
	let storeFromToNames = () => {

		if(!props.stores || props.stores.length === 0)	return ""
		
		let storeObj = props.stores.find(o => {
			if(o.id === parseInt(props.store))	return o
		})

		return storeObj? `${props.name} (${storeObj.name})` : props.name
	}

	if(!props.securityClearance(props, access))	return "";

	return (
		<div className={props.className} onClick={() => {
			getEbayitems()
			modal(false)
		}}>
			{
				storeFromToNames()
			}
		</div>
	);
}