// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons' */
import {ajaxPerso, fetchFormula} from "../fnc.js"
import store from 'store2';
import 'react-data-grid/lib/styles.css';
import DataGrid, { Row, RowRendererProps } from 'react-data-grid';
import Pagination from './ctn/eBay/Pagination.js'
import { utils, writeFile } from 'xlsx';
import moment from 'moment';

const refConst = require("../constants.js").v
var tagRefs = [];
var refNewForm =  null;

function Carrier(props){

	const [carriers, setCarriers] = useState([]);
	const [carrierRates, setCarrierRates] = useState([]);
	const [idGrpOpen, setIdGrpOpen] = useState(null);
	const [idModif, setIdModif] = useState(null);
	const [triggered, setTriggered] = useState(false);
	const [carrierSelected, setCarrierSelected] = useState(store("carrierSelected"));
	const [formula, setFormula] = useState(null);
	const [navGrpSelected, setNavGrpSelected] = useState("Setup");
	const [issues, setIssues] = useState([]);
	const [pageNbr, setPageNbr] = useState(1);
	const [pageLimit, setPageLimit] = useState(50);
	const [totalIssueFound, setTotalIssueFound] = useState(0);
	const [isFormulaModified, setIsFormulaModified] = useState(false);

	const mappingLink = {
		"eBay - EIO": "ebay_url",
		"EIO.com": "shopify_eio_url",
		"Haus of Tools": "shopify_haus_url",
		"Amazon - EIO": "amazon_url",
		"Walmart - EIO": "walmart_url"
	}

	const newEntryGrpRefs = {
		weight: React.createRef(),
		cost: React.createRef()
	};

	if(!refNewForm)
		refNewForm =  React.createRef()

	useEffect(() => {
		if(!props.securityClearance(props))	return "";
		
		fetchCarriers()
		fetchTriggerFormula()

		if(store("carrierNavSelected") != null)
			setNavGrpSelected(store("carrierNavSelected"))
	}, [])

	useEffect(() => {

		if(store("carrierNavSelected") !== navGrpSelected)
			store("carrierNavSelected", navGrpSelected)

		switch (navGrpSelected) {
		case "Result":
			fetchShippingLost(pageNbr, pageLimit, (r) => {
				setIssues(r.success? r.data : [])
				setTotalIssueFound(r.success? r.totalIssueFound : 0)
			})
			break;
		}
	}, [navGrpSelected])

	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		if(carrierSelected){
			fetchCarrierRates()
		}
	}, [carrierSelected])

	useEffect(() => {
		if(carriers.length > 0 && !store("carrierSelected")){
			setCarrierSelected(carriers[0].id)
		}
	}, [carriers])

	useEffect(() => {

		setIdModif(parseInt(props.parent.match.params.idCarrierOpen))

	}, [props.parent.match.params.idCarrierOpen])

	useEffect(() => {
		// Add focus to the first field
		if(tagRefs[idModif] && tagRefs[idModif].weight.current)
			tagRefs[idModif].weight.current.focus()

	}, [idModif])

	let dlFile = () => {
		props.loading(true)

		fetchShippingLost(1, totalIssueFound, (r) => {
			props.loading(false)

			var book  = utils.book_new(),
				sheet = utils.json_to_sheet(dataCleaned(r.data, true))
	
			utils.book_append_sheet(book, sheet, `Data`);
			writeFile(book, `price_issues_Tool-ehub_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
		})

	}

	const fetchCarriers = (callback) => {
		ajaxPerso( {
			"api": "shipstation",
			"trigger": "getCarriers",
		}, (r) => {
			console.info(`Fetched getCarriers:`, r)
			if(callback)	callback(r.success? r.data : [])
			setCarriers(r.success? r.data : [])
		})
	}

	const fetchShippingLost = (pageNumber, limit, callback) => {
		props.loading(true)
		ajaxPerso( {
			"api": "eio",
			"trigger": "triggerShippingCostIssues",
			"page": pageNumber,
			"limit": limit
		}, (r) => {
			console.info(`Fetched triggerShippingCostIssues:`, r)
			props.loading(false)
			if(callback)	callback(r)
		})
	}
	
	const fetchTriggerFormula = () => {
		fetchFormula((r) => {
			console.info(`Fetched fetchFormula:`, r)
			if(r.success){
				setFormula(r.data)
			}
		}, {name: "Shipping Cost"})
	}

	const fetchCarrierRates = (callback) => {
		ajaxPerso( {
			"api": "shipstation",
			"trigger": "getCarrierRates",
			"idCarrier": carrierSelected
		}, (r) => {
			console.info(`Fetched getCarriers:`, r)
			if(callback)	callback(r.success? r.data : [])
			setCarrierRates(r.success? r.data : [])
		})
	}

	const triggerAlterFormula = (args, callback) => {
		ajaxPerso( Object.assign({
			"api": "shipstation",
			"trigger": "alterFormula",
		}, args), (r) => {
			console.info(`Trigger alterFormula:`, r)

			if(r.res === null){
				props.info({success: "Nothing changed."});
			}else{
				if(r.res.error > 0){
					props.info({error: r.res.errorMsg.join(', ')});
				}else{
					fetchShippingLost(pageNbr, pageLimit, (r) => {
						setIssues(r.success? r.data : [])
						setTotalIssueFound(r.success? r.totalIssueFound : 0)
					})

					props.info({success: `Successfully changed (${r.res.updated}).`});
				}
			}
			if(callback)	callback(r.success? r : [])
		})
	}

	const showSeg = () => {
		if(idGrpOpen){
			// return showTags()
		}else{
			return showGroup()
		}
	}

	const addnewEntry = (data, e, callback) => {
		e.preventDefault();

		console.info('data', data);
		
		if(!data.cost.current.value || !data.weight.current.value || !carrierSelected){
			props.info({error: "Please fill the name and prefix."})
			return false;
		}

		setTriggered(true)
		if(!callback)	callback = () => {
			setTriggered(false)
		}

		let objData = {
			"id_carrier": carrierSelected,
			"cost": data.cost.current.value.trim(),
			"weight_lb": data.weight.current.value.trim()
		}

		if(idModif)
			objData.id = idModif

		console.info('objData', objData);

		ajaxPerso({
			"api": "shipstation",
			"trigger": "addCarrierRate",
			"data": JSON.stringify(objData),
			"verbose": false
		}, callback)
	}

	const triggerDel = () => {

		setTriggered(true)

		ajaxPerso({
			"api": "shipstation",
			"trigger": "delCarrierRates",
			"id": idModif,
			"verbose": false
		}, (r) => {
			console.info('Fetch: delCarrierRates', r);
			setTriggered(false)

			if(r.success){
				fetchCarrierRates()
				props.info({success: "Rate deleted."})
			}
		})
	}

	let stickyHeader = (item) => {
		// console.info('itemitemitemitem', item);
		return <span className="mr-2">{item.column.name}</span>;
	}

	let cellRenderer = (cell) => {
		console.info('itemitemitemitem', cell);
		console.info('issues', issues);
		// let pos = Object.keys(mappingLink).indexOf(cell.column.name)
		let pos = cell.column.key.indexOf("lost,")
		if(pos !== -1){
			let storeName = cell.column.key.substr("lost, ".length)

			//return <span className="fixed-top text-center mr-2">{createLink(issues[cell.row.id], cell, mappingLink[Object.keys(mappingLink)[pos]])}</span>;
			return <span className=" mr-2">{createLink(issues[cell.row.id], cell, mappingLink[storeName])}</span>;
		}else
			return <span className=" mr-2">{cell.column? cell.row[cell.column.name] : ""}</span>;
	}

	let createLink = (issueObj, cell, propLost) => {
		return <span className=" mr-2">{issueObj && cell.column.name in cell.row && cell.row[cell.column.name] > 0? <a href={issueObj[propLost]} target="_blank" rel="noopener noreferrer">{cell.row[cell.column.name]}</a> : "" }</span>;
	}
	
	const defaultColumnProperties = {
		resizable: true,
		headerRenderer: stickyHeader,
		formatter : cellRenderer
	}

	const dataCleaned = (data, addUrls) => {

		let isCp = issues

		if(data)	isCp = data
		if(isCp.length === 0) return []

		let storeNamePerId = {};
		refConst.stores.forEach(o => {
			storeNamePerId[o.id] = o.name
		})

		return isCp.map(o => {
			let objout = {
				"Vendor": o.vendor,
				"Sku": o.sku,
				"Title": o.title,
				"Cost": o.cost,
				"Weight (LB)": o.weight,
				"Rate Cost": o.rate.cost,

				"Price eBay": o.price_ebay,
				"Price Shopify EIO": o.price_shopify_eio,
				"Price Shopify Haus": o.price_shopify_haus,
				"Price Walmart": o.price_walmart,
				"Price Amazon EIO": o.price_amazon_eio,
				"Price Amazon GOTT": o.price_amazon_haus,

				// "min Price": o.minSellingPrice, // This is now a list.

				/* "eBay Lost": o.price_ebay_lost,
				"Eio Lost": o.price_shopify_eio_lost,
				"Haus Lost": o.price_shopify_haus_lost,
				"Amazon Lost": o.price_amazon_lost,
				"Walmart Lost": o.price_walmart_lost, */

				"AVG Lost": o.avgLost,
				"True cost": o.true_shipping_cost? '1' : '0'
			}

			for (const idStore in o.sellingAtLost) {
				if (Object.hasOwnProperty.call(o.sellingAtLost, idStore)) {
					const lostAmount = o.sellingAtLost[idStore];
					objout[`lost, ${storeNamePerId[idStore]}`] = lostAmount

					const minPrice = o.minSellingPrice[idStore];
					objout[`min Price, ${storeNamePerId[idStore]}`] = minPrice;
				}
			}

			// Add each URL to the JSON object
			/* if(addUrls){
				let urlsObject = {}
				Object.values(mappingLink).forEach(key => {
					urlsObject[key] = o[key]
				});
				
				objout = Object.assign(objout, urlsObject)
			} */

			return objout
		})
	}

	const showIssues = () => {

		if(issues.length === 0) return ""

		let issueBeautified = dataCleaned()

		issueBeautified.forEach((o, pos) => {
			o.id = pos
		})
		
		return <DataGrid
			headerRenderer={stickyHeader}
			cellRenderer={cellRenderer}
			/* className='rdg-light' */
			/* columns={columns(Object.keys(items.original[0]).length)} */
			columns={Object.keys(issueBeautified[0]).map(k => (
				Object.assign({
					key: k,
					name: k
				}, defaultColumnProperties)
			))}
			rows={issueBeautified}
		/>
	}

	const showPagination = () => {

		if(totalIssueFound === 0) return ""

		return <Pagination 
			current_page={pageNbr}
			total_items={totalIssueFound}
			qtyPerPage={pageLimit}
			className="mb-3"
			onUpdatePage={(e, pageNumber) => {
				setPageNbr(pageNumber)
				fetchShippingLost(pageNumber, pageLimit, (r) => {
					setIssues(r.success? r.data : [])
					setTotalIssueFound(r.success? r.totalIssueFound : 0)
				})
			}}
			limit_per_page={pageLimit}
		/>
	}

	const showGroup = () => {
		
		let carrier = carriers.find(o => {
			if(o.id === carrierSelected)	return o
			return false
		})

		return(
			<div className="container w-100">
				<div className="row">
					<div className="col-md-3 p-3">
						Weight
					</div>
					<div className="col-md-3 p-3">
						Cost
					</div>
					<div className="col-md-3 p-3 text-center">
						Added at
					</div>
				</div>
				<form onSubmit={(e) => addnewEntry(newEntryGrpRefs, e, (r) => {
					setTriggered(false)
					if(refNewForm.current)
						refNewForm.current.reset()

					if(r.success){
						fetchCarrierRates()
						props.info({success: "Rate added."})
					}

				})} ref={refNewForm} className="form-group">
					<div className="row bg-info">
						<div className="col-md-3 p-3">
							<input type="number" step="0.01" className="w-100 form-control" id={`input_tag_shopify_new`} autoFocus={true} ref={newEntryGrpRefs.weight} placeholder="Weight in lb"/>
						</div>
						<div className="col-md-3 p-3">
							<input type="number" step="0.01" className="w-100 form-control" id={`input_tag_img_shopify_new`} ref={newEntryGrpRefs.cost} placeholder="Cost"/>
						</div>
						<div className="col-md-3 p-3 text-center">
							<button type="submit" className="btn-primary btn btn-sm ml-3">Add</button>
						</div>
					</div>
				</form>
				<div>
					Count: {carrierRates.length}
				</div>
				{
					carrierRates.length > 0? carrierRates.map(t => {

						if(!tagRefs[t.id]){
							tagRefs[t.id] = {
								"weight": React.createRef(),
								"cost": React.createRef()
							}
						}

						return <form key={`${t.id}_tagGrpNew`} onSubmit={(e) => addnewEntry(tagRefs[t.id], e)} className="form-group">
							<div key={`${t.id}_tagGrp`} className="row">
								<div className="col-md-3 p-3">
									<input type="number" step="0.01" className="w-100 form-control" defaultValue={t.weight_lb==null? 0.0 : t.weight_lb} ref={tagRefs[t.id].weight} disabled={idModif === t.id? false : true} id={`input_tag_weight_shopify_${t.id}`} />
								</div>
								<div className="col-md-3 p-3">
									<input type="number" step="0.01" className="w-100 form-control" defaultValue={t.cost} ref={tagRefs[t.id].cost} disabled={idModif === t.id? false : true} id={`input_tag_cost_shopify_${t.id}`} />
								</div>
								<div className="col-md-3 p-3">
									{t.created_at}
								</div>
								<div className="col-md-3 p-3 text-center">
									<button type="button" className={(idModif === t.id? "bg-dark text-light" : "btn-primary") + " btn btn-sm"} onClick={(e) => {
										setIdModif(idModif === t.id? null : t.id)
									}}>{idModif === t.id? "Close" : "Modify"}</button>
									{
										idModif === t.id? <button type="button" className="btn-danger btn btn-sm ml-3 " disabled={triggered} onClick={triggerDel}>Delete</button> : ''
									}
									{
										idModif === t.id? <button type="submit" className="btn-primary btn btn-sm ml-3 " disabled={triggered}>Save</button> : ''
									}
								</div>
							</div>
						</form>
					}) : <div>No Rate for {carrier? carrier.name : ""}</div>
				}
			</div>
		)
	}

	const boxSetup = () => {
		
		return <div>
			<div className="input-group mb-4 mt-2">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Carrier</span>
				</div>
				<select className="custom-select font-weight-bold" value={ carrierSelected? carrierSelected : "" } onChange={(e) => {
					store("carrierSelected", e.target.value)
					setCarrierSelected(e.target.value)
				}}>
					{
						carriers.map(obj => {
							return <option key={obj.name} value={obj.id}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<div className="d-flex flex-row flex-nowrap w-100" style={{overflowX: "auto"}}>
				{showSeg()}
			</div>
		</div>
	}

	const boxResult = () => {
		
		return <div>
			{
			/* 	props.loading()? <Loading loading={true} centered={true}/> : */ <div>
					<div className="container p-3">
						Total Issue found: {totalIssueFound}
						{ totalIssueFound? <button type="button" className="btn btn-primary ml-3" onClick={dlFile}>Export (xlsx)</button> : '' }
					</div>
					{ showIssues() }
					{ showPagination() }
				</div>
			}
		</div>
	}

	if(!props.securityClearance(props))	return "";
	

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			{/* <div className="alert alert-primary text-wrap w-100" role="alert">
				You can add or update a group that will represent a type of tags, then you will be able to attache tag name to it., the new tag goup will be instantly available on the Shopify listing page.
			</div>
			<div className="alert alert-danger text-wrap w-100" role="alert">
				You have to be very carefull if you are modifying a tag because all the corresponding tag used on the store will have to be updated separatly to represent your new value.
			</div> */}
			<div className="border mb-3 p-3 w-100 bg-light">
				<div className="alert alert-primary" role="alert">
					Variable available:
					<ul className="m-0">
						<li>cost</li>
						<li>shipping_cost</li>
					</ul>
				</div>
				<div className="alert alert-info" role="alert">
					For all the products sold in the past 12 months, the true shipping cost is used instead of the static value.
				</div>
				<div className="alert alert-info" role="alert">
					The threshold is the amount in $ minimum to get after substracting the shipping cost.
				</div>
				{
					formula && formula.length > 0? formula.map( o => {

						let store = refConst.stores.find(s => {
							if(s.id == o.id_marketplace)	return o
						})

						return <div key={`formula_${o.id}`} className="mt-3">
							<div className="d-flex flex-row">
								<div className="input-group w-25 mr-3">
									<div className="input-group-prepend">
										<span className="input-group-text" id="basic-formula">Store</span>
									</div>
									<input type="text" className="form-control bg-white" disabled placeholder="store" aria-describedby="basic-store" defaultValue={store.name}/>
								</div>
								<div className="input-group w-75">
									<div className="input-group-prepend">
										<span className="input-group-text" id="basic-formula">Formula</span>
									</div>
									<input type="text" className="form-control" placeholder="Formula" aria-describedby="basic-formula" defaultValue={o.formula} onChange={e => {
										o.formula = e.target.value;
										o.modified = true
										setIsFormulaModified(true)
										return e
									}}/>
								</div>
								<div className="input-group w-25 ml-3" style={{width: "200px"}}>
									<div className="input-group-prepend">
										<span className="input-group-text" id="basic-threshold">Threshold</span>
									</div>
									<input type="number" step="0.01" className="form-control" placeholder="Formula" aria-describedby="basic-threshold" defaultValue={o.threshold}  onChange={e => {
										o.threshold = e.target.value;
										o.modified = true
										setIsFormulaModified(true)
										return e
									}}/>
								</div>
							</div>
						</div>
					}) : ''
				}
				{
					<div className="d-flex justify-content-center mt-3">
						<button className="btn btn-primary" onClick={() => {

							let fUpdate = formula.filter(o => {
								if(o.modified)	return o
							})

							console.info('fUpdate', fUpdate);

							triggerAlterFormula({
								data: JSON.stringify(fUpdate)
							})

						}}>Modify</button>
					</div>
				}
			</div>
			<hr className="w-100 mt-3"/>

			<div className="w-100">
				<nav>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						<a key={"Setup_keyGrpTitleNav"} onClick={() => { setNavGrpSelected("Setup"); }} className={
							(navGrpSelected === "Setup"? "active" : "") + " nav-item nav-link text-center"
						} id={"Setup_navBtn"} data-toggle="tab" href={"#Setup_navCtn"} role="tab" aria-controls={"Setup_navCtn"} aria-selected={false}>
							<div>Setup</div>
						</a>
						<a key={"Result_keyGrpTitleNav"} onClick={() => { setNavGrpSelected("Result"); }} className={
							(navGrpSelected === "Result"? "active" : "") + " nav-item nav-link text-center"
						} id={"Result_navBtn"} data-toggle="tab" href={"#Result_navCtn"} role="tab" aria-controls={"Result_navCtn"} aria-selected={false}>
							<div>Result</div>
						</a>
					</div>
				</nav>
				<div className="tab-content pt-3 position-relative" id="nav-tabContent">
					{
						navGrpSelected === "Setup"? boxSetup() : ""
					}
					{
						navGrpSelected === "Result"? boxResult() : ""
					}
				</div>
			</div>
			
		</div>
	);
}

export default Carrier;