// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import {ajaxPerso, socketPerso} from "../fnc.js"
import avatar from '../img/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { TransitionGroup, SwitchTransition, CSSTransition } from 'react-transition-group';
import store from 'store2';
const refConst = require("../constants.js").v

var initialState = {
	_isMounted: false
}

var mode = "out-in"
var sendTesting = true

export default function Winning(props){
	const [accountsPerUid, setAccountsPerUid] = useState({});
	const [isSmall, setIsSmall] = useState(store("winningIsSmall")? true : false);
	const [isOpen, setIsOpen] = useState(store("winningIsOpen")? true : false);

	
	useEffect(() => {
		console.info('TESTST', props);
	}, [])
	
	useEffect(() => {
		if(!props.accounts || props.accounts.length === 0)	return;
		/**
		 * Organise our accounts so that we can get the object per UID
		 */
		let list = {};
		props.accounts.forEach(o => {
			list[o.id_firebase] = o
		})
		setAccountsPerUid(list)
		
	}, [props.accounts])
	
	useEffect(() => {
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	const showData = () => {		

		if(!props.winnerList || !props.winnerList.percentBase)	return;
		if(Object.keys(accountsPerUid).length === 0)	return;
		let ouptut = [];

		let iPos = 0
		for (const uid in props.winnerList.percentBase) {
			if (Object.hasOwnProperty.call(props.winnerList.percentBase, uid)) {
				const scorePrc = props.winnerList.percentBase[uid];
				let email = accountsPerUid[uid].data_firebase.email,
					list = props.winnerList.list,
					insertList = list.filter(o => {
						if(o.type == "insert" && o.uid === uid)	return o
					}),
					insertCount = insertList.length > 0? insertList.map((o1) => o1.count).reduce((v1,v2) => v1+v2) : 0,
					updateList = list.filter(o => {
						if(o.type == "update" && o.uid === uid)	return o
					}),
					updateCount = updateList.length > 0? updateList.map( (o1) => o1.count).reduce((v1,v2) => v1+v2) : 0

				/* console.info('insertCount', insertCount);
				console.info('updateCount', updateCount); */
				let emailCleaned = email.substr(0, email.indexOf("@"))
				
				ouptut.push(<CSSTransition
					key={`${uid}_${iPos}`}
					timeout={1020}
					classNames="userGrp"
				>
					{
						<div key={`${uid}_winningList`} className="ctn_user_winning d-flex flex-row justify-content-around w-auto align-items-center">
							<div className="number_winning text-center flex-fill">
								{iPos+1}
							</div>
							<div className="img_winning text-center align-self-start" onClick={() => {
								setIsSmall(!isSmall)
								store("winningIsSmall", !isSmall)
							}}>
								{ accountsPerUid[uid].avatar? <img src={avatar} title={emailCleaned}/> : <FontAwesomeIcon className="iconUser" icon={faUser} style={{color: "rgba(13, 41, 167, 0.836)", marginTop: "5%", width: "90%", height: "90%"}} title={emailCleaned}/> }
							</div>
							<div className="ctn_data_winning bg-white text-left">
								<div className="font-weight-bold">{emailCleaned}</div>
								<div>Score: <span className="font-weight-bold">{Math.round(scorePrc)}</span></div>
								<div>Insert: { insertCount }</div>
								<div>Update: { updateCount }</div>
							</div>
						</div>
					}
				</CSSTransition>)
				iPos++
			}
		}
		return <TransitionGroup className={(isOpen? "" : "closeWinning") + " winningList"}>{ouptut}</TransitionGroup>
	}

	return (
		<div className={(isSmall? "" : "smallWinning") + " ctn_winning_section position-fixed text-right"}>
			<button onClick={() => {
				setIsOpen(!isOpen)
				store("winningIsOpen", !isOpen)

				/* sendTesting = !sendTesting
				socketPerso("askForWinningList", {
					"url": refConst.urlServer,
					"testing": sendTesting
				}) */
			}}>
				<FontAwesomeIcon className="winningHideButton" icon={faSignOutAlt} style={{}} title={"Hide"}/>
			</button>
			{showData()}
		</div>
	);
}