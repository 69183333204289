// Config file
import firebase from "firebase/app";
// eslint-disable-next-line
import database from "firebase/database";
// eslint-disable-next-line
import auth from "firebase/auth";

var config = {
	apiKey: "AIzaSyBrB9Ueibr1QtC75BkMt4-gGuF9VQnEqWI",
	authDomain: "ebayapp-85a68.firebaseapp.com",
	databaseURL: "https://ebayapp-85a68.firebaseio.com",
	projectId: "ebayapp-85a68",
	storageBucket: "ebayapp-85a68.appspot.com",
	messagingSenderId: "267452126550"
};

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
/*
console.log(firebase);
console.log(firebase.database());
console.log(firebase.auth());
*/