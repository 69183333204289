import React, { Component } from 'react';
import DB from '../../../db.js'
import Chart from 'chart.js/auto';
import $ from 'jquery';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import logoStoreEio from '../../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../../img/ebay_circle_perso.png';
import {
	NavLink
} from 'react-router-dom';
import {ajaxPerso} from "../../../fnc.js"
import UserAlert from "./UserAlert"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
  } from 'chart.js';import { Line } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

var moment = require('moment');
const refConst = require("../../../constants.js").v


class UserGraph extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		
		// this.userIdSelected = this.props.match.params.idUser;
		this.firebase = DB.firebase

		// Bind Allow access by "this" into the function
		this.loadItems = this.loadItems.bind(this);
		this.handleDayClick = this.handleDayClick.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.filterDate = this.filterDate.bind(this);
		this.filterByDay = this.filterByDay.bind(this);
		this.ItemsLoaded = this.ItemsLoaded.bind(this);
		this.setChart = this.setChart.bind(this);
		this.updateChart = this.updateChart.bind(this);
		this.showItemsCount = this.showItemsCount.bind(this);
		this.userData = this.userData.bind(this);
		this.state = this.getInitialState();
		
		this.version = "1.0.0"
		this.graphShopInitalState = {
			'eio': {
				stateName: "productsShopify_eio",
				tabItemDaily: [],
				tabDate: [],
				borderColor: 'rgb(255, 80, 0)',
				showItem: (stateName) => {
					return {
						head: <thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">SKU</th>
								<th scope="col">Title</th>
								<th scope="col">Picture</th>
							</tr>
						</thead>,
						body: <tbody>
							{
								this.state[stateName].map((obj) => {

									let shop = refConst.shopList.find(o => {
										if(o.storeId === parseInt(obj.id_marketplace))	return o
									})

									return <tr key={obj.id}>
										<th scope="row">{obj.id}</th>
										<td className="d-flex justify-content-between">
											{obj.shopify_sku}
											<div>
												<button type="button" className="btn btn-primary btn-sm"><a href={refConst.finaleLinkProduct + btoa('/eio/api/product/' + obj.shopify_sku)} target="_blank" rel="noopener noreferrer" className="text-white">Finale</a></button>
												<button type="button" className="btn btn-primary btn-sm ml-3"><a href={'https://' + shop.shopName + '.myshopify.com/admin/products/' + obj.id_product}  target="_blank" rel="noopener noreferrer" className="text-white">Marketplace</a></button>
											</div>
										</td>
										<td>{obj.shopify_title}</td>
										<td>{ obj.shopify_img? <img src={obj.shopify_img} height="30" width="30"/> : "" }</td>
									</tr>
								})
							}
						</tbody>
					}
				}
			},
			'hausoftools': {
				stateName: "productsShopify_haus",
				tabItemDaily: [],
				tabDate: [],
				borderColor: 'rgb(52, 255, 184)',
				showItem: (products) => {
					return this.graphShop.eio.showItem(products)
				}
			},
			'autoCreator': {
				stateName: "autoCreator",
				tabItemDaily: [],
				tabDate: [],
				borderColor: 'rgb(52, 191, 255)',
				showItem: (stateName) => {
					console.info("productsproducts", this.state[stateName]);
					return {
						head: <thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Count</th>
							</tr>
						</thead>,
						body: <tbody>
							{
								this.state[stateName].map((o) => {
									return <tr key={o.date}>
										<td>{o.date}</td>
										<td>{o.count}</td>
									</tr>
								})
							}
						</tbody>
					}
				}
			},
			'ebay': {
				stateName: "productsEbay_eio",
				tabItemDaily: [],
				tabDate: [],
				borderColor: 'rgb(0, 0, 255)',
				showItem: (stateName) => {
					return {
						header: <div>
							<div className="alert alert-info mt-3" role="alert">
								Items that have been recently created shown with some missing informations will appear normally once the Cron task refreshing the catalog will be done.
							</div>
						</div>,
						head: <thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">SKU</th>
								<th scope="col">Title</th>
								<th scope="col">Picture</th>
							</tr>
						</thead>,
						body: <tbody>
							{
								this.state[stateName].map((o) => {
									let obj = o
									return <tr key={o.id}>
										<th scope="row">{o.id}</th>
										<td><a href={obj.ebay_url} target="_blank" rel="noopener noreferrer">{obj.ebay_sku}</a></td>
										<td>{obj.ebay_title}</td>
										<td><img src={obj.ebay_img} height="30" width="30"/></td>
									</tr>
								})
							}
						</tbody>
					}
				}
			}
		}

		this.graphShop = Object.assign({}, this.graphShopInitalState);
	}

	componentDidMount() {
		this.initialState = this.state
		
		this.props.loading(false)
		//this.loadItems();
		//this.loadItemCount();
	}

	componentDidUpdate(prevProps) {
	}

	getInitialState() {
		return {
			from: undefined,
			to: undefined,
			productsCount: 0,
			productsShopify_eio: [],
			productsShopify_haus: [],
			productsEbay_eio: [],
			autoCreator: [],
			navSelected: [],
			chart: null,
			userData: null,
			chartData: null,
			//timeSelected: "day"
		};
	}

	filterByDay(products){
		let tabDate = [],
			tabItemDaily = []

		// Specific day
		for (let i = 1; i <= 24; i++) {
			tabDate.push(i + "H")
			tabItemDaily.push(0)
		}

		if(products.length > 0){
			let lastItemDate = products[products.length-1].date
			lastItemDate = moment(lastItemDate).format("MM-DD-YYYY")
	
			products.map((obj) => {
				let currentDate = moment(obj.date).format("MM-DD-YYYY")
	
				if(currentDate === lastItemDate){
					let hour = moment(obj.date).get('hour');
	
					tabItemDaily[hour]++
				}
			})
		}

		return {
			tabDate: tabDate,
			tabItemDaily: tabItemDaily
		}
	}

	setChart(argChart){
		this.setState({chart: argChart})
	}

	getLabelsByDay(){
		let tabDate = []

		// Specific day
		for (let i = 1; i <= 24; i++) {
			tabDate.push(i + "H")
		}
		return tabDate
	}

	ItemsLoaded(){
		let returnValue = false
		Object.keys(this.graphShop).forEach(sourceName => {
			if(this.state[this.graphShop[sourceName].stateName].length > 0)
				returnValue = true
		})
		return returnValue
	}

	getLabelsByRange(){
		let tabDate = []

		let nbrDays = moment(this.state.to).diff(moment(this.state.from), 'days') + 1;

		console.log("nbrDays", nbrDays);
		
		let dayForGraph = moment(this.state.from).subtract(1, 'days');

		for (let i = 1; i <= nbrDays; i++) {
			let dayPlusOne = dayForGraph.add(1, 'days')
			tabDate.push(dayPlusOne.format("YYYY-MM-DD"))
		}

		return tabDate
	}

	filterByRange(products){
		let tabDate = [],
			tabItemDaily = []

		if(products.length === 0)	return {}

		let lastItemDate = products[products.length-1].date
		lastItemDate = moment(lastItemDate).format("MM-DD-YYYY")

		let nbrDays = moment(this.state.to).diff(moment(this.state.from), 'days') + 1;

		console.log("nbrDays", nbrDays);
		
		let dayForGraph = moment(this.state.from).subtract(1, 'days');

		// Specific day
		for (let i = 1; i <= nbrDays; i++) {
			let dayPlusOne = dayForGraph.add(1, 'days')
			tabDate.push(dayPlusOne.format("YYYY-MM-DD"))
			tabItemDaily[dayPlusOne.get('date')] = 0
		}

		products.map((obj) => {
			let currentDate = moment(obj.date).format("MM-DD-YYYY")

			let day = moment(obj.date).get('date');			

			if("count" in obj)
				tabItemDaily[day] += obj.count
			else
				tabItemDaily[day]++
			//previousDate = currentDate
		})

		let tabTemp = [];
		tabItemDaily.forEach(val => {
			tabTemp.push(val);
		})
		tabItemDaily = tabTemp;

		return {
			tabDate: tabDate,
			tabItemDaily: tabItemDaily
		}
	}

	updateChart(){
		console.log(document.querySelector("#ctnChart"));

		/* if(this.state.productsEbay_eio.length === 0)
			return false */

		let labels = [];

		if( moment(this.state.from).format("YYYY-MM-DD") == moment(this.state.to).format("YYYY-MM-DD") ){

			this.graphShop.ebay = Object.assign(this.graphShop.ebay, this.filterByDay( this.state.productsEbay_eio ))
			this.graphShop.eio = Object.assign(this.graphShop.eio, this.filterByDay( this.state.productsShopify_eio ))
			this.graphShop.hausoftools = Object.assign(this.graphShop.hausoftools, this.filterByDay( this.state.productsShopify_haus ))
			this.graphShop.autoCreator = Object.assign(this.graphShop.autoCreator, this.filterByDay( this.state.autoCreator ))
			labels = this.getLabelsByDay()
		}else if(this.state.from && this.state.to){
			// Range
			
			this.graphShop.ebay = Object.assign(this.graphShop.ebay, this.filterByRange( this.state.productsEbay_eio ))
			this.graphShop.eio = Object.assign(this.graphShop.eio, this.filterByRange( this.state.productsShopify_eio ))
			this.graphShop.hausoftools = Object.assign(this.graphShop.hausoftools, this.filterByRange( this.state.productsShopify_haus ))
			this.graphShop.autoCreator = Object.assign(this.graphShop.autoCreator, this.filterByRange( this.state.autoCreator ))
			labels = this.getLabelsByRange()
		}

		console.warn(this.graphShop);
		
		//if(document.querySelector("#ctnChart")){
			
			let dataSets = [];
			for (const sourceName in this.graphShop) {
				if (this.graphShop.hasOwnProperty(sourceName)) {
					const dataSet = this.graphShop[sourceName];
					dataSets.push({
						label: sourceName,
						//backgroundColor: 'rgb(255, 99, 132)',
						borderColor: dataSet.borderColor,
						data: dataSet.tabItemDaily
					})
				}
			}

			this.setState({chartData: {
				labels,
				datasets: dataSets
			}})

			/* if(!this.state.chart){
				console.warn("Create new one");

				this.setChart( new Chart(document.querySelector("#ctnChart"), {
					// The type of chart we want to create
					type: 'line',
					// The data for our dataset
					data: {
						labels: labels,
						datasets: dataSets
					},
				
					// Configuration options go here
					options: {
						//responsive: false,
						aspectRatio: 4
					}
				}) )

			}else{
				console.warn("Update chart", dataSets);

				//chart.destroy()
				let currentChart = this.state.chart
				currentChart.data = {
					labels: labels,
					datasets: dataSets
				};
				currentChart.update();
				this.setChart( currentChart )
			} */
		//}
	}

	userData() {
		if(this.props.userData.uid)
			return this.props.getUsers(this.props.userData.uid);
		return ""
	}

	filterDate() {
		let startAt = false,
			endAt = false;

		// day
		//console.log(this.state.from + "==" + this.state.to, moment(this.state.from).format("YYYY-MM-DD") == moment(this.state.to).format("YYYY-MM-DD"));
		if(this.state.from && this.state.to){
			startAt = moment(this.state.from).format("YYYY-MM-DD 00:00");
			endAt = moment(this.state.to).format("YYYY-MM-DD 23:59");
		}

		return (startAt && endAt)? {
			dateFrom: startAt,
			dateTo: endAt
		} : false
	}

	loadItems() {

		let filter = this.filterDate()

		console.info('Filter', filter);
		let self = this 

		if(filter){
			self.graphShop = Object.assign({}, self.graphShopInitalState);

			/* ajaxPerso( Object.assign({
				"api": "eio",
				"trigger": "get_history_insert",
				"uid": this.userIdSelected,
			}, filter), (r) => {
				console.log("test", r);
				if(r.success){
					this.setState({ "productsEbay_eio": r.data}, () => {
						console.info('self.state.productsEbay_eio', r.data);
						
						//if(r.data.length > 0)
						self.updateChart();
					})
				}
			}, function(jqXHR, textStatus, errorThrown) {
				this.props.info({error: textStatus})
				console.warn( textStatus );
			}); */

			ajaxPerso( Object.assign({
				"api": "eio",
				"trigger": "get_history_insert",
				"uid": this.props.userData.uid,
				// "source": "shopify"
			}, filter), (r) => {
				console.log("Fetched_get_history_insert", r);
				if(r.success){
					let itemEio = [],
						itemHaus = [],
						itemEbayEio = [],
						clonedItems = [];

					r.data.forEach(obj => {
						if(obj.id_marketplace == refConst.id_store_eio)
							itemEio.push(obj)
						else if(obj.id_marketplace == refConst.id_store_hausfTools)
							itemHaus.push(obj)
						else if(obj.id_marketplace == refConst.id_store_eBay_EIO)
							itemEbayEio.push(obj)
						else if(obj.trigger.indexOf("cloneFromOtherStore") !== -1)
							clonedItems.push(obj)
					})

					this.setState({
						"productsShopify_eio": itemEio,
						"productsShopify_haus": itemHaus,
						"productsEbay_eio": itemEbayEio,
						"productsCloned": clonedItems
					}, () => {
						self.updateChart();
					})

					/* if(r.data.length === 0){
						this.props.info({"error": "0 item found at the selected date."})
					} */
				}else this.props.info({error: r.error})
			}, function(jqXHR, textStatus, errorThrown) {
				this.props.info({error: textStatus})
				console.warn( textStatus );
			});

			ajaxPerso( Object.assign({
				"api": "eio",
				"trigger": "get_history_insert_autoCreator",
				"uid": this.props.userData.uid,
				// "source": "shopify"
			}, filter), (r) => {
				console.log("test", r);
				if(r.success){

					this.setState({
						"autoCreator": r.data
					}, () => {
						self.updateChart();
					})

					/* if(r.data.length === 0){
						this.props.info({"error": "0 item found at the selected date."})
					} */
				}else this.props.info({error: r.error})
			}, function(jqXHR, textStatus, errorThrown) {
				this.props.info({error: textStatus})
				console.warn( textStatus );
			});
		}
	}


	handleDayClick(day) {
		let range = DateUtils.addDayToRange(day, this.state);

		console.log("range", range);
		console.log(!range.from && !range.to);

		if(!range.from && !range.to){
			range = Object.assign(range, this.getInitialState())
			if(this.chart){
				this.chart.destroy();
			}
		}

		this.setState(range, () => {
			this.loadItems();
		});
	}
	
	handleResetClick() {
		if(this.state.chart)
			this.state.chart.destroy();
		this.setState(this.getInitialState());
	}

	showItemsCount() {
		
		let eioInsert = 0,
			eioUpdate = 0,
			hausOfToolsInsert = 0,
			hausOfToolsUpdate = 0,
			ebayInsert = 0,
			ebayUpdate = 0,
			autoCreator = 0,
			clonedCount = 0;

		this.state.productsShopify_eio.forEach(o => {
			if(o.type == "insert")
				eioInsert++;
			else
				eioUpdate++;
		})

		this.state.productsShopify_haus.forEach(o => {
			if(o.type == "insert")
				hausOfToolsInsert++;
			else
				hausOfToolsUpdate++;
		})

		this.state.productsEbay_eio.forEach(o => {
			if(o.type == "insert")
				ebayInsert++;
			else
				ebayUpdate++;
		})

		this.state.autoCreator.forEach(o => {
			autoCreator += o.count;
		})

		clonedCount = this.state.productsCloned.length;

		return (
			<div className="d-flex flex-row">
				<div className="flex-grow-1">
					<div className="d-flex align-items-center">
						<img src={logoStoreEio} className="float-left mr-3" style={{height: "35px"}}></img>
						<span>
							<div>Added: <span className="font-weight-bold">{ eioInsert }</span></div>
							<div>Updated: <span className="font-weight-bold">{ eioUpdate }</span></div>
						</span>
					</div>
					<div className="d-flex align-items-center">
						<img src={logoStoreHaus} className="float-left mr-3" style={{height: "35px"}}></img>
						<span>
							<div>Added: <span className="font-weight-bold">{ hausOfToolsInsert }</span></div>
							<div>Updated: <span className="font-weight-bold">{ hausOfToolsUpdate }</span></div>
						</span>
					</div>
					<div className="d-flex align-items-center">
						<img src={logoStoreEbay} className="float-left mr-3" style={{height: "35px"}}></img>
						<span>
							<div>Added: <span className="font-weight-bold">{ ebayInsert }</span></div>
							<div>Updated: <span className="font-weight-bold">{ ebayUpdate }</span></div>
						</span>
					</div>
				</div>
				<div className="align-self-center">
					<div>Total Insert: <span className="font-weight-bold">{ eioInsert + hausOfToolsInsert + ebayInsert }</span></div>
					<div>Total update: <span className="font-weight-bold">{ eioUpdate + hausOfToolsUpdate + ebayUpdate }</span></div>
					<div>Total Auto Creator: <span className="font-weight-bold">{ autoCreator }</span></div>
					<div>Total Cloned: <span className="font-weight-bold">{ clonedCount }</span></div>
				</div>
			</div>
		)
	}

	
	render() {

		if(!this.props.userData)	return "Loading";

		const { from, to } = this.state;
		return (
			<div className="main ctnPage">
				<div className="ctnInfoUser d-flex flex-row justify-content-start p-4">
					<div className="w-100">
						<div className="row m-0">
							<div className="col-2 p-3 border">
								Account:
							</div>
							<div className="col-sm p-3 border">
								{ this.props.userData.uid }
							</div>
						</div>
						<div className="row m-0">
							<div className="col-2 p-3 border">
								Email:
							</div>
							<div className="col-sm p-3 border">
								{ this.props.userData.email }
							</div>
						</div>
						<div className="row m-0">
							<div className="col-2 p-3 border">
								Alert:
							</div>
							<div className="col-sm p-3 border">
								<UserAlert
									{...this.props}
								/>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-5 p-3 border">
								Date selected:
							</div>
							<div className="col-sm p-3 border">
								{ !from && !to && 'Please select the first day.' }
								{ from && !to && 'Please select the last day.' }
								{
									from && to && `Selected from ${from.toLocaleDateString()} to ${to.toLocaleDateString()}`
								}{' '}
								{
									from && to && ( <button type="button" className="btn btn-primary ml-3" onClick={this.handleResetClick}>Reset</button>)
								}
							</div>
						</div>
						{
							this.state.productsShopify_eio.length > 0 || this.state.productsShopify_haus.length > 0 || this.state.productsEbay_eio.length > 0 || this.state.autoCreator.length > 0?
								<div className="row m-0">
									<div className="col-5 p-3 border">
										Item Updated on Shopify:
									</div>
									<div className="col-sm p-3 border">
										{
											this.showItemsCount()
										}
									</div>
								</div> : ""
						}
					</div>
					<div className="ctnDatePicker border">
						{(()=>{console.log([from, { from, to }])})()}
						<DayPicker
							className="Selectable"
							selectedDays={[from, { from, to }]}
							modifiers={{ start: this.state.from, end: to }}
							onDayClick={this.handleDayClick}
							onMonthChange={(month) => {
								console.log("mounth", month);
								let monthObj = moment(month).format("YYYY-MM-DD")
							}}/>
					</div>
				</div>

				<canvas id="ctnChart"></canvas>
				{
					this.state.chartData? <Line options={{
						//responsive: false,
						aspectRatio: 4
					}} data={this.state.chartData} /> : ""
				}

				{
					this.ItemsLoaded()? <div>
						<nav className="mt-4">
							<div className="nav nav-tabs" id="nav-tab" role="tablist">
								{
									Object.keys(this.graphShop).map((sourceName) => {
										if(this.state[this.graphShop[sourceName].stateName].length > 0)
											return <a key={sourceName+"_keyGrpTitleNav"} onClick={() => { this.setState({"navSelected": sourceName}); }} className={
												(this.state.navSelected === sourceName? "active" : "") + 
												" nav-item nav-link text-center"} id={sourceName + "_navBtn"} data-toggle="tab" href={"#" + sourceName + "_navCtn"} role="tab" aria-controls={sourceName + "_navCtn"} aria-selected={false}>
												<div>{sourceName}</div>
											</a>
									})
								}
							</div>
						</nav>
						<div className="tab-content" id="nav-tabContent">
							{
								Object.keys(this.graphShop).map((sourceName) => {

									if(this.state[this.graphShop[sourceName].stateName].length > 0)
										return <div key={sourceName+"_keyGrpCtnNav"} className={(this.state.navSelected === sourceName? "active" : "") + " tab-pane fade show"} id={sourceName + "_navCtn"} role="tabpanel" aria-labelledby={sourceName + "_navBtn"}>
											{
												(() => {
													let tableSec = this.graphShop[sourceName].showItem(this.graphShop[sourceName].stateName)

													return (<div>
														{tableSec && "header" in tableSec? tableSec.header : ""}
														<table className="table mt-3">
															{tableSec? tableSec.head : ""}
															{tableSec? tableSec.body : ""}
														</table>
													</div>)
												})()
											}
										</div>
								})
							}
						</div>
					</div> : ""
				}
				<h6 className="position-fixed text-secondary" style={{right: "15px", bottom: "0px"}}>v{this.version}</h6>
			</div>
		);
	}
}

export default UserGraph;
