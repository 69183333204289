// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import Loading from './Loading.js';
import { ajaxPerso, loadMinSellingPrices, fetchAvgPerSku, fetchItemGlobalInfo, fetchPriceHistory } from '../../fnc.js'
import Select from 'react-select';
import logoStoreEio from '../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../img/ebay_circle_perso.png';
import logoStoreWalmart from '../../img/walmart_circle_updated.png';
import logoStoreAmazon from '../../img/amazon_circle_perso.png';
import logoStoreAmazonGott from '../../img/amazon_circle_perso_gott_v2.png';
import moment from 'moment';

const refConst = require("../../constants.js").v

export default function ModalItemInfo(props){

	const initialState = {
		result: {success: [], error: []},
		submited: false,
		reqRunning: false,
		itemGlobalInfo: null,
		itemMargin: [],
		minPrices: null,
		lastFewSale: null,
		priceHistoryPerStore: {},
	}

	const [itemGlobalInfo, setItemGlobalInfo] = useState(initialState.itemGlobalInfo);
	const [itemMargin, setItemMargin] = useState(initialState.itemMargin);
	const [submited, setSubmited] = useState(initialState.submited);
	const [minPrices, setMinPrices] = useState(initialState.minPrices);
	const [lastFewSale, setLastFewSale] = useState(initialState.lastFewSale);
	const [priceHistoryPerStore, setPriceHistoryPerStore] = useState(Object.assign({}, initialState.priceHistoryPerStore));
	const [version, setVersion] = useState("1.0.0");

	useEffect(() => {
		
		triggerModal(props.id_product, true);
		
	}, [itemMargin, itemGlobalInfo, submited, minPrices, lastFewSale, priceHistoryPerStore])

	/* useEffect(() => {
		//! * Just for development purpose. so the modal is always open.
		
		if(props.modalOpen)
			triggerModal(85239, false);
		
	}, [props.modalOpen, itemGlobalInfo, submited, minPrices]) */

	useEffect(() => {

		let o = itemGlobalInfo
		
		if(o && o.weight && o.weight_unit && o.cost && o.sku){
					
			loadMinSellingPrices([{
				weight: o.weight,
				weight_unit: o.weight_unit,
				sku: o.sku,
				cost:  parseFloat(o.cost),
			}], (r) => {
				console.info('Min Selling Prices:', r);

				setMinPrices(r.success && r.data.length > 0? r.data[0] : null)
			});
		}

		if(o){
			fetchAvgPerSku({
				"sku": o.sku,
				"ship_start": moment().subtract(1, 'year').format("YYYY-MM-DD"),
				"packageId": 198, // package
				"qty": 1,
				"international": false
			}, (r) => {
				if(r.success){
					props.loading(false)
	
					var sum = 0
					for( var i = 0; i < r.data.length; i++ ){
						sum += parseFloat( r.data[i].shipment_cost, 10 ) //don't forget to add the base
					}
					var avg = sum/r.data.length
					
					setLastFewSale(r.data.length > 0? {"data": r.data, "avg": avg} : initialState.lastFewSale)
				}else if(r.error){
					props.info({
						error: r.error
					})
					setLastFewSale(initialState.lastFewSale)
				}
			})
		}
		
	}, [itemGlobalInfo])

	const init = () => {
		
		console.info("props.id_productprops.id_product", props.id_product);
		getItemGlobalInfo({"id_product": props.id_product});

		getPriceHistory({"id_product": props.id_product});
	}

	const getPriceHistory = (obj) => {


		fetchPriceHistory(obj.id_product, (r) => {
			console.info('Fetched fetchPriceHistory:', r);

			if(r.errors){
				props.info({
					error: r.errors
				})
				setPriceHistoryPerStore(Object.assign({}, initialState.priceHistoryPerStore))
			}else{
				let listPerStore = {}
				r.data.forEach(o => {
					listPerStore[o.id_marketplace] = o
				})
				setPriceHistoryPerStore(listPerStore)
			}
		});
	}
	
	const getItemGlobalInfo = (obj) => {
		// Reset the items and presence list.

		props.loading(true)

		fetchItemGlobalInfo({
			"id_product": obj.id_product
		}, (r) => {
			console.info('Fetched itemGlobalInfo:', r);

			setItemGlobalInfo(r)
	
			props.loading(false);
		}, (r) => {
			console.info('Fetched itemMargin:', r);
			
			setItemMargin(r)
		
			props.loading(false);
		});
	}

	const sectionBuilder = (title, fncCtnData, styles) => {

		return <div key={`blc_${title}`} style={styles? styles : {}} className={"m-2 mt-3 bg-light p-3 d-inline-block border position-relative"}>
			<p className="mb-1 font-weight-bold pl-2 pr-2 position-absolute d-inline-block border bg-white" style={{
					top: "-12px",
			}}>{title}</p>
			{ fncCtnData() }
		</div>
	}


	const sectionMinSelling = () => {

		if(!minPrices)	return;

		let listStorePerId = []
		refConst.stores.forEach(o => {
			listStorePerId[o.id] = o
		});

		console.info("listStorePerIdlistStorePerId", listStorePerId);
		console.info("minPricesminPrices", minPrices);

		let htmlPrices = [];
		for (const idStore in minPrices.minSellingPrice) {
			if (Object.hasOwnProperty.call(minPrices.minSellingPrice, idStore)) {
				const minPrice = minPrices.minSellingPrice[idStore];
				htmlPrices.push(<div className="d-flex justify-content-between">
					<span className="smallText">- {listStorePerId[idStore].name}</span>
					<span>${minPrice}</span>
				</div>)
			}
		}

		return sectionBuilder("Min Selling Prices", () => {
			return <div>
				{
					htmlPrices
				}
			</div>
		}, {
			minWidth: "280px"
		})
	}

	const sectionLastSale = () => {

		if(!lastFewSale)	return;

		console.info("lastFewSale", lastFewSale);

		let htmlPrices = [];
		lastFewSale.data.forEach(o => {

			htmlPrices.push(<tr>
				<td className="smallText">{o.ordered_at}</td>
				<td>${o.shipment_cost}</td>
				<td>{o.id_order}</td>
				<td>{o.state}</td>
				<td>{o.store_name}</td>
			</tr>)
		});

		return sectionBuilder("Last few sales", () => {
			return <div className="smallText mt-1">
				<div>
					Average Shipping Cost: 
					{lastFewSale.avg? <span className="font-weight-bold ml-2">${lastFewSale.avg.toFixed(3)}</span> : ""}
					<span className="ml-2">Since: {moment().subtract(1, 'year').format("YYYY-MM-DD")}</span>
				</div>
				<div className="mt-1" style={{
					maxHeight: "150px",
					overflowY: "scroll",
					boxShadow: "0px 3px 4px #a2a2a2 inset"
				}}>
					<table className="table table-sm table-bordered">
						<thead>
							<tr>
								<th scope="col" className="text-center">Date</th>
								<th scope="col" className="text-center">Cost</th>
								<th scope="col" className="text-center">Order</th>
								<th scope="col" className="text-center">State</th>
								<th scope="col" className="text-center">Store</th>
							</tr>
						</thead>
						<tbody>
							{
								htmlPrices
							}
						</tbody>
					</table>
				</div>
			</div>
		}, {
			minWidth: "280px"
		})
	}


	let triggerModal = (id_product, refresh) => {

		props.modal({
			key: "Product Info",
			show: refresh? false : true,
			title: <span>Product Info: {itemGlobalInfo? itemGlobalInfo.sku : ""}<h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {

				if(!itemGlobalInfo){
					return <Loading loading={true} centered={true} />
				}else{
					let o = itemGlobalInfo
					console.info("productData", o);
					let outputBlc = []

					let listStoreInfo = [
						{
							id: refConst.id_store_eio,
							logo: logoStoreEio,
							name: "EIO",
							qtyProp: "shopify_eio_qty",
							priceProp: "eio_price",
						},
						{
							id: refConst.id_store_hausfTools,
							logo: logoStoreHaus,
							name: "Haus",
							qtyProp: "shopify_haus_qty",
							priceProp: "haus_price",
						},
						{
							id: refConst.id_store_eBay_EIO,
							logo: logoStoreEbay,
							name: "eBay",
							qtyProp: "ebay_qty",
							priceProp: "ebay_price",
						},
						{
							id: refConst.id_store_walmart_eio,
							logo: logoStoreWalmart,
							name: "Walmart",
							qtyProp: "walmart_qty",
							priceProp: "walmart_price",
						},
						{
							id: refConst.id_store_amazon_EIO,
							logo: logoStoreAmazon,
							name: "Amazon",
							qtyProp: "amazon_us_eio_qty",
							priceProp: "amazon_us_eio_price",
						},
						{
							id: refConst.id_store_amazon_GOTT,
							logo: logoStoreAmazonGott,
							name: "Amazon",
							qtyProp: "amazon_us_gott_qty",
							priceProp: "amazon_us_gott_price",
						},
					]

					outputBlc.push(<div key={"blcPresence"} className="d-flex flex-row align-items-start">
						<div className="m-2 bg-light p-3 d-inline-block border position-relative">
							<p className="mb-1 font-weight-bold pl-2 pr-2 position-absolute d-inline-block border bg-white" style={{
								top: "-12px",
							}}>Quantities & Prices:</p>
							<p className="mb-1 font-weight-bold">QOH: {parseInt(o.qty_on_hand)>0? o.qty_on_hand : 0}</p>
							<table className="fontNumber font-weight-bold">
								<thead>
									<tr>
										{
											listStoreInfo.map((stO, i) => {
												return <th scope="col" key={`th_${stO.id}`} className="text-center"><img src={stO.logo} className="" style={{height: "35px"}}></img></th>
											})
										}
									</tr>
								</thead>
								<tbody>
									<tr>
										{
											listStoreInfo.map((stO, i) => {

												if(priceHistoryPerStore[stO.id] && priceHistoryPerStore[stO.id].prices[0] != o[stO.priceProp]){
													priceHistoryPerStore[stO.id].prices.unshift(o[stO.priceProp])
													priceHistoryPerStore[stO.id].dates.unshift("Current")
												}

												return <td className="text-center smallText" key={`td_${stO.id}`}>
													<div>{ o.qtyProp? o[stO.qtyProp] || 0 : "" }</div>
													<div className="pl-2 pr-2">
														{
															priceHistoryPerStore[stO.id]? <select style={{width: "100px", outline: "none", fontWeight: "bold"}}>
																{
																	priceHistoryPerStore[stO.id].prices.map((price, pos) => {
																		return <option key={`${stO.id} - ${pos}`}>${price} - <span className="smallText">{priceHistoryPerStore[stO.id].dates[pos]}</span></option>
																	})
																}
															</select> : <span>${ o[stO.priceProp] || 0 }</span>
														}
													</div>
												</td>
											})
										}
									</tr>
								</tbody>
							</table>
						</div>						
					</div>)

					console.info('TESTSHOP', refConst.shopList);
					
					let eioStore = refConst.stores.find(s => {
						if(s.id === refConst.id_store_eio)	return s
					})
					let hausStore = refConst.stores.find(s => {
						if(s.id === refConst.id_store_hausfTools)	return s
					})
					let eBayEio = refConst.stores.find(s => {
						if(s.id === refConst.id_store_eBay_EIO)	return s
					})
					let walmart = refConst.stores.find(s => {
						if(s.id === refConst.id_store_walmart_eio)	return s
					})
					let amazon = refConst.stores.find(s => {
						if([refConst.id_store_amazon_GOTT, refConst.id_store_amazon_EIO].indexOf(s.id) !== false)	return s
					})

					let urlOptions = [];

					if(o.eio_id){
						urlOptions.push({
							value: "https://" + eioStore.account_name + "/admin/products/" + o.eio_id,
							label: eioStore.name + " (Admin)"
						})
						urlOptions.push({
							value: "https://" + eioStore.account_name + "/products/" + o.eio_handle,
							label: eioStore.name
						})
					}
					if(o.haus_id){
						urlOptions.push({
							value: "https://" + hausStore.account_name + "/admin/products/" + o.haus_id,
							label: hausStore.name + " (Admin)"
						})
						urlOptions.push({
							value: "https://" + hausStore.account_name + "/products/" + o.haus_handle,
							label: hausStore.name
						})
					}
					if(o.ebay_id){
						urlOptions.push({
							// https://www.ebay.com/sl/list?itemId=255523718975&mode=ReviseItem
							value: "http://www.ebay.com/sl/list?itemId=" + o.ebay_id+"&mode=ReviseItem",
							label: eBayEio.name + " (Admin)"
						})
						urlOptions.push({
							value: o.ebay_url,
							label: eBayEio.name
						})
					}

					if(o.url_link)
						urlOptions.push({
							value: `https://app.finaleinventory.com/eio/sc2/?product/detail/` + btoa(o.url_link),
							label: `Finale`
						})

					if(o.amazon_us_eio_asin || o.amazon_us_gott_asin){
						let asin = o.amazon_us_eio_asin || o.amazon_us_gott_asin;
						urlOptions.push({
							value: `https://sellercentral.amazon.com/abis/listing/edit/offer?marketplaceID=ATVPDKIKX0DER&ref=xx_myiedit_cont_myifba&sku=${encodeURIComponent(o.sku)}&&asin=${asin}`,
							label: `${amazon.name} (Admin)`
						})
						urlOptions.push({
							value: `https://www.amazon.com/dp/${asin}?ref=myi_title_dp`,
							label: `${amazon.name} Store`
						})
					}

					if(o.walmart_id)	urlOptions.push({
						value: o.walmart_url,
						label: walmart.name
					})

					outputBlc.push(<div key={"blcLinks"} className="m-2 bg-light p-3 d-inline-block border position-relative">
						<p className="mb-1 font-weight-bold pl-2 pr-2 position-absolute d-inline-block border bg-white" style={{
								top: "-12px",
						}}>Store Link</p>
						<Select
							styles={refConst.styleList}
							className="mt-2"
							onChange={(selectedOption) => {
								window.open(selectedOption.value,'_blank');
							}}
							options={urlOptions}
							// value={selectedToDel !== initialState.selectedToDel? {value: selectedToDel, label: selectedToDel} : null}
							isSearchable={true}
							isMulti={false}
							placeholder={"Select to open link in a new tab"}
						/></div>)
						
					let tags = [
						{
							name: "[Walmart] Do not auto create",
							tag: "do_not_create_on_walmart"
						},
						{
							name: "Deleted",
							tag: "deleted"
						},
						{
							name: "Active",
							tag: "is_active"
						},
						{
							name: "No Auto Lock",
							tag: "no_auto_lock"
						},
					]
					outputBlc.push(<div key={"blcTags"} className="m-2 bg-light p-3 d-inline-block border position-relative" style={{
						minWidth: "200px"
					}}>
						<p className="mb-1 font-weight-bold pl-2 pr-2 position-absolute d-inline-block border bg-white" style={{
								top: "-12px",
						}}>System Tags</p>
							{
								tags.filter((t, i) => {
									console.info(`Aaaaaaaaaa ${t.tag}`, parseInt(o[t.tag]) );
									if(o[t.tag] && parseInt(o[t.tag]) > 0)	return t
								}).map(t => {
									
									return <span key={`${t.tag}_badge_system`} className="badge badge-primary p-2 ml-1 mr-1 mt-2">{t.name}</span>

								})
							}
						</div>)

					let secMinSell = sectionMinSelling()
					if(secMinSell)
						outputBlc.push(secMinSell)

					let secLastSells = sectionLastSale()
					if(secLastSells)
						outputBlc.push(secLastSells)

					return <div>
						<div>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text">Title</span>
								</div>
								<input type="text" className="form-control bg-light" value={o.title ?? ""} readOnly/>
							</div>
							<div className="d-flex flex-row">
								<div className="input-group mb-3 w-50 mr-2">
									<div className="input-group-prepend">
										<span className="input-group-text">SKU</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.sku ?? ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">UPC</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.upc ?? ""} readOnly/>
								</div>
							</div>
							<div className="d-flex flex-row">
								<div className="input-group mb-3 w-50 mr-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Location</span>
									</div>
									<input type="text" className="form-control bg-light" value={!o.qty_remaining? "No Stock" : o.location} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Cost</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.supplier1_price? "$" + o.supplier1_price : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">MSRP</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.msrp? "$" + o.msrp : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">MAP</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.map_price? "$" + o.map_price : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 ml-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Lead Days</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.supplier1_lead_days? o.supplier1_lead_days : ""} readOnly/>
								</div>
							</div>
							<div className="d-flex flex-row">
								<div className="input-group mb-3 w-50 mr-2">
									<div className="input-group-prepend">
										<span className="input-group-text">Weight</span>
									</div>
									<input type="text" className="form-control bg-light" value={o.weight? o.weight + " " + o.weight_unit : ""} readOnly/>
								</div>
								<div className="input-group mb-3 w-50 mr-2">
									
								</div>
							</div>
						</div>
						<div className="d-flex flex-row align-items-start flex-wrap justify-content-center mt-2">
							{outputBlc}
						</div>
					</div>
				}
			}
			, exit: (popup, close) => {
				props.setStateSafe({
					"modalOpen": false,
				})
				close()
			},
			return: {
				title: !props.promoMetaObj? "Close" : "Remove Promo",
				fnc: (popup, close) => {
					props.setStateSafe({
						"modalOpen": false,
					})
					close()
				}
			}
		});
	}

	/**
	 * This paghe should return a callback for the onClick event.
	 */

	/* if(!props.modalOpen && props.setStateSafe){
		props.setStateSafe({
			"modalOpen": true,
		})
	} */

	return (
		<div className={props.className} onClick={(e) => {
			if(e){
				e.preventDefault();
				e.stopPropagation();
			}

			props.setStateSafe({
				"modalOpen": true,
			})
			init()
			triggerModal(props.id_product, false);
		}}>
			{props.title}
		</div>
	);
}