// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { capitalizeFLetter, socketPerso } from '../../../fnc'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

const refConst = require("../../../constants.js").vz

export default function Module(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-Mj_haqzismzadLiujp1" // order_view
	]

	const initialState = {
		submited: false
	}
	
	const [version, setVersion] = useState("0.1.0");
	const [submited, setSubmited] = useState(initialState.submited);
	const refInputFrom = useRef(null);
	const refInputTo = useRef(null);
	const [refreshView, setRefreshView] = useState(0);
	const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'year').toDate());
	const [dateTo, setDateTo] = useState(moment().toDate());
	const [compareToPrevTime, setCompareToPrevTime] = useState(false);

	useEffect(() => {
		modalSheet(true);
	}, [dateFrom, dateTo, refInputFrom.current, refInputTo.current, refreshView, props.compareToPrevTime])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let submitTaskShippingType = () => {

		props.info({success: `Task started, you will receive an email with the download link.`})

		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			internalReq: {
				"method": "POST",
				"api": "eio",
				"trigger": "3M_finale_shipping_type_calculator",
				"createFile": true,
				"uid": props.getUser().uid
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Create report for 3M Shipping Type`
				}
			}
		}, (passThrough) => {
			console.info("Res socket", passThrough);

			if(passThrough.errors){
				props.info({"error": passThrough.errors})
			}else{

				console.info("Success pastrhough: ", passThrough);
			}

		})
	}

	const handleInputChange = (e, side) => {
		side.ref.current.value = e.target.value; // keep the input value in sync
		console.info("e.target.value", e.target.value);
		if (moment(e.target.value).isValid()) {
			side.setDate(moment(e.target.value).format("MM-DD-YYYY"))
		} else {
			side.setDate(null);
		}
	};

	let modalSheet = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: <span>Date selector <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			html: (popup) => {

				return <div className="text-center">
					{(()=>{console.log([dateFrom])})()}
					<div className="alert alert-primary" role="alert">
						the range you select here will take over the history filter, the compared to range will be the selected range minus 1 year
					</div>
					<div className="d-flex flex-row justify-content-center align-items-start">
						{
							[
								{
									ref: refInputFrom,
									date: dateFrom,
									setDate: setDateFrom,
									position: "left",
								},{
									ref: refInputTo,
									date: dateTo,
									setDate: setDateTo,
									position: "right",
								}
							].map(o => {
								return <div key={`${o.position}`} className="d-flex flex-column">
									<input
										style={{ fontSize: "inherit" }}
										ref={o.ref}
										className="w-75 m-auto text-dark form-control"
										type="text"
										value={o.date? moment(o.date).format("MM-DD-YYYY") : ""}
										placeholder={"MM-dd-yyyy"}
										onChange={(e) => handleInputChange(e, o)}
									/>
									<DayPicker
										captionLayout="dropdown" fromYear={2010} toYear={2024}
										className="Selectable"
										// selectedDays={[o.date]}
										month={moment(o.date).toDate()}
										selectedDays={moment(o.date).toDate()}
										// modifiers={{ start: props.setDateFrom, end: to }}
										onDayClick={(result) => {
											console.log("result", moment(result).format("YYYY-MM-DD"));
											o.setDate(result)
											o.ref.current.value = moment(result).format("MM-DD-YYYY")
											// setRefreshView(refreshView + 1)
										}}
									/>
								</div>
							})
						}
					</div>
					{/* <div className="custom-control custom-switch noselect w-100">
						<input type="checkbox" className="custom-control-input" id="customCpPrev" checked={compareToPrevTime} onChange={(e) => {
							setCompareToPrevTime(!compareToPrevTime)
						}}/>
						<label className="custom-control-label" htmlFor="customCpPrev">Compare to previous year</label>
					</div> */}
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: {
				title: "Filter",
				fnc: (popup, close) => {
					props.setDateFrom(dateFrom)
					props.setDateTo(dateTo)
					props.setCompareToPrevTime(compareToPrevTime)
					close()
				}
			}, return: {
				title: "Exit",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}
	
	return (
		<button type="button" className={(props.className || "") +  " btn btn-outline-primary"} onClick={() => modalSheet(props.storeTo, props.storeFrom)}>
			{ props.name }
		</button>
	);
}