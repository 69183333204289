
var DB = {
	firebase: null,
	adminUid: [
		'4XTCWbwcFscWYkXaOXQeSbVqE6o2', // me
		'N7nLPzs2MVc8l2wbWtp4PT4Oiot1' // Tach
	],
	init: (firebase) => {
		DB.firebase = firebase
		return DB
	},
	add: (obj, where, callback) => {
	
		var ref = DB.firebase.database().ref(where);

		var newPostKey = ref.push().key,
			uidEntered = false;

		if(!("uid" in obj)){
			uidEntered= true;
			console.info(newPostKey);
		}else
			newPostKey = obj.uid;

		if(!uidEntered)
			DB.getLogedUserWhenReady(user => {
		
				obj = Object.assign(obj, {
					"uid": user.uid
				});
		
				return pushChild(obj, newPostKey);
			});
		else
			return pushChild(obj, newPostKey);

		function pushChild(obj, newPostKey){
			return ref.child(newPostKey).set(obj).then(() => {
				// then(): doesnt return anything
				callback(false, newPostKey);
			}).catch(e => {
				callback(e, false);
			});
		}

		//try {
		/*} catch (e) {
			if(e)
				return false
		}*/

		/**
		 * {
			"FullURL": "a23",
			"BaseURL": "b23",
			"MemberURL": "c23",
			"PictureHeight": "d23",
			"PictureWidth": "e23",
			"UseByDate": "f23",
			"ErrorCode": "g23"
		}
		 */
	},

	loginEmailAndPassword: (firebase, mail, pass, callback) => {
		//console.info(firebase);
		let loginBack = DB.firebase.auth().signInWithEmailAndPassword(mail, pass);
		
		loginBack.then(function(user){
			//console.info(arguments);
			callback(false, user);
		}).catch((err) => {
			callback(err, false);
		});
	},
	resetPassword: (emailAddress, callback) => {
		DB.firebase.auth().sendPasswordResetEmail(emailAddress).then(function() {
			callback(false, true)
		}).catch(function(error) {
			callback("Error during the process, Please ask your supervisor.")
		});
	},
	getLogedUser: () => {
		// Has to be initialized before.
		var user = DB.firebase.auth().currentUser;
		return user;
	},
	getLogedUserWhenReady: (callback) => {
		DB.firebase.auth().onAuthStateChanged(function (user) {
			callback(user, DB);
		});
	},


	addListener: (obj, callback) => {
		DB.firebase.auth().onAuthStateChanged(function (user) {
			console.info(user);
			
			if (user) {
				// User is signed in.
				//var isAnonymous = user.isAnonymous;
				//var uid = user.uid;
				//console.info(user);

				for (const tableName in obj) {
					if (obj.hasOwnProperty(tableName)) {
						const valObj = obj[tableName];
						
						DB.load(tableName, valObj, callback);
						//DB[valObj.fnc](tableName, valObj);
					}
				}

			} else
				console.warn('Error wih firebase account');
		});
	},
	load: (table, obj, callback) => {
		// alert(JSON.stringify(obj));

		var ref = DB.firebase.database().ref(table);
		
		if('orderByKey' in obj)
			ref = ref.orderByKey();
		else if('orderByChild' in obj)
			ref = ref.orderByChild(obj.orderByChild);

		if('limitToLast' in obj)
			ref = ref.limitToLast(obj.nbrPage * obj.nbrPerPage);

		if('equalTo' in obj)
			ref = ref.equalTo(obj.equalTo);
		
		if('once' in obj){
			ref = ref.once("value");
			
			ref.then(function(data) {
				//DB.triggerAddImg(data);
				if("fnc" in obj)
					DB[obj.fnc](data, obj);
				if(typeof callback === 'function'){
					callback(data, DB.nbrAddedCalculator(obj, data)); // Run Only one time
				}
			});
		}else{

			ref.on("value", function (data) {
				//DB.triggerAddImg(data);
				if("fnc" in obj)
					DB[obj.fnc](data, obj);
				if(typeof callback === 'function'){
					callback(data, DB.nbrAddedCalculator(obj, data));// Run each time something change
				}
			});
		}
	},
	nbrAddedCalculator: (obj, data) => {
		let totalAsked = obj.nbrPage * obj.nbrPerPage,
			totalGot = data.val() === null? 0 : Object.keys(data.val()).length,
			nbrAdded = obj.nbrPerPage;

		if(totalAsked - totalGot > obj.nbrPerPage)
			nbrAdded = 0;
		else if(totalAsked > totalGot)
			nbrAdded = totalAsked - totalGot;
		return nbrAdded;
	}
};

export default DB;
