// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { fetchBrands } from '../../../fnc'
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
const refConst = require("../../../constants.js").v

const styleList = {
	container: (provided) => ({
		...provided,
		minWidth: "250px"
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

function ListView(props){

	// const [nbrGrpInline, setNbrGrpInline] = useState(2);

	// const inputImg = useRef(null)

	useEffect(() => {
		
	}, [props.shopIdSelected])


	const viewListMeta = () => {

		let output = <div className="w-100">
			<div>
				Total: {props.aPlusList.length}
			</div>
			<div className="list-group">
				{
					props.aPlusList.map(o => {

						let idBlockFound = props.aPlusIdList.find(oIdList => {
							if(o.key == oIdList.keyMeta)	return o;
							return false;
						})

						
						if(idBlockFound){

							let cr_email = null;
							let up_email = null;
							props.accounts.forEach(oA => {
								if(idBlockFound.uid_creator == oA.id_firebase)	cr_email = oA.data_firebase.email
								if(idBlockFound.uid_updated == oA.id_firebase)	up_email = oA.data_firebase.email
							})

							return <div key={o.id} className="list-group-item list-group-item-action p-2 pointer d-flex flex-row d-flex justify-content-between">
								<h5 className="d-flex align-items-center m-0">
									<span className="badge badge-primary">{ o.key }</span>
									<span className={(idBlockFound.brand? "" : "d-none") + " badge badge-info ml-3"}>Brand: { idBlockFound.brand }</span>
									<span className={(idBlockFound.type? "" : "d-none") + " badge badge-info ml-3"}>Type: { idBlockFound.type }</span>
									<span className={(idBlockFound.tag? "" : "d-none") + " badge badge-info ml-3"}>Tag: { idBlockFound.tag }</span>
								</h5>
								<span className="d-flex flex-row">
									<div className="d-flex flex-column">
										<span className={(idBlockFound.uid_creator? "" : "d-none") + " badge badge-warning ml-3"}>Created by: { cr_email }</span>
										<span className={(idBlockFound.uid_updated? "" : "d-none") + " badge badge-warning ml-3 mt-1"}>Last Update: { up_email }</span>
									</div>
									<NavLink className="ml-3" to={{
										pathname: "/Aplus/CreateNew/" + o.key,
										// search: "?mode=Create",
									}}><button type="button" className="btn btn-primary">Modify</button></NavLink>
								</span>
							</div>
						}
					})
				}
			</div>
		</div>

		return <div className="mt-3">{output}</div>;
	}
		
	return (<div className={("className" in props? props.className : "")}>
		<div className="alert alert-primary w-100 text-wrap mt-4" role="alert">
			Create or Update an A+ content, A+ represents a section on the website that highlights a product per its type and brand.
		</div>
		{ viewListMeta() }
	</div>);
}

export default ListView;