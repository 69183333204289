// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import logoStoreFinale from '../../img/finale_logo_circle_v2.png';
import logoStoreEio from '../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../img/ebay_circle_perso.png';
import logoStoreWalmart from '../../img/walmart_circle_updated.png';
import logoStoreAmazon from '../../img/amazon_circle_perso.png';
import logoStoreAmazonGott from '../../img/amazon_circle_perso_gott_v2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const refConst = require("../../constants.js").v
const logoStoreWantedList = [
	logoStoreEbay,
	logoStoreAmazon,
	logoStoreAmazonGott,
	logoStoreWalmart,
	logoStoreEio,
	logoStoreHaus,
	logoStoreFinale
];

export default function ShowPresence(props){

	const [storeWanted, setStoreWanted] = useState([
		refConst.id_store_eBay_EIO,
		refConst.id_store_amazon_EIO,
		refConst.id_store_amazon_GOTT,
		refConst.id_store_walmart_eio,
		refConst.id_store_eio,
		refConst.id_store_hausfTools
	]);
	const [stores, setStores] = useState([]);
	const [version, setVersion] = useState("0.8.2");

	useEffect(() => {

		if("addStores" in props){
			setStoreWanted(storeWanted.concat(props.addStores).slice(0))
		}
	}, [])

	useEffect(() => {

		if(props.stores && props.stores.length > 0){
			let storeList = [];
			props.stores.forEach(o => {
				let indexOfFound = storeWanted.indexOf(o.id)
				if(indexOfFound !== -1){
					storeList.push(o)
					storeList[storeList.length-1].img = logoStoreWantedList[indexOfFound]
				}
			});
			
			setStores(storeList)
		}
	}, [props.stores, storeWanted])

	const showStores = (isHeader) => {
		let output = [];

		if(stores.length === 0)	return output

		storeWanted.map((valWanted, index) => {
			let oS = false

			if(typeof valWanted != 'object')
				oS = stores.find( o => {
					if(o.id === valWanted)	return o
				})
			else	oS = valWanted;
			
			if(oS){

				if(isHeader){
					output.push(<div key={oS.id+"_logoStore"} className="col p-0" style={{minWidth: "35px", maxWidth: "35px", width: "35px"}} title={oS.name}><img src={logoStoreWantedList[index]} className="" style={{height: "35px"}}></img></div>)
				}else{
					let presenceLoaded = props.list_id_store !== null,
						presenceIdList = props.list_id_store 

					let classColor = "bg-light"
					if(presenceLoaded)
						classColor = presenceIdList.indexOf(oS.id) !== -1 || "autoMark" in oS && oS.autoMark? "bg-success" : "bg-warning"

					output.push(<div key={oS.id+"_storeId"} className={(classColor) + " border-right p-0 col-sm align-middle text-center smallText"} style={{overflow: "hidden", minWidth: "35px", maxWidth: "35px", height: "35px", fontSize: "20px"}} title={oS.name}>
						{props.deletedFrom && props.deletedFrom.indexOf(oS.id) !== -1? <FontAwesomeIcon icon={faCheckCircle}/> : ''}
						{props.notDeletedFrom && props.notDeletedFrom.indexOf(oS.id) !== -1? <FontAwesomeIcon icon={faExclamationCircle}/> : ''}
					</div>)
				}
			}
		})
		return output
	}

	return props.return == "icons"? <div className="text-center d-flex flex-row">
		{/* {
			logoStoreWantedList.map((img, index) => {
				
				if(storeWanted[index]){
					if(typeof storeWanted[index] === 'object')	storeWanted[index]
					return <div key={storeWanted[index]+"_logoStore"} className="col p-0" style={{minWidth: "35px", maxWidth: "35px", width: "35px"}}><img src={img} className="" style={{height: "35px"}}></img></div>
				}
			})
		} */}
		{
			showStores(true)
		}
	</div> : <div className="text-center d-flex flex-row">
		{
			showStores(false)
		}
	</div>
}