// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Loading from './Loading';
import Select from 'react-select';
import { capitalizeFLetter, ajaxPerso, findWherePosted, getItemsCatalog } from '../../fnc'
import ShowPresence from './ShowPresence.js';
import { utils, writeFile } from 'xlsx';

const refConst = require("../../constants.js").v
const storeToAdd = [{
	id: 5,
	name: 'Finale',
	// autoMark: true
}]

export default function DeleteItem(props){

	const initialState = {
		// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
		items: null,
		presence: false,
		result: {success: [], error: []},
		submited: false,
		reqRunning: false,
	}

	const [reqRunning, setReqRunning] = useState(initialState.reqRunning);
	const [submited, setSubmited] = useState(initialState.submited);
	const [result, setResult] = useState(initialState.result);
	const [items, setItems] = useState(initialState.items);
	const [presence, setPresence] = useState(initialState.presence);
	const [version, setVersion] = useState("1.0.0");

	useEffect(() => {
		triggerModal(true);
	}, [submited, reqRunning, items, presence, props.itemsSelected, result ])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerProcess = () => {

		setSubmited(false)

		let itemsSelected = props.itemsSelected
		if(itemsSelected.length === 0)
			return false

		setItems(initialState.items);

		getItemsData()

		triggerModal(false);
	}

	let downloadFile = (resExec) => {
		let needFileOutput = false
		let sheetData = resExec.map((o) => {
			let cLine = {
				"product ID (eio DB)": o.id_product,
				"sku": o.sku
			}

			if(o.data.length > 0 && needFileOutput === false)
				needFileOutput = true;

			o.data.forEach( d => {

				let s = props.stores.find(s => {
					if(s.id == d.id_store)	return s
				})

				cLine[`status store ${s? s.name : d.id_store}`] = d.status === true? 'Deleted' : d.status
				if('error' in d)
					cLine[`Error`] = d.error
			})
			return cLine
		});

		let sheetDataDatabase = resExec.map((o) => {
			let cLine = {
				"product ID (eio DB)": o.id_product,
				"sku": o.sku
			}
			o.data.forEach( d => {

				let s = props.stores.find(s => {
					if(s.id == d.id_store)	return s
				})

				if("database" in d)
					cLine[`database ${s? s.name : d.id_store}`] = "database" in d? d.database : "-"
			})
			return cLine
		});

		console.info("sheetData", sheetData);
		console.info("sheetDataDatabase", sheetDataDatabase);

		if(needFileOutput){
			var book  = utils.book_new()
			utils.book_append_sheet(book, utils.json_to_sheet(sheetData), "Result");
			utils.book_append_sheet(book, utils.json_to_sheet(sheetDataDatabase), "Database");
			writeFile(book, `Result_Deletion_Result_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
		}else{
			console.info('No file required to download.');
			
		}
	}


	let triggerModal = (refresh) => {
		console.info('result', result);
		
		props.modal({
			show: refresh? false : true,
			title: <span>End item <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);
				
				return items === null? <Loading loading={true} centered={true}/> : <div>
					<div>
						<div className="alert alert-info" role="alert">
							You can delete up to 100 items at the time. (Item with QOH cannot be deleted)<br/>
							<span style={{fontSize: "13px"}}>Estimated time for full deletion: 5:30 Min (base on 100 items)</span>
						</div>
						<div className="alert alert-warning" role="alert">
							Items retired on Walmart can take up to 48 hours to be removed from the walmart catalog.
						</div>
						<table className="table">
							<thead>
								<tr>
									<th scope="col" className="bg-info text-white">N#</th>
									<th scope="col" className="bg-info text-white">Id</th>
									<th scope="col" className="bg-info text-white">Brand</th>
									<th scope="col" className="bg-info text-white">SKU</th>
									<th scope="col" className="bg-info text-white text-center">QOH</th>
									<th scope="col" className="bg-info text-white">
										{
											<ShowPresence addStores={storeToAdd} stores={props.stores} return={"icons"} />
										}
									</th>
								</tr>
							</thead>
							<tbody>
								{
									items.length > 0?
										items.map((o, ii) => {
											// Add finale as store activate
											/* let show = true;
											if(o.is_active){
												if(!o.list_id_store)
													o.list_id_store = [5]
												else
													o.list_id_store.push(5)
											}else{
												if(!o.list_id_store)
													show = false;
											} */
											
											//if(show)
												return	<tr key={`${o.id}_itemCreate`}>
													<th scope="row">{ii+1}</th>
													<th scope="row">{o.id}</th>
													<td>{o.vendor}</td>
													<td>{o.sku}</td>
													<td className={(o.qty_on_hand > 0? "text-danger font-weight-bolder " : "") + " text-center"}>{o.qty_on_hand}</td>
													<td>
														{
															presence[o.id]? <ShowPresence list_id_store={presence[o.id]} addStores={storeToAdd} stores={props.stores} notDeletedFrom={Object.keys(result.error).length > 0? result.error[o.id] : []} deletedFrom={Object.keys(result.success).length > 0? result.success[o.id] : []} return={"info"}/> : "Loading..."
														}
													</td>
												</tr>
										})
										: <tr>
											<td colSpan={5} className="text-center">
												No active product found in your selection. 
											</td>
										</tr>
								}
							</tbody>
						</table>
						{
							("resExec" in result)? <div>
								<button type="button" className="btn btn-primary" onClick={() => downloadFile(result.resExec)}>Download Report</button>
							</div> : ''
						}
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: !submited? {
				title: "Validate",
				fnc: (popup, close) => {
					props.loading(true)
					setSubmited(true)
					setReqRunning(true)

					let listIdProduct = []
					items.forEach( o => {
						console.info('item to delete: ', o);
						listIdProduct.push(o.id)
					})

					console.info('Items_send', listIdProduct);
					// return false;

					reqTriggerPage(listIdProduct, (r) => {
						
						props.loading(false)
						setReqRunning(false)
						// setSubmited(false)

						let success = [],
							errors = [],
							countInserted = 0,
							itemsDeletedList = {}, // id product: [store id 1, store id 2...]
							itemsErrorList = {}

						console.info('Fetch Req Create Item', r);

						if(r.errorList.length > 0){
							errors = errors.concat(r.errorList)
						}

						if(r.resExec.length > 0){
							
							r.resExec.map((oItem) => {

								if(oItem.data.length > 0){

									oItem.data.map((storeStatus) => {

										if(storeStatus.status === true){
											if(!(oItem.id_product in itemsDeletedList)){
												itemsDeletedList[oItem.id_product] = [];
											}

											itemsDeletedList[oItem.id_product].push(storeStatus.id_store)
											countInserted++
										}else{
											if(!(oItem.id_product in itemsErrorList))
												itemsErrorList[oItem.id_product] = [];
											itemsErrorList[oItem.id_product].push(storeStatus.id_store)
										}
									})
								}
							})

							console.info("itemsDeletedList", itemsDeletedList);
							console.info("itemsErrorList", itemsErrorList);

							setResult(Object.assign({
								success: itemsDeletedList,
								error: itemsErrorList,
							}, countInserted > 0? {resExec: r.resExec} : {}))

							success.push(<div key={`${success.length}_success`}>Store deleted count: ({countInserted})</div>);
							if(countInserted > 0)	success.push(<div key={`${success.length}_success`}>items deleted List: {Object.keys(itemsDeletedList).join(', ')}</div>);
							
							downloadFile(r.resExec)
						}
						
						if(r.success)	success.push(<div key={`successMessage`}>{r.success}</div>);
						if(errors.length > 0)	errors.push(<div key={`${errors.length}_error`}>items Error List {Object.keys(errors).join(', ')}</div>);
						if(itemsErrorList.length > 0)	errors.push(<div key={`${itemsErrorList.length}_itemsErrorList`}>items Error List {Object.keys(itemsErrorList).join(', ')}</div>);
						
						// if(r.data.notUpdated)		error.push(r.data.notUpdated)
						if("callback" in props)	props.callback()

						props.info({success: success, error: errors})
					}, (jqXHR, textStatus, errorThrown) => {
						getItemsData()
						setSubmited(false)
						props.loading(false)
						setReqRunning(false)
						props.info({error: textStatus + ", Please try again"})
					})
					// resetPage()
					// popup.modal('hide')
				}
			} : false, return: {
				title: reqRunning? "Please wait" : "Close",
				fnc: (popup, close) => {
					if(!submited){
						resetPage()
						close()
					}
				}
			}
		});
	}

	/* let fetchItems = (callbackItem) => {
		console.info('props.itemsSelected', props.itemsSelected);
		
		if(!props.itemsSelected)
			throw("Please make sure that you have selected some items.");
		else
			ajaxPerso( {
				"api": "eio",
				"trigger": "getItemsCatalog",
				"allStatus": false, // We need to show if an item is active or inactive
				"idList": props.itemsSelected.join(',')
			}, callbackItem);
	} */

	let getItemsData = () => {
		getItemsCatalog({
			"idList": props.itemsSelected.join(','),
		}, (r) => {
			console.info('Fetched Items', r);
			if(r.success){
				setItems(r.data)
			}else{
				props.info({"error": r.error})
			}
		})
		findWherePosted({
			"idList": props.itemsSelected.join(','),
		}, (r) => {
			console.info('Fetched Presence', r);
			if(r.success){
				// save presence per ID
				let presenceSv = {}
				r.data.forEach(o => {
					if(!o.deleted)
						o.list_id_store.push(5)
					presenceSv[o.id] = o.list_id_store
				})
				setPresence(presenceSv)
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let reqTriggerPage = (listIdProduct, callbackPresence, callbackFail) => {
		if(!listIdProduct)
			throw("Please indicate a storeId / itemList.");
		else{
			/* console.info("SSSSS", {
				"api": "eio",
				"trigger": "bulkEnding",
				"uid": props.getUser().uid,
				"idProductListJson": JSON.stringify(listIdProduct)
			});
			return; */
			ajaxPerso( {
				"api": "eio",
				"trigger": "bulkEnding",
				"uid": props.getUser().uid,
				"idProductListJson": JSON.stringify(listIdProduct)
			}, callbackPresence, callbackFail)
		}
	}

	console.info("Deleting Props", props);
	console.info("presence Props", presence);

	return (
		<div className={("className" in props? props.className : 'list-group-item list-group-item-action p-2 text-center')} onClick={() => triggerProcess()}>
			{
				props.title
			}
		</div>
	);
}