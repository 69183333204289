// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo, createElement } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Loading from './Loading';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { capitalizeFLetter, ajaxPerso, addLog } from '../../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import ShowPresence from './ShowPresence.js';

const refConst = require("../../constants.js").v
var listIdItemModifiedFastEdit = []

export default function FastEditFeaturesEbayModal(props){
	
	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-MjVTgFHd2akT44hrjKg" // ebay_eio_write
	]
	

	const initialState = {
		// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
		items: [],
		submited: false,
		selectedCategory: null,
		categories: [],
		currentItem: null,
		newItemList: {},
		catFeatures: null,
		// selectList: [],
		itemsDetails: []
	}

	var listRefs = []

	const [itemCount, setItemCount] = useState(0);
	const [focusAdded, setFocusAdded] = useState(false);
	const [catFeatures, setCatFeatures] = useState(initialState.catFeatures);
	const [newItemList, setNewItemList] = useState(initialState.newItemList);
	const [currentItem, setCurrentItem] = useState(initialState.currentItem);
	const [categories, setCategories] = useState(initialState.categories.slice(0));
	const [selectedCategory, setSelectedCategory] = useState(initialState.selectedCategory);
	const [submited, setSubmited] = useState(initialState.submited);
	const [items, setItems] = useState(initialState.items.slice(0));
	const [itemsDetails, setItemsDetails] = useState(initialState.itemsDetails.slice(0));
	const [version, setVersion] = useState("0.1.1");

	/* useEffect(() => {
		// Remove the items specifics that shloud not be used for this category.
		console.info('catFeatures', catFeatures);
		console.info('itemsDetails', itemsDetails);
		console.info('items', items);

		if(catFeatures && catFeatures.NameRecommendation.length > 0 && Object.keys(itemsDetails).length > 0){
			let nameValueList = itemsDetails[currentItem.item.id_item].ItemSpecifics.NameValueList

			let specificNameAllowed = catFeatures.NameRecommendation.map(o => {
				return o.Name
			})
			
			nameValueList.forEach((o, index) => {
				if(specificNameAllowed.indexOf(o.Name) === -1){
					delete nameValueList[index]
				}
			});
			nameValueList = nameValueList.filter(o => o !== null)

			console.info('JSONSSS', JSON.stringify(nameValueList));
			// setItemsDetails(Object.assign({}, itemsDetails));
		}
		
	}, [catFeatures, itemsDetails]) */

	useEffect(() => {

		/**
		 	Fill the newItemList with the current item specifics and clean up some unwanted specifics
		 */
		
		if(catFeatures && Object.keys(itemsDetails).length > 0 && currentItem && currentItem.item.id_item in itemsDetails && itemsDetails[currentItem.item.id_item].ItemSpecifics.NameValueList){

			let specificNameAllowed = catFeatures && catFeatures.NameRecommendation? catFeatures.NameRecommendation.map(o => {
				return o.Name
			}) : []

			newItemList[currentItem.item.id_item] = {ItemSpecifics: {}}
			
			// Add all current specific values, if not adding, they will be deleted on eBay
			if(currentItem.item.id_item in itemsDetails && itemsDetails[currentItem.item.id_item].ItemSpecifics){
				itemsDetails[currentItem.item.id_item].ItemSpecifics.NameValueList.forEach(o => {
					// Take only if the prop name is expected for this category.
					if(specificNameAllowed.indexOf(o.Name) > -1){
						newItemList[currentItem.item.id_item].ItemSpecifics[o.Name] = Array.isArray(o.Value)? o.Value[0] : o.Value;
					}
				});
				/* for (const name in itemsDetails[currentItem.item.id_item].specifics) {
				} */
			}
			setNewItemList(Object.assign({}, newItemList))
		}
		
	}, [itemsDetails, currentItem, catFeatures])

	useEffect(() => {
		triggerModal(true);
	}, [items, currentItem, categories, selectedCategory, submited, catFeatures, itemsDetails, newItemList])

	useEffect(() => {

		if(currentItem){
			listRefs = [];
			setFocusAdded(false)
			getCategory()
		}

	}, [currentItem])

	useEffect(() => {

		if(items.length > 0){
			getItemsDetails()
		}

	}, [items])

	useEffect(() => {

		if(items && itemsDetails){
			/* if(currentItem.item.id_item in itemsDetails && o.Name in itemsDetails[currentItem.item.id_item].specifics){
				currentItem.item = Object.assign(currentItem.item,  itemsDetails[currentItem.item.id_item])
				setCurrentItem(currentItem)
			} */
		}

	}, [currentItem])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerProcess = (isForReload) => {
		listIdItemModifiedFastEdit = []
		setNewItemList({})

		getItemsData()

		triggerModal(isForReload);
	}

	let previousItem = (fromKeyEvent) => {

		if(currentItem){
			let pos = currentItem.pos
			if(pos >= 1){
				setCurrentItem({
					pos: pos-1,
					item: items[pos-1],
					triggeredFromKey: fromKeyEvent
				})
			}
		}else{
			props.info({error: "You have reached the end."})
		}
	}

	let nextItem = (fromKeyEvent) => {

		if(currentItem){
			let pos = currentItem.pos
			
			if(pos < items.length-1){
				setCurrentItem({
					pos: pos+1,
					item: items[pos+1],
					triggeredFromKey: fromKeyEvent
				})
			}
		}else{
			props.info({error: "You have reached the end."})
		}
	}

	let keyEventMove = (e) => {
		/* console.info('Event key', e.keyCode);
		console.info('Event key test ', e.key.toLowerCase()); */
		
		if(e.keyCode === 37){
			previousItem(true);
		}else if(e.keyCode === 39){
			nextItem(true);
		}
	}

	let isRequired = (o) => {
		return "UsageConstraint" in o.ValidationRules && o.ValidationRules.UsageConstraint.toLowerCase() == "required"? true : false
	}

	let triggerModal = (refresh) => {
		console.info('listRefs', listRefs);

		props.modal({
			show: refresh? false : true,
			title: <span>Fast Edit eBay <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				/* let objPreSelected = refConst.shopList.find(obj => {
					if(obj.storeId === storeTo)
						return obj
				}) */

				console.info("currentItem", currentItem)
				console.info("itemsDetails", itemsDetails)
				console.info("newItemList", newItemList)

				return currentItem == initialState.currentItem || itemsDetails.length == 0? <Loading loading={true} centered={true}/> : <div>
					<div onKeyDown={keyEventMove} tabIndex={-1}>
						<div className="container">
							<div className="row">
								<div className="col bg-light w-50 d-flex justify-content-center align-items-top pt-4">
									<div>
										<div className="mb-3">
											Item count: <span className="badge badge-primary">{itemCount}</span>
										</div>
										<button type="button" className="btn btn-secondary btn-lg mr-4" onClick={() => previousItem(false)} disabled={submited}>Previous [←]</button>
										<button type="button" className="btn btn-primary btn-lg" onClick={() => nextItem(false)} disabled={submited}>Next [→]</button>
										<div className={(currentItem.item.error_features.length > 0? "" : "d-none" ) + " mt-3"}>
											<div className="alert alert-danger" role="alert">
												{
													currentItem.item.error_features.map((o, pos) => {
														return <div key={`${pos}_${o.code}_errors`}>{o.msg}</div>
													})
												}
											</div>
										</div>
										<div className={(currentItem.item.error_features.length > 0? "" : "d-none" ) + " mt-3"}>
											<div className="alert alert-primary" role="alert">
												If the specifics seems not appropriate for this product, it means that the product is in the wrong category, in this case please click on &apos;Edit&apos; and change the product category.
											</div>
										</div>
									</div>
								</div>
								<div className="col bg-light w-50">
									<form>
										<div className="form-group">
											<label>
												<a className="d-block" target="_blank" rel="noreferrer" href={currentItem.item.url? currentItem.item.url : ""} title="Open in a new tab">eBay <span className="smallText">(click to open on eBay)</span></a>
												<a className="d-block" target="_blank" rel="noreferrer" href={`${window.location.origin}/ebayListing/${currentItem.item.id_item}`} title="Open in a new tab">Edit <span className="smallText">(click to open on eBay Editor)</span></a>
											</label>
											<input className="form-control" type="text" placeholder={currentItem.item.id_item} readOnly value={currentItem.item.id_item}/>
											<label>TITLE</label>
											<input className="form-control" type="text" placeholder={currentItem.item.title} readOnly value={currentItem.item.title}/>
											<label>Category</label>
											<textarea type="text" className="form-control text-wrap text-break" disabled={true} placeholder="Category" value={itemsDetails[currentItem.item.id_item].PrimaryCategory.CategoryName.split(":").join(" > ")}></textarea>
											{	(() => {
												var added = focusAdded;

												/* let specificNameAllowed = catFeatures && catFeatures.NameRecommendation? catFeatures.NameRecommendation.map(o => {
													return o.Name
												}) : [] */

												return catFeatures && catFeatures.NameRecommendation? catFeatures.NameRecommendation.map((o, index) => {
													return o.ValidationRules.ValueType == "Text"? <div key={currentItem.item.id + "_" + o.Name}>
														<label className={isRequired(o)? "font-weight-bold" : ""}>{o.Name} {isRequired(o)? "*" : ""}</label>
														<CreatableSelect
															ref={ref => {
																if(!added && isRequired(o)){
																	ref.focus()
																	added = true;
																	setFocusAdded(true)
																	// focusAdded = true
																}
																listRefs.push(ref);
															}}
															isClearable
															styles={Object.assign(refConst.styleList, {
																container: (provided) => ({
																	...provided,
																	width: "auto",
																	minWidth: "auto"
																})
															})}
															onChange={(selectedOption) => {

																let listToRemove = null;
																if(selectedOption)
																	listToRemove = selectedOption.value

																if(listIdItemModifiedFastEdit.indexOf(currentItem.item.id_item) === -1)
																	listIdItemModifiedFastEdit.push(currentItem.item.id_item)

																console.info('listIdItemModifiedssssss', listIdItemModifiedFastEdit);
																

																/* if(!(currentItem.item.id_item in newItemList)){
																	newItemList[currentItem.item.id_item] = {ItemSpecifics: {}}
																	
																	// Add all current specific values, if not adding, they will be deleted on eBay
																	if(currentItem.item.id_item in itemsDetails && itemsDetails[currentItem.item.id_item].specifics){
																		for (const name in itemsDetails[currentItem.item.id_item].specifics) {

																			// Take only if the prop name is expected for this category.
																			if(specificNameAllowed.indexOf(name) > -1){
																				if (itemsDetails[currentItem.item.id_item].specifics.hasOwnProperty(name)) {
																					const val = itemsDetails[currentItem.item.id_item].specifics[name];
																					newItemList[currentItem.item.id_item].ItemSpecifics[name] = val;
																				}
																			}
																		}
																		// setCurrentItem(currentItem)
																	}
																} */

																if(listToRemove === null)
																	delete newItemList[currentItem.item.id_item].ItemSpecifics[o.Name]
																else
																	newItemList[currentItem.item.id_item].ItemSpecifics[o.Name] = listToRemove;

																// console.info('newItemLis22222t', JSON.stringify(newItemList));
																setNewItemList(Object.assign({}, newItemList))
																// setSelectedCategory(listToRemove)
															}}
															options={(() => {
																return o.ValueRecommendation && Array.isArray(o.ValueRecommendation)? o.ValueRecommendation.map(b => {
																	return {
																		value: b.Value,
																		label: `${b.Value}`
																	}
																}) : []
															})()}
															/* value={
																currentItem.item.id_item in newItemList && o.Name in newItemList[currentItem.item.id_item].ItemSpecifics? 
																	[{value: newItemList[currentItem.item.id_item].ItemSpecifics[o.Name], label: newItemList[currentItem.item.id_item].ItemSpecifics[o.Name] + '-1-'}] 
																	: 
																} */
															value={(
																currentItem.item.id_item in newItemList && o.Name in newItemList[currentItem.item.id_item].ItemSpecifics?
																	[{value: newItemList[currentItem.item.id_item].ItemSpecifics[o.Name], label: newItemList[currentItem.item.id_item].ItemSpecifics[o.Name]}]
																	: [{value: null, label: null}]
															)}
															placeholder={o.Name}
															isSearchable={true}
															isMulti={false}
														/>
													</div> : <span>-</span>
												}) : ""

											})()
											}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				resetPage()
				close()
			}
			, ok: Object.keys(newItemList).length > 0? {
				title: `Submit Filled (`+Object.keys(newItemList).length+`)`,
				fnc: (popup, close) => {
					props.loading(true)

					console.info('newItemList', newItemList);
					// return false;
					
					try {
						reqTriggerPage((r) => {
			
							setSubmited(true)
							props.loading(false)

							console.info('Fetch Req Trigger', r);
	
							let outputSuccess = [],
								outputErrors = [];
	
							if(r.resList.length > 0){
	
								// Reload items which will load items that require to be still updated.
								triggerProcess(true);
	
								r.resList.forEach( (o, i) => {
									if(o.status == "Success"){
										outputSuccess.push(<div>{o.idItem} - Updated with success.</div>)
									}
									if(o.errors && Array.isArray(o.errors) && o.errors.length > 0){
										o.errors.forEach( (h) => {
											outputErrors.push(<div>{o.idItem} - {h.msg}</div>)
										})
									}
								})
							}
	
							if(r.error)		outputErrors.push(r.error)
	
							console.log("outputSuccess", outputSuccess);
							console.log("outputErrors", outputErrors);
							props.info({success: outputSuccess, error: outputErrors})
						})
					} catch (error) {
						addLog({
							type: "error",
							fncName: "tools_fastEditFeaturesEbayModal",
							message: error.toString()
						}, (r) => {
							console.info('tools_fastEditFeaturesEbayModal', r);
							props.info({"error": "Error found, data reloading..."})
							// Reload items which will load items that require to be still updated.
							triggerProcess(true);
						})
					}
					// resetPage()
					// close()
				}
			} : false, return: {
				title: "Return",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}

	let fetchItems = (callbackItem) => {
		
		ajaxPerso( {
			"api": "ebay",
			"trigger": "fetch_item_with_feature_issues",
			"shop": props.store
		}, callbackItem);
	}

	let getItemsData = () => {
		fetchItems((r) => {
			console.info('Fetched Items', r);
			props.loading(false)
			if(r.success){
				setItems(r.data)
				if(r.data.length > 0){
					setCurrentItem({
						pos: 0,
						item: r.data[0],
						triggeredFromKey : false
					})
					setItemCount(r.data[0].count)
				}
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let fetchItemsDetails = (callbackItem) => {
		
		ajaxPerso( {
			"api": "ebay",
			"trigger": "get_item_detail",
			"shop": props.store,
			"selector": "Item.ItemSpecifics,Item.Site,Item.SKU,Item.Title,Item.ItemID,Item.PrimaryCategory",
			"idList": items.map( o => {
				return o.id_item
			}).join(',')
		}, callbackItem);
	}

	let getItemsDetails = () => {
		fetchItemsDetails((r) => {
			console.info('Fetched Items details', r);
			if(r.success){
				if(r.data.length > 0){
					let obj = {};
					r.data.forEach( o => {
						obj[o.ItemID] = o;
						/* let specifics = {}
						if(o.ItemSpecifics && Array.isArray(o.ItemSpecifics.NameValueList))
							o.ItemSpecifics.NameValueList.forEach( u => {
								specifics[u.Name] = u.Value;
							})
						obj[o.ItemID].specifics = specifics */
					})
					console.info('Specifics founds', obj);
					setItemsDetails(obj)
					/* items.forEach( (o, i) => {
						let found = r.data.find( u => {
							if(u.ItemID == o.id_item)	return u
						})
						if(found){
							items[i].ItemSpecifics = found.ItemSpecifics
						}
					})
					setItems(items.slice(0)) */

					/* let foundc = r.data.find( u => {
						if(u.ItemID == currentItem.id_item)	return u
					})
					if(foundc){
						currentItem.ItemSpecifics = foundc.ItemSpecifics
					}
					setCurrentItem(Object.assign({}, currentItem)) */
				}
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let fetchCategory = (callbackItem) => {
		if(!currentItem || !currentItem.item.id_category)	return false;

		console.info('currentItemcurrentItemcurrentItem', currentItem);
		
		
		setSubmited(true)
		
		ajaxPerso( {
			"api": "ebay",
			"trigger": "fetch_specifics",
			"shop": props.store,
			"idCatList": currentItem.item.id_category,
			"site": currentItem.item.site
		}, callbackItem);
	}

	let getCategory = () => {
		fetchCategory((r) => {
			setSubmited(false)

			console.info('Fetched fetch_specifics', r);
			if(r.success.length > 0){
				setCatFeatures(r.resList.length > 0? r.resList[0] : initialState.catFeatures)
			}else{
				props.info({"error":r.errors})
			}
		})
	}

	let reqTriggerPage = (callbackPresence) => {
		if(!newItemList)
			throw("Please indicate a newItemList.");
		else{
			// New format for our API
			let listOut = []
			for (const idItem in newItemList) {
				if (Object.hasOwnProperty.call(newItemList, idItem) && listIdItemModifiedFastEdit.indexOf(parseInt(idItem)) > -1) {
					newItemList[idItem].ItemID = idItem
					listOut.push(newItemList[idItem])
				}
			}

			/* setSubmited(false)
			props.loading(false)
			console.info('listIdItemModified', listIdItemModifiedFastEdit);
			console.info('newItemListnewItemList', listOut);
			return; */

			ajaxPerso( {
				"api": "ebay",
				"trigger": "alterItem",
				"shop": props.store,
				"data": JSON.stringify(listOut)
			}, callbackPresence);
		}
	}

	if(!props.securityClearance(props, access))	return "";

	return (
		<div className={props.className} onClick={() => triggerProcess(false)}>
			{
				props.name
			}
		</div>
	);
}