// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { ajaxPerso, fetchMenu, menuOrganized, menuOrganizedChilds, loadMinSellingPrices, findWherePosted, fetchItemGlobalInfo, fetchAutoCreatorMapping } from '../../../fnc'
import ListView from './ListView'
import ListView_3m from './ListView_3m'
import Stats from './Stats'
import CreatedModal from './CreatedModal.js'
import Reviews from './Reviews'
import {
	NavLink
} from 'react-router-dom';
import store from 'store2';
import logoStoreEio from '../../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../../img/ebay_circle_perso.png';
import logoStoreFinale from '../../../img/finale_logo_circle_v2.png';
import logoStoreAmazonGott from '../../../img/amazon_circle_perso_gott_v2.png';
import logoStoreAmazon from '../../../img/amazon_circle_perso.png';
import logoStoreWalmart from '../../../img/walmart_circle_updated.png';
import Select from 'react-select';
import loading from '../../../Rolling.svg';

const refConst = require("../../../constants.js").v

var initialState = {
	_isMounted: false,
	storeWantedForOurBrand: [],
	selectedBrand: null,
	// selectedMenu: null,
	menuOpened: [],
	onlyWithSmallestSeleableUnit: true,
	forceWith3MFeedImages: true,
	onlyWithWeight: true,
	reviewMode: false,
	selectedStoreToRemove: null,
	menu: [],
	autoCreateProduct: [],
	amazonAccountList: [],
	manualItemCount: null,
	storeInfo: [{
		active: true,
		name: "Finale",
		storeId: refConst.id_store_finale,
		img: logoStoreFinale,
		pos: 6
	}, {
		active: true,
		name: "eBay - EIO",
		storeId: refConst.id_store_eBay_EIO,
		img: logoStoreEbay,
		pos: 3
	}, {
		active: true,
		name: "EIO",
		storeId: refConst.id_store_eio,
		img: logoStoreEio,
		pos: 1
	}, {
		active: true,
		name: "hausOfTools",
		storeId: refConst.id_store_hausfTools,
		img: logoStoreHaus,
		pos: 2
	}, {
		active: true,
		name: "Amazon GOTT Feed",
		storeId: refConst.id_store_amazon_GOTT,
		img: logoStoreAmazonGott,
		noPrice: true,
		pos: 4
	}, {
		active: true,
		name: "Amazon EIO Feed",
		storeId: refConst.id_store_amazon_EIO,
		img: logoStoreAmazon,
		noPrice: true,
		pos: 5
	}, {
		active: false,
		name: "Walmart - EIO",
		storeId: refConst.id_store_walmart_eio,
		img: logoStoreWalmart,
		noPrice: true,
		pos: 5
	}]
}

export default function AutoCreate(props){

	console.info('AutoCreate props', props);
	console.info('Match.params', props.parent.match.params);

	var storeSelectedBrand = store("selectedBrand")
	var storeMenuOpened = store("menuOpened")

	const [amazonAccountList, setAmazonAccountList] = useState(initialState.amazonAccountList.slice(0));
	const [presenceInfoLoaded, setPresenceInfoLoaded] = useState(false);
	const [presenceLoaded, setPresenceLoaded] = useState(false);
	const [itemsCountSelectedCat, setItemsCountSelectedCat] = useState(null);
	const [itemsPerCatCount, setItemsPerCatCount] = useState(null);
	const [autoCreate, setAutoCreate] = useState([]);
	const [autoCreateProduct, setAutoCreateProduct] = useState(initialState.autoCreateProduct.slice(0));
	// const [history, setHistory] = useState(null);
	const [onlyWithSmallestSeleableUnit, setOnlyWithSmallestSeleableUnit] = useState(initialState.onlyWithSmallestSeleableUnit);
	const [forceWith3MFeedImages, setForceWith3MFeedImages] = useState(initialState.forceWith3MFeedImages);
	const [onlyWithWeight, setOnlyWithWeight] = useState(initialState.onlyWithWeight);
	const [refreshView, setRefreshView] = useState(0);
	const [menu, setMenu] = useState(initialState.menu.slice(0));
	const [selectedBrand, setSelectedBrand] = useState(storeSelectedBrand? storeSelectedBrand : null);
	const [storeWantedForOurBrand, setStoreWantedForOurBrand] = useState(initialState.storeWantedForOurBrand);
	const [menuOpened, setMenuOpened] = useState(storeMenuOpened? storeMenuOpened : initialState.menuOpened); // 3M
	const [pageOpen, setPageOpen] = useState(0); // pagination
	const [pageSize, setPageSize] = useState(50); // pagination
	const [reviewMode, setReviewMode] = useState(props.parent.match.params.ctn == 'reviews');
	const [manualSendAwaiting, setManualSendAwaiting] = useState(null);
	const [submited, setSubmited] = useState("init"); // Can be init, true, false
	const [storeInfo, setStoreInfo] = useState(JSON.parse(JSON.stringify(initialState.storeInfo))); // Can be init, true, false
	const [selectedStoreToRemove, setSelectedStoreToRemove] = useState(initialState.selectedStoreToRemove);
	const [manualItemCount, setManualItemCount] = useState(initialState.manualItemCount);
	const [intervalManualSendAwaiting, setIntervalManualSendAwaiting] = useState(null);
	const [isReadyToManuallyStart, setIsReadyToManuallyStart] = useState(false);

	console.info("selectedBrand", selectedBrand);

	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {

		if(props.parent.match.params.ctn == 'reviews'){

			setReviewMode(true)
		}else
			setReviewMode(false)

	}, [props.parent])

	useEffect(() => {
			
		if(reviewMode){
			setOnlyWithSmallestSeleableUnit(false)
			setForceWith3MFeedImages(false)
			setOnlyWithWeight(false)
		}else{
			setOnlyWithSmallestSeleableUnit(initialState.onlyWithSmallestSeleableUnit)
			setForceWith3MFeedImages(initialState.forceWith3MFeedImages)
			setOnlyWithWeight(initialState.onlyWithWeight)
		}

	}, [reviewMode])

	useEffect(() => {

		loadReadyToManuallyStart((isReady) => {
			setIsReadyToManuallyStart(isReady)
		})
		
		/* let intISReady = setInterval(function(){
			console.info("Timing going on");
			loadReadyToManuallyStart((isReady) => {
				setIsReadyToManuallyStart(isReady)
			})
		}, 1000 * 10)

		return () => {
			clearInterval(intISReady)
		} */

	}, [])

	useEffect(() => {
			
		adjustStoreWantedPerBrands()

		loadAmazonAccountCount()

	}, [selectedBrand])

	useEffect(() => {
		$('[data-toggle="tooltip"]').tooltip('dispose')

		$('[data-toggle="tooltip"]').tooltip({
			placement: "bottom",
			template:  '<div class="tooltip lightTooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
		}).tooltip('update')
		return() => {
			// When disconnected, we remove all active tooltip.
			$('[data-toggle="tooltip"]').tooltip('dispose')
		}
	})

	useEffect(() => {
		$('[data-toggle="popover"]').popover({
			placement: "bottom",
			html: true,
			trigger: 'click',
		}).on('show.bs.popover', function () {
			// Hide all other popovers
			$('[data-toggle="popover"]').not(this).popover('hide');
		});
	
		return() => {
			// When disconnected, we remove all active popover.
			$('[data-toggle="popover"]').popover('dispose')
		}
	})

	useEffect(() => {

		setPageOpen(0)
		setStoreInfo(JSON.parse(JSON.stringify(initialState.storeInfo)))
		setSelectedStoreToRemove(initialState.selectedStoreToRemove)

	}, [
		menuOpened
	])

	useEffect(() => {

		loadMenu()
		
	}, [
		selectedBrand,
		storeWantedForOurBrand,
		onlyWithSmallestSeleableUnit,
		forceWith3MFeedImages,
		onlyWithWeight
	])

	useEffect(() => {

		loadProductsCount()
			
	}, [
		menuOpened,
		storeWantedForOurBrand,
		onlyWithSmallestSeleableUnit,
		forceWith3MFeedImages,
		onlyWithWeight,
		pageOpen
	])

	useEffect(() => {
			
		loadProducts()

	}, [
		menuOpened,
		storeWantedForOurBrand,
		onlyWithSmallestSeleableUnit,
		forceWith3MFeedImages,
		onlyWithWeight,
		pageOpen
	])

	useEffect(() => {
		/**
		 * Will go through each variant and fill up corectly the storeWanted base on the result from `presence_checker`
		 */

		if(autoCreateProduct.length > 0 && !presenceLoaded){
			/**
			 	This call is to find where a product is missing.
			 */
			
			findWherePosted({
				"skuList": autoCreateProduct.map((o) => {
					return o.sku
				})
			}, (r) => {
				console.info("res_findWherePosted", r);

				if(r.success){
					/**
					 * We need to always add Finale, althoug it already exist just to overwrite and improve some data.
					 * 	Actually we do this in autoCreate_complete_data now.
					 */
					autoCreateProduct.forEach(ocp => {
						if(ocp.sku){
							let found = false;
							r.data.some(sqlRes => {
								if(sqlRes.sku == ocp.sku){
									found = true;
									ocp.variants.map((v, key) => {
										
										v.presenceInfo = null
										v.presenceCheckedResult = []
										v.presenceCheckedResult.push(refConst.id_store_finale)
										v.presenceCheckedResult = [].concat(
											v.presenceCheckedResult,
											sqlRes.list_id_store
										)

										// Remove from storeWanted the stores already existing
										let listStoreNeededToCreate = v.presenceCheckedResult? v.storeWanted.filter(x => !v.presenceCheckedResult.includes(x)) : [];
										/* console.info("listStoreNeededToCreate", listStoreNeededToCreate);
										console.info("v.storeWanted", v.storeWanted); */
										v.storeWantedOriginal = listStoreNeededToCreate;
										v.storeWanted = listStoreNeededToCreate;
									})

									return true;
								}
							})
							if(!found)
								ocp.variants.map((v, key) => {
									v.presenceInfo = null
									v.presenceCheckedResult = []
									v.storeWantedOriginal = v.storeWanted.slice(0)
								})
						}
					})
					// props.loading(false);
					setPresenceLoaded(true)
					setAutoCreateProduct(JSON.parse(JSON.stringify(autoCreateProduct)))
				}
				// console.info("Recap_autoCreateProduct2", JSON.stringify(autoCreateProduct));
			})
		}
		
	}, [autoCreateProduct])

	useEffect(() => {
		/**
		 * Will go through each variant and fill up corectly the storeWanted base on the result from `presence_checker`
		 */

		if(autoCreateProduct.length > 0 && !presenceInfoLoaded){
			/**
			 	This call is to find where a product is missing.
			 */
			
			fetchItemGlobalInfo({
				"skuList": autoCreateProduct.map((o) => {
					return o.sku
				})
			}, (data) => {
				console.info("res_findWherePosted", data);

				if(data && data.length > 0){

					autoCreateProduct.forEach(ocp => {
						if(ocp.sku){
							data.some(sqlRes => {
								if(sqlRes.sku == ocp.sku){

									ocp.variants.map((v, key) => {
										v.presenceInfo = sqlRes
									})
									return true;
								}
							})
						}
					})
					setPresenceInfoLoaded(true)
					setAutoCreateProduct(JSON.parse(JSON.stringify(autoCreateProduct)))
				}
			})
		}
		
	}, [autoCreateProduct])

	useEffect(() => {
		loadEverything()
	}, [])

	useEffect(() => {
		if(manualSendAwaiting){
			let intVal = setInterval(() => {
				loadManualStartCount()
			}, 3000)
			setIntervalManualSendAwaiting(intVal)
		}else{
			if(intervalManualSendAwaiting)
				clearInterval(intervalManualSendAwaiting)
		}
		return () => {
			if(intervalManualSendAwaiting)
				clearInterval(intervalManualSendAwaiting)
		}
	}, [manualSendAwaiting])

	const reloadData = () => {

		loadMenu();
		loadProducts()
	}

	const adjustStoreWantedPerBrands = () => {

		if(!selectedBrand)	return
		
		let listWanted = [],
			fullListAcceptable = [ // We can personalise per brand bellow
				refConst.id_store_finale,
				refConst.id_store_eio,
				refConst.id_store_hausfTools,
				refConst.id_store_eBay_EIO,
				refConst.id_store_amazon_GOTT,
				refConst.id_store_amazon_EIO
			];

		/* storeInfo.forEach((sI, sIPos) => {

			if("removed" in sI)
				fullListAcceptable.some((lA, lAPos) => {
					if(sI.storeId == lA){
						fullListAcceptable.splice(lAPos, 1)
						return true
					}
				})
		}) */

		switch (parseInt(selectedBrand.id_brand)) {
			case 3:		// 3M
				listWanted = fullListAcceptable;
				break;
			case 284:	// Wiha
				listWanted = fullListAcceptable;
				break;
			default:
				break;
		}
		setStoreWantedForOurBrand(listWanted)
	}

	const loadMenu = () => {

		console.info("selectedBrand", selectedBrand);

		if(selectedBrand && selectedBrand.id && selectedBrand.id_brand){

			props.loading(true);

			fetchAutoCreateProductCountPerCat({
				"onlyWithoutPricingData": false,
				"onlyWithSmallestSeleableUnit": onlyWithSmallestSeleableUnit,
				"forceWith3MFeedImages": forceWith3MFeedImages,
				"onlyWithWeight": onlyWithWeight,
				"id_product_brand": selectedBrand.id_brand,
			},
			(resCountPerCat) => {
				console.info("Fetch resCountPerCat", resCountPerCat);

				if(resCountPerCat.error){
					props.info({error: resCountPerCat.error})
				}else{

					setItemsPerCatCount(resCountPerCat.data)

					fetchAutoCreateMenu({
						"id_product_brand": selectedBrand.id_brand,
						"onlyWithoutPricingData": false,
						"onlyWithSmallestSeleableUnit": onlyWithSmallestSeleableUnit,
						"forceWith3MFeedImages": forceWith3MFeedImages,
						"onlyWithWeight": onlyWithWeight,
						"doNotCreate": reviewMode? '0' : 'null',
					}, (resMenu) => {
						console.info("Fetch fetchAutoCreateMenu", resMenu);

						props.loading(false);

						if(resMenu.error){
							props.info({error: resMenu.error})
						}else{
							// let menuFrom = resMenu.success? menuOrganized(resMenu.data) : []
							let	menuBrand = resMenu.success? menuOrganizedChilds(resMenu.data) : [];
							console.info("menuBrand", menuBrand);
							setMenu(menuBrand)
						}
					})
				}
			})
		}
	}

	const getCorrectCostDataFromPricingData = (pricingData) => {
		return "futurePrice" in pricingData && pricingData.futurePrice.netPrice != pricingData.currentPrice.netPrice? pricingData.futurePrice : pricingData.currentPrice
	}

	const hasFuturePrice = (pricingData) => {

		return "futurePrice" in pricingData && pricingData.futurePrice.netPrice != pricingData.currentPrice.netPrice
	}

	const loadProducts = () => {

		/**
		 * Fetch the content of auto_creator_product which contain
		 * 	- `product_feed_data`
		 * 	- `api_price_result` with `default_pricing_type`
		 */
		if(menuOpened && menuOpened.length > 0 && menuOpened[menuOpened.length-1] && storeWantedForOurBrand.length > 0){

			props.loading(true);

			fetchAutoCreateProduct({
				// "id_auto_creator": idAutoCreator
				"category": menuOpened[menuOpened.length-1],
				"onlyWithoutPricingData": false, // Onlytake when a pricing data is present.
				"onlyWithSmallestSeleableUnit": onlyWithSmallestSeleableUnit,
				"forceWith3MFeedImages": forceWith3MFeedImages,
				"onlyWithWeight": onlyWithWeight,
				"addBcomSized": true,
				"doNotCreate": reviewMode? '0' : 'null',
				"page": pageOpen,
				"limit": pageSize,
				"lightVersion": true, // Just get the data that we use
			}, (r) => {

				console.info("res_fetchAutoCreateProduct", r);
				props.loading(false);

				if(r.success){

					let listInfotoGetMinSellingPrice = [];

					r.data.map(o => {

						o.active = false
						o.discontinued = false
						o.ImgPositionSelected = 0 // Only for tools.work

						if(reviewMode)
							o.reviewed = true

						/* if(!("pricingData" in o.api_price_result)){
							props.info({error: o.api_price_result.pricing[0].message})
						} */

						if(o.variants.length === 0){

							let varSku = o.sku,
								varQty = null

							if(o.default_pricing_type){
								varSku += `_${o.default_pricing_type}`
								varQty = o.smallest_saleable_unit? o.smallest_saleable_unit : o.default_pricing_type // will be each/pack... for 3M and a number for our other brands.
							}

							o.variants.push({
								"id_variant": null, // DB id
								"qty": varQty,
								"selling_price": {},
								"default_price": {}, // Just for the view
								"active": true,
								"createStatus": {
									"created": null,
									"msg": null,
									"date_Created": null
								},
								"sku": varSku,
								"storeWanted": storeWantedForOurBrand,
								"storeWantedOriginal": storeWantedForOurBrand,
								"shipping_cost": o.shipping_cost,
							})
						}else{

							o.variants.forEach((v) => {
								v.default_price = {}
								if(v.storeWanted.length === 0){
									v.storeWanted = storeInfo.map(validStore => {
										/* if(!("removed" in validStore))
											return validStore.storeId */
											return validStore.storeId
									})
									v.storeWanted.sort()
									v.storeWantedOriginal = v.storeWanted.slice(0)
								}else{
									v.storeWantedOriginal = v.storeWanted.slice(0)
								}
								v.shipping_cost = o.shipping_cost
							})

						}

						// These properties will be filled manually and then used to find the right mapping for each store.
						o.collection_shopify_eio = null;
						o.dataset_shopify_eio = null;
						o.variants[0].presenceCheckedResult = null

						//if o.api_price_result.pricingData is not existing, it's a discontinued product.
						if(o.smallest_saleable_unit && o.bcom_sizes && o.api_price_result && o.api_price_result.pricingData){

							let smPrice = o.api_price_result.pricingData.filter((p) => {
								if(p.unit.name.toLowerCase() == o.smallest_saleable_unit.toLowerCase()){
									return p
								}
							})
							let smBcom = o.bcom_sizes.filter((p) => {
								if(p.name.toLowerCase() == o.smallest_saleable_unit.toLowerCase()){
									return p
								}
							})

							if(smPrice.length > 0 && smBcom.length > 0){
								listInfotoGetMinSellingPrice.push({
									"sku": o.sku,
									"weight": smBcom[0].dims.weight.value,
									"weight_unit": smBcom[0].dims.weight.type,
									"cost": getCorrectCostDataFromPricingData(smPrice[0]).netPrice,
								})
							}
						}
					})
					console.info("New prd info", r.data);
					console.info("listInfotoGetMinSellingPrice", listInfotoGetMinSellingPrice);
					
					setPresenceLoaded(false)
					setPresenceInfoLoaded(false)

					if(listInfotoGetMinSellingPrice.length > 0)
						loadMinSellingPrices(listInfotoGetMinSellingPrice, (dr) => {
							//console.info("res_loadMinSellingPrices", dr);

							r.data.forEach(o => {

								dr.data.some(d => {
									if(d.sku == o.sku){
										o.variants[0].minSellingPrice = d.minSellingPrice
										o.variants[0].rate = d.rate
										return d;
									}
								})
							})
							setAutoCreateProduct(r.data)
						})
					else{
						setAutoCreateProduct(r.data)
					}
				}
			})
		}
	}

	const loadProductsCount = () => {

		if(menuOpened && menuOpened.length > 0 && menuOpened[menuOpened.length-1] && storeWantedForOurBrand.length > 0){

			fetchAutoCreateProduct({
				"getCountOnly": true,
				"category": menuOpened[menuOpened.length-1],
				"onlyWithoutPricingData": false, // Onlytake when a pricing data is present.
				"onlyWithSmallestSeleableUnit": onlyWithSmallestSeleableUnit,
				"forceWith3MFeedImages": forceWith3MFeedImages,
				"onlyWithWeight": onlyWithWeight,
				"addBcomSized": true,
				"doNotCreate": reviewMode? '0' : 'null',
			}, (r) => {
				console.info("Fetch loadProductsCount", r);
				setItemsCountSelectedCat(parseInt(r.count))
			})
		}
	}

	const loadAmazonAccountCount = (callback) => {

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "auto_creator_created_count_per_user"
		}, (r) => {
			console.info("res_auto_creator_created_count_per_user", r);
			if(r.success){
				setAmazonAccountList(r.data.filter((o) => {
					if(o.count > 0)	return o
				}))
			}
			if(callback)	callback()
		});
	}

	const downloadAmazonReport = (uidUser) => {

		props.info({success: "The report is being created and will be sent to you by email."})
		
		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "auto_creator_report_created",
			"uidReceiver": props.getUser().uid,
			"uidUser": uidUser
		}, (r) => {
			console.info("RES auto_creator_report_created", r);
			if(r.errors){
				props.info({error: r.errors})
			}else{
				loadAmazonAccountCount()
			}
		});
	}

	const submitAutoCreatorMemory = (id_auto_creator, feed_value, eio_value, type, callback) => {

		if(!id_auto_creator || !feed_value || !eio_value || !type){
			props.info({error: "Please verify your submitAutoCreate arguments"})
			return;
		}

		console.info(`submitAutoCreatorMemory:`, `${feed_value} -> ${eio_value}`);

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "alter_auto_creator_memory",
			"id_auto_creator": id_auto_creator,
			"feed_value": feed_value,
			"eio_value": eio_value,
			"type": type,
		}, callback);
	}

	const addAutoCreatorSetRunTime = (is_started, is_ended) => {

		if(is_started === undefined || is_ended === undefined){
			props.info({error: "Please verify your arguments"})
			return;
		}

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "auto_creator_set_run_time",
			"is_started": is_started,
			"is_ended": is_ended,
			"uid": props.getUser().uid,
		}, (r) => {
			console.info("res_auto_creator_set_run_time", r);
			if(r.error){
				props.info({error: r.error})
			}
		});
	}

	const loadReadyToManuallyStart = (callback) => {

		ajaxPerso( {
			"special": "eio",
			"api": "autoCreator",
			"trigger": "get_auto_creator_set_run_time",
			"last": true
		}, (data) => {
			console.info("get_auto_creator_set_run_time", data);
			callback(data.length === 0 || data[0].is_ended? true : false)
		});
	}

	const getAutoCreatorMemory = (id_auto_creator, callback) => {

		let args = {
			"api": "autoCreator",
			"trigger": "get_auto_creator_memory",
			"id_auto_creator": id_auto_creator
		}

		if(id_auto_creator)
			args["id_auto_creator"] = id_auto_creator;

		ajaxPerso(args, callback);
	}

	const loadEverything = (callback) => {

		fetchAutoCreatorMapping({}, null, (r) => {

			if(!initialState._isMounted)	return false
			console.info('Fetched autoCreate:', r);
			if(r.success){
				setAutoCreate(r.data)
			}
		})

		loadManualStartCount()

		if(callback)	callback();
	}

	const loadManualStartCount = (callback) => {

		fetchAutoCreateProduct({
			"getCountOnly": true,
			"reviewed": 1,
			"doNotCreate": 0,
			"isCreated": 0,
			"uid": props.getUser().uid,
		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched fetchAutoCreateProduct Count:', r);
				setManualItemCount(r.success? parseInt(r.count) : null)
		})
	}

	const fetchAutoCreateProduct = (args, callback) => {

		props.loading(true);
		if(!initialState._isMounted)	return false

		ajaxPerso( Object.assign({
			"api": "autoCreator",
			"trigger": "get_autoCreate_product",
			// "skuList": "3M-7100068954"
		}, args), callback, (errMsg) => {
			console.error("fetchAutoCreateProduct", errMsg);
		});
	}

	const fetchAutoCreateMenu = (args, callback) => {

		if(!initialState._isMounted)	return false

		ajaxPerso( Object.assign({
			"api": "autoCreator",
			"trigger": "get_autoCreator_menu"
		}, args), callback);
	}
	
	const fetchAutoCreateProductCountPerCat = (args, callback) => {
		
		if(!initialState._isMounted)	return false
		
		ajaxPerso( Object.assign({
			"api": "autoCreator",
			"trigger": "get_autoCreate_category_items_count"
		}, args), callback);
	}

	const triggerManualCreation = (args, callback) => {
		
		if(!initialState._isMounted)	return false
		
		ajaxPerso( Object.assign({
			"api": "autoCreator",
			"trigger": "autoCreate_create_listing"
		}, args), callback);
	}

	const organise_summarize = (sumarizeOut) => {
		
		let listErrors = [],
			listWarning = [],
			listSuccess = [];

		for (const pSku in sumarizeOut) {
			if (Object.hasOwnProperty.call(sumarizeOut, pSku)) {
				const pInfo = sumarizeOut[pSku];

				for (const vSku in pInfo) {
					if (Object.hasOwnProperty.call(pInfo, vSku)) {
						const vInfo = pInfo[vSku];

						for (const idStore in vInfo) {
							if (Object.hasOwnProperty.call(vInfo, idStore)) {
								const sInfo = vInfo[idStore];

								if(sInfo && sInfo.created)
									listSuccess.push(`[P: ${pSku}][V: ${vSku}]: Created`)

								if(sInfo && sInfo.errors)
									listErrors.push(`[P: ${pSku}][V: ${vSku}]:\n` + sInfo.errors.join(",\n"))
								
								if(sInfo && sInfo.warning)
									listWarning.push(`[P: ${pSku}][V: ${vSku}]:\n` + sInfo.warning.join(",\n"))
							}
						}
					}
				}
			}
		}
		return {
			listErrors,
			listWarning,
			listSuccess
		}
	}

	const sendCreatedReport = (groupedPerUser) => {

		if(!initialState._isMounted)	return false
		if(groupedPerUser === undefined)	groupedPerUser = false
		props.info({success: "The report is being created and will be sent to you by email."})

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "autoCreator_created_report",
			"uid": props.getUser().uid,
			"groupedPerUser": groupedPerUser,
		}, (r) => {
			console.info("res_autoCreator_created_report", r);
			if(r.errors){
				props.info({error: r.errors})
			}
		});
	}

	const ShowSubPage = (callback) => {
		if(!initialState._isMounted)	return false

		if(props.parent.match.params.ctn == 'reviews'){

			if(props.securityClearance(props, [
				"-MjVxK86GVBUKL5oNU66"
			]))	
				return <Reviews
					{...props}
					selectedBrand={selectedBrand}
					autoCreate={autoCreate}
					autoCreateProduct={autoCreateProduct}
					setAutoCreateProduct={setAutoCreateProduct}
					getAutoCreatorMemory={getAutoCreatorMemory}
					submitAutoCreatorMemory={submitAutoCreatorMemory}
					refreshView={refreshView}
					setRefreshView={setRefreshView}
					storeInfo={storeInfo}
					setStoreInfo={setStoreInfo}
					menu={menu}
					setMenu={setMenu}
					storeWantedForOurBrand={storeWantedForOurBrand}
					menuOpened={menuOpened}
					setMenuOpened={setMenuOpened}
					pageOpen={pageOpen}
					pageSize={pageSize}
					setPageOpen={setPageOpen}
					setPageSize={setPageSize}
					reviewMode={reviewMode}
					reloadData={reloadData}
					initialState={initialState}
					itemsCountSelectedCat={itemsCountSelectedCat}
					selectedStoreToRemove={selectedStoreToRemove}
					setSelectedStoreToRemove={setSelectedStoreToRemove}
					addAutoCreatorSetRunTime={addAutoCreatorSetRunTime}
					isReadyToManuallyStart={isReadyToManuallyStart}
				/>

		}else if(selectedBrand && selectedBrand.id_brand == 3){

			return <ListView_3m className=""
				{...props}
				selectedBrand={selectedBrand}
				autoCreate={autoCreate}
				autoCreateProduct={autoCreateProduct}
				setAutoCreateProduct={setAutoCreateProduct}
				getAutoCreatorMemory={getAutoCreatorMemory}
				submitAutoCreatorMemory={submitAutoCreatorMemory}
				refreshView={refreshView}
				setRefreshView={setRefreshView}
				storeInfo={storeInfo}
				setStoreInfo={setStoreInfo}
				menu={menu}
				setMenu={setMenu}
				storeWantedForOurBrand={storeWantedForOurBrand}
				menuOpened={menuOpened}
				setMenuOpened={setMenuOpened}
				pageOpen={pageOpen}
				pageSize={pageSize}
				setPageOpen={setPageOpen}
				setPageSize={setPageSize}
				reviewMode={reviewMode}
				reloadData={reloadData}
				initialState={initialState}
				itemsPerCatCount={itemsPerCatCount}
				submited={submited}
				setSubmited={setSubmited}
				itemsCountSelectedCat={itemsCountSelectedCat}
				selectedStoreToRemove={selectedStoreToRemove}
				setSelectedStoreToRemove={setSelectedStoreToRemove}
				addAutoCreatorSetRunTime={addAutoCreatorSetRunTime}
				isReadyToManuallyStart={isReadyToManuallyStart}
				getCorrectCostDataFromPricingData={getCorrectCostDataFromPricingData}
				hasFuturePrice={hasFuturePrice}
			/>
		}

		else if(selectedBrand && selectedBrand.id_brand == 284)

			<ListView className=""
				{...props}
				selectedBrand={selectedBrand}
				autoCreate={autoCreate}
				autoCreateProduct={autoCreateProduct}
				setAutoCreateProduct={setAutoCreateProduct}
				getAutoCreatorMemory={getAutoCreatorMemory}
				submitAutoCreatorMemory={submitAutoCreatorMemory}
				refreshView={refreshView}
				setRefreshView={setRefreshView}
				storeInfo={storeInfo}
				setStoreInfo={setStoreInfo}
				menu={menu}
				setMenu={setMenu}
				storeWantedForOurBrand={storeWantedForOurBrand}
				reviewMode={reviewMode}
				reloadData={reloadData}
				initialState={initialState}
				itemsPerCatCount={itemsPerCatCount}
				submited={submited}
				setSubmited={setSubmited}
				selectedStoreToRemove={selectedStoreToRemove}
				setSelectedStoreToRemove={setSelectedStoreToRemove}
			/>
	}
	
	if(!props.securityClearance(props))	return "";

	let brandObjectSelected = selectedBrand? autoCreate.find(o => {
		if(o.id == selectedBrand.id)	return o
	}) : null

	console.info("selectedBrandselectedBrand", selectedBrand);
	console.info("isReadyToManuallyStartisReadyToManuallyStart", isReadyToManuallyStart);

	return (
		<div className="main ctnPage d-flex flex-row flex-wrap">
			<div className="input-group mb-4">
				<div className="input-group-prepend" style={{width: "20%"}}>
					<span className="input-group-text w-100" id="basic-addon1">Brand per Store</span>
				</div>
				<div style={{width: "80%"}}>
					<Select
						isClearable
						className="w-auto"
						styles={refConst.styleList}
						onChange={(selectedOption) => {
							$('.hasTooltip').tooltip('dispose') // Need to be affective before the rerender of the new form cause nodes will be different.
							setSelectedBrand(JSON.parse(selectedOption.value)) 
							store("selectedBrand", JSON.parse(selectedOption.value))
							setMenuOpened(initialState.menuOpened) 
						}}
						options={(() => {
							return autoCreate.map(o => {
								return {
									value: JSON.stringify({
										"id": o.id,
										"id_brand": o.id_product_brand_finale
									}),
									label: o.vendor
								}
							})
						})()}
						value={brandObjectSelected? [{
							value: JSON.stringify(selectedBrand),
							label: brandObjectSelected.vendor
						}] : null}
						placeholder={"Brand per Store"}
						isSearchable={true}
						isMulti={false}
					/>
				</div>
			</div>
			{/* <div className="input-group mb-4">
				<div className="input-group-prepend" style={{width: "20%"}}>
					<span className="input-group-text w-100" id="basic-addon1">Categories</span>
				</div>
				<div className="" style={{width: "80%"}}>
					<Select
						isClearable
						className="w-auto"
						styles={refConst.styleList}
						onChange={(selectedOption) => {
							let newValue = initialState.selectedMenu
							if(selectedOption){
								newValue = selectedOption
							}
							console.info('selectedOption', newValue);
							store("selectedCategoryAutoCreate", newValue)
							setSelectedMenu(newValue)
						}}
						options={(() => {
							return menu.map(sObj => {
								return {
									value: sObj.last.id_category,
									label: `${sObj.inline} [items: ${sObj.last.item_count_resting}]`
								}
							})
						})()}
						value={selectedMenu}
						placeholder={"Categories"}
						isSearchable={true}
						isMulti={false}
					/>
				</div>
			</div> */}
			{/* <div className="alert alert-danger" role="alert">
					Do not use this page unless directly asked to.
			</div> */}
			<div  className={(reviewMode? "d-flex" : "d-none") + " justify-content-start align-items-center pb-3"}>
				<NavLink className="ml-3" to={{
					pathname: `/auto_create`,
				}}>
					<button type="button" className="btn btn-outline-primary">
						Back
					</button>
				</NavLink>
			</div>
			<div className="d-flex flex-wrap justify-content-start align-items-center align-items-center pb-3">
				{
					[
						"N7nLPzs2MVc8l2wbWtp4PT4Oiot1",
						"bdPQVmUCs3UhT8TepjFDPipBzIu2",
						"4XTCWbwcFscWYkXaOXQeSbVqE6o2"
					].indexOf(props.getUser().uid) !== -1?

						<div className="groupFormModified ml-3 mb-3">
							<button type="button" className={(manualSendAwaiting? "bg-dark text-light" : "bg-success") + " btn d-flex align-items-center text-light"} disabled={manualSendAwaiting} onClick={() => {
								if(isReadyToManuallyStart){

									setManualSendAwaiting(true)

									triggerManualCreation({
										dataFromFile: false,
										uid: props.getUser().uid,
										filterforUid: props.getUser().uid,
									}, (r) => {
										setManualSendAwaiting(false)
									})
								}
							}}>{manualItemCount !== null? `[${manualItemCount}] ` : ""}{isReadyToManuallyStart? `Manual Start` : "Please wait"}<img src={loading} alt="Loading" className={(!isReadyToManuallyStart? "" : "d-none" ) + " ml-2"} style={{height: "30px", width: "30px"}}></img></button>
						</div> : ""
				}
				<div  className={(reviewMode? "d-none" : "d-flex") + " flex-wrap justify-content-start align-items-center"}>
					<NavLink className="ml-3 mb-3" to={{
						pathname: `/AutoCreate_settings`,
					}}>
						<button type="button" className="btn btn-outline-primary">
							Go to categories mapping
						</button>
					</NavLink>
					{/* <NavLink className="ml-3" to={{
						pathname: `/auto_create/reviews`,
					}}>
						<button type="button" className="btn btn-outline-primary">
							Reviews
						</button>
					</NavLink> */}

					<div className="groupFormModified ml-3 mb-3">
						<span>Only With Smallest Seleable Unit</span>
						<select onChange={(e) => {
							setOnlyWithSmallestSeleableUnit(e.currentTarget.value.toLowerCase() === 'true'? true : false);
						}} defaultValue={onlyWithSmallestSeleableUnit}>
							<option value={true}>Yes</option>
							<option value={false}>No</option>
						</select>
					</div>
					<div className="groupFormModified ml-3 mb-3">
						<span>Only With Images From Feed</span>
						<select onChange={(e) => {
							setForceWith3MFeedImages(e.currentTarget.value.toLowerCase() === 'true'? true : false);
						}} defaultValue={forceWith3MFeedImages}>
							<option value={true}>Yes</option>
							<option value={false}>No</option>
						</select>
					</div>
					<div className="groupFormModified ml-3 mb-3">
						<span>Only With Weight</span>
						<select onChange={(e) => {
							setOnlyWithWeight(e.currentTarget.value.toLowerCase() === 'true'? true : false);
						}} defaultValue={onlyWithWeight}>
							<option value={true}>Yes</option>
							<option value={false}>No</option>
						</select>
					</div>
					{
						[
							"N7nLPzs2MVc8l2wbWtp4PT4Oiot1",
							"bdPQVmUCs3UhT8TepjFDPipBzIu2",
							"4XTCWbwcFscWYkXaOXQeSbVqE6o2"
						].indexOf(props.getUser().uid) !== -1?
							<div className="groupFormModified ml-3 mb-3 " data-toggle="tooltip" title="Select the user account to download the last report">
								<span className="border-secondary">Download Amazon Report</span>
								<select className="border-secondary" onChange={(e) => {
									if(e.currentTarget.value != ""){
										let selectedUser = amazonAccountList[e.currentTarget.value]
										console.info("selectedUser", selectedUser);
										if(selectedUser && selectedUser.uid){
											downloadAmazonReport(selectedUser.uid)
										}
									}
								}}>
									<option value={""}>Select a user</option>
									{
										amazonAccountList.map((o, key) => {
											return <option key={o.account_name} value={key}>{`${o.account_name}: ${o.count}`}</option>
										})
									}
								</select>
							</div> : ""
					}
					<div className="btn-group btn-group-toggle ml-3 mb-3" data-toggle="buttons">
						<label className="btn btn-secondary" style={{borderRight: "1px solid white"}} onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							sendCreatedReport()
						}}>
							<input type="radio" name="options" id="option1" checked /> Report Created
						</label>
						<label className="btn btn-secondary" style={{borderLeft: "1px solid white"}} onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							sendCreatedReport(true)
						}}>
							<input type="radio" name="options" id="option2" /> Report Created Per user
						</label>
					</div>
				</div>
			</div>
			<div className="d-flex flex-row">
				<Stats
					{...props}
					organise_summarize={organise_summarize}
					submited={submited}
				/>
				<CreatedModal
					{...props}
					submited={submited}
				/>
			</div>
			<div className="w-100 mt-4">
				{/* {{ fncName && fncName.indexOf('Create') !== -1? <CreateNew className=""
					{...props}
					selectedBrand={selectedBrand}
					// loadAplusIds={loadAplusIds}
					aPlusIdList_id={aPlusIdList_id}
					history={history}
				/> : <NavLink to={{
					pathname: "/AplusV2/CreateNew",
					// search: "?mode=Create"
				}}><button type="button" className="btn btn-primary">Create</button></NavLink>} */}
				{
					ShowSubPage()
				}
			</div>
		</div>
	);
}