// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import Loading from './Loading';
import { capitalizeFLetter, ajaxPerso, downLoadFile } from '../../fnc'
import { utils, writeFile, read } from 'xlsx';

const refConst = require("../../constants.js").v

export default function ModalFtpFeed(props){

	const initialState = {
		// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
		items: [],
		result: {success: [], error: []},
		submited: false,
		reqRunning: false
	}

	const [reqRunning, setReqRunning] = useState(initialState.reqRunning);
	const [submited, setSubmited] = useState(initialState.submited);
	const [result, setResult] = useState(initialState.result);
	const [items, setItems] = useState(initialState.items.slice(0));
	const [version, setVersion] = useState("0.1.0");

	useEffect(() => {
		if(submited || items.length > 0)
			triggerModal(true);
	}, [submited, items, result])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerProcess = () => {

		setSubmited(false)

		getItemsData()

		triggerModal(false);
	}

	let triggerModal = (refresh) => {
		console.info('result', result);
		
		props.modal({
			key: "FTP File Downloader",
			show: refresh? false : true,
			title: <span>FTP File Downloader <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);
				
				return items.length === 0? <Loading loading={true} centered={true}/> : <div>
					<div className="alert alert-info" role="alert">
						Download the latest feed sent by our suppliers
					</div>
					<div>
						<div className="d-flex flex-row flex-wrap">
							{
								items.map(o => {
									return <button key={`${o.name}_ftp_download`} onClick={() => getFileBody(o.name, o.data[0].date)} type="button" className="btn btn-primary m-2 text-nowrap" disabled={o.data.length > 0? false : true}>
										{o.name}
										&nbsp;-&nbsp;
										{
											o.data.length > 0? <span className="middeSizeText">
												<span>({o.data.length} file{o.data.length > 1? 's' : ''})</span>
												{(o.data[0].date? <div>latest: {o.data[0].date} </div> : '')}</span> :
												<span>0 file</span>
										}
									</button>
								})
							}
						</div>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, 
			ok: false,
			return: {
				title: reqRunning? "Please wait" : "Close",
				fnc: (popup, close) => {
					if(!submited){
						resetPage()
						close()
					}
				}
			}
		});
	}

	let fetchItems = (callbackItem) => {
		
		ajaxPerso( {
			"api": "ftp",
			"trigger": "get_ftp_list_info"
		}, callbackItem);
	}

	let getItemsData = () => {
		props.loading(true)
		fetchItems((r) => {
			props.loading(false)
			console.info('Fetched Items', r);
			if(!r){
				props.info({error: "Request error in getItemsData - ModalFtpFeed"})
			}else if(r.error){
				props.info({error: r.error})
			}else{
				setItems(r.data)
			}
		})
	}

	let fetchFileBody = (ftpAcc, callbackItem) => {
		
		ajaxPerso( {
			"api": "ftp",
			"trigger": "ftp_feeds",
			"ftpAcc": ftpAcc,
			"fileInfoOnly": false,
			"returnBase64": true
		}, callbackItem);
	}

	let getFileBody = (ftpAcc, date) => {
		props.loading(true)
		fetchFileBody(ftpAcc, (r) => {
			props.loading(false)
			console.info('Fetched fetchFileBody', r);
			if(!r){
				props.info({error: "Request error in fetchFileBody"})
			}else if(r.error){
				props.info({error: r.error})
			}else{

				/* const reader = new FileReader();

				reader.onload = () => {
					console.log(reader.result);
				};

				  reader.readAsBinaryString(blob); */


				/* var blob = new Blob([r.lastFile.binary], {type: r.lastFile.fileMime});
				var objectUrl = URL.createObjectURL(blob);
				// window.open(objectUrl);

				const url = window.URL.createObjectURL(blob);
				anchorElement.href = url;
				anchorElement.download = "test.xlsx";
				// anchorElement.click();

				window.open('/').location = URL.createObjectURL(blob); */

				downLoadFile(r.lastFile.base64, r.lastFile.fileName, r.lastFile.fileMime)


				/* let sheet = read(r.lastFile.binary, {
					type: "binary",
					dense: true
				}) */
				/* writeFile(sheet, `FTP_FEED_${ftpAcc}_${date}.` + (r.lastFile.fileMime.toLowerCase().indexOf('csv') !== -1? 'csv' : 'xlsx'), {
					bookType: (r.lastFile.fileMime.toLowerCase().indexOf('csv') !== -1? 'csv' : 'xlsx')
				}) */
			}
		})
	}

	return (
		<div className={("className" in props? props.className : 'list-group-item list-group-item-action p-2 text-center')} onClick={() => triggerProcess()}>
			{
				props.title
			}
		</div>
	);
}