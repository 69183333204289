// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo, createElement } from 'react'
import Loading from './Loading';
import { capitalizeFLetter, ajaxPerso } from '../../fnc'

const refConst = require("../../constants.js").v

export default function FastEditFeaturesEbayModal(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-MjVTgFHd2akT44hrjKg" // ebay_eio_write
	]

	const initialState = {
		// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
		items: [],
		submited: false,
	}

	var listRefs = []

	const [submited, setSubmited] = useState(initialState.submited);
	const [items, setItems] = useState(initialState.items.slice(0));
	const [version, setVersion] = useState("0.0.2");
	const [resReq, setResReq] = useState(null);
	const refChangeInput = useRef(null);

	const inputIndividualChanges = {};

	useEffect(() => {
		triggerModal(true);
	}, [items, submited])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerClick = (isForReload) => {
		getItemsData()
		triggerModal(isForReload);
	}

	let triggerModal = (refresh) => {
		console.info('listRefs', listRefs);

		props.modal({
			show: refresh? false : true,
			title: <span>eBay Bulk Quantity<h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('items.length', items.length);
				
				return items.length == 0? <Loading loading={true} centered={true}/> : <div>
					<div tabIndex={-1}>
						<div className="container">
							<p className="h5 mb-4">Item compatible for the selected marketplace: {items.length}</p>
							<div className="container bg-light p-3 border mb-3">
								<div className="row">
									<div className="col-sm d-flex align-items-center">
										<div className="input-group">
											<div className="input-group-prepend">
												<span className="input-group-text" id="basic-addon1">New Value for each selected item</span>
											</div>
											<input type="number" className="form-control" placeholder="New Value" aria-label="New Value" ref={refChangeInput}/>
											<div className="input-group-append">
												<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
													if(!refChangeInput || !refChangeInput.current)	return false;
														
													if(items.length == 0) 	props.info({success: "Please select some items first"})
													console.info('inputIndividualChangesinputIndividualChanges', inputIndividualChanges);
													
													items.map(o => {
														o.qte_available = parseInt(refChangeInput.current.value)
														o.autoQty = false
														if(inputIndividualChanges[o.id])
															inputIndividualChanges[o.id].value = parseInt(refChangeInput.current.value)
													})
													setItems(items.slice(0))
												}}>Change</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row p-3">
									<div className="col-sm d-flex align-items-center">
										<div className="custom-control custom-switch noselect w-100">
											<input type="checkbox" className="custom-control-input" id={`custom_auto_main_qty`} onChange={(e) => {
												items.map(o => {
													o.autoQty = e.target.checked
												})
												setItems(items.slice(0))
											}}/>
											<label className="custom-control-label" htmlFor={`custom_auto_main_qty`}>Toggle On/Off Auto QTY <span className="smallText">($0 to $100: 10, $101 to $500: 5, more than $500: 2)</span></label>
										</div>
									</div>
								</div>
							</div>
							<div>
								<table className="table">
									<thead>
										<tr>
											<th scope="col" className="bg-info text-white">Id</th>
											<th scope="col" className="bg-info text-white">Brand</th>
											<th scope="col" className="bg-info text-white">SKU</th>
											<th scope="col" className="bg-info text-white">Price</th>
											<th scope="col" className="bg-info text-white">Quantity</th>
											<th scope="col" className="bg-info text-white">Auto Qty</th>
											<th scope="col" className="bg-info text-white">Link</th>
											{resReq && Object.keys(resReq).length > 0? <th scope="col" className="bg-primary text-white">Status</th> : null}
										</tr>
									</thead>
									{
										items.length > 0?
											<tbody>
												{
													items.map((o, index) => {
														return	<tr key={o.id + "lineTagsSystem"}>
															<th scope="row">{o.id}</th>
															<td>{o.vendor}</td>
															<td>{o.sku}</td>
															<td>${o.price}</td>
															<td><input type="text" disabled={o.autoQty} defaultValue={o.qte_available} ref={(ref) => inputIndividualChanges[o.id] = ref} onChange={(val) => {
																o.qte_available = parseInt(val.target.value)
																console.info('inputIndividualChanges', inputIndividualChanges);
															}} style={{width: "100px"}}/></td>
															<td>
																<div className="custom-control custom-switch noselect w-100">
																	<input type="checkbox" className="custom-control-input" id={`custom_auto_qty_${index}`} checked={o.autoQty} onChange={(e) => {
																		o.autoQty = e.target.checked
																		setItems(items.slice(0))
																	}}/>
																	<label className="custom-control-label" htmlFor={`custom_auto_qty_${index}`}></label>
																</div>
															</td>
															<td><a target="_blank" rel="noreferrer" href={o.view_item_url_natural}>open</a></td>
															{resReq && Object.keys(resReq).length > 0? <td>{o.updated? "Success" : JSON.stringify(o.errors)}</td> : null}
														</tr>
													})
												}
											</tbody>
											: null
									}
								</table>
							</div>
						</div>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				resetPage()
				close()
			}
			, ok: !submited? {
				title: `Submit`,
				fnc: (popup, close) => {
					props.loading(true)

					console.info('items', items);

					reqTriggerPage((r) => {
						setResReq(r)
						setSubmited(false)
						props.loading(false)

						console.info('Fetch Req Trigger', r);

						let outputErrors = [];

						if(r.success){

							items.forEach(o => {
								let found = false
								r.data.updated.forEach( (u) => {
									if(o.sku == u.sku){
										found = true
										o.updated = true
										return;
									}
								})
								if(!found)
									r.data.notUpdated.forEach( (nu) => {
										if(o.sku == nu.sku){
											o.error = nu.errors // array
											return;
										}
									})
							})
						}

						if(r.error)		outputErrors.push(r.error)

						setItems(items.slice(0))
						console.log("outputErrors", outputErrors);
						if(outputErrors.length > 0)
							props.info({success: null, error: outputErrors})
					})
				}
			} : false, return: {
				title: "Return",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}

	let fetchItems = (callbackItem) => {
		
		ajaxPerso( {
			"api": "ebay",
			"trigger": "getItems",
			"idProductListJson": JSON.stringify(props.itemsSelected),
			"shop": props.store
		}, callbackItem);
	}

	let getItemsData = () => {
		fetchItems((r) => {
			console.info('Fetched Items', r);
			if(r.success){
				r.data.map(o => {
					o.autoQty = false
				})
				setItems(r.data)
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let reqTriggerPage = (callbackPresence) => {
		if(!items)
			throw("Please indicate a newItemList.");
		else{
			ajaxPerso({
				"api": "ebay",
				"trigger": "bulkUpdateQty",
				"shop": props.store,
				"data": JSON.stringify(items.map(o => {
					return {
						site: o.site,
						sku: o.sku,
						qty: o.qte_available,
						autoQty: o.autoQty
					}
				}))
			}, callbackPresence);
		}
	}

	if(!props.securityClearance(props, access))	return "";

	return (
		<div className="list-group-item list-group-item-action p-2 text-center" onClick={() => triggerClick()}>
			{
				props.title
			}
		</div>
	);
}