// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import { ajaxPerso, fetchAutoCreateProduct } from '../../../fnc.js'

var initialState = {
	_isMounted: false,
	items: [],
	count: 0,
	pageOpen: null,
	pageSize: 20,
}

const refConst = require("../../../constants.js").v

export default function SectionStats(props){
	const [items, setItems] = useState(initialState.items.slice(0));
	const [count, setCount] = useState(initialState.count);
	const [pageOpen, setPageOpen] = useState(initialState.pageOpen);
	const [pageSize, setPageSize] = useState(initialState.pageSize);
	const loadLast = useRef(null)
	const refSku = useRef(null)
	const [modalOpened, setModalOpened] = useState(false);
	const [loadPos, setLoadPos] = useState(0);
	const [endPageNbr, setEndPageNbr] = useState(null);

	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;
		preInit()
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		
		if(modalOpened)
			modalResult()
		
	}, [items, loadLast, count, modalOpened, pageOpen])

	useEffect(() => {

		if(modalOpened)
			init()
		
	}, [pageOpen, modalOpened])

	const init = (e, o) => {

		// List created
		/* fetchAutoCreateProduct( {
			"getCountOnly": true,
			"onlyWithSmallestSeleableUnit": null,
			"reviewed": 1,
			"doNotCreate": 0,
			"isCreated": 1
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct - isCreated', r);
			if(r.success){
				setCountCreated(r.count)
			}
		}); */

		/* fetchAutoCreateProduct( {
			"onlyWithSmallestSeleableUnit": null,
			"reviewed": 1,
			"doNotCreate": 0,
			"isVariantCreated": 1,
			"page": pageOpen,
			"limit": pageSize
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct', r);
			setItems(r.success? r.data : initialState.items)
		}); */

		let itemBase = {};
		let objReq = {
			"page": pageOpen,
			"size": pageSize,	
		}

		if(refSku.current && refSku.current.value)
			objReq.sku = refSku.current.value;

		[
			refConst.id_store_eBay_EIO,
			refConst.id_store_eio,
			refConst.id_store_hausfTools,
			refConst.id_store_amazon_GOTT,
			refConst.id_store_amazon_EIO,
		].forEach(idStore => {

			objReq.shop = idStore

			fetchProductCreated( objReq, (r) => {
				if(!initialState._isMounted)	return false
				console.info('fetchProductCreated', r);
				if(r.success){
					r.data.forEach(o => {
						o.idStore = idStore
						if(!(o.sku in itemBase))
							itemBase[o.sku] = []
						o.idStore = idStore
						itemBase[o.sku].push(o)
					})
					if(r.data.length == 0)
						setEndPageNbr(pageOpen)
					setItems(Object.assign({}, itemBase))
				}
			});
		})
	}

	const paginationGrouped = () => {

		let groupHtmlOut = [],
			maxCtn = Math.ceil(count/pageSize)

		console.info("maxCtn", maxCtn);

		for (let i = 0; i < maxCtn; i++) {
			
			groupHtmlOut.push(<button key={`${i+1}_paginationAutoCreate`} className={(pageOpen == i? "active" : "") + " btn btn-outline-info mr-2 mb-2"} onClick={() => {
				setPageOpen(i)
			}} title={`${i*pageSize} to ${i < maxCtn-1? (i+1)*pageSize : count}`}>
				{ i+1 }
			</button>)
		}
		return groupHtmlOut
	}

	const preInit = (e, o) => {

		fetchAutoCreateProduct( {
			"getCountOnly": true,
			"onlyWithSmallestSeleableUnit": null,
			"reviewed": 1,
			"doNotCreate": 0,
			"isVariantCreated": 1
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct Count', r);
			setCount(r.count)
		});
	}

	const htmlItems = (e) => {

		let listGrpHtml = [];
		let listUrlShopify = {}
		let listStoresNamePerId = {}
		refConst.stores.forEach(s => {
			if(s.account_name)
				listUrlShopify[s.id] = s.account_name
			listStoresNamePerId[s.id] = s.name
		})

		for (const sku in items) {
			if (Object.hasOwnProperty.call(items, sku)) {
				const l = items[sku];

				let listHtml = [];

				l.forEach(o => {

					let url = null,
						urlAdmin = null;
					if(o.idStore == refConst.id_store_eBay_EIO)
						url = `https://www.ebay.com/itm/${o.id_item}`
					if(o.idStore == refConst.id_store_eio)
						url = `https://${listUrlShopify[o.idStore]}/products/${o.handle}`
					if(o.idStore == refConst.id_store_hausfTools){
						url = `https://${listUrlShopify[o.idStore]}/products/${o.handle}`
					}
					if([refConst.id_store_amazon_GOTT, refConst.id_store_amazon_EIO].indexOf(o.idStore) !== -1){
						url = `https://www.amazon.com/dp/${o.asin1}?ref=myi_title_dp`
					}

					listHtml.push(<div key={`${o.id}_item_${o.idStore}`} className="border rounded m-2 mb-2" style={{
						maxWidth: "170px",
						width: "170px",
					}}>
						<div style={{
							maxWidth: "170px",
							width: "170px",
						}}>
							<div className="smallText font-weight-bold">{listStoresNamePerId[o.idStore]}</div>
							<div style={{
								minWidth: "100px",
								maxWidth: "100px",
								width: "100px",
								height: "100px",
								backgroundImage: `url(${o.img})`,
								backgroundOrigin: "center center",
								backgroundSize: "contain",
								backgroundRepeat: "no-repeat",
								margin: "auto",
							}}></div>
						</div>
						<div className="p-2">
							<div className="smallText mb-2">{o.title}</div>
							{"asin1" in o? <div className="smallText mb-2">Asin: {o.asin1}</div> : ""}
							<div className="smallText mb-2">${o.price}</div>
							<div><a href={url} target="_blank" rel="noreferrer"><button className="btn btn-sm btn-primary">Open</button></a></div>
						</div>
					</div>)
				})

				listGrpHtml.push(<div className="" style={{
					/* borderBottom: "2px solid #ccc", */
				}}>
					<div className="mt-2 bg-dark text-light rounded d-flex align-items-center justify-content-center"><span className="mr-2">{sku}</span> - <span className="smallText ml-2">{l[0].date}</span></div>
					<div className="d-flex flex-row">
						{listHtml}
					</div>
				</div>)
			}
		}
		return listGrpHtml;
	}

	const modalResult = (dataType) => {

		console.info("refConst.stores", refConst.stores);

		let idStoreToName = [];
		refConst.stores.forEach(s => {
			idStoreToName[s.id] = s.name
		})

		props.modal({
			show: true,
			title: "Product Created by sku",
			html: (close) => {
				return <div>
					<div className="d-flex justify-content-center flex-wrap">
						<div className="input-group flex-nowrap ml-3" style={{width: "405px"}}>
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">Search SKU:</span>
							</div>
							<input ref={refSku} type="text" className="form-control" placeholder="SKU"/>
							<div className="input-group-append">
								<button className="btn btn-dark" id="basic-addon1" onClick={() => {
									init()
								}}>Search</button>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center flex-wrap mt-3 mb-1">
						<div className={(pageOpen <= 0? "d-none" : "") + " input-group-prepend mr-1"}>
							<button className="btn btn-dark" style={{borderRight: "1px solid #CCC"}} onClick={() => {
								setPageOpen(pageOpen-1)
							}}>Prev</button>
						</div>
						<div className={(endPageNbr == pageOpen && pageOpen > 0? "d-none" : "") + " input-group-append ml-1"}>
							<button className="btn btn-dark" onClick={() => {
								setPageOpen(pageOpen+1)
							}}>Next</button>
						</div>
					</div>
					{
						<div className="text-center" style={{
							maxHeight: "800px",
							overflowY: "auto"
						}}>
							<p className="font-weight-bold">number: {loadPos+1}{loadPos===0? " (Latest)" : ""}</p>
							{
								htmlItems()
							}
						</div>
					}
				</div>
			},
			return: {
				title: "Close",
				fnc: (popup, close) => {
					setModalOpened(false)
					close()
				}
			},
			exit: (popup, close) => {
				setModalOpened(false)
				close()
			}
		})
	}
	
	const fetchProductCreated = (args, callback) => {
		
		if(!args)	throw("fetchProductCreated Please indicate a parameter");
		
		ajaxPerso(Object.assign({
			"api": "autoCreator",
			"trigger": "fetch_autoCreator_created_liaison",
		}, args), callback);
	}

	const sectionView = (sentense, colorCode, callback) => {
		
		return <div className={"mt-2 ml-3 mr-3 mb-2 p-4 rounded text-light font-weight-bold" + (callback? " pointer" : "")} onClick={callback? () => callback() : null} style={{
			backgroundColor: colorCode,
			textShadow: "0 0 2px BLACK"
		}}>
			{sentense}
		</div>
	}

	console.info("Modal items", items);
	console.info("Modal items count", count);
		
	return (
		sectionView(
			`${count} Variant Created`,
			"rgb(165, 105, 189)",
			() => {
				setModalOpened(true)
				modalResult()
				init()
			}
		)
	);
}