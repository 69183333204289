import $ from "jquery";
import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css';

export default function (inputNode, placeholder){
	return $(inputNode).summernote({
		placeholder: placeholder? placeholder : 'Write the description here.',
		tabsize: 2,
		height: 100,
		disableDragAndDrop: true,
		toolbar: [
			// [groupName, [list of button]]
			['style', ['bold', 'italic', 'underline', 'clear']],
			//['font', ['strikethrough', 'superscript', 'subscript']],
			['fontsize', ['fontsize']],
			['color', ['color']],
			['insert', ['link']],
			['para', ['ul', 'ol', 'paragraph']],
			['table', ['table']],
			['height', ['height']],
			['Misc', ['codeview']]
		]
		,
		callbacks: {
		//     onKeyup: function (e) {
		//         console.info($('#inputDesc').val());
		//     }
			// onInit: function() {
			// 	console.log('Summernote is launched');
			// 	$(nodeStr).summernote('justifyLeft');
			// }
			onPaste: function (e) {
				e.preventDefault();
				
				var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

				document.execCommand('insertText', false, bufferText);
				//$(nodeStr).summernote('insertText', bufferText);
				return false;
			}
		}
	});
}