import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import SheetCapture from './ctn/SheetCapture';
import $ from 'jquery';
import { utils, writeFile } from 'xlsx';
import Select, { components, createFilter } from 'react-select';
import { capitalizeFLetter, ajaxPerso, fetchMenu, uniqueArray, socketPerso } from '../fnc'
import moment from 'moment';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
const refConst = require("../constants.js").v

const {info, log, warn} = console
const initialState = {
	selectedType: null,
	typeList: null,
	vendorList: null,
	submited: false,
	deleteOldCol: true
};


export default function ShopifyCollectionChanger(props){

	const stores = refConst.stores.filter(o => {
		if(o.id_marketplace == 14)	return o
		return false
	})

	console.info("props", props);

	const [selectedTrigger, setSelectedTrigger] = useState(null);
	const [selectedStore, setSelectedStore] = useState(stores.length > 0? stores[0] : null);
	const [selectedType, setSelectedType] = useState(null);
	const [typeList, setTypeList] = useState([]);
	const [vendorList, setVendorList] = useState([]);
	const [submited, setSubmited] = useState(initialState.submited);
	const [deleteOldCol, setDeleteOldCol] = useState(initialState.deleteOldCol);
	const refNewCol = useRef(null);
	var colorTheme = selectedStore? (selectedStore.id == refConst.id_store_eio? "#f56600" : "#343a40") : "#CCC";

	useEffect(() => {
			
		init()

	}, [selectedStore])

	useEffect(() => {
		resetPage()
	}, [selectedStore])

	useEffect(() => {
		props.loading(submited)
	}, [submited])

	useEffect(() => {
		console.info("typeList", typeList);
		console.info("vendorList", vendorList);
		console.info("refNewCol", refNewCol.current.value);
	})

	let resetPage = () => {
		refNewCol.current.value = ""
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let init = () => {

		if(!selectedStore || !selectedStore.id)	return

		fetchMenu({
			shop: selectedStore.id,
			menuOrNot: true
		}, () => {
			props.loading(true)
		}, (r) => {
			props.loading(false)
			console.info("Result from getProductTypeList:", r);
			if(r.error){
				props.info({error: r.error})
			}else{
				let lT = uniqueArray(r.data.map(o => {
					return o.name
				}))
				lT.sort();
				setTypeList(lT)
			}
		})
	}

	const submitData = () => {

		if(!selectedType || !refNewCol.current.value || !selectedStore || !selectedStore.id){
			props.info({error: "Please fill all fields"})
			return
		}

		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			internalReq: {
				"method": "POST",
				"api": "shopify",
				"trigger": "replace_category",
				"shop": selectedStore.id,
				"currentType": selectedType,
				"newType": refNewCol.current.value,
				"deleteOldCol": deleteOldCol
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Replace a collection on Shopify [${selectedStore.id}]`
				}
			}
		})
		props.info({success: "The task has been initiated."})
	}

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage container">
			<h1 className="page-header mb-4">{props.title}</h1>
			<div className="p-3 bg-light border mb-3">
				<p className="font-weight-bolder">Store to compare to</p>
				<div className="d-flex flex-row align-items-center">
					{
						<Select
							className="w-50"
							styles={refConst.styleList}
							onChange={(selectedOption) => {
								setSelectedStore(selectedOption? JSON.parse(selectedOption.value) : initialState.selectedType)
							}}
							options={(() => {
								return stores.map(s => {
									return {
										value: JSON.stringify(s),
										label: `${s.name}`
									}
								})
							})()}
							// value={selectedStore? [{value: JSON.stringify(selectedStore), label: selectedStore.name}] : null}
							placeholder={"Brand"}
							isSearchable={true}
							isMulti={false}
						/>
					}
				</div>
			</div>
			<div className="d-flex justify-content-between align-items-stretch">
				<div className="mr-2 bg-light border p-3 text-wrap" style={{width: "45%"}}>
					{
						selectedTrigger? <div>
							{getApiProperty(selectedStore).triggers[selectedTrigger].alert}
						</div> : ''
					}
					<p className="font-weight-bolder">Select the current collection:</p>
					<Select
						className="w-100"
						// styles={refConst.styleList}
						onChange={(selectedOption) => {
							setSelectedType(selectedOption? selectedOption.value : stores[0])
						}}
						options={(() => {
							return typeList? typeList.map(t => {
								return {
									value: t,
									label: t
								}
							}) : null
						})()}
						value={selectedType? [{value: selectedType, label: selectedType}] : null}
						placeholder={"Collection"}
						isSearchable={true}
						isMulti={false}
					/>
					<div className="custom-control custom-switch noselect mt-2 w-100 position-static">
						<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={deleteOldCol} onChange={(e) => { setDeleteOldCol(e.target.checked) }}/>
						<label className="custom-control-label" htmlFor="customSwitchFree">Delete it once the new one is set</label>
					</div>
				</div>
				<div className="text-center d-flex align-items-center justify-content-center" style={{width: "10%"}}>
					<FontAwesomeIcon icon={faChevronRight} className="" style={{fontSize: "35px", minWidth: "25px"}}/>
				</div>
				<div className="ml-2 bg-light border p-3 text-wrap" style={{width: "45%"}}>
					<p className="font-weight-bolder">Define the new collection:</p>
					<div className="alert alert-info smallText" role="alert">
						<ul className="m-0 pl-3">
							<li>This Collection will be created automatically if it doesn&apos;t exist.</li>
							<li>The old URL will be automatically redirected to the new collection.</li>
						</ul>
					</div>
					<div className="input-group flex-nowrap">
						<input type="text" ref={refNewCol} className="form-control" placeholder="Collection Title" aria-label="Name"/>
					</div>
				</div>
			</div>
			{
				props.taskList.filter(o => {
					if(o.data && o.data.fnc == "replace_category")	return o
				}).map(o => {
					return <div key={`${o.id}_replace_category`} className="smallText mt-3 border pt-1 pr-3 pl-3 pb-2 rounded">
						<div className="badge mb-1 p-1">{o.date}</div>
						{
							o.data.resUpdate? <div className="alert alert-info mb-2 p-2" role="alert">
								<span className="border rounded p-2 m-2">Count product moved: {o.data.resUpdate.data.executed}</span>
								<span className="border rounded p-2 m-2">Count errors: {o.data.resUpdate.data.notUpdated.length}</span>
							</div> : ''
						}
						{
							o.data.resCreateCl && o.data.resCreateCl.success? <div className="alert alert-success mb-2 p-2" role="alert">
								{`The new collection has been created: ${o.data.resCreateCl.body.smart_collection.title}`}
							</div> : ''
						}
						{
							o.data.resDel && o.data.resDel.success? <div className="alert alert-success mb-2 p-2" role="alert">
								{`The old collection has been deleted.`}
							</div> : ''
						}
						{
							o.data.resRedirect && o.data.resRedirect.success? <div className="alert alert-success mb-2 p-2" role="alert">
								{`The old collection has been redirected to the new one.`}
							</div> : ''
						}
					</div>
				})
			}
			<div className={(selectedType? "" : "d-none") + " fixed-bottom text-center mb-1"} style={{
				height: "1px"
			}}>
				<button
					type="submit"
					className="d-flex align-items-center justify-content-center text-center btn btn-lg btn-block d-inline-block text-white mx-auto position-relative"
					disabled={props.loading()}
					style={{width: "50%", opacity: '0.8', bottom: "50px", backgroundColor: colorTheme}}
					onClick={() => {submitData()}}
				>
					<span>Submit</span>
				</button>
			</div>
		</div>
	);
}