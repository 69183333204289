// https://reacttraining.com/react-router/web/example/modal-gallery
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import './Pages/css/index.css';
import App from './App';
//import MainMenu from './MainMenu';
//import HeaderApp from './header';
// import { register, unregister } from './registerServiceWorker'; // service-worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// eslint-disable-next-line
import {
	BrowserRouter as Router,
	Switch, // eslint-disable-line
	Route, // eslint-disable-line
	Link // eslint-disable-line
} from 'react-router-dom';
import 'bootstrap';
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

var fncCtnNewUpdate = (callback) => {
	// console.log("Caches", caches);
	setTimeout(() => {
		// if (caches) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(function(names) {
				for (let name of names) caches.delete(name);
				if(callback)	callback();
				console.info('Website need to be refreshed');
			});
		// }
	}, 2000)
};

var fncCtnNewUpdateModal = (callback) => {
	console.log("Caches", caches);
	setTimeout(() => {
		// if (caches) {
			// Service worker cache should be cleared with caches.delete()
			/* caches.keys().then(function(names) {
				for (let name of names) caches.delete(name);
			}) */
			if(callback && typeof callback === "function")	callback();
			createRoot(document.getElementById('root')).render(<Router>
				{/* We need to use Route and the render attribut in order to get the history object from passed by */}
				<Route render={(props) => <App {...props} cacheRefresh={fncCtnNewUpdate} needReload={true}/>}/>
			</Router>)
			//});
		// }
		console.log("Check cache");
	}, 1500)
};

createRoot(document.getElementById('root')).render(<Router>
	{/* We need to use Route and the render attribut in order to get the history object from passed by */}
	<Route render={(props) => <App {...props} cacheRefresh={fncCtnNewUpdate} needReload={false}/>}/>
</Router>)

/* ReactDOM.render((
	<Router>
		<Route render={(props) => <App {...props} cacheRefresh={fncCtnNewUpdate} needReload={false}/>}/>
	</Router>
), document.getElementById('root')); */

//ReactDOM.render(<HeaderApp />, document.getElementById('header'));
//ReactDOM.render(<MainMenu />, document.getElementById('mainMenu'));
/*
ReactDOM.render( () => {
	<ul className="nav navbar-nav navbar-right">{(new MainMenu()).getLink().menu}</ul>
}, document.getElementById('navbarMenu'));
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//// serviceWorkerRegistration.unregister();

// console.warn("TEST", process);
serviceWorkerRegistration.register({
	onUpdate: fncCtnNewUpdateModal,
});
// register(fncCtnNewUpdateModal);
