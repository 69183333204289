import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import SheetCapture from './ctn/SheetCapture';
import $ from 'jquery';
import { utils, writeFile } from 'xlsx';
import Select, { components, createFilter } from 'react-select';
import { capitalizeFLetter, ajaxPerso, roundPrice, socketPerso } from '../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
const refConst = require("../constants.js").v

const {info, log, warn} = console
const initialState = {
	dataSheet: null,
	resTrigger: null,
	submited: false,
	createForMultiStore: true,
	errorsTrigger: null
};

export default function ShopifyBulkFile(props){

	const apiProperty = [{
		api: "shopify",
		name: "EIO.com",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjH_9MuY646871R7lUA", // shopify_eio_write
		]),
		fnc: () => triggerFnc("eio", "shopify", { createFor: refConst.id_store_eio }),
		val: [{
			required: ["sku", "fileUrl"]
		}]
	}, {
		api: "shopify",
		name: "Haus of Tools",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
		]),
		fnc: () => triggerFnc("hausOfTools", "shopify", { createFor: refConst.id_store_hausfTools }),
		val: [{
			required: ["sku", "fileUrl"]
		}]
	}].filter(o => {
		if(o.hasAccess)	return o
	})

	const [selectedStore, setSelectedStore] = useState(apiProperty.length > 0? apiProperty[0].name : null);
	const [dataSheet, setDataSheet] = useState(initialState.dataSheet);
	const [resTrigger, setResTrigger] = useState(initialState.resTrigger);
	const [errorsTrigger, setErrorsTrigger] = useState(initialState.errorsTrigger);
	const [submited, setSubmited] = useState(initialState.submited);
	const [createForMultiStore, setCreateForMultiStore] = useState(initialState.createForMultiStore);
	const [countReqToExecute, setCountReqToExecute] = useState(0);
	const [reqDoneCount, setReqDoneCount] = useState(0);

	useEffect(() => {
		info('dataSheet', dataSheet);
	}, [dataSheet])

	useEffect(() => {
		resetPage()
	}, [selectedStore])

	useEffect(() => {
		props.loading(submited)
	}, [submited])

	useEffect(() => {
		console.info('reqDoneCount', reqDoneCount);		
	}, [reqDoneCount])

	useEffect(() => {
		if(submited && reqDoneCount === countReqToExecute)
			setSubmited(false)
	}, [countReqToExecute, reqDoneCount, submited])

	/* useEffect(() => {
		// force file download
		dlDetails();
	}, [resTrigger]) */

	useEffect(() => {
		$('.tooltipInfoMap').tooltip({
			placement: "right",
			title: "When checked, if the product exist in one of our other stores (sku base), the files will also be associated."
		}).tooltip('update')
	})

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let stickyHeader = (item) => {
		// console.info('itemitemitemitem', item);
		return <span className="mr-2">{item.column.name}</span>;
	}
	
	const defaultColumnProperties = {
		resizable: true,
		headerRenderer: stickyHeader
	}

	let dlDetails = () => {
		if(!errorsTrigger)	return false

		var book  = utils.book_new()

		console.info('api', findStore(selectedStore).api);
		let listErrors = [];
		
		switch (findStore(selectedStore).api.toLowerCase()) {
		case "shopify":
			for (const sku in errorsTrigger) {
				if (Object.hasOwnProperty.call(errorsTrigger, sku)) {
					listErrors.push({
						"sku": sku,
						"error": JSON.stringify(errorsTrigger[sku])
					})
				}
			}
			utils.book_append_sheet(book, utils.json_to_sheet(listErrors), "Errors");
			break;
		default:
			break;
		}
		
		if(Object.keys(errorsTrigger).length > 0)
			writeFile(book, `Result_Error_${selectedStore}_Bulk_File_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
	}

	const modalConfirmation = (callback) => {
		props.modal({
			show: true,
			title: "Confirmation - Reprice",
			html: () => {
				return <div>
					{
						<div className="text-center">
							<p className="text-center text-danger font-weight-bold">Are you sure ?</p>
						</div>
					}
				</div>
			}
			,ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					if(callback)	callback()
					close()
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	let correctReturnedArray = (list) => {
		list.forEach(obj => {
			for (const key in obj) {
				if (Object.hasOwnProperty.call(obj, key)) {
					if(Array.isArray(obj[key]))
						obj[key] = obj[key].join(', ')
				}
			}
		})
		return list;
	}

	const showDifference = () => {
		if(dataSheet && resTrigger !== null){

			return <div>
				<table className="table table-sm">
					<thead>
						<tr>
							<th scope="col">Result</th>
							<th scope="col">Quantity</th>
						</tr>
					</thead>
					<tbody>
						<tr className="table-success"><td>Updated</td><td>{resTrigger.updated.length}</td></tr>
						<tr className="table-danger"><td>Errors</td><td>{resTrigger.notUpdated.length}</td></tr>
						<tr className="border-top"><td className="align-middle">Details</td><td><button type="button" className="btn btn-primary btn-sm" onClick={() => dlDetails()}>Download</button></td></tr>
					</tbody>
				</table>
			</div>
		}
	}

	const triggerPageFnc = () => {
		if(!dataSheet){
			props.info({error: "Please select a file, a sheet and a column to compare it with your marketplace."})
		}else{
			setSubmited(true)
			findStore(selectedStore).fnc()
		}
	}

	const triggerFnc = (storeName, api, shopObj) => {

		console.info('dataSheetdataSheet', dataSheet);

		// Pre Clean from empty value.
		let newOut = [];
		dataSheet.listObj.forEach((o, i) => {
			let listUrl = o.fileUrl.filter((f) => {
				if(f.trim().length > 0)	return f;
			});
			o.fileUrl = listUrl;
			if(o.fileUrl.length > 0)
				newOut.push(o)
		});

		let cpResTrigger = {},
			cpErrorsItems = {},
			reqRes = {done: 0}

		setCountReqToExecute(newOut.length)

		newOut.forEach((o, pos) => {
			setTimeout(() => { // Send 1 req per second
				SubmitReq(api, Object.assign({
					data: JSON.stringify([o])
				}, shopObj), cpResTrigger, cpErrorsItems, reqRes)
			}, Math.floor((pos / 1.1)) * 1000)
		})

		/* let createFor = [];
		if(createForMultiStore){
			createFor = [
				refConst.id_store_eio,
				refConst.id_store_hausfTools
			].join(',');
		} */
		
		/* dataSheet.listObj.forEach((o, pos) => {
			setTimeout(() => {
				socketPerso("shopifyBulkFile", {
					url: refConst.urlServer,
					token: refConst.tokenEio,
					internalReq: {
						"api": "shopify",
						"trigger": "bulkUpdateFile",
						"createFor": createFor.join(','),
						"data": JSON.stringify([o])
					}
				})
			}, 500)
		}) */
	}

	const submitData = (api, args, reqDone, callback) => {
		let type = getApiProperty(selectedStore)

		if(!type){
			props.info({error: "No type requived corresponding a test."})
		}else{

			let objAj = Object.assign({
				"trigger": "bulkUpdateFile",
				"api": api,
			}, args);

			if(createForMultiStore){
				objAj.createFor = [
					refConst.id_store_eio,
					refConst.id_store_hausfTools
				].join(',');
			}

			// console.info('ssssssssssss', objAj);
			// return;

			ajaxPerso( objAj, callback, function(jqXHR, textStatus) {
				reqDone.done++;
				setReqDoneCount(reqDone.done)
				console.warn( textStatus );
			});
		}
	}

	const SubmitReq = (api, args, cpResTrigger, cpErrorsItems, reqDone) => {
		submitData(api, args, reqDone, (r) => {

			console.info(`Fetching bulkUpdateFile ${api}:`, r);
			
			reqDone.done++;
			setReqDoneCount(reqDone.done)

			if(r.res && Object.keys(r.res).length > 0){
				for (const sku in r.res) {
					if (Object.hasOwnProperty.call(r.res, sku)) {
						cpResTrigger[sku] = r.res[sku]
					}
				}
				setResTrigger(JSON.parse(JSON.stringify(cpResTrigger)))
			}

			if(r.errors.length > 0){
				props.info({error: r.errors})
			}
			if(r.errorsItems && Object.keys(r.errorsItems).length > 0){
				for (const sku in r.errorsItems) {
					if (Object.hasOwnProperty.call(r.errorsItems, sku)) {
						cpErrorsItems[sku] = r.errorsItems[sku]
					}
				}
				setErrorsTrigger(JSON.parse(JSON.stringify(cpErrorsItems)))
			}
		})
	}

	const getApiProperty = (store) => {
		let type = null
		
		let reqSheet = Array.isArray(dataSheet.required)? dataSheet.required : [dataSheet.required]

		findStore(store).val.forEach(r => {
			reqSheet.forEach(r2 => {
				if(r.required.indexOf(r2.toLowerCase()) !== -1){
					type = r
					return false;
				}
			})
		})
		return type
	}

	const findStore = (store) => {
		return apiProperty.find(s => {
			if(s.name === store)	return s
		})
	}

	let addGrid = () => {
		
		if(!dataSheet)	return;

		let listView = [];

		console.info('errorsTrigger', JSON.stringify(errorsTrigger));
		
		dataSheet.listObj.map(o => {
			if(!Array.isArray(o.fileUrl))
				o.fileUrl = [o.fileUrl]
			listView.push({
				sku: o.sku,
				result_info: (resTrigger && o.sku in resTrigger?`<div style="line-height: normal; font-size: 10px;">`+
						`<div>Meta created: ${resTrigger[o.sku].meta.length}</div>` +
						`<div>Meta deleted: ${resTrigger[o.sku].resMetaDel.length}</div>` + 
						`<div>Azure created: ${resTrigger[o.sku].resCreateAz.length}</div>` + 
						`<div>Azure deleted: ${resTrigger[o.sku].resAzureDel.length}</div>` +
				`</div>` : null),
				result_error: ( errorsTrigger && o.sku in errorsTrigger? `<div style="line-height: normal;">` +
						`<div>${JSON.stringify(errorsTrigger[o.sku])}</div>` +
				`</div>` : '' ),
				fileUrl: o.fileUrl.map(url => {
					// return '<div>'+url+'</div>'
					return url
				}).join(",\n")
			})
		})


		let columns = dataSheet && dataSheet.listObj.length > 0? Object.keys(dataSheet.listObj[0]).map(key => {
			return {key: key, name: key}
		}) : [];

		columns = columns.concat([
			{key: "result_info", name: "result Info", formatter: (props)  => {
				return <span dangerouslySetInnerHTML={{__html: props.row[props.column.key]}}></span>
			}},
			{key: "result_error", name: "result error", formatter: (props)  => {
				return <span dangerouslySetInnerHTML={{__html: props.row[props.column.key]}}></span>
			}}
		])

		columns = columns.map(o => Object.assign(o, defaultColumnProperties))

		return <DataGrid
			headerRenderer={stickyHeader}
			columns={columns}
			rows={listView}
			rowHeight={() => {
				return 60;
			}}
			className="fill-grid"
			style={{ resize: 'both' }}
		/>
	}

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage">
			<h1 className="page-header mb-4">Shopify Bulk Files</h1>
			<div className="container p-3 bg-light border mb-3">
				<p className="font-weight-bolder">Store to compare to</p>
				<div className="d-flex flex-row align-items-center">
					{
						<Select
							className="w-50"
							styles={refConst.styleList}
							onChange={(selectedOption) => {
								setSelectedStore(selectedOption? selectedOption.value : initialState)
							}}
							options={(() => {
								return apiProperty.map(b => {
									return {
										value: b.name,
										label: `${b.name}`
									}
								})
							})()}
							value={selectedStore? [{value: selectedStore, label: selectedStore}] : null}
							placeholder={"Brand"}
							isSearchable={true}
							isMulti={false}
						/>
					}
					<div className="custom-control custom-switch noselect w-50  text-center">
						<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={createForMultiStore} onChange={(e) => { setCreateForMultiStore(e.target.checked? true : false) }}/>
						<label className="custom-control-label" htmlFor="customSwitchFree">
							Also Create for our other stores
							<span className="ml-3 tooltipInfoMap" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
						</label>
					</div>
				</div>
			</div>
			<div id="mainMenu" className="container mb-3 bg-light border p-3">
				<p className="font-weight-bolder">Select the primary type of data to use for comparison</p>
				{
					findStore(selectedStore).val.map(s => {
						return <SheetCapture allowArrayForSupData={true} key={s.required + "_sheetCapture"} className={"ml-4"} dataSheet={dataSheet} onChange={(val) => {
							setDataSheet(val)
						}} name={`From Sheet [by: ${s.required}]`} {...props} returnAll={true} required={Array.isArray(s.required)? s.required : [s.required]}/>
					})
				}
			</div>
			{
				dataSheet? <div className="container mt-4 bg-light p-3 border">
					<p className="font-weight-bolder">Informations</p>
					<div className="row">
						<div className="col-sm">
							Line number:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.sheet.length > 0? dataSheet.sheet.length-1 : 0 }</span>
						</div>
					</div>
					<div className="row">
						<div className="col-sm">
							The column selected:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.columnName.join(', ') }</span>
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							<button type="button" className="btn btn-primary btn-lg" onClick={() => {modalConfirmation(triggerPageFnc)}} disabled={submited? true : false}>Submit</button>
							{ errorsTrigger && !submited? <button type="button" className="btn btn-primary btn-sm ml-3" onClick={() => dlDetails()}>Download Error Report</button> : '' }
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							{
								submited? <div>
									Items Progress: {reqDoneCount}/{countReqToExecute}
								</div> : ''
							}
							<div className="progress">
								<div className="progress-bar" role="progressbar" style={{width: Math.ceil(reqDoneCount/countReqToExecute*100) + "%"}} aria-valuenow={Math.ceil(reqDoneCount/countReqToExecute*100)} aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>
					</div>
					<div className="row">
						<span className="col justify-content-center text-center mt-3">
							{ addGrid() }
						</span>
					</div>
				</div> : ''
			}
		</div>
	);
}