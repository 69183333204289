// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment'
import PropTypes from 'prop-types';
const refConst = require("../../../constants.js").v
//var moment = require('moment');

function Filter(props){
	var toObj = null;
	const currentYear = new Date().getFullYear();
	const fromMonth = new Date(currentYear, 0);
	const toMonth = new Date(currentYear + 10, 11);

	const [monthSelectedDate1, setMonthSelectedDate1] = useState(props.fromTo.from);
	const [monthSelectedDate2, setMonthSelectedDate2] = useState(props.fromTo.from);


	useEffect(() => {
	}, [])


	const yearMonthForm = (date, dateSelected, localeUtils, onChange) => {
		/* console.info('dateSelected', dateSelected);
		console.info('date', date); */
		//console.info('localeUtils', localeUtils);
		const months = localeUtils.getMonths();
		//const months = moment.months()
		
		const years = [];
		for (let i = moment().format('YYYY'); i > moment().format('YYYY') - 10; i--) {
			years.push(i);
		}

		//let date = ctnRef == "from"? props.from : props.to;
	
		const handleChange = function handleChange(e) {
			const { year, month } = e.target.form;
			//console.info('date_changed_to1', date);
			//onChange(moment(date).month(month.value).year(year.value).format());
			//console.log(new Date(moment(date).month(month.value).year(year.value).format()));
			console.warn(new Date(moment(date).month(month.value).year(year.value).format()));
			
			onChange(new Date(moment(date).month(month.value).year(year.value).format()));
		};

		/* console.info(moment(date).format('MMMM'));
		console.info(dateSelected); */
		
		return (
			<form className="DayPicker-Caption">
				{/* <select name="month" onChange={handleChange} value={moment(date).month()}> */}
				<select className="form-control w-auto p-0 d-inline-block" name="month" onChange={handleChange} value={dateSelected.getMonth()}>
					{months.map((month, i) => (
						<option key={month} value={i}>
							{month}
						</option>
					))}
				</select>
				{/* <select name="year" onChange={handleChange} value={moment(date).month()}> */}
				<select className="form-control w-auto p-0 d-inline-block ml-2" name="year" onChange={handleChange} value={dateSelected.getFullYear()}>
					{years.map(year => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</select>
			</form>
		);
	}

	const overlayComponent = (classNames, selectedDay, children, argsAttribut) => {

		/* console.info('children', children);
		console.info('selectedDay', selectedDay); */

		return (
			<div
				className={classNames.overlayWrapper}
				style={{ /* marginLeft: -100 */ }}
				{...argsAttribut}
				/* onBlur={argsOverlay.onBlur} 
				onFocus={argsOverlay.onFocus} 
				selectedDay={argsOverlay.selectedDay} 
				tabIndex={argsOverlay.tabIndex}  */
			>
				<div className={classNames.overlay}>
					<div className={selectedDay[0]? "bg-info text-white p-1" : "" }>
						{ selectedDay[0]? <span className="pl-2">
							{
								`You picked: ${selectedDay[0].toLocaleDateString()}`
							}
							<button type="button" className="btn btn-primary btn-sm ml-2 mr-2" onClick={() => {
								props.setFromTo({to: props.fromTo.to, from: null});
							}}>Reset</button>
						</span> : ''}
						{ "before" in selectedDay[1] && selectedDay[1].before? <span className="pr-2">
							{
								` to: ${selectedDay[1].before.toLocaleDateString()}`
							}
							<button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => {
								props.setFromTo({from: props.fromTo.from, to: null});
							}}>Reset</button>
						</span> : ''}
					</div>
					{children}
				</div>
			</div>);
	}


	const selectedDays = () => {
		let diffDays = moment(props.fromTo.from).diff(props.fromTo.to, 'days');

		console.info('diffDays', diffDays);

		// Special case for when we selected the same day
		if(props.fromTo.from && props.fromTo.to){
			if(diffDays === 0)
				return [
					props.fromTo.from,
					{ after: false, before: false }
				]
			else{
				return [
					props.fromTo.from,
					{ after: props.fromTo.from, before: props.fromTo.to }
				]
			}
		}else
			return [
				props.fromTo.from? props.fromTo.from : false,
				{ after: false, before: false }
			]
	}

	const onlyForOneDay = () => {
		return !('onlyOneDay' in props) || !props.onlyOneDay? false : true;
	}
	

	overlayComponent.propTypes = {
		classNames: PropTypes.object.isRequired,
		selectedDay: PropTypes.instanceOf(Date),
		children: PropTypes.node.isRequired,
	};

	console.info('Date selected check', [ props.fromTo.from, Object.assign( (props.fromTo.from && props.fromTo.to? { after: props.fromTo.from, before: props.fromTo.to } : {}) )]);
	

	return (
		<div className="d-flex align-items-center">
			{/* <div className="btn-group btn-group-toggle" data-toggle="buttons">
				<label className="btn btn-secondary active">
					<input type="radio" name="options" id="option1" checked={true} onChange={() => {}}/>Value
				</label>
				<label className="btn btn-secondary">
					<input type="radio" name="options" id="option2" onChange={() => {}}/>Qty
				</label>
			</div> */}
			{props.top !== null? <div className="input-group" style={{width: "135px"}}>
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Top:</span>
				</div>
				<input value={props.top? props.top : ''} onChange={(val => { props.setTop(val.target.value) })} type="number" step="1.00" className="form-control" placeholder="Top" />
			</div> : ''}
			{
				props.returnDatePiker || !("returnDatePiker" in props)?
					<span className="InputFromTo ml-4">
						<DayPickerInput
							value={props.fromTo.from}
							placeholder={onlyForOneDay()? "Date" : "From" }
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							hideOnDayClick={false}
							/* showOverlay={true} */
							overlayComponent={({ classNames, selectedDay, children, ...props }) => {
								// console.log("classNames", classNames);
								if(onlyForOneDay())
									classNames.overlay = classNames.overlay + ' withAuto';
								return overlayComponent(classNames, selectedDay, children, props)
							}}
							dayPickerProps={Object.assign({
								selectedDays: selectedDays(),
								showWeekNumbers: true,
								disabledDays: ('disabledDays' in props)? props.disabledDays : {},
								month: monthSelectedDate1,
								toMonth: props.fromTo.to,
								numberOfMonths: onlyForOneDay()? 1 : 2 ,
								//onDayClick: () => toObj.getInput().focus(),
								captionElement: ( { date, localeUtils } ) => {
									return yearMonthForm(props.fromTo.from, date, localeUtils, (newMonth) => {
										//console.info('monthOut', newDate);
										//props.setFrom(new Date(newDate))
										if(newMonth != "Invalid Date")
											setMonthSelectedDate1(newMonth)
									})
								},
							}, (onlyForOneDay()? { toMonth: props.fromTo.to, start: props.fromTo.from, end: props.fromTo.to } : {}))}
							onDayChange={(fromReceived) => {
								/* console.log("fromReceived", fromReceived);
								console.log("props", props); */
								
								props.fromTo.from = fromReceived
								props.setFromTo(props.fromTo)
								//console.info('Date format: ', fromReceived);
							}}
							inputProps={{"className": "form-control", style: {width: "200px"}}}
						/>
						{ !onlyForOneDay()? <span className="InputFromTo-to">
							{/* (()=>{console.log("ALOOOOOO",  [ props.fromTo.from, { after: props.fromTo.from, before: props.fromTo.to }]);})() */}

							{<DayPickerInput
								//style={{marginLeft: '-1.5em'}}
								ref={el => (toObj = el)}
								value={props.fromTo.to}
								placeholder="To"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								overlayComponent={({ classNames, selectedDay, children, ...props }) => {return overlayComponent(classNames, selectedDay, children, props) }}
								dayPickerProps={Object.assign({
									selectedDays: selectedDays(),
									showWeekNumbers: true,
									disabledDays: ('disabledDays' in props)? props.disabledDays : {},
									month: monthSelectedDate2,
									fromMonth: props.fromTo.from,
									//toMonth: props.to,
									numberOfMonths: 2,
									captionElement: ( { date, localeUtils } ) => {
										return yearMonthForm(props.fromTo.from, date, localeUtils, (newMonth) => {
											//console.info('monthOut', newDate);
											//props.setFrom(new Date(newDate))
											if(newMonth != "Invalid Date")
												setMonthSelectedDate2(newMonth)
										})
									}
								}, { start: props.fromTo.from, end: props.fromTo.to })}
								onDayChange={(toReceived) => {
									//props.setTo(toReceived)
									props.fromTo.to = toReceived
									props.setFromTo(props.fromTo)
								}}
								inputProps={{"className": "form-control", style: {width: "200px", marginLeft: "15px"}}}
							/>}
						</span> : '' }
					</span> : ''
			}
			{props.moreFilters? props.moreFilters : ''}
		</div>
	);
}

export default Filter;