import React, { useState, useEffect, useCallback} from 'react'
import SheetCapture from './ctn/SheetCapture';
import $ from 'jquery';
import { utils, writeFile } from 'xlsx';
import Select from 'react-select';
import { capitalizeFLetter, ajaxPerso } from '../fnc';
// import * as canvasDatagrid from 'canvas-datagrid';
const refConst = require("../constants.js").v

const {info, log, warn} = console

const initialState = {
	dataSheet: null,
	resTrigger: null,
	dataFiltered: null,
	wantToAddPrice: false
};

export default function Compare(props){

	const apiProperty = [{
		name: "eBay - EIO",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjVTgFHd2akT44hrjKg", // ebay_eio_write
		]),
		fnc: (callback) => loadItems("ebay", { shop: refConst.id_store_eBay_EIO }, callback),
		val: [{
			required: "sku",
			req: "skuListJson",
			res: "sku_in_store"
		},{
			required: "upc",
			req: "upcListJson",
			res: "upc"
		}]
	},{
		name: "EIO.com",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjH_9MuY646871R7lUA", // shopify_eio_write
		]),
		fnc: (callback) => loadItems("shopify", { shop: refConst.id_store_eio }, callback),
		val: [{
			required: "sku",
			req: "skuListJson",
			res: "sku"
		},{
			required: "upc",
			req: "upcListJson",
			res: "upc"
		}]
	}, {
		name: "Haus of Tools",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
		]),
		fnc: (callback) => loadItems("shopify", { shop: refConst.id_store_hausfTools }, callback),
		val: [{
			required: "sku",
			req: "skuListJson",
			res: "sku"
		},{
			required: "upc",
			req: "upcListJson",
			res: "upc"
		}]
	}, {
		name: "Walmart - EIO",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjWGqGi59xNbduybZHA", // walmart_eio_write
		]),
		fnc: (callback) => loadItems("walmart", { shop: refConst.id_store_walmart_eio }, callback),
		val: [{
			required: "sku",
			req: "skuListJson",
			res: "sku_on_walmart"
		},{
			required: "upc",
			req: "upcListJson",
			res: "upc"
		},{
			required: "id_listing",
			req: "idInStoreListJson",
			res: "id_on_walmart"
		}]
	}, {
		name: "Amazon - EIO",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjWHEUEG0HJvoZtpe19", // amazon_eio_write
		]),
		fnc: (callback) => loadItems("amazon", { shop: refConst.id_store_amazon_EIO }, callback),
		val: [{
			required: "sku",
			req: "skuListJson",
			res: "sku_on_amazon"
		},{
			required: "asin",
			req: "asinListJson",
			res: "asin1"
		},{
			required: "id_listing",
			req: "idListingListJson",
			res: "id_listing"
		}]
	},{
		name: "Amazon - GOTT",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjWHEUEG0HJvoZtpe19", // amazon_eio_write
		]),
		fnc: (callback) => loadItems("amazon", { shop: refConst.id_store_amazon_GOTT }, callback),
		val: [{
			required: "sku",
			req: "skuListJson",
			res: "sku_on_amazon"
		},{
			required: "asin",
			req: "asinListJson",
			res: "asin1"
		},{
			required: "id_listing",
			req: "idListingListJson",
			res: "id_listing"
		}]
	}].filter(o => {
		if(o.hasAccess)	return o
	})

	const [selectedStore, setSelectedStore] = useState(apiProperty.length > 0? apiProperty[0].name : null);
	const [dataSheet, setDataSheet] = useState(initialState.dataSheet);
	const [resTrigger, setResTrigger] = useState(initialState.resTrigger);
	const [dataFiltered, setDataFiltered] = useState(initialState.dataFiltered);
	const [wantToAddPrice, setWantToAddPrice] = useState(initialState.wantToAddPrice);

	/* useEffect(() => {
		info('dataSheet', dataSheet);
	}, [dataSheet]) */

	useEffect(() => {
		resetPage()
	}, [selectedStore])

	useEffect(() => {
		if(dataSheet)
			triggerPageFnc()
	}, [wantToAddPrice])

	useEffect(() => {
		
		/* if(dataSheet && resTrigger !== null){
			let found = [],
				notFound = []
			let type = getApiProperty(selectedStore)

			dataSheet.sheet.forEach(o => {

				let tFound = resTrigger.find(t => {
					if(t[type.res] === o[dataSheet.columnName])	return t
				})

				if(tFound)	found.push(o)
				else		notFound.push(o)
			});

			info('found', found)
			info('notFound', notFound)
	
			var book  = utils.book_new(),
				sheet = utils.json_to_sheet(found),
				sheet2 = utils.json_to_sheet(notFound)
	
			utils.book_append_sheet(book, sheet, "found");
			utils.book_append_sheet(book, sheet2, "notFound");
	
			writeFile(book, "result.xlsx")
		} */
		
	}, [resTrigger])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				// console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let createFilter = (oData, headers) => {
		var book  = utils.book_new()
		for (const columnName in oData) {
			if (oData.hasOwnProperty(columnName)) {
				let sheet = utils.json_to_sheet(oData[columnName], {skipHeader: true});
				utils.book_append_sheet(book, sheet, columnName);
			}
		}
		console.info('oData', oData);
		
		writeFile(book, `Result_${selectedStore}_${Object.keys(oData).join('-')}.xlsx`)
	}

	const filterData = (data) => {
		let found = [],
			notFound = [],
			duplicate = [];
		if(dataSheet && data !== null){
			let type = getApiProperty(selectedStore)

			console.info('typetype', type);
			console.info('resTrigger', data);
			console.info('dataSheet', dataSheet);

			if(type){
				let idValListSql = [],
					priceValListSql = [];

				data.forEach(o => {
					idValListSql.push(o[type.res]);
					if(wantToAddPrice)
						priceValListSql.push(o.price);
				})

				let posIdColumn = null;
				dataSheet.sheet[0].forEach((title, pos) => {
					if(dataSheet.columnName[0] == title){
						posIdColumn = pos;
						return false;
					}
				})

				console.info('posIdColumn', posIdColumn);
				
				if(posIdColumn === null)
					return false;

				let listDupTmp = [];
				for (let i = 1; i < dataSheet.sheet.length; i++) {
					const lineData = dataSheet.sheet[i].slice(0);
					let idVal = lineData[posIdColumn];

					// If thile line has an ID
					if(idVal){

						if(duplicate.indexOf(idVal) !== -1){ continue }

						// Isolate Duplicate
						/* for (let c = i+1, l = dataSheet.columnData[0].length; c < l; c++) {
							
							if(idVal === dataSheet.columnData[0][c]){
								duplicate.push(idVal)
								break;
							}
						} */

						let dupChecking = dataSheet.columnData[0].filter(cIdVal => {
							if(cIdVal == idVal)	return cIdVal
							return false;
						})

						if(dupChecking.length > 1 && listDupTmp.indexOf(idVal) === -1){
							listDupTmp.push(idVal)
							duplicate.push([idVal])
						}

						var foundValPos = idValListSql.indexOf(idVal);

						/* if(foundValPos.length > 1){
							duplicate.push(foundValPos[0])
						}else  */
						if(foundValPos !== -1){
							if(wantToAddPrice)
								lineData.push(priceValListSql[foundValPos])
							found.push(lineData)
						}else{
							notFound.push(lineData);
						}
					}
				}
		
				/* dataSheet.columnData[0].forEach((idVal, i) => {

					// We pass this element if it was already met prior, meaning the current id is a duplicate.
					if(duplicate.indexOf(idVal) !== -1)	return false;

					// Isolate Duplicate
					for (let c = i+1, l = dataSheet.columnData[0].length; c < l; c++) {
						
						if(idVal === dataSheet.columnData[0][c]){
							duplicate.push(idVal)
							break;
						}
					}

					var foundVal = idValListSql.indexOf(idVal) === -1;

					// if(foundVal.length > 1){
					// 	duplicate.push(foundVal[0])
					// }else
					if(foundVal)
						found.push(idVal)
					else{
						notFound.push(idVal);
					}
				}); */
			}
			info('found', found)
			info('notFound', notFound)
			info('duplicate', duplicate)
		}
		setDataFiltered({
			found: found,
			notFound: notFound,
			duplicate: duplicate
		})
	}

	const showDifference = () => {
		console.info('dataFiltered', dataFiltered);
		
		if(dataFiltered){
			let type = getApiProperty(selectedStore)
			let headherLine = dataSheet.sheet[0].slice(0)
			if(wantToAddPrice)
				headherLine.push("DB Price")
			dataFiltered.found.unshift(headherLine)
			dataFiltered.notFound.unshift(headherLine)
			dataFiltered.duplicate.unshift(dataSheet.required)

			return <div>
				<table className="table table-sm">
					<thead>
						<tr>
							<th scope="col">Result</th>
							<th scope="col">Quantity</th>
							<th scope="col">File</th>
						</tr>
					</thead>
					<tbody>
						<tr className="table-success"><td>Found</td><td>{dataFiltered.found.length-1}</td><td><button type="button" className="btn btn-outline-primary btn-sm" onClick={() => 
							createFilter({ "found": dataFiltered.found })
						}>Download</button></td></tr>
						<tr className="table-danger"><td>Not Found</td><td>{dataFiltered.notFound.length-1}</td><td><button type="button" className="btn btn-outline-primary btn-sm" onClick={() => 
							createFilter({ "notFound": dataFiltered.notFound })
						}>Download</button></td></tr>
						<tr className="table-warning"><td>Duplicate</td><td>{dataFiltered.duplicate.length-1}</td><td><button type="button" className="btn btn-outline-primary btn-sm" onClick={() => 
							createFilter({ "duplicate": dataFiltered.duplicate	}, dataSheet.required)
						}>Download</button></td></tr>
						<tr className="border-top"><td colSpan="2" className="align-middle">Complete File</td><td><button type="button" className="btn btn-primary btn-sm" onClick={() => {
							createFilter({
								found: dataFiltered.found,
								notFound: dataFiltered.notFound,
								duplicate: dataFiltered.duplicate,
							})}}>Download</button></td></tr>
					</tbody>
				</table>
				<div className="overflow-auto" style={{maxHeight: "300px"}}>
					{ showTable(type, dataFiltered.found, dataFiltered.notFound, dataFiltered.duplicate) }
				</div>
			</div>
		}
	}

	const showTable = (type, found, notFound, duplicate) => {
		if(dataSheet.columnData.length === 0)	return null

		let keyColumnName = dataSheet.columnName[0],
			posColumnId = null;

		dataSheet.sheet[0].forEach((colHeadTitle, index) => {
			if(colHeadTitle === keyColumnName)	posColumnId = index;
		})

		return <table className="table table-sm">
			<thead>
				<tr>
					{
						found[0].map((k, index) => <th key={`${k}_${index}_colThCompareFound`} scope="col">{ k }</th>)
					}
				</tr>
			</thead>
			<tbody>
				{
					dataSheet.sheet.map((lineList, i) => {
						if(!lineList[posColumnId] || i === 0)	return null
						
						let tDupli = duplicate.find( d => {
							if(d[0] == lineList[posColumnId])	return d;
							return false;
						})

						let tFound = found.find(oFound => {
							// info(`${oFound[posColumnId]} === ${lineList[posColumnId]}`)
							if(oFound[posColumnId] === lineList[posColumnId])	return oFound
						})

						return <tr key={`${lineList[posColumnId]}_${i}_colTrCompareFound`} className={tFound? "table-success" : "table-danger"}>
							{
								lineList.map((val, pos) => <td key={`${val}_${i}_${pos}_colTDCompareFound`}>
									<span className="d-flex align-items-center justify-content-center">
										{ val }
										{ tDupli && tFound && posColumnId === pos? <span className="badge badge-success ml-3">Duplicate in Sheet</span> : ''}
									</span>
								</td>)
							}{wantToAddPrice && tFound && tFound.length == found[0].length? <td>{tFound[tFound.length-1]}</td> : null }
						</tr>
					})
				}
			</tbody>
		</table>
	}

	const triggerPageFnc = () => {
		if(!dataSheet){
			props.info({error: "Please select a file, a sheet and a column to compare it with your marketplace."})
		}else{
			findStore(selectedStore).fnc(filterData)
		}
	}
	
	const fetchItems = (api, args, callback) => {
		let type = getApiProperty(selectedStore)
		props.loading(true)

		if(!type){
			props.info({error: "No type requived corresponding a test."})
		}else{
			let listValue = [];

			// The test has to be effectued on the first column selected.
			dataSheet.columnData[0].forEach(val => {
				if(val)
					listValue.push(val)
			})
	
			ajaxPerso( Object.assign({
				"trigger": "getItems",
				"allowDeletedFromProduct": true,
				"api": api,
				[type.req]: JSON.stringify(listValue),
			}, args), callback);
		}
	}

	const loadItems = (api, args, callback) => {
		fetchItems(api, args, (r) => {
			props.loading(false)

			console.info(`Fetching fetchItems ${api}:`, r);

			if(r.success){
				if(r.data)
					// setResTrigger(r.data)
					callback(r.data)
			}else{
				props.info({"error": r.errors})
			}
		})
	}

	const getApiProperty = (store) => {
		let type = null

		/* info("test", dataSheet.required)
		info("test2", findStore(store).val) */
		
		let reqSheet = Array.isArray(dataSheet.required)? dataSheet.required : [dataSheet.required]

		findStore(store).val.forEach(r => {
			reqSheet.forEach(r2 => {
				if(r.required.indexOf(r2.toLowerCase()) !== -1){
					type = r
					return false;
				}
			})
		})
		return type
	}

	const findStore = (store) => {
		return apiProperty.find(s => {
			if(s.name === store)	return s
		})
	}

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage">
			<h1 className="page-header mb-4">Get a list of products already listed or not</h1>
			<div className="container p-3 bg-light border mb-3">
				<p className="font-weight-bolder">Store to compare to</p>
				{
					<Select
						styles={refConst.styleList}
						onChange={(selectedOption) => {
							setSelectedStore(selectedOption? selectedOption.value : initialState)
						}}
						options={(() => {
							return apiProperty.map(b => {
								return {
									value: b.name,
									label: `${b.name}`
								}
							})
						})()}
						value={selectedStore? [{value: selectedStore, label: selectedStore}] : null}
						placeholder={"Brand"}
						isSearchable={true}
						isMulti={false}
					/>
				}
			</div>
			<div id="mainMenu" className="container mb-3 bg-light border p-3">
				<p className="font-weight-bolder">Select the primary type of data to use for comparison</p>
				<div className="d-flex flex-row align-items-center">
					{
						findStore(selectedStore).val.map(s => {
							return <SheetCapture key={s.required + "_sheetCapture"} className={"ml-4"} dataSheet={dataSheet} onChange={(val) => {
								setDataFiltered(initialState.dataFiltered)
								setDataSheet(val)
							}} name={`From Sheet [by: ${s.required}]`} {...props} returnAll={true} required={[s.required]}/>
						})
					}
					<div className="custom-control custom-switch ml-4">
						<input type="checkbox" className="custom-control-input" id="customSwitchAddPrice" checked={wantToAddPrice} value={wantToAddPrice} onChange={(e) => { setWantToAddPrice(e.target.checked? true : false) }}/>
						<label className="custom-control-label noselect pointer" htmlFor="customSwitchAddPrice">Add current price to sheet</label>
					</div>
				</div>
			</div>
			{
				dataSheet? <div className="container mt-4 bg-light p-3 border">
					<p className="font-weight-bolder">Informations</p>
					<div className="row">
						<div className="col-sm">
							Line number:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.columnData[0].length }</span>
						</div>
					</div>
					<div className="row">
						<div className="col-sm">
							The column selected for Comparison:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.columnName.join(', ') }</span>
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							<button type="button" className="btn btn-primary btn-lg" onClick={triggerPageFnc}>Compare</button>
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							{ showDifference() }
						</div>
					</div>
				</div> : ''
			}
		</div>
	);
}