// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { fetchApiData } from '../../../fnc'
import moment from 'moment';

const refConst = require("../../../constants.js").v

var initialState = {
	_isMounted: false,
	poList: [],
}

export default function ManufacturerMain(props){

	const [poList, setPoList] = useState(initialState.poList);

	useEffect(() => {
		$('.tooltipQuickViewStatus').tooltip({
			placement: "bottom",
			template:  '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-info-ebay"></div></div>',
		}).tooltip('update')

		$('.tooltipQuickViewStatus').on('click', function() {
			$('.tooltip').tooltip('hide');
		});
	})

	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {

		props.loading(true)
		
		fetchApiData(
			"finale",
			"fetch_most_recent_PO", {
				// "all": true
			}, null,
			(r) => {
				props.loading(false)
				console.info('fetch_most_recent_PO', r);
				setPoList(r.success && r.data && r.data.length > 0? r.data : null)
			}
		)
	}, [])

	if(!props.securityClearance(props))	return "";

	let supplierIdToName = []
	if(props.suppliers.length > 0)
		props.suppliers.forEach((supplier) => {
			supplierIdToName[`1${String(supplier.id_finale).padStart(4, '0')}`] = supplier.name
		})

	console.info("poList", poList);
	console.info("supplierssuppliers", props.suppliers);
	console.info("supplierIdToName", supplierIdToName);

	return (<div className="m-3 border rounded d-inline-block" style={{
		backgroundColor: "rgb(238, 238, 238)"
	}}>
		<div className="text-center ml-3 mr-3">Last PO Status</div>
		<div style={{
			maxHeight: "300px",
			overflowY: "auto",
			overflowX: "hidden",
			boxShadow: "inset 0px 0px 5px 0px rgb(173 173 173)"
		}} className="bg-light ml-2 mr-2 mb-2 rounded">
			{
				props.suppliers && props.suppliers.length > 0 && poList && poList.length > 0? poList.map(o => {

					let colorStatus = "bg-info"

					let dateLastPo = moment(o.last_po_date)
					let diff = moment().diff(dateLastPo, 'days');

					console.info("o.supplier_ido.supplier_id", o.supplier_id);
					console.info("diffdiff", diff);

					if(diff <= 7)
						colorStatus = "bg-success"
					else if(diff <= 14)
						colorStatus = "bg-warning"
					else if(diff > 14)
						colorStatus = "bg-danger"

					return <div
						key={`${o.supplier_id}_quickViewSupplier`}
						className="d-flex flex-row align-items-center justify-content-between m-1" style={{
							width: "400px",
						}}
					>
							<span className="ml-3" data-idsupplier={o.supplier_id}>{supplierIdToName[o.supplier_id]}</span>
							<div
								data-html="true"
								title={`
									<div>supplier ID: ${o.supplier_id}</div>
									<div>Last PO date: ${o.last_po_date}</div>
									<div>${diff} days ago</div>`
								}
								style={{
								borderRadius: "100px",
								height: "50px",
								width: "50px",
							}} className={"tooltipQuickViewStatus mr-1 " + colorStatus}></div>
					</div>
				}) : <div className="m-3">Loading...</div>
			}
		</div>
	</div>);
}