import React, { Component } from 'react';
import MainMenu from './MainMenu';
import HeadPage from './HeadPage';
import Winning from './Pages/winning';
// import DevMenu from './DevMenu';
import Login from './Pages/Login';
import Info from './Pages/ctn/Info';
import Loading from './Pages/ctn/Loading';
import './Pages/css/App.css';
import './Pages/css/EbayImg.css';
import './Pages/css/shopifyAplus.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import firebase from './Firebase.js'
import DB from './db.js'
import $ from 'jquery'; 
import io from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import Bowser from "bowser";
import IdleTimer from 'react-idle-timer'
import {ajaxPerso, loadAccount, fetchStores} from "./fnc.js"
import store from 'store2';
import moment from 'moment';
import ImgCanvaser from './Pages/ctn/ImgCanvaser';
import FileConvertor from './Pages/ctn/FileConvertor';
const refConst = require("./constants.js").v
const browser = Bowser.getParser(window.navigator.userAgent);
/*
 *  We will need to find the group of 30 min for this current day, if it was already filled (a req was sent) we just pass it.
	If it wasn't filled, we need to add it to the list and send a req to save our current list of 30 min groups.
	 - Can we generate a daily score base on the 30 days activity group and just save that numer in DB ?

	Since there is 48 group of 30 min per day, 48 would be 100%
	1% would represent 2.0833, we will need to use this metric to assigne the value in our DB.
 */
const minuteIdle = 5;
var activeLogs = {
	prcActive: {}, // will have {[type]: 0.0}
	dayDate: moment().format("YYYY-MM-DD"),
	list: new Array(48) // 48 is the number of group of 30 min per day.
};
const activityValuePerPrc = 100 / activeLogs.list.length;
var prevPageName = null;

////// const socket = io('http://localhost:3100/', {
// const socket = io('https://719debc98a14.ngrok.app/', {
const socket = io(refConst.urlServer.indexOf('localhost') === -1? 'https://socket.ehub.work' : 'http://localhost:3100/', {
// FOR EMERGENCY ONLY:::( NEED TO BE STARTED ON UBUNTU WEBSERVER ):::  const socket = io('http://10.10.0.152:3100', {
	//path: '/dante/socket.io',
	reconnection: true,
	//transports: ['websocket']
});

window.socket = socket;

const initialState = {
	modal: {
		html: () => "",
		key: null,
		isOpen: false,
		ok: false,
		return: false,
		exit: false,
		options: {
			width: "modal-lg"
		}
	},
	info: {
		nbrSuccess: 0,
		nbrError: 0,
		success: false,
		error: false,
		warning: false
	},
	user: null,
	menuHidden: false,
	loadingLaunched: false,
	recaptchaPassed: refConst.dev? true : null,
	taskList: [],
	winnerList: [],
	accounts: [],

	msg: {
		success: false,
		successColor: "#A2E8BB",
		error: false,
		errorColor: "#FF917D"
	},
}

class App extends Component {


	constructor(props) {
		super(props);
		
		// Start our own implementation of Firebase with the ReactApp firebase Module
		DB.init(firebase);

		this.state = {
			info: Object.assign({}, initialState.info),
			user: initialState.user,
			modal: Object.assign({}, initialState.modal),
			loadingLaunched: initialState.loadingLaunched,
			recaptchaPassed: initialState.recaptchaPassed,
			taskList: initialState.taskList.slice(0),
			winnerList: initialState.winnerList.slice(0),

			msg: Object.assign({}, initialState.msg)
		};

		this.idleTimer = null
		this.handleOnAction = this.handleOnAction.bind(this)
		this.handleOnActive = this.handleOnActive.bind(this)
		this.handleOnIdle = this.handleOnIdle.bind(this)
		this.handleInIdle = this.handleInIdle.bind(this)

		this.levels = {
			"admin": {
				index: 21
			},
			"user": {
				index: 14
			},
			"guest": {
				index: 10
			},
			"ebay": {
				index: 11
			}
		}

		// Bind Allow access by "this" into the function
		this.info = this.info.bind(this);
		this.logOut = this.logOut.bind(this);
		this.loginData = this.loginData.bind(this);
		this.getUser = this.getUser.bind(this);
		this.getLevel = this.getLevel.bind(this);
		this.loading = this.loading.bind(this);
		this.infoPresent = this.infoPresent.bind(this);
		this.getLevel = this.getLevel.bind(this);
		this.getLevels = this.getLevels.bind(this);
		this.modal = this.modal.bind(this);
		this.fetchAccountDb = this.fetchAccountDb.bind(this);
		this.init = this.init.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.triggerActivityLog = this.triggerActivityLog.bind(this);
		this.fetchActivity = this.fetchActivity.bind(this);
		this.getTypeActivePage = this.getTypeActivePage.bind(this)
		this.securityClearance = this.securityClearance.bind(this)
		this.getStores = this.getStores.bind(this)
		this.msgAlert = this.msgAlert.bind(this)
	}

	componentDidMount(){
		let self = this,
			limitCounter = 100,
			counter = 0

		var intApp = setInterval(() => {
			counter++;
			if(counter > limitCounter){
				clearInterval(intApp);
				console.warn('Error, could not load the app');
			} else if(window.grecaptcha){
				clearInterval(intApp);

				if(refConst.byPassRecaptcha === false){
					// We just check with google if the connection is legit.
					ajaxPerso({
						"api": "authorized",
						"recapForceName": "loadingControl",
					}, function( r ) {
						console.info('Recaptcha result:', r);

						if(r.error || !("authorized" in r) || !r.authorized){
							self.setState({"recaptchaPassed": r.authorized? true : false}, self.init)
						}else{

							if(refConst.dev){
								self.setState({"recaptchaPassed": true}, self.init)
							}else{
								// Check the location

								/* $.getJSON("https://ipgeolocation.abstractapi.com/v1/?api_key=fb8e5daff9b948b5bccd603efbb69268", function(r) {
									console.log("ipgeolocation", r);
									if(r.ip_address === refConst.IP_EIO || ["US", "CA", "IN", "CH", "PH"].indexOf(r.country_code) !== -1){
										self.setState({"recaptchaPassed": true}, self.init)
									}
								}) */
								if(refConst.noIpSec === false){
									ajaxPerso({
										"api": "eio",
										"trigger": "location",
										"special": "b44772738e3285131d262156c877cfc27b2b795ca6b8337e2629b9b42e3ce545",
									}, function( r ) {
										console.info('location:', r);
										if(r.ip_address === refConst.IP_EIO || ["US", "CA", "IN", "CH", "JP", "-"].indexOf(r.country_code) !== -1){
											self.setState({"recaptchaPassed": true}, self.init)
										}
									})
								}else{
									self.setState({"recaptchaPassed": true}, self.init)
								}
							}
						}
					})
				}else{
					// In Dev
					console.info("Dev mode authorized");
					self.setState({"recaptchaPassed": true}, self.init)
				}
		
				// Pull the previously selected option for our menu.
				let menuHidden = store("menuHidden")
				if(menuHidden !== this.state.menuHidden)
					this.setState({"menuHidden": menuHidden})
		
				prevPageName = this.props.location.pathname
			}
		}, 1000);

		window.closeModal = () => {
			$('.modalDtFeature').modal('hide')
		}
	}

	componentDidUpdate (){
		let self = this;

		if(!this.state.user) return;

		/* if(this.state.msg.error || this.state.msg.success)
			$('.toast').toast({
				"autohide": false
			}).toast("show")
		else
			$('.toast').toast("dispose") */

		// We make sure to not close the main modal in case we tried to close a submodal from the modal menu.
		$('.modalDtFeature').off('hidden.bs.modal').on('hidden.bs.modal', function (e) {
			if($(e.target).is(".modalDtFeature"))
				self.setState({modal: Object.assign({}, initialState.modal)}, () => {
					console.info('Resetted');
				})
			else{
				e.preventDefault();
			}
		})
		
		if(this.props.location.pathname != prevPageName){
			this.triggerWhenPageChange(prevPageName, this.props.location.pathname)
			prevPageName = this.props.location.pathname
		}

		let limitCounter = 500,
			counter = 0

		var intApp = setInterval(() => {
			counter++;
			if(counter > limitCounter){
				clearInterval(intApp);
				console.warn('Error, could not load the app');
			} else if($('[data-tooltiptoolbox="tooltipQuickAccess"]').length > 0){
				clearInterval(intApp);
				$('[data-tooltiptoolbox="tooltipQuickAccess"]').tooltip({
					placement: "left",
				}).tooltip('update')
			}
		}, 50);

	}

	msgAlert(error, success){
		let msgCp = Object.assign({}, this.state.msg)

		msgCp.error = false
		msgCp.success = false

		if(error)	msgCp.error = error
		if(success)	msgCp.success = success

		console.info('msgCp', msgCp);

		this.setState({msg: msgCp})
	}

	triggerWhenPageChange (lastName, newName){
		console.info('Page change', `${lastName} -> ${newName}`);
		this.triggerActivityLog()
	}

	init(){

		if(!this.state.recaptchaPassed)	return false;

		let self = this;

		firebase.auth().onAuthStateChanged(function(user) {
			self.state.compNotReady = [];

			if(user){
				socket.emit("askForTaskList", {
					"url": refConst.urlServer,
					"uid": user.uid
				})
				socket.emit("askForWinningList", {
					"url": refConst.urlServer,
					"isLoading": true
				})
				
				console.info('Connected', user.uid);
				
				socket.emit('addUser', {uid: user.uid});

				loadAccount(null, (r) => {
					if(r.success)
						self.setState({"accounts": r.res})
				})
				
				self.getStores();

				DB.load("users/"+user.uid, {
				}, (data, nbr) => {
					let userData = data.val()
					if(!userData)	return;
					// Add the DB ID to the user object
					self.fetchAccountDb( user.uid, (rAccDb) => {
						
						let userDbAcc = rAccDb.res.find(u => {
							if(u.id_firebase === user.uid){
								return u
							}
						})
						
						console.info("userDbAcc: ", userDbAcc);

						//console.info(data);
						userData.uid = user.uid;
						userData.id = rAccDb.success? userDbAcc.id : null;

						// Load the activy for this user for today, then initiate the activity process.
						self.setState({"user": userData}, () => {
							self.fetchActivity(self.triggerActivityLog)
						})
					});
				});
			}
		});


		socket.on('userCount', function(msg){
			console.info("userCount logged: ", msg);
		});
		
		socket.on('taskList', function(body){
			/**
			 * Receie a list of all task generated from ourselve for today, since midnight to 23:59
			 */
			console.info('taskList', body);
			
			if(body.success){
				self.setState({ "taskList": body.res || [] })
			}
		});

		socket.on('winnerList', function(body){
			/**
			 * Receie a list of all task generated from ourselve for today, since midnight to 23:59
			 */
			console.info(`winnerList [${body.socketId}]:`, body);
			
			if(body.success){
				self.setState({"winnerList": body.data})
			}
		});

		socket.on('taskDone', function(res, callback){

			if(callback)
				callback(res) // res is a passthrough info.

			if(typeof res == "object" && "type" in res){
				if(res.type == "shopify_product"){

					if(res.data && typeof res.data == "object" && "success" in res.data && !res.data.success) self.info({error: "Task failled, please check your task list"});
					else if("error" in res && res.error) self.info({error: JSON.stringify(res.error)});
					else if("errors" in res && res.errors) self.info({error: JSON.stringify(res.errors)});

					if(res.data && res.data.updated && res.data.updated.length === 1){
						if([200, 201].indexOf(res.data.updated[0].code) !== -1)
							self.info({success: `${res.data.updated[0].idReceived} Inserted`})
						else
							self.info({error: `${res.data.updated[0].idReceived} has an error.`})
					}
				}
			}
		});

		socket.on('taskError', function(res){
			console.warn('taskError', res);
			if(typeof res === 'object')	res = JSON.stringify(res);
			self.info({error: res})
		});

		// Send ping events every X milliseconds
		const pingInterval = setInterval(() => {
			socket.emit('ping');
		}, 15000); // Adjust the interval as needed (e.g., every 20 seconds)

		socket.on('pong', () => {
			console.log('Received pong from server');
		});
	}

	logOut(){
		let self = this
		firebase.auth().signOut().then(function() {
			// Sign-out successful.
			self.setState({ "user": null })
		}).catch(function(error) {
			// An error happened.
			console.warn("Error, Log out impossible.", error);
		});
	}

	getTypeActivePage(){
		let type = null,
			mappingList = {
				"shopify": 'listing',
				"ebayListing": 'listing'
			}

		for (const pageName in mappingList) {
			if (Object.hasOwnProperty.call(mappingList, pageName)) {
				const mappingType = mappingList[pageName];
				if(this.props.location.pathname.indexOf(pageName) !== -1){
					type = mappingType;
				}
			}
		}
		return type;
	}

	triggerActivityLog(){

		if(!this.getUser().id)	return false;

		let type = this.getTypeActivePage()
		if(!type)	return false;

		console.info('activeLogs -> triggerActivityLog', activeLogs);
		
		let newDate = new Date(),
			grp30Min = newDate.getHours() * 2 + (newDate.getMinutes() > 30? 1 : 0),
			todayDate = moment().format("YYYY-MM-DD")

		// If a day has passed since the last activity, we reset the counter.
		if(todayDate != activeLogs.dayDate){
			activeLogs = {
				prcActive: {[type]: 0.0},
				dayDate: moment().format("YYYY-MM-DD"),
				list: new Array(48) // 48 is the number of group of 30 min per day.
			};
		}

		if(!activeLogs.list[grp30Min] || !activeLogs.list[grp30Min][type]){
			/**
			 * 	We need to add true to this section
			 */
			if(!activeLogs.list[grp30Min])
				activeLogs.list[grp30Min] = {[type]: true}

			if(!activeLogs.prcActive[type])
				activeLogs.prcActive[type] = 0.0

			activeLogs.list[grp30Min][type] = true;
			activeLogs.prcActive[type] += Math.round(activityValuePerPrc);

			/* console.info('TESTTSTS1', activityValuePerPrc);
			console.info('TESTTSTS', activeLogs.prcActive);*/

			ajaxPerso({
				"api": "eio",
				"trigger": "add_activity_log",
				"data": JSON.stringify({
					"id_account": this.getUser().id,
					"prc_time_active": activeLogs.prcActive[type],
					"type":  type
				})
			}, function( r ) {
				console.info('Triggered: add_activity_log', r);
				if(r.error)
					console.warn(r.error);
			})
		}else{
			console.info(`Not ready for active log yet.`)
		}
	}

	fetchActivity(callback){
		let self = this
		if(!this.getUser() || !this.getUser().id){
			console.warn("No user ID");
			return false
		}

		ajaxPerso({
			"api": "eio",
			"trigger": "get_active_scores",
			"id_account": this.getUser().id
		}, function( r ) {
			console.info('Triggered: get_active_scores', r);

			// We get only a result if there was an score today. If no result, the result will be added by trigger_active...
			
			if(r.data.length > 0){
				let type = self.getTypeActivePage()
				if(!type)	return false;
				
				let newList = new Array(48) // 48 is the number of group of 30 min per day.
			
				let m_lastInsert =  moment(r.data[0].last_update),
					positionGrpLastInsert = m_lastInsert.hour() * 2 + (m_lastInsert.minute() > 30? 1 : 0);
				
				newList[positionGrpLastInsert] = {[type]: true}
	
				activeLogs = {
					// Math.round(parseFloat(r.data[0].prc_time_active) / activityValuePerPrc)
					prcActive: {[type]: Math.round(parseFloat(r.data[0].prc_time_active))},
					dayDate: (moment(r.data[0].date)).format("YYYY-MM-DD"),
					list: newList
				};
	
				console.info('activeLogs -> fetchActivity', activeLogs);
			}
			if(callback)	callback()
		})
	}

	fetchAccountDb(uid, callback){
		let self = this

		if(!uid){
			console.warn("Please indicate a uid for fetchAccountDb.");
			return false
		}

		loadAccount({
			"id_firebase": uid
		}, callback)
	}

	securityClearance(propReceived, access){
		let passed = false,
			group = []

		// For some of our modal or little feature here and there.
		if(access && access.length > 0){
			group = access;
		}else if("group" in propReceived)
			group = propReceived.group.slice(0);


		if(group.length === 0){
			passed = true;
		}else if(propReceived.getUser() && propReceived.getUser().permissions){

			propReceived.getUser().permissions.forEach(pUid => {
				if(group.indexOf(pUid) !== -1){
					passed = true;
					return;
				}
			});
		}
		return passed;
	}

	loginData(loginD){
		let self = this,
			userData = null

		self.loading(true)

		firebase.auth().signInWithEmailAndPassword(loginD.email, loginD.password).catch(function(error) {
			//var errorCode = error.code;
			var errorMessage = error.message;
			console.warn(errorMessage);
			alert("Error");

		}).then(function(d){

			if(d){
				
				let user = d.user

				//Add user into db users
				let ref = firebase.database().ref("users")
				ref.child(user.uid).once("value").then(function(data) {
					userData = data.val();
					
					console.info("User Object: ",  userData);
					console.info("User Object User: ",  user);
				
					if(!userData){
						userData = {
							level: "user",
							uid: user.uid,
							email: user.email
						}
					}
					
					self.fetchAccountDb( user.uid, (rAccDb) => {

						userData = {
							"id": rAccDb.success? rAccDb.res[0].id : null,
							"uid": userData.uid,
							"email": userData.email || user.email,
							"permissions": userData.permissions || [],
							"creationTime": user.metadata? user.metadata.creationTime : null,
							"lastSignInTime": user.metadata? user.metadata.lastSignInTime : null,
							"photoURL": user.photoURL || null,
							"level": userData.level
						};
	
						ref.child(user.uid).set(userData).then(() => {
							console.info('Success, User saved into firebase.')
						}).catch(e => {
							console.warn("Error when trying to save this user in firebase.", e)
						});
	
						self.loading(false)
						self.setState({user: userData}, () => console.info('Connected.'));
					});
				});
			}else{
				self.setState({user : null, info: {error: "Wrong informations."}}, () => self.loading(false));
			}
		});
	}

	info(data){

		let currentInfo = Object.assign({}, this.state.info);
		
		if(typeof data === "undefined"){
			return currentInfo
		}else{
			let objInfo = {}
			objInfo = {
				info: {
					nbrSuccess: data.nbrSuccess || 0,
					nbrError: data.nbrError || 0,
					success: !data.success || Array.isArray(data.success) && data.success.length === 0? false : data.success,
					error: !data.error || Array.isArray(data.error) && data.error.length === 0? false : data.error,
					second: !data.second? 7 : data.second,
				}
			}
			this.setState(objInfo)
		}
	}

	infoPresent(){
		if(this.info().success || this.info().error)
			return true
		return false
	}

	loading(data){
		if(typeof data === "undefined"){
			return this.state.loadingLaunched
		}else if(data === true || data === false)
			this.setState({loadingLaunched: data})
	}

	getUser(user){
		if(user === false){
			this.logOut()
		}else
			return this.state.user
	}

	getLevel(){
		let user = this.state.user

		if(!user)
			return false
		
		let level = !("level" in user)? 'user' : user.level
		return level
	}

	getLevels(){
		return this.levels
	}

	modal(objModal){

		// return $('.modalDtFeature').css("display") !== "none";

		let isOpen = $('.modalDtFeature').css("display") !== "none"

		if(objModal.key === undefined)	objModal.key = null
		if(!("options" in objModal))	objModal.options = initialState.modal.options

		console.info(`${this.state.modal.isOpen} && ${this.state.modal.key} === ${objModal.key}`);
		
		if( // Update the modal content.
			this.state.modal.isOpen && this.state.modal.key === objModal.key
		){

			objModal.key = "key" in objModal && objModal.key? objModal.key : null
			objModal.isOpen = true

			this.setState({ modal: objModal}, () => {
				$('.modalDtFeature').modal({
					show: !isOpen? true : false,
					focus: true
				}).modal('handleUpdate')
			})	
		}else if(!this.state.modal.isOpen && objModal.show){  // Create the modal and show it.

			objModal.key = "key" in objModal && objModal.key? objModal.key : null
			objModal.isOpen = objModal.show? true : false

			this.setState({ modal: objModal }, () => {
				$('.modalDtFeature').modal({
					show: true,
					focus: true
				}).modal('handleUpdate')
			})	
		}else{
			// this.setState({ modal: Object.assign({}, initialState.modal) })
		}

		/*
		if(!("options" in objModal))	objModal.options = initialState.modal.options
		this.setState({ modal: objModal }, () => {
			$('.modalDtFeature').modal({
				show: objModal.show,
				focus: true
			}).modal('handleUpdate')

			// $('.modalDtFeature').on('hide.bs.modal', function (e) {
			// 	console.info('Modal close', e);
			// })
		}) */
		return true;
	}

	handleOnAction (event) {
		//console.log("event", event.type);
		// console.log("TEST", this.idleTimer);

		if(event.type == "mousemove"){
			// console.log('user did something', event)
		}
	}
	
	handleOnActive (event) {
		console.log('user is active', event)
		console.log('time remaining', this.idleTimer.getRemainingTime())
		this.triggerActivityLog()
	}

	handleOnIdle (event) {
		console.log('user is idle', event)
		console.log('last active', this.idleTimer.getLastActiveTime())
	}

	handleInIdle (event) {
		console.log('user is idle2', event)
	}

	toggleMenu () {
		store("menuHidden", !this.state.menuHidden)
		this.setState({"menuHidden": !this.state.menuHidden})
	}

	getStores () {
		fetchStores(r => {
			console.info('Fetching stores', r);
			if(r.success){
				r.data.push({
					"id": 5,
					"name": "Finale",
					"id_marketplace": null
				})
				refConst.stores = r.data
			}
		})
	}

	render() {
		//console.warn("needReload", this.props.needReload);
		console.info('this.state.recaptchaPassed', this.state.recaptchaPassed);
		
		let menu = (new MainMenu(
			{
				"securityClearance": this.securityClearance,
				"accounts": this.state.accounts,
				"socket": socket,
				"taskList": this.state.taskList,
				"info": this.info,
				"loading": this.loading,
				"getLevel": this.getLevel,
				"getLevels": this.getLevels,
				"getUser": this.getUser,
				"modal": this.modal,
				"msgAlert": this.msgAlert,
				"triggerActivityLog" : this.triggerActivityLog
			})).getLink();

		if(!this.state.recaptchaPassed){
			return <p>security clearance: {this.state.recaptchaPassed === null? 'In progress' : 'Denied'}</p>
		}else{
			if(this.state.user){

				let classNameBody = this.state.modal.options && "body" in this.state.modal.options && "className" in this.state.modal.options.body? this.state.modal.options.body.className : ''

				return (
					<div className="body d-flex flex-column align-items-stretch w-100 h-100" style={{/* minWidth: "455px" */}}>
						<IdleTimer
							ref={ref => { this.idleTimer = ref }}
							// timeout={1000 * 60 * 1}
							timeout={1000 * 60 * minuteIdle}
							onActive={this.handleOnActive}
							onIdle={this.handleOnIdle}
							onAction={this.handleOnAction}
							isIdle={this.handleInIdle}
							debounce={250}
							events={["mousemove", "touchmove", "touchstart", "keydown"]}
						/>
						{/* <DevMenu socket={socket} getUser={this.getUser}/> */}
						<HeadPage 
							{...this.props}
							socket={socket}
							taskList={this.state.taskList}
							info={this.info}
							getLevel={this.getLevel}
							getLevels={this.getLevels}
							getUser={this.getUser}
							toggleMenu={this.toggleMenu}
							loading={this.loading}
							modal={this.modal}
							msgAlert={this.msgAlert}
						/>
						<div id="bodyUnderHeader" className="overflow-auto d-flex flex-row">
							<div className={(this.state.menuHidden? "d-none" : "") + " menuWidth overflow-auto"} id="navbarNavAltMarkup">
								<div className="mb-2 mt-2 text-center border border-dark bg-primary text-white">
									{ this.getUser().email }
									<span className="float-right bg-secondary pointer" onClick={this.logOut}>
										<FontAwesomeIcon icon={faSignOutAlt} style={{margin: "0px 10px"}} title={"Log Out"}/>
									</span>
								</div>
								{menu.menuLeft}
							</div>
							<div className={(this.state.menuHidden? "left0" : "") + " btnLeftMenuHiding"} onClick={this.toggleMenu}>.<br/>.<br/>.<br/></div>
							<div className="w-100 p-4 text-truncate position-relative" id="contentBody" style={{overflowX: "hidden", overflowY: "auto"}}>
								<Winning
									winnerList={this.state.winnerList}
									accounts={this.state.accounts}
									socket={socket}
								/>
								{ 
									["Microsoft Edge", "Chrome"].indexOf(browser.getBrowser().name) !== -1? '' : <div className={"bg-warning p-3 font-weight-bold text-center mb-4"}>
										This Web app is specifically conceived for Chrome base browser, please use the <u>new Edge</u>/Google Chrome/Brave.
									</div>
								}
								{menu.ctn}
							</div>
						</div>
						
						<div className={(this.loading()? "d-block " : "d-none ") + "container position-fixed fixed-bottom overAll"}>
							<Loading loading={this.loading}/>
						</div>
						<div className={(this.infoPresent()? "d-block " : "d-none ") + "container position-fixed fixed-bottom overAll"}>
							<Info info={this.info} infoPresent={this.infoPresent} loading={this.loading}/>
						</div>
						<div className="modalDtFeature modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
							<div className={(this.state.modal.options? this.state.modal.options.width : '') + " modal-dialog modal-dialog-centered"} role="document">
								<div className="modal-content shadow">
									<div className="modal-header">
										<h5 className="modal-title">{ this.state.modal.title }</h5>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={ () => {
											
											if(this.state.modal.exit)
												this.state.modal.exit($('.modalDtFeature'), () => {
													//this.setState({modal: Object.assign({}, initialState.modal)})
													$('.modalDtFeature').modal('hide')
												});
											else{
												//this.setState({modal: Object.assign({}, initialState.modal)})
												$('.modalDtFeature').modal('hide')
											}
										}}>
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									{/* <div className="modal-body" dangerouslySetInnerHTML={{ __html: this.state.modal.html }} /> */}
									<div className={"modal-body " + (classNameBody)}>{ this.state.modal.html? this.state.modal.html(() => {
										$('.modalDtFeature').modal('hide')
									}) : '' }</div>
									<div className="modal-footer">
										{ 
											this.state.modal.return? <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={ () => {
												return this.state.modal.return.fnc? 
													/* this.state.modal.return.fnc($('.modalDtFeature'), () => {
														this.setState({modal: Object.assign({}, initialState.modal)})
														$('.modalDtFeature').modal('hide')
													}) : this.setState({modal: Object.assign({}, initialState.modal)}, () => $('.modalDtFeature').modal('hide')) */
													this.state.modal.return.fnc($('.modalDtFeature'), () => {
														//this.setState({modal: Object.assign({}, initialState.modal)})
														$('.modalDtFeature').modal('hide')
													}) :  $('.modalDtFeature').modal('hide')
											}}>
												{ this.state.modal.return.title? this.state.modal.return.title : "validating" }
											</button> : ""
										}
										{ this.state.modal.ok? <button type="button" className="btn btn-primary" onClick={ () => {
											if(this.state.modal.ok.fnc)		this.state.modal.ok.fnc($('.modalDtFeature'), () => {
												//this.setState({modal: Object.assign({}, initialState.modal)})
												$('.modalDtFeature').modal('hide')
											});
											else{
												//this.setState({modal: Object.assign({}, initialState.modal)})
												$('.modalDtFeature').modal('hide')
											}
											//$('.modalDtFeature').modal('hide')
										}}>
											{ this.state.modal.ok.title? this.state.modal.ok.title : "validating" }
										</button> : "" }
									</div>
								</div>
							</div>
						</div>
						<div aria-live="polite" aria-atomic="true" className={(!this.state.msg.success && !this.state.msg.error? "d-none" : "") + " position-fixed"} style={{left: "auto", right: "20px", top: "20px", zIndex: "8456"}}>
							<div className="toast border-dark shadow-lg mw-100 show" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false" style={{backgroundColor: this.state.msg.success? this.state.msg.successColor : this.state.msg.errorColor}}>
								<div className="toast-header">
									{/* <strong className="mr-auto">title</strong> */}
									<button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" onClick={(e) => {
										e.preventDefault()
										console.info("SSSSSSSSS", initialState.msg);
										this.setState({msg: Object.assign({}, initialState.msg)})
									}}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								{
									typeof this.state.msg.success === "string" || typeof this.state.msg.error === "string"?
										<div className="toast-body p-3 pl-5 pr-5 font-weight-bold text-wrap" dangerouslySetInnerHTML={{__html:  this.state.msg.success? this.state.msg.success : (this.state.msg.error? this.state.msg.error : "") }}/>
										:
										<div className="toast-body p-3 pl-5 pr-5 font-weight-bold text-wrap">
											{ this.state.msg.success? this.state.msg.success : (this.state.msg.error? this.state.msg.error : "") }
										</div>

								}
							</div>
						</div>
						<div className="toolsQuickAccess">
							<div className="mainCircle d-flex justify-content-center align-items-center">
								<i className="bi bi-tools"></i>
							</div>
							<div className="subMenuQuickAccess">
								<div className="toolQuickAccess d-flex justify-content-center align-items-center pointer" data-tooltiptoolbox="tooltipQuickAccess" data-placement="left" title="Change the framing of an image">
									<ImgCanvaser
										{...this}
										className=""
										title={<span>
											<i className="bi bi-card-image"></i>
										</span>}
										{...{
											"accounts": this.state.accounts,
											"socket": socket,
											"taskList": this.state.taskList,
											"info": this.info,
											"loading": this.loading,
											"getUser": this.getUser,
											"modal": this.modal
										}}
									/>
								</div>
								<div className="toolQuickAccess d-flex justify-content-center align-items-center pointer" data-tooltiptoolbox="tooltipQuickAccess" data-placement="left" title="Convert XLSX to JSON or inversly">
									<FileConvertor
										{...this}
										className=""
										title={<span>
											<i className="bi bi-filetype-json"></i>
										</span>}
										{...{
											"accounts": this.state.accounts,
											"socket": socket,
											"taskList": this.state.taskList,
											"info": this.info,
											"loading": this.loading,
											"getUser": this.getUser,
											"modal": this.modal
										}}
									/>
								</div>
							</div>
						</div>

						{this.props.needReload? <div className="fixed-bottom bg-light border border-success rounded p-3 m-5 shadow font-weight-bold" style={{left: "auto"}}>
							Update available
							<button type="button" className="btn btn-success ml-3" onClick={() => {
								this.props.cacheRefresh(() => {
									//this.props.history.location.reload();
									window.location.reload(true);
								})
							}}>Refresh</button>
						</div> : ""}
					</div>
				);
			}else
				return (
					<div>
						<Login user={this.user} info={this.info} loginData={this.loginData} loading={this.loading}/>
						<div className={(this.loading()? "d-block " : "d-none ") + "container position-fixed fixed-bottom overAll"}>
							<Loading loading={this.loading}/>
						</div>
					</div>
				);
		}
	}
}


/*

<div className="container-fluid">
						<div className="row">
								<div id="reactRender">
										<div>
												<div id="mainMenu">
													<MainMenu/>
												</div>
												<div id="ctnPage">
													<div>
														{(new MainMenu()).getLink().ctn}
													</div>
												</div>
										</div>
								</div>
						</div>
				</div>*/
export default App;
