// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import wiziwig from '../../wiziwig'
import logoStoreEbay from '../../../../img/ebay_circle_perso.png';
import CreatableSelect from 'react-select/creatable';
import Mustache from 'mustache';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCut } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import Loading from '../../Loading';
import {ajaxPerso, socketPerso, historyInsert, fetchIsAutoCreated} from "../../../../fnc.js"

const refConst = require("../../../../constants.js").v

const initialState = {
	title: "",
	sku: "",
	// upc: "",
	mpn: "",
	lbs: 0,
	oz: "",
	qty: 0,
	extendedBody: "",
	shortDesc: "",
	desc: "",
	price: 0.0,
	freeShipping: 1,
	htmlDT: "",
	autoPrefix: true,
	idCatSelected: null,
	diffSiteSelected: false,
	originallyWasMotor: null,
	motorSectionSelected: null,
}

let testInt = false;

function EbayForm(props) {
	
	const [loading, setLoading] = useState(false);
	const [errorGeneratedFields, setErrorGeneratedFields] = useState([]);
	const [valGeneratedFields, setValGeneratedFields] = useState([]);
	const [addedSpecificsValue, setAddedSpecificsValue] = useState({});
	const [motorSectionSelected, setMotorSectionSelected] = useState(initialState.motorSectionSelected);
	const [originallyWasMotor, setOriginallyWasMotor] = useState(initialState.originallyWasMotor);
	
	const [autoQty, setAutoQty] = useState(true);
	const [autoPrefix, setAutoPrefix] = useState(initialState.autoPrefix);
	const [suggestions, setSuggestions] = useState([]);
	const [specifics, setSpecifics] = useState([]);
	const [features, setFeatures] = useState({});
	
	const [diffSiteSelected, setDiffSiteSelected] = useState(initialState.diffSiteSelected);
	const [idCatSelected, setIdCatSelected] = useState(initialState.idCatSelected);

	const [title, setTitle] = useState(initialState.title);
	const [sku, setSku] = useState(initialState.sku);
	// const [upc, setUpc] = useState(initialState.upc);
	const [searchSuggestion, setSearchSuggestion] = useState(null);
	const [mpn, setMpn] = useState(initialState.mpn);
	const [lbs, setLbs] = useState(initialState.lbs);
	const [oz, setOz] = useState(initialState.oz);
	const [qty, setQty] = useState(initialState.qty);
	// const [shortDesc, setShortDesc] = useState(initialState.shortDesc);
	const [desc, setDesc] = useState(initialState.desc);
	const [price, setPrice] = useState(initialState.price);
	var currentCat = useRef();

	var specificsToRemove = ['Brand', 'Condition'];

	refConst.styleList.container = (provided) => ({
		...provided,
		width: "100%",
		minWidth: "250px"
	})

	useEffect(() => {
		console.log("updateMode", props.updateMode);
		console.log("props EbayView", props);
		let cpDataSet = JSON.parse(JSON.stringify(props.originlDataSet));
		let item = props.updateMode
		let cpCalculated = {}

		props.setProposalHidden(true)

		if(item){
			// props.setPublished(item.published_at? 1 : 0)
			// setInventoryPolicy(item.variants[0].inventory_policy)
			setTitle(item.Title)
			setMotorSectionSelected(props.updateMode.Site === "eBayMotors")
			setOriginallyWasMotor(props.updateMode.Site === "eBayMotors")
			// setUpc(item.variants[0].barcode || "")
			props.setMsgInfo(props.initialState)
			
			setLbs(parseFloat(item.ShippingPackageDetails.WeightMajor.value) || 0)
			setOz(parseFloat(item.ShippingPackageDetails.WeightMinor.value) || 0)
			setQty(parseInt(item.Quantity) || 0)
			setPrice(parseFloat(item.SellingStatus.CurrentPrice.value) || 0.0)
			setIdCatSelected(item.PrimaryCategory.CategoryID)

			let descHtml = null;
			try {
				if(item.Description.indexOf("eio-electronicinventoryonline") !== -1){
					// For Old template
					let htmlQ = $(item.Description.replace('&nbsp;', ''));
					htmlQ.splice(0, 1)
					console.info('item.Description', htmlQ)
					descHtml = $('<div/>').html(htmlQ);
				}else{
					// For enw template
					descHtml = $(item.Description).find(".prdDescri").length > 0? $(item.Description).find(".prdDescri").children().html() : $(item.Description).html();
				}
			} catch (error) {
				// If there is no html structure recognisable, we just keep the description.
				descHtml = item.Description;
			}

			console.info('descHtmldescHtmldescHtml', descHtml);
			$('#inputDesc').summernote("code", descHtml);

			if(item.PictureDetails.PictureURL.length > 0){

				let cpImg = props.images.slice(0),
					cpImgSelected = props.imgSelected.slice()

				item.PictureDetails.PictureURL.forEach(urlImg => {
					let rdId = parseFloat(Math.random() * 100000).toFixed(0)
					let objImg = {
						id: rdId,
						name: 'current_' + rdId,
						url: urlImg
					};
						
					cpImg.unshift(objImg);
					cpImgSelected.push(objImg);
				})
				props.setImages(cpImg);
				props.setImgSelected(cpImgSelected)
			}

			let brandObj = item.ItemSpecifics.NameValueList.find(o => {
					if(o.Name == "Brand")	return o
				}),
				brandDb = null

			

			if(brandObj){
				let ebayBrand = Array.isArray(brandObj.Value)? brandObj.Value[0] : brandObj.Value
				brandDb = props.brands.find(obj => {
					if(ebayBrand.toLowerCase().indexOf(obj.name.toLowerCase()) !== -1)		return obj
				})

				console.info("---brandDb", brandDb);
				console.info("---ebayBrand", ebayBrand);
				console.info("---ebayBrand22", props.brands);
			}
				

			props.setSelectedBrand(brandDb || {})
			console.warn("objBrand", brandDb);
			console.warn("itemFinale", props.itemFinale.id_brand);
			
			/* let skuRecup = brandDb && brandDb.prefix && item.SKU.indexOf('-') !== -1?
				item.SKU.substr(brandDb.prefix.length) : item.SKU */

			let skuRecup = item.SKU
			// console.info(`TESSSSSST: ${item.SKU.substr(0, brandDb.prefix.length)} == ${brandDb.prefix}`);
			if(brandDb && brandDb.prefix && item.SKU.substr(0, brandDb.prefix.length) == brandDb.prefix){
				skuRecup = item.SKU.substr(brandDb.prefix.length)
			}

			console.info("skuRecupskuRecupskuRecup", skuRecup);
			fetchIsAutoCreated({
				"sku": item.SKU,
				"id_marketplace": refConst.id_store_eBay_EIO,
			}, null, (r) => {
				props.setIsAutoCreated(r.found)
			})

			setSku(skuRecup || "")

			/* if(mpn)
				setMpn(mpn.value); */
		
		}else{ // for when we click on Go to empty form
			emptyForm()
		}
		
	}, [props.updateMode])

	useEffect(() => {
		wiziwig("#inputDesc", "Write the short description here")
		props.setVersion("0.1")
	}, [])

	useEffect(() => {
		if(currentCat.current)
			console.info('TESTSIze', currentCat.current.scrollLeft);
	})

	useEffect(() => {
		console.info('imgSelectedimgSelected', props.imgSelected);
		
	}, [props.imgSelected])

	/* useEffect(() => {

		if(suggestions.length > 0){
			
			setIdCatSelected(suggestions[0].Category.CategoryID)
		}

	}, [suggestions]) */

	useEffect(() => {
		
		if(idCatSelected){
			setValGeneratedFields([])
			setAddedSpecificsValue({})
			changeSuggestionSelection(idCatSelected)
		}

	}, [idCatSelected, motorSectionSelected])

	useEffect(() => {
		console.info('specifics state changed', specifics);
		console.info('features state changed', features);
	}, [specifics, features])

	useEffect(() => {

		console.info("Info features", features)
		
		let listFormValue = [];
		// let listFormValue = valGeneratedFields.slice(0);
		if(specifics && specifics.length > 0){

			console.info('props.updateMode', props.updateMode);
			
			specifics.forEach(specific => {
			
				let name = specific.localizedAspectName

				let foundVal = props.updateMode? props.updateMode.ItemSpecifics.NameValueList.find(iin => {
					if(iin.Name == name)	return iin;
				}) : null;
	
				if(specificsToRemove.indexOf(name) === -1){ // 'country/region of manufacture'
					
					let listRecomm = []
					if("aspectValues" in specific)
						listRecomm = specific.aspectValues.map(o => {
							return { // Imitate the old API
								"Value": o.localizedValue
							}
						})
						
					let isRequired = specific.aspectConstraint.aspectRequired? true : false
					let listRecommOptions = []

					if(listRecomm.length > 0 || Object.keys(addedSpecificsValue).length > 0){
						
						if(Array.isArray(listRecomm) && Object.keys(addedSpecificsValue).length > 0 && name in addedSpecificsValue && addedSpecificsValue[name].length > 0){
							console.info('listRecommlistRecomm', listRecomm);
							
							listRecomm = listRecomm.concat(addedSpecificsValue[name])
						}
						
						listRecomm.forEach((nodeRecomm) => {
							listRecommOptions.push({
								value: nodeRecomm.Value,
								label: nodeRecomm.Value
							})
						})
					}
					listFormValue.push({
						"name": name,
						"nameVisible": name,
						"val": foundVal? (Array.isArray(foundVal.Value)? foundVal.Value[0] : foundVal.Value) : '',
						"isRequired": isRequired,
						"listRecommOptions": listRecommOptions
					}) // Default value
				}
			})
		}
		if(features && Object.keys(features).length > 0){

			let objCondFound = listFormValue.find(oi => {
				if(oi.name == "Condition")	return oi;
			})

			let objUPCFound = listFormValue.find(oi => {
				if(oi.name == "UPC")	return oi;
			})
			
			let objUpcFoundEbay = props.updateMode? props.updateMode.ItemSpecifics.NameValueList.find(oi => {
				if(oi.Name == "UPC")	return oi;
			}) : null
	
			/* if(objFound){

			}else{

			} */

			console.info("objFound", objCondFound)
			console.info("objFound2", props.updateMode.ConditionID)
			console.info("objUpcFound", objUpcFoundEbay)

			if(!objCondFound && features.ConditionEnabled && features.ConditionEnabled != "Disabled"){
				listFormValue.push({
					"name": 'Condition',
					"nameVisible": 'Condition',
					"val": props.updateMode && props.updateMode.ConditionID? props.updateMode.ConditionID : '',
					"isRequired": features.ConditionEnabled === 'Required',
					"listRecommOptions": features.ConditionValues.Condition.map(o => {
						return {
							value: o.ID,
							label: o.DisplayName
						}
					})
				})
			}else if(objCondFound){
				objCondFound.val = props.updateMode && props.updateMode.ConditionID? props.updateMode.ConditionID : '';
			}
			if(!objUPCFound && features.UPCEnabled && features.UPCEnabled != "Disabled"){
				listFormValue.push({
					"name": 'UPC',
					"nameVisible": 'UPC',
					"val": objUpcFoundEbay? (Array.isArray(objUpcFoundEbay.Value)? objUpcFoundEbay.Value[0] : objUpcFoundEbay.Value) : '',
					"isRequired": features.UPCEnabled === 'Required',
					"listRecommOptions": null
				})
			}else if(objUPCFound){
				objUPCFound.val = objUpcFoundEbay? objUpcFoundEbay.Value : '';
			}
		}


		console.info('listFormValuelistFormValue', listFormValue);
		
		setValGeneratedFields(listFormValue.length > 0? listFormValue : []);

	}, [specifics, props.updateMode, features])

	/* useEffect(() => {
		
		if(valGeneratedFields.length > 0 && props.updateMode && !("valGeneratedAdded" in props.updateMode)){
			let item = props.updateMode,
				cpValGenerated = valGeneratedFields.slice(0);

			console.info('cpValGenerated2', JSON.stringify(cpValGenerated));
			

			item.valGeneratedAdded = true;

			if(item.ConditionDisplayName){
				item.ItemSpecifics.NameValueList.push({
					Name: "Condition",
					Value: item.ConditionID
				});
			}

			item.ItemSpecifics.NameValueList.forEach(o => {

				if(specificsToRemove.indexOf(o.Name) === -1){
					let foundVgf = cpValGenerated.find(vgf => {
						if(vgf.name == "Type")
							console.info('TPETEST', `${vgf.name} == ${o.Name}`);
						
						if(vgf.name == o.Name)	return vgf;
					})
	
					if(foundVgf){
						foundVgf.val = o.Value;
					}else{
						cpValGenerated.push({
							name: o.Name,
							nameVisible: o.Name,
							val: o.Value
						})
					}
				}
			});

			console.info('cpValGenerated', cpValGenerated);
			
			setValGeneratedFields(cpValGenerated)
		}

	}, [valGeneratedFields, props.updateMode]) */

	/* useEffect(() => {

		let listFormValue = valGeneratedFields.slice(0);
		// let listFormValue = [];

		// if(!features)	return;

		let objFound = valGeneratedFields.find(oi => {
			if(oi.name === "Condition")	return oi;
		})
		
		// let objUpcFound = props.updateMode? props.updateMode.ItemSpecifics.NameValueList.find(oi => {
		// 	if(oi.name === "UPC")	return oi;
		// }) : null

		if(features && !objFound && features.ConditionEnabled && features.ConditionEnabled != "Disabled"){
			listFormValue.push({
				"name": 'Condition',
				"nameVisible": 'Condition',
				// "val": props.updateMode && props.updateMode.conditionID? props.updateMode.conditionID : '',
				"val": '',
				"isRequired": features.ConditionEnabled === 'Required',
				"listRecommOptions": features.ConditionValues.Condition.map(o => {
					return {
						value: o.ID,
						label: o.DisplayName
					}
				})
			})
		}
		if(features && features.UPCEnabled && features.UPCEnabled != "Disabled"){
			listFormValue.push({
				"name": 'UPC',
				"nameVisible": 'UPC',
				// "val": objUpcFound? objUpcFound.Value : '',
				"val": '',
				"isRequired": features.UPCEnabled === 'Required',
				"listRecommOptions": null
			})
		}

		setValGeneratedFields(listFormValue);

	}, [features]) */

	useEffect(() => {
		if(sku && props.selectedBrand){
			props.fetchItemFromFinale(sku, (r) => {
				if(r && r.length > 0){
					props.setItemFinale(r[0])
				}
				console.warn("getItems", r);
			})
		}
	}, [sku, props.selectedBrand])


	useEffect(() => {
		/**
		 * We need to rename the UPC field if the id type has another value than UPC
		 */
		if(valGeneratedFields.length === 0)	return;

		let objFoundType = valGeneratedFields.find((oi,i) => {
			if(oi.name === "idType") return oi;
		})
		let objFoundID = valGeneratedFields.find((oi,i) => {
			if(oi.name === "UPC") return oi;
		});

		if(objFoundType && objFoundID && objFoundType.val && objFoundType.val !== objFoundID.nameVisible){
			objFoundID.nameVisible = objFoundType.val;
			setValGeneratedFields(valGeneratedFields.slice(0))
		}


	}, [valGeneratedFields])

	useEffect(() => {
		$('.tooltipInfo').tooltip({
			placement: "right",
			template:  '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-info-ebay"></div></div>',
		}).tooltip('update')
	})

	/* useEffect(() => {
		if(props.itemFinale && !upc){
			if(props.itemFinale.upc || props.itemFinale.ean){
				setUpc(props.itemFinale.upc || props.itemFinale.ean)
			}
		}
	}, [props.itemFinale]) */

	const emptyForm = () => {
		setTitle(initialState.title)
		setSku(initialState.sku)
		// setUpc(initialState.upc)
		setLbs(initialState.lbs)
		setOz(initialState.oz)
		setQty(initialState.qty)
		setPrice(initialState.price)
		setAutoPrefix(initialState.autoPrefix)

		setSuggestions([])
		setSpecifics([])
		setFeatures({})

		setDiffSiteSelected(initialState.diffSiteSelected)
		setIdCatSelected(initialState.idCatSelected)

		props.setItemFinale(props.initialState.itemFinale)
		props.setSelectedBrand(props.initialState.selectedBrand)

		setDesc(initialState.desc)
		// setMpn(initialState.mpn)

		setAddedSpecificsValue({})
		setMotorSectionSelected(initialState.motorSectionSelected)
		setOriginallyWasMotor(initialState.idCatSelected)
		setValGeneratedFields([])

		props.setBinList(props.initialState.images)
		props.setBinListDropzone(props.initialState.imagesDropzone)

		if($("#inputDesc").val())
			$('#inputDesc').summernote("code", "");

		props.emptyForm();
	}

	/* const addValueToSpecifics = () => {
		if(valGeneratedFields.length > 0 && props.updateMode && !("valGeneratedAdded" in props.updateMode)){
			let item = props.updateMode,
				cpValGenerated = valGeneratedFields.slice(0);

			console.info('cpValGenerated2', JSON.stringify(cpValGenerated));
			

			item.valGeneratedAdded = true;

			if(item.ConditionDisplayName){
				item.ItemSpecifics.NameValueList.push({
					Name: "Condition",
					Value: item.ConditionID
				});
			}

			item.ItemSpecifics.NameValueList.forEach(o => {

				if(specificsToRemove.indexOf(o.Name) === -1){
					let foundVgf = cpValGenerated.find(vgf => {
						if(vgf.name == "Type")
							console.info('TPETEST', `${vgf.name} == ${o.Name}`);
						
						if(vgf.name == o.Name)	return vgf;
					})
	
					if(foundVgf){
						foundVgf.val = o.Value;
					}else{
						cpValGenerated.push({
							name: o.Name,
							nameVisible: o.Name,
							val: o.Value
						})
					}
				}
			});

			console.info('cpValGenerated', cpValGenerated);
			
			setValGeneratedFields(cpValGenerated)
		}
	} */

	const templateDescription = () => {
		let output = $("#inputDesc").val().replace(/™|©/g,'');

		if(props.template && (props.brandObject || props.selectedBrand && "isManAdded" in props.selectedBrand && props.selectedBrand.isManAdded)){
			let objBlockMuchtache = [];
			if(props.brandObject)
				props.brandObject.forEach(obj => {
					objBlockMuchtache[obj.model] = obj.value
				})
			let bodyWithBlockPhase1 = Mustache.render(props.template.body, objBlockMuchtache, null, ['$%$', '$%$']);

			let objValMuchtache = {
				"title": title.replace(/™|©/g,''),
				"brand": props.selectedBrand.name,
				"sku": sku,
				"description": $("#inputDesc").val().replace(/™|©/g,''),
				"urlImg": props.imgSelected.length > 0? props.imgSelected[0].url : "https://firebasestorage.googleapis.com/v0/b/ebayapp-85a68.appspot.com/o/eBay%2Fwrench_small.png?alt=media"
			};
			let bodyWithBlockPhase2 = Mustache.render(bodyWithBlockPhase1, objValMuchtache, null);

			console.info('bodyWithBlockPhase2', bodyWithBlockPhase2);
			output = bodyWithBlockPhase2;
		}

		return output;
	}

	const grpSpecifics = () => {
		let output = { "Brand": props.selectedBrand.name }

		valGeneratedFields.forEach(o => {
			if ((["Condition", "idType", "UPC"].indexOf(o.name) === -1) && o.val) {
				output[o.name] = o.val;
			}
		});

		let objFoundIdType = valGeneratedFields.find(oi => {
			if(oi.name === "idType")	return oi;
		})

		let objFoundUpc = valGeneratedFields.find(oi => {
			if(oi.name === "UPC")	return oi;
		})

		if(objFoundIdType && objFoundUpc && objFoundIdType.val && objFoundUpc.val)	output[objFoundIdType.val] = objFoundUpc.val;

		return output;
	}

	const productListingDetails = () => {

		let objFoundMpn = valGeneratedFields.find(oi => {
			if(["Manufacturer Part Number", "MPN"].indexOf(oi.name) !== -1)	return oi;
		})

		let output = {
			BrandMPN: {
				"Brand": props.selectedBrand.name
			}
		};

		// Both Brand and MPN must be specified if the BrandMPN container is used or an error may occur.
		if(objFoundMpn && objFoundMpn.val && props.selectedBrand.name)
			output.BrandMPN.MPN = objFoundMpn.val;

		let objFoundIdType = valGeneratedFields.find(oi => {
			if(oi.name === "idType")	return oi;
		})

		let objFoundUpc = valGeneratedFields.find(oi => {
			if(oi.name === "UPC")	return oi;
		})

		if(objFoundIdType && objFoundUpc && objFoundIdType.val && objFoundUpc.val)	output[objFoundIdType.val] = objFoundUpc.val;

		return output;
	}

	const qtyCalculator = () => {

		if(!autoQty){
			return qty;
		}else{
			let qtyOut = 2;
			if(price <= 100){
				qtyOut = 10
			}else if(price > 100 && price <= 500){
				qtyOut = 5
			}
			return qtyOut;
		}
	}

	const assembleDataForRequest = () => {

		let addItemData = {};
		let objFound = valGeneratedFields.find(oi => {
			if(oi.name == "Condition")	return oi;
		})

		let skuPassThrough = (autoPrefix? (props.selectedBrand.prefix? props.selectedBrand.prefix : "") : '') + sku;

		if(props.updateMode){
			let item = props.updateMode

			if(!props.isAutoCreated){
				addItemData.ItemSpecifics = grpSpecifics(); // Item.ItemSpecifics.NameValueList
				addItemData.ProductListingDetails = productListingDetails(); // Item.ItemSpecifics.NameValueList
				addItemData.Description = templateDescription(); // Item.Description
				if(objFound && objFound.val)	addItemData[props.updateMode? "ConditionID" : "conditionId"] = parseInt(objFound.val);
			}

			addItemData = JSON.stringify([
				Object.assign(addItemData, {
					"ItemID": item.ItemID,
					"PrimaryCategory": {
						"CategoryID": idCatSelected
					},
					"StartPrice": price,
					"Quantity": qtyCalculator(),
					"SKU": skuPassThrough,
					"ShippingPackageDetails": {
						"Weight": parseFloat(lbs? lbs : oz),
						"Unit": lbs? "lb" : "oz"
					},
					"Title": title.replace(/™|©/g,''), // Item.Title
					"PictureURL": props.imgSelected.map(o => {
						return o.url;
					})
				})
			])
			// console.info("addItemDataaddItemData", addItemData);
			// throw new Error("STOP");
		}else{
			if(objFound && objFound.val)	addItemData[props.updateMode? "ConditionID" : "conditionId"] = parseInt(objFound.val);
			addItemData = Object.assign(addItemData, {
				"categoryId": idCatSelected,
				"price": price,
				"quantity": qtyCalculator(),
				"sku": skuPassThrough,
				"weight": parseFloat(lbs? lbs : oz), // Item.ShippingPackageDetails.WeightMajor
				"weight_unit": lbs? "lb" : "oz", // Item.ShippingPackageDetails.WeightMajor
				"title": title.replace(/™|©/g,''), // Item.Title
				"description": templateDescription(), // Item.Description
				"itemSpecifics": grpSpecifics(), // Item.ItemSpecifics.NameValueList
				"productListingDetails": productListingDetails(), // Item.ItemSpecifics.NameValueList
				"pictureURL": props.imgSelected.map(o => {
					return o.url;
				}).join(',')
			})
		}

		return {
			"obj": addItemData,
			"sku": skuPassThrough
		};
	}

	const sendRequest = (base64ImgsBin, base64FilesBin) => {
		console.log("base64ImgsBin", base64ImgsBin);

		let addItemData = assembleDataForRequest();
		//return false;

		console.info('features', features);
		console.info('addItemData', addItemData);

		//return false;
		// emptyForm();
		// return false;

		/* emptyForm();
		props.parent.history.push("/ebayListing") */

		ajaxPerso({
			"api": "ebay",
			"trigger": props.updateMode? "alterItem" : "createItem",
			"shop": props.shopSelected.storeId,
			"data": addItemData.obj,
			"site": motorSectionSelected? "eBayMotors" : "US",
		}, function(r) {

			console.info('Called "createItem":', r);
			// return false;

			let info = { success: [], error: []}
			let warningList = [];
			let hasFatalError = false;

			if(props.updateMode){

				let itemId = r.resList[0].idItem;

				if(r.resList.length > 0){

					r.resList.forEach((res)=> {
						if(Array.isArray(res.errors) && res.errors.length > 0){
							res.errors.forEach((o,i)=> {
								if(o.status == "Error"){
									hasFatalError = true;
									info.error.push(<div key={o.code} data-errorinfo="true">{o.msg}</div>)
								}else{
									warningList.push(<div key={"linkOutWarn_"+i} data-warninginfo="true">{o.msg}</div>)
								}
							})
						}
					})
				}

				console.info('hasFatalErrorhasFatalError', hasFatalError);
				

				if(!hasFatalError){
					historyInsert(props.shopSelected.storeId, {
						"uid": props.getUser().uid,
						"sku_ebay": addItemData.sku,
						"id_product": itemId,
						"type": props.updateMode? "update" : "insert",
					}, () => {
						props.loadUserHistory();
					})

					emptyForm();
					info.success.push(<div key={"linkOut_" + addItemData.sku}>Item [{addItemData.sku}] {(props.updateMode? "updated" : "added")} with success.</div>);
					info.success.push(<div key={"linkOut_link_" + itemId}>Open on eBay: [<a target="_blank" rel="noopener noreferrer" href={"https://www.ebay.com/itm/" + itemId}>{itemId}</a>]</div>);
					props.parent.history.push("/ebayListing")

					socketPerso("askForWinningList", {
						"url": refConst.urlServer,
					})
				}
			}else{

				if(r.success){
	
					let itemId = r.res.ItemID;
	
					historyInsert(props.shopSelected.storeId, {
						"uid": props.getUser().uid,
						"sku_ebay": addItemData.sku,
						"id_product": itemId,
						"type": props.updateMode? "update" : "insert",
					}, () => {
						props.loadUserHistory();
					})
					
					emptyForm();
					// http://www.ebay.com/itm/333883665371
					info.success.push(<div key={"linkOut_" + addItemData.sku}>Item [{addItemData.sku}] {(props.updateMode? "updated" : "added")} with success.</div>);
					info.success.push(<div key={"linkOut_link_" + itemId}>Open on eBay: [<a target="_blank" rel="noopener noreferrer" href={"https://www.ebay.com/itm/" + itemId}>{itemId}</a>]</div>);
					
				}else{
					if(typeof r.errors === "string")
						r.errors = [r.errors]
					if(r.errors)
						info.error = r.errors.map((error, i) => {
							return <div key={"linkOutErr_"+i} data-errorinfo="true">{error}</div>;
						})
				}

				if(r.warning)
					warningList = r.warning.map((warning, i) => {
						return <div key={"linkOutWarn_"+i} data-warninginfo="true">{warning}</div>;
					})
			}

			console.info('INFO MSG', info);
			
			props.msgAlert(
				info.error.length > 0? <div>{info.error}</div> : null, 
				info.success.length > 0? <div>{info.success}</div> : null, 
			);

			if(warningList.length > 0)	props.info({error: warningList})
			props.loading(false);

		}, function(jqXHR, textStatus) {
			props.loading(false);

			console.warn( textStatus );
			props.info({ error: textStatus });
		});
	}

	function controlValue(){
		let passed = true,
			msg = {}

		if(!idCatSelected){
			passed = false;
			msg.idCatSelected = 'Please select a category'
		}
		if(!title || title.length > 80){
			passed = false;
			msg.title = 'Please fill the title [length: '+title.length+'/80]'
		}
		if(!sku){
			passed = false;
			msg.sku = 'Please fill the sku'
		}
		if(!qty && !autoQty){
			passed = false;
			msg.qty = 'Please fill the qty [> 0]'
		}
		/* if(!mpn){
			passed = false;
			msg.mpn = 'Please fill the mpn'
		} */
		if(!lbs && !oz || Number.isNaN(lbs) && Number.isNaN(oz)){
			passed = false;
			msg.weight = 'Please fill the weight (Number format)'
		}
		if(!props.selectedBrand){
			passed = false;
			msg.brand = 'Please select the brand'
		}
		if(!price || parseFloat(price) < 1.0){
			passed = false;
			msg.price = 'Please indicate a price greater than $1'
		}
		if(props.itemFinale.cost && parseFloat(props.itemFinale.cost) >= parseFloat(price)){
			passed = false;
			msg.price = 'Please indicate a price greater than item cost ($ ' + parseFloat(props.itemFinale.cost).toFixed(2) + ')'
		}
		if(props.imgSelected.length === 0){
			passed = false;
			msg.imgs = 'Please select at least one picture';
		}
		if($("#inputDesc").val().length === 0){
			passed = false;
			msg.description = 'Please fill the description field.';
		}

		console.info('valGeneratedFields', valGeneratedFields);
		

		// Generated error messages.
		valGeneratedFields.forEach(o => {
			if(o.isRequired && o.val.length === 0){
				passed = false;
				msg[o.name] = 'Please indicate a value.'
			}
		})

		console.info("passed:", passed);
		console.info("msg:", msg);

		$('.hasTooltip').tooltip('dispose')

		return {
			passed: passed,
			msg: msg
		};
	}

	function azureShippingRate(idItem, idVariant, isFreeShipping){
		if(!idItem || !idVariant)	return false

		let ratedGrp = "5454161346626"
		//, freeShippingGrp = "5235813285954"
			
		fetchAzure({
			"accName": "shopifyapp",
			"trigger": "fetch",
			"filters": "RowKey eq '" + idVariant + "' and idProduct eq '" + idItem + "' and idMeta eq '" + ratedGrp + "'",
		}, (r) => {
			console.info('Azure', r);
			console.info('isFreeShipping', isFreeShipping);

			if(r.res.length > 0){
				if(isFreeShipping)
					fetchAzure({
						"accName": "shopifyapp",
						"trigger": "delete",
						"partition": "itemsSelected",
						"key": idVariant
					}, (r) => {
						console.info('Azure Delete Requested', r);
					})
			}else{
				if(!isFreeShipping)
					fetchAzure({
						"accName": "shopifyapp",
						"trigger": "insert",
						"table": "shipping",
						"data": JSON.stringify({
							"PartitionKey": "itemsSelected",
							"RowKey": idVariant.toString(),
							"idMeta": ratedGrp.toString(),
							"idProduct": idItem.toString(),
							"shopName": "eio-com.myshopify.com",
						})
					}, (r) => {
						console.info('Azure Insert Requested', r);
					})
			}
		})
	}

	const fetchAzure = (obj, callback) => {
		if(!obj.trigger){
			console.warn("Please indicate a trigger name for Azure.");
			return false
		}
		ajaxPerso( Object.assign({
			"api": "azure",
			"table": "shipping"
		}, obj), callback)
	}

	const fetchGetCategoryFeaturesRequest  = (obj, callback) => {
		if(!obj.trigger){
			console.warn("Please indicate a trigger name for Azure.");
			return false
		}
		ajaxPerso( Object.assign({
			"api": "ebay",
			"table": "getCategoryFeaturesRequest"
		}, obj), callback)
	}

	const fetchCategorySuggestions  = (qList, callback) => {
		if(!qList){
			console.warn("Please indicate a qList for fetchCategorySuggestions.");
			return false
		}
		setLoading(true);
		props.loading(true);
		ajaxPerso( Object.assign({
			"api": "ebay",
			"trigger": "categorySuggestions",
			"qList": [qList]
		}), callback)
	}

	const fetchCategoryFeatures  = (catId, callback) => {
		if(!catId){
			console.warn("Please indicate a catId for fetchCategoryFeatures.");
			return false
		}
		ajaxPerso( Object.assign({
			"api": "ebay",
			"trigger": "getCategoryFeatures",
			"site": motorSectionSelected? "eBayMotors" : "US",
			"catId": catId
		}), callback)
	}

	const fetchCategorySpecifics  = (catId, callback) => {
		if(!catId){
			console.warn("Please indicate a catId for fetchCategorySpecifics.");
			return false
		}
		setLoading(true);
		props.loading(true);
		ajaxPerso( Object.assign({
			"api": "ebay",
			"trigger": "fetch_specifics",
			"idCat": catId,
			"site": motorSectionSelected? "eBayMotors" : "US"
		}), callback)
	}

	const updateCatSuggestionTrigger = (query, callback) => {

		// if(props.updateMode)	return false;

		fetchCategorySuggestions(query, (r) => {
			setLoading(false);
			props.loading(false);
			console.info('REQ fetchCategorySuggestions:', r);
			setSuggestions(r.length > 0 && r[0].status? r[0].data : [])
		})
	}

	const updateFeatureByCat = (catId) => {
		fetchCategoryFeatures(catId, (r) => {
			console.info('REQ fetchCategoryFeatures:' + catId, r);

			setValGeneratedFields([])
			setFeatures(r.length > 0 && r[0].status? r[0].data : [])
		})
	}

	const updateSpecificsByCat = (catId) => {
		fetchCategorySpecifics(catId, (r) => {
			setLoading(false);
			props.loading(false);
			console.info('REQ fetchCategorySpecifics:' + catId, r);
			setSpecifics(r.success && r.data.aspects.length > 0? r.data.aspects : [])
		})
	}

	const changeSuggestionSelection = (idSelected) => {
		updateFeatureByCat(idSelected)
		updateSpecificsByCat(idSelected)
	}

	const getSuggestion = () => {
		let strOut = []
		console.info('suggestions', suggestions);
		
		if(suggestions.length > 0)
			suggestions.forEach((lineObj, posLine) => {

				let lineHtml = [],
					isMotorSection = false

				console.info('lineObj', lineObj);

				if("CategoryParentName" in lineObj.Category){
					if(typeof lineObj.Category.CategoryParentName === 'string'){
						lineObj.Category.CategoryParentID = [lineObj.Category.CategoryParentID];
						lineObj.Category.CategoryParentName = [lineObj.Category.CategoryParentName];
					}
					lineObj.Category.CategoryParentName.forEach((catParentName, pos) => {
						
						if(parseInt(lineObj.Category.CategoryParentID[pos]) === 6000){ // eBay Motors
							isMotorSection = true;
						}
						lineHtml.push(<span key={lineObj.Category.CategoryParentID[pos]}>{catParentName}&nbsp;&#62;&nbsp;</span>)
					})

					if(posLine === 0 && (!idCatSelected || motorSectionSelected === null)){
						setMotorSectionSelected(isMotorSection)
						setIdCatSelected(lineObj.Category.CategoryID)
					}

					strOut.push(
						<div key={lineObj.Category.CategoryID + "_catSuggest"} onCompositionStart={() => {this.updateFeatureByCat(lineObj.Category.CategoryID)}}>
							<input onChange={(e) => {
								let val = e.target.value

								// If we select a category from another eBay site, we will have to show a warning.
								/* if(props.updateMode && lineObj.Category.CategoryParentName[0] != props.updateMode.PrimaryCategory.CategoryName.split(":")[0]){
									setDiffSiteSelected(true)
								}else setDiffSiteSelected(false) */

								if(originallyWasMotor != isMotorSection){
									setDiffSiteSelected(true)
								}else setDiffSiteSelected(false)
 
								setMotorSectionSelected(isMotorSection)
								setIdCatSelected(lineObj.Category.CategoryID)
								return e
							}} defaultChecked={idCatSelected === lineObj.Category.CategoryID? true : false} type="radio" name="inputCategory" value={lineObj.Category.CategoryID} className="mr-2"></input>
							{lineHtml}{lineObj.Category.CategoryName}
						</div>)
				}
			})
		
		return <div className={(suggestions.length > 0? "mt-4 mb-4 " : "") + " hasTooltip position-relative"} style={{overflowY: "auto"}} { ...(props.msgInfo.idCatSelected? { "data-toggle": "tooltipFormError", "title": props.msgInfo.idCatSelected } : {}) }>
			<Loading loading={loading} centered={true}/>
			{strOut}
		</div>;
	}


	const getNodesSpecifics = () => {

		console.info('specifics', specifics);
		console.info('valGeneratedFields', valGeneratedFields);
		
		if(!specifics || specifics.length === 0)	return false

		let iOptional = 0,
			iRequired = 0;

		let outputNodes = [
			[], // Required on the top
			[] //  Optional
		]

		let indexFound = null;
		let objFound = valGeneratedFields.find((oi,i) => {
			if(oi.name === "UPC"){
				indexFound = i;
				return oi;
			}
		})
		let objFoundType = valGeneratedFields.find((oi,i) => {
			if(oi.name === "idType") return oi;
		})

		if(objFound && !objFoundType){
			let cp = valGeneratedFields;
			// We insert the idType just before the UPC
			cp.splice(indexFound, 0, {
				"name": 'idType',
				"nameVisible": 'ID type',
				"val": props.itemFinale && props.itemFinale.ean? 'EAN' : 'UPC',
				"isRequired": objFound.isRequired,
				"listRecommOptions": ["UPC", "EAN", "ISBN"].map(id => {
					return {
						value: id,
						label: id
					}
				})
			})
			
			/* cp.idType = {
				"val": '',
				"isRequired": true,
				"listRecommOptions": ["UPC", "EAN", "ISBN"].map(id => {
					return {
						value: id,
						label: id
					}
				})
			} */
			// setValGeneratedFields(cp);
		}

		valGeneratedFields.forEach((o) => {

			//let valField = valGeneratedFields[o.name];
		
			if(!o.isRequired && iOptional % 2 === 0)
				outputNodes[1].push([])
			if(o.isRequired && iRequired % 2 === 0)
				outputNodes[0].push([])

			outputNodes[o.isRequired? 0 : 1][outputNodes[o.isRequired? 0 : 1].length-1].push(<div key={o.name + 'generated'} className={(o.name in errorGeneratedFields? "errorField" : "") + " hasTooltip mr-3 ml-3 mb-3 "} { ...(props.msgInfo[o.name]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[o.name] } : {}) }>
				<label htmlFor={o.name + '_generated'} className="m-0 p-0">{o.nameVisible + (o.isRequired? '*' : '')}</label>
				<span>
					<CreatableSelect
						isClearable
						styles={refConst.styleList}
						onChange={(args, actionMeta) => {

							let cpObj = valGeneratedFields.slice(0)

							let objFound = valGeneratedFields.find(oi => {
								if(oi.name === o.name)	return oi;
							})

							objFound.val = args? args.value : "";

							if(actionMeta.action === "create-option"){
								let spcV = JSON.parse(JSON.stringify(addedSpecificsValue))
								
								if(!(o.name in spcV))
									spcV[o.name] = []

								spcV[o.name].push({Value: args.value})

								setAddedSpecificsValue(spcV)
							}
							
							setValGeneratedFields(cpObj)
						}}
						value={
							[
								o.val? {
									value: o.val,
									label: o.val
								} : null
							]
						}
						options={ o.listRecommOptions? o.listRecommOptions : [] }
						isSearchable={true}
					/>
				</span>
			</div>)

			if(o.isRequired)
				iRequired++
			else
				iOptional++;
		})

		console.info('outputNodes', outputNodes);
		
		let fullList = outputNodes[0].concat(outputNodes[1]),
			niceOutput = []
					
		fullList.forEach((grp, i) => {
			
			niceOutput.push(<div key={"ctnSpecifics_" + i} className="grpOfLineSpecifics d-flex flex-row">
				{grp}
			</div>)
		})

		console.info('niceOutput', niceOutput);
		
		return <div className="mt-4 mb-4">
			{niceOutput}
		</div>;
	}

	const cutString = (e) => {
		if(title.length > 80){
			let newTitle = title.substr(0, 80);
			newTitle = newTitle.substr(0, newTitle.lastIndexOf(' '));
			setTitle(newTitle)
		}
	}

	const generateTmpHtml = () => {
		let tmpHtml = templateDescription()
		
		/* var el = document.createElement('textarea'); */
		
		/* el.value = tmpHtml;
		el.id = "tempTXTClipboard"+Date.now();
		document.body.appendChild(el);
		el.focus();
		el.select(); */
		navigator.clipboard.writeText(tmpHtml).then(function() {
			/* clipboard successfully set */
			props.msgAlert(null, "Copied in your Clipboard")
		}, function() {
			props.msgAlert("Copy to clipboard failled.", null)
		});
		// document.execCommand('copy');
		// $(el).remove();
		
		console.info('templateDescription', tmpHtml);
	}

	const generateTmpHtmlView = () => {
		let tmpHtml = templateDescription()

		props.modal({
			show: true,
			title: "View",
			"html": () => {
				return <div>
					<div dangerouslySetInnerHTML={{__html: tmpHtml}}></div>
				</div>
			},
			"exit": (popup, close) => {
				close()
			},
			"ok": {
				title: "Copy in clipboard",
				fnc: (popup, close) => {
					generateTmpHtml()
				}
			},
			"return": {
				title: "Close",
				fnc: (popup, close) => {
					close()
				}
			}
		})
	}
	
	return (
		<form onSubmit={(e) => { props.submitForm(e, controlValue, sendRequest); }}>
			{props.nodeBrandView(props.selectedBrand && props.selectedBrand.name? () => {
				return <div className="input-group">
					<div className="input-group-prepend">
						<button type="button" className="btn btn-outline-secondary" onClick={generateTmpHtml}>Generate Template</button>
						<button className="btn btn-outline-secondary rounded-right" onClick={generateTmpHtmlView} type="button">Watch</button>
					</div>
				</div>
			} : null)}
			{
				props.isAutoCreated? <div className="alert alert-warning mt-3 text-wrap">
					This product was automatically created by the system. you are limited in the data you can update.
				</div> : ''
			}
			<div className="form-group hasTooltip" { ...(props.msgInfo.title? { "data-toggle": "tooltipFormError", "title": props.msgInfo.title } : {}) }>
				<label htmlFor="forTitle">Title*{title.length > 0? <span className="ml-3">[{title.length}/80]<button type="button" className={(title.length > 80? "" : "d-none") + " btn btn-sm ml-1"} onClick={cutString}><FontAwesomeIcon icon={faCut}/></button></span>:''}</label>
				<input type="text" className="form-control" id="forTitle" placeholder="Title" value={title} onChange={(e) => {
					setTitle(e.target.value)
				}} onBlur={(e) => {
					if(!props.updateMode)
						updateCatSuggestionTrigger(e.target.value)
				}}/>
			</div>
			<div /* className={suggestions.length > 0? "" : "d-none"} */>
				{props.updateMode? <div className="smallText text-wrap text-break mb-2 mt-1">
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text bg-dark text-light">Category</span>
						</div>
						<textarea type="text" ref={currentCat} className="form-control text-wrap text-break" disabled={true} placeholder="Category" value={props.updateMode.PrimaryCategory.CategoryName.split(":").join(" > ")}></textarea>
					</div>
				</div> : ''}
				<label htmlFor="forSearchSuggestion">Search for category suggestion</label>
				<span className="ml-3 tooltipInfo" data-html="true" title="Important:<br/>
					<ul>
						<li>Select carefully the most appropriate category</li>
						<li>You will NOT be able to move an item from eBay - US to eBay Motor since those are 2 diffetents website.</li>
					</ul>" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
				<div className="input-group hasTooltip" { ...(props.msgInfo.searchSuggestion? { "data-toggle": "tooltipFormError", "title": props.msgInfo.searchSuggestion } : {}) }>
					<input type="text" className="form-control" id="forSearchSuggestion" placeholder="Search Suggestion" onChange={(e) => {
						setSearchSuggestion(e.target.value)
					}} onKeyPress={(e) => {
						if(e.key === 'Enter'){
							e.preventDefault();
							return;
						}				
					}}/>
					<div className="input-group-append">
						<button className="btn btn-outline-secondary" type="button" onClick={(e) => {
							e.preventDefault()
							updateCatSuggestionTrigger(searchSuggestion)
						}}>
							<FontAwesomeIcon icon={faSearch}/>
						</button>
					</div>
				</div>
				{
					props.updateMode && diffSiteSelected? <div className="alert alert-danger text-break text-wrap mt-3" role="alert">
						You have selected a category from another ebay site, this might not works (please consider deleting your item and creating it again in the right store)
					</div> : ''
				}
			</div>
			{ getSuggestion() }
			{ !props.isAutoCreated? getNodesSpecifics() : '' }
			{/* <button onClick={() => {
				testInt = !testInt
				console.info('testInttestInt', testInt);
				
				props.msgAlert(
					testInt? "success" : false,
					!testInt? "error" : false
				)
			}} type="button">ssssssssssssssss</button> */}
			<div className="form-group row mt-3">
				<div className="col hasTooltip" { ...(props.msgInfo.sku? { "data-toggle": "tooltipFormError", "title": props.msgInfo.sku } : {}) }>
					<label htmlFor="forSku">
						SKU*
						<span className="ml-3 d-flex" style={{fontSize: "12px"}}>
							{props.selectedBrand && props.selectedBrand.prefix? "["+props.selectedBrand.prefix+"]" : "[no prefix]"}
							{props.selectedBrand && autoPrefix? "Prefix automatically added: [" + props.selectedBrand.prefix + "]" : ""}
							
							<div className="custom-control custom-switch noselect ml-3">
								<input type="checkbox" className="custom-control-input" id="customSwitchPrefix" checked={autoPrefix} onChange={(e) => { setAutoPrefix(e.target.checked? true : false) }}/>
								<label className="custom-control-label" htmlFor="customSwitchPrefix">Auto Prefix</label>
							</div>
						</span>
					</label>
					<input type="text" className="form-control" id="forSku" placeholder="SKU" value={sku} onChange={(e) => {
						setSku(e.target.value) 
					}} />
				</div>
				{/* <div className="col">
					<label htmlFor="forUpc">UPC</label>
					<input type="text" className="form-control" id="forUpc" placeholder="UPC" value={upc} onChange={(e) => { setUpc(e.target.value) }}/>
				</div> */}
			</div>
			<div className="form-group row">
				{/* <div className="col hasTooltip" { ...(props.msgInfo.mpn? { "data-toggle": "tooltipFormError", "title": props.msgInfo.mpn } : {}) }>
					<label htmlFor="forMpn">Manufacture number*</label>
					<input type="text" className="form-control" id="forMpn" placeholder="Part number" value={mpn} onChange={(e) => { setMpn(e.target.value) }}/>
				</div> */}
			</div>
			<div className="form-group row noselect">
				{/* <div className="col d-flex flex-column align-items-center ml-2">
					{<div className="custom-control custom-switch noselect mb-3 w-100">
						<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={freeShipping === 1? true : false} onChange={(e) => { setFreeShipping(e.target.checked? 1 : 0) }}/>
						<label className="custom-control-label" htmlFor="customSwitchFree">Free shipping</label>
					</div>}
					{<div className="custom-control custom-switch noselect w-100">
						<input type="checkbox" className="custom-control-input" id="customSwitchIP" checked={inventoryPolicy === "continue"? true : false} onChange={(e) => { setInventoryPolicy(e.target.checked? "continue" : "deny") }}/>
						<label className="custom-control-label" htmlFor="customSwitchIP">Order when out of stock</label>
					</div>}
				</div> */}
				<div className="col hasTooltip" { ...(props.msgInfo.weight? { "data-toggle": "tooltipFormError", "title": props.msgInfo.weight } : {}) }>
					<label htmlFor="exampleInputEmail1">Weight*</label>
					<div className="d-flex justify-content-center">
						<input type="number" step={0.1} className="form-control mr-1" placeholder="Lbs" style={{width: "70px"}} value={lbs} onChange={(e) => { setLbs(parseFloat(e.target.value) || 0) }}/>
						<input type="number" step={0.1} className="form-control ml-1" placeholder="Oz" style={{width: "70px"}} value={oz} onChange={(e) => { setOz(parseFloat(e.target.value) || 0) }}/>
					</div>
				</div>
				<div className="col hasTooltip" { ...(props.msgInfo.price? { "data-toggle": "tooltipFormError", "title": props.msgInfo.price } : {}) }>
					<label htmlFor="forPrice">Price
						{ ( props.itemFinale.map_price? <span className="ml-3" style={{fontSize: "12px"}}><button type="button" className="btn btn-sm btn-outline-secondary" title="change price to the MAP value" onClick={() => {
							setPrice(parseFloat(props.itemFinale.map_price).toFixed(2))
						}}>MAP: { parseFloat(props.itemFinale.map_price).toFixed(2) }</button></span> : '' ) }
					</label>
					<input type="number" step="0.01" className="form-control" id="forPrice" placeholder="Price" value={price} onChange={(e) => { setPrice(e.target.value) }} style={{maxWidth: "180px"}}/>
					{ ( props.itemFinale.cost? <span className="ml-3" style={{fontSize: "12px"}}>COST: { parseFloat(props.itemFinale.cost).toFixed(2) }</span> : '' ) }
				</div>
				<div className="col hasTooltip" { ...(props.msgInfo.qty? { "data-toggle": "tooltipFormError", "title": props.msgInfo.qty } : {}) } style={{maxWidth: "120px"}}>
					<label htmlFor="forQty">
						Quantity
					</label>
					<div className="custom-control custom-switch noselect mb-3 w-100">
						<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={autoQty} onChange={(e) => { setAutoQty(e.target.checked? true : false) }}/>
						<label className="custom-control-label" htmlFor="customSwitchFree">Auto</label>
					</div>
					<input type="number" step="1" className="form-control" id="forQty" placeholder="Qty" disabled={autoQty} value={qty} onChange={(e) => { setQty(e.target.value) }}/>
				</div>
			</div>
			{/* <div className="form-group" style={{maxWidth: "600px"}}>
				<label htmlFor="inputSortDesc">Description</label>
				<textarea type="text" className="form-control" id="inputSortDesc" defaultValue={desc}></textarea>
			</div> */}
			{<div className="form-group hasTooltip" { ...(props.msgInfo.description? { "data-toggle": "tooltipFormError", "title": props.msgInfo.description } : {}) } style={{maxWidth: "600px"}}>
				<label htmlFor="inputDesc">Description</label>
				<span className="ml-3 tooltipInfo" data-html="true" title="Do NOT include:<br/>
					<ul>
						<li>Info about yourself or your company</li>
						<li>Any special characters (™, ½, @, ^, *, hearts or shapes)</li>
						<li>Retailer-specific info about the product (special sales, guaranteed ship times)</li>
						<li>Any sales information (shipping information, product cost, promotions, etc.)</li>
						<li>Unproven, vague marketing phrases (#1 rated, best selling, high quality, etc.)</li>
						<li>Product attributes (price, quality, weight, material, etc.)</li>
						<li>Marketing phrases (#1 rated, best selling, high quality, etc.)</li>
						<li>Any references to other marketplaces (sold on Amazon, sold on eBay, Alexa, etc.)</li>
					</ul>" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
				<textarea type="text" className="form-control" id="inputDesc" defaultValue={desc}></textarea>
			</div>}
			{/* <div className="form-group" style={{maxWidth: "600px"}}>
				{ props.formDefineVideoInfo() }
			</div> */}
			<div className="fixed-bottom text-center mb-1" style={{
				height: "1px"
			}}>
				<button type="submit" className="bgEbay d-flex align-items-center justify-content-center text-center btn btn-lg btn-block d-inline-block text-white mx-auto position-relative" disabled={props.loading()} style={{width: "50%", opacity: '0.8', bottom: "50px"}}>
					<span>Submit</span>
					<img src={logoStoreEbay} className="ml-4" style={{height: "35px"}}></img>
				</button>
			</div>
		</form>)
}

export default EbayForm