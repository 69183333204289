// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { ajaxPerso, fetchMenu, menuOrganized, fetchDataSet, clipboard, diff } from '../../../fnc'
import { faCaretSquareDown } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
import store from 'store2';

var initialState = {
	_isMounted: false,
	categoriesStoreTo: [],
}

const refConst = require("../../../constants.js").v

refConst.styleList.multiValueLabel = (styles, { data }) => ({
    ...styles,
    color: data.color,
})
refConst.styleList.multiValue = (styles, { data }) => {
	// console.info("datadata", data);
	return {
		...styles,
		backgroundColor: data.background,
		color: data.color,
	}
}
function Menu(props){

	const [lockMenuSize, setLockMenuSize] = useState(true);

	const colorPerLevel = [
		"rgb(53, 53, 53)",
		"rgb(53, 53, 77)",
		"rgb(77, 53, 50)",
		"rgb(32, 19, 94)",
	];

	useEffect(() => {
		initialState._isMounted = true;

		init();
		
		return () => initialState._isMounted = false
	}, [])

	const init = (e, o) => {
		/* fetchMenu( {
			"shop": refConst.id_store_eio
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched Categories for " + refConst.id_store_eio, r);
				let results = r.data
				let menu = menuOrganized(results)
	
				setCategoriesStoreTo(menu)
			}
		});

		fetchDataSet({
			"shop": refConst.id_store_eio,
			"level": 2,
			"justData": true
		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched dataset for " + refConst.id_store_eio, r);
				setDataSet(r.data)
			}
		}) */
	}

	let menuStructure = () => {
		console.info("Props", props);
		return <div>
			<div className="text-center mt-2 font-weight-bold d-flex align-items-center justify-content-center text-wrap">
				Categories Explorer [{props.itemsPerCatCount? props.itemsPerCatCount.reduce((partialSum, o1) => partialSum + o1.item_count, 0) : ""}]
				<span onClick={() => {
					setLockMenuSize(!lockMenuSize)
				}}><FontAwesomeIcon icon={faLock} style={{fontSize: "16px", color: lockMenuSize? "#000" : "rgb(168, 168, 168)"}} className="ml-2 pointer" title="Lock the wider size"/></span>
			</div>
			<div className="mt-2 ctnMenuElementAutoCreator border" style={{
				width: "100%",
				height: "100%",
				overflowX: "auto",
				overflowY: "hidden",
			}}>
				{
					recurseMenu(props.menu, 0)
				}
			</div>
		</div>
	}

	let recurseMenu = (childs, level) => {

		return childs.map(o => {

			if(!o.countChilds)	return;

			return <div key={`level_${level}_${o.name}`} className={(props.menuOpened[level] == o.id_category? "menuGrpSelected" : "") + " pointer w-100"} style={{lineHeight: "30px"}} onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				let cp = props.menuOpened.slice(0)
				cp[level] = props.menuOpened[level] == o.id_category? null : o.id_category
				if(level+1 < cp.length)
				for (let i = level+1; i < cp.length; i++) {
					cp.splice(i, 1)
				}
				console.info("menuOpenedmenuOpened", cp);
				props.setMenuOpened(cp)
				store("menuOpened", cp)
			}}>
				<div className="pl-2 p-2 w-100 menuElementAutoCreator d-flex align-items-center" style={{
					backgroundColor: props.menuOpened[level] == o.id_category? (props.menuOpened[props.menuOpened.length-1] == o.id_category? "rgb(31, 31, 31)" : colorPerLevel[level]) : null,
				}}>
					{o.childs.length > 0? <FontAwesomeIcon icon={faCaretSquareDown} style={{fontSize: "16px", color: "rgb(168, 168, 168)"}} className="mr-2"/> : <span style={{display: "inline-block", width: "22px"}}></span>}
					{o.name}
					<span className="badge badge-light border ml-2">sub: {o.childs.length}</span>
					{o.countChilds > 0? <span className="badge badge-light border ml-2">items: {o.countChilds}</span> : ""}
					{props.menuOpened[props.menuOpened.length-1] == o.id_category? <span className="badge badge-pill badge-success ml-2">Selected</span> : ""}
				</div>
				<div className={(props.menuOpened[level] == o.id_category? "d-block" : "d-none") + " menuChildsGrp"} style={{
					borderColor: props.menuOpened[level] == o.id_category? colorPerLevel[level] : null,
				}}>
					{
						recurseMenu(o.childs, level+1)
					}
				</div>
			</div>
		})
	}
		
	return (<div className={("className" in props? props.className : "") + " position-relative"} style={{
		minWidth: lockMenuSize? "500px" : "200px",
	}}>
		<div className="bg-light text-dark border rounded position-absolute" style={{
			width: "100%",
			zIndex: 888,
			top: "0px",
			left: "0px",
		}} onMouseEnter={(e) => {
			console.info("Enter", e.currentTarget);
			$(e.currentTarget).css({
				width: "500px",
				opacity: "1"
			})
		}} onMouseLeave={(e) => {
			// console.info("Leave", e);
			$(e.currentTarget).css({
				width: lockMenuSize? "500px" : "200px", ///// 
				opacity: "0.8"
			})
		}}>
			{ menuStructure() }
		</div>
	</div>);
}

export default Menu;