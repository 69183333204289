import React, {  } from 'react'
// const refConst = require("../../../constants.js").v

export default function Pagination(props){

	//console.info('Pagination.props', props);

	const getLimitPerPage = () => {
		let limitPerPage = props.qtyPerPage.current && props.qtyPerPage.current.value > 0? props.qtyPerPage.current.value : props.limit_per_page

		if(limitPerPage === 0){
			console.warn("Be careful, the page number in 0.");
			return false;
		}else
			return limitPerPage;
	}

	const getPagination = () => {
		let out = [],
			current_page = props.current_page
		
		let nbrPages = Math.ceil(props.total_items/getLimitPerPage())

		console.info('nbrPages', nbrPages);
		

		for (let i = 1; nbrPages > 0 && i <= nbrPages; i++) {
			if(current_page > 5 && i === 1)
				out.push(<label key={"page_" + i} onClick={(e) => {props.onUpdatePage(e, 1)}} className={(i === current_page? "" : "") + "btn btn-secondary"}>
					<input type="radio" name="options"/>{i}
				</label>);
			if(current_page > 5 && i === 1)
				out.push(<label key={"page_" + i + "_first"} className="btn btn-secondary">
					<input type="radio" name="options" value="..."/>...
				</label>);

			// Hide button when we are going through the pages to keep only a few pages before and after the current opened.
			// Show only 3 button before and after the current opened.
			if(i < current_page -3)
				i = current_page - 3

			// Add after the 2 next button following the current page and marqueur with ... ONLY if there is a third button
			if(i >= current_page + 3 && i <= nbrPages -1)
				out.push(<label key={"page_" + i + "_last"} className="btn btn-secondary">
					<input type="radio" name="options" value="..."/>...
				</label>);

			// Show only the 2 next buttons after the current one.
			if(i > current_page + 2)
				i = nbrPages

			out.push(<label key={"page_" + i} onClick={(e) => {props.onUpdatePage(e, i)}} className={(i === current_page? "active" : "") + " btn btn-secondary"}>
				<input type="radio" name="options"/>{i}
			</label>);
		}
		return out
	}

	return (
		<div className={"text-center fixedCenter m-0 mb-2 p-0" + (props.className? " " + props.className : "")} style={{zIndex: 1030}}>
			<div className="btn-group btn-group-toggle" data-toggle="buttons">
				{getPagination()}
			</div>
		</div>
	);
}