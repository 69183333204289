import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import {
	NavLink
} from 'react-router-dom';
import {ajaxPerso} from "../../../fnc.js"
import Select from 'react-select';
var moment = require('moment');
const refConst = require("../../../constants.js").v

const initialState = {
	_isMounted: false
}

export default function UserAlert(props){

	const [alertList, setAlertList] = useState([]);
	const [alertListSaved, setAlertListSaved] = useState([]);
	
	useEffect(() => {
		initialState._isMounted = true;

		fetchAlert((r) => {
			if(!initialState._isMounted)	return false
			console.log("Fetched fetch_alert:", r);
			setAlertList(r.success? r.data : [])
		})

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		initialState._isMounted = true;
		console.info('props.userData', props.userData);
		
		if(!props.userData)	return;
		
		fetchAccountAlert(props.userData.uid, (r) => {
			if(!initialState._isMounted)	return false
			console.log("Fetched fetch_account_alert:", r);
			setAlertListSaved(r.success? r.data : [])
		})
		return () => initialState._isMounted = false
	}, [props.userData])

	const fetchAlert = (callback) => {

		ajaxPerso({
			"api": "finale",
			"trigger": "fetch_alert",
		}, callback);
	}

	const fetchAccountAlert = (idUser, callback) => {

		if(!idUser)	return;
		
		ajaxPerso({
			"api": "finale",
			"trigger": "fetch_account_alert",
			"uid": idUser
		}, callback);
	}

	const updateDbAccAlert = (idUser, listIdAlert, callback) => {

		if(!idUser)	return;
		
		ajaxPerso({
			"api": "eio",
			"trigger": "update_account_alert",
			"uid": idUser,
			"listIdAlert": listIdAlert.join(',')
		}, callback);
	}

	console.info('TESSSSST', alertListSaved.length > 0? alertListSaved.map(o => {
		return {value: o.id_alert, label: o.name}
	}) : {value: '', label: '-'});
	
	refConst.styleList.container = (provided) => ({
		...provided,
		width: "100%",
		minWidth: "250px"
	})
	
	return (
		<div className={"d-flex flex-wrap justify-content-center "}>
			<Select
				isClearable
				styles={ refConst.styleList }
				onChange={(args) => {
					console.info('args', args);

					let idAlertList = [];
					args.forEach(o => {
						if(o.value > 0)
							idAlertList.push(o.value)
					})

					props.loading(true)

					updateDbAccAlert(props.userData.uid, idAlertList, (r) => {
						
						console.info('Fetched updateDbAccAlert:', r);

						if(r.success)
							props.info({succces: ""})
						else
							props.info({error: r.error})

						fetchAccountAlert(props.userData.uid, (r) => {
							props.loading(false)
							console.log("Fetched fetch_account_alert:", r);
							setAlertListSaved(r.success? r.data : [])
						})
					})
				}}
				options={
					alertList.map(o => {
						return{
							value: o.id,
							label: o.name
						}
					})
				}
				value={
					alertListSaved.length > 0? alertListSaved.map(o => {
						return {value: o.id, label: o.name}
					}) : null
				}
				/* defaultValue={
					alertListSaved.length > 0? alertListSaved.map(o => {
						return {value: o.id, label: o.name}
					}) : null
				} */
				isSearchable={true}
				isMulti={true}
			/>
		</div>
	);
}