// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import Loading from './Loading.js';
import { ajaxPerso, getSupplierInfo, fetchApiData } from '../../fnc.js'
import Select from 'react-select';
import logoStoreEio from '../../img/fav-trans-eio-border.png';
import logoStoreHaus from '../../img/fav-trans-hausOfTools.png';
import logoStoreEbay from '../../img/ebay_circle_perso.png';
import logoStoreWalmart from '../../img/walmart_circle_updated.png';
import logoStoreAmazon from '../../img/amazon_circle_perso.png';
import logoStoreAmazonGott from '../../img/amazon_circle_perso_gott_v2.png';
import moment from 'moment';
import store from 'store2';
import StyledDropzone from './shopify/dropZone'

const refConst = require("../../constants.js").v

export default function ModalModifySupplier(props){

	const initialState = {
		result: {success: [], error: []},
		submited: false,
		reqRunning: false,
		itemGlobalInfo: null,
		itemMargin: [],
		minPrices: null,
		lastFewSale: null,
		priceHistoryPerStore: {},
		selectedSupplier: null,
		freeFreight: false,
	}

	var storeSelectedSupplier = store("selectedSupplier")

	const [supplierInfo, setSupplierInfo] = useState(initialState.supplierInfo);
	const [freeFreight, setFreeFreight] = useState(initialState.freeFreight);
	const [selectedSupplier, setSelectedSupplier] = useState(initialState.selectedSupplier); // {id: name:}
	const [submited, setSubmited] = useState(initialState.submited);
	const [version, setVersion] = useState("0.1.0");
	const [binList, setBinList] = useState([]);
	const [selectedFileBase64, setSelectedFileBase64] = useState();

	const refImg = useRef();
	const refContact = useRef();
	const refAccountNumber = useRef();
	const refPhone = useRef();
	const refLink = useRef();

	useEffect(() => {

		if(storeSelectedSupplier){
			setSelectedSupplier(storeSelectedSupplier)
		}
		
		if(window.modalOpen && "closeModal" in window){
			window.closeModal()
		}
		window.modalOpen = false

		// triggerModal(props.idSupplier, false);
		
	}, [])

	useEffect(() => {

		if(binList.length == 0) return
		
		const reader = new FileReader();

		reader.onloadend = () => {
			const base64Data = reader.result;
			setSelectedFileBase64(base64Data);
		};

		reader.readAsDataURL(binList[0]);
		
	}, [binList])

	useEffect(() => {
		
		triggerModal(props.idSupplier, true);
		
	}, [supplierInfo, submited, props.suppliers, selectedFileBase64])

	/* useEffect(() => {

		function handleRefChange() {
			[
				refImg,
				refContact,
				refPhone,
				refLink,
				refAccountNumber
			].forEach(ref => {
			  if (ref.current) {
				// The ref is already assigned
				console.log('Ref is already assigned');
			  }
			});
		}

		handleRefChange();
	  
		// Clean up function
		return () => {
		  handleRefChange();
		};
	}, []); */

	useEffect(() => {
		
		if(selectedSupplier && selectedSupplier.id)
			getSupplierInfo({
				"id_supplier": selectedSupplier.id
			}, (r) => {
				console.info('getSupplierInfo', r);
				let newData = null
				if(r.success){
					if(r.data.length > 0){
						newData = r.data[0]
						newData.name = selectedSupplier.name	
					}else{
						newData = {
							"id_supplier": selectedSupplier.id,
							"name": selectedSupplier.name
						}
					}
				}
				setSupplierInfo(newData)
			})
		
	}, [selectedSupplier])

	useEffect(() => {

		let intView = loadSupplierDataToView()

		return () => {
			if(intView)
				clearInterval(intView)
		}
		
	}, [supplierInfo])

	const loadSupplierDataToView = () => {

		if(!supplierInfo) return

		let tmRef = setInterval(() => {

			if(refImg.current){
				clearInterval(tmRef)
				
				console.info("supplierInfosupplierInfo", supplierInfo);
			
				refImg.current.value = supplierInfo.img_url ?? ""
				/* refContact.current.value = supplierInfo.contact ?? ""
				refPhone.current.value = supplierInfo.phone ?? ""
				refLink.current.value = supplierInfo.link ?? ""
				refAccountNumber.current.value = supplierInfo.account_number ?? ""
				setFreeFreight(supplierInfo.free_freight ?? 0) */
			}
		}, 50)
		return tmRef
	}

	const submitImgChange = (subGrp) => {

		// Format: subCatIcon_3m__hard-hats.png

		if(!binList || binList.length == 0 || !supplierInfo)	return

		props.loading(true);

		let newFileName = "supplierLogo_" + selectedSupplier.name.replace(/ /g, "-").toLowerCase();

		var formData = new FormData();
		formData.append(`img`, binList[0], newFileName);
		formData.append("api", "shopify");
		formData.append("trigger", "manual_api_storage_img_replacer");
		formData.append("uid", props.getUser().uid);
		formData.append("shop", refConst.id_store_eio);
		formData.append("imgName", newFileName);
		formData.append("retrieveUrl", true);

		ajaxPerso( formData, r => {

			console.info("Result from submitChangeReq:", r);
			props.loading(false);

			if(r.errors){
				props.info({error: JSON.stringify(r.errors)})
			}else if(r.insertResult && r.insertResult.length > 0){
				props.info({success: "Image added."})
				setSupplierInfo(Object.assign({}, supplierInfo, {img_url: r.insertResult[0].url.substring(0, r.insertResult[0].url.lastIndexOf("?"))}))
			}

		}, null, {
			contentType: false,
			processData: false,
			cache: false,
			async: true,
			// timeout: 60000,
		});
	}

	const submitNewSupplierData = () => {

		setSubmited(true)

		fetchApiData(
			"finale",
			"add_supplier_info", {
				"id_supplier": selectedSupplier.id,
				"img_url": refImg.current.value,
				/* "contact": refContact.current.value,
				"phone": refPhone.current.value,
				"link": refLink.current.value,
				"free_freight": freeFreight,
				"account_number": refAccountNumber.current.value */
			}, null,
			(r) => {
				console.info('submitNewSupplierData', r);
				if(r.success){
					props.info({success: "Supplier info saved."})
				}
				setSubmited(false)
			}
		)
	}

	const init = () => {
		
		
	}

	const sectionChoiceSupplier = () => {
		return <div className="input-group w-100">
			<div className="input-group-prepend" style={{width: "20%"}}>
				<span className="input-group-text w-100" id="basic-addon1">Supplier</span>
			</div>
			<div style={{width: "75%"}}>
				<Select
					isClearable
					className="w-auto"
					styles={refConst.styleList}
					onChange={(selectedOption) => {
						store("selectedSupplier", JSON.parse(selectedOption.value)) 
						setSelectedSupplier(JSON.parse(selectedOption.value))
					}}
					options={(() => {
						return props.suppliers.map(o => {
							return {
								value: JSON.stringify({
									"id": o.id_finale,
									"name": o.name
								}),
								label: o.name
							}
						})
					})()}
					value={selectedSupplier? [{
						value: JSON.stringify(selectedSupplier),
						label: selectedSupplier.name
					}] : null}
					placeholder={"Supplier"}
					isSearchable={true}
					isMulti={false}
				/>
			</div>
		</div>
	}

	const formEdit = () => {

		return <div className="input-group w-100">
			<form className="mt-4 w-100" onSubmit={(e) => {
				e.preventDefault()
				submitNewSupplierData()
				console.info("submit")
			}}>
				<div className="form-group">
					<label htmlFor="inputContact">Logo</label>
					<StyledDropzone setDropzone={setBinList} binList={binList} info={props.info} type="img"/>
					<div className="d-flex justify-content-center mt-3">
						{
							selectedFileBase64 || supplierInfo && supplierInfo.img_url? <div className="rounded" style={{
								backgroundImage: `URL(${selectedFileBase64 ?? supplierInfo.img_url})`,
								backgroundSize: "contain",
								backgroundPosition: "center center",
								backgroundRepeat: "no-repeat",
								width: "100px",
								height: "100px"
							}}></div> : ""
						}
					</div>
					<div className="d-flex justify-content-center">
						<button type="button" className="btn btn-sm btn-dark d-inline-block" onClick={(e) => {
							e.preventDefault()
							submitImgChange()
						}}>Generate link</button>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="inputImg">URL image <span className="smallText">(can be autofilled by the Generate link button)</span></label>
					<input type="text" className="form-control" id="inputImg" ref={refImg}/>
				</div>
				{/* <div className="form-group">
					<label htmlFor="inputContact">Contact</label>
					<input type="text" className="form-control" id="inputContact" ref={refContact}/>
				</div>
				<div className="form-group">
					<label htmlFor="inputAcNumber">Account Number</label>
					<input type="text" className="form-control" id="inputAcNumber" ref={refAccountNumber}/>
				</div>
				<div className="form-group">
					<label htmlFor="inputPhone">Phone</label>
					<input type="text" className="form-control" id="inputPhone" ref={refPhone}/>
				</div>
				<div className="form-group">
					<label htmlFor="inputLink">Link</label>
					<input type="text" className="form-control" id="inputLink" ref={refLink}/>
				</div>
				<div className="custom-control custom-switch noselect mb-3 w-100">
					<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={freeFreight === 1? true : false} onChange={(e) => { setFreeFreight(e.target.checked? 1 : 0) }}/>
					<label className="custom-control-label" htmlFor="customSwitchFree">Free Freight</label>
				</div> */}
				<div className="text-center">
					<button type="submit" className="btn btn-primary d-inline-block" disabled={props.loading()}>Save</button>
				</div>
			</form>
		</div>
	}


	let triggerModal = (idSupplier, refresh) => {

		loadSupplierDataToView()

		props.modal({
			key: "Supplier Info",
			show: refresh? false : true,
			title: <span>Supplier Info: {supplierInfo? supplierInfo.account_number : ""}<h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				// width: "modal-xl"
			},
			html: (popup) => {

				if(!props.suppliers){
					return <Loading loading={true} centered={true} />
				}else{
					/* let o = supplierI
					console.info("productData", o);
					let outputBlc = [] */

					return <div>
						{
							sectionChoiceSupplier()
						}
						{
							formEdit()
						}
					</div>
				}
			}
			, exit: (popup, close) => {
				window.modalOpen = false
				close()
			},
			return: {
				title: !props.promoMetaObj? "Close" : "Remove Promo",
				fnc: (popup, close) => {
					window.modalOpen = false
					close()
				}
			}
		});
	}

	/**
	 * This paghe should return a callback for the onClick event.
	 */

	if(!props.modalOpen && window.modalOpen){
		window.modalOpen = true
	}

	console.info("binList", binList);
	console.info("selectedSupplier", selectedSupplier);
	console.info("refImg", refImg);

	return (
		<div className={props.className} onClick={(e) => {
			if(e){
				e.preventDefault();
				e.stopPropagation();
			}

			window.modalOpen = true

			init()
			triggerModal(props.idSupplier, false);
		}}>
			{props.title}
		</div>
	);
}