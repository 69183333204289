// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { fetchStores, ajaxPerso } from '../fnc'
import CreateNew from './ctn/aPlus/CreateNew'
import ListView from './ctn/aPlus/ListView'
import {
	NavLink
} from 'react-router-dom';
const refConst = require("../constants.js").v

var initialState = {
	stores: [],
	_isMounted: false
}

function Aplus(props){

	const [aPlusIdList, setaPlusIdList] = useState([]);
	const [aPlusList, setaPlusList] = useState([]);
	const [shopIdSelected, setShopIdSelected] = useState(null);
	const [stores, setStores] = useState([]);
	const [fncName, setFncName] = useState(null);
	const [keyMetaTmp, setKeyMetaTmp] = useState(null);
	const [aPlusIdList_id, setAPlusIdList_id] = useState(null);

	useEffect(() => {
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		let fncName = props.parent.match.params.fncName
		let idObjOpen = props.parent.match.params.id

		setFncName(fncName);
		setKeyMetaTmp(idObjOpen);

	}, [props.parent.match.params])

	useEffect(() => {

		if(!props.securityClearance(props))	return;
		
		fetchStores((r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched Stores:', r);
			if(r.success){
				
				setStores(r.data.filter(o => {
					if(o.id_marketplace == refConst.id_marketplace_shopify)
						return o
				}))
			}
		})
	}, [])

	useEffect(() => {
		if(stores && stores.length > 0){
			setShopIdSelected(stores[0].id)
		}
	}, [stores])

	useEffect(() => {
		
		if(shopIdSelected){
			loadEverything()
		}
	}, [shopIdSelected])

	const loadEverything = (callback) => {
		let loaded = 0,
			cycle = 0;

		loadData(() => {loaded++});
		loadAplusIds(() => {loaded++});

		let intK = setInterval(() => {
			if(loaded === 2 || cycle > 100){
				clearInterval(intK);
				if(callback)	callback();
			}
			cycle++;
		}, 100)
	}

	const loadData = (callback) => {

		fetchAplus( (r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched A+:', r);
			props.loading(false)
			if(r.success){
				let results = r.res

				results.sort((obj1, obj2) => {
					if(obj1.id < obj2.id)
						return 1
					return -1
				})
				
				setaPlusList(results)
				if(callback)	callback(results);
			}
		})
	}

	const loadAplusIds = (callback) => {

		fetchAplusIds( (r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched AplusIds:', r);
			props.loading(false)
			console.log(r);
			if(r.success){
				let results = r.res
				setAPlusIdList_id(results.length > 0? results[0].id : null)
				let body = results.length > 0? JSON.parse(results[0].value) : []
				setaPlusIdList(body)
				if(callback)	callback(results);
			}
		})
	}

	const fetchAplus = (callback) => {
		if(!initialState._isMounted)	return false
		props.loading(true);

		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetch_meta",
			"namespace": "aPlus_content",
			"shop": shopIdSelected
		}, callback);
	}

	const fetchAplusIds = (callback) => {
		if(!initialState._isMounted)	return false
		props.loading(true);

		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetch_meta",
			"namespace": "aPlus_list",
			"shop": shopIdSelected
		}, callback)
	}
	
	if(!props.securityClearance(props))	return "";

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" value={ JSON.stringify(shopIdSelected) } onChange={(e) => {
					$('.hasTooltip').tooltip('dispose') // Need to be affective before the rerender of the new form cause nodes will be different.
					setShopIdSelected(JSON.parse(e.target.value)) 
				}}>
					{
						refConst.stores.map(obj => {
							if(obj.id_marketplace == 14)
								return <option key={obj.name} value={obj.id}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<div className="w-100 mt-1">
				{ fncName && fncName.indexOf('Create') !== -1? <CreateNew className=""
					{...props}
					shopIdSelected={shopIdSelected}
					keyMetaTmp={keyMetaTmp}
					aPlusList={aPlusList}
					aPlusIdList={aPlusIdList}
					loadData={loadData}
					loadAplusIds={loadAplusIds}
					loadEverything={loadEverything}
					aPlusIdList_id={aPlusIdList_id}
				/> : <NavLink to={{
					pathname: "/Aplus/CreateNew",
					// search: "?mode=Create"
				}}><button type="button" className="btn btn-primary">Create</button></NavLink>}

				{ !fncName? <ListView className=""
					{...props}
					shopIdSelected={shopIdSelected}
					keyMetaTmp={keyMetaTmp}
					aPlusList={aPlusList}
					aPlusIdList={aPlusIdList}
					aPlusIdList_id={aPlusIdList_id}
				/> : ""}
			</div>
		</div>
	);
}

export default Aplus;