// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import PromoReport from './PromoReport';
import OrderReport from './OrderReport';
import AmazonFeeCalculator from './AmazonFeeCalculator';
import MarginModal from './MarginModal.js';
import Report3M from './Report3M';
import WarrantyPages from './WarrantyPages';
import DuplicateModal from './DuplicateModal.js';
import HistoryItems from './HistoryItems.js';
import FastEditFeaturesEbayModal from './FastEditFeaturesEbayModal.js';
import UpdateShopifyTags from './UpdateShopifyTags.js';
import {ajaxPerso} from "../../fnc.js"

const refConst = require("../../constants.js").v

var initialState = {
	_isMounted: false
}

export default function MenuCatalog(props){

	useEffect(() => {
		initialState._isMounted = true;

		return () => initialState._isMounted = false
	}, [])

	return (
		<div className="main ctnPage d-flex flex-wrap mt-3 mb-3 bg-light border pt-3 pl-3 pr-3 pb-0">
			<div className="dropdown">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Amazon
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					{/* <a className="dropdown-item" href="#" onClick={() => amazonFeeCalculator(true)}>Fee Calculator</a> */}
					<AmazonFeeCalculator className={"dropdown-item mb-3"} dataSheet={props.dataSheet} name={"Fee Calculator"} {...props} stores={props.stores} />
				</div>
			</div>
			<div className="dropdown ml-4">
				<button className="btn bg-primary text-white dropdown-toggle mb-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Shopify
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<OrderReport className={"dropdown-item"} dataSheet={props.dataSheet} name={"Order Report"} {...props} required={['sku']} stores={props.stores} store={202487} />
					<OrderReport className={"dropdown-item"} dataSheet={props.dataSheet} name={"Order Report"} {...props} required={['sku']} stores={props.stores} store={205618} />
					<PromoReport className={"dropdown-item"} dataSheet={props.dataSheet} name={"Promo Report"} {...props} required={['sku']} stores={props.stores} store={205618} />
					<PromoReport className={"dropdown-item"} dataSheet={props.dataSheet} name={"Promo Report"} {...props} required={['sku']} stores={props.stores} store={202487} />
				</div>
			</div>
			<div className="dropdown ml-4">
				<button className="btn bg-info text-white dropdown-toggle mb-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					eBay
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<FastEditFeaturesEbayModal className={"dropdown-item mb-3"} dataSheet={props.dataSheet} name={"Fast eBay feature editing"} {...props} stores={props.stores} store={222175} />
				</div>
			</div>
			<MarginModal className={"ml-4 mb-3"} dataSheet={props.dataSheet} onChange={(val) => {
				props.setStateSafe({"dataSheet": val})
			}} name={"Report"} {...props} required={['sku']}/>
			<Report3M className={"ml-4 mb-3"} name={"3M API"} {...props} />
			<DuplicateModal className={"ml-4 mb-3"} dataSheet={props.dataSheet} name={"Duplicate Report"} {...props} />
			<HistoryItems className={"ml-4 mb-3"} dataSheet={props.dataSheet} name={"History"} {...props} />
			<UpdateShopifyTags
				{...props}
				loading={props.loading}
				info={props.info}
				modal={props.modal}
				stores={props.stores}
				isButton={true}
				classname={"ml-4 mb-3 btn btn-outline-primary"}
			/>
			<WarrantyPages className={"ml-4 mb-3"} {...props} />
		</div>

	);
}