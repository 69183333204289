import React, { useRef, Component } from 'react';
import $ from 'jquery'; 
import { utils, writeFile } from 'xlsx';
import BrandList from './BrandList.js';
import {withRouter} from 'react-router';
import SheetCapture from './SheetCapture';
import { ajaxPerso, fetchBrands } from '../../fnc.js';
import Select from 'react-select';

const refConst = require("../../constants.js").v

class FastEdit_form extends Component {
	
	constructor(props) {
		super(props);

		this._isMounted = false;

		console.warn("propppppsss", props);

		this.minFinaleUpdate = React.createRef();
		this.limitFinaleUpdate = React.createRef();
		this.limitFinaleUpdate = React.createRef();

		this.previousIdPage = this.getIdPage();
		this.state = {
			selectedBrandV2: null,
			dataSheet: [],
			basicInfo: [],
			resList: [],
			response: {
				nbrSuccess: 0,
				nbrError: 0,
				success: false,
				error: false
			},
			pages: {
				"1": {
					required: ["sku","price"],
					required2: ["upc","price"],
					triggerName: "editPrice",
					title: "Update prices by bulk",
					urlApi: refConst.walmartApi,
					shopId: refConst.id_store_walmart_eio
				},
				"2": {
					required: ["sku","upc", "price", "weight"],
					triggerName: "addProduct",
					title: "Add items by bulk",
					urlApi: refConst.walmartApi,
					shopId: refConst.id_store_walmart_eio
				},
				"3": {
					required: ["sku"],
					triggerName: "compare",
					title: "Compare a SKU list to Walmart catalog",
					urlApi: refConst.walmartApi,
					shopId: refConst.id_store_walmart_eio
				},
				"4": {
					required: ["sku", "upc"],
					triggerName: "UpdateUpcInDb",
					title: "Update UPC in DB",
					stateToSend: {"selectedBrand": false},
					reqData: "formBrand",
					api: refConst.eioApi,
					placeholder: "Select a brand",
					urlApi: refConst.eioApi,
					shopId: refConst.id_store_finale
				},
				"5": {
					required: ["sku", "qty"],
					triggerName: "updateQtyFinale",
					title: "Update quantity on Finale",
					stateToSend: {"selectedBrand": false},
					reqData: "getSupplier",
					api: "finale",
					placeholder: "Select a supplier",
					urlApi: refConst.finaleApi,
					shopId: refConst.id_store_finale,
					formula: {
						"qty": "< 0"
					}
				}
			},
			triggerOnData: {
				"upc": (tab) => {
					return tab.map(elem => {
						let newVal = elem;
						
						newVal = newVal.replace(/[\s\-_]/ig, "");
						if(newVal.length > 12)
							newVal = newVal.substr(newVal.length - 12)

						return newVal;
					});
				}
			},
			selectedBrand: null,
			brandsAlike: null
		};

		// Bind Allow access by "this" into the function
		this.selectedBrand = this.selectedBrand.bind(this);
		this.sendDataToServer = this.sendDataToServer.bind(this);
		this.loadBrandsAlike = this.loadBrandsAlike.bind(this);
		this.loadBasicInfo = this.loadBasicInfo.bind(this);
		this.submitNewWay = this.submitNewWay.bind(this);
		this.submitFile = this.submitFile.bind(this);
	}

	componentDidMount(){
		this._isMounted = true;

		this.loadBasicInfo()
		$("#labelImg").text("Select a file [xlsx/csv]")
	}

	componentDidUpdate(){
	}

	componentWillUnmount(){
		console.warn("Unmounted");
		this._isMounted = false;
	}

	sendDataToServer() {
		this.props.loading(true)
		let self = this

		console.info('dataSheet', this.state.dataSheet);
		console.info('this.state.pages[this.getIdPage()]', this.state.pages[this.getIdPage()]);

		let currentPageInfo = this.state.pages[this.getIdPage()]
		let stateToSend = currentPageInfo.stateToSend
		let data = this.state.dataSheet

		let dataFiltered = this.filterData(JSON.parse(JSON.stringify(data)))

		console.info('dataFiltered', dataFiltered);

		if(dataFiltered.length === 0){
			this.props.info({error: "Please select some data."})
			this.props.loading(false)
			return false;
		}

		ajaxPerso({
			//"ajax_url": "https://marketplace.walmartapis.com/v3/feeds?feedType=price",
			//"ajax_method": "POST",
			"api": currentPageInfo.urlApi,
			"data": JSON.stringify(dataFiltered), // {"upc":"92644440113","price":"43.49"}
			"trigger": currentPageInfo.triggerName,
			"state": stateToSend,
			"shop": currentPageInfo.shopId
		}, function(res){
			
			if(!self._isMounted)	return false
			self.props.loading(false)

			console.warn(res);
			let nbrObj = {
				nbrSuccess: 0,
				nbrEchec: 0,
				success: false,
				error: false
			};

			self.state.resList.push(res)
			self.setState({resList: self.state.resList})
			
			if(currentPageInfo.triggerName === "editPrice"){
				if(res.errors && res.errors.length > 0)
					nbrObj.error = res.errors;
				else if(res.success)
					nbrObj.success = `Updated: ${res.nbrResultPos}<br/>Errors: ${res.nbrResultNeg}<br>Feed Id: ${res.idFeedList}`;
				
			} else if(currentPageInfo.triggerName === "updateQtyFinale"){
				if(res.error)
					nbrObj.error = res.errors;
				if(res.success)
					nbrObj.success = res.success;
				
			} else if(currentPageInfo.triggerName === "compare"){
				console.info(res.notFound);

				if(res.error)
					nbrObj.error = res.error;
				if(res.success)
					nbrObj.success = res.success;

				var book  = utils.book_new(),
					sheet = utils.json_to_sheet(res.found),
					sheet2 = utils.json_to_sheet(res.notFound)

				utils.book_append_sheet(book, sheet, "found");
				utils.book_append_sheet(book, sheet2, "notFound");

				//writeFile(wb, "compare.xlsx")
				//self.setState(Object.assign(self.state, {"xlsx": {isReady: true, file: utils.book_append_sheet(wb, ws, "result")}}))
				// self.setState(Object.assign(self.state, {"xlsx": {isReady: true, file: writeFile(book, "result.xlsx")}})) // force a client-side download.
				writeFile(book, "result.xlsx")
			}

			if("countUpdated" in res)
				if(res.countUpdated)
					nbrObj.nbrSuccess = res.countUpdated;
				else
					nbrObj.nbrEchec = res.countEntry;

			console.log('nbrObj', nbrObj);
			
			self.props.info(nbrObj); // We push our object with @success, @error, @body
			//self.setState(state);
		}, function(res) {
			self.props.loading(false)
			alert("Error, Please look at your web dev console.", res);
		});
	}
 
	loadBrandsAlike(brandName){
		let self = this

		fetchBrands(null, {
			"like": '%'+brandName+'%'
		}, null, (r) => {
			console.info('Fetched fetchBrands:', r);
			
			if(this._isMounted)	return false

			if(r.success){
				self.setState({brandsAlike: r.data})
			}
		})
	}

	loadBasicInfo(){
		let self = this
		ajaxPerso( {
			"trigger": "get_basic_info_ftp",
			"api": "ftp",
		}, function( r ) {
			console.info('Fetched get_basic_info_ftp:', r);
			
			if(this._isMounted)	return false
			self.setState({basicInfo: r.sort((obj1, obj2) => {
				if(obj1.brand > obj2.brand)
					return 1
				return -1
			}).filter(f => {
				if(f.need_feed_from_email)	return f
			})})
		});
	}

	submitFile(e, brand, file){
		let self = this

		var formData = new FormData();
		formData.append(`file`, file, file.name);
		formData.append("trigger", "put_feed_into_ftp");
		formData.append("api", "ftp");
		formData.append("ftpAcc", brand);

		self.props.loading(true)

		ajaxPerso( formData, function( r ) {
			console.info('Fetched put_feed_into_ftp:', r);

			if(r.success){
				self.props.info({success: "File submited"})
				e.target.reset();
				$("#labelImg").text("Select a file [xlsx/csv]")
			}else
				self.props.info({error: JSON.stringify(r.errors)})
			self.props.loading(false)
		}, null, {
			contentType: false,
			processData: false,
			cache: false,
			async: true,
			// timeout: 60000,
		});
	}

	resetForm() {
		let cp = Object.assign({}, this.state.pages)
		cp[this.getIdPage()].stateToSend.selectedBrand = false
		this.setState({pages: cp})
	}

	filterData(data) {
		let min = parseInt(this.minFinaleUpdate.current.value)
		let limit = parseInt(this.limitFinaleUpdate.current.value)

		data.forEach((o, i) => {
			if(min > 0){
				if(parseInt(o.qty) < min)	o.qty = "0" // delete data.splice(i, 1)
			}
			if(limit > 0){
				if(parseInt(o.qty) > limit)	o.qty = limit.toString()
			}
		});
		return data;
	}

	getIdPage() {
		return "pageId" in this.props.match.params? this.props.match.params.pageId : 1;
	}

	selectedBrand(objBrand){
		console.log("-------------------------");
		console.log("selectedBrand: ", objBrand);
		
		if(objBrand){

			this.loadBrandsAlike(objBrand.name)

			console.log("this.state.pages", this.state.pages);
			let cp = Object.assign({}, this.state.pages)
			cp[this.getIdPage()].stateToSend.selectedBrand = objBrand
			this.setState({pages: cp})
		}else
			return this.state.pages[this.getIdPage()].stateToSend.selectedBrand
	}

	submitNewWay(e){

		let brand = this.state.selectedBrandV2
		let files = $("#validatedCustomFile").get(0).files
		
		if(brand && files.length > 0){

			console.info("brand", brand);
			console.info("files", files);

			this.submitFile(e, brand, files[0])
		}else{
			this.props.info({error: "Please select a brand and an image"})
		}
	}

	render() {
		if(!this.props.securityClearance(this.props))	return "";
		let self = this
		return (
			<div className="d-flex flex-row flex-wrap justify-content-center ctnSplittedInHalf">
				<div className="border m-2 p-3" style={{width: "45%"}}>
					<div className="alert alert-primary text-wrap" role="alert">
						The newer way takes into account the delta and runs in the background on a weekly basis.
					</div>
					<form onSubmit={(e) => {
						e.preventDefault();
						this.submitNewWay(e)
					}}>
						<div className="d-flex flex-row">
							<Select
								placeholder={"placeholder" in this.props? this.props.placeholder : "Select a brand"}
								isClearable
								styles={{
									control: (base) => ({
										...base,
										width: 200
									})
								}}
								onChange={(selectedOption) => {
									this.setState({selectedBrandV2: selectedOption? selectedOption.value : null})
								}}
								options={
									this.state.basicInfo.map(obj => {
										return {value: obj.brand, label: obj.brand}
									})
								}
							/>
							<div className="custom-file ml-3">
								<input type="file" className="custom-file-input" id="validatedCustomFile" required placeholder="Select a file [xlsx/csv]" onChange={(e) => {
									console.info("FILE", e.target.files);
									let refInputImg = e.target.files
									if(refInputImg)
										$("#labelImg").text(refInputImg.length > 0? refInputImg[0].name + " (" + (Math.round(refInputImg[0].size/1000)) + "Kb)" : "Select a file [xlsx/csv]")
								}}/>
								<label className="custom-file-label" id="labelImg" htmlFor="validatedCustomFile">
								</label>
							</div>
						</div>
						<div className="mt-3 text-center">
							<input type="submit" name="Change" className="btn btn-success" disabled={this.props.loading()}/>
						</div>
					</form>
				</div>
				<div className="w-50 border m-2 p-3" style={{width: "45%"}}>
					<div className="alert alert-warning text-wrap" role="alert">
						The older way to submit a feed does not consider any delta. It pushes the entirety of your file, making it a very slow process.
					</div>
					<form onSubmit={(e) => {
						e.preventDefault()
						this.sendDataToServer()
					}} onReset={() => {
						this.resetForm()
					}}>
						{"required2" in this.state.pages[this.getIdPage()]? <SheetCapture dataSheet={this.state.dataSheet} onChange={(val) => {
							this.setState({dataSheet: val})
						}} name={"From Sheet " + JSON.stringify(this.state.pages[this.getIdPage()].required2).split('"').join('')} {...this.props} required={this.state.pages[this.getIdPage()].required2} /> : ''}
						
						<SheetCapture dataSheet={this.state.dataSheet} onChange={(val) => {
							this.setState({dataSheet: val})
						}} name={"From Sheet " + JSON.stringify(this.state.pages[this.getIdPage()].required).split('"').join('')} {...this.props} required={this.state.pages[this.getIdPage()].required} />
					
						<BrandList
							placeholder={this.state.pages[this.getIdPage()].placeholder}
							hidden={["4", "5"].indexOf(this.getIdPage()) >= 0? false : true}
							changeBrand={this.selectedBrand}
							reqName={this.state.pages[this.getIdPage()].reqData}
							api={this.state.pages[this.getIdPage()].api}
							hasInput={["updateQtyFinale"].indexOf(this.state.pages[this.getIdPage()].triggerName) === -1? true : false}
						/>

						{
							this.state.brandsAlike && this.state.brandsAlike.length > 0? <div className="p-3 border mb-3">
								{
									this.state.brandsAlike.map(o => {
										return <div key={o.id} className="p-1">
											<span className="badge badge-secondary">{o.id_marketplace}</span> {o.name} <span className="badge badge-primary">{o.prefix}</span>
											<button className={(o.prefix? "" : "d-none") + " btn btn-outline-primary btn-sm ml-3"} onClick={() => {
												navigator.clipboard.writeText(o.prefix).then(function() {
													/* clipboard successfully set */
													self.props.info({success: "Copied in your Clipboard"})
												}, function() {
													self.props.info({error: "Copy to clipboard failled."})
												});
											}}>Copy</button>
										</div>
									})
								}
							</div> : ''
						}

						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroup-sizing-default">Min</span>
							</div>
							<input ref={this.minFinaleUpdate} type="number" step="1.00" className="form-control" />
						</div>

						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroup-sizing-default">Limite</span>
							</div>
							<input ref={this.limitFinaleUpdate} type="number" step="1.00" className="form-control" />
						</div>

						<div className="d-flex justify-content-around">
							<input type="reset" className="btn btn-danger" value="Reset" />
							<input type="submit" className="btn btn-primary" value="Start" disabled={this.props.loading()}/>
						</div>
						{
							this.state.resList.length > 0? this.state.resList.map((r, pos) => <div key={`${r.id_brand}-${pos}`} className="mt-3 border">
								<span>
									{r.id_brand} - 
								</span><span>
									{r.success? r.success.map((o, t) => {
										return <div key={`${pos}-${t}`}>{o}</div>
									}) : r.error}
								</span>
							</div>) : null
						}
					</form>
				</div>
			</div>
		);
	}
}

export default withRouter(FastEdit_form)