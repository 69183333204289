// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
import Loading from './Loading';
import { capitalizeFLetter, ajaxPerso, meta, handelize, roundPrice } from '../../fnc'

const refConst = require("../../constants.js").v

export default function ModalSpecialPromo(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-Mj_tL_BeXYP4sYC2EUi" // promo_access
	]

	const initialState = {
		// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
		result: {success: [], error: []},
		submited: false,
		reqRunning: false,
		// alreadyExist: false,
		toggledSpecialPromoPage: false,
		navGrpSelectedMenuRight: 0,
		specialPromoObj: null
	}

	const [submited, setSubmited] = useState(initialState.submited);
	const [version, setVersion] = useState("1.0.0");
	// const [alreadyExist, setAlreadyExist] = useState(initialState.alreadyExist);
	const [specialPromoObj, setSpecialPromoObj] = useState(initialState.specialPromoObj);
	const [toggledSpecialPromoPage, setToggledSpecialPromoPage] = useState(initialState.toggledSpecialPromoPage);
	const [navGrpSelectedMenuRight, setNavGrpSelectedMenuRight] = useState(initialState.navGrpSelectedMenuRight);
	const [idMetaSpecialPromo, setIdMetaSpecialPromo] = useState(null);
	const [prcCalculated, setPrcCalculated] = useState(null);

	const dateStart = useRef(null);
	const dateEnd = useRef(null);
	const valueTypePromo = useRef(null);
	const valuePromo = useRef(null);
	const calculatedNewPrice = useRef(null);
	const tempOriginalPricePromo = useRef(null);
	const valueDescPromo = useRef(null);
	const valueVideoPromo = useRef(null);

	useEffect(() => {
		console.info('propsssss', props);
		
		triggerModal(true);
	}, [props.promoMetaObj, navGrpSelectedMenuRight, toggledSpecialPromoPage, idMetaSpecialPromo, prcCalculated])

	/* useEffect(() => {

		if(props.updateMode){
			let metaFound = props.updateMode.metafields.find(o => {
				if(o.key == "specialPromo")	return o;
				return false;
			})
	
			if(metaFound){
				setIdMetaSpecialPromo(metaFound.id)
				setSpecialPromoObj(JSON.parse(metaFound.value))
			}

		}else{
			setIdMetaSpecialPromo(null)
		}

	}, [props.updateMode]) */


	useEffect(() => {
		if(!props.title || !props.promoPageListItems)	return;

		console.info('props.promoPageListItemsprops.promoPageListItems', props.promoPageListItems);
		

		let found = props.promoPageListItems.find(o => {
			if(o.handle === handelize(props.title))	return o;
			return false;
		})
		setSpecialPromoObj(found? found : false)
		setToggledSpecialPromoPage(found? true : false)
	}, [props.promoPageListItems, props.title])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerProcess = () => {

		// setSubmited(false)
		// getItemsData()
		triggerModal(false);
	}

	let checkValues = () => {

		let oriPrice = parseFloat(parseFloat(tempOriginalPricePromo.current.value) > 0? tempOriginalPricePromo.current.value : props.price)

		let errors = [];
		if(valueTypePromo.current.value.trim().length === 0){
			errors.push(<p>Please indicate a type of value</p>)
		}
		if(valuePromo.current.value.trim().length === 0 || parseFloat(valuePromo.current.value) === 0.0){
			errors.push(<p>Please indicate a value &gt; 0</p>)
		}
		if(valueTypePromo.current.value != "percentage" && (parseFloat(valuePromo.current.value) >= oriPrice - 1 || parseFloat(valuePromo.current.value) < 0.0)){
			errors.push(<p>Please indicate a value &gt; 0 and &lt; {oriPrice - 1}</p>)
		}
		if(valueTypePromo.current.value == "percentage" && (parseFloat(valuePromo.current.value) >= 90 || parseFloat(valuePromo.current.value) < 0.0)){
			errors.push(<p>Please indicate a value &gt; 0 and &lt; 90%</p>)
		}
		if(tempOriginalPricePromo.current.value.trim().length !== 0 && (parseFloat(tempOriginalPricePromo.current.value) === 0.0 || parseFloat(tempOriginalPricePromo.current.value) <= parseFloat(props.price))){
			errors.push(<p>Please indicate a temp price higher than {parseFloat(props.price)}</p>)
		}

		props.info({"error": errors})
		return errors.length === 0? true : false
	}

	let addTrigger = (callback) => {

		if(checkValues()){
			/**
			 * If we are updating a product, we save the new promo right away.
			 * If we are creating a new product, we create the promo only once we submit the item and get a possitive response.
			 */
			let newData = {
				"startingDate": parseInt(moment(dateStart.current.value).format("X")),
				"endingDate": parseInt(moment(dateEnd.current.value).format("X")),
				// "handle": handelize(props.title),
				"value_type": valueTypePromo.current.value.trim(),
				"value": valuePromo.current.value,
				"calculatedValue": calculatedNewPrice.current.value,
				"tempOriginalPrice": tempOriginalPricePromo.current.value.trim().length > 0? roundPrice(tempOriginalPricePromo.current.value) : null
			}
			setSubmited(true)
			
			if("setPromoMetaObj" in props)
				props.setPromoMetaObj(newData)
	
			// setAlreadyExist(true)
			callback(newData)
		}
	}

	let updateViewCalculatedPrice = () => {

		let oriPrice = parseFloat(parseFloat(tempOriginalPricePromo.current.value) > 0? tempOriginalPricePromo.current.value : props.price)
		let calPrice = valueTypePromo.current.value === 'percentage'? oriPrice - oriPrice * parseFloat(valuePromo.current.value) / 100 : parseFloat(valuePromo.current.value);
		console.info('calPricecalPrice', calPrice);

		calculatedNewPrice.current.value = roundPrice(calPrice)

		let prcDiscount = null
		if(calPrice && oriPrice)
			prcDiscount = 100 - (Math.round((oriPrice-calPrice)*100/calPrice))

		// console.info("prcDiscount", `${oriPrice}-${calPrice}*100/${calPrice}  -> ${(oriPrice-calPrice)*100/calPrice}`);
		setPrcCalculated(prcDiscount)
	}
	
	let updateViewDiscountValue = () => {

		if(valueTypePromo.current.value == "percentage"){
			let newVal = parseFloat(calculatedNewPrice.current.value),
				priceFl = parseFloat(parseFloat(tempOriginalPricePromo.current.value) > 0? tempOriginalPricePromo.current.value : props.price),
				prcDown = (newVal-priceFl)*100/priceFl * -1
			
			valuePromo.current.value = prcDown
		}
	}

	let htmlFormPromo = (callback) => {
		let defaultStartingDate = null,
			defaultEndingDate = null

		if(props.promoMetaObj){
			defaultStartingDate = moment.unix(props.promoMetaObj.startingDate).format("YYYY-MM-DDTHH:mm")
			defaultEndingDate = moment.unix(props.promoMetaObj.endingDate).format("YYYY-MM-DDTHH:mm")
		}else{
			defaultStartingDate = moment().startOf('day').format("YYYY-MM-DDTHH:mm")
			defaultEndingDate = moment().endOf('day').add(7, 'd').format("YYYY-MM-DDTHH:mm")
		}

		/* console.info("calculatedNewPrice.current", valuePromo.current);
		console.info("props.price", props.price); */

		return <form>
			<div className="alert alert-info" role="alert">
				Select the starting and ending date for your promotion.
			</div>
			<div className="input-group mb-3">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Date Starting</span>
				</div>
				<input className="form-control" type="datetime-local" defaultValue={defaultStartingDate} ref={dateStart}/>
			</div>
			<div className="input-group mb-3">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Date Ending</span>
				</div>
				<input className="form-control" type="datetime-local" defaultValue={defaultEndingDate} ref={dateEnd} onChange={(r) => {
					console.info(dateEnd.current.value)
				}}/>
			</div>
			<div className="d-flex flex-row mb-3 align-items-center">
				Current Price: <span className="font-weight-bold ml-2">{props.price}</span>
				, with discount: <span className="font-weight-bold ml-2">
					<input className="form-control" disabled style={{width: "120px"}} type="number" defaultValue={props.promoMetaObj? props.promoMetaObj.calculatedValue : '0'} ref={calculatedNewPrice} /* onChange={updateViewDiscountValue} */ max={parseFloat(props.price)-1}/>
				</span><span className="badge badge-primary ml-3">{prcCalculated}%</span>
			</div>
			<div className="d-flex flex-row mb-3">
				<div className="input-group mr-3">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-addon1">Value Type</span>
					</div>
					<select className="custom-select" ref={valueTypePromo} defaultValue={props.promoMetaObj? props.promoMetaObj.value_type : 'fixed_amount'}  onChange={updateViewCalculatedPrice}>
						<option value="fixed_amount">fixed_amount</option>
						<option value="percentage">percentage</option>
					</select>
				</div>
				<div className="input-group ml-3">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-addon1">Value</span>
					</div>
					<input className="form-control" type="number" defaultValue={props.promoMetaObj? props.promoMetaObj.value : '0'} ref={valuePromo} onChange={updateViewCalculatedPrice}/>
				</div>
			</div>
			<div className="d-flex flex-row mb-3 w-75">
				<div className="input-group">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-addon1">Temp Original Price</span>
					</div>
					<input className="form-control" type="number" defaultValue={props.promoMetaObj? props.promoMetaObj.tempOriginalPrice : ''} ref={tempOriginalPricePromo} onChange={updateViewCalculatedPrice}/>
				</div>
			</div>
		</form>
	}

	let htmlFormPromoPage = (callback) => {

		return <form>
			<hr className="my-4"></hr>
			<div className="alert alert-info" role="alert">
				Add this item on the special tool of the week page
			</div>
			<div className="input-group mb-3 text-center">
				<div className={("className" in props? props.className : "custom-control custom-switch mb-3 w-100 mt-3")}>
					<input type="checkbox" className="custom-control-input" id="customSwitchSpecialPromoPage" checked={toggledSpecialPromoPage} onChange={(e) => { setToggledSpecialPromoPage(!toggledSpecialPromoPage); }} />
					<label className="custom-control-label noselect pointer" htmlFor="customSwitchSpecialPromoPage">Add to special promo page</label>
				</div>
			</div>
			<div className="input-group mb-3 text-center">
				<div className="input-group-prepend">
					<span className="input-group-text">Description</span>
				</div>
				<textarea className="form-control" rows="3" ref={valueDescPromo} defaultValue={specialPromoObj? specialPromoObj.description : ''}></textarea>
			</div>
			<div className="input-group mb-3 text-center">
				<div className="input-group-prepend">
					<span className="input-group-text">Youtube Link</span>
				</div>
				<textarea className="form-control" rows="3" ref={valueVideoPromo} defaultValue={specialPromoObj? specialPromoObj.video : ''}></textarea>
			</div>
		</form>
	}

	var menuRight = [{
		hidden: false,
		title: 'Promo',
		body: () => <div>
			{ htmlFormPromo() }
		</div>
	},{
		hidden: !("metaPromoPageName" in props) || !props.metaPromoPageName? true : false,
		title: 'Special Page',
		body: () => <div>
			{ htmlFormPromoPage() }
		</div>
	}];

	menuRight = menuRight.filter(o => {
		if(!o.hidden)	return o
	})

	let triggerModal = (refresh) => {
		
		props.modal({
			key: "Special Promo",
			show: refresh? false : true,
			title: <span>Special Promo <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				// width: "modal-xl"
			},
			html: (popup) => {

				return <div>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						{
							menuRight.map((o, index) => {
								return <a key={o.title+"_keyGrpTitleNav"} onClick={() => { setNavGrpSelectedMenuRight(index); }} className={
									(navGrpSelectedMenuRight === index? "active" : "") + 
									" nav-item nav-link text-center"} id={o.title + "_navBtn"} data-toggle="tab" href={"#" + o.title + "_navCtn"} role="tab" aria-controls={o.title + "_navCtn"} aria-selected={false}>
									<div>{o.title}</div>
								</a>
							})
						}
					</div>
					<div className="tab-content" id="nav-tabContent">
						{
							menuRight.length > 0? menuRight.map((o, index) => {

								return <div key={o.title+"_keyGrpCtnNav"} className={(navGrpSelectedMenuRight === index? "active" : "") + " tab-pane fade show"} id={o.title + "_navCtn"} role="tabpanel" aria-labelledby={o.title + "_navBtn"}>
									<div className="mt-3">
										{o.body()}
									</div>
								</div>;
							}) : null
						}
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}, 
			ok: {
				title: navGrpSelectedMenuRight === 0? "Add Promo" : "Save Change",
				fnc: (popup, close) => {

					if(navGrpSelectedMenuRight === 0){
						addTrigger((newPromo) => {
							if("onSubmit" in props){
								props.onSubmit(newPromo, close)
							}
							close()
						})
					}else{
						/* metaChange((r) => {
							if(r.success){
								// setToggledSpecialPromoPage(toggledSpecialPromoPage);
								props.info({success: "fields updated"});
							}
						}); */
					}
				}
			},
			return: {
				title: !props.promoMetaObj? "Close" : "Remove Promo",
				fnc: (popup, close) => {
					if(!props.promoMetaObj){
						setSubmited(true)
						if("setPromoMetaObj" in props)
							props.setPromoMetaObj(null)
					}
					close()
				}
			}
		});
	}

	if(!props.securityClearance(props, access))	return "";

	return (
		props.style == "radio"? <div className={("className" in props? props.className : "")} onClick={() => {
			if("onClick" in props)
				props.onClick(triggerProcess)
			else
				triggerProcess();
		}}>
			<input type="checkbox" className="custom-control-input" id="customSwitchSpecialPromo" checked={props.promoMetaObj !== null} readOnly={true} disabled={toggledSpecialPromoPage} />
			<label className="custom-control-label noselect pointer" htmlFor="customSwitchSpecialPromo">{props.title}</label>
		</div> : <button className={("className" in props? props.className : "")} onClick={() => {
			if("onClick" in props)
				props.onClick(triggerProcess)
			else
				triggerProcess();
		}}>{props.title}</button>
	);
}