// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Filter from './filter'
import Chart from 'chart.js/auto';
import Loading from '../Loading';
import {ajaxPerso} from "../../../fnc.js"

function BestSelling(props){

	const [storeSelectedInChart, setStoreSelectedInChart] = useState(false);
	const [version] = useState("1.2.0");
	const [loadingOn, setLoadingOn] = useState(true);
	const [chart, setChart] = useState(null);
	const [historyQty, setHistoryQty] = useState([]);
	const [itemInStock, setItemInStock] = useState(false);

	const [fromTo, setFromTo] = useState({
		from: new Date(moment().local().format('YYYY-MM-DD 00:00:00')),
		to: new Date(moment().local().format('YYYY-MM-DD 23:59:59'))
	});

	const [top, setTop] = useState(50);
	const [previousSearch, setPreviousSearch] = useState({
		"to": fromTo.to, 
		"from": fromTo.from,
		"top": top,
		"itemInStock": itemInStock
	});

	useEffect(() => {
		loadHistoryQty();
	}, [])

	useEffect(() => {
		if(previousSearch.top != top || previousSearch.to != fromTo.to || previousSearch.from != fromTo.from || previousSearch.itemInStock != itemInStock){
			if(fromTo.to && fromTo.from){
				loadHistoryQty();
				setPreviousSearch({
					"to": fromTo.to, 
					"from": fromTo.from,
					"top": top,
					"itemInStock": itemInStock
				})
			}
		}
	}, [top, fromTo, itemInStock])

	useEffect(() => {
		console.log("historyQty", historyQty);
		
		if(document.querySelector("#ctnChartBestSelling") && historyQty.length > 0){

			let skuList = {};
			// let storeList = {}; // Make the script faster by just looking at this index instead of going through each sku over and over
			// let sumCount = 0;
			let datasets = [];

			historyQty.forEach(storeObj => { // .slice(0, 30)
			// historyQty.map(o => { // .slice(0, 30)

				// let color = `rgb(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255})`

				if(storeObj.bestSelling && storeObj.bestSelling.length > 0){
					let storeName = storeObj.name
					storeObj.bestSelling.forEach((objSku, index) => {

						if(Object.keys(skuList).indexOf(storeName) === -1){
							skuList[storeName] = [];
						}

						skuList[storeName].push(objSku.sku)
					});

					datasets.push({
						label: storeName,
						data: storeObj.bestSelling.map((o) => {
							return o.qty_sold
						}).sort((a, b) => b - a),
						backgroundColor: storeObj.bestSelling.map((o) => {
							return `rgb(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255})`
						}),
						hidden: storeSelectedInChart === false && storeName === historyQty[0].name? false : (storeSelectedInChart === storeName? false : true),
						maxBarThickness: 25
					})
				}
			})

			console.log("storeSelectedInChart", storeSelectedInChart);
			console.log("skuList", skuList);
			console.log("chart", chart);
			console.log("datasets", datasets);
			console.log("datasetsSSS", storeSelectedInChart + " : " + historyQty[0].name);

			let clickLegendFnc = (e, legend) => {
				console.log("legend", legend);
				console.log("e", e);
				console.log("eEEEEE", storeSelectedInChart + " === " + legend.text);

				setStoreSelectedInChart(storeSelectedInChart === legend.text? false : legend.text)
			}
			
			if(!chart){
				
				console.warn("Create new one");
				
				setChart(new Chart(document.querySelector("#ctnChartBestSelling"), {
					type: 'bar',
					data: {
						labels: skuList[storeSelectedInChart? storeSelectedInChart : Object.keys(skuList)[0]],
						datasets: datasets,
					},
					options: {
						aspectRatio: 4,
						//circumference: 4 * Math.PI,
						plugins: {
							legend: {
								align: 'center',
								onClick: clickLegendFnc
							}
						},
						scales: {
							y: [{
								ticks: {
									beginAtZero: true,
									//stepSize: 1
								}
							}]
						}
						// cutoutPercentage: sumCount / 2
					}
				}))
			}else{
				console.warn("Update chart");

				//chart.destroy()
				chart.data = {
					labels: skuList[storeSelectedInChart? storeSelectedInChart : Object.keys(skuList)[0]],
					datasets: datasets,
				};
				chart.options.plugins.legend.onClick = clickLegendFnc;
				chart.update();
				setChart(chart)
			}
		}
	}, [historyQty, storeSelectedInChart])

	const filterDate = () => {

	}

	const loadHistoryQty = (args) => {
		setLoadingOn(true)
		fetchHistoryQty(args, (r) => {
			if(r.success){
				if(r.data && r.data.length > 0)
					setHistoryQty(r.data)
			}else{
				props.info({"error": r.error})
			}
			setLoadingOn(false)
		})
	}

	const fetchHistoryQty = (args, callback) => {
		// console.log("TEST", filterDate());

		// if(moment(fromTo.from).format("YYYY-MM-DD") === moment(fromTo.to).format("YYYY-MM-DD"))	return;
		
		ajaxPerso( Object.assign({
			"trigger": "get_best_selling",
			"api": "finale",
			"dateFrom": moment(fromTo.from).format("YYYY-MM-DD"),
			"dateTo": moment(fromTo.to).format("YYYY-MM-DD"),
			"sort": "qty",
			"limit": top,
			"exclude": "133836,5,7,208674",
			"inStock": itemInStock,
		}, args), callback)
	}

	return (
		<div className={props.className + " main ctnPage d-flex flex-column flex-wrap border pr-3 pl-3 pb-3 pt-1 position-relative"}>
			<p className="h5">Best Selling Item</p>
			<Filter
				//from={fromTo.from}
				//setFrom={setFrom}
				setFromTo={(newFromTo) => {
					setFromTo(Object.assign({}, newFromTo))
				}}
				fromTo={fromTo}
				/* to={to}
				setTo={setTo} */
				top={top}
				setTop={setTop}
				moreFilters={<div className="ml-3 noselect">
					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(val) => {
							setItemInStock($(val.target).is(':checked')? true : false)
						}} checked={itemInStock}/>
						<label className="custom-control-label" htmlFor="customSwitch1">Item currently in stock</label>
					</div>
				</div>}
			/>
			<div>
				<div className="mt-4">
					<canvas id="ctnChartBestSelling" className="w-100"></canvas>
				</div>
			</div>
			<Loading loading={loadingOn} centered={true} />
			<h6 className="position-absolute text-secondary" style={{right: "15px", bottom: "0px", fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6>
		</div>

	);
}

export default BestSelling;