// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {ajaxPerso, getCaptchaToken, socketPerso} from "../../../../fnc.js"

const refConst = require("../../../../constants.js").v

const styleList = {
	/* container: (provided) => ({
		...provided,
		width: "80%"
	}), */
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

const initialState = {
	selectedBrand: null,
	selectedCountry: "",
	selectedMenu: "",
	nbrVideoMax: 2,
	navTabVideoSelected: 0,
	linkVideo: [],
	brandObject: false,
	tagsGroup: [],
	tagsGroupSelection: [], // {idGroup1: [stringSelected], idGroup2: [stringSelected]}
	itemFinale: false,
	published: 1,
	template: null
}

function EbayView(props) {

	const [selectedBrand, setSelectedBrand] = useState(initialState.selectedBrand);
	const [selectedCountry, setSelectedCountry] = useState(initialState.selectedCountry);
	const [selectedMenu, setSelectedMenu] = useState(initialState.selectedMenu);
	const [nbrVideoMax, setNbrVideoMax] = useState(initialState.nbrVideoMax);
	const [navTabVideoSelected, setNavTabVideoSelected] = useState(initialState.navTabVideoSelected);
	const [linkVideo, setLinkVideo] = useState(initialState.linkVideo);
	const [brandObject, setBrandObject] = useState(initialState.brandObject);
	const [template, setTemplate] = useState(initialState.template);
	const [tagsGroup, setTagsGroup] = useState(initialState.tagsGroup);
	const [tagsGroupSelection, setTagsGroupSelection] = useState(initialState.tagsGroupSelection);
	const [itemFinale, setItemFinale] = useState(initialState.itemFinale);
	const [published, setPublished] = useState(initialState.published);

	useEffect(() => {
		/**
		 * Pull the brand object associated if it exist
		 */
		if(selectedBrand && selectedBrand.name){
			setBrandObject(initialState.brandObject)
			fetchBrandObject( selectedBrand.id? selectedBrand.id : null, selectedBrand.name? refConst.defaultBrandName : null, r => {
				props.loading(false)

				console.info('Fetched brand Object:', r);
				if(r.success && r.data && "data" in r.data){
					let objData = r.data.data
					setBrandObject(objData)
				}else
					setBrandObject(initialState.brandObject)
			})
		}

	}, [selectedBrand])
	
	useEffect(() => {
		fetchTmp(r => {
			console.info('Fetched `fetchTmp`', r);
			if(r.success)
				setTemplate(r.data)
		})
	}, [])

	useEffect(() => {

		$('.ctnNavBrandTmp .nav-item').on("click", (e) => {
			// We close the opened tab if a click was made on the open tab
			let currentTg = $($(e)[0].currentTarget)
			console.log(currentTg.is(".active"));
			
			if(currentTg.is(".active")){
				console.log($("#" + currentTg.attr("aria-controls")));
				
				currentTg.removeClass("active").attr("aria-selected", "false")
				$("#" + currentTg.attr("aria-controls")).removeClass("show").removeClass("active")
				return false;
			}
		})
	})

	const fetchBrandObject = (idBrand, brandName, callback) => {
		let args = {}
		if(idBrand)
			args.idProductBrand = idBrand
		else{
			args.brandName = brandName
		}

		props.loading(true)

		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getBrandTemplate",
			"template_id": refConst.template_id_ebay,
			//"data": JSON.stringify(args)
		}, args), callback)
	}

	const fetchTmp = (callback) => {

		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getTemplate",
			"data": JSON.stringify({
				"name": "eBay"
			}),
		}), callback)
	}

	const fetchItemFromFinale = (sku, callback) => {

		if(!sku)	return false

		ajaxPerso( {
			"api": "finale",
			"trigger": "getItemsBySku",
			"sku": JSON.stringify([(selectedBrand.prefix? selectedBrand.prefix : "") + sku]),
		}, callback)
	}

	const fetchItemFromShopify = (sku, callback) => {

		if(!sku)	return false

		ajaxPerso( {
			"api": "shopify",
			"trigger": "getItemFromSku",
			"sku": JSON.stringify([(selectedBrand.prefix? selectedBrand.prefix : "") + sku]),
			"shop": props.shopSelected.storeId,
		}, callback)
	}

	const formSelectionBrand = (prependNodes) => {
		//console.log("brandObject", brandObject)
		let partView = {"nav": [], "ctn": []};
		if(brandObject)
			brandObject.forEach(obj => {
				partView.nav.push( <a key={obj.model+"_navBrandTmp"} className="nav-item nav-link" id={"nav_"+obj.model+"-tab"} data-toggle="tab" href={"#nav_"+obj.model} role="tab" aria-controls={"nav_"+obj.model} aria-selected="true">{obj.model}</a> )
				partView.ctn.push( <div key={obj.model+"_CtnBrandTmp"} className="border border-top-0 tab-pane p-3 fade" id={"nav_"+obj.model} role="tabpanel" aria-labelledby={"#nav_"+obj.model+"-tab"}  dangerouslySetInnerHTML={{__html: obj.value}}/> )
			})

		return(
			<div className="form-group hasTooltip" { ...(props.msgInfo.brand? { "data-toggle": "tooltipFormError", "title": props.msgInfo.brand } : {}) }>
				<label htmlFor="exampleInputEmail1">Brand*</label>
				<div className="input-group mb-3">
					{
						prependNodes? <div className={(selectedBrand.id? "d-none" : "") + " alert alert-danger text-wrap"}>
							If you have to list a new brand, please make sure first with your manager that no template is required for this brand.
						</div> : ''
					}
					{prependNodes?<div className={(!brandObject? "invisible" : "") + " input-group-prepend w-50"}>
						{prependNodes()}
					</div>:''}
					{<CreatableSelect
						/* isClearable */
						className={prependNodes? "w-50" : "w-100"}
						styles={ styleList }
						placeholder="Select or Create a tag"
						onChange={(args, actionMeta) => {

							let newSelectedTemp = null;
							
							if(actionMeta.action === "create-option"){
								let found = props.brands.find(t => {
									if(t.name == args.value)	return t
									return false;
								})

								let bL = props.brands;

								if(!found){
									bL.push({
										isManAdded: true,
										name: args.value,
										prefix: ''
									})

									props.setBrands(bL.slice(0))
									newSelectedTemp = bL[bL.length-1]
								}else{
									newSelectedTemp = found;
								}
							}else{
								newSelectedTemp = JSON.parse(args.value)
							}
							
							setSelectedBrand(newSelectedTemp)
						}}
						options={props.brands.map((obj) => {
							return {value: JSON.stringify(obj), label: obj.name}
						})}
						defaultValue={[selectedBrand? {value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""} : {value: "", label: "-"} ]}
						value={selectedBrand? [{value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""}] : null}
						isSearchable={true}
					/>}
				</div>
				{/* { props.msgInfo.brand? <div className="text-danger">{props.msgInfo.brand}</div> : ''} */}
				{partView.nav.length > 0? <div style={{maxWidth: "600px"}} className="ctnNavBrandTmp overflow-auto mt-3">
					<nav>
						<div className="nav nav-tabs" id="nav-tab" role="tablist">
							{ partView.nav }
						</div>
					</nav>
					<div className="tab-content text-wrap text-break overflow-auto" id="nav-tabContent" style={{maxWidth: "600px", maxHeight: "250px"}}>						{ partView.ctn }
					</div>
				</div> : "" }
			</div>)
	}

	const formDefineVideoInfo = () => {

		return(
			<div>
				<nav>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						{
							(() => {
								let outputNavVideo = []
								for (let index = 0; index < nbrVideoMax; index++) {
									outputNavVideo.push(<a key={index+"_videoCtn"} onClick={() => { setNavTabVideoSelected(index) }} className={
										(navTabVideoSelected === index? "active" : "") + 
										" nav-item nav-link text-center"} id={index + "_navBtnVideoCtn"} data-toggle="tab" href={"#" + index + "_navCtnVideo"} role="tab" aria-controls={index + "_navCtnVideo"} aria-selected={false}>
										<div>video {index+1}</div>
									</a>)
								}
								return outputNavVideo
							})()
						}
					</div>
				</nav>
				<div className="tab-content" id="nav-tabContent">
					{
						(() => {
							let outputNavVideo = []
							for (let index = 0; index < nbrVideoMax; index++) {
								outputNavVideo.push(<div key={index+"_keyGrpCtnNav"} className={(navTabVideoSelected === index? "active" : "") + " tab-pane fade show mt-3"} id={index + "_navCtnVideo"} role="tabpanel" aria-labelledby={index + "_navBtnVideoCtn"}>
									<div className="form-group">
										<label htmlFor="inputDesc">Description Video {index+1}</label>
										<textarea type="text" className="form-control descVideo" id={"inputDescVideo_"+index}></textarea>
										{ props.msgInfo.descVideo && props.msgInfo.descVideo[index]? <div className="text-danger">{props.msgInfo.descVideo[index]}</div> : ''}
									</div>
									<div className="form-group">
										<label htmlFor={"inputYoutubeLink_"+index}>Youtube link {index+1}</label>
										<input type="text" placeholder="on a youtube page, click on 'Share' then 'copy'" className="form-control" id={"inputYoutubeLink_"+index} rows="3" value={linkVideo[index]? linkVideo[index]  : ""} onChange={(e) => {
											let valLink = e.target.value
											let lkv = linkVideo.slice(0)
											lkv[index] = valLink.trim().length > 0? "https://www.youtube.com/embed" + valLink.substring(valLink.lastIndexOf("/")) : ""
											setLinkVideo(lkv)
										}}/>
										{ props.msgInfo.linkVideo && props.msgInfo.linkVideo[index]? <div className="text-danger">{props.msgInfo.linkVideo[index]}</div> : ''}
									</div>
								</div>)
							}
							return outputNavVideo
						})()
					}
				</div>
			</div>)
	}

	async function getBase64(file) {
		let res = await new Promise(function(resolve, reject) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result)
			};
			reader.onerror = function (error) {
				reject(error)
			};
		})

		return res;
	}

	const submitForm = (e, fncControlData, fncTriggerRequest) => {
		e.preventDefault();
		props.loading(true);
		//props.info({success: "okkkkk"})

		var res = fncControlData(),
			tabPromise = [],
			tabObjBins = [],
			tabObjBinsLinkImgs = [],
			tabObjBinsLinkFiles = []
			
		if(res.passed){
			props.binList.forEach(bin => {
				
				/* if(!(bin.typeBin in objBinGrouped))
					objBinGrouped[bin.typeBin] = [] */

				if(bin.isFromShopify){
					bin.position = bin.pos + 1

					if(bin.typeBin == "img")
						tabObjBinsLinkImgs.push( bin )
					if(bin.typeBin == "file")
						tabObjBinsLinkFiles.push( bin )
				}else{
					tabObjBins.push(bin) // keep object order
					tabPromise.push(getBase64(bin))
				}
			})
			
			if(props.binList.length > 0){
				if(tabPromise.length > 0)
					Promise.all(tabPromise).then((base64Bins) => {
						let imgsBin = [],
							filesBin = []

						base64Bins.forEach((base64, index) => {
							base64Bins[index] = { attachment: base64 }
							base64Bins[index].position = tabObjBins[index].pos + 1
							base64Bins[index].name = tabObjBins[index].name

							if(tabObjBins[index].typeBin == "img")
								imgsBin.push(base64Bins[index])
							if(tabObjBins[index].typeBin == "file")
								filesBin.push(tabObjBins[index])
						})
						
						// Add every files already saved to the new binary objects.
						imgsBin = imgsBin.concat(tabObjBinsLinkImgs)
						filesBin = filesBin.concat(tabObjBinsLinkFiles)
						
						imgsBin.sort((obj1, obj2) => {
							if(obj1.pos > obj2.pos)
								return 1
							return -1
						})

						filesBin.sort((obj1, obj2) => {
							if(obj1.pos > obj2.pos)
								return 1
							return -1
						})

						fncTriggerRequest(imgsBin, filesBin);
					})
				else
					fncTriggerRequest(tabObjBinsLinkImgs, tabObjBinsLinkFiles);
			}else{
				fncTriggerRequest([], []);
			}
			
		}else{
			props.loading(false);
			console.warn("MsgInfo", res.msg);
			props.setMsgInfo(res.msg)
		}

		console.log("Submit");
	}

	const htmlDescVideo = () => {
		let output = []

		console.warn("htmlDescVideo", linkVideo);

		linkVideo.forEach((linkVideo, index) => {
			let descVde = $("#inputDescVideo_" + index ).val()
			console.log("descVde", descVde);
			console.log("linkVideo", linkVideo);
			
			if(descVde.trim().length > 0 && linkVideo.trim().length > 0)
				output.push({
					"description": descVde,
					"link": linkVideo
				})
		})

		// We add the version number if ever we need to adjust the Liquid Theme
		return {
			data: output,
			version: props.version
		}
	}

	/* const htmlTagsGroup = () => {
		
		return <div className="d-flex flex-row flex-wrap">
			{tagsGroup.map((tagGrp, index) => {
				return <div className="form-group w-50 p-2" key={tagGrp.id + "_tagGroup"}>
					<label>{tagGrp.name}</label>
					<Select
						styles={styleList}
						onChange={(selectedOption) => {
							let valChoosen = [];
							let cp = Object.assign({}, tagsGroupSelection)

							if(selectedOption){
								if(!cp[tagGrp.id])
									cp[tagGrp.id] = [];

								if(parseInt(tagGrp.multi_select))
									selectedOption.forEach(obj => {
										if(obj.value)
											valChoosen.push(obj.value)
									})
								else{
									if (selectedOption.value)
										valChoosen.push(selectedOption.value);
								}
								cp[tagGrp.id] = valChoosen;
							}else{
								// Remove the group group from the selected variable
								if(cp[tagGrp.id])
									delete cp[tagGrp.id];
							}
							//console.log("new tab", cp);
							setTagsGroupSelection(cp)
						}}
						options={[""].concat(tagGrp.data).map((oTag) => {
							return {
								"value": oTag.name? JSON.stringify({
									"prefix": tagGrp.prefix,
									"data": oTag.name
								}) : '', 
								"label": oTag.name? oTag.name : '-'}
						})}
						value={(() => {
							if(!tagsGroupSelection[tagGrp.id]) return []
							let output = [];
							tagsGroupSelection[tagGrp.id].forEach(objStr => {
								let obj = JSON.parse(objStr)
								output.push({value: objStr , label: obj.data})
							});
							return output;
						})()}
						//defaultValue={[{value: selectedCountry? selectedCountry : "", label: selectedCountry? selectedCountry : "-"}]}
						isSearchable={true}
						isMulti={parseInt(tagGrp.multi_select)? true : false}
					/>
				</div>
			})}
		</div>
	} */

	const modalDeleteItem = () => {
		if(props.updateMode && props.updateMode.id)
			props.modal({
				show: true,
				title: "Confirmation",
				html: () => {
					return <div>
						Are you sure you want to delete the following product: <span className="font-weight-bold">{props.updateMode.id}</span>
					</div>
				}
				,ok: {
					title: "I'm sure",
					fnc: (popup, close) => {
						console.log('props.updateMode', props.updateMode);
						
						ajaxPerso( {
							"api": `shopify`,
							"page": `products/${props.updateMode.id}.json`,
							"trigger": "request",
							"method": "DELETE",
							"shop": props.shopSelected.storeId,
						}, (r) => {
							console.info('Execute delTemplate:', r);
							let info = {error: false, success: false}
							if(r.code === 200 || r.code === 201){
								close()
								info.success = <div key={"linkInDeleteItem"}>Item: <u>{props.updateMode.title}</u> successfully removed.</div>
								props.parent.history.push("./");
							}else{
								info.error = r.reason;
							}
							props.msgAlert(info.error, info.success)
						})
					}
				},
				return: {
					title: "Close"
				}
			})
	}
	
	return (
		<div>
			<div className="form-group d-flex flex-row">
				{
					props.updateMode && props.updateMode.id? <button type="button" className="btn btn-danger" onClick={modalDeleteItem}>Remove</button> : ""
				}
			</div>

			{/* {formSelectionBrand()}
			{htmlTagsGroup()} */}
			
			{props.loadAndshowForm(props.shopSelected.name, {
				...props,
				nodeBrandView: formSelectionBrand,
				htmlTags: getBase64,
				selectedMenu: selectedMenu,
				selectedBrand: selectedBrand,
				setSelectedBrand: setSelectedBrand,
				setSelectedMenu: setSelectedMenu,
				setSelectedCountry: setSelectedCountry,
				selectedCountry: selectedCountry,
				menu: props.menu,
				initialState: Object.assign(initialState, props.initialState),
				submitForm: submitForm,
				formDefineVideoInfo: formDefineVideoInfo,
				linkVideo: linkVideo,
				setLinkVideo: setLinkVideo,
				nbrVideoMax: nbrVideoMax,
				htmlDescVideo: htmlDescVideo,
				brandObject: brandObject,
				template: template,
				setBrandObject: setBrandObject,
				tagsGroup: tagsGroup,
				setTagsGroup: setTagsGroup,
				tagsGroupSelection: tagsGroupSelection,
				setTagsGroupSelection: setTagsGroupSelection,
				fetchItemFromFinale: fetchItemFromFinale,
				fetchItemFromShopify: fetchItemFromShopify,
				itemFinale: itemFinale,
				setItemFinale: setItemFinale,
				published: published,
				setPublished: setPublished,
				/* dataSet: props.dataSet,
				setDataSet: props.setDataSet */
			})}
				
		</div>);
}

export default EbayView