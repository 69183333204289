// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { capitalizeFLetter, ajaxPerso } from '../../fnc'

const refConst = require("../../constants.js").v

export default function ModalTicket(props){

	const initialState = {
		submited: false,
		response: null,
		reqRunning: false
	}

	const [reqRunning, setReqRunning] = useState(initialState.reqRunning);
	const [submited, setSubmited] = useState(initialState.submited);
	const [response, setResponse] = useState(initialState.response);
	const [version, setVersion] = useState("0.1.0");

	const refDesc = useRef();	

	useEffect(() => {
		if(response !== null)
			triggerModal(true);
	}, [response])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerProcess = () => {

		setSubmited(false)

		triggerModal(false);
	}

	let triggerModal = (refresh) => {
		
		props.modal({
			key: "FTP File Downloader",
			show: refresh? false : true,
			title: <span>Describe your issue <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-lg"
			},
			html: (popup) => {
				return response === null? <div className="mb-3">
					<div className="alert alert-info" role="alert">
						Submit this form or send an email direclty to <a href="mailto: yannis@eio.com">yannis@eio.com</a>
					</div>
					<label htmlFor="validationTextarea">Description</label>
					<textarea className="form-control" ref={refDesc} id="validationTextarea" placeholder="Describe your issue with our tools" required></textarea>
				</div> : <div>
					{ response? "Email Sent" : "Error, Try later" }
				</div>
			}, exit: (popup, close) => {
				close()
			}, 
			ok: response !== 1? {
				title: "Submit",
				fnc: (popup, close) => {

					if(reqRunning)	return false;

					sendMail((r) => {
						console.info('Fetched `toolSupport`', r);
						setReqRunning(false)
						setResponse(r)
						if(r){
							props.info({success: "Email sent"});
						}else	props.info({error: "Error while sending the email."});
					})
				}
			} : null,
			return: {
				title: reqRunning? "Please wait" : "Close",
				fnc: (popup, close) => {
					if(!submited){
						resetPage()
						close()
					}
				}
			}
		});
	}

	let sendMail = (callbackItem) => {
		setReqRunning(true)
		ajaxPerso( {
			"api": "eio",
			"trigger": "toolSupport",
			"message": refDesc.current.value,
			"from": props.getUser().email
		}, callbackItem);
	}

	return (
		<div className={("className" in props? props.className : 'list-group-item list-group-item-action p-2 text-center')} onClick={() => triggerProcess()}>
			{
				props.title
			}
		</div>
	);
}