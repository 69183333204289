// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
// import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
/* import {
	NavLink
} from 'react-router-dom'; */
//import DayPicker, { DateUtils } from 'react-day-picker';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
// import { formatDate, parseDate } from 'react-day-picker/moment'
import Chart from 'chart.js/auto';
import BestSelling from './ctn/historySells/bestSelling';
import WorstSelling from './ctn/historySells/worstSelling';
import DecreasingSells from './ctn/historySells/DecreasingSells';
import DailyValueInventory from './ctn/historySells/DailyValueInventory';
import {ajaxPerso} from "../fnc.js"
require('codemirror/lib/codemirror.css');
require('codemirror/theme/monokai.css');

require('codemirror/mode/javascript/javascript.js');
require('codemirror/keymap/sublime');
// const refConst = require("../constants.js").v
//var moment = require('moment');


function HistorySells(props){

	const [historyQty, setHistoryQty] = useState([]);
	const [version] = useState("1.1.1");

	/* const [from, setFrom] = useState(undefined);
	const [to, setTo] = useState(undefined);
	const [top, setTop] = useState(null);
	const [previousSearch, setPreviousSearch] = useState({
		"to": to, 
		"from": from
	}); 
	var toObj = null;*/
	var chart = null;

	useEffect(() => {
		/* loadLogs(filterDate())
		let timerInterval = setInterval(() => {
			loadLogs(filterDate())
		}, 3000)
		
		return () => {
			clearInterval(timerInterval)
		} */
	}, [])

	useEffect(() => {
		$('#contentBody').scrollspy({ target: '#navTopHistory' })
	})

	useEffect(() => {
		console.log("historyQty", historyQty);
		
		if(document.querySelector("#ctnChart") && historyQty.res){

			let dataValue = [];
			let dataQty = [];
			let dataMsrpValue = [];
			let brandList = [];
			let dataColor = [];

			Object.keys(historyQty.res).forEach((brandName, index) => {

				brandList.push(brandName || "No brand")

				let sold = 0;
				let cost = 0.0;
				let map_value = 0.0;
				let color = null;
				for (const idItem in historyQty.res[brandName]) {
					if (historyQty.res[brandName].hasOwnProperty(idItem)) {
						const dataItem = historyQty.res[brandName][idItem];

						color = dataItem[Object.keys(dataItem)[0]].color;
						if(color){
							let exaCode = color.substr(1)
							let code1 = parseInt(exaCode.substr(0, 2), 16)
							let code2 = parseInt(exaCode.substr(2, 2), 16)
							let code3 = parseInt(exaCode.substr(4, 2), 16)
	
							color = `rgba(${code1}, ${code2}, ${code3})`
						}

						for (const date in dataItem) {
							if (dataItem.hasOwnProperty(date)) {
								const dataDate = dataItem[date];
								if(dataDate.sold > 0 && dataDate.cost){
									sold += dataDate.sold
									cost += dataDate.cost * dataDate.sold
									map_value += dataDate.map_price * dataDate.sold

									/* if(brandName.toLowerCase() === 'wiha tools')
										console.log(idItem + " > " + dataDate.cost + " * " +  dataDate.sold); */
								}
							}
						}
					}
				}
				dataColor.push(color)
				dataValue.push(cost.toFixed(2))
				dataQty.push(sold)
				dataMsrpValue.push(map_value.toFixed(2))
			})

			for (let index = 0; index < brandList.length; index++) {
				if(dataValue[index] == 0 && dataMsrpValue[index] == 0 && dataQty[index] == 0){
					dataColor.splice(index, 1);
					brandList.splice(index, 1);
					dataValue.splice(index, 1);
					dataMsrpValue.splice(index, 1);
					dataQty.splice(index, 1);
					index--;
				}else
					console.warn(dataValue[index] + " - " +  dataMsrpValue[index] + " - " +  dataQty[index]);
					
			}

			/* console.log("brandList", brandList);
			console.log("dataValue", dataValue);
			console.log("dataQty", dataQty);
			console.log("dataMsrpValue", dataMsrpValue);
			console.log("dataColor", dataColor); */


			
			chart = new Chart(document.querySelector("#ctnChart"), {
				// The type of chart we want to create
				type: 'bar',
				// The data for our dataset
				data: {
					labels: brandList,
					datasets: [{
						label: 'Value',
						data: dataValue,
						backgroundColor: dataColor,
						//borderColor: 'rgba(54, 162, 235, 1)',
						borderWidth: 1
					}, {
						label: 'Qty',
						data: dataQty,
						backgroundColor: dataColor,
						//borderColor: 'rgba(153, 102, 255, 1)',
						borderWidth: 1
					}, {
						label: '~ MAP generated',
						data: dataMsrpValue,
						backgroundColor: dataColor,
						//borderColor: 'rgba(153, 102, 255, 1)',
						borderWidth: 1
					}]
				},
			
				// Configuration options go here
				options: {
					//responsive: false,
					aspectRatio: 4
				}
			});

			/* chart.update({});
			chart.resize(); */
		}
	}, [historyQty])

	const filterDate = () => {

	}

	const loadHistoryQty = (args, callback) => {
		fetchHistoryQty(args, (r) => {
			if(r.success){
				if(r.res && Object.keys(r.res).length > 0)
					setHistoryQty({
						"range": r.range,
						"res": r.res
					})
			}else{
				props.info({"error": r.error})
			}
		})
	}

	const fetchHistoryQty = (args, callback) => {
		// console.log("TEST", filterDate());
		
		ajaxPerso( Object.assign({
			"trigger": "get_history_qty",
			"api": "finale",
			/* "dateFrom": moment(from).format("YYYY-MM-DD"),
			"dateTo": moment(to).format("YYYY-MM-DD"), */
			"sort": "itemId",
		}, args), callback);
	}
	
	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage container d-flex flex-column flex-wrap">
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark bg-light mb-5 sticky-top">
				<div className="collapse navbar-collapse" id="navbarText">
					<ul id="navTopHistory" className="navbar-nav mr-auto">
						<li className="nav-item">
							<a className="nav-link" href="#decreasingSells">Decreasing sells <span className="sr-only">(current)</span></a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#bestSelling">Best selling</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#worstSelling">Worst selling</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="#dailyValueHistory">Daily Inventory Value</a>
						</li>
					</ul>
				</div>
			</nav>
			<div id="decreasingSells">
				<DecreasingSells
					{...props}
					className="mb-5"
				/>
			</div>
			<div id="bestSelling">
				<BestSelling
					{...props}
					className="mb-5"
				/>
			</div>
			<div id="worstSelling">
				<WorstSelling
					{...props}
					className="mb-5"
				/>
			</div>
			<div id="dailyValueHistory">
				<DailyValueInventory
					{...props}
					className="mb-5"
				/>
			</div>
			<h6 className="position-fixed text-secondary" style={{right: "15px", bottom: "0px"}}>v{version}</h6>
		</div>
	);
}

export default HistorySells;