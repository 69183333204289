// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { ajaxPerso } from '../../../fnc'
import ShopifySearchProduct from '../ShopifySearchProduct';
import ModalSpecialPromo from '../ModalSpecialPromoV2';
import moment from 'moment';

/* var initialState = {
	_isMounted: false,
	promoList: [],
	showEnded: false
} */

// const refConst = require("../../../constants.js").v
var _isMounted = false

const initialState = {
	newSpecialPromoPageItem: null,
	currentSpecialPageInfo: null,
	refreshView: 0,
	version: 1.0,
};

export default function SpecialPromoPage(props){

	const [newSpecialPromoPageItem, setNewSpecialPromoPageItem] = useState(initialState.newSpecialPromoPageItem);
	const [currentSpecialPageInfo, setCurrentSpecialPageInfo] = useState(initialState.currentSpecialPageInfo);
	const [refreshView, setRefreshView] = useState(initialState.refreshView);
	const [version] = useState(initialState.version);

	useEffect(() => {
		_isMounted = true;

		fetchCurrentSpecialPageItem()

		return () => _isMounted = false
	}, [])

	useEffect(() => {
		console.info('propsssss', props);
		
		triggerModal(true);

	}, [currentSpecialPageInfo, newSpecialPromoPageItem, refreshView])

	useEffect(() => {
		
		setNewSpecialPromoPageItem(initialState.newSpecialPromoPageItem)
		setCurrentSpecialPageInfo(initialState.currentSpecialPageInfo)

		fetchCurrentSpecialPageItem()

	}, [props.shopSelected.id])

	const fetchCurrentSpecialPageItem = (callback) => {

		if(!props.shopSelected || !props.shopSelected.id)	return;
		
		props.loading(true)

		ajaxPerso( {
			"api": "shopify",
			"trigger": "get_special_promo_page_items",
			"method": "GET",
			"shop": props.shopSelected.id,
		}, (res) => {
			console.info("Triggered 'get_special_promo_page_items':", res);
			props.loading(false)
			if(res.success){
				if(callback)	callback()
				setCurrentSpecialPageInfo(res.data)
			}else{
				props.info({error: res.errors})
			}
		})
	}

	const executeCleaning = (args, messageSuccess) => {

		/**
		 * This fnc will clean any handle that has no product discount from the meta that contain all the product for our special brand page
		 */

		props.loading(true)

		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "specialPromoDesc_cleanup",
			"method": "GET",
			"shop": props.shopSelected.id,
		}, args), (res) => {
			console.info("Triggered 'get_special_promo_page_items':", res);
			props.loading(false)
			if(res.data.executed){
				fetchCurrentSpecialPageItem(() => {
					props.info({success: messageSuccess? messageSuccess : "Old Items Removed"})
				})
			}else{
				props.info({error: res.errors})
			}
		})
	}

	let triggerModal = (refresh) => {
		
		props.modal({
			key: "Special Promo",
			show: refresh? false : true,
			title: <span>Special Promo <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			html: (popup) => {

				return <div>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						{
							currentSpecialPageInfo? <table className="table">
								<thead>
									<tr>
										<td>ID</td>
										<td>Title</td>
										<td>Options</td>
									</tr>
								</thead>
								<tbody>
									{
										currentSpecialPageInfo.db_result.map((res, pos) => {
											return <tr key={`${res.id}_currentSpecialPageInfo`}>
												<td>{res.id_product_shopify}</td>
												<td>{res.title}</td>
												<td>
													<button className="btn btn-danger" onClick={() => {
														currentSpecialPageInfo.db_result.splice(pos, 1)
														setRefreshView(refreshView+1)
													}}>Remove</button>
												</td>
											</tr>
										})
									}
								</tbody>
							</table> : ""
						}
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}, 
			ok: {
				title: "Save Changes",
				fnc: (popup, close) => {

					let listhandles = currentSpecialPageInfo.db_result.map(o => {
						return o.handle
					})

					executeCleaning({
						"handleList": listhandles.join(",")
					})
				}
			},
			return: {
				title: !props.promoMetaObj? "Close" : "Remove Promo",
				fnc: (popup, close) => {
					if(!props.promoMetaObj){
						setSubmited(true)
						props.setPromoMetaObj(null)
					}
					close()
				}
			}
		});
	}

	const addItemToSpecialPage = () => {

		if(!newSpecialPromoPageItem){
			props.info({error: "Please select a product."})
			return
		}

		let handles = currentSpecialPageInfo.db_result.map((res, pos) => {
			return res.handle
		})

		handles.push(newSpecialPromoPageItem.handle)

		console.info("handles", handles);
		console.info("handles to add", newSpecialPromoPageItem.handle);

		executeCleaning({
				"handleList": handles.join(",")
			},
			"Page updated"
		)
	}

	console.info("currentSpecialPageInfo", currentSpecialPageInfo);
		
	return (<div className={("className" in props? props.className : "")}>
		<div className="border radius mb-4">
			<span className="position-relative bg-white pl-2 pr-2" style={{bottom: "14px", left: "4px"}}>Special promo page</span>
			<div className="d-flex align-items-center justify-content-between pb-3 pl-3 pr-3">
				<div className="d-flex flex-row align-items-center">
					<div className={(newSpecialPromoPageItem? "border" : "") + " p-3"}>
						{
							newSpecialPromoPageItem? <div className="pb-3 smallText">
								<div className="font-weight-bold">Item selected:</div>
								<div className="d-flex flex-column ml-1 smallText text-wrap" style={{maxWidth: "600px"}}>
									<div><span className="d-inline-block font-weight-bold" style={{minWidth: "80px"}}>sku:</span><span>{newSpecialPromoPageItem.sku}</span></div>
									<div><span className="d-inline-block font-weight-bold" style={{minWidth: "80px"}}>Title:</span>{newSpecialPromoPageItem.title}</div>
									<div><span className="d-inline-block font-weight-bold" style={{minWidth: "80px"}}>Handle:</span>{newSpecialPromoPageItem.handle}</div>
								</div>
							</div> : ""
						}
						<ShopifySearchProduct
							{...props}
							className={"btn bg-primary text-white"}
							shopId={props.shopSelected.id}
							title={"Select a product"}
							itemSelected={newSpecialPromoPageItem? newSpecialPromoPageItem.id_variant_shopify : null}
							updateItem={(item, close) => {
								console.info("idItemidItem", item);
								setNewSpecialPromoPageItem(item)
								setRefreshView(refreshView+1)
							}}
						/>
						{newSpecialPromoPageItem? <button type="button" className="btn btn-success ml-3" title="Create" onClick={addItemToSpecialPage}>Submit</button> : ""}
					</div>
					
					<button onClick={() => {
						triggerModal()
					}} className="btn btn-info ml-3">See Currrent Items [{currentSpecialPageInfo? currentSpecialPageInfo.meta_value.length : 0}]</button>
					{
						currentSpecialPageInfo && currentSpecialPageInfo.meta_value.length > 1? <button onClick={() => {
							executeCleaning()
						}} className="btn btn-warning ml-3">Cleanup old items</button> : ""
					}
				</div>
			</div>
		</div>
	</div>);
}