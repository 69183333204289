// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { ajaxPerso, fetchMenu, menuOrganized, fetchDataSet, clipboard, diff } from '../../../fnc'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
// import logoStoreAmazon from '../../img/amazon_circle_perso.png';

var initialState = {
	_isMounted: false,
	categoriesStoreTo: [],
	dataSet: [],
}

const refConst = require("../../../constants.js").v

/* refConst.styleList.control = (styles) => {
	return { ...styles, backgroundColor: 'white' }
} */
/* refConst.styleList.option = (styles, { data, isDisabled, isFocused, isSelected }) => {
	console.info("datadata", data);
  return {
	...styles,
	backgroundColor: data.color,
	color: isDisabled? '#ccc' : isSelected? data.color : "#FFF",
	cursor: isDisabled ? 'not-allowed' : 'default',

	':active': {
	  ...styles[':active'],
	  backgroundColor: data.color
	},
  };
} */
refConst.styleList.multiValueLabel = (styles, { data }) => ({
    ...styles,
    color: data.color,
})
refConst.styleList.multiValue = (styles, { data }) => {
	// console.info("datadata", data);
	return {
		...styles,
		backgroundColor: data.background,
		color: data.color,
	}
}
function ListView(props){

	const [categoriesStoreTo, setCategoriesStoreTo] = useState(initialState.categoriesStoreTo);
	const [dataSet, setDataSet] = useState(initialState.dataSet);
	const [updateView, setUpdateView] = useState(0);
	const [storeListForCreation, setStoreListForCreation] = useState([]);
	const [refreshView, setRefreshView] = useState(0);

	/* useEffect(() => {
		
		console.info('props.autoCreateProduct', props.autoCreateProduct);
		
		if(props.autoCreateProduct.length > 0 && props.autoCreateProduct[0].packQtyToCreate.length === 1){
			props.autoCreateProduct[0].packQtyToCreate = [{
				active: true,
				isMainSku: true,
				qty: null, // Always null when it's the main product
				sellPrice: 122,
				storesToAvoid: []
			}, {
				active: true,
				qty: 22,
				sellPrice: 500,
				storesToAvoid: []
			}]
			props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
		}
	}, [props.autoCreateProduct]) */

	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;

		init()
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		
		if(refConst.stores.length > 0)
			setStoreListForCreation(getFullStoreList())
		
		return () => initialState._isMounted = false
	}, [refConst.stores])

	const init = (e, o) => {
		fetchMenu( {
			"shop": refConst.id_store_eio
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched Categories for " + refConst.id_store_eio, r);
				let results = r.data
				let menu = menuOrganized(results)
	
				setCategoriesStoreTo(menu)
			}
		});

		fetchDataSet({
			"shop": refConst.id_store_eio,
			"level": 2,
			"justData": true
		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched dataset for " + refConst.id_store_eio, r);
				setDataSet(r.data)
			}
		})
	}

	const submitQtyPack = (e, o, pricesPerType) => {
		e.preventDefault()

		let qtyNode = document.getElementById(`${o.id}_button-form-input`)
		let unitSoldAsNode = document.getElementById(`${o.id}_select-form-input`)

		
		let qtySelected = parseInt(qtyNode.value) > 0? parseInt(qtyNode.value) : unitSoldAsNode.value

		if(qtySelected) {
			// We make sure that this qty do not already exist.
			let found = o.variants.find(pqc => {
				if(pqc.qty == qtySelected) return pqc
			})
			// We need to find the next "Unit sold as" and use it as QTY
			/* let listPricingData = o.api_price_result.pricingData.map(pd => {
				return pd.unit.name.toLowerCase()
			})
			console.info("listPricingData", listPricingData);
			// We need to get the last element that match a pricing data.
			let lastPosFound = null;
			o.variants.forEach(v => {
				let posFound = listPricingData.indexOf(v.qty)
				if(posFound !== -1){
					lastPosFound = posFound
				}
			})
			console.info("posfound:", lastPosFound);
			*/
			console.info(`submitQtyPack:`, found);
			if(!found)
				o.variants.push({
                    // "id_variant": null,
                    "qty": qtySelected,
                    "selling_price": null,
					"active": true,
                    "createStatus": {
                        "created": null,
                        "msg": null,
                        "date_Created": null
                    },
                    "sku": `${o.sku}_${qtySelected}`,
                    "storeWanted": props.storeWantedForOurBrand
                })

			// Prefile the price in case there is a price option
			if(parseInt(qtyNode.value) === 0 || isNaN(parseInt(qtyNode.value)) && qtySelected){

				o.variants[o.variants.length-1].default_pricing_type = qtySelected

				updateVariantsDefaultPrices(
					pricesPerType
					, parseFloat(pricesPerType[qtySelected].listPrice)
					, parseFloat(pricesPerType[qtySelected].listPrice)
					, o.variants[o.variants.length-1]
					, false
				)
			}

			qtyNode.value = ''
			setRefreshView(refreshView+1)
		}
	}

	const getFullStoreList = (e, o) => {
		/**
			List all store accepted for creation
		 */
		let idWanted = [
			refConst.id_store_finale,
			refConst.id_store_hausfTools,
			refConst.id_store_eio,
			refConst.id_store_eBay_EIO,
		]
		return refConst.stores.filter(s => {
			if(idWanted.indexOf(s.id) !== -1)	return s
			return false
		})
	}

	const submitAutoCreateComplete = (data, callback) => {
		// props.loading(true);

		console.info("Submit", JSON.stringify(data));
		return

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "autoCreate_complete_data",
			"uid": props.getUser().uid,
			"data": JSON.stringify(data)
		}, callback);
	}

	const updateVariantsDefaultPrices = (pricesPerType, currentDefaultPrice, newPrice, variant, isUpdate) => {

		if(newPrice){
			let changedSomething = false

			console.info("changedSomething--", `${currentDefaultPrice} - ${variant.selling_price} - ${newPrice}`);

			if(currentDefaultPrice == variant.selling_price && currentDefaultPrice != newPrice || !variant.selling_price){
				changedSomething = true
				variant.selling_price = newPrice
				console.info("new value ", newPrice);
			}

			console.info("changedSomething", changedSomething);
			console.info("changedSomething2", variant);
			console.info("changedSomething3", newPrice);
			console.info("changedSomething4", pricesPerType);
			// debugger
			if(changedSomething || isUpdate)
				setRefreshView(refreshView+1)
		}
	}

	const filterValidData = () => {

		console.info("filterValidData", props.autoCreateProduct);

		let cp = JSON.parse(JSON.stringify(props.autoCreateProduct))
		let dataToSend = cp.filter(o => {
			o.variants = o.variants.filter(pqc => {
				if(pqc.active && pqc.selling_price > 0) {
					return pqc
				}
			})
			if(o.variants && o.variants.length > 0)
				return o
			return false
		})

		return dataToSend
	}

	const viewListMeta = () => {
 
		let storeListIdToName = {}
		props.storeInfo.forEach(s => {
			storeListIdToName[s.storeId] = s.name;
		})

		let output = <div className="w-100">
			<div>
				Total: {props.autoCreate.length}
			</div>
			<div className="list-group overflow-auto">
				<table className="table" style={{marginBottom: "100px"}}>
					<thead>
						<tr>
							<th className="position-sticky">id</th>
							{/* <th className="position-sticky text-center">
								<div className="d-flex align-items-center">
									{
										props.storeInfo.map(oP => {
											return <div key={`${oP.prop}_storeProp`} className={'text-center d-flex align-items-center justify-content-center'}>
												<img src={oP.img} className={''} style={{width: "25px", height: "25px"}}/>
											</div>
										})
									}
								</div>
							</th> */}
							{/* <th className="position-sticky">vendor</th> */}
							<th className="position-sticky">info</th>
							{/* <th className="position-sticky text-center">Category</th> */}
							{/* <th className="position-sticky text-center">Add Pack Qty</th> */}
							{/* <th>mapping table</th> */}
						</tr>
					</thead>
					<tbody>
						{
							props.autoCreateProduct.map((o, posAcp)=> {

								console.info("autoCreateProduct ["+o.sku+"]", o);

								let autoC = props.autoCreate.find(a => {
									if(a.id == o.id_auto_creator) return a
								})

								if(!autoC)	return false;

								console.info('o', o);
								console.info('autoC', autoC);

								let collectionValue = null,
									datasetValue = null

								if(o.collection_shopify_eio)
									categoriesStoreTo.find(cs => {
										if(cs.last.name == o.collection_shopify_eio){
											collectionValue = {
												value: cs.last.name,
												label: cs.inline
											}
										}
									})

								if(o.dataset_shopify_eio)
									dataSet.find(dt => {
										if(parseInt(dt.id) == o.dataset_shopify_eio){
											datasetValue = {
												value: parseInt(dt.id),
												label: dt.title
											}
										}
									})


								let pricesPerType = {}, // Just for faster access to the prices.
									pricingOptions = o.api_price_result.pricingData.map(pd => {
										pricesPerType[pd.unit.name.toLowerCase()] = pd.currentPrice
										return {
											value: pd.unit.name.toLowerCase(),
											label: `[${pd.unit.name}] net: $${parseFloat(pd.currentPrice.netPrice).toFixed(2)}, list: $${parseFloat(pd.currentPrice.listPrice).toFixed(2)}`
										}
									})

								if(o.variants.length > 0 && !("default_pricing_type" in o.variants[0])){
									o.variants[0].default_pricing_type = o.default_pricing_type
								}

								/**
								 * Filtered list of price option for the "Add variant list"
								 */
								let listQtyAlreadyIn = o.variants.map(v => {
									return v.qty
								})

								let listPriceOpAvailable = diff(Object.keys(pricesPerType), listQtyAlreadyIn)
								
								pricingOptions.some((po) => {
									let posFound = listPriceOpAvailable.indexOf(po.value)
									if(posFound !== -1){
										listPriceOpAvailable[posFound] = po
									}
								})
								/****************************************************** */

								console.info("listPriceOpAvailable", listPriceOpAvailable);
								console.info("listQtyAlreadyIn", listQtyAlreadyIn);
								console.info("pricesPerType", pricesPerType);
								console.info("pricingOptions", pricingOptions);
								if(o.variants.length > 0)
									console.info("o.variants[0].default_pricing_type", o.variants[0].default_pricing_type);

								// Prefil the first 
								if(o.variants.length > 0 && o.variants[0].selling_price === null)
									updateVariantsDefaultPrices(
										pricesPerType
										, parseFloat(pricesPerType[o.variants[0].default_pricing_type].listPrice)
										, parseFloat(pricesPerType[o.variants[0].default_pricing_type].listPrice)
										, o.variants[0]
										, false
									)

								return <tr key={`${o.id}_ctn_list_autoCreate`} style={{borderBottom: "2px solid orange"}}>
									<td>{o.id}</td>
									{/* <td>
										<div className="cubeStoreCreation">
											<div className="d-flex align-items-center">
												{
													props.storeInfo.map(oP => {
														return <div key={`${oP.prop}_storeProp`} className={'text-center d-flex align-items-center justify-content-center'}>
															<FontAwesomeIcon icon={faCheckCircle} className={o[oP.prop]? '' : ''} style={{width: "25px", height: "25px"}} />
														</div>
													})
												}
											</div>
										</div>
									</td> */}
									{/* <td>
										{autoC.vendor}
										<div className="d-flex align-items-center justify-content-center">
											<input className={(o.s? `d-none` : ``) + ''} type="checkbox" defaultChecked={o.do_not_create? false : true} style={{width: "25px", height: "25px"}} onChange={(e) => {
												console.info('e', e.target.checked);
												o.do_not_create = !e.target.checked // do_not_create is a property from our SQL table
												props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
											}}/>
										</div>
									</td> */}
									<td>
										<div className="d-flex flex-row mt-2">
											<div className="rounded p-1 middeSizeText w-50">
												<div>
													<span className="font-weight-bold mr-3">Manufacturer:</span>
													{autoC.vendor}
													<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(`${autoC.vendor}`, props)}}/></button>
												</div>
												<div>
													<span className="font-weight-bold mr-3">Sku:</span>
													{o.sku}
													<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(`${o.sku}`, props)}}/></button>
												</div>
												<div>
													<span className="font-weight-bold mr-3">Title:</span>
													{autoC.main_prop_mapping.title? o.product_feed_data[autoC.main_prop_mapping.title] : ""}
												</div>
											</div>
											<div className="w-50">
												
											</div>
										</div>
										<div className="smallText mb-3">
											{/* <div className="d-flex align-items-center p-2">
												<input id={`${o.id}_label_sku`} className={''} type="checkbox" defaultChecked={true} style={{width: "25px", height: "25px"}} onChange={(e) => {
													console.info('e', e.target.checked);
													// o.packQtyToCreate[s.storeId] = true
													props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
												}}/>
												<label htmlFor={`${o.id}_label_sku`} className="m-0 ml-2 noselect">{autoC.prefix + o.product_feed_data[autoC.sku_prop_name]}</label>
											</div> */}
											<div>
												{(() => {
													console.info('o.variants', o.variants);
													
													let varNodeList = o.variants.map((v, keyVar) => {


														/* let listStoreNeededToCreate = o.presenceCheckedResult? v.storeWanted.filter(x => !o.presenceCheckedResult.includes(x)) : [];
														console.info("listStoreNeededToCreate", listStoreNeededToCreate);
														console.info("v.storeWanted", v.storeWanted); */

														// if(v.storeWanted.length === 0)	return ""

														let listSelected = [];
														v.storeWanted.forEach(idStoreWanted => {
															if(idStoreWanted)
																listSelected.push({
																	value: idStoreWanted,
																	label: storeListIdToName[idStoreWanted],
																	// background: Math.round(Math.random())? "#13DA25" : "#FF5733",
																	background: "#e18956",
																	color: "#FFFFFF"
																})
														})

														// If no store were selected yet, we need to fill up the variant with all the stores possible to create.
														/* if(listSelected.length === 0){
															props.storeInfo.forEach(validStore => {
																listSelected.push({
																	"value": validStore.storeId,
																	"label": validStore.name
																})
															})
														} */

														console.info("listSelectedlistSelected", listSelected);
														
														let foundPO = pricingOptions.filter((pO) => {
															console.info("SSAAA", pO);
															if(pO.value == v.default_pricing_type)	return pO
														})
														
														console.info("foundPOfoundPO", foundPO);

														return <div key={`${v.qty}_${o.id}_qty_pack`} className="mt-3 border p-3">
															
															<div className="d-flex align-items-center ">

																<div className="input-group">
																	<div className="input-group-prepend">
																		<span className="input-group-text" id="basic-addon-buy-title">Unit Sold As</span>
																	</div>
																	<div style={{width: "75%"}}>
																		<Select
																			styles={Object.assign(
																				refConst.styleList, {
																					container: (provided) => ({
																						...provided,
																						width: "100%",
																						minWidth: "250px"
																					})
																				})}
																			onChange={(selectedOption) => { 
																				if(selectedOption.value != v.default_pricing_type){
																					console.info('selectedOption', selectedOption);

																					if(listQtyAlreadyIn.indexOf(selectedOption.value) !== -1)	return
																					
																					updateVariantsDefaultPrices(
																						pricesPerType
																						, parseFloat(pricesPerType[v.default_pricing_type].listPrice)
																						, parseFloat(pricesPerType[selectedOption.value].listPrice)
																						, v
																						, true
																					)
																					v.default_pricing_type = selectedOption.value
																				}
																			}}
																			options={(() => {
																				return pricingOptions
																			})()}
																			value={foundPO.length > 0? foundPO[0] : null}
																			placeholder={"Select Selling Type"}
																			isSearchable={true}
																			isMulti={false}
																		/>
																	</div>
																</div>
																{<Select
																	isClearable
																	styles={refConst.styleList}
																	onChange={(selectedOption) => {

																		console.info('selectedOption', selectedOption);

																		if(!selectedOption)	selectedOption = []

																		// Make sure that Finale is always listed.
																		let finaleFound = selectedOption.find(sO => {
																			if(sO.value == refConst.id_store_finale)	return sO
																		})

																		if(!finaleFound){
																			selectedOption.push({
																				value: refConst.id_store_finale,
																				label: `Finale`
																			})
																		}

																		if(selectedOption.length === 0)
																			v.storeWanted = [refConst.id_store_finale]
																		else{
																			v.storeWanted = selectedOption.map(sO => {
																				return sO.value
																			})
																		}

																		v.storeWanted.sort()

																		setRefreshView(refreshView+1)
																	}}
																	options={(() => {
																		return props.storeInfo.filter(sF => {
																			// if(v.isMainSku || sF.storeId != 5)	return sF
																			if(v.storeWanted.indexOf(sF.storeId) != -1)	return sF
																		}).map(sObj => {
																			return {
																				value: sObj.storeId,
																				label: `${sObj.name}`
																			}
																		})
																	})()}
																	value={listSelected}
																	placeholder={"Store To Create"}
																	isSearchable={true}
																	isMulti={true}
																/>}
															</div>
															<div className="d-flex align-items-center mt-3">
																<div className="input-group" style={{width: "250px"}}>
																	<div className="input-group-prepend">
																		<span className="input-group-text" id="basic-addon2">Price</span>
																	</div>
																	<input type="number" className="form-control" value={v.selling_price? v.selling_price : ""} placeholder="Sell Price" onChange={e => {
																		v.selling_price = parseFloat(e.target.value)
																		setRefreshView(refreshView+1)
																	}}/>
																</div>
																<input id={`${keyVar}_label_sku_generated`} className={'ml-3'} type="checkbox" checked={v? v.active : false} style={{width: "25px", height: "25px"}} onChange={(e) => {
																	console.info('e', e.target.checked);
																	console.info('packQtyToCreate', v);
																	v.active = e.target.checked? true : false
																	setRefreshView(refreshView+1)
																}}/>
																<label htmlFor={`${keyVar}_label_sku_generated`} className="m-0 ml-2 noselect" style={{fontSize: "16px"}}>
																	{o.sku}{v.qty? '_' : ''}{v.qty}
																</label>
																<button type="button" className="ml-2 btn btn-sm btn-dark pointer text-light" data-toggle="tooltip" title="Copy the value in your clipboard">
																	<FontAwesomeIcon className="" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(`${o.sku}${v.qty? '_' : ''}${v.qty}`, props)}}/>
																</button>
																<button type="button" className="ml-2 btn btn-danger btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Remove this variant" onClick={() => {
																	o.variants.splice(keyVar, 1)
																	setRefreshView(refreshView+1)
																}}>
																	<FontAwesomeIcon className="" icon={faTrashAlt} />
																</button>
															</div>
														</div>
													})

													varNodeList.push(
														<div key={`${o.id}_section_new_pack`} className="mt-3 border p-3 position-relative">
															
															<div className="position-absolute d-flex align-items-center" style={{top: "-14px", backgroundColor: "white"}}>
																<FontAwesomeIcon icon={faPlusSquare} style={{backgroundColor: "white", color: "rgb(212, 212, 212)", fontSize: "25px"}}/>
																<span className="ml-2">New variant</span>
															</div>
															
															<form onSubmit={(e) => {
																submitQtyPack(e, o, pricesPerType)
															}} className="d-flex justify-content-between">
																<div className="input-group w-100 mr-2">
																	<div className="input-group-prepend">
																		<span className="input-group-text" id="basic-addon-buy-title">Add a new pack quantity</span>
																	</div>
																	<input type="number" id={`${o.id}_button-form-input`} className="form-control" placeholder={"Qty " + (listPriceOpAvailable.length === 0? "" : "(Optional)")} aria-label="Recipient's username" aria-describedby={`${o.id}_button-form-pack`}/>
																	<select
																		id={`${o.id}_select-form-input`}
																		className={"form-control " + (listPriceOpAvailable.length === 0? "d-none" : "")}
																		placeholder={"Select Selling Type"}
																		onChange={(e) => { 

																			let value = e.currentTarget.value
																			console.info('selectedOption_value', value);
																			
																		}}>
																			{
																				[{
																					value: null,
																					label: "-"
																				}].concat(listPriceOpAvailable).map(po => {
																					return <option key={`${po.value}_${o.id}`} value={po.value}>{po.label}</option>
																				})
																			}
																		</select>
																	<div className="input-group-append">
																		<button type="submit" className="btn btn-outline-secondary" id={`${o.id}_button-form-pack`}>Add</button>
																	</div>
																</div>
															</form>
														</div>)
													
													return varNodeList
												})()}
											</div>
										</div>
									</td>
									{/* <td className="text-center">
										<Select
											isClearable
											styles={refConst.styleList}
											className="ml-3 text-left"
											onChange={(selectedOption) => {

												o.collection_shopify_eio = selectedOption? selectedOption.value : null
												setUpdateView(updateView+1)

												console.info("TESTTT:", o.product_feed_data);
												console.info("TESTTT1:", autoC.category_prop_name);
												console.info("TESTTT2:", selectedOption.value);
												console.info("TESTTT3:", o.product_feed_data[autoC.category_prop_name]);

												if(o.product_feed_data && autoC.category_prop_name in o.product_feed_data)
													props.submitAutoCreatorMemory(autoC.id, o.product_feed_data[autoC.category_prop_name], selectedOption.value, "category", (r) => {
														console.info("Response submitAutoCreatorMemory:", r);
														if(r.error){
															props.info({error: r.error})
														}else{
															console.info("submitAutoCreatorMemory `category` success.");
														}
													})
											}}
											options={(() => {
												let valueReturn = []
												categoriesStoreTo.forEach(m => {
													valueReturn.push( {
														value: m.last.name,
														label: m.inline
													})
												})
												return valueReturn
											})()}
											value={collectionValue}
											isSearchable={true}
											isMulti={false}
											placeholder={"Master Collection EIO"}
										/>

										<Select
											isClearable
											styles={refConst.styleList}
											className="ml-3 mt-3 text-left"
											onChange={(selectedOption) => {
												o.dataset_shopify_eio = selectedOption? selectedOption.value : null
												setUpdateView(updateView+1)

												if(o.product_feed_data && autoC.category_prop_name in o.product_feed_data)
													props.submitAutoCreatorMemory(autoC.id, o.product_feed_data[autoC.category_prop_name], selectedOption.value, "dataset", (r) => {
														console.info("Response submitAutoCreatorMemory:", r);
														if(r.error){
															props.info({error: r.error})
														}else{
															console.info("submitAutoCreatorMemory `dataset` success.");
														}
													})
											}}
											options={(() => {
												return dataSet.map(dt => {
													return {
														value: parseInt(dt.id),
														label: dt.title
													}
												})
											})()}
											value={datasetValue}
											isSearchable={true}
											isMulti={false}
											placeholder={"Level 2 Dataset EIO"}
										/>
									</td> */}
									{/* <td>
										<div>
											<form onSubmit={(e) => {
												submitQtyPack(e, o)
											}}>
												<div className="input-group" style={{width: "150px"}}>
													<input type="number" id={`${o.id}_button-form-input`} className="form-control" placeholder="Qty" aria-label="Recipient's username" aria-describedby={`${o.id}_button-form-pack`}/>
													<div className="input-group-append">
														<button type="submit" className="btn btn-outline-secondary" id={`${o.id}_button-form-pack`}>Add</button>
													</div>
												</div>
											</form>
										</div>
									</td> */}
									{/* <td><button type="button" className="btn btn-outline-primary">Table</button></td> */}
								</tr>
							})
						}
					</tbody>
				</table>
				<div className="fixed-bottom text-center mb-1" style={{
					height: "1px"
				}}>
					<button type="button" className="bgEio d-flex align-items-center justify-content-center text-center btn btn-lg btn-block d-inline-block text-white mx-auto position-relative" disabled={props.loading()} style={{width: "50%", opacity: '0.8', bottom: "50px"}} onClick={() => {
						
						let dataToSend = filterValidData()

						console.info('Submit_all_data', dataToSend);
						return
							
						submitAutoCreateComplete(dataToSend, (r) => {
							//props.loading(false);

							console.info('Res submitAutoCreateComplete', r);
							
						})
					}}>
						<span>Submit</span>
						<img className="ml-4" style={{height: "35px"}}></img>
					</button>
				</div>
			</div>
		</div>

		return <div className="mt-3">{output}</div>;
	}
		
	return (<div className={("className" in props? props.className : "")}>
		{/* <div className="alert alert-primary w-100 text-wrap mt-4" role="alert">
			Create or Update an A+ content, A+ represents a section on the website that highlights a product per its type and brand.
		</div> */}
		{ viewListMeta() }
	</div>);
}

export default ListView;