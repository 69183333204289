// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import {
	NavLink,
	Redirect
} from 'react-router-dom';
import store from 'store2'; 
import { fetchStores, fetchBrands, uniqueArray } from '../../../fnc'
const refConst = require("../../../constants.js").v


function ListBrand(props){
	const [brand, setBrand] = useState([]);
	const [shopSelected, setShopSelected] = useState(store("storeSelectedBrand") ?? null);
	const [brandSelected, setBrandSelected] = useState(store("brandSelected") ?? null);
	const [stores, setStores] = useState([]);

	useEffect(() => {

		fetchBrands( null, {}, null, (r) => {
			console.info('Fetched brands listBrand:', r);
			props.loading(false)

			if(r.success)
				setBrand(r.data)
		})
		fetchStores((r) => {
			console.info('Fetched Stores:', r);
			if(r.success)
				setStores(r.data)
		})
	}, [])

	useEffect(() => {
		let objPreSelected = store("storeSelectedBrand")
		
		if(objPreSelected)
			setShopSelected(objPreSelected)
	}, [])

	useEffect(() => {
		console.info('shopSelected', shopSelected);
		
	})

	const getItemAfterFilters = () => {
		let items = []
		brand.forEach((obj) => {
			//console.log("obj", obj);
			let shown = true

			console.info("brandSelected", brandSelected);
			console.info("brandSelected2", obj.name);
			
			// Show "no assigned"
			if(shopSelected !== null && !shopSelected && parseInt(obj.id_marketplace))
				shown = false
			// Show item corresponding to the criteria selected
			if(shopSelected !== null && shopSelected && parseInt(obj.id_marketplace) !== shopSelected.id)
				shown = false

			if(brandSelected !== null && brandSelected && obj.name != brandSelected)
				shown = false

			if(shown){
				/* console.log(obj);
				console.log("shopSelected", shopSelected); */
				
				items.push(obj)
			}
		})
		return items
	}

	return (<div className="main ctnPage container">
		<p className="h1 mb-3">Brand Management</p>
		<div className="d-flex flex-row">
			<span><NavLink to={{
				pathname: "/brand",
				search: "?mode=add"
			}}><button type="button" className="btn btn-primary">Add new brand</button></NavLink></span>
			<span className="ml-3"><NavLink to={{
				// pathname: props.parent.match.params.idBrand? `/template/${props.parent.match.params.idBrand}` : '/MappingBrand',
				pathname: '/MappingBrand',
				// search: props.parent.match.params.idBrand? `mode=tmpdatagroup` : ""
			}}><button type="button" className="btn btn-primary">TMP - Group Data</button></NavLink></span>
			<div className="input-group w-50 ml-4">
				<div className="input-group-prepend">
					<span className="input-group-text">Marketplace</span>
				</div>
				<select className="custom-select" value={ JSON.stringify(shopSelected) }  id="exampleFormControlSelect1" onChange={(e) => {
					store("storeSelectedBrand", e.target.value? JSON.parse(e.target.value) : null)
					setShopSelected(e.target.value? JSON.parse(e.target.value) : null)
				}}>
					<option value="">-</option>
					{
						refConst.stores.filter(o => {
							return o;
							/* if(o.id_marketplace == 14)	return o
							return false */
						}).map(obj => {
							return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<div className="input-group w-50 ml-4">
				<div className="input-group-prepend">
					<span className="input-group-text">Brand Name</span>
				</div>
				<select className="custom-select" value={brandSelected} onChange={(e) => {
					store("brandSelected", e.target.value)
					setBrandSelected(e.target.value)
				}}>
					<option value="">-</option>
					{
						uniqueArray(brand.map(o => {
							return o.name
						})).map(name => {
							return <option key={name} value={name}>{name}</option>
						})
					}
				</select>
			</div>
		</div>
		<div>
			<ul className="list-group list-group-flush w-100">
				{
					getItemAfterFilters().map(obj => {
						
						return <li key={obj.id + "_keyBrandList"} className="list-group-item d-flex flex-row align-items-center justify-content-between">
							<span><NavLink to={{
								pathname: "/brand/" + obj.id,
								//search: "?mode=tmpdatagroup"
							}}>{(obj.template_name? obj.template_name + ': ' : '')}<span className="font-weight-bold">{ obj.name }</span></NavLink></span>
							<span>
								{ obj.id_supplier? <span className="badge badge-pill badge-dark mr-3">Supplier ID: { obj.id_supplier }</span>: "" }
								{ obj.prefix? <span className="badge badge-pill badge-info mr-3">{ obj.prefix }</span>: "" }
								{ <span className={"badge-" + (parseInt(obj.is_active)==1? "success" : "warning") + " badge badge-pill mr-3"}>{ parseInt(obj.is_active)? "active" : "inactive" }</span> }
								{ obj.store_name? <span className="badge badge-pill badge-light">{ obj.store_name }</span> : "" }
							</span>
						</li>
					})
				}
			</ul>
		</div>
	</div>);
}

export default ListBrand;