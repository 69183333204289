// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons' */
import {ajaxPerso} from "../fnc.js"
import {
	NavLink,
	Redirect
} from 'react-router-dom';
import store from 'store2';
const refConst = require("../constants.js").v
var refNewForm =  null;

function Shopify_draft(props){

	const [items, setItems] = useState([]);

	if(!refNewForm)
		refNewForm =  React.createRef()

	let objPreSelected = store("storeSelectedDraft")
	const [shopSelected, setShopSelected] = useState(objPreSelected? objPreSelected : refConst.shopList[0]);
	
	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		if(shopSelected){
			fetchItems()
		}
	}, [shopSelected])

	/* useEffect(() => {

		setIdGrpOpen(parseInt(props.parent.match.params.idGrpOpen))
		
	}, [props.parent.match.params.idGrpOpen]) */

	/* useEffect(() => {

		setIdModif(parseInt(props.parent.match.params.idTagOpen))

	}, [props.parent.match.params.idTagOpen]) */

	/* useEffect(() => {

		if(tagRefs[idModif] && tagRefs[idModif].name.current)
			tagRefs[idModif].name.current.focus()

	}, [idModif]) */

	const fetchItems = (callback) => {
		props.loading(true)
		ajaxPerso( {
			"api": "shopify",
			"trigger": "getItems",
			"shop": shopSelected.id,
			"tag": "draft"
		}, (r) => {
			console.info(`Fetched getItems:`, r)
			props.loading(false)
			if(callback)	callback(r.success? r.data : [])
			setItems(r.success? r.data : [])
		})
	}

	const trigger_activation = (id, callback) => {
		props.loading(true)

		ajaxPerso( {
			"api": "shopify",
			"trigger": "activate_product",
			"shop": shopSelected.id,
			"idList": id
		}, (r) => {
			console.info(`Fetched activate_product:`, r)
			props.loading(false)
			if(r.success){
				// fetchItems()
				let idShopifyListToRemove = r.res.data.updated.map(o => {
					return o.id_response
				})
				if(idShopifyListToRemove && idShopifyListToRemove.length > 0){
					/**
					 * Remove the items activated from our item list.
					 * We need to do this instead of reloading the whole items because we need some time to get the update in our DB from the webhook
					 */
					let newItemsList = items.filter(o => {
						if(idShopifyListToRemove.indexOf(o.id_product_shopify) === -1){
							return o;
						}
						return false;
					})
					console.info('Item list updated: ', newItemsList);
					props.info({success: "Updated."})
					if(newItemsList.length != items.length){
						setItems(newItemsList)
					}
				}
			}else{
				props.info({error: r.error})
			}
			if(callback)	callback()
		})
	}


	/* const showTags = () => {

		let grpObj = items.find(o => {
			if(o.id === idGrpOpen)	return o
			return false
		})
		
		if(!grpObj)	return ''

		 return <div className="container w-100">
			<div className="row">
				<div className="col-sm-1 p-3">
					ID
				</div>
				<div className="col-sm-3 p-3">
					Name
				</div>
				<div className="col-md-4 p-3">
					Image Name (shopify admin files)
				</div>
				<div className="col-sm p-3">
					Option
				</div>
			</div>
			{
				grpObj.data? grpObj.data.map((t, i) => {

					if(!tagRefs[t.id]){
						tagRefs[t.id] = {
							"name": React.createRef(),
							"image": React.createRef()
						}
					}
					
					return <form key={`${t.id}_tagNew`} onSubmit={(e) => saveTag(tagRefs[t.id], e)} className="form-group">
						<div className="row">
							<div className="col-sm-1 p-3">
								{t.id}
							</div>
							<div className="col-sm-3 p-3">
								<input className="w-100 form-control" defaultValue={t.name} disabled={idModif === t.id? false : true} id={`input_tag_shopify_${t.id}`} ref={tagRefs[t.id].name}/>
							</div>
							<div className="col-md-4 p-3">
								<input className="w-100 form-control" defaultValue={t.image_name} ref={tagRefs[t.id].image} disabled={idModif === t.id? false : true} id={`input_tag_img_shopify_${t.id}`} />
							</div>
							<div className="col-sm p-3">
								<button type="button" className={(idModif === t.id? "bg-dark text-light" : "btn-primary") + " btn btn-sm"} onClick={(e) => {
									setIdModif(idModif === t.id? null : t.id)
								}}>{idModif === t.id? "Close" : "Modify"}</button>
								{
									idModif === t.id? <button className="bg-danger btn btn-sm ml-3 text-light" onClick={(e) => deleteTag(tagRefs[t.id], e)} disabled={triggered}>Delete</button> : ''
								}
								{
									idModif === t.id? <button type="submit" className="btn-primary btn btn-sm ml-3" disabled={triggered}>Save</button> : ''
								}
							</div>
						</div>
					</form>
				}) : 'Empty'
			}
		</div>
	} */

	/* const modalConfirmation = (callback) => {
		props.modal({
			show: true,
			title: "Confirmation - Reprice",
			html: () => {
				return <div>
					{
						<div className="text-center">
							<p className="text-center text-danger font-weight-bold">Are you sure ?</p>
						</div>
					}
				</div>
			}
			,ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					if(callback)	callback()
					close()
				}
			},
			return: {
				title: "Close"
			}
		})
	} */

	const showItems = () => {
		console.info('shopSelected', shopSelected);
		
		return(
			items.length === 0? <div className="w-100 d-flex justify-content-center">
				<p>No items in draft mode</p>
			</div> : <div className="container w-100">
				{
					<div className="d-flex align-items-center justify-content-cente">
						<button type="button" className="btn btn-success m-auto" onClick={() => {trigger_activation(items.map(o => {
							return o.id
						}).join(','))}}>Accept All</button>
					</div>
				}
				<div className="row">
					<div className="col-md-3 p-3 w-25">
						Vendor
					</div>
					<div className="col-md-3 p-3 w-25">
						SKU
					</div>
					<div className="col-sm-2 p-3 text-center w-25 overflow-auto">
						Title
					</div>
					<div className="col-sm-1 p-3 text-center">
						Price
					</div>
					<div className="col-md-3 p-3 text-center">
						Options
					</div>
				</div>
				{
					items.map(o => {

						return <div key={`${o.id}_tagGrp`} className="row">
							<div className="col-md-3 p-3 w-25">
								{/* <a href={"https://" + shopSelected.account_name + "/products/" + o.handle} target="_blank" rel="noopener noreferrer">{o.vendor}</a> */}
								{/* <button type="button" className="btn btn-primary btn-lg" onClick={() => {modalConfirmation()}}>Modal</button> */}
								<a href={"https://" + shopSelected.account_name + "/admin/products/" + o.id_product_shopify} target="_blank" rel="noopener noreferrer" title="Open on Shopify Admin">{o.vendor}</a>
							</div>
							<div className="col-md-3 p-3 w-25">
								<a href={window.location.origin + "/shopify/" + o.id_product_shopify} target="_blank" rel="noopener noreferrer" className="mr-3" title="Open in Tools Editor">{o.sku}</a>
							</div>
							<div className="col-sm-2 p-3 text-center d-flex align-items-center justify-content-center w-25 overflow-auto">
								{o.title}
							</div>
							<div className="col-sm-1 p-3 text-center d-flex align-items-center justify-content-center">
								${o.price}
							</div>
							<div className="col-md-3 p-3 text-center">
								{/* <button type="button" className="btn btn-danger mr-2">Refuse</button> */}
								<button type="button" className="btn btn-success ml-2" onClick={() => {trigger_activation(o.id)}}>Accept</button>
							</div>
						</div>
					})
				}
			</div>
		)
	}

	if(!props.securityClearance(props))	return "";

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="alert alert-primary text-wrap w-100" role="alert">
				You can click on the vendor name to open a new tab to the Shopify admin page.<br/>
				You can also click on the sku to open the product in our editor.
			</div>
			<div className="input-group mb-4 mt-2">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" defaultValue={ JSON.stringify(shopSelected) } onChange={(e) => {
					store("storeSelectedDraft", JSON.parse(e.target.value))
					setShopSelected(JSON.parse(e.target.value))
				}}>
					{
						refConst.stores.filter(o => {
							if(o.id_marketplace == 14)	return o
							return false
						}).map(obj => {
							return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
						})
					}
				</select>
				<button type="button" className="btn btn-primary ml-4" onClick={() => fetchItems()}>Reload products</button> 
			</div>
			{showItems()}
		</div>
	);
}

export default Shopify_draft;