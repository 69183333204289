import React, { Component } from 'react';
import loading from '../../Rolling.svg';

class Loading extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		
		// Bind Allow access by "this" into the function
		//this.addBrand = this.addBrand.bind(this);
	}
	
	render() {
		
		if(this.props.loading)
			if(this.props.centered)
				return (<div className={(this.props.className? this.props.className : " position-absolute ") + " w-100 h-100 d-flex align-items-center justify-content-center"}>
					<img src={loading} alt="Loading" className="mx-auto" style={this.props.style? this.props.style : {height: "70px"}}></img>
				</div>)
			else
				return (
					<div className={this.props.className? this.props.className : "bg-primary text-white p-3 text-center ctnLoading m-auto"}>
						<img src={loading} alt="Loading" className="mx-auto height45px" style={this.props.style? this.props.style : null}></img>
					</div>
				);
		return ''
	}
}

export default Loading