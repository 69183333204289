// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { fetchStores, ajaxPerso } from '../fnc'
import CreateNew from './ctn/aPlus/CreateNewV2'
import ListView from './ctn/aPlus/ListViewV2'
import {
	NavLink
} from 'react-router-dom';
const refConst = require("../constants.js").v

var initialState = {
	stores: [],
	_isMounted: false
}

function Aplus(props){

	console.info('props', props);
	

	const [aPlusIdList, setaPlusIdList] = useState([]);
	const [aPlusList, setaPlusList] = useState([]);
	const [shopIdSelected, setShopIdSelected] = useState(null);
	const [stores, setStores] = useState([]);
	const [fncName, setFncName] = useState(null);
	const [keyMetaTmp, setKeyMetaTmp] = useState(null);
	const [aPlusIdList_id, setAPlusIdList_id] = useState(null);

	const [history, setHistory] = useState(null);

	useEffect(() => {
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		let fncName = props.parent.match.params.fncName
		let idObjOpen = props.parent.match.params.id

		setFncName(fncName);
		setKeyMetaTmp(idObjOpen);

	}, [props.parent.match.params])

	useEffect(() => {
		if(history){
			modalHistory();
		}
	}, [history])

	useEffect(() => {

		if(!props.securityClearance(props))	return;
		
		fetchStores((r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched Stores:', r);
			if(r.success){
				
				setStores(r.data.filter(o => {
					if(o.id_marketplace == refConst.id_marketplace_shopify)
						return o
				}))
			}
		})
	}, [])

	useEffect(() => {
		if(stores && stores.length > 0){
			setShopIdSelected(stores[0].id)
		}
	}, [stores])

	useEffect(() => {
		
		if(shopIdSelected){
			loadEverything()
		}
	}, [shopIdSelected])

	const loadEverything = (callback) => {
		let loaded = 0,
			cycle = 0;

		loadDbAplus(() => {loaded++});
		// loadAplusIds(() => {loaded++});

		if(callback)	callback();

		/* let intK = setInterval(() => {
			if(loaded === 2 || cycle > 100){
				clearInterval(intK);
			}
			cycle++;
		}, 100) */
	}

	const loadDbAplus = (callback) => {

		fetchDbAplus( (r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched fetchDbAplus:', r);
			props.loading(false)
			setaPlusList(r.data)
		})
	}

	const loadDbAplusHistory = (id_aplus, callback) => {

		fetchDbAplusHistory(id_aplus, (r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched fetchDbAplusHistory:', r);
			props.loading(false)
			setHistory(r.success? r.data : null)
		})
	}

	const modalHistory = (callback) => {

		console.info('history', history);
		

		props.modal({
			show: true,
			title: "Modification History",
			html: (close) => {
				return <div>
					{
						<div className="text-center">
							<table className="table">
								<thead>
									<tr>
										<th scope="col">Date</th>
										<th scope="col">Type</th>
										<th scope="col">User</th>
										<th scope="col">Options</th>
									</tr>
								</thead>
								<tbody>
									{
										history?	history.map(o => {
											let userAccount = props.accounts.find(a => {
												if(a.id == o.id_account)	return a
											})
											let userName = ''
											if(userAccount)
												userName = userAccount.data_firebase.email.substr(0, userAccount.data_firebase.email.indexOf("@"))

											return <tr key={`history_${o.id}`}>
												<td>{o.date_created}</td>
												<td>{o.event}</td>
												<td>{userName}</td>
												<td>
													<NavLink className="ml-3" to={{
														pathname: `/AplusV2/CreateNew/${o.id_aplus}/${o.id}`,
													}}><button type="button" className="btn btn-primary" onClick={() => {
															console.info('KKKKKKKKKKKKKKKKKKK');
															close()
														}}>View</button>
													</NavLink>
												</td>
											</tr>
										}) : ''
									}
								</tbody>
							</table>
						</div>
					}
				</div>
			}
			,/* ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					if(callback)	callback()
					setHistory(null)
					close()
				}
			}, */
			return: {
				title: "Close",
				fnc: (popup, close) => {
					if(callback)	callback()
					setHistory(null)
					close()
				}
			}
		})
	}

	const fetchDbAplus = (callback) => {
		if(!initialState._isMounted)	return false
		props.loading(true);

		ajaxPerso( {
			"api": "shopify",
			"trigger": "get_aplus",
			"id_marketplace": shopIdSelected
		}, callback);
	}

	const fetchDbAplusHistory = (id_aplus, callback) => {
		if(!initialState._isMounted)	return false
		props.loading(true);

		ajaxPerso( {
			"api": "shopify",
			"trigger": "get_aplus_history",
			"id_aplus": id_aplus
		}, callback);
	}

	/* const fetchAplusIds = (callback) => {
		if(!initialState._isMounted)	return false
		props.loading(true);

		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetch_meta",
			"namespace": "aPlus_list",
			"shop": shopIdSelected
		}, callback)
	} */
	
	if(!props.securityClearance(props))	return "";

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" value={ JSON.stringify(shopIdSelected) } onChange={(e) => {
					$('.hasTooltip').tooltip('dispose') // Need to be affective before the rerender of the new form cause nodes will be different.
					setShopIdSelected(JSON.parse(e.target.value)) 
				}}>
					{
						refConst.stores.map(obj => {
							if(obj.id_marketplace == 14)
								return <option key={obj.name} value={obj.id}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<div className="w-100 mt-1">
				{ fncName && fncName.indexOf('Create') !== -1? <CreateNew className=""
					{...props}
					shopIdSelected={shopIdSelected}
					keyMetaTmp={keyMetaTmp}
					aPlusList={aPlusList}
					aPlusIdList={aPlusIdList}
					loadDbAplus={loadDbAplus}
					// loadAplusIds={loadAplusIds}
					loadEverything={loadEverything}
					aPlusIdList_id={aPlusIdList_id}
					history={history}
				/> : <NavLink to={{
					pathname: "/AplusV2/CreateNew",
					// search: "?mode=Create"
				}}><button type="button" className="btn btn-primary">Create</button></NavLink>}

				{ !fncName? <ListView className=""
					{...props}
					shopIdSelected={shopIdSelected}
					keyMetaTmp={keyMetaTmp}
					aPlusList={aPlusList}
					aPlusIdList={aPlusIdList}
					aPlusIdList_id={aPlusIdList_id}
					loadDbAplusHistory={loadDbAplusHistory}
					loadEverything={loadEverything}
				/> : ""}
			</div>
		</div>
	);
}

export default Aplus;