var isDev = false;
export const v = {
	noIpSec: false,
	dev: isDev,
	byPassRecaptcha: false,
	/// urlServer: "https://api.ehub.work/proxy",/** Real case one */
	//// Turned off 12/28/2021 -> urlServer: isDev? "http://localhost/eioapp/proxy.php" : "https://api.ehub.work/proxy",/** Real case one */
	// urlServer: isDev? "https://78360a537d21.ngrok.app/eio/api/task/task_multi.php" : "https://api.ehub.work/task_multi",/** Real case one */
	urlServer: isDev? "http://localhost/eio/api/task/task_multi.php" : "https://api.ehub.work/task_multi",/** Real case one */

	////urlServer: "http://10.10.0.152/eiodev/api/task/task_multi.php",
	//////urlServer: "http://localhost/eio/api/task/task_multi.php",
	//finaleFilePath:"eio/api/task/task_finale.php"
	//urlServer: "http://10.10.0.152/api/",
	walmartApi: "walmart",
	finaleApi: "finale",
	eioApi: "eio",
	IP_EIO: "12.189.90.170",
	tokenEio: "00269b99a9c87d1ea12dd511be3e9f5924c6ddec3ceeecdb3803c4d777c0b37f",
	finaleLinkProduct: "https://app.finaleinventory.com/eio/sc2/?product/detail/",
	eioLinkProduct: "https://eio-com.myshopify.com/admin/products/",
	hausOfToolsLinkProduct: "https://hausoftools.myshopify.com/admin/products/",
	shopify: [
		"eio-com",
		"brandnameapp"
	],
	defaultBrandName: "Default",
	template_id_ebay: 1,
	id_marketplace_shopify: 14,
	id_marketplace_ebay_eio: 144,
	id_marketplace_amazon_eio: 2,
	id_marketplace_walmart: 93,
	id_store_finale: 5,
	id_store_hausfTools: 202487,
	id_store_brandnameapp: 7,
	id_store_walmart_eio: 178963,
	id_store_eio: 205618,
	id_store_eBay_EIO: 222175,
	id_store_amazon_EIO: 133845,
	id_store_amazon_GOTT: 133847,
	stores: [],
	shopList: [{
		name: "eio.com",
		shopName: "eio-com",
		storeId: 205618,
		selected: true
	}, {
		name: "dev",
		storeId: 7,
		shopName: "brandnameapp"
	}, {
		name: "HausOfTools",
		storeId: 202487,
		shopName: "hausoftools"
	},{
		name: "eBay - EIO",
		storeId: 222175,
		shopName: "eBay - EIO"
	},{
		name: "Walmart - EIO",
		storeId: 178963,
		shopName: "Walmart - EIO"
	}],
	styleList: {
		menu: (provided) => ({
			...provided,
			zIndex: 99999,
		}),
		container: (provided) => ({
			...provided,
			width: "auto",
			minWidth: "250px"
	
		}),
		control: provided => ({
			...provided,
			margin: 0,
			marginBottom: 0,
			// borderTopLeftRadius: "4px",
			// borderBottomLeftRadius: "4px"
		})
	}

	/*, sql: {
		server: "localhost",
		user: "root",
		pass: "pass"
	}*/
};