import React, { Component } from 'react';

class Login extends Component {

	constructor(props) {
		super(props)
		
		this.state = {
		}

		this.submitForm = this.submitForm.bind(this);
	}

	/* componentDidMount(){
	} */

	submitForm(e){
		e.preventDefault();
		let state = this.state;
		
		let mail = document.querySelector("#emailLogin").value,
			pass = document.querySelector("#passwordLogin").value

		if(!pass.trim() || !mail.trim()){
			this.props.info({
				error: "Please, try again with different informations."
			})
		}else{
			state.error = null
			this.props.loginData({
				"email": mail,
				"password": pass
			}, () => {
				
			});
		}
		this.setState(state);
	}

	render() {
		let info = this.props.info()
		console.log('info', info);
		
		return (
			<div className="d-flex align-items-center justify-content-center flex-column heiht100">
				<div className={ (info.success? "bg-success " : "bg-danger ") + (!info.success && !info.error? "invisible " : "visible ")  + " p-3 mb-4 text-white"}>{info.error || info.success}</div>
				<div className="container w-25 centerCtn">
					<form onSubmit={this.submitForm}>
						<div className="form-group">
							<label htmlFor="exampleInputEmail1">Email address</label>
							<input onKeyUp={(e) => {e.target.value? this.setState(Object.assign(this.state, {resetPasswordShow: true})) : this.setState(Object.assign(this.state, {resetPasswordShow: false}))}} type="email" className="form-control" id="emailLogin" aria-describedby="emailHelp" placeholder="Enter email" autoComplete="on"></input>
							<small id="emailHelp" className="form-text text-muted">We&apos;ll never share your email with anyone else.</small>
						</div>
						<div className="form-group">
							<label htmlFor="exampleInputPassword1">Password</label>
							<input type="password" className="form-control" id="passwordLogin" placeholder="Password" autoComplete="on"></input>
						</div>
						<div className="d-flex justify-content-between">
							<button type="submit" className="btn btn-primary">Submit</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export default Login;
