import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import SheetCapture from './ctn/SheetCapture';
import $ from 'jquery';
import { utils, writeFile } from 'xlsx';
import Select, { components, createFilter } from 'react-select';
import { capitalizeFLetter, ajaxPerso, roundPrice, socketPerso } from '../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment';
const refConst = require("../constants.js").v

const {info, log, warn} = console
const initialState = {
	dataSheet: null,
	resTrigger: null,
	submited: false,
	isMapReprice: false,
	idTask: null
};


export default function Compare(props){

	const apiProperty = [{
		api: "ebay",
		name: "eBay - EIO",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjVTgFHd2akT44hrjKg", // ebay_eio_write
		]),
		fnc: () => triggerFnc("eBay - EIO", "ebay", { shop: refConst.id_store_eBay_EIO }),
		val: [{
			required: ["sku", "price"]
		}]
	}, {
		api: "shopify",
		name: "EIO.com",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjH_9MuY646871R7lUA", // shopify_eio_write
		]),
		fnc: () => triggerFnc("eio", "shopify", { shop: refConst.id_store_eio }),
		val: [{
			required: ["sku", "price"]
		}]
	}, {
		api: "shopify",
		name: "Haus of Tools",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjVTYrT8L8HguYYrRKE", // shopify_haus_write
		]),
		fnc: () => triggerFnc("hausOfTools", "shopify", { shop: refConst.id_store_hausfTools }),
		val: [{
			required: ["sku", "price"]
		}]
	}, {
		api: "walmart",
		name: "Walmart- EIO",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjWGqGi59xNbduybZHA", // walmart_eio_write
		]),
		fnc: () => triggerFnc("Walmart - EIO", "walmart", { shop: refConst.id_store_walmart_eio }),
		val: [{
			required: ["sku", "price"]
		}]
	}, {
		api: "amazon",
		name: "Amazon - EIO",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjWHEUEG0HJvoZtpe19", // amazon_eio_write
		]),
		fnc: () => triggerFnc("Amazon - EIO", "amazon", { shop: refConst.id_store_amazon_EIO }),
		val: [{
			required: ["sku", "price"]
		}]
	}, {
		api: "amazon",
		name: "Amazon - GOTT",
		hasAccess: props.securityClearance(props, [
			"-MjVxK86GVBUKL5oNU66", // admin_access
			"-MjWHEUEG0HJvoZtpe19", // amazon_eio_write
		]),
		fnc: () => triggerFnc("Amazon - GOTT", "amazon", { shop: refConst.id_store_amazon_GOTT }),
		val: [{
			required: ["sku", "price"]
		}]
	}].filter(o => {
		if(o.hasAccess)	return o
	})

	const [selectedStore, setSelectedStore] = useState(apiProperty.length > 0? apiProperty[0].name : null);
	const [dataSheet, setDataSheet] = useState(initialState.dataSheet);
	const [resTrigger, setResTrigger] = useState(initialState.resTrigger);
	const [submited, setSubmited] = useState(initialState.submited);
	const [isMapReprice, setIsMapReprice] = useState(initialState.isMapReprice);
	const [idTask, setIdTask] = useState(initialState.idTask);

	useEffect(() => {

		socket.on('bulkRepriceEvent', function(idTask){
			console.info("Id Task", idTask);
			setIdTask(idTask)
		})

	}, [])

	useEffect(() => {
		info('dataSheet', dataSheet);
	}, [dataSheet])

	useEffect(() => {
		resetPage()
	}, [selectedStore])

	useEffect(() => {
		props.loading(submited)
	}, [submited])

	useEffect(() => {
		// force file download
		dlDetails();
	}, [resTrigger])

	useEffect(() => {
		$('.tooltipInfoMap').tooltip({
			placement: "right",
			title: "When checked, the prices will be updated only if smaller than your new price (map)."
		}).tooltip('update')
	})

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let dlDetails = () => {
		if(!resTrigger)	return false

		var book  = utils.book_new()

		console.info('api', findStore(selectedStore).api);
		
		switch (findStore(selectedStore).api.toLowerCase()) {
		case "ebay":
			utils.book_append_sheet(book, utils.json_to_sheet(correctReturnedArray(resTrigger.updated)), "Details");
			utils.book_append_sheet(book,  utils.json_to_sheet(correctReturnedArray(resTrigger.notUpdated)), "Errors");
			utils.book_append_sheet(book,  utils.json_to_sheet(resTrigger.dbUpdated), "DB Updated");
			break;
		case "shopify": 
			if(resTrigger.updated.length > 0)
				resTrigger.updated.forEach(o => {
					o.status = [200, 201].indexOf(o.code) !== -1? "success" : "error";
				});
	
			utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.updated), "Details");
			utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.notUpdated), "Errors");
			break;
		case "amazon":
			
			utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.updated), "Details");
			utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.notUpdated), "Errors");
			// utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.summary), "Summary");

			// props.info({success: JSON.stringify(resTrigger.summary))
			break;
		case "walmart":
			
			utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.updated), "Details");
			utils.book_append_sheet(book, utils.json_to_sheet(resTrigger.notUpdated), "Errors");

			// props.info({success: JSON.stringify(resTrigger.summary))
			break;
		default:
			break;
		}
		
		if(resTrigger.updated.length > 0 || resTrigger.notUpdated.length > 0 || resTrigger.dbUpdated.length > 0)
			writeFile(book, `Result_${selectedStore}_MAP_Reprice_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
	}

	const modalConfirmation = (callback) => {
		props.modal({
			show: true,
			title: "Confirmation - Reprice",
			html: () => {
				return <div>
					{
						isMapReprice? <div className="text-center">
							<p className="text-center">You have selected <span className="font-weight-bold">MAP Reprice</span></p>
							<p className="text-center text-danger font-weight-bold">Please make sure that your price column in your xslx file represent the MAP LIMIT, any items that match your selected sku and brake your map price will be adjusted to reflect your new map.</p>
						</div> : <div>
							<p className="text-center"><span className="font-weight-bold">MAP Reprice</span> is unselected</p>
							<p className="text-center text-danger font-weight-bold">Any items matching your selected sku list will be updated with your new selected price, please make sure that you price column is accurate.</p>
						</div>
					}
				</div>
			}
			,ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					if(callback)	callback()
					close()
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	let correctReturnedArray = (list) => {
		list.forEach(obj => {
			for (const key in obj) {
				if (Object.hasOwnProperty.call(obj, key)) {
					if(Array.isArray(obj[key]))
						obj[key] = obj[key].join(', ')
				}
			}
		})
		return list;
	}

	const showDifference = () => {
		if(dataSheet && resTrigger !== null){

			return <div>
				<table className="table table-sm">
					<thead>
						<tr>
							<th scope="col">Result</th>
							<th scope="col">Quantity</th>
						</tr>
					</thead>
					<tbody>
						<tr className="table-success"><td>Updated</td><td>{resTrigger.updated.length}</td></tr>
						<tr className="table-danger"><td>Errors</td><td>{resTrigger.notUpdated.length}</td></tr>
						<tr className="border-top"><td className="align-middle">Details</td><td><button type="button" className="btn btn-primary btn-sm" onClick={() => dlDetails()}>Download</button></td></tr>
					</tbody>
				</table>
			</div>
		}
	}

	const triggerPageFnc = () => {
		if(!dataSheet){
			props.info({error: "Please select a file, a sheet and a column to compare it with your marketplace."})
		}else{
			setSubmited(true)
			findStore(selectedStore).fnc()
		}
	}

	const triggerFnc = (storeName, api, shopObj) => {

		let objOut = [];
		console.info('dataSheetdataSheet', dataSheet);
		
		/* dataSheet.sheet.forEach((o, i) => {
			let cO = {};
			for (const key in o) {
				if (o.hasOwnProperty(key)) {
					cO[dataSheet.required[dataSheet.columnName.indexOf(key)]] = o[key]
				}
			}
			objOut.push(cO)
		}); */

		/* let isForMapReprice = null;
		if(dataSheet.columnName.indexOf('map') !== -1)
			isForMapReprice = true
		if(dataSheet.columnName.indexOf('price') !== -1)
			isForMapReprice = false */

		let newOut = [];
		dataSheet.listObj.forEach((o, i) => {
			if(o.price != "0"){
				o.price = roundPrice(o.price)
				newOut.push(o)
			}
		});

		SubmitReq(api, Object.assign({
			data: JSON.stringify(newOut),
			itemCount: newOut.length,
		}, shopObj))
	}

	const submitData = (api, args, callback) => {
		let type = getApiProperty(selectedStore)

		if(!type){
			props.info({error: "No type requived corresponding a test."})
		}else{

			console.info("bulkUpdatePrice", Object.assign({
				"method": "POST",
				"api": api,
				"trigger": "bulkUpdatePrice",
				"isMapAdjustment": isMapReprice,
			}, args));

			socketPerso("task", {
				url: refConst.urlServer,
				// token: refConst.tokenEio,
				internalReq: Object.assign({
					"method": "POST",
					"api": api,
					"trigger": "bulkUpdatePrice",
					"isMapAdjustment": isMapReprice,
				}, args),
				task: {
					op: "update",
					type: "repricer",
					args: {
						uid: props.getUser().uid,
						name: `Reprice ${args.itemCount} items on ${api}`
					}
				},
				eventIdTaskName: "bulkRepriceEvent"
			}, callback)
			
			/* ajaxPerso( Object.assign({
				"trigger": "bulkUpdatePrice",
				"isMapAdjustment": isMapReprice,
				"api": api,
			}, args), callback, function(jqXHR, textStatus) {
				setSubmited(false)
				console.warn( textStatus );
			}); */
		}
	}

	const SubmitReq = (api, args) => {
		submitData(api, args, (r) => {
			setSubmited(false)

			// return false
			//setSubmited(false)
			console.info(`Fetching bulkUpdatePrice ${api}:`, r);
			if(r.success){
				setResTrigger(r.data)
				// if(r.success !== true)	props.info({"success": r.success})
				if(r.success === true)
					r.success = "Items updated";
			}
			props.info(r)
		})
	}

	const getApiProperty = (store) => {
		let type = null
		info("test", dataSheet.required)
		info("test2", findStore(store).val)
		
		let reqSheet = Array.isArray(dataSheet.required)? dataSheet.required : [dataSheet.required]

		findStore(store).val.forEach(r => {
			reqSheet.forEach(r2 => {
				if(r.required.indexOf(r2.toLowerCase()) !== -1){
					type = r
					return false;
				}
			})
		})
		return type
	}

	const findStore = (store) => {
		return apiProperty.find(s => {
			if(s.name === store)	return s
		})
	}

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage">
			<h1 className="page-header mb-4">Repricer</h1>
			<div className="container p-3 bg-light border mb-3">
				<p className="font-weight-bolder">Store to compare to</p>
				<div className="d-flex flex-row align-items-center">
					{
						<Select
							className="w-50"
							styles={refConst.styleList}
							onChange={(selectedOption) => {
								setSelectedStore(selectedOption? selectedOption.value : initialState)
							}}
							options={(() => {
								return apiProperty.map(b => {
									return {
										value: b.name,
										label: `${b.name}`
									}
								})
							})()}
							value={selectedStore? [{value: selectedStore, label: selectedStore}] : null}
							placeholder={"Brand"}
							isSearchable={true}
							isMulti={false}
						/>
					}
					<div className="custom-control custom-switch noselect w-50  text-center">
						<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={isMapReprice} onChange={(e) => { setIsMapReprice(e.target.checked? true : false) }}/>
						<label className="custom-control-label" htmlFor="customSwitchFree">
							Map Reprice
							<span className="ml-3 tooltipInfoMap" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
						</label>
					</div>
				</div>
			</div>
			<div id="mainMenu" className="container mb-3 bg-light border p-3">
				<p className="font-weight-bolder">Select the primary type of data to use for comparison</p>
				{
					findStore(selectedStore).val.map(s => {
						return <SheetCapture key={s.required + "_sheetCapture"} className={"ml-4"} dataSheet={dataSheet} onChange={(val) => {
							setDataSheet(val)
						}} name={`From Sheet [by: ${s.required}]`} {...props} returnAll={true} required={Array.isArray(s.required)? s.required : [s.required]}/>
					})
				}
			</div>
			{
				dataSheet? <div className="container mt-4 bg-light p-3 border">
					<p className="font-weight-bolder">Informations</p>
					<div className="row">
						<div className="col-sm">
							Line number:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.sheet.length > 0? dataSheet.sheet.length-1 : 0 }</span>
						</div>
					</div>
					<div className="row">
						<div className="col-sm">
							The column selected:
						</div>
						<div className="col-sm">
							<span className="font-weight-bolder">{ dataSheet.columnName }</span>
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							<button type="button" className="btn btn-primary btn-lg" onClick={() => {modalConfirmation(triggerPageFnc)}} disabled={submited? true : false}>Reprice</button>
						</div>
					</div>
					<div className="row">
						<div className="col justify-content-center text-center mt-3">
							{ showDifference() }
						</div>
					</div>
				</div> : ''
			}
		</div>
	);
}