// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { List, arrayMove } from 'react-movable';
import {ajaxPerso, fetchDataSet} from "../fnc.js"
import store from 'store2';
const refConst = require("../constants.js").v


function Shopify_dt(props){

	const [dataSet, setDataSet] = useState([]);
	const [grpOpened, setGrpOpened] = useState([]);

	const [nameNewGrp, setNameNewGrp] = useState('');
	
	let objPreSelected = store("storeSelectedDt")
	console.info("objPreSelected", objPreSelected);
	const [shopSelected, setShopSelected] = useState(objPreSelected? JSON.parse(objPreSelected) : refConst.stores.map(obj => {
		if(obj.id_marketplace == 14)
			return obj
	})[0]);
	const [idSelectedUpdate, setIdSelectedUpdate] = useState(false);

	const [typeList, setTypeList] = useState([
		"text",
		"textarea",
		"countries",
		"number",
		"weight",
		"brand",
		"prop65",
		"packaging"
	]);

	useEffect(() => {
		if(!props.securityClearance(props))	return "";

		if(shopSelected){
			loadData((res) => {
				console.info('res', res);
				
				if(res.length > 0){
					let opnGrp = new Array();
					res.forEach(obj => {
						if(!obj.id_parent)
							opnGrp.push(obj)
					})
					setGrpOpened(opnGrp)
				}
			})
		}

	}, [shopSelected])

	useEffect(() => {
		$('[data-toggle]').tooltip({
			placement: "left"
		}).tooltip('update')
	})
	useEffect(() => {
		console.info('Info', 'Dataset changed');
		
	}, [dataSet])

	const loadData = (callback) => {

		props.loading(true)

		fetchDataSet({
			"shop": shopSelected.id
		}, (r) => {
			props.loading(false)
			console.log(r);
			if(r.success){
				let results = r.data
				setDataSet(results)

				if(callback)	callback(results);
			}
		})
	}

	function getLevel(idParent){

		let valReturn = [];
		
		dataSet.forEach((obj) => {
			
			if(obj.id_parent == idParent)
				valReturn.push(obj)
		})

		return valReturn;
	}

	const eventCreateNewGroup = (e, level, idParent) => {
		e.preventDefault();

		if(nameNewGrp){
			ajaxPerso( {
				"api": "eio",
				"trigger": "setDescriptionToolsGroup",
				"shop": shopSelected.id,
				"data": JSON.stringify({
					"level": level,
					"id_parent": idParent,
					"title": nameNewGrp,
					"is_required": 0
				})
			}, (r) => {
				if(r.res.ok){
					if(level === 2){
						ajaxPerso( {
							"api": "eio",
							"trigger": "setDescriptionToolsGroup",
							"shop": shopSelected.id,
							"data": JSON.stringify({
								"level": level + 1,
								"id_parent": r.res.id,
								"title": "General Information",
								"is_required": 1
							})
						}, (r) => {
							if(r.success){
								["Manufacturer", "Part Number", "Product Type"].forEach(name => {
									ajaxPerso( {
										"api": "eio",
										"trigger": "setDescriptionToolsGroup",
										"shop": shopSelected.id,
										"data": JSON.stringify({
											"level": level + 2,
											"id_parent": r.res.id,
											"title": name,
											"is_required": 1,
											"input_type": "text"
										})
									}, (r) => {
										if(r.success){
											loadData()
										}
									})
								})
							}
						})
					}else
						loadData()
				}
			})
		}else{
			console.info('Please fill the input');
		}
	}

	const deleteLine = (e, id) => {
		e.preventDefault();
		e.stopPropagation();

		let objFound = dataSet.find(obj => {
			if(obj.id === id)	return obj
		})

		if(objFound)
			props.modal({
				show: true,
				title: "Confirmation",
				html: () => {
					return <div>
						Are you sure you want to delete the following data group: <span className="font-weight-bold">{objFound.title}</span>
					</div>
				}
				,ok: {
					title: "I'm sure",
					fnc: (popup, close) => {
						
						if(id){
							ajaxPerso( {
								"api": "eio",
								"trigger": "delDescriptionToolsData",
								"shop": shopSelected.id,
								"id": id
							}, (r) => {
								if(r.success){
									loadData()
									close()
								}
							})
						}else{
							console.info('Please fill the input');
						}
					}
				},
				return: {
					title: "Close"
				}
			})
	}

	const updateLineType = (e, id, newType) => {
		e.preventDefault();
		e.stopPropagation();

		if(!id || !newType){
			props.info({ error: "Wrong id["+id+"] or type["+newType+"]" });
			return false;
		}

		ajaxPerso( {
			"api": "eio",
			"trigger": "setDescriptionToolsGroup",
			"shop": shopSelected.id,
			"data": JSON.stringify({
				"id": id,
				"input_type": newType
			})
		}, (r) => {
			if(r.success){
				loadData()
			}
		})
	}

	const updateTitle = (e, obj) => {
		e.preventDefault();
		e.stopPropagation();

		if(!obj.id || !obj.title){
			props.info({ error: "Wrong id["+obj.id+"] or title["+obj.title+"]" });
			return false;
		}

		/* console.info('id', id);
		console.info('title', title);
		return */

		ajaxPerso( {
			"api": "eio",
			"trigger": "setDescriptionToolsGroup",
			"shop": shopSelected.id,
			"data": JSON.stringify(obj)
		}, (r) => {
			if(r.success){
				loadData()
				/* setTitleUpdate(false)
				setIdSelectedUpdate(false)
				setNote(false)
				setIsRequired(false) */
				if(r.res.ok){
					props.info({success: "Group: " + obj.title + " updated."})
				}else{
					props.info({error: "Group: " + obj.title + " not updated, please refresh."})
				}
			}
		})
	}


	const isOpenInView = (idGrp) => {
		let objOpenFound = grpOpened.find(obj => {
			if(obj.id === idGrp)
				return obj
		})

		return objOpenFound
	}

	const invertDataSet = (idOld, idNew) => {
		if(!idOld || !idNew){
			props.info({error: "Something wrong happened with some ID"})
			return;
		}

		ajaxPerso( {
			"api": "eio",
			"trigger": "invertDataSetPosition",
			"shop": shopSelected.id,
			"data": JSON.stringify({
				"idOld": idOld,
				"idNew": idNew
			}),
		}, (r) => {
			if(r.success && r.result){
				loadData()
			}
		})
	}

	const renderBlock = (idParent, level, pos, objParent) => {


		let dataSetArray = getLevel(objParent.id, level);

		dataSetArray.sort((obj1, obj2) => {
			if(obj1.position > obj2.position)
				return 1
			return -1
		})

		return (<div className="card mr-4 mb-4" key={idParent + "_card_" + level} style={{minWidth: "28em", maxHeight: "70vh", overflowY: "auto", overflowX: "hidden"}}>
			<button onClick={() => {
				let levelArray = grpOpened.slice(0);
				levelArray.splice(pos, 1);
				setGrpOpened(levelArray)
			}} className={(level === 1? "d-none" : "")}>x</button>
			<div className="card-header">
				<form onSubmit={(e) => { eventCreateNewGroup(e, level + 1, objParent.id) } }>
					<div className={"input-group mb-3"}>
						<input type="text" className="form-control" placeholder={"New Grp"} aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setNameNewGrp(e.target.value)} />
						<div className="input-group-append">
							<input type="submit" className="btn btn-outline-secondary" id="button-addon2" value="Save"/>
						</div>
					</div>
				</form>
			</div>
			<div className={" p-2 font-weight-bold"}>level: { level } Parent id: {objParent.id}</div>
			<div className={"ctnListDraggable"}>
				<List
					values={ dataSetArray.map(obj => {
						//console.log(obj);
						
						return <div key={obj.id + "_listLevel"} className={(isOpenInView(obj.id)? "shadowIn" : "") + " w-100 h-100 p-3 StackedListItem  d-flex flex-column justify-content-between align-items-center"} >
							<div className="d-flex justify-content-between">
								<span className="mr-3"><FontAwesomeIcon icon={faGripLines}/></span>
								<button className={(obj.level > 3? "hide" : "") + " mr-3 border-0 text-black"} onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									
									console.info('Clicked');
									
									if(obj.level > 3)
										return false
									/**
									 * Add the parent ID to the array of open block
									 * 	- We need not to add anything when the current level opened has reached the limit ( can't open 2 from the same level )
									 */
									let newArraySelected = [];

									
									for (let u = 0, lengthTab = grpOpened.length; u < lengthTab; u++) {
										//console.info(parseInt(grpOpened[u].level) +">="+ parseInt(obj.level));
										
										if(parseInt(grpOpened[u].level) < parseInt(obj.level)){
											//console.warn("delete lvl: " + grpOpened[u].level + ", Looking for: " + obj.level);
											newArraySelected.push(grpOpened[u])
										}
									}
									
									newArraySelected.push(obj)
									setGrpOpened(newArraySelected);
								}} title="Show childs">Open</button>
								<input style={{width: "210px"}} className="overflow-hidden" defaultValue={obj.title} onChange={ (e) => {
									//let cp = Object.assign({}, obj)
									obj.title = e.target.value
									//setTitleUpdate(e.target.value)
								}} disabled={idSelectedUpdate === parseInt(obj.id)? false : true}/>
								<div className="ml-3 d-flex justify-content-center" /* style={{minWidth: "90px"}} */>

									{
										parseInt(obj.level) === 4? 
											<select className="mr-3" value={obj.input_type || ""} onChange={(e) => { updateLineType(e, obj.id, e.target.value) }}>
												{
													typeList.map(type => {
														return <option key={type}>{type}</option>
													})
												}
											</select> 
											: "" 
									}
									<button className="border-0" onClick={(e) => { 
										e.stopPropagation() ; setIdSelectedUpdate(idSelectedUpdate? false : parseInt(obj.id)) 
									}} title={idSelectedUpdate === parseInt(obj.id)? 'Close editor mode' : 'Open editor mode' }>
										{idSelectedUpdate === parseInt(obj.id)? <FontAwesomeIcon icon={faWindowClose}/> : <FontAwesomeIcon icon={faPen}/> }
									</button>
									{ 
										idSelectedUpdate === parseInt(obj.id)? <button className="ml-3 border-0 bg-success text-white" onClick={(e) => { 
											updateTitle(e, obj) 
										}} title="Save change">
											<FontAwesomeIcon icon={faSave}/>
										</button> : ""
									}
									<button className="ml-3 border-0 bg-danger text-white" onClick={ (e) => { 
										deleteLine(e, obj.id)
									}}  data-toggle="tooltip" title="Only if really necessary">
										<FontAwesomeIcon icon={faTrashAlt}/>
									</button>

								</div>
							</div>
							{
								idSelectedUpdate === parseInt(obj.id)? 
									<div className="d-flex justify-content-between align-items-center pt-3">
										<textarea value={obj.note || ''} placeholder="Note..." onChange={(e) => {
											console.info('e.target.value', e.target.value);
											
											obj.note = e.target.value
											setDataSet(dataSet.slice(0))
										}}/>
										<div className="ml-4 d-flex justify-content-between align-items-center">
											<input id="checkFreeShipping" type="checkbox" style={{height: "20px", width: "20px"}} checked={parseInt(obj.is_required) === 1? true : false} onChange={(e) => {
												//setIsActive(e.target.checked? 1 : 0)
												obj.is_required = e.target.checked? 1 : 0
												setDataSet(dataSet.slice(0))
											}}/>
											<label className="ml-3" htmlFor="checkFreeShipping">Required</label>
										</div>
									</div>
									: ""
							}
						</div>
					}) }
					onChange={( move ) => {

						console.log(move);
						console.log(dataSetArray[move.oldIndex].title);
						console.log(dataSetArray[move.newIndex].title);

						invertDataSet(dataSetArray[move.oldIndex].id, dataSetArray[move.newIndex].id)
					}}
					renderList={
						({ children, props }) => 
							<ul {...props} className="list-group list-group-flush">{children}</ul>
					}
					renderItem={({ value, props }) => {
						props.onKeyDown = (e) => { return e };
						//props.calculateOffsets = (e) => { console.log('calculateOffsets', e); };
						console.log(props);
						return(<li {...props} className={"p-0 list-group-item"}>{value}</li>) }}
				/>
			</div>
		</div>
		)
	}

	if(!props.securityClearance(props))	return "";
	
	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" defaultValue={ JSON.stringify(shopSelected) } onChange={(e) => {
					setShopSelected(JSON.parse(e.target.value))
					store("storeSelectedDt", e.target.value)
				}}>
					{
						refConst.stores.map(obj => {
							if(obj.id_marketplace == 14)
								return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
						})
					}
				</select>
			</div>
			<div className="d-flex flex-row flex-nowrap" style={{overflowX: "auto"}}>
				{(() => {
					let blocksReturn = [];
					for (let i = 0; i < grpOpened.length; i++) {
						let obj = grpOpened[i];
						if(obj)
							blocksReturn.push( renderBlock( obj.parent_id, parseInt(obj.level), i, obj) )
					}
					return blocksReturn;
				})()
				}
			</div>
			
		</div>

	);
}

export default Shopify_dt;