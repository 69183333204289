import React, { Component } from 'react';
import $ from 'jquery'; 
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
const refConst = require("../../../constants.js").v

class UserList extends Component {
	constructor(props) {
		super(props);

		this.state = {

		};

		// Bind Allow access by "this" into the function
		//this.fetchItems = this.fetchItems.bind(this);
	}

	/* componentWillMount() {
		this.initialState = this.state
	} */

	componentDidUpdate(prevProps){
	}

	componentDidMount() {
	}

	render() {
		return (
			<div className="main ctnPage">
				Admin

				<table className="table table-bordered">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Id</th>
							<th scope="col">Email</th>
							<th scope="col">Last connexion</th>
						</tr>
					</thead>
					<tbody>
						{
							this.props.getUsers().map((obj, index) => {
								
								return (<tr key={obj.uid}><th scope="row">{index}</th>
									<td><NavLink to={"/Account/"+obj.uid} activeClassName="active">{obj.uid}</NavLink></td>
									<td>{obj.email}</td>
									<td>{obj.lastSignInTime}</td>
									<td>
										{/*<button type="button" className={(level == "admin"? "btn-primary" : "btn-warning" ) + " btn"} onClick={(e) => { e.preventDefault(); this.changeLevel(obj.uid, level, (level == "admin"? "user" : "admin")) } }>{level == "admin"? "to user" : "to admin"}</button>*/}
										<Select
											onChange={(selectedOption) => {
												this.props.changeLevel(obj.uid, selectedOption)
											}}
											options={this.props.permissions? this.props.permissions.map(p => {
												return {value: p.uid, label: p.name}
											}) : null}
											value={obj.permissions? obj.permissions.map(puid => {
												let pAttached = this.props.permissions.find(po => {
													// console.info(`${po.uid} - ${puid}`);
													if(po.uid === puid)		return po
													return false;
												})
												console.info('pAttachedpAttached', pAttached);
												
												return pAttached? {value: puid, label: pAttached.name} : null
											}) : null}
											isSearchable={true}
											isMulti={true}
											styles={{
												option: (base, { data, isDisabled, isFocused, isSelected }) => {
													if(data.value.indexOf("write") !== -1 || data.value.indexOf("delete") !== -1)
														return { ...base, color: 'red' }
													return {...base}
												}
											}}
										/>
									</td>
								</tr>)
							})
						}
					</tbody>
				</table>
			</div>
		);
	}
}

export default UserList;
