// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { capitalizeFLetter, ajaxPerso } from '../../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

const refConst = require("../../constants.js").v

export default function FileConvertor(props){

	const initialState = {
		items: [],
		submited: false,
		file: null,
		version: "1.0.0",
		requireV1: false,
		isExcelToJson: true
	}

	const inputImg = useRef(null)

	const [submited, setSubmited] = useState(initialState.submited);
	const [file, setFile] = useState(initialState.file);
	const [items, setItems] = useState(initialState.items.slice(0));
	const [version, setVersion] = useState(initialState.version);
	const [requireV1, setRequireV1] = useState(initialState.requireV1);
	const [isExcelToJson, setIsExcelToJson] = useState(initialState.isExcelToJson);

	useEffect(() => {
		triggerModal(true);
	}, [submited, file, requireV1, isExcelToJson])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let showImgMenu = () => {

		return <div>
			<div className="d-flex flex-row mr-2 ml-3" title="Only to see some white logo/text">
				<div className="custom-control custom-switch noselect mb-3">
					<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={requireV1} onChange={(e) => { setRequireV1(e.target.checked) }}/>
					<label className="custom-control-label" htmlFor="customSwitchFree">Require V1</label>
				</div>
			</div>
		</div>
	}

	
	let initProcess = (callback) => {
		
		if(!inputImg.current || inputImg.current.files.length === 0){
			props.info({error: "Please select a file."})
			return;
		}

		console.info('inputImg', inputImg.current.files);
		let mimeFile = inputImg.current.files[0].type

		if(mimeFile.indexOf("spreadsheet") === -1 || mimeFile.indexOf("excel") === -1){
			
			console.info("mimeFile", mimeFile);
			console.info("lengthlength", inputImg.current.files[0].size/1024/1024);

			setFile({
				sizeMb: inputImg.current.files[0].size/1024/1024,
				name: inputImg.current.files[0].name,
				mime: mimeFile
			})

		}else{
			props.info({error: "Please select a spreadsheet."})
		}
	}

	let submitFile = (callback) => {

		props.loading(true)
		setSubmited(true)

		var formData = new FormData();
		formData.append(`file`, inputImg.current.files[0], inputImg.current.files[0].name);
		formData.append("api", "eio");
		formData.append("trigger", isExcelToJson? "convert_xlsx_to_json" : "convert_json_to_xslx");
		if(isExcelToJson){
			formData.append("v1", requireV1);
		}else {
			formData.append("arrayOfObject", !requireV1);
		}
		formData.append("isWebRequest", true);
		
		ajaxPerso(formData, function(res){
			console.info('Fetched `FileConvertor`', res);
			setSubmited(false)
			props.loading(false)
			callback(res);
		}, function(jqXHR, textStatus, errorThrown) {
			props.info({error: textStatus})
			console.warn( textStatus );
			setSubmited(false)
			props.loading(false)
		}, {
			contentType: false,
			processData: false,
			cache: false,
			async: true,
			timeout: 60000,
		});
	}

	console.info("imgimg", file);

	let triggerModal = (refresh) => {
		
		props.modal({
			show: refresh? false : true,
			title: <span>Convert XLSX/JSON <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);
				
				return <div>
					<div className="text-center mb-3 text-light">
						<div className="btn-group btn-group-toggle m-auto" data-toggle="buttons">
							<label className={(isExcelToJson? "active" : "") + " btn btn-primary border-light border-right"}>
								<input type="radio" name="options" autoComplete="off" checked={isExcelToJson} onChange={(e) => {
									setIsExcelToJson(true)
								}}/> Xlsx to JSON
							</label>
							<label className={(!isExcelToJson? "active" : "") + " btn btn-primary border-light border-left"} onChange={(e) => {
									setIsExcelToJson(false)
								}}>
								<input type="radio" name="options" autoComplete="off" checked={!isExcelToJson}/> JSON to Xlsx
							</label>
						</div>
					</div>
					{
						isExcelToJson? <div>
							<div className="alert alert-primary" role="alert">
								V1 return 1 object in which each props is an array of multiple values.
							</div>
							<div className="alert alert-primary" role="alert">
								V2 return each data per line (Better for big excel file)
							</div>
						</div> : ""
					}
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text" id="inputGroupFileImg">Upload</span>
						</div>
						<div className="custom-file">
							<input type="file" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileImg" ref={inputImg} onChange={() => {
								initProcess(() => {
									// close()
								})
							}}/>
							<label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
						</div>
					</div>
					<div>
						<div className="d-flex flex-row">
							{
								file? <div className="w-100">
									{showImgMenu()}
									{/*<file src={'data:'+file.mime+';base64,' + btoa(unescape(encodeURIComponent(file.bin)))} width={200} height={200}/> : 'No image'*/}
									<div className="text-center mt-4 mb-4">
										{file.name}<br/>
										{file.sizeMb.toFixed(3)} MB
									</div>
								</div> : ''
							}
						</div>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				// setModalOpened(false)
				resetPage()
				close()
			}
			, 
			ok: {
				title: submited? "Please wait" : (file? "Download" : "Generate"),
				fnc: (popup, close) => {
					if(!submited){
						if(file){

							submitFile((res) => {
								let mime = "application/octet-stream";
								console.info("mimemime", mime);
								var a = document.createElement("a"); //Create <a>
								a.href = "data:"+mime+";base64," + res.base64; //Image Base64 Goes here
								// Change the extension for json
								file.name = file.name.split('.').slice(0, -1).join('.') + (isExcelToJson? '.json' : '.xlsx')
								a.download = file.name
								document.body.appendChild(a);
								a.click();
								document.body.removeChild(a);
							})

						}else{
							initProcess(() => {
								// close()
							})
						}
					}
				}
			},
			return: {
				title: submited? "Please wait" : "Close",
				fnc: (popup, close) => {
					// setModalOpened(false)
					resetPage()
					close()
				}
			}
		});
	}

	return (
		<div className={("className" in props? props.className : 'list-group-item list-group-item-action p-2 text-center')} onClick={() => 	triggerModal(false)}>
			{
				props.title
			}
		</div>
	);
}