// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Select from 'react-select';
import { capitalizeFLetter, ajaxPerso, socketPerso } from '../../fnc'
import store from 'store2';
import { utils, writeFile } from 'xlsx';
import Filter from './historySells/filter.js'
import {
	NavLink
} from 'react-router-dom';
const refConst = require("../../constants.js").v

var initialState = {
	selected: null,
	resEioReport: null,
}

var specialVar = {
	_isMounted: false
}

var statusOrderList = ['open', 'closed', 'cancelled', 'any'];
var financialStatusOrderList = ['authorized', 'pending', 'paid', 'partially_paid', 'refunded', 'voided', 'partially_refunded', 'unpaid', 'any'];
var fulfillmentStatusOrderList = ['shipped', 'partial', 'unshipped', 'unfulfilled', 'any'];


export default function CreateItem(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
		"-Mj_haqzismzadLiujp1" // order_view
	]

	const [financialStatusOrderSelected, setFinancialStatusOrderSelected] = useState(financialStatusOrderList[financialStatusOrderList.length-1]);
	const [statusOrderSelected, setStatusOrderSelected] = useState(statusOrderList[statusOrderList.length-1]);
	const [fulfillmentStatusOrderSelected, setFulfillmentStatusOrderSelected] = useState(financialStatusOrderList[financialStatusOrderList.length-1]);
	const [resEioReport, setResEioReport] = useState(initialState.resEioReport);
	const [fromTo, setFromTo] = useState({
		from: new Date(moment().local().format('YYYY-MM-DD 00:00:00')),
		to: new Date(moment().local().format('YYYY-MM-DD 23:59:59'))
	});
	const [top, setTop] = useState(50);

	useEffect(() => {
		specialVar._isMounted = true

		return () => specialVar._isMounted = false
	}, [])

	useEffect(() => {
		if(resEioReport)
			modal();
	}, [resEioReport])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let modal = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: "Shopify Order Report",
			html: () => {
				return <div>
					<div>
						<div className="d-flex flex-row align-items-center">
							<div>
								<Filter
									//from={fromTo.from}
									//setFrom={setFrom}
									setFromTo={(newFromTo) => {
										console.info('newFromTo', newFromTo);
										
										setFromTo(Object.assign({}, newFromTo))
									}}
									fromTo={fromTo}
									/* to={to}
									setTo={setTo} */
									top={null}
									setTop={setTop}
									moreFilters={null}
								/>
							</div>
						</div>
						<div className="d-flex flex-row align-items-center mt-3">
							<div className="d-flex flex-column">
								<span>Status</span>
								<Select
									styles={refConst.styleList}
									onChange={(selectedOption) => {
										console.log(selectedOption);
										setStatusOrderSelected(selectedOption? selectedOption.value : null)
									}}
									options={(() => {
										return statusOrderList.map(status => {
											return { value: status, label: status }
										})
									})()}
									defaultValue={[{value: statusOrderList[statusOrderList.length-1], label: statusOrderList[statusOrderList.length-1]}]}
									isSearchable={true}
									isMulti={false}
									className={""}
								/>
							</div>
							<div className="d-flex flex-column ml-3">
								<span>Financial Status</span>
								<Select
									styles={refConst.styleList}
									onChange={(selectedOption) => {
										console.log(selectedOption);
										setFinancialStatusOrderSelected(selectedOption? selectedOption.value : null)
									}}
									options={(() => {
										return financialStatusOrderList.map(status => {
											return { value: status, label: status }
										})
									})()}
									defaultValue={[{value: financialStatusOrderList[financialStatusOrderList.length-1], label:financialStatusOrderList[financialStatusOrderList.length-1]}]}
									isSearchable={true}
									isMulti={false}
									className={""}
								/>
							</div>
							<div className="d-flex flex-column ml-3">
								<span>Fulfillment Status</span>
								<Select
									styles={refConst.styleList}
									onChange={(selectedOption) => {
										console.log(selectedOption);
										setFulfillmentStatusOrderSelected(selectedOption? selectedOption.value : null)
									}}
									options={(() => {
										return fulfillmentStatusOrderList.map(status => {
											return { value: status, label: status }
										})
									})()}
									defaultValue={[{value: fulfillmentStatusOrderList[fulfillmentStatusOrderList.length-1], label:fulfillmentStatusOrderList[fulfillmentStatusOrderList.length-1]}]}
									isSearchable={true}
									isMulti={false}
									className={""}
								/>
							</div>
						</div>
					</div>
					{
						resEioReport? <div className="mt-3 container bg-light text-dark border" style={{
							maxHeight: "200px",
							overflow: "hidden",
							overflowY: "auto"
						}}>
							<table className="table table-sm">
								<thead>
									<tr>
										<th scope="col">User</th>
										<th scope="col">Link</th>
										<th scope="col">Dl</th>
										<th scope="col">Created</th>
									</tr>
								</thead>
								<tbody>
									{
										resEioReport ? resEioReport.map((res) => {
											return <tr key={`${res.id}_eioOrder`}>
												<td><NavLink to={`/Account/${res.gid}`}>{res.email.substr(0, res.email.indexOf('@'))}</NavLink></td>
												<td><a href={`${refConst.urlServer}?token=${refConst.tokenEio}&trigger=reportDl&api=eio&idReport=${res.id}`} download>{res.path.substr(res.path.lastIndexOf('/')+1)}</a></td>
												<td>{ res.time_downloaded }</td>
												<td>{ res.date }</td>
											</tr>
										}) : ''
									}
								</tbody>
							</table>
						</div>
							: ''
					}
				</div>
			}
			, ok: {
				title: "Validate",
				fnc: (popup, close) => {

					if(!props.store){
						props.info({error: "Please indicate a store ID"})
						return;
					}
					
					socketPerso("task", {
						url: refConst.urlServer,
						// token: refConst.tokenEio,
						internalReq: {
							"method": "POST",
							"api": "shopify",
							"trigger": "create_report_order",
							"shop": props.store,
							"userId": props.getUser().uid,
							"sendMail": false,
							"criteria": JSON.stringify({
								created_at_min: fromTo.from,
								created_at_max: fromTo.to,
								gateway: ['paypal', 'shopify_payments'],
								shipping_province_code: 'CA',
								financial_status: financialStatusOrderSelected,
								fulfillment_status: fulfillmentStatusOrderSelected,
								status: statusOrderSelected
							})
						},
						task: {
							op: "insert",
							type: "report",
							args: {
								uid: props.getUser().uid,
								name: `Create report for shopify margin`
							}
						}
					})

					close()
					props.info({"success": "Task created, You will be notified per eMail once done."})
				}
			}, return: {
				title: "Return",
				fnc: () => {
					/* itemsSel = items.map((obj) => {
						return obj.id
					})
					this.setState({"itemsSelected": itemsSel}) */
				}
			}
		});
	}

	const loadresEioReport = (args) => {
		fetchresEioReport(args, (r) => {
			if(!initialState._isMounted)	return false

			console.info('Fetching eio->loadReport:', r);
			if(r.length > 0){
				setResEioReport(r)
			}else{
				setResEioReport(initialState.resEioReport)
				props.info({"error": r.errors})
			}
		})
	}

	const fetchresEioReport = (args, callback) => {
		ajaxPerso( Object.assign({
			"trigger": "loadReport",
			"api": "eio",
		}, args), callback)
	}
	
	let storeFromToNames = () => {
		
		if(!props.stores || props.stores.length === 0)	return ""
		
		let storeObj = props.stores.find(o => {
			if(o.id === parseInt(props.store))	return o
		})

		return storeObj? `${props.name} (${storeObj.name})` : props.name
	}

	if(!props.securityClearance(props, access))	return "";

	return (
		<div className={props.className} onClick={() => {
			loadresEioReport()
			modal(false)
		}}>
			{
				storeFromToNames()
			}
		</div>
	);
}