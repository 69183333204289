// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { ajaxPerso, uniqueArray, handelize, getProductTypeList } from '../../../fnc'
import Select from 'react-select';
import subcatImgInfo from '../../../img/subCat_image_collection_hausoftools.png';

var initialState = {
	_isMounted: false,
	categoriesStoreTo: {},
	dataSet: [],
}

const refConst = require("../../../constants.js").v

const styleList = {
	container: (provided) => ({
		...provided,
		minWidth: "250px"
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

export default function ChangeImage(props){

	const [typeList, setTypeList] = useState([]);
	const [vendorList, setVendorList] = useState([]);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [reloadReact, setReloadReact] = useState(0); // increment just to update the view sometimes
	const refInputImg = useRef(null);

	useEffect(() => {
		initialState._isMounted = true;
		console.info("TEST", props.shop);
		if(!props.shop)	return
		init()
		return () => initialState._isMounted = false
	}, [props.shop])

	useEffect(() => {
		$('[data-toggle="tooltip"]').tooltip({
			placement: "bottom"
		}).tooltip('update')
	})

	const init = () => {
		console.info("YESY", props.shop);
		getProductTypeList(props.shop.id, (r) => {
			console.info("Result from getProductTypeList:", r);
			if(r.error){
				props.info({error: r.error})
			}else{
				setTypeList(uniqueArray(r.data.map(o => {
					return o.product_type
				})))

				setVendorList(uniqueArray(r.data.map(o => {
					return o.vendor
				})))
			}
		})
	}

	const submitChangeReq = (formData, callback) => {
		
		ajaxPerso( formData, callback);
	}

	function getBase64(file) {
		return new Promise(function(resolve, reject) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result)
			};
			reader.onerror = function (error) {
				reject(error)
			};
		})
	}

	const submitImgChange = (e) => {
		e.preventDefault();

		// Format: subCatIcon_3m__hard-hats.png

		let errors = [];
		if(refInputImg.current.files.length != 1)
			errors.push("Please select one image only")
		if(!selectedType)
			errors.push("Please select a collection")
		if(!selectedVendor)
			errors.push("Please select a vendor")

		if(errors.length > 0){
			props.info({error: errors})
		}else{
			props.loading(true);
			let imgName = `subCatIcon_${handelize(selectedVendor)}__${handelize(selectedType)}`

			var formData = new FormData();
			formData.append(`img`, refInputImg.current.files[0], imgName);
			formData.append("api", "shopify");
			formData.append("trigger", "manual_api_storage_img_replacer");
			formData.append("uid", props.getUser().uid);
			formData.append("shop", props.shop.id);
			formData.append("imgName", imgName);

			ajaxPerso( formData, r => {

				console.info("Result from submitChangeReq:", r);
				props.loading(false);

				if(r.errors){
					props.info({error: r.errors})
				}else if(r.insertResult && r.insertResult.length > 0){
					props.info({success: "Image changed."})
				}

			}, null, {
				contentType: false,
				processData: false,
				cache: false,
				async: true,
				// timeout: 60000,
			});
		}
	}
		
	return (<div className={"border radius m-3 p-3 w-100 bg-light " + ("className" in props? props.className : "")}>
		<form onSubmit={submitImgChange}>
			<div className="pb-3">
				Replace a collection image on our special vendor page
				<span className="ml-3" data-html="true" data-toggle="tooltip" title={`<img class="imgInfoTooltip" src="${subcatImgInfo}" style="height: '35px'"></img>`}><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
			</div>
			<div className="d-flex flex-row">
				<Select
					styles={styleList}
					// isDisabled={isInViewMode}
					placeholder="Select a vendor"
					onChange={(selectedOption) => {
						setSelectedVendor(selectedOption.value.trim())
					}}
					options={vendorList.map((vendor) => {
						return {"value": vendor? vendor : '', "label": vendor? vendor : '-'}
					})}
					value={[selectedVendor? {"value": selectedVendor, "label": selectedVendor} : null]}
					isSearchable={true}
				/>
				<Select
					styles={styleList}
					className="ml-3"
					// isDisabled={isInViewMode}
					placeholder="Select a category"
					onChange={(selectedOption) => {
						setSelectedType(selectedOption.value.trim())
					}}
					options={typeList.map((type) => {
						return {"value": type? type : '', "label": type? type : '-'}
					})}
					value={[selectedType? {"value": selectedType, "label": selectedType} : null]}
					isSearchable={true}
				/>
				<div className="custom-file ml-3">
					<input ref={refInputImg} type="file" className="custom-file-input" id="validatedCustomFile" required onChange={() => setReloadReact(reloadReact+1)}/>
					<label className="custom-file-label" htmlFor="validatedCustomFile">{ refInputImg.current && refInputImg.current.files.length === 1? refInputImg.current.files[0].name + " (" + (Math.round(refInputImg.current.files[0].size/1000)) + "Kb)" : "Choose file..." }</label>
				</div>
			</div>
			<div className="mt-3 text-center">
				<input type="submit" name="Change" className="btn btn-success" disabled={props.loading()}/>
			</div>
		</form>
	</div>);
}