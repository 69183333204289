import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import { ajaxPerso, uniqueArray, fetchAutoCreateProduct } from '../../../fnc'

var initialState = {
	_isMounted: false,
	categoriesStoreTo: [],
	dataSet: [],
	itemsWithIssue: [],
	itemData: null,
}

const refConst = require("../../../constants.js").v

export default function SectionStats(props){

	const [countToReview, setCountToReview] = useState(0);
	const [countCreated, setCountCreated] = useState(0);
	const [countVariantCreated, setCountVariantCreated] = useState(0);
	const [countToCreate, setCounToCreate] = useState(0);
	const [itemsWithIssueCount, setItemsWithIssueCount] = useState(0);
	const [itemsWithIssue, setItemsWithIssue] = useState(initialState.itemsWithIssue.slice(0));
	const loadLast = useRef(null)
	const [resultInfo, setResultInfo] = useState(null);
	const [itemData, setItemData] = useState(initialState.itemData);
	const [modalOpened, setModalOpened] = useState(false);
	const [loadPos, setLoadPos] = useState(0);

	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;

		init()
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		
		if(modalOpened)
			modalLastResult()
		
	}, [resultInfo, itemData, loadLast])

	useEffect(() => {
		
		if(modalOpened)
			modalIssuesFixes()
		
	}, [itemsWithIssue, loadLast])

	useEffect(() => {
		
		if(!resultInfo || resultInfo.listSummarize.length === 0)	return;
		let skuList = [];
		resultInfo.listSummarize.forEach((summarize, key) => {
			for (const pSku in summarize) {
				if (Object.hasOwnProperty.call(summarize, pSku)) {
					let product = summarize[pSku];
					skuList.push(pSku)
					
					for (const vSku in product) {
						if (Object.hasOwnProperty.call(product, vSku)) {

							skuList.push(vSku)
						}
					}
				}
			}
		})

		skuList = uniqueArray(skuList)

		fetchAutoCreatorItemPerSku(skuList, null, (r) => {
			console.info('fetchAutoCreatorItemPerSku_list', r);
			if(r && r.errors){
				console.error(r.errors);
				return false;
			}else if(r.data.length > 0){

				// Sort per sku and then per store
				let data = {}
				r.data.forEach(d => {
					if(!data[d.sku])
						data[d.sku] = {}
					if(!data[d.sku][d.id_marketplace])
						data[d.sku][d.id_marketplace] = {}
					data[d.sku][d.id_marketplace] = d;
				})
				setItemData(data)
			}
		})
		
	}, [resultInfo])

	useEffect(() => {

		// We reload the stats after we receive response from the creation trigger
		if(props.submited || props.submited == 'init')	return;
		console.info('props.submited', props.submited);

		init()
		
	}, [props.submited, props.parent.match.params.ctn])

	const init = (e, o) => {

		// List to review
		fetchAutoCreateProduct( {
			"getCountOnly": true,
			"onlyWithSmallestSeleableUnit": null,
			"reviewed": 0,
			"doNotCreate": 0
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct - not reviewed', r);
			if(r.success){
				setCountToReview(r.count)
			}
		});
		// List created
		fetchAutoCreateProduct( {
			"getCountOnly": true,
			"onlyWithSmallestSeleableUnit": null,
			"reviewed": 1,
			"doNotCreate": 0,
			"isCreated": 1
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct - isCreated', r);
			if(r.success){
				setCountCreated(r.count)
			}
		});
		// List created
		fetchAutoCreateProduct( {
			"getCountOnly": true,
			"onlyWithSmallestSeleableUnit": null,
			"reviewed": 1,
			"doNotCreate": 0,
			"isVariantCreated": 1
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct - isVariantCreated', r);
			if(r.success){
				setCountVariantCreated(r.count)
			}
		});
		// List awaiting to be created
		fetchAutoCreateProduct( {
			"getCountOnly": true,
			"onlyWithSmallestSeleableUnit": null,
			"isCreated": 0,
			"reviewed": 1,
			"doNotCreate": 0
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct', r);
			if(r.success){
				setCounToCreate(r.count)
			}
		});

		// Item wihh issues
		fetchAutoCreateProduct( {
			"getCountOnly": true,
			"onlyWithSmallestSeleableUnit": null,
			"filterOutput": "t1.id, t1.sku, t1.smallest_saleable_unit",
			"isCreated": 0,
			"reviewed": 1,
			"errors": 1,
			"warning": 1,
			"doNotCreate": 0
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct issues', r);
			if(r.success){
				setItemsWithIssueCount(r.count)
				// setItemsWithIssue(r.success? r.data : initialState.itemsWithIssue)
			}
		});
	}

	const modalLastResult = (dataType) => {

		let classColor = dataType == "error"? "alert-danger" : dataType == "warning"? "alert-warning" : dataType == "success"? "alert-success" : "alert-info"
		console.info("Modal resultInfo", resultInfo);
		console.info("refConst.stores", refConst.stores);

		let idStoreToName = [];
		refConst.stores.forEach(s => {
			idStoreToName[s.id] = s.name
		})

		let nodeP = []
		if(resultInfo)
			resultInfo.listSummarize.forEach((summarize, key) => {
				for (const pSku in summarize) {
					if (Object.hasOwnProperty.call(summarize, pSku)) {
						const product = summarize[pSku];
						
						let nodeV = []
						for (const vSku in product) {
							if (Object.hasOwnProperty.call(product, vSku)) {
								const variant = product[vSku];

								let nodeD = [],
									nodeErros = [],
									nodeWarnings = []
								for (const idStore in variant) {
									if (Object.hasOwnProperty.call(variant, idStore)) {
										const data = variant[idStore];

										console.info("SSSS data", data);
										let hasIssue = false;
										let bg = "bg-light"
										if(data.created){
											bg = "bg-success"
										}else if(data.errors){
											bg = "bg-danger"
											nodeErros = data.errors;
											hasIssue = true;
										}else if(data.warning){
											bg = "bg-warning"
											nodeWarnings = data.warning;
											hasIssue = true;
										}

										let markedAsCreated = false;
										if(itemData && pSku in itemData && idStore in itemData[pSku] && itemData[pSku][idStore].created){
											if(hasIssue)
												bg = "bg-info"
											markedAsCreated = true;
										}

										if(idStore != "created")
											nodeD.push(<div className="d-flex flex-column align-items-center justify-content-center">
												{/* <button className={(hasIssue && !markedAsCreated? "" : "d-none") + " btn btn-sm btn-outline-danger mt-2 ml-1 mr-1"} onClick={() => {
													
													fetchAutoCreatorItemPerSku(vSku, idStore, (r) => {
														console.info('fetchAutoCreatorItemPerSku', r);
														if(r.errors){
															console.error(r.errors);
															return false;
														}else if(r.data.length > 0){

															let data = r.data[0];
															if(data.created){
																props.info({success: "This product is already removed."})
															}else{
																alterVariant({
																	"id": data.id_variant,
																	"id_auto_creator_product": data.id_acp,
																	"id_marketplace": idStore,
																	"created": 1,
																	"note": "Product was created but had errors, it was removed from the creator and will not be updated anymore.",
																}, null, (r) => {
																	console.info('alterVariant', r);
																})
															}
														}
													})
												}}>Remove from creator</button> */}
												<div className={"border rounded m-3 d-flex align-items-center justify-content-center " + bg} style={{
													height: "50px",
													width: "80px",
													fontSize: "10px",
												}}>
													{idStoreToName[idStore]}
												</div>
											</div>
												)
									}
								}

								if(vSku != "created" && nodeD.length > 0){

									nodeV.push(<div style={{
										borderBottom: "2px dotted #CCC"
									}}>
										<div style={{
											fontSize: "12px",
										}}>Variant ID: {vSku}</div>
										<div className="d-flex justify-content-center">
											{nodeD}
										</div>
										{
											nodeErros.length > 0? <div className={" alert p-1 smallText d-inline-block "} style={{backgroundColor: "#ff00188c"}}>
												{
													nodeErros.map((e, key) => {
														return <div key={key} dangerouslySetInnerHTML={{ __html: typeof e == "object"? JSON.stringify(e) : e }}></div>
													})
												}
											</div> : ""
										}
										{
											nodeWarnings.length > 0? <div className="alert bg-warning p-1 smallText d-inline-block">
												{
													nodeWarnings.map((e, key) => {
														return <div key={key}>{typeof e == "object"? JSON.stringify(e) : e}</div>
													})
												}
											</div> : ""
										}
									</div>)
								}
							}
						}
						if(nodeV.length > 0)
							nodeP.push(<div style={{
								borderBottom: "4px solid #CCC"
							}}>
								<div>Product ID: {pSku}</div>
								{nodeV}
							</div>)
					}
				}
			})

		props.modal({
			show: true,
			title: "Last Result",
			html: (close) => {
				return <div>
					<div className="d-flex justify-content-center">
						<div className={(loadPos <= 0? "d-none" : "") + " input-group-prepend"}>
							<button className="btn btn-dark" style={{borderRight: "1px solid #CCC"}} onClick={() => {
								loadXSummaries(1, loadPos-1, 1);
								setLoadPos(loadPos-1)
							}}>Prev</button>
						</div>
						<div className="input-group-append">
							<button className="btn btn-dark" onClick={() => {
								loadXSummaries(1, loadPos+1, 1);
								setLoadPos(loadPos+1)
							}}>Next</button>
						</div>
					</div>
					{
						<div className="text-center" style={{
							maxHeight: "800px",
							overflowY: "auto"
						}}>
							<p className="font-weight-bold">number: {loadPos+1}{loadPos===0? " (Latest)" : ""}</p>
							{ nodeP }
						</div>
					}
				</div>
			},
			return: {
				title: "Close",
				fnc: (popup, close) => {
					setModalOpened(false)
					close()
				}
			},
			exit: (popup, close) => {
				setModalOpened(false)
				close()
			}
		})
	}

	const modalIssuesFixes = (dataType) => {

		let classColor = dataType == "error"? "alert-danger" : dataType == "warning"? "alert-warning" : dataType == "success"? "alert-success" : "alert-info"
		
		console.info("Modal itemsWithIssue", itemsWithIssue);
		console.info("refConst.stores", refConst.stores);

		let idStoreToName = [];
		refConst.stores.forEach(s => {
			idStoreToName[s.id] = s.name
		})

		let nodeP = []
		if(itemsWithIssue)
			itemsWithIssue.forEach((p, key) => {

				let nodeV = []
				p.variants.forEach((variant, key) => {

					let nodeD = [],
						nodeErros = [],
						nodeWarnings = []

					for (const idStore in variant.status) {
						if (Object.hasOwnProperty.call(variant.status, idStore)) {
							const data = variant.status[idStore];
							let idVariant = variant.ids_variant[idStore]

							let hasIssue = false;
							let bg = "bg-light"
							if(parseInt(data.created)){
								bg = "bg-success"
							}else if(data.errors){
								bg = "bg-danger"
								nodeErros = data.errors;
								hasIssue = true;
							}else if(data.warning){
								bg = "bg-warning"
								nodeWarnings = data.warning;
								hasIssue = true;
							}

							if(variant.status[idStore].markedAsCreated)
								bg = "bg-info"

							nodeD.push(<div className="d-flex flex-column align-items-center justify-content-center">
								<button className={(!data.markedAsCreated? "" : "d-none") + " btn btn-sm btn-outline-danger mt-2 ml-1 mr-1"} onClick={() => {
									alterVariant({
										"id": idVariant,
										"id_auto_creator_product": p.id,
										"id_marketplace": idStore,
										"created": 1,
										"note": "Product was created but had errors, it was removed from the creator and will not be updated anymore.",
									}, null, (r) => {
										console.info('alterVariant', r);

										if(r.success){
											variant.status[idStore].markedAsCreated = true
											setItemsWithIssue(itemsWithIssue.slice(0))
										}
									})
								}}>Remove from creator</button>
								<div className={"border rounded m-3 d-flex align-items-center justify-content-center " + bg} style={{
									height: "50px",
									width: "80px",
									fontSize: "10px",
								}}>
									{idStoreToName[idStore]}
								</div>
							</div>
							)
						}
					}

					if(p.sku != "created" && nodeD.length > 0){

						nodeV.push(<div style={{
							borderBottom: "2px dotted #CCC"
						}}>
							<div style={{
								fontSize: "12px",
							}}>Variant ID: {p.sku}</div>
							<div className="d-flex justify-content-center">
								{nodeD}
							</div>
							{
								nodeErros.length > 0? <div className={" alert p-1 smallText d-inline-block "} style={{backgroundColor: "#ff00188c"}}>
									{
										nodeErros.map((e, key) => {
											return <div key={key} dangerouslySetInnerHTML={{ __html: typeof e == "object"? JSON.stringify(e) : e }}></div>
										})
									}
								</div> : ""
							}
							{
								nodeWarnings.length > 0? <div className="alert bg-warning p-1 smallText d-inline-block">
									{
										nodeWarnings.map((e, key) => {
											return <div key={key}>{typeof e == "object"? JSON.stringify(e) : e}</div>
										})
									}
								</div> : ""
							}
						</div>)
					}
				})
				if(nodeV.length > 0)
					nodeP.push(<div style={{
						borderBottom: "4px solid #CCC"
					}}>
						<div>Product ID: {p.sku}</div>
						{nodeV}
					</div>)
			})

		props.modal({
			show: true,
			title: "List of all issues",
			html: (close) => {
				return <div>
					<div className="alert alert-primary smallText text-center" role="alert">
						You might need to look often to this window to know if you need to create a missing category or remove a unwanted product with errors.
					</div>
					<div className="d-flex justify-content-center">
						<div className={(loadPos <= 0? "d-none" : "") + " input-group-prepend"}>
							<button className="btn btn-dark" style={{borderRight: "1px solid #CCC"}} onClick={() => {
								loadIssueList(loadPos-1, 100);
								setLoadPos(loadPos-1)
							}}>Prev</button>
						</div>
						<div className="input-group-append">
							<button className="btn btn-dark" onClick={() => {
								loadIssueList(loadPos+1, 100);
								setLoadPos(loadPos+1)
							}}>Next</button>
						</div>
					</div>
					{
						<div className="text-center" style={{
							maxHeight: "800px",
							overflowY: "auto"
						}}>
							<p className="font-weight-bold">number: {loadPos+1}{loadPos===0? " (Latest)" : ""}</p>
							{ nodeP }
						</div>
					}
				</div>
			},
			return: {
				title: "Close",
				fnc: (popup, close) => {
					setModalOpened(false)
					close()
				}
			},
			exit: (popup, close) => {
				setModalOpened(false)
				close()
			}
		})
	}

	const fetchLastSummary = (args, loadingFnc, callback) => {
		
		if(loadingFnc)	loadingFnc()
		
		ajaxPerso(Object.assign({
			"api": "autoCreator",
			"trigger": "get_autoCreate_summary_result",
			"filters": JSON.stringify({
				"last": true
			})
		}, args), callback);
	}

	const loadXSummaries = (number, from, length) => {

		let filters = {
			"last": number
		}

		console.info("from", from);
		console.info("length", length);

		if(from !== null && length !== null)
			filters.page = from
			filters.limit = length
		
		fetchLastSummary( {
			"filters": JSON.stringify(filters)
		}, null, (r) => {
			console.info('fetchLastSummary', r);
			if(r.success){
	
				let listErrors = [],
					listWarning = [],
					listSuccess = [],
					listSummarize = [];
	
				r.data.forEach(d => {
					
					let newSumm = props.organise_summarize(d.summarize_json);
					console.info("newSumm", newSumm);
					console.info("d.summarize_json", d.summarize_json);
	
					listErrors = newSumm.listErrors.concat(listErrors);
					listWarning = newSumm.listWarning.concat(listWarning);
					listSuccess = newSumm.listSuccess.concat(listSuccess);
					listSummarize = [d.summarize_json].concat(listSummarize);
	
				});
	
				console.info("SSSSSSS22", {
					listErrors,
					listWarning,
					listSuccess
				});
				setResultInfo({
					listErrors: listErrors,
					listWarning: listWarning,
					listSuccess: listSuccess,
					listSummarize: listSummarize
				})
			}
		})
	}

	const loadIssueList = (page, limit) => {

		let filters = {}

		if(page !== null && limit !== null)
			filters.page = page
			filters.limit = limit
		

			fetchAutoCreateProduct( Object.assign({
			"onlyWithSmallestSeleableUnit": null,
			"filterOutput": "t1.id, t1.sku, t1.smallest_saleable_unit",
			"isCreated": 0,
			"reviewed": 1,
			"errors": 1,
			"warning": 1,
			"doNotCreate": 0
		}, filters), () => {

		}, (r) => {
			if(!initialState._isMounted)	return false
			console.info('fetchAutoCreateProduct issues', r);
			setItemsWithIssue(r.success? r.data : initialState.itemsWithIssue)
		});
	}

	const fetchAutoCreatorItemPerSku = (skuList, id_store, callback) => {
		
		if(!skuList)	throw("fetchAutoCreatorItemPerSku Please indicate a sku");

		if(!Array.isArray(skuList))
			skuList = [skuList]

		let args = {
			"api": "autoCreator",
			"trigger": "fetch_auto_creator_product_variant_per_sku",
			"skuList": skuList,
		}

		if(id_store)
			args.id_store = id_store

		ajaxPerso(args, callback);
	}

	const alterVariant = (args, loadingFnc, callback) => {
		
		if(!args)	throw("alterVariant");
		if(loadingFnc)	loadingFnc()
		
		ajaxPerso({
			"api": "autoCreator",
			"trigger": "alter_auto_creator_product_variant",
			"data": args,
		}, callback);
	}

	const sectionView = (sentense, colorCode, callback) => {
		
		return <div className={"mt-2 ml-3 mr-3 mb-2 p-4 rounded text-light font-weight-bold" + (callback? " pointer" : "")} onClick={callback? () => callback() : null} style={{
			backgroundColor: colorCode,
			textShadow: "0 0 2px BLACK"
		}}>
			{sentense}
		</div>
	}

	// console.info("itemDataitemData", itemData);
		
	return (<div className={("className" in props? props.className : "") + " d-flex flex-row"}>
		<div className="d-flex flex-row">
			{/* {
				sectionView(
					`${countToReview} to Review`,
					"#3498DB"
				)
			} */}
			{
				sectionView(
					`${countCreated} Created`,
					"#A569BD"
				)
			}
			{
				sectionView(
					`${countToCreate} Awaiting Creation`,
					"#48C9B0"
				)
			}
		</div>
		<div className="d-flex flex-row">
			{
				sectionView(
					`Last Results`,
					"#4e4e4e",
					() => {
						loadXSummaries(1);
						setModalOpened(true)
						modalLastResult()
					}
				)
			}
			{
				sectionView(
					itemsWithIssueCount + ` Issue to fixe`,
					"#f55050",
					() => {
						loadIssueList(0, 100);
						setModalOpened(true)
						modalIssuesFixes()
					}
				)
			}
		</div>
	</div>);
}