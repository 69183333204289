import React, { Component } from 'react';
import $ from 'jquery'; 
import {
	Link
} from 'react-router-dom';
import {ajaxPerso, fetchAvgPerSku} from "../fnc.js"
import moment from 'moment';

const refConst = require("../constants.js").v

var initialState = {
	packages: [],
	data: [],
	avg: false,
	limit: 100,
	error: false,
	success: false,
	warning: false
};

class AvgShipping extends Component {
	constructor(props) {
		super(props);

		if(!this.props.securityClearance(this.props))	return;

		this.state = Object.assign({}, initialState);

		this.search = React.createRef()
		this.startAt = React.createRef()
		this.qty = React.createRef()
		this.package = React.createRef()
		this.international = React.createRef()

		// Bind Allow access by "this" into the function
		this.submitSearch = this.submitSearch.bind(this);
		this.updatePackage = this.updatePackage.bind(this);
		this.clearResult = this.clearResult.bind(this);
	}

	/* componentWillMount() {
		this.initialState = this.state
	} */

	componentDidUpdate(prevProps){
		/*
		if(this.props.parent.match.params.idStore && prevProps.parent.match.params.idStore == this.props.parent.match.params.idStore){
			console.info('idStoreSelected Changed');
			//this.setState(Object.assign(this.state, {"idStoreSelected": this.props.parent.match.params.idStore}))
			//this.loadStoreData()
		}*/
	}

	componentDidMount() {
		
	}

	submitSearch(e) {
		e.preventDefault();
		this.props.loading(true)

		if(!this.search.current.value){
			this.props.info({"error": "Please Indicate a SKU"})
			return false
		}
		
		//return false;
		this.updatePackage()

		fetchAvgPerSku({
			"sku": this.search.current.value,
			"ship_start": this.startAt.current.value,
			"packageId": this.package.current.value,
			"qty": this.qty.current.value,
			"international": this.international.current.checked
		}, (r) => {
			if(r.success){
				this.props.loading(false)

				var sum = 0
				for( var i = 0; i < r.data.length; i++ ){
					sum += parseFloat( r.data[i].shipment_cost, 10 ) //don't forget to add the base
				}
				var avg = sum/r.data.length
				
				this.setState({"data": r.data, "avg": avg})
			}else if(r.error)
				this.props.info({"error": r.error})
		})

		/* ajaxPerso( {
			"api": "eio",
			"trigger": "getDataAvg",
			"sku": this.search.current.value,
			"ship_start": this.startAt.current.value,
			"packageId": this.package.current.value,
			"qty": this.qty.current.value,
			"international": this.international.current.checked
		}, (r) => {
			if(r.success){
				this.props.loading(false)

				var sum = 0
				for( var i = 0; i < r.data.length; i++ ){
					sum += parseFloat( r.data[i].shipment_cost, 10 ) //don't forget to add the base
				}
				var avg = sum/r.data.length
				
				this.setState({"data": r.data, "avg": avg})
			}else if(r.error)
				this.props.info({"error": r.error})
		}); */

		//props.info(nbrObj); // We push our object with @success, @error, @body
	}

	updatePackage() {
		if(!this.search.current.value)
			return false

		ajaxPerso( {
			"api": "shipstation",
			"trigger": "getPackageForSku",
			"sku": this.search.current.value
		}, (r) => {
			if(r.success){
				this.setState({"packages": r.data})
			}else if(r.error)
				this.props.info({"error": r.error})
		});
	}

	triggerData() {
		let data = this.state.data,
			returnData = [],
			lengthTab = data.length > this.state.limit? this.state.limit : data.length

		if(data && data.length > 0)
			for (let i = 0; i < lengthTab; i++) {
				const obj = data[i]

				if(!obj)
					continue
				
				returnData.push(<tr key={"list_" + i}>
					<th scope="row">{i+1}</th>
					<td>{obj.shipment_cost}</td>
					<td>{obj.item_price}</td>
					<td>{obj.weight}</td>
					<td>{obj.state? obj.state : "n/a"}</td>
					<td>{obj.shipped_at}</td>
					<td>{obj.ordered_at}</td>
					<td>{obj.store_name}</td>
				</tr>)
			}
		return returnData
	}


	clearResult() {
		this.setState(Object.assign({}, initialState))
	}

	clearMsg() {
		this.setState({"error": false, "warning": false, "success": false})
	}

	render() {
		if(!this.props.securityClearance(this.props))	return "";
		return (
			<div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main ctnPage mx-auto">
				<div className={(!this.state.error && !this.state.warning && !this.state.warning? "d-none " : "") + (this.state.warning? "bg-warning " : "") + (this.state.success? "bg-success " : "") + (this.state.error? "bg-danger " : "") + "p-3 m-2 text-white"}>
					{this.state.warning || this.state.success || this.state.error}
				</div>
				<h1 className="page-header mb-3">Average shipping cost</h1>
				<div>
					<form onSubmit={ (e) => { this.submitSearch(e); } }>
						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<button className="btn btn-outline-secondary" onClick={this.clearResult} type="button" id="button-addon1">Clear</button>
							</div>
							<input type="text" ref={this.search} className="form-control" placeholder="Search a SKU" aria-label="Recipient's username" aria-describedby="button-addon2" ></input>
							<div className="input-group-append">
								<button className="btn btn-outline-secondary bg-primary text-white" type="submit" id="button-addon2">Search</button>
							</div>
						</div>
					</form>
					<div className="row">
						<div className="col">
							<div className="input-group mb-3">
								<input type="number" ref={this.qty} defaultValue="1" onChange={this.submitSearch} className="form-control" placeholder="Quantity" aria-label="Quantity" aria-describedby="button-addon2"></input>
							</div>
						</div>
						<div className="col">
							<div className="input-group mb-3">
								<input type="date" ref={this.startAt} defaultValue={moment().subtract(1, 'year').format("YYYY-MM-DD")} onChange={this.submitSearch} className="form-control" placeholder="Start at" aria-label="Start at" aria-describedby="button-addon2"></input>
							</div>
						</div>
						<div className="col">
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<label className="input-group-text" htmlFor="inputGroupSelect01">Package</label>
								</div>
								<select ref={this.package} onChange={this.submitSearch} className="custom-select" id="inputGroupSelect01">
									<option value="">-</option>
									{
										this.state.packages.map((obj) => {
											return <option value={obj.id} key={obj.name}>{obj.name}</option>
										})
									}
								</select>
							</div>
						</div>
						<div className="col ml-3 d-flex align-items-center">
							<div className="input-group mb-3">
								<input ref={this.international} onChange={this.submitSearch} className="form-check-input" type="checkbox" id="defaultInternational" defaultChecked={false}></input>
								<label className="form-check-label" htmlFor="defaultInternational">International</label>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className={(this.state.avg? "" : "d-none") + " text-center text-white mx-auto p-2 h2 bg-primary"}>Average: {parseFloat(this.state.avg).toFixed(3)}</div>
					<div className="container">
						<div className="row">
							<div className="col-sm p-3">
								Information base on <span className="font-weight-bold">{this.state.data.length}</span> results
							</div>
						</div>
					</div>
					{
						this.state.data && this.state.data.length > 0?
							<table className="table">
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Shipping Cost</th>
										<th scope="col">Item Price</th>
										<th scope="col">weight</th>
										<th scope="col">State</th>
										<th scope="col">Shipping Date</th>
										<th scope="col">Ordered Date</th>
										<th scope="col">Store</th>
									</tr>
								</thead>
								<tbody>
									{ this.triggerData() }
								</tbody>
							</table>
						:
							<h5 className="p-3 text-center">
								Empty data for this sku.
							</h5>
					}
				</div>
			</div>
		);
	}
}

export default AvgShipping;
