import React, { Component } from 'react';
import $ from 'jquery'; 

class Info extends Component {
	constructor(props) {
		super(props);
		this.state = {
			second: 7
		};
		
		// Bind Allow access by "this" into the function
		this.responseMsg = this.responseMsg.bind(this);
	}

	/* componentWillMount() {
		this.initialState = this.state
	} */

	responseMsg() {
		let info = this.props.info()

		let val = info.success? info.success : info.error
		let output = val
		
		if(typeof val === "function" || typeof val === "object"){
			output = ""
			val.forEach(line => {
				output += line
			});
		}

		return output;
	}

	componentDidUpdate(prevProps){
		//console.info("Comp 'INFO' updated");

		let timoutInfo = null
		if(this.props.infoPresent()){
			// Reset the info object to remove
			let info = this.props.info()
			let secondTOWait = info.second? info.second : this.state.second

			console.info("info waiting time:", secondTOWait);

			timoutInfo = window.setTimeout(function(){
				prevProps.info(false)
				window.clearTimeout(timoutInfo)
			}, secondTOWait * 1000)
			
			/*let self = this
			timoutInfo = window.setInterval(function(){
				let second = self.state.second

				console.info(second)

				if(second == 0){
					prevProps.info(false)
					self.setState(self.initialState)
					window.clearInterval(timoutInfo)
				}else
					self.setState({"second": second-1})
			}, 1000)*/
		}
	}
	
	render() {
		//console.info('this.props.info().error', this.props.info().error);
		// console.log("info check", this.props.info());
		
		return (
			<div>
				{
					typeof this.props.info().success === "string"?
						<div className={(this.props.info().success? "alert alert-success " : " ") + (this.props.info().success.length > 0? "" : "d-none ") + " p-3 text-center mb-2"}>
							<span dangerouslySetInnerHTML={{__html: this.props.info().success}}></span>
						</div> :
						<div className={(this.props.info().success? "alert alert-success " : " ") + (this.props.info().success? "" : "d-none ") + " p-3 text-center mb-2"}>
							{ this.props.info().success }
						</div>

				}
				<div className={(this.props.info().error? "alert alert-warning " : " ") + (this.props.info().error.length > 0? "" : "d-none ") + " p-3 text-center mb-2"}>
					{this.props.info().error.length > 0 && typeof this.props.info().error[0] === "string"? <span dangerouslySetInnerHTML={{__html: this.props.info().error}}></span> : ''}
					{this.props.info().error.length > 0 && typeof this.props.info().error[0] === "object"? this.props.info().error.map((o, keyIndex) => {
						return <span key={`${keyIndex}_keyInfo`}>{o}</span>;
					}) : ''}
				</div>
			</div>
		);
	}
}

export default Info