// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { ajaxPerso, uniqueArray } from '../fnc'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import {
	NavLink
} from 'react-router-dom';
import { prefix } from "@fortawesome/free-solid-svg-icons";
import store from 'store2';
const refConst = require("../constants.js").v

var initialState = {
	stores: [],
	_isMounted: false
}

let modalOpenBuyXGetY = false

export default function BuyXGetY(props){

	const stores = refConst.stores.filter(o => {
		if(o.id_marketplace == 14)	return o
		return false
	})
	let objPreSelected = store("storeSelectedBxGy")
	const [shopSelected, setShopSelected] = useState(objPreSelected? objPreSelected : stores[0]);
	const [file, setFile] = useState([]);
	const searchTypes = [
		{"show": "Sku", "real": "searchSku"},
		{"show": "Title", "real": "searchTitle"},
		{"show": "Vendor", "real": "searchVendor"}
	];
	const [searchBy, setSearchBy] = useState(searchTypes[0]);
	const [searchResult, setSearchResult] = useState([]);
	const [buyrOrGetName, setBuyrOrGetName] = useState(null);
	const [currentBlockOpen, setCurrentBlockOpen] = useState(null);
	const [itemAddedToFile, setItemAddedToFile] = useState(false); // Just used to refresh our File view without going agin into our useEffect
	const [fileModified, setDataModified] = useState(false);
	const refInputSearch = useRef(null)

	useEffect(() => {
		initialState._isMounted = true;

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		$('[data-toggle="tooltip"]').tooltip({
			placement: "bottom"
		}).tooltip('update')
	})

	useEffect(() => {
		if(modalOpenBuyXGetY)
			selectProductModal(true);
	}, [searchBy, searchResult, file, buyrOrGetName, currentBlockOpen])

	useEffect(() => {
		if(!shopSelected)	return;
		init()
	}, [shopSelected])

	useEffect(() => {
		/**
		 * Need to load each product and add them to the main object attached to buyVariantIdList_item or getVariantIdList_item
		 */
		if(itemAddedToFile)	return;
		if(!file.length === 0)	return;

		let idVariantList = []
		file.forEach(o => {
			idVariantList = idVariantList.concat(o.buyVariantIdList, o.getVariantIdList)
		})
		idVariantList = uniqueArray(idVariantList)
		
		if(idVariantList.length === 0)	return;

		fetchShopifyItems( {
			idShopifyVariantList: idVariantList.join(',')
		}, (r) => {
			console.info(`Fetch fetchShopifyItems for entire file`, r);
			if(r.error){
				props.info({error: r.error})
			}else{

				r.data.forEach(p => {
					file.forEach(o => {

						if(!o.buyVariantIdList_item) o.buyVariantIdList_item = [];
						if(!o.getVariantIdList_item) o.getVariantIdList_item = [];

						if(o.buyVariantIdList)
							o.buyVariantIdList.forEach((buyVariantId, pos) => {
								if(buyVariantId == parseInt(p.id_variant_shopify)){
									idVariantList.push(buyVariantId)
									o.buyVariantIdList_item[pos] = p;
									return;
								}
							})
						if(o.getVariantIdList)
							o.getVariantIdList.forEach((getVariantId, pos) => {
								if(getVariantId == parseInt(p.id_variant_shopify)){
									idVariantList.push(getVariantId)
									o.getVariantIdList_item[pos] = p;
									return;
								}
							})
					})
				})
				console.info("FILE json", file);
				props.loading(false);
				setItemAddedToFile(true) // refresh our view with the new data from the file
			}
		})
	}, [file])

	const init = () => {
		setItemAddedToFile(false)
		props.loading(true);
		fetchBuyXgetY((r) => {
			console.info("Fetching fetchBuyXgetY", r);
			if(!r.errors && !r.id_file){
				props.info({error: `Please make sure that you have a buy_x_get_y.json in your Shopify admin.`})
			}
			setFile(r.data? r.data : [])
			props.loading(false);
		})
	}

	const submitSearch = (e) => {
		e.preventDefault();
		console.info("refInputSearch", refInputSearch);
		if(!refInputSearch || refInputSearch.current.value.length === 0){
			props.info({error: `Please fill up the search box.`})
		}else{

			fetchShopifyItems( {
				[searchBy.real]: refInputSearch.current.value
			}, (r) => {
				console.info(`Fetch fetchShopifyItems by ${searchBy.real}`, r);
				setSearchResult(r.success? r.data : [])
			})
		}
	}

	let fetchShopifyItems = (args, callbackItem) => {
		ajaxPerso(Object.assign({
			"api": "shopify",
			"trigger": "getItems",
			"fromNbr": 0,
			"nbrItem": 80,
			"shop": shopSelected.id
		}, args), callbackItem);
	}

	const selectProductModal = (refresh) => {
		console.info("POPPPPUP", buyrOrGetName);
		props.modal({
			show: refresh? false : true,
			title: "Select a product",
			html: (close) => {
				return <div>
					<form className="input-group mb-3" onSubmit={submitSearch}>
						<div className="input-group-prepend">
							<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">Search by {searchBy.show}</button>
							<div className="dropdown-menu">
								{
									searchTypes.map(typeO => {
										return <a className="dropdown-item" key={`searchBy_${typeO.show}`} onClick={() => {
											setSearchBy(typeO)
										}}>
											Search by {typeO.show}
										</a>
									})
								}
							</div>
						</div>
						<input type="text" className="form-control" ref={refInputSearch} />
						<div className="input-group-append">
							<button className="btn btn-outline-secondary" type="submit">Search</button>
						</div>
					</form>
					<div>
						{
							searchResult.length > 0? searchResult.map(d => {

								let imgSrc = d.images_json && d.images_json.length > 0? d.images_json[0].node? d.images_json[0].node.originalSrc : d.images_json[0].src : null
								
								return <div 
									key={d.id_product_shopify+"_searchResult"}
									style={{marginBottom: "1px"}}
									className={"d-flex flex-row align-items-center middeSizeText cursor p-0 lineHoverColor " + (file[currentBlockOpen][buyrOrGetName] && file[currentBlockOpen][buyrOrGetName].indexOf(parseInt(d.id_variant_shopify)) !== -1? "bg-primary text-light" : "")}
									onClick={() => {

										if(!file[currentBlockOpen][buyrOrGetName + "_item"])
											file[currentBlockOpen][buyrOrGetName + "_item"] = []

										let posFound = file[currentBlockOpen][buyrOrGetName].indexOf(parseInt(d.id_variant_shopify))

										if(posFound !== -1){
											file[currentBlockOpen][buyrOrGetName].splice(posFound, 1)
											file[currentBlockOpen][buyrOrGetName + "_item"].splice(posFound, 1)
										}else{
											file[currentBlockOpen][buyrOrGetName + "_item"].push(d)
											file[currentBlockOpen][buyrOrGetName].push(parseInt(d.id_variant_shopify))
										}

										console.info("TESTFILE", file);
										setDataModified(true)
										setFile(file.slice(0))
									}}>
									{d.images_json && d.images_json.length > 0? <img src={imgSrc} height="45px" width="45px" className="ml-3"/> : ''}
									<div className="p-1 d-inline-block ml-3">[<u>{d.sku}</u>] <u>${d.price}</u> <span className="ml-2">{d.title}</span></div>
								</div>
							}) : <div className="text-center">No result</div>
						}
					</div>
				</div>
			}
			,ok: {
				title: "Validate",
				fnc: (popup, close) => {
					modalOpenBuyXGetY = false
					setSearchResult([])
					close()
				}
			},
			return: {
				title: "Close",
				fnc: (popup, close) => {
					modalOpenBuyXGetY = false
					setSearchResult([])
					close()
				}
			}
		})
	}

	const fetchBuyXgetY = (callback) => {
		if(!initialState._isMounted)	return false
		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetch_buy_x_get_y",
			"shop": shopSelected.id
		}, callback);
	}

	const submitBuyXgetYChanges = (args, callback) => {
		if(!initialState._isMounted)	return false
		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "update_buy_x_get_y",
			"shop": shopSelected.id
		}, args), callback);
	}

	const forceUploadDiscount = (args, callback) => {
		if(!initialState._isMounted)	return false
		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "update_auto_discount_admin_file",
			"shop": shopSelected.id
		}, args), callback);
	}

	const addNewLine = () => {
		if(!initialState._isMounted)	return false
		let cpFile = file.slice(0)
		cpFile.unshift({
			"special_id": Date.now(),
			"manualCheckoutCode": null,
			"title": null,
			"type": "product", // product/collection
			"buyVariantIdList": [],
			"buyCollectionObj": null,
			"buyQty": 1,
			"buyValue": null,
			"getVariantIdList": [],
			"getValue": null,
			"getPercentage": null,
			"getQty": 1,
			"isAuto": false
		})
		setFile(cpFile)
	}

	const changeObject = (value, objFile, qtyPropName) => {
		setDataModified(true)
		objFile[qtyPropName] = value;
	}


	const saveChanges = () => {
		if(!fileModified)	return;
		props.loading(true);
		/**
		 * Need to clean up some property from the file
		 */
		let cpFile = JSON.parse(JSON.stringify(file))
		cpFile.forEach(o => {
			delete o.buyVariantIdList_item
			delete o.getVariantIdList_item
			delete o.checkoutCode // Remove old prop name
		});

		console.info("cpFile", cpFile);

		submitBuyXgetYChanges({
			data: JSON.stringify(cpFile)
		}, (r) => {
			console.info("Fetching submitBuyXgetYChanges", r);
			if(r.errors){
				props.info({error: r.errors})
			}else{
				props.info({success: "File updated successfully"})
			}
			props.loading(false);
		})
	}

	const triggerLoadDiscount = () => {
		props.loading(true);

		forceUploadDiscount({}, (r) => {
			console.info("Fetching forceUploadDiscount", r);
			if(r.errors){
				props.info({error: r.errors})
			}else{
				props.info({success: "Discounts saved, The data on this page will be reloaded in 7sec."})
				setTimeout(() => {
					init();
				}, 7000)
			}
			props.loading(false);
		})
	}

	const showUp = () => {
		
		return <div className="w-100">
			<div>
				<div className="alert alert-primary m-3 w-100 text-wrap" role="alert">
					It is recommanded to check on the store that a discount works the expected way once done setting up.
				</div>
				<div className="alert alert-warning m-3 w-100 text-wrap" role="alert">
					If you just need to add a product in the cart base on another product in it, then you can do it on this page by adding a rule.
					<br/>OR<br/>
					If you want to get a product in the cart directly shown as free, you can create an automatic &quot;Buy X get Y&quot; discount directly on Shopify, it will then be imported here.
				</div>
			</div>
			<div className="border radius m-3 p-3 w-100 d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					<button type="button" className="btn btn-primary d-flex align-items-center mr-3" onClick={init} title="Reload">
						<svg style={{height: "25px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
							<path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
							<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
						</svg>
					</button>
					<button type="button" className="btn btn-primary d-flex align-items-center mr-3" onClick={addNewLine}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill mr-2" viewBox="0 0 16 16">
							<path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
						</svg>
						Add rule
					</button>
					<button type="button" className="btn btn-primary d-flex align-items-center" onClick={triggerLoadDiscount} title="Force the download of our Shopify discount">
						<svg style={{height: "25px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
							<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
							<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
						</svg>
					</button>
				</div>
				{
					fileModified? <button type="button" className="btn btn-success" onClick={saveChanges}>Save changes</button> : ""
				}
			</div>
			<div className="m-3 w-100">
				{
					file? file.map((o, pos) => {
						return <div key={`file_block_${pos}`} className="border radius bg-light mb-4">
							<div className="pl-3 pr-3 pt-3 d-flex justify-content-between align-items-center">
								{ o.title? <span className="mr-3 font-weight-bold">{o.title}</span> : ""}
								<span className="badge badge-pill badge-info mr-3">Managed on {o.isAuto? "Shopify" : "tools.work"}</span>
								{ o.manualCheckoutCode? <span className="badge badge-pill badge-info mr-3">Code {o.manualCheckoutCode? o.manualCheckoutCode : ""}</span> : "" }
							</div>
							{
								!o.isAuto? <div className="d-flex align-items-center justify-content-between p-3">
										<div className="d-flex flex-row align-items-center">
											<button type="button" className="btn btn-danger mr-3" title="Remove this rule" onClick={() => {
												file.splice(pos, 1)
												setFile(file.slice(0))
												setDataModified(true)
											}}>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
													<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
												</svg>
											</button>
											<button type="button" className="btn bg-primary text-white" onClick={() => {
												modalOpenBuyXGetY = true
												setCurrentBlockOpen(pos)
												setBuyrOrGetName("buyVariantIdList")
												selectProductModal(false)
											}}>Add product needed to buy</button>
											<div style={{width: "150px"}} className="input-group ml-3">
												<input type="number" defaultValue={o.buyQty} className="form-control" aria-describedby="basic-addon-buy-qty" onChange={(e) => changeObject(parseInt(e.currentTarget.value), o, 'buyQty')} />
												<div className="input-group-append">
													<span className="input-group-text" id="basic-addon-buy-qty">Buy Qty</span>
												</div>
											</div>
										</div>
										<div className="d-flex flex-row align-items-center">
											<div style={{width: "150px"}} className="input-group">
												<input type="number" defaultValue={o.getQty} className="form-control" aria-describedby="basic-addon-get-qty"  onChange={(e) => changeObject(parseInt(e.currentTarget.value), o, 'getQty')}/>
												<div className="input-group-append">
													<span className="input-group-text" id="basic-addon-get-qty">Get Qty</span>
												</div>
											</div>
											<button type="button" className="btn bg-primary text-white ml-3" onClick={() => {
												modalOpenBuyXGetY = true
												setCurrentBlockOpen(pos)
												setBuyrOrGetName("getVariantIdList")
												selectProductModal(false)
											}}>Add product added to cart</button>
											
										</div>
								</div> : ""
							}
							{ !o.isAuto? <div className="pl-3 pr-3 middeSizeText">Not required fields</div> : "" }
							{ !o.isAuto? <div className="d-flex flex-row pl-3 pr-3 pb-2">
								<div style={{width: "350px"}} className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text" id="basic-addon-buy-title">Title</span>
									</div>
									<input type="text" defaultValue={o.title} className="form-control" aria-describedby="basic-addon-buy-title" onChange={(e) => changeObject(e.currentTarget.value, o, 'title')} />
								</div>
								<div className="d-flex align-items-center ml-1" data-toggle="tooltip" title="The title only facilitate the research of this promo"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></div>
								<div style={{width: "350px"}} className="input-group ml-3">
									<div className="input-group-prepend">
										<span className="input-group-text" id="basic-addon-buy-code">Promo Code</span>
									</div>
									<input type="text" defaultValue={o.manualCheckoutCode} className="form-control" aria-describedby="basic-addon-buy-code" onChange={(e) => changeObject(e.currentTarget.value, o, 'manualCheckoutCode')} />
								</div>
								<div className="d-flex align-items-center ml-1" data-html="true" data-toggle="tooltip" title={`The code will appear under the product in the cart<br/>"Discount available when using the code: ${o.manualCheckoutCode? o.manualCheckoutCode : ''}"`}><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></div>
							</div> : "" } 
							<div className="d-flex justify-content-around pb-3 pt-3">
								<div className="w-50">
									<div className="w-100 p-2 bg-dark text-white">Buying condition</div>
									<div className="p-3 text-wrap">
										{
											o.buyVariantIdList_item? o.buyVariantIdList_item.map(p => <div key={`buyVariantId_item_${p.id}`}>
												<div><span className="d-inline-block" style={{minWidth: "100px"}}>Product:</span></div>
												<div><span className="d-inline-block" style={{minWidth: "100px"}}>Sku:</span>{p.sku}</div>
												<div><span className="d-inline-block overflow-hidden text-truncate" style={{minWidth: "100px"}}>Title:</span>{p.title}</div>
												<div><span className="d-inline-block" style={{minWidth: "100px"}}>Price:</span>{p.price}</div>
											</div>) : ""
										}
										{
											o.buyCollectionObj? <div>
												{
													o.buyCollectionObj.map(c => <div key={`buyCollectionObj_${c.id}`}>
														<div><span className="d-inline-block" style={{minWidth: "180px"}}>Collection title:</span>{c.title}</div>
													</div>)
												}
												{
													o.buyValue? <div>
														<span className="d-inline-block" style={{minWidth: "180px"}}>Minimum purchasse:</span>{o.buyValue}
													</div> : ""
												}
											</div> : ""
										}
									</div>
								</div>
								<div className="w-50">
									<div className="w-100 p-2 text-white" style={{backgroundColor: "#5d4343"}}>Selling condition</div>
									<div className="p-3">
										{
											o.getVariantIdList_item? o.getVariantIdList_item.map(p => <div key={`getVariantId_item_${p.id}`}>
												<div>
													<span className="d-table-cell" style={{minWidth: "80px"}}>Product:</span>
												</div>
												<div>
													<span className="d-table-cell" style={{minWidth: "80px"}}>Sku:</span>
													<span className="d-table-cell text-wrap">{p.sku}</span>
												</div>
												<div>
													<span className="d-table-cell overflow-hidden text-truncate" style={{minWidth: "80px"}}>Title:</span>
													<span className="d-table-cell text-wrap">{p.title}</span>
												</div>
												<div>
													<span className="d-table-cell" style={{minWidth: "80px"}}>Price:</span>
													<span className="d-table-cell text-wrap">{p.price}</span>
												</div>
											</div>) : ""
										}
									</div>
								</div>
							</div>
						</div>
					}) : ""
				}
			</div>
		</div>
	}

	
	if(!props.securityClearance(props))	return "";
	
	return (
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" value={ shopSelected? JSON.stringify(shopSelected) : false } onChange={(e) => {
					store("storeSelectedBxGy", JSON.parse(e.target.value))
					setShopSelected(JSON.parse(e.target.value))
				}}>
					{
						stores.length > 0? stores.map(o => {
							return <option key={o.name} value={JSON.stringify(o)}>{o.name}</option>
						}) : []
					}
				</select>
			</div>
			{showUp()}
		</div>
	);
}

