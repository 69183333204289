// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { capitalizeFLetter } from '../../fnc'
import { utils, writeFile, read } from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

const refConst = require("../../constants.js").v


export default function SheetCapture(props){

	const initialState = {
		// linesCount: 0,
		jsonFile: false,
		nameFileSelected: null,
		sheetSelected: false,
		columnIndexSelected: [],
		startLineXlsx: 0,
		required: props.required || [],
		allowed: props.valuesAllowed || [],
		allowArrayForSupData: props.allowArrayForSupData? true : false
	}
	
	const selectPrefix = useRef(null)
	const inputPrefix = useRef(null)
	const inputFile = useRef(null)
	const [linesCount, setLinesCount] = useState(0); // This var should never have an initialState cause it must not get reset
	const [formatAccepted, setFormatAccepted] = useState(["xlsx", "xls"]);
	const [jsonFile, setJsonFile] = useState(initialState.jsonFile);
	const [nameFileSelected, setNameFileSelected] = useState(initialState.nameFileSelected);
	const [sheetSelected, setSheetSelected] = useState(initialState.sheetSelected);
	const [columnIndexSelected, setColumnIndexSelected] = useState(initialState.columnIndexSelected);
	const [startLineXlsx, setStartLineXlsx] = useState(initialState.startLineXlsx);
	const [version, setVersion] = useState("0.9.5");
	const [required, setRequired] = useState(initialState.required);
	const [allowed, setAllowed] = useState(initialState.allowed);
	const [allowArrayForSupData, setAllowArrayForSupData] = useState(initialState.allowArrayForSupData);

	/* useEffect(() => {
		console.info("jsonFile", jsonFile)
	}) */

	useEffect(() => {
		//console.info('props.required', required);
		
		modalSheet(true);
	}, [jsonFile, nameFileSelected, sheetSelected, columnIndexSelected, startLineXlsx, required, allowed])

	useEffect(() => {
		console.info('props.dataSheet', props);
		
		if(!props.dataSheet || props.dataSheet.length === 0)
			setLinesCount(0)
	}, [props.dataSheet])

	useEffect(() => {
		if(JSON.stringify(props.required) !== JSON.stringify(required))
			setRequired(props.required || initialState.required)
	}, [props.required])

	useEffect(() => {
		if(JSON.stringify(props.allowed) !== JSON.stringify(allowed))
			setAllowed(props.allowed || initialState.allowed)
	}, [props.allowed])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
		inputFile.current.value = ""
	}

	let handleChange = (event) => {
		event.preventDefault();
		
		//console.log(event.target.files);
		var files = event.target.files;
		event.value= null; // Important if we select the same file multiple time.

		// loop through files
		for (var i = 0; i < files.length; i++) {
			let file = files.item(i);// get item

			if(formatAccepted.indexOf(file.name.split('.').pop()) === -1){
				resetPage();
				props.info({"error": "Please, Select a file in the following type: " + JSON.stringify(formatAccepted)});
			}else{

				var reader = new FileReader();
				// eslint-disable-next-line
				reader.addEventListener("load", function () {

					var workbook = read(reader.result, {type:"buffer"});
					var roa = {};
					workbook.SheetNames.sort().forEach(function(sheetName) {
						// Json parse and stringify is there to keep the calumn when when the value is "empty"
						roa[sheetName] = JSON.parse(JSON.stringify(utils.sheet_to_json(workbook.Sheets[sheetName], {defval: '', blankrows: false, raw:true, header: 1})));
						roa[sheetName] = roa[sheetName].map(elem => {
							if(Array.isArray(elem))
								return elem.map(elem2 => {
									return String(elem2).trim()
								});
							else
								return String(elem).trim()
						});
					});

					let defaultSheet = initialState.sheetSelected;
					for (const sheetName in roa)
						if (roa.hasOwnProperty(sheetName)) {
							const element = roa[sheetName];
							if(roa[sheetName].length > 0){
								defaultSheet = sheetName;
								break;
							}
						}

					setSheetSelected(defaultSheet);
					setJsonFile(roa);
					setNameFileSelected(file.name);
					
				}, false);
				reader.readAsArrayBuffer(file);
			}
		}
		return event;
	}

	let returnColumnList = () => {
		let out = [];
		if(jsonFile && sheetSelected && jsonFile[sheetSelected] && jsonFile[sheetSelected].length > 0){
			out = jsonFile[sheetSelected][0]
		}
		return out;
	}

	let addPrefixToColumn = (e) => {
		e.preventDefault()

		if(jsonFile && sheetSelected && jsonFile[sheetSelected]){
			let column = parseInt(selectPrefix.current.value)
			let prefix = inputPrefix.current.value

			for (let i = 1; i < jsonFile[sheetSelected].length; i++) {
				if(jsonFile[sheetSelected][i][column].trim().length > 0)
					jsonFile[sheetSelected][i][column] = prefix.concat(jsonFile[sheetSelected][i][column])
			}
		}
		e.currentTarget.reset()
		setJsonFile(Object.assign({}, jsonFile))
	}

	let contentForm = () => {
		// console.info('jsonFile[sheetSelected]', jsonFile[sheetSelected]);
		
		return (<div className="container">
			<form onSubmit={(event) => {
				event.preventDefault()
			}} encType="multipart/form-data" method="post">
				<label>
					<span className="pr-3">Name:</span>
					<div className="custom-file">
						<input ref={inputFile} type="file" className="custom-file-input" id="validatedCustomFile" required={true} onChange={handleChange}></input>
						<label className="custom-file-label" htmlFor="validatedCustomFile">Choose file...</label>
						<div className="invalid-feedback">Example invalid custom file feedback</div>
					</div>
				</label>
				<div>
					You must select an xlsx file with the following format:
					<ul>
						<li>Data grouped by column</li>
						<li>The first line must be reserved for your title (SKU, PRICE, DATE...)</li>
						<li>Data start at the second line (the line after the title)</li>
						{ required? <li className="font-weight-bold">Required column: {required.join(', ')}</li> : '' }
						{ "valuesAllowed" in props && props.valuesAllowed? <li className="font-weight-bold">Value allowed: <u>{props.valuesAllowed.join(' / ')}</u></li> : '' }
						{ "ctnAdded" in props && props.ctnAdded? props.ctnAdded : '' }
					</ul>
				</div>
			</form>
			<div className={!jsonFile? "invisible" : ""}>
				<h4>File selected: <u>{nameFileSelected}</u></h4>
				{ required? <p className="msgWarning">Please, select the column corresponding to <u>&quot;{required.join("', '")}&quot;</u></p> : '' }
				<div className="w-100">
					<div className="input-group mb-3 w-25">
						<div className="input-group-prepend">
							<span className="input-group-text" id="basic-addon1">Start at line:</span>
						</div>
						<input type="number" step="1" className="form-control" placeholder="0" aria-label="Username" aria-describedby="basic-addon1" onChange={(val) => {
							setStartLineXlsx(val.target.value);
							// resetPage();
							return val.target;
						}} />
					</div>
					<div className="mb-3 bg-light p-3 border">
						<form onSubmit={addPrefixToColumn}>
							<p>Add Prefix</p>
							<div className="input-group">
								<div className="input-group-prepend">
									<select ref={selectPrefix} className="btn btn-outline-secondary custom-select" id="inputGroupSelect02">
										{
											returnColumnList().map((t, i) => {
												return <option key={i} value={i}>{t}</option>
											})
										}
									</select>
								</div>
								<input ref={inputPrefix} type="text" className="form-control" aria-label="Text input with dropdown button" />
								<div className="input-group-append">
									<button className="btn btn-outline-secondary" type="submit">Add</button>
								</div>
							</div>
						</form>
					</div>
				</div>

				<div className={ (columnIndexSelected.length > 0? "" : "d-none") + " p-2 mb-3 bg-info text-white" }>
					Column selection:
					<ul className="p-0 m-0 pl-4">{columnIndexSelected.map((name, key) => {
						if(key < required.length)
							return <li key={key}><label className="pr-2">Position: {++name}, Type: { (required[key])}</label></li>
						return false
					})}
					</ul>
				</div>
				
				{<div className="text-nowrap overAuto">
					<ul className="listNameSheet">
						{ (Object.keys(jsonFile)).map((name, key) => <li key={key} className={(sheetSelected === name? "active" : "noOk")} onClick={(e) => {
							setSheetSelected(name)
						}}>{name} ({jsonFile[name].length > 1? jsonFile[name].length-1 : 0})</li>) }
					</ul>
					{/* { (jsonFile && sheetSelected? addDataForm(sheetSelected) : '') } */}
				</div>}
			</div>
		</div>)
	}
	let contentTable = (sheetName) => {
		return (<div style={{
			overflow: "auto",
			maxHeight: "400px",
		}} className="position-relative">
			<table className="table table-hover table-bordered">
				<thead>
					<tr>
						{ vueTableHtml(sheetName).head }
					</tr>
				</thead>
				<tbody>
					{ vueTableHtml(sheetName).body }
				</tbody>
			</table>
		</div>
		)
	}

	let selectColumnView = (index) => {
		//let val = e.target.innerHTML
		//let valName = e.target.innerHTML;
		let cp = columnIndexSelected.slice(0)

		if(cp.indexOf(index) === -1){
			cp.push(index)
		}else
			cp.splice(cp.indexOf(index), 1)
		
		setColumnIndexSelected(cp)
	}

	let vueTableHtml = (sheetName) => {
		let data = jsonFile[sheetName],
			tabOut = [],
			headTbl = [],
			firstLine = true;

		if(data){
			for (let i = startLineXlsx; i < (data.length > 100? 100 : data.length); i++) {
				const dataLine = data[i];
				let tabColumn = [];
	
				//console.log(dataLine);
				
				//for (const key in dataLine) {
				if(dataLine)
					// eslint-disable-next-line
					dataLine.forEach((calumnVal, index) => {
						let typeSelected = required && required.length > 0? required[columnIndexSelected.indexOf(index)] : false
						let typeSelectedAllowed = allowed && allowed.length > 0? allowed[columnIndexSelected.indexOf(index)] : false
						let typeSel = typeSelectedAllowed? typeSelectedAllowed : typeSelected
						if(firstLine){
							headTbl.push(<th key={index} className={ (columnIndexSelected.indexOf(index)) >= 0? "bg-secondary text-light" : "bg-light" } onClick={() => selectColumnView(index) }>
								<div className="text-nowrap" style={{
									overflow: "hidden",
									overflowX: "auto",
									height: "25px",
									maxWidth: "300px"
								}}>{calumnVal? calumnVal + (typeSel? ` (${typeSel})` : '') : '-'}<FontAwesomeIcon icon={columnIndexSelected.indexOf(index) >= 0? faCheckCircle : faCircle} style={{margin: "0px 10px"}}/></div>
							</th>);
						}else{
							tabColumn.push(<td key={index}><div className="text-nowrap" style={{
								overflow: "hidden",
								overflowX: "auto",
								height: "25px",
								maxWidth: "300px"
							}}>{calumnVal? calumnVal : '-'}</div></td>);
						}
					});
				
				firstLine = false;
				tabOut.push(<tr key={i}>{tabColumn}</tr>)
			}	
		}
		return {"head": headTbl, "body": tabOut};
	}


	let modalSheet = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: <span>Select Data From Sheet {JSON.stringify(formatAccepted).split('"').join('')} <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			options: {
				width: "modal-xl"
			},
			html: (popup) => {

				return <div>
					{contentForm()}
					{contentTable(sheetSelected)}
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: sheetSelected? {
				title: "Validate",
				fnc: (popup, close) => {

					if(sheetSelected === initialState.sheetSelected){
						props.info({error: "Please select a sheet."})
					}else if(columnIndexSelected.length < required.length){
						props.info({error: "Please select the right columns."})
					}else if("returnAll" in props){
						let data = jsonFile[sheetSelected],
							columnsName = null,
							columnsData = [],
							listO = [];

						if(data.length > 1){
							console.info('data', data);
							console.info('columnIndexSelected', columnIndexSelected);

							let keysHeaderList = data[0]
							columnsName = columnIndexSelected.map(pos=>{
								return keysHeaderList[pos]
							});


							// Remove empty lines in required column
							for (let i = 1; i < data.length; i++) {
								columnIndexSelected.forEach((pos, index) => {
									if(data[i][pos].trim().length === 0 && required[index]){
										data.splice(i, 1)
										i--;
									}
								})
							}

							// Organise Data
							for (let i = 1; i < data.length; i++) {

								columnIndexSelected.forEach((pos, index) => {
									if(!columnsData[index] && (!allowArrayForSupData || index <= required.length-1))	columnsData[index] = [];

									// If more column were selected than required, we should assume that everything past the required number represent more data to add to the last required column.
									// In this case we create an array of value for the last column.
									if(allowArrayForSupData && index === required.length-1 && columnIndexSelected.length > required.length){
										columnsData[index][columnsData[index].length] = [data[i][pos]];
									}else if(allowArrayForSupData && index > required.length-1 && columnIndexSelected.length > required.length){
										columnsData[columnsData.length-1][columnsData[columnsData.length-1].length-1].push(data[i][pos]);
									}else{
										columnsData[index].push(data[i][pos]);
									}
								})

								// Other way of showing data grouped by listed object. Just for conveniance
								let cO = {}
								let countAddedValue = 0;
								/* columnIndexSelected.forEach((pos, index) => {
									// If more column were selected than required, we should assume that  everything past the required number represent more data to add to the last required column.
									let key = required[index]
									if(!key){
										countAddedValue++
										key = required[required.length-1] + `_${countAddedValue}`
									}
									cO[key] = data[i][pos];
								}) */

								columnIndexSelected.forEach((pos, index) => {
									// If more column were selected than required, we should assume that everything past the required number represent more data to add to the last required column.
									// In this case we create an array of value for the last column.
									if(allowArrayForSupData && index === required.length -1 && columnIndexSelected.length > required.length){
										cO[required[index]] = [data[i][pos]];
									}else if(allowArrayForSupData && index > required.length -1 && columnIndexSelected.length > required.length)
										cO[required[required.length-1]].push(data[i][pos]);
									else
										cO[required[index]] = data[i][pos];
								})

								listO.push(cO)
							}
						}

						console.info('listO', listO);
						console.info('columnsData', columnsData);
	
						if(!props.onChange)
							props.info({error: "Please indicate a onChange argument."})
						else{
							setLinesCount(columnsData.length);
							props.onChange({
								columnName: columnsName.slice(0, required.length),
								columnData: columnsData.slice(0, required.length),
								sheet: data,
								required: props.required,
								listObj: listO
							})
						}

						resetPage()
						close()

					}else if(JSON.stringify(columnIndexSelected) === JSON.stringify(initialState.columnIndexSelected)){
						props.info({error: "Please select a column by clicking on it's header."})
					}else if(jsonFile[sheetSelected].length > 0){
						let data = jsonFile[sheetSelected]
	
						// console.info('data', data);
						// console.info('columnIndexSelected', columnIndexSelected);

						let listOut = [];
						let keysList = data[0];

						let colomnAllowed = allowed && allowed.length > 0? allowed : required;
						for (let i = 1; i < data.length; i++) {
							let objOut = {};
							columnIndexSelected.forEach((valColumn, u) => {
								if(data[i][valColumn].trim().length > 0){
									// objOut[keysList[valColumn]] = data[i][valColumn];
									objOut[colomnAllowed[u]] = data[i][valColumn];
								}
							})
							if(Object.keys(objOut).length > 0)
								listOut.push(objOut)
						}
	
						if(!props.onChange)
							props.info({error: "Please indicate a onChange argument."})
						else{
							setLinesCount(listOut.length);
							props.onChange(listOut)
						}

						props.info({success: `Successfully selected ${listOut.length} entries`})
						console.info('sheet data out', listOut);
						resetPage()
						close()
					}else{
						props.info({error: "No data selected"})
					}
				}
			} : null, return: {
				title: "Return",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}

	console.info('linesCount', linesCount);
	
	/* console.info("jsonFile", jsonFile)
	console.info("initialState.jsonFile", initialState.jsonFile) */
	return (
		<button type="button" className={(props.className || "") +  " btn btn-outline-primary " + ("isActive" in props && props.isActive? "btn-primary text-light" : "")} onClick={() => {
			if("onClick" in props)	props.onClick()
			modalSheet(props.storeTo, props.storeFrom)
		}}>
			{ props.name } {linesCount? `( ${linesCount} )` : ''}
		</button>
	);
}