// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { fetchBrands, ajaxPerso } from '../../../fnc'
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
const refConst = require("../../../constants.js").v

const styleList = {
	container: (provided) => ({
		...provided,
		minWidth: "250px"
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

function ListView(props){

	const [idAplusOpen, setIdAplusOpen] = useState(null)

	useEffect(() => {
		setIdAplusOpen(props.parent.match.id)
	}, [props.parent.match.id])

	const deleteAplus = (idAplus) => {

		if(!props.shopIdSelected || !idAplus){
			props.info({error: "No shop selected or no A+ selected"})
			return;
		}

		props.modal({
			show: true,
			title: "Confirmation",
			html: () => {
				return <div>
					Are you sure you want to delete this a+
				</div>
			}
			,ok: {
				title: "I'm sure",
				fnc: (popup, close) => {
					
					ajaxPerso( {
						"api": `shopify`,
						"id": idAplus,
						"shop": props.shopIdSelected,
						"trigger": "del_aplus"
					}, (r) => {
						console.info('Execute del_aplus:', r);
						let info = {error: false, success: false}
						if(r.success){
							info.success = "A+ deleted"
							if(r.resPrdUpdate)
								info.success += `, ${r.resPrdUpdate.data.updated.length} product updated`;
							props.loadEverything()
							close()
						}else{
							info.error = r.error
						}
						console.warn('info', info);
						props.info(info)
						props.parent.history.push("/AplusV2")
					})
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	const viewListMeta = () => {

		let output = <div className="w-100">
			<div>
				Total: {props.aPlusList.length}
			</div>
			<div className="list-group">
				{
					props.aPlusList.map(o => {
						
						let cr_email = null;
						// let up_email = null;
						props.accounts.forEach(oA => {
							if(o.id_account == oA.id)	cr_email = oA.data_firebase.email
						})

						return <div key={o.id} className="list-group-item list-group-item-action p-2 pointer d-flex flex-row d-flex justify-content-between">
							<h5 className="d-flex align-items-center m-0">
								<span className="badge badge-primary">{ o.key }</span>
								<span className={(o.is_active? "badge-success" : "badge-warning") + " badge ml-3"}>{o.is_active? 'Active' : 'Inactive'}</span>
								{/* <span className={(o.id_meta? "" : "d-none") + " badge badge-info ml-3"}>id Meta: { o.id_meta }</span> */}
								<span className={(!o.id_meta? "" : "d-none") + " badge badge-danger ml-3"}>No Meta attached</span>
								<span className={(o.random_meta_key_generated? "" : "d-none") + " badge badge-info ml-3"}>Tag: { o.random_meta_key_generated }</span>
								<button className={(o.random_meta_key_generated? "" : "d-none") + " btn btn-outline-primary btn-sm ml-3"} onClick={() => {
									navigator.clipboard.writeText(o.random_meta_key_generated).then(function() {
										/* clipboard successfully set */
										props.info({success: `${o.random_meta_key_generated} Copied in your Clipboard`})
									}, function() {
										props.info({error: "Copy to clipboard failled."})
									});
								}}>Copy Tag</button>
								<span className={(o.desciption? "" : "d-none") + " badge badge-light ml-3 text-wrap"}>Info: { o.desciption }</span>
							</h5>
							<span className="d-flex flex-row">
								<div className="d-flex flex-column">
									<span className={(o.id_account? "" : "d-none") + " badge badge-warning ml-3"}>Created by: { cr_email }</span>
									{/* <span className={(idBlockFound.uid_updated? "" : "d-none") + " badge badge-warning ml-3 mt-1"}>Last Update: { up_email }</span> */}
								</div>
								<button type="button" className="btn btn-danger ml-3" onClick={() => {
									deleteAplus(o.id)
								}}><FontAwesomeIcon icon={faTrashAlt} title={"Delete"}/></button>
								<button type="button" className={(o.history_count? "" : "d-none") + " btn btn-primary ml-3"} onClick={() => {
									props.loadDbAplusHistory(o.id)
								}}><FontAwesomeIcon icon={faHistory} title={"History"}/></button>
								<NavLink className="ml-3" to={{
									pathname: "/AplusV2/CreateNew/" + o.id,
									// search: "?mode=Create",
								}}><button type="button" className="btn btn-primary">Modify</button></NavLink>
							</span>
						</div>
					})
				}
			</div>
		</div>

		return <div className="mt-3">{output}</div>;
	}
		
	return (<div className={("className" in props? props.className : "")}>
		<div className="alert alert-primary w-100 text-wrap mt-4" role="alert">
			Create or Update an A+ content, A+ represents a section on the website that highlights a product per its type and brand.
		</div>
		{ viewListMeta() }
	</div>);
}

export default ListView;