// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import {
	NavLink
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import { faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons'
import Filter from './filter'
import Loading from '../Loading';
import {ajaxPerso} from "../../../fnc.js"
const refConst = require("../../../constants.js").v
//var moment = require('moment');

function WorstSelling(props){

	const [version, setVersion] = useState("1.2.0");
	const [loadingOn, setLoadingOn] = useState(true);
	const [chart, setChart] = useState(null);
	const [historyQty, setHistoryQty] = useState([]);
	const [choiceSorting, setChoiceSorting] = useState({});
	const [oldestEntryInStock, setOldestEntryInStock] = useState(null);
	
	const [fromTo, setFromTo] = useState({
		from: null, // new Date(moment().subtract(3, 'years').format()),
		to: null //new Date(moment().subtract(3, 'years').format())
	});
	/* const [from, setFrom] = useState(new Date(moment().subtract(3, 'years').format()));
	const [to, setTo] = useState(new Date(moment().subtract(2, 'years').format())); */
	const [sorting, setSorting] = useState([
		{name: "Stock Value", value: "stock_value"},
		{name: "Date Last Sold", value: "date_last_sold"},
		{name: "Qty On Hand", value: "qty_on_hand"},
	]);
	const [sortingSelected, setSortingSelected] = useState("date_last_sold");

	const [sortingOrder, setSortingOrder] = useState([
		{name: "Ascendant (1-9)", value: "ASC"},
		{name: "Descendant (9-1)", value: "DESC"},
	]);
	const [sortingOrderSelected, setSortingOrderSelected] = useState("ASC");

	const [top, setTop] = useState(15);
	const [previousSearch, setPreviousSearch] = useState({
		"to": fromTo.to, 
		"from": fromTo.from,
		"top": top,
		"sorting": sortingSelected,
		"sortingOrder": sortingOrderSelected
	});

	useEffect(() => {
		if(
			previousSearch.top != top || 
			previousSearch.to != fromTo.to || 
			previousSearch.from != fromTo.from || 
			previousSearch.sorting != sorting || 
			previousSearch.sortingOrder != sortingOrder
		){
			if(fromTo.to && fromTo.from){
				loadHistoryQty();
				setPreviousSearch({
					"to": fromTo.to, 
					"from": fromTo.from,
					"top": top,
					"sorting": sortingSelected,
					"sortingOrder": sortingOrderSelected
				})
			}
		}
	}, [top, fromTo, sortingSelected, sortingOrderSelected])

	useEffect(() => {
		loadOldestItemInStock();
		//loadHistoryQty();
	}, [])

	const loadHistoryQty = (args, callback) => {
		fetchHistoryQty(args, (r) => {
			if(r.success){
				if(r.res && Object.keys(r.res).length > 0)
					setHistoryQty(r.res)
			}else{
				props.info({"error": r.error})
			}
			setLoadingOn(false)
		})
	}

	const loadOldestItemInStock = (args, callback) => {
		fetchOldestItemInStock(args, (r) => {
			if(r.success){
				if(r.res && Object.keys(r.res).length > 0){
					setOldestEntryInStock(r.res[0])
					setFromTo({
						from: new Date(moment(r.res[0].date_last_sold).format()),
						to: new Date(moment(r.res[0].date_last_sold).add(1, 'years').format())
					})
					/* setFrom(new Date(moment(r.res[0].date_last_sold).format()))
					setTo(new Date(moment(r.res[0].date_last_sold).add(1, 'years').format())) */
				}
			}else{
				props.info({"error": r.error})
			}
		})
	}

	const fetchOldestItemInStock = (args, callback) => {
			
		ajaxPerso( Object.assign({
			"trigger": "get_worst_selling",
			"api": "finale",
			"sortBy": "date_last_sold",
			"sortingOrder": "asc",
			"limit": 1,
		}, args), callback)
	}

	const fetchHistoryQty = (args, callback) => {
		//console.log("TEST", filterDate());
		
		ajaxPerso( Object.assign({
			"trigger": "get_worst_selling",
			"api": "finale",
			"dateFrom": fromTo.from? moment(fromTo.from).format("YYYY-MM-DD") : null,
			"dateTo": fromTo.to? moment(fromTo.to).format("YYYY-MM-DD"): null,
			"sortBy": sortingSelected,
			"sortingOrder": sortingOrderSelected,
			"limit": top,
		}, args), callback)
	}

	const listValue = () => {
		let tempOrderedTab = historyQty.slice(0);

		for (const key in choiceSorting) {
			if (choiceSorting.hasOwnProperty(key)) {
				tempOrderedTab.sort((o1, o2) => {
					//console.log(o1[key] + " " + choiceSorting[key] + " " +  o2[key]);
					
					if(choiceSorting[key] === "<" && o1[key] < o2[key])	return 1
					if(choiceSorting[key] === ">" && o1[key] > o2[key])	return 1
					return -1
				})
			}
		}

		/* console.info('choiceSorting', choiceSorting);
		console.info('historyQty', tempOrderedTab); */
		

		return <ul className="list-group">
			{
				tempOrderedTab.map((o, index) => {
					return(<li key={o.id} className="list-group-item">
						<div className="container">
							{
								(index === 0? <div className="row font-weight-bold mb-3 border-bottom">
									<div className="col-sm" style={{maxWidth: "70px"}}>
										N:
									</div>
									<div className="col-sm">
										ID:
									</div>
									<div className={(choiceSorting.sku? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('sku') === -1)
											choiceSorting.sku = '>'
										else if(choiceSorting.sku === '>')
											choiceSorting.sku = '<';
										else
											delete choiceSorting.sku

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										SKU: { choiceSorting.sku? <FontAwesomeIcon icon={ choiceSorting.sku === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.qty_on_hand? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('qty_on_hand') === -1)
											choiceSorting.qty_on_hand = '>'
										else if(choiceSorting.qty_on_hand === '>')
											choiceSorting.qty_on_hand = '<';
										else
											delete choiceSorting.qty_on_hand

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Qty On Hand { choiceSorting.qty_on_hand? <FontAwesomeIcon icon={ choiceSorting.qty_on_hand === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.stock_value? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('stock_value') === -1)
											choiceSorting.stock_value = '>'
										else if(choiceSorting.stock_value === '>')
											choiceSorting.stock_value = '<';
										else
											delete choiceSorting.stock_value

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Stock Value { choiceSorting.stock_value? <FontAwesomeIcon icon={ choiceSorting.stock_value === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.date_last_sold? "bg-primary text-white" : "" ) + " col-sm"} onClick={() => {
										if(Object.keys(choiceSorting).indexOf('date_last_sold') === -1)
											choiceSorting.date_last_sold = '>'
										else if(choiceSorting.date_last_sold === '>')
											choiceSorting.date_last_sold = '<';
										else
											delete choiceSorting.date_last_sold

										setChoiceSorting(Object.assign({}, choiceSorting))
									}}>
										Date Last Sold { choiceSorting.date_last_sold? <FontAwesomeIcon icon={ choiceSorting.date_last_sold === '>'? faSortAmountDownAlt : faSortAmountUp } style={{marginLeft: "12px"}} /> : '' }
									</div>
									<div className={(choiceSorting.date_last_sold? "bg-primary text-white" : "" ) + " col-sm"}>
										Marketplace
									</div>
								</div> : "")

							}
							<div className="row">
								<div className="col-sm" style={{maxWidth: "70px"}}>
									{index+1}
								</div>
								<div className="col-sm">
									{o.id}
								</div>
								<div className="col-sm">
									<a href={refConst.finaleLinkProduct + btoa('/eio/api/product/' + o.sku)} target="_blank" rel="noopener noreferrer">{o.sku}</a>
								</div>
								<div className="col-sm">
									{o.qty_on_hand}
								</div>
								<div className="col-sm">
									{o.stock_value}
								</div>
								<div className="col-sm">
									{o.date_last_sold}
								</div>
								<div className="col-sm" style={{maxWidth: "120px", overflowY: "auto"}}>
									{o.name_store}
								</div>
							</div>
						</div>
						
					</li>)
				})
			}
		</ul>
	}

	return (
		<div className={props.className + " main ctnPage d-flex flex-column flex-wrap border pr-3 pl-3 pb-3 pt-1 position-relative"}>
			<p className="h5">Last Selling Date By Item In Stock</p>
			<Filter
				//from={fromTo.from}
				//setFrom={setFrom}
				setFromTo={(newFromTo) => {
					setFromTo(Object.assign({}, newFromTo))
				}}
				fromTo={fromTo}
				/* to={to}
				setTo={setTo} */
				top={top}
				setTop={setTop}
				moreFilters={
					<span className="d-flex flex-row">
						<div className="input-group ml-3" style={{width: "235px"}}>
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">Sorting:</span>
							</div>
							<select value={sortingSelected} className="form-control" onChange={(val) => {
								setSortingSelected(val.target.value)
							}}>
								{
									sorting.map(o => {
										return <option key={o.value} value={o.value}>{o.name}</option>
									})
								}
							</select>
						</div>
						<div className="input-group ml-3" style={{width: "235px"}}>
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1">Sorting order:</span>
							</div>
							<select value={sortingOrderSelected} className="form-control" onChange={(val) => {
								setSortingOrderSelected(val.target.value)
							}}>
								{
									sortingOrder.map(o => {
										return <option key={o.value} value={o.value}>{o.name}</option>
									})
								}
							</select>
						</div>
					</span>
				}
			/>
			{ oldestEntryInStock? <span className="mt-2 font-weight-light">Oldest item found at: {oldestEntryInStock.date_last_sold}</span> : '' }
			<div>
				<div className="mt-4" style={{maxHeight: "400px", overflowX: "auto"}}>
					{ listValue() }
					{/* <canvas id="ctnChartBestSelling" className="w-100"></canvas> */}
				</div>
			</div>
			<Loading loading={loadingOn} centered={true} />
			<h6 className="position-absolute text-secondary" style={{right: "15px", bottom: "0px", fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6>
		</div>

	);
}

export default WorstSelling;