import React, { Component } from 'react';
import MainMenu from './MainMenu';
import './Pages/css/header.css';
import './Pages/css/form.css';
import ModalTicket from './Pages/ctn/ModalTicket';
// import $ from 'jquery';
import {
	// Link,
	NavLink
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
var appName = "EIO Tools";
const refConst = require("./constants.js").v

class HeadPage extends Component {
	
	adminSection(tabLink){
		let level = this.props.getLevel()

		if(["admin"].indexOf(level) >= 0){
			return (
				<div className="dropdown mr-3">
					<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Admin
					</button>
					<div className="dropdown-menu " style={{zIndex: "1060", left: "auto", right: 0}} aria-labelledby="dropdownMenu2">
						{ tabLink }
					</div>
				</div>
			)
		}else return ""
	}

	render() {
		
		let link = (new MainMenu({"info": this.props.info, "getLevel": this.props.getLevel, "getLevels": this.props.getLevels, "getUser": this.props.getUser, "modal": this.props.modal})).getLink();
		return (

			<nav className="navbar navbar-dark bg-dark d-flex  align-items-center justify-content-between">
				<span className="navbar-brand">{appName}</span>

				<div className="justify-content-end d-flex align-items-center">
					<div className="mr-3">
						<div type="button" className=" d-flex align-items-center text-light btnTicket">
							<ModalTicket {...this.props} className="" title={<FontAwesomeIcon icon={faEnvelope} title={"Ticket"}/>} />
						</div>
					</div>
					<div className="mr-3">
						<button type="button" className=" d-flex align-items-center text-light btnReload" style={{backgroundColor: "transparent", border: "none"}} onClick={() => {
							this.props.info({success: "Process initialized, please wait !"})
							this.props.cacheRefresh(() => {
								//this.props.history.location.reload();
								window.location.reload(true);
							})
						}}><FontAwesomeIcon icon={faSyncAlt}/></button>
					</div>
					{<div className="dropdown mr-3">
						<button className="btn bg-primary dropdown-toggle text-light" type="button" id="dropdownTask" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Tasks
							<span className="badge badge-light ml-2 mr-1">{this.props.taskList? this.props.taskList.length : "0"}</span>
						</button>
						<div className="dropdown-menu p-0 overflow-auto" aria-labelledby="dropdownTask" style={{maxHeight: "280px", zIndex: "1060", left: "auto", right: 0}}>
							<ul className="list-group ctnUlTaskHeader">
								{
									this.props.taskList && this.props.taskList.length > 0? this.props.taskList.map(obj => {
										// console.info('objobjobj', obj);
										
										return <li key={obj.id} className="list-group-item d-flex justify-content-between align-items-center">
											<span className="d-inline-block" style={{width: "200px"}}>
												<span className="mr-2" style={{fontSize: "12px"}}>{ obj.name }</span>
											</span>
											{
												!parseInt(obj.finished)? <div className="spinner-border spinner-border-sm text-primary" role="status">
													<span className="sr-only">Loading...</span>
												</div> : <div className="d-flex align-items-center">
													{ obj.data && obj.data.product && obj.data.product.id? <NavLink to={ "/shopify/" + obj.data.product.id }>Open</NavLink> : '' }
													{ obj.successful && obj.data && obj.type_name === "shopify_product" && obj.data.data && obj.data.data.updated && obj.data.data.updated.length === 1 && obj.data.data.updated[0].id_response? <NavLink to={ "/shopify/" + obj.data.data.updated[0].id_response }>Open</NavLink> : '' }

													{
														(obj.type_name && obj.type_name === "report" && obj.data.id_report? <a title="Download File" href={`${refConst.urlServer}?token=${refConst.tokenEio}&trigger=reportDl&api=eio&idReport=${obj.data.id_report}`} target="_blank" rel="noopener noreferrer" download><FontAwesomeIcon icon={faFileExcel} /></a> : "")
													}{
														(obj.successful? <FontAwesomeIcon icon={faCheckCircle} className="ml-3"/> : <FontAwesomeIcon icon={faExclamationCircle} className="ml-3"/>)
													}
												</div>
											}
										</li>
									}) : ""
								}
							</ul>
						</div>
					</div>}
					{ this.adminSection(link.admin) }
					<button onClick={() => {

						// $(".body").toggleClass("mobileVopen")
						// $(".btnToggleMenu").toggleClass("open")
						this.props.toggleMenu()

					}} className="btn btnToggleMenu btn-secondary" type="button">
						<span className="navbar-toggler-icon"></span>
					</button>
				</div>
			</nav>
			
		);
	}
}

export default HeadPage;
