import React, { Component } from 'react';
import $ from 'jquery'; 
const refConst = require("../../../constants.js").v

class Modal extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
		};

		// Bind Allow access by "this" into the function
		//this.fetchItems = this.fetchItems.bind(this);
	}

	/* componentWillMount() {
		this.initialState = this.state
	} */

	componentDidUpdate(prevProps){
	}

	componentDidMount() {
	}

	render() {
		return (
			<div className="modal fade" id="mainModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className={(this.props.modal.title? "" : "d-none") + "modal-header"}>
							<h5 className="modal-title" id="exampleModalCenterTitle">{this.props.modal.title}</h5>
							<button type="button" className="close m-1" data-dismiss="modal" aria-label="Close" onClick={this.props.fncModalExit}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body text-center mb-3 font-weight-bold">
							{this.props.modal.body}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.fncModalRefuse.fnc}>{this.props.fncModalRefuse.name}</button>
							<button type="button" className="btn btn-primary" onClick={this.props.fncModalAccept.fnc}>{this.props.fncModalAccept.name}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Modal;
