// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { ajaxPerso, fetchShopifyItems, capitalizeFLetter } from '../../../fnc'
import ShopifySearchProduct from '../ShopifySearchProduct';
import ModalSpecialPromo from '../ModalSpecialPromoV2';
import moment from 'moment';
import SheetCapture from '../SheetCapture';

var initialState = {
	refreshView: 0,
	dataSheet: false,
	newPromoList: false,
	newPromoListResult: false,
	submitDisabled: false,
	newPromo: {
		"item": null/* {
			id_product_shopify: 6783481905175,
			id_variant_shopify: 40222601183255,
			id_product: 5119974,
			price: 269.99,
			compare_at_price: null,
			sku: "WIHA-91239"
		} */,
		"promo": null
	}
}

// const refConst = require("../../../constants.js").v
var _isMounted = false

export default function AddPromo(props){

	const [newPromo, setNewPromo] = useState(JSON.parse(JSON.stringify(initialState.newPromo)));
	const [dataSheet, setDataSheet] = useState(initialState.dataSheet);
	const [newPromoList, setNewPromoList] = useState(initialState.newPromoList);
	const [newPromoListResult, setNewPromoListResult] = useState(initialState.newPromoListResult);
	const [submitDisabled, setSubmitDisabled] = useState(initialState.submitDisabled);

	const [refreshView, setRefreshView] = useState(initialState.refreshView);

	useEffect(() => {
		_isMounted = true;
		return () => _isMounted = false
	}, [])

	useEffect(() => {

		// Reset the 
		setNewPromo(JSON.parse(JSON.stringify(initialState.newPromo)))

	}, [props.shopSelected])

	useEffect(() => {
		
		if(dataSheet.length > 0){
			setSubmitDisabled(false)
			fetchAndSortItemData()
		}

		return () => _isMounted = false

	}, [dataSheet])

	/* const changeObject = (value, objFile, qtyPropName) => {
		setRefreshView(refreshView+1)
		objFile[qtyPropName] = value;
	} */

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	const resetPromo = (value) => {
		newPromo.promo = value;
		setRefreshView(refreshView+1)
	}

	const fetchAndSortItemData = () => {

		if(!dataSheet || dataSheet.length == 0)	return;

		const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
		let dt = dataSheet.filter(o => {

			if(dateTimeRegex.test(o.starting_date) && dateTimeRegex.test(o.ending_date)) {
				return o
			}
		})

		if(dt.length == 0){
			props.info({error: "The date format is not correct. Please use the format: 2023-12-21 23:59:00"})
		}else{

			fetchShopifyItems({
				"shop": props.shopSelected.id,
				"skuList": dt.map(o => o.sku)
			}, (r) => {
				console.info("fetchShopifyItems", r);
	
				if(r.success && r.data.length > 0){
					// We need to merge the data from dataSheet and r.data
					let newData = []
					dt.forEach(d => {
						r.data.some(db => {
							if(d.sku == db.sku){
								newData.push(Object.assign({...d}, {
									id_product_shopify: db.id_product_shopify,
									id_variant_shopify: db.id_variant_shopify,
									id_marketplace: props.shopSelected.id,
									original_price: db.price,
								}))
								return true
							}
						})
					});
					console.info("newDatanewData", newData);
					setNewPromoList(newData)
				}else
					setNewPromoList(initialState.newPromoList)
			})
		}
	}

	const submitPromo = () => {

		let objReq = {
			"id_marketplace": props.shopSelected.id,
			"id_product_shopify": newPromo.item.id_product_shopify,
			"id_variant_shopify": newPromo.item.id_variant_shopify,
			"change_type": newPromo.promo.value_type,
			"change_value": newPromo.promo.value,
			"original_price": newPromo.item.price,
			"starting_date": moment.unix(newPromo.promo.startingDate).format('YYYY-MM-DD HH:mm:ss'),
			"ending_date": moment.unix(newPromo.promo.endingDate).format('YYYY-MM-DD HH:mm:ss')
		}

		if(newPromo.promo.tempOriginalPrice > 0.0)
			objReq.temp_original_price = newPromo.promo.tempOriginalPrice

		objReq.uid = props.getUser().uid

		ajaxPerso( {
			"api": "shopify",
			"trigger": "add_promo",
			"method": "POST",
			"json": JSON.stringify(objReq)
		}, (resPromo) => {
			console.info("Triggered 'add_promo':", resPromo);
			if(resPromo.success){
				props.info({success: "Promo Saved."})
				setNewPromo(JSON.parse(JSON.stringify(initialState.newPromo)))
			}else{
				props.info({error: resPromo.errors})
			}
		})
	}

	const submitPromoList = () => {

		console.info("newPromoListnewPromoList", newPromoList);
		
		let newPlRes = []
		newPromoList.forEach((o, pos) => {

			o.uid = props.getUser().uid
			/* console.info("objReqobjReq", o);
			return; */

			ajaxPerso( {
				"api": "shopify",
				"trigger": "add_promo",
				"method": "POST",
				"json": JSON.stringify(o)
			}, (resPromo) => {
				console.info(`Triggered 'add_promo'[${pos}]:`, resPromo);
				props.info({success: "Promo Saved."})  

				newPlRes.push({
					res: resPromo.resAddNew,
					errors: resPromo.errors,
				})
				setNewPromoListResult(newPlRes.slice(0))
			})
		})
	}

	const saveNewPromo = () => {
		setSubmitDisabled(true)
		if(!newPromoList || newPromoList.length === 0)
			submitPromo()
		else
			submitPromoList()
	}
		
	return (<div className={("className" in props? props.className : "")}>
		<div className="border radius mb-4">
			<span className="position-relative bg-white pl-2 pr-2" style={{bottom: "14px", left: "4px"}}>Create a new promo</span>
			<div className="alert alert-primary m-0 ml-3 mr-3">
				Frist select a product, then set the promo specifics into the setting window and validate.<br/>
				Or<br/>
				You can also use the <b>From Sheet</b> button to create multiple promos at once with a xlsx file. <a target="_blank" rel="noopener noreferrer" href="https://tools.ehub.work/DL/template_promo_creation.xlsx">Template</a>
			</div>
			<div>
				<div className="d-flex align-items-center justify-content-between p-3">
					<div className="d-flex flex-row align-items-center">
						{/* <button type="button" className="btn btn-danger mr-3" title="Remove this rule" onClick={() => {
								setNewPromo({})
								setRefreshView(refreshView+1)
							}}>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
							<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
							</svg>
						</button> */}

						<SheetCapture className={"mr-3"} dataSheet={dataSheet} onClick={() => {
							resetPage()
						}} onChange={(val) => {

							console.info('SheetCapture val:', val);
							setDataSheet(val)

						}} name={"From Sheet"} {...props}
							valuesAllowed={['sku', 'change_type', 'change_value', 'starting_date', 'ending_date', 'temp_original_price']}
							required={['sku', 'change_type', 'change_value', 'starting_date', 'ending_date']}
							ctnAdded={<div className="pt-3">
								<div className="mb-2">Download our template: <a target="_blank" rel="noopener noreferrer" href="https://tools.ehub.work/DL/template_promo_creation.xlsx">Template</a></div>
								<div className="alert alert-primary">
									<ul>
										<li>
											<b>change_type</b> allow only: <b>percentage</b> or <b>fixed_amount</b>
										</li>
										<li>
											<b>change_value</b> is the value of the discount related to what you have choosen for <b>change_type</b><br/>
											Ex: if change_type is set to: <b>percentage</b>, <b>20</b> represent 20% off the current price autocalulated.<br/>
											Ex: if change_type is set to: <b>fixed_amount</b>, <b>159.99</b> represent the new price of the item.<br/>
										</li>
										<li>
											<b>starting_date</b> and ending_date are the date of the promo in the format: 2023-12-21 23:59:00
										</li>
									</ul>
								</div>
							</div>}
						/>
						<div className="mr-3">
							OR
						</div>
						<ShopifySearchProduct
							{...props}
							className={"btn bg-primary text-white"}
							shopId={props.shopSelected.id}
							submitDisabled={submitDisabled}
							title={"Select a product"}
							itemSelected={newPromo.item? newPromo.item.id_variant_shopify : null}
							onClick={() => {
								resetPage()
							}}
							updateItem={(item, close) => {
								console.info("idItemidItem", item);
								newPromo.item = item
								newPromo.item.id_variant_shopify = parseInt(item.id_variant_shopify)
								newPromo.item.price = parseFloat(item.price)
								newPromo.item.compare_at_price = item.compare_at_price? parseFloat(item.compare_at_price) : null
								setRefreshView(refreshView+1)
								setSubmitDisabled(false)
							}}
						/>
						{
							newPromo.item? <ModalSpecialPromo
								{...props}
								title={"Settings"}
								className={"btn bg-primary text-white ml-3"}
								promoMetaObj={newPromo.promo}
								//setPromoMetaObj={resetPromo}
								onClick={(triggerProcess) => {
									if("id_variant_shopify" in newPromo.item && newPromo.item.id_variant_shopify){
										triggerProcess()
									}else{
										props.info({error: "Please select a product first."})
									}
								}}
								onSubmit={(promo, close) => {
									console.info("close", promo);
									newPromo.promo = promo
									setRefreshView(refreshView+1)
								}}
								price={newPromo.item.price}
								originalPrice={newPromo.item.compare_at_price? newPromo.item.compare_at_price : newPromo.item.price}
							/> : ""
						}
						{newPromo.item? <span className="ml-3 badge badge-info">sku: {newPromo.item.sku}</span> : ""}
						{newPromo.item? <span className="ml-3 badge badge-info">Original price: {newPromo.item.price}</span> : ""}
						{
							newPromo.promo? <div className="d-flex flex-column">
								<span className="ml-3 badge badge-info">Discount type: {newPromo.promo.value_type}</span>
								<span className="ml-3 badge badge-info mt-1">Value discount: {newPromo.promo.value}</span>
							</div> : ""
						}
					</div>
					{newPromo.item && newPromo.promo || newPromoList && newPromoList.length > 0? <button type="button" className="btn btn-success mr-3" title="Create" onClick={saveNewPromo} disabled={submitDisabled}>Save</button> : ""}
				</div>
				<div className={(dataSheet.length > 0? "" : "d-none" ) + " pl-3 pr-3"}>
					<div>
						we have found <b>{newPromoList.length}/{dataSheet.length}</b> items.
					</div>
					<div style={{
						maxHeight: "150px",
						overflowY: "auto"
					}}>
						{
							newPromoList? newPromoList.map((o, pos) => {
								let res = newPromoListResult[pos] && (newPromoListResult[pos].res.updated || newPromoListResult[pos].res.processed)
								return <div key={`${o.sku}_dataset`} className="d-flex flex-row align-items-center justify-content-between pt-2 pb-2 pl-3 pr-3 border-bottom">
									<div className="d-flex flex-row smallText">
										<span className="mr-2">SKU: <b>{o.sku}</b></span>
										<span className="mr-2">{o.change_type}: <b>{o.change_value}</b></span>
										<span className="mr-2">
											<span className="badge badge-secondary mr-2 pl-2 pr-2 pt-1 pb-1">Start: {o.starting_date}</span>
											<span className="badge badge-secondary pl-2 pr-2 pt-1 pb-1">End: {o.ending_date}</span>
										</span>
										<span className="mr-2">Current Price: <b>{o.original_price}</b></span>
										<span className="mr-2"><b>{o.temp_original_price}</b></span>
										{newPromoListResult[pos]? <span className="mr-2">
											{newPromoListResult[pos].errors? <span className="alert alert-danger rounded p-2">{newPromoListResult[pos].errors.join(", ")}</span> : ""}
											{res? <span className="alert alert-success rounded p-2">Success</span> : ""}
										</span> : ""}
									</div>
								</div>
							}) : ""
						}
					</div>
				</div>
			</div>
		</div>
	</div>);
}