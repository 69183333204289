// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import { ajaxPerso } from '../fnc'
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
import { prefix } from "@fortawesome/free-solid-svg-icons";
import ChangeImage from "./ctn/imageShopify/ChangeImage";
import AddBrandPageImage from "./ctn/imageShopify/AddBrandPageImage";
import AddImageToCdn from "./ctn/imageShopify/AddImageToCdn";
import store from 'store2';
const refConst = require("../constants.js").v

var initialState = {
	stores: [],
	_isMounted: false
}

const subCatPrefix = [
	"subCatIcon_",
	"flag_",
	"brandPage_"
]

function ImagesShopify(props){

	const stores = refConst.stores.filter(o => {
		if(o.id_marketplace == 14)	return o
		return false
	})
	let objPreSelected = store("storeSelectedShImg")
	const [shopSelected, setShopSelected] = useState(objPreSelected? objPreSelected : stores[0]);

	const [shopifyStores, setShopifyStores] = useState([]);
	const [prefixSelected, setPrefixSelected] = useState(null);
	const [imagesData, setImagesData] = useState({imgs: [], info: null});

	useEffect(() => {
		initialState._isMounted = true;

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		if(refConst.stores.length > 0)
			setShopifyStores( refConst.stores.filter(o => {
				if(o.id_marketplace == 14)	return o
				return false
			}))
	}, [refConst.stores])

	useEffect(() => {
		if(!prefixSelected || !shopSelected.id)	return;
		props.loading(true);
		fetchImages({
			"shop": shopSelected.id,
			"filter_name": prefixSelected.value,
			"includeDetails": false
		}, (r) => {
			console.info('Result fetchImages', r);
			props.loading(false);
			if(r.errors)	props.info({"error": r.errors})
			setImagesData(r.success? {
				imgs: r.urlList,
				info: r.pageInfo
			} : {imgs: [], info: null})
		})
	}, [prefixSelected])

	const fetchImages = (args, callback) => {
		if(!initialState._isMounted)	return false
		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "get_admin_files",
			"autoLoad": false,
			"qty": 20,
		}, args), callback);
	}

	const loadMoreImages = () => {
		props.loading(true);
		fetchImages({
			"shop": shopSelected.id,
			"filter_name": prefixSelected.value,
			"includeDetails": false,
			"after": imagesData.info.endCursor
		}, (r) => {
			console.info('Result loadMoreImages', r);
			props.loading(false);
			if(r.errors)	props.info({"error": r.errors})
			else if(r.success){
				imagesData.imgs = imagesData.imgs.concat(r.urlList)
				imagesData.info = r.pageInfo
				setImagesData(Object.assign({}, imagesData))
			}
		})
	}

	const showUp = () => {
		
		return <div className="border radius m-3 p-3 w-100 bg-light">
			<div className="mb-3 d-flex flex-row align-items-center justify-content-center">
				<span className="mr-4">Select one of our special prefix</span>
				<Select
					isClearable
					styles={refConst.styleList}
					onChange={(selectedOption) => {
						setPrefixSelected(selectedOption? selectedOption : null)
					}}
					options={(() => {
						let valueReturn = []
						subCatPrefix.forEach(prefix => {
							valueReturn.push( {
								value: prefix,
								label: prefix
							})
						})
						return valueReturn
					})()}
					value={prefixSelected? [prefixSelected] : null}
					isSearchable={true}
					isMulti={false}
					placeholder={"Image prefix"}
				/>
			</div>
			<div className="d-flex flex-wrap justify-content-center">
				{
					imagesData.imgs.map(o => {
						let sizeChoice = o.height > 150? "contain" : "auto"
						return <div key={`${o.id}`} className="p-2 d-inline-block">
							<div className="" style={{backgroundImage: "url('"+o.url+"')", backgroundSize: sizeChoice, backgroundPosition: "center center", backgroundRepeat: "no-repeat", height: "150px", width: "150px"}}></div>
							<div className="mt-2">{o.date_created}</div>
						</div>
					})
				}
			</div>
			{imagesData.info && imagesData.info.hasNextPage? <div className="d-flex justify-content-center p-2"><button onClick={loadMoreImages}>Load More</button></div> : ""}
		</div>
	}

	
	if(!props.securityClearance(props))	return "";
	
	return (
		<div className="main ctnPage container d-flex flex-row flex-wrap">
			<div className="input-group mb-4">
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">Website</span>
				</div>
				<select className="custom-select font-weight-bold" value={ shopSelected? JSON.stringify(shopSelected) : false } onChange={(e) => {
					store("storeSelectedShImg", JSON.parse(e.target.value))
					setShopSelected(JSON.parse(e.target.value))
				}}>
					{
						stores.length > 0? stores.map(o => {
							return <option key={o.name} value={JSON.stringify(o)}>{o.name}</option>
						}) : []
					}
				</select>
			</div>
			{showUp()}
			<ChangeImage {...props} shop={shopSelected? shopSelected : null} />
			<AddBrandPageImage {...props} shop={shopSelected? shopSelected : null} />
			<AddImageToCdn {...props} shop={shopSelected? shopSelected : null} />
		</div>
	);
}

export default ImagesShopify;