// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons' */
import Select from 'react-select';
import { parseAjaxReturn, fetchStores, ajaxPerso, fetchBrands } from '../fnc'
import {
	NavLink,
	Redirect
} from 'react-router-dom';
import store from 'store2'; 

const refConst = require("../constants.js").v
/* const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999,
		//boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
		//border: "1px solid black",
	}),
	container: (provided) => ({
		...provided,
		width: "100%"
	}),
	control: provided => ({
		...provided,
		margin: 8,
		marginBottom: 0
	}),
} */

function MappingBrand(props){

	const [brandsObj, setBrandsObj] = useState([]);
	const [brands, setBrands] = useState([]);
	const [stores, setStores] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [selectedStore, setSelectedStore] = useState("all");
	const [selectedBrands, setSelectedBrands] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState("all");

	useEffect(() => {
		if(!props.securityClearance(props))	return "";
		props.loading(true)
		loadData()

		fetchStores((r) => {
			console.info('Fetched Stores:', r);
			if(r.success)
				setStores(r.data)
		})
		fetchTemplate((r) => {
			console.info('Fetched template:', r);
			if(r.success)
				setTemplates(r.data)
		})
	}, [])

	useEffect(() => {
		let objPreSelected = store("storeSelected")
		
		if(objPreSelected)
			setSelectedStore(objPreSelected)
	}, [])

	useEffect(() => {
		let objSelected = {}
		brandsObj.forEach(obj => {
			if(obj.brand_name && obj.brand_id)
				objSelected[obj.id] = {"value": obj.id_product_brand, label: obj.store_name + ": " + obj.brand_name}
		})
		console.log('objSelected', objSelected);
		
		setSelectedBrands(objSelected)
	}, [brandsObj])

	/* useEffect(() => {
		// * Once the brand list and the brandOBject is fully loaded, we load the mapping saved
		if(brands.length > 0 && brandsObj.length > 0)
			loadDataMapping()
	}, [brands, brandsObj]) */

	/* const loadDataMapping = () => {
		fetchMapping((r) => {
			console.log('fetchMapping:', r);
			if(r){
				let objBrandSelected = {}
				r.forEach(obj => {
					if(!(obj.id_brand_object in objBrandSelected))
						objBrandSelected[obj.id_brand_object] = [];

					objBrandSelected[obj.id_brand_object].push(obj.brand)
				})

				console.info('Mapping loaded', objBrandSelected);
				setSelectedBrands(objBrandSelected)
			}
		})
	} */

	const loadData = () => {
		let p1 = new Promise(function(resolve, reject) {
			fetchBrandsObject( (r) => {
				console.log('fetchBrandsObject', r);
				if(r.success){
					let results = r.data
					resolve(results)
				}else reject(r.error)
			})
		})
		let p2 = new Promise(function(resolve, reject) {
			fetchBrands( null, {}, null, (r) => {
				console.log('fetchBrands', r);
				if(r.success){
					let results = r.data
					resolve(results)
				}else reject(r.error)
			})
		})

		Promise.all([p1, p2]).then((r) => {
			setBrandsObj(r[0])
			setBrands(r[1])
		}).catch((e) => {
			console.warn(e);
			props.info({error: "Please reload this page, something wrond happened."})
		}).then(() => {
			props.loading(false)
		})
	}

	const fetchTemplate = (callback) => {

		ajaxPerso( {
			"api": "eio",
			"trigger": "getTemplate"
		}, callback)
	}

	const fetchBrandsObject = (callback) => {
		
		ajaxPerso( {
			"api": "eio",
			"trigger": "getBrandTemplate"
		}, callback)
	}

	const updateMapping = () => {

		if(selectedBrands){
			let listReq = []

			for (const idBrandObj in selectedBrands) {
				if (selectedBrands.hasOwnProperty(idBrandObj)) {
					const listBrands = selectedBrands[idBrandObj];

					//listBrands.forEach(brand => {

					/* let brandFound = brands.find(obj => {
						if(obj.name === brand)
							return obj
					}) */

					console.log(listBrands);
					

					listReq.push({
						"idBrandObj": idBrandObj,
						"idBrand": listBrands.value,
						//id_marketplace: brandFound? brandFound.id_marketplace : null
					})
					//})
				}
			}

			console.info('listReq', listReq);

			ajaxPerso( {
				"api": "eio",
				"trigger": "setBrandMapping",
				"data": JSON.stringify(listReq)
			}, (r) => {
				console.info('r', r);
				props.info({success: parseAjaxReturn(r.res) + parseAjaxReturn(r.cleaning.res)})
			})
		}else{
			console.info('Please fill the input');
		}
	}

	const getItemAfterFilters = () => {
		let items = []
		/* console.info('brandsObj', brandsObj);
		console.info('selectedStore_1', selectedStore); */
		brandsObj.forEach((obj) => {
			//console.log("obj", obj);
			let shown = true
			
			/* console.info("selectedStore", selectedStore);
			console.info("obj.store_id", obj.store_id); */

			// Show "no assigned"
			if(selectedStore !== "all" && !selectedStore && parseInt(obj.store_id))
				shown = false
			// Show item corresponding to the criteria selected
			if(selectedStore !== "all" && selectedStore && parseInt(obj.store_id) !== selectedStore)
				shown = false


			// Show "no assigned"
			if(selectedTemplate !== "all" && !selectedTemplate && parseInt(obj.id_template))
				shown = false
			// Show item corresponding to the criteria selected
			if(selectedTemplate !== "all" && selectedTemplate && parseInt(obj.id_template) !== selectedTemplate)
				shown = false

			if(shown){
				/* console.log(obj);
				console.log("selectedStore", selectedStore);
				console.log("selectedTemplate", selectedTemplate); */
				
				items.push(obj)
			}
		})
		return items
	}
	
	if(!props.securityClearance(props))	return "";

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container">
			<p className="h1 mb-3">Map brands object to their respective brand</p>
			<div className="alert alert-warning mb-4" role="alert">
				Create a new brand object contenaing data to feed the template
				<ul>
					<li>Each brand for which a template is needed has to be attached to the right brand from the desired marketplace.</li>
					<li>Once a brand object is created, open it and add a data group to it</li>
					<li>Attache the brand object to the right template by opening it and selecting the desired template</li>
					<li>No need to attache a Shopify brand to a template for now.</li>
				</ul>
			</div>
			<div>
				<span><NavLink to={{
					pathname: "/template",
					search: "?mode=tmpdatagroup"
				}}><button type="button" className="btn btn-primary">Add new brand Object</button></NavLink></span>
				<span className="ml-3"><NavLink to={{
					pathname: "/brand"
				}}><button type="button" className="btn btn-primary">Add new brand</button></NavLink></span>
				<span className="ml-3"><NavLink to={{
					pathname: "/template",
					search: "?mode=tmplist"
				}}><button type="button" className="btn btn-primary">List frames</button></NavLink></span>
			</div>
			<div className="container">
				<div className="row mt-3 mb-3">
					<div className="col-sm p-0 mr-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">Marketplace</span>
							</div>
							<select className="custom-select" value={selectedStore || false} id="exampleFormControlSelect1" onChange={(e) => {
								store("storeSelected", parseInt(e.target.value))
								setSelectedStore(["all", ""].indexOf(e.target.value) === -1? parseInt(e.target.value) : e.target.value)
							}}>
								<option value="all">-</option>
								<option value="">No assigned</option>
								{
									stores.map(obj => {
										return <option key={obj.id + "_keyListStore"} value={obj.id}>{obj.name}</option>
									})
								}
							</select>
						</div>
					</div>
					<div className="col-sm p-0 ml-3">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">Template</span>
							</div>
							<select className="custom-select" defaultValue={selectedStore} id="exampleFormControlSelect1" onChange={(e) => {
								setSelectedTemplate(parseInt(e.target.value))
							}}>
								<option value="all">-</option>
								<option value="">No assigned</option>
								{
									templates.map(obj => {
										return <option key={obj.id + "_keyListTmp"} value={obj.id}>{obj.name}</option>
									})
								}
							</select>
						</div>
					</div>
				</div>
			</div>
			{
				"Items shown: " + getItemAfterFilters().length
			}
			<div className="d-flex flex-row flex-nowrap">
				<ul className="list-group list-group-flush w-100">
					<li className="font-weight-bold list-group-item d-flex flex-row align-items-center justify-content-between">
						<span>Template fragment</span>
						<span>Brand from marketplaces <button type="button" className="btn btn-primary ml-3" onClick={updateMapping}>Update Mapping</button></span>
					</li>
					{
						getItemAfterFilters().map((obj) => {
							//console.log(obj);

							let model = obj.model || []
							
							return <li key={obj.id + "_listBrdObj"} className="list-group-item d-flex flex-row align-items-center justify-content-between">
								<span><NavLink to={{
									pathname: "/template/" + obj.id,
									search: "?mode=tmpdatagroup"
								}}>{(obj.template_name? obj.template_name + ': ' : '')}<span className="font-weight-bold">{ obj.name }</span></NavLink></span>
								<span className="ml-3 w-50 d-flex flex-row align-items-center">
									<span className={(obj.is_active? "badge-light" : "badge-warning") + " badge badge-pill mr-4"}>{obj.is_active? "Active" : "Inactive"}</span>
									<span className={(model.length > 0? "badge-light" : "badge-warning") + " badge badge-pill mr-4"}>Model: {model.length}</span>
									<Select
										styles={ refConst.styleList }
										onChange={(args) => {
											let cpList = Object.assign({}, selectedBrands)
											console.log("selectedBrands", selectedBrands);
											console.log("cpList", cpList);
											
											cpList[obj.id] = args // {value, label}
											setSelectedBrands(cpList)
										}}
										options={
											brands.map(obj => {
												return{
													value: obj.id,
													label: obj.store_name + ": " + obj.name
												}
											})
										}
										value={
											obj.id in selectedBrands && selectedBrands[obj.id]? selectedBrands[obj.id] : {value: '', label: '-'}
										}
										defaultValue={
											obj.id in selectedBrands && selectedBrands[obj.id]? selectedBrands[obj.id] : {value: '', label: '-'}
										}
										isSearchable={true}
										/* isMulti={true} */
									/>
								</span>
							</li>
						})
					}
				</ul>
			</div>
		</div>

	);
}

export default MappingBrand;