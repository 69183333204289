import React, { Component } from 'react';
import $ from 'jquery'; 
const refConst = require("../../../constants.js").v

class ItemsTable extends Component {
	constructor(props) {
		super(props);
		
		this.state = {

		};

		// Bind Allow access by "this" into the function
		//this.fetchItems = this.fetchItems.bind(this);
	}

	/* componentWillMount() {
		this.initialState = this.state
	} */

	componentDidUpdate(prevProps){
	}

	componentDidMount() {
	}

	addItemsToView() {
		let items = this.props.items,
			returnData = [],
			lengthTab = items.length

		if(items && items.length > 0)
			for (let i = 0; i < lengthTab; i++) {
				const obj = items[i]

				if(!obj)
					continue
				
				returnData.push(<tr key={"list_" + i}>
					<th className="align-middle">
						<label className="ctn_checkbox">
							<input type="checkbox" onChange={this.props.onSelectItems} checked={this.props.itemsSelected.indexOf(obj.id_item) >= 0? true : false} value={obj.id_item}/>
							<span className="checkmark"></span>
						</label>
					</th>
					<th scope="row" className="align-middle">{obj.id}</th>
					<td className="align-middle">{obj.id_item}</td>
					<td className="ebayItemCellImg"><img src={obj.picture_url} className="img-fluid" alt="Responsive image"></img></td>
					<td className="align-middle">{obj.sku||obj.sku_on_ebay}<br/><span className="text-warning">{!obj.sku? '(Sku not found in Finale)' : ''}</span></td>
					<td className="align-middle">{obj.price}</td>
					<td className="align-middle">{obj.qte_sold}</td>
					<td className="align-middle">Finale: {obj.qty_on_hand}<br/>eBay: {obj.qte_available}</td>
					<td className="align-middle"><a href={obj.view_item_url} target="_blank"  rel="noopener noreferrer">link</a></td>
				</tr>)
			}
		return returnData
	}

	render() {
		return (
			<table className="table table-bordered">
				<thead>
					<tr>
						<th scope="col" className="align-top"><label className="ctn_checkbox">
							<input type="checkbox" onChange={(e) => this.props.onSelectItems(e, true)} ref={this.props.mainCheckbox} />
							<span className="checkmark"></span>
						</label></th>
						<th scope="col">#</th>
						<th scope="col">ID IN STORE</th>
						<th scope="col">IMG</th>
						<th scope="col">SKU</th>
						<th scope="col">PRICE</th>
						<th scope="col">QTY SOLD</th>
						<th scope="col">QTY AVAILABLE</th>
						<th scope="col">LINK</th>
					</tr>
				</thead>
				<tbody>
					{ this.addItemsToView() }
				</tbody>
			</table>
		);
	}
}

export default ItemsTable;
