// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import {
	NavLink
} from 'react-router-dom';
import JSONPretty from 'react-json-pretty';
import {ajaxPerso} from "../fnc.js"
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
const refConst = require("../constants.js").v


export default function CronTask(props){

	const [cronTask, setCronTask] = useState([]);
	const [submited, setSubmited] = useState(false);

	useEffect(() => {
		if(!props.securityClearance(props))	return "";
		loadCrontask()
	}, [])

	const fetchCronTask = (callback) => {
		props.loading(true);

		ajaxPerso( {
			"trigger": "fetchCronicleEvent",
			"api": "eio",
		}, callback)
	}
	const sendTask = (cron, callback) => {
		if(!cron)	return false
		props.loading(true);

		ajaxPerso( {
			"trigger": "executeCronTask",
			"api": "eio",
			"cronCall": cron, // the urlEncoded version from the server.
		}, callback, function(jqXHR, textStatus) {
			props.loading(false);
			setSubmited(false)
			props.info({error: textStatus})
			console.warn( textStatus );
		});
	}

	const loadCrontask = () => {
		fetchCronTask((r) => {
			if(r.success && r.data.length > 0){
				console.info("Fetch CronTask:", r);
				setCronTask(r.data)
			}
			props.loading(false);
		})
	}

	const runTask = (o, i) => {
		setSubmited(i)
		sendTask(o.trigger, (r) => {
			console.info("Run CronTask:", r);
			setSubmited(false)
			props.loading(false);
			if(r){
				// JSON.stringify(r)
				props.info({success: JSON.stringify(r)})
			} else props.info({error: "Nothing received."})
		})
	}

	if(!props.securityClearance(props))	return "";

	console.info("cronTask", cronTask);

	return (
		<div className="main ctnPage">
			{
				cronTask && cronTask.length > 0? <div className="table-responsive-md">
					<table className="table">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Api</th>
								{/* <th scope="col">Time</th> */}
								<th scope="col">Trigger</th>
								<th scope="col">Call</th>
								<th scope="col">Execute</th>
							</tr>
						</thead>
						<tbody>
							{
								cronTask.map((o,i)=> {
									return <tr key={`${o.id}`} className="">
										<td className="mr-3">{i+1}</td>
										<td>{o.api}</td>
										{/* <td>{o.time}</td> */}
										<td>{o.title}</td>
										<td className="overflow-auto text-wrap text-truncate text-break">{decodeURIComponent(o.trigger.replace(/\+/g, ' '))}</td>
										<td><button disabled={submited} type="button" className="btn btn-primary d-flex justify-content-center align-items-center" onClick={() => {
											runTask(o, i)
										}}>
											<div className={(submited === i? "" : "d-none") + " spinner-border spinner-grow-sm text-light mr-3"} role="status">
												<span className="sr-only">Loading...</span>
											</div>
											Run
										</button></td>
									</tr>
								})
							}
						</tbody>
					</table></div>
					: ''
			}
		</div>

	);
}