// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
// eslint-disable-next-line no-unused-vars
import StyledDropzone from './ctn/shopify/dropZone'
// eslint-disable-next-line no-unused-vars
import FormShopifyListing from './ctn/shopify/form'
// eslint-disable-next-line no-unused-vars
import EioForm from './ctn/shopify/forms/eio_form'
// eslint-disable-next-line no-unused-vars
import HausOfTools from './ctn/shopify/forms/hauseOfToold_form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
//import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import $ from 'jquery';
import store from 'store2';
import { fetchMenu, menuOrganized, fetchStores, fetchEioCatalogItems, ajaxPerso, fetchDataSet, uniqueArray, fetchBrands, fetchIsAutoCreated } from '../fnc'
import { useMediaQuery } from 'react-responsive'
import pdfIconSmall from '../img/pdf_icon_small.png';
import LinkToSection from './ctn/shopify/linkToSection'
import moment from 'moment';

import {
	NavLink
} from 'react-router-dom';

const refConst = require("../constants.js").v
const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}


var initialState = {
	loadedBinList: [],
	alsoCreateFilesFor: [],
	binList: [],
	binListDropzone: [],
	images: [],
	imagesDropzone: [],
	filesDropzone: [],
	files: [],
	msgInfo: {},
	menu: [],
	brands: [],
	countries: [],
	searched: "",
	searchedOld: "",
	searchResult: [],
	dataSet: null,
	dataSetList: null,
	calculatedFieldsDt: {},
	htmlDT: "",
	updateMode: false, // `false` when initiated, `null` when about to be cleaned by emptyFormTotal
	isAutoCreated: null,
	title: "",

	grpDataSetSelected: false,
	navGrpSelected: false,
	navGrpSelectedMenuRight: 'Select Images',
	// navGrpSelectedMenuRight: 'Link to',

	history: {
		updated: [],
		inserted: [],
	},
	_isMounted: false,
	// multiVariantLock: false,
	defaultVariant: [],
	productOptions: [],
	variantMode: null,
	navGrpOptionsSelected: null,
	globalOptions: [],
	/* msg: {
		success: false,
		successColor: "#A2E8BB",
		error: false,
		errorColor: "#FF917D"
	}, */
	selectedVariant: 1,
	navHistoryGrpSelected: "inserted",
	// mpnList: 1
}

const defaultVariantSpecific = {
	options: [null, null, null],
	modified: false,
	valFilled: {
		mpn: "",
		title: null,
		price: "",
		inventory_quantity: "",
		sku: "",
		barcode: "",
		position: 1,
		weight: "",
		weight_unit: ""
	}
}

initialState.defaultVariant[1] = JSON.parse(JSON.stringify(defaultVariantSpecific))



function ShopifyListing(props){

	const smallWidthWindow = useMediaQuery({
		query: '(max-device-width: 1024px)'
	})

	// const [msg, setMsg] = useState(Object.assign({}, initialState.msg));

	var objPreSelected = store("shopSelected")

	const [keyLinkedTo, setKeyLinkedTo] = useState(0); // IS used to reset the state from our linkTo component when we trigger the emptyState function
	const [shopSelected, setShopSelected] = useState({id: null, name: null});
	const [title, setTitle] = useState(initialState.title);

	const [stores, setStores] = useState([]);
	const [loadedBinList, setLoadedBinList] = useState([...initialState.loadedBinList]);
	const [alsoCreateFilesFor, setAlsoCreateFilesFor] = useState([...initialState.alsoCreateFilesFor]);
	const [binList, setBinList] = useState([]);
	const [binListDropzone, setBinListDropzone] = useState([]);
	const [images, setImages] = useState([]);
	// const [files, setFiles] = useState([]);
	const [imagesDropzone, setImagesDropzone] = useState([...initialState.imagesDropzone]);
	const [filesDropzone, setFilesDropzone] = useState([...initialState.filesDropzone]);
	const [updateMode, setUpdateMode] = useState(initialState.updateMode); // get the item object with metafields to update
	const [isAutoCreated, setIsAutoCreated] = useState(initialState.isAutoCreated); // get the item object with metafields to update

	const [proposalHidden, setProposalHidden] = useState(false);
	const [version, setVersion] = useState("3.0.0");

	const [menu, setMenu] = useState(initialState.menu);

	const [brands, setBrands] = useState(initialState.brands);

	const [countries, setCountries] = useState(initialState.countries);

	const [msgInfo, setMsgInfo] = useState(Object.assign({}, initialState.msgInfo));

	//const [extendedBody, setExtendedBody] = useState(initialState.extendedBody);

	const [searched, setSearched] = useState(initialState.searched);
	const [searchedOld, setSearchedOld] = useState(initialState.searchedOld);
	const [searchResult, setSearchResult] = useState(initialState.searchResult);

	const [originlDataSet, setOriginalDataSet] = useState(initialState.dataSet);
	const [dataSet, setDataSet] = useState(initialState.dataSet);
	const [dataSetList, setDataSetList] = useState(initialState.dataSetList); // Will get the list of dataset per store ID
	const [grpDataSetSelected, setGrpDataSetSelected] = useState(initialState.grpDataSetSelected);
	const [navGrpSelected, setNavGrpSelected] = useState(initialState.navGrpSelected);
	const [navGrpSelectedMenuRight, setNavGrpSelectedMenuRight] = useState(initialState.navGrpSelectedMenuRight);
	//const [dsData, setDsData] = useState(initialState.dsData);
	const [calculatedFieldsDt, setCalculatedFieldsDt] = useState(initialState.calculatedFieldsDt);
	const [htmlDT, setHtmlDT] = useState(initialState.htmlDT);

	const [history, setHistory] = useState(initialState.history);
	// const [multiVariantLock, setMultiVariantLock] = useState(initialState.multiVariantLock);
	const [selectedVariant, setSelectedVariant] = useState(initialState.selectedVariant);
	// const [selectVariantImage, seSelectVariantImage] = useState(null);
	const [refreshView, setRefreshView] = useState(0);
	const [openModalVariantImage, setOpenModalVariantImage] = useState(false);

	// Default empty variant for the single variant item.
	const [variantsSpecificsToSubmit, setVariantsSpecificsToSubmit] = useState(initialState.defaultVariant);
	const [globalOptions, setGlobalOptions] = useState(initialState.globalOptions);
	const [navGrpOptionsSelected, setNavGrpOptionsSelected] = useState(initialState.navGrpOptionsSelected);
	const [variantMode, setVariantMode] = useState(initialState.variantMode);
	const [productOptions, setProductOptions] = useState(initialState.productOptions);
	const [navHistoryGrpSelected, setNavHistoryGrpSelected] = useState(initialState.navHistoryGrpSelected);
	// const [mpnList, setMpnList] = useState(initialState.mpnList);

	console.info("variantsSpecificsToSubmitShopifyListing", variantsSpecificsToSubmit);
	console.info("navGrpSelectednavGrpSelected", navGrpSelected);

	var menuRight = [{
		title: 'Select Images',
		body: () => <div>
			<StyledDropzone setDropzone={setBinListDropzone} binList={binList} info={props.info} type="img"/>
			{ sectionSelectVariantImage() }
			{ showSelectedImg() }
		</div>
	},{
		title: 'Select Files',
		body: () => <div>
			{shopMultiCreateFileOptions()}
			<StyledDropzone setDropzone={setBinListDropzone} binList={binList} info={props.info} type="file"/>
			{ showSelectedFile() }
		</div>
	},{
		title: 'Link to',
		body: () => <div className="p-3">
			{ shopSelected.id ? <LinkToSection key={`linkedTo_${keyLinkedTo}`} {...props} shop={shopSelected} title={title}/> : '' }
		</div>
	}];

	useEffect(() => {
		initialState._isMounted = true;

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		if(openModalVariantImage)
			selectVariantImageModal()
	}, [refreshView, updateMode, openModalVariantImage])

	/* useEffect(() => {
		
		if(selectedLinkTo.length === 0){
			setResSelectedLinkTo([])
			return;
		}

		fetchShopifyItems({
			shop: shopSelected.id,
			idShopifyList: selectedLinkTo.join(',')
		}, (r) => {
			console.info('Fetched "fetchShopifyItems": ', r);
			setResSelectedLinkTo(r.success && r.data.length > 0? r.data.map(d => {

				let imgSrc = d.images_json[0].node? d.images_json[0].node.originalSrc : d.images_json[0].src

				return {
					id_product_shopify: d.id_product_shopify,
					images_src: imgSrc,
					title: d.title,
					sku: d.sku,
				}
			}) : [])
		})

	}, [selectedLinkTo]) */

	/* useEffect(() => {

		/// * Lock if has multiple variant

		console.info('VARIUANTTSSSS', updateMode && updateMode.variants.length > 1? true : false);
		
		setMultiVariantLock(updateMode && updateMode.variants.length > 1? true : false);

	}, [updateMode]) */


	useEffect(() => {
		$('.tooltipNote').tooltip('dispose').tooltip({
			placement: "right"
		}).tooltip('update')
	})

	useEffect(() => {
		$('.tooltipActualise').tooltip('dispose').tooltip({
			// placement: "right"
		}).tooltip('update')
	})

	useEffect(() => {
		$('.hasTooltip').tooltip('dispose')

		$('.hasTooltip[data-toggle="tooltipFormError"]').tooltip({
			placement: "bottom",
			trigger: "manual",
			template: '<div class="tooltip" role="tooltip"><div class="arrow arrow_red"></div><div class="tooltip-inner bg-danger"></div></div>'
		}).tooltip('show').tooltip('update')

		return() => {
			// When disconnected, we remove all active tooltip.
			$('.hasTooltip').tooltip('dispose')
		}
	})

	useEffect(() => {

		fetchStores((r) => {
			if(!initialState._isMounted)	return false
			console.info('Fetched Stores:', r);
			if(r.success){
				
				setStores(r.data.filter(o => {
					if(o.id == "205618" && props.securityClearance(props, [
						"-MjVxK86GVBUKL5oNU66", // admin_access
						"-MjH_9MuY646871R7lUA" // shopify_eio_write
					])){
						o.fnc = (args) => {
							return <EioForm {...args}/>;
						}
						return o;
					}else if(o.id == "202487" && props.securityClearance(props, [
						"-MjVxK86GVBUKL5oNU66", // admin_access
						"-MjVTYrT8L8HguYYrRKE" // shopify_haus_write
					])){
						o.fnc = (args) => {
							return <HausOfTools {...args}/>;
						}
						return o;
					}
					/* if(o.id_marketplace == refConst.id_marketplace_shopify)
						return o */
				}))
			}
		})

		fetchCountryList( (r) => {
			if(!initialState._isMounted)	return false
			props.loading(false)
			console.log("Fetched countries", r);
			if(r.success){
				let results = r.data.sort()
				setCountries([false].concat(results))
			}
		})
		loadUserHistory()
	}, [])

	useEffect(() => {
		if(stores.length > 0){
			let storeWanted = objPreSelected && objPreSelected.id? objPreSelected : stores[0]
			setShopSelected(Object.assign({}, storeWanted))

			// Make sure that the currently selected store is part of our allowed store (Permission security)
			if(stores.length > 0 && storeWanted && !stores.find(o => {
				if(o.id === storeWanted.id)	return o;
				return false;	
			})){
				store("shopSelected", stores[0])
			}
		}
	}, [stores])

	useEffect(() => {
		if(!updateMode)	return
		if(stores.length > 0 && updateMode.list_id_store){
			setAlsoCreateFilesFor(stores.filter((o, i) => {
				if(o.id_marketplace == refConst.id_marketplace_shopify && o.id != shopSelected.id && updateMode.list_id_store.indexOf(o.id) !== -1)	return o
			}).map(o => o.id))
		}
		
	}, [stores, shopSelected, updateMode])

	useEffect(() => {
		
		if(selectedVariant !== null && updateMode && updateMode.variants[selectedVariant-1] && updateMode.variants[selectedVariant-1].sku && !("list_id_store" in updateMode)){
			fetchEioCatalogItems({
				"sku": updateMode.variants[selectedVariant-1].sku
			}, (r) => {
				if(!initialState._isMounted)	return false
				console.info('Fetch Shopify Item: ' + updateMode.variants[selectedVariant-1].sku, r);
				// let cp = Object.assign({}, updateMode)
				if(r.success){
					updateMode.list_id_store = r.success && r.data.length > 0 ? uniqueArray(r.data[0].list_id_store) : []
				}
				// setUpdateMode(cp)
				setRefreshView(refreshView+1)
			}, props.info)
		}
		
	}, [updateMode, selectedVariant])

	useEffect(() => {
		/**
		 * Fetching:
		 * 	- Menu
		 * 	- Brand
		 */
		if(shopSelected && shopSelected.id){
			fetchMenu( {
				"shop": shopSelected.id
			}, () => {
				props.loading(true)
			}, (r) => {
				if(!initialState._isMounted){
					props.loading(false)
					return false
				}
				props.loading(false)
				if(r.success){
					console.log("Fetched menu", r);
					let results = r.data.filter(o => {
						if(o.is_menu)	return o
						return false
					})
					let menu = menuOrganized(results)

					setMenu(menu)
				}
			});
			
			fetchBrands( shopSelected.id, {
				onlyActive: true,
			}, null, (r) => {
				if(!initialState._isMounted)	return false
				props.loading(false)
				console.log("Fetched brands", r);
				if(r.success){
					let results = r.data.sort()
					setBrands(results)
				}
			})
		}

	}, [shopSelected])

	useEffect(() => {
		if(searched === searchedOld){
			initiateSearch()
		}
	}, [searched, shopSelected])

	const initiateSearch = () => {
		if(searched){
			console.info('updated Search result');

			searchRequest({
				title: searched,
				nbrResult: 20
			}, (r) => {
				if(r.success && (!r.data || r.data.length === 0)){
					searchRequest({
						sku: searched,
						nbrResult: 20
					}, (r) => {
						searchResultMulti(r)
					})
				}else
					searchResultMulti(r)
			})
		}else
			setSearchResult([])
	}

	const searchResultMulti = (r) => {
		if(!initialState._isMounted)	return false
		props.loading(false)
		let products = r.data.map(obj => {
			return {
				"id": obj.node.legacyResourceId,
				"title": obj.node.title,
				"featuredImage": obj.node.featuredImage,
			}
		})
		setSearchResult(products)
	}

	useEffect(() => {
		var idItem = props.parent.match.params.idItem;

		if(idItem && shopSelected.id){
			if((!updateMode || updateMode.id != idItem)){
				setUpdateMode(null)
				console.info('Load item data itemId:', idItem);
				// Overwrite each data with the new item informations
				loadItem(idItem)
			}
		}else if(updateMode){
			// Clean the view, this will trigger `emptyFormTotal`
			setUpdateMode(null)
		}
		
	}, [props.parent.match.url, shopSelected])

	useEffect(() => {
		console.info("images", binList);
		console.info("imagesDropzone", binListDropzone);
		
		let bin = binList.slice(0)
		let countPerGrp = {};

		binList.forEach(o => {
			if(!(o.typeBin in countPerGrp))
				countPerGrp[o.typeBin] = 0;
			countPerGrp[o.typeBin]++;
		})

		console.info('countPerGrp', countPerGrp);

		for (let i = 0; i < binListDropzone.length; i++) {
			if(!(binListDropzone[i].typeBin in countPerGrp))
				countPerGrp[binListDropzone[i].typeBin] = 0;

			binListDropzone[i].pos = countPerGrp[binListDropzone[i].typeBin]++
			bin.push(binListDropzone[i])
		}
		setBinList(bin)
		
	}, [binListDropzone])

	/* useEffect(() => {
		console.info("files", files);
		console.info("filesDropzone", filesDropzone);
		
		let fls = files.slice(0)

		for (let i = 0; i < filesDropzone.length; i++) {
			filesDropzone[i].pos = fls.length
			fls.push(filesDropzone[i])
		}
		setFiles(fls)
		
	}, [filesDropzone]) */

	useEffect(() => {

		/**
			We aload first the dataset for our 2 stores then:
				1) If we just loaded the tool, we need to load the dataset base on the shop selected
				2) If an ID is present in the URL, we need to check if the description correspond to an
					item from haus of eio and select the appropriate dataset.
					2.a)	We then need to go through the body and fill up the appropriate dataset element.
		 */
		if(shopSelected && shopSelected.id)
			fetchDataSet({
				// "shop": shopSelected.id
				"shop": [
					refConst.id_store_eio,
					refConst.id_store_hausfTools
				].join(",")
			}, (r) => {
				if(!initialState._isMounted)	return false
				console.info("Fetched dataSet:", r);
				if(r.success){
					
					/* let results = organiseDataSet(r.data)
					console.log("results", results) */
					console.log("organiseDataSet", r.dataOrganized)

					setDataSetList(r.dataOrganized)
					setOriginalDataSet(JSON.parse(JSON.stringify(r.dataOrganized[shopSelected.id])))
					setDataSet(JSON.parse(JSON.stringify(r.dataOrganized[shopSelected.id])))
					setGrpDataSetSelected(r.dataOrganized[shopSelected.id][0].obj.id)
				}
			})
	}, [shopSelected])

	useEffect(() => {
		let refresh = true

		loadModalFeatureSelection(refresh)

	}, [grpDataSetSelected, navGrpSelected, calculatedFieldsDt, dataSet])

	useEffect(() => {
		/**
		 * Select the matching dataset
		 */

		if(!updateMode)				return;
		if(!dataSet)				return;
		if(!grpDataSetSelected)		return;
		if(!shopSelected)			return;
		
		changeDatasetStore(grpDataSetSelected)

	}, [updateMode, dataSet, grpDataSetSelected, shopSelected])

	useEffect(() => {

		if(!updateMode)		return
		if(!originlDataSet)	return

		console.info("call getDatasetFromBody", originlDataSet);
		console.info("call getDatasetFromBody 2 ", updateMode);

		getDatasetFromBody()

	}, [updateMode, originlDataSet])

	useEffect(() => {
		/**
		 	Open the first NAV everytime we change of dataset element.
		 */

		if(!dataSet)	return;
		if(!grpDataSetSelected)	return;

		let selectedDt = dataSet.find(o => {
			if(o.obj.id == grpDataSetSelected)	return o
		})
		
		if(selectedDt)
			setNavGrpSelected(selectedDt.childs[0].obj.id)

	}, [grpDataSetSelected, dataSet])

	const changeDatasetStore = (dataGrpIdReceived) => {
		/**
		 	in case it's a product created from a cloning haus to eio
			We need to change the dataset for the one from hausoftools.
		 */

		console.info("grpDataSetSelected2", dataGrpIdReceived);

		let currentItemIdStore = updateMode.idStoreSelected

		let dataSetNeeded = dataSetList[updateMode.idStoreSelected],
			storeiIdWanted = updateMode.idStoreSelected

		for (const idStore in dataSetList) {
			if (Object.hasOwnProperty.call(dataSetList, idStore)) {
				dataSetList[idStore].some(o => {
					if(o.obj.id === dataGrpIdReceived){
						storeiIdWanted = idStore
						dataSetNeeded = dataSetList[idStore]
						console.info(`Changed dataset for [${idStore}], store selected: [${updateMode.idStoreSelected}]`);
						return true
					}
				})
			}
		}

		console.info("findCurrentIdStore2", `${currentItemIdStore} != ${storeiIdWanted}`);
		// return;
		
		if(currentItemIdStore != storeiIdWanted || !dataSet || !originlDataSet){
			console.info("Update dataset", dataSetNeeded);
			setOriginalDataSet(JSON.parse(JSON.stringify(dataSetNeeded)))
			setDataSet(JSON.parse(JSON.stringify(dataSetNeeded)))
			updateMode.idStoreSelected = storeiIdWanted
			setGrpDataSetSelected(dataSetNeeded[0].obj.id)
		}
	}

	const getDatasetFromBody = () => {

		let item = updateMode
		let cpCalculated = {}

		console.info("start getDatasetFromBody");
		
		if(originlDataSet && item && item.body_html.trim().length > 0 && /^<[a-z][\s\S]*>.*>$/is.test(item.body_html.trim())){ // /<\/?[a-z][\s\S]*>/
			
			let cpDataSet = JSON.parse(JSON.stringify(originlDataSet));
			console.info("getDatasetFromBody - cpDataSet", cpDataSet);
			console.log("Current description: ", item.body_html.trim());
			let groupIdLvl1 = $(item.body_html.trim()).attr("data-idgrpdataset"),
				navOpened = false

			// Scrape the dataSet saved and match each part to the right Group and key by using id's saved.
			if($(item.body_html.trim()).find("[data-idgrpdata]").length > 0){
				console.info('Scraper base on [ID\'s] htmlBody');
				
				$(item.body_html.trim()).find("[data-idgrpdata]").each((i, node) => {
					let idParent = $(node).parent("[data-idgrp]").attr("data-idgrp"),
						idData = $(node).attr("data-idgrpdata"),
						value = $(node).html(),
						weightValue = $(node).attr("data-originalValue"),
						weightType = $(node).attr("data-originalType")

					// Special condition for calculated fields
					if(weightValue){
						cpCalculated[idData] = {
							"type": weightType,
							"name": "weight"
						}

						// We overwrite the value if a calculated field is found.
						value =  parseFloat(weightValue).toFixed(2)
					}

					// We select the first group with data as open by default
					if(i === 0)		navOpened = idParent
					
					let oParentFound = cpDataSet.find(o => {
						if(parseInt(o.obj.id) === parseInt(groupIdLvl1))	return o
						return false;
					})

					if(oParentFound){

						let oChildFound = oParentFound.childs.find(o => {
							if(parseInt(o.obj.id) === parseInt(idParent))	return o
							return false;

						})
						let oNewElemFound = oChildFound.childs.find(o => {
							if(parseInt(o.obj.id) === parseInt(idData))	return o
							return false;

						})

						if(oChildFound && oNewElemFound)
							oNewElemFound.obj.value = value.replace(/<br[/]*>/g,"\n")
					}
				})
			}else{
				console.warn('Scraper base on [Keys] htmlBody');

				let listKeyWanted = [],
					listNavKey = [],
					keyFoundIntoDataSet = {}, // for retrocompatibility with the OLD EIO
					keyNavFound = {}

				// Make the choices smaller by looking at the groups first
				$(item.body_html.trim()).find(".attribute-group-name").each((i, node) => {
					listNavKey.push( $(node).html().toLowerCase() )
				})

				// find the best match in the group selected
				$(item.body_html.trim()).find(".attribute-value").each((i, node) => {
					listKeyWanted.push( $(node).prev().html().toLowerCase() )
				})

				// Make a list of key for each group of the dataSet, then used to find the best match with the old datased saved
				cpDataSet.forEach(lvl1 => {

					if(!keyFoundIntoDataSet[lvl1.obj.id]){
						keyNavFound[lvl1.obj.id] = []
						keyFoundIntoDataSet[lvl1.obj.id] = []
					}

					if(lvl1.childs)
						lvl1.childs.forEach(lvl2 => {

							if(keyNavFound[lvl1.obj.id].indexOf(lvl2.obj.title.toLowerCase()) === -1)
								keyNavFound[lvl1.obj.id].push(lvl2.obj.title.toLowerCase())

							if(lvl2.childs)
								lvl2.childs.forEach(lvl3 => {

									if(keyFoundIntoDataSet[lvl1.obj.id].indexOf(lvl3.obj.title.toLowerCase()) === -1)
										keyFoundIntoDataSet[lvl1.obj.id].push(lvl3.obj.title.toLowerCase())
								})
						})
				})

				// Find the best match, the group that contain all keys that were recorded
				//loop1:
				console.log("keyNavFound", keyNavFound);
				
				// Filter by removing keyGroup that do not match for 1 key or more
				for (const idGrp in keyNavFound) {
					if (keyNavFound.hasOwnProperty(idGrp)) {
						const listKeyFound = keyNavFound[idGrp];
						let allFound = true
						//loop2:
						for (let i = 0; i < listNavKey.length; i++) {
							const keyWanted = listNavKey[i];
							if(listKeyFound.indexOf(keyWanted) === -1){
								allFound = false
								break;
							}
						}

						if(!allFound && keyFoundIntoDataSet[idGrp])
							//bestGroupsAccordingToNavs.push(idGrp)
							delete keyFoundIntoDataSet[idGrp]
					}
				}

				console.log("keyFoundIntoDataSet", keyFoundIntoDataSet);
				console.log("listKeyWanted", listKeyWanted);

				// Find the best match, the group that contain all keys that were recorded
				//loop1:
				for (const idGrp in keyFoundIntoDataSet) {
					if (keyFoundIntoDataSet.hasOwnProperty(idGrp)) {
						const listKeyFound = keyFoundIntoDataSet[idGrp];
						let allFound = true
						//loop2:
						for (let i = 0; i < listKeyWanted.length; i++) {
							const keyWanted = listKeyWanted[i];
							if(listKeyFound.indexOf(keyWanted) === -1){
								allFound = false
								break;
							}
						}

						if(allFound)
							groupIdLvl1 = idGrp
					}
				}

				console.log("groupIdLvl1:", groupIdLvl1);

				if(groupIdLvl1){

					let groupOpened = cpDataSet.find(o => {
						if(o.obj.id === groupIdLvl1)	return o
					})

					console.info("groupOpened", groupOpened);

					if(groupOpened && groupOpened.childs){
						groupOpened.childs.forEach(lvl3 => {
							if(lvl3.childs)
								lvl3.childs.forEach(lvl4 => {

									// Create the dsData Object mapped with the right ID saved in our DB
									$(item.body_html.trim()).find(".attribute-value").each((i, node) => {
										let keyGroup = $(node).parent(".attribute-group").find(".attribute-group-name").html().toLowerCase(),
											keyData = $(node).prev().html().toLowerCase(),
											value = $(node).html().trim()

											
										/* if(keyData === "weight (approximate)"){
											console.log("keydata", keyData);
											console.log(lvl3.obj.title.toLowerCase() + "===" + keyGroup + "&&" + lvl4.obj.title.toLowerCase() + "===" + keyData)
										} */
										
										if(lvl3.obj.title.toLowerCase() === keyGroup && lvl4.obj.title.toLowerCase() === keyData){
											//console.log(lvl4.obj.title.toLowerCase().trim());
											
											if([ "item weight", "weight", "weight (approximate)" ].indexOf(lvl4.obj.title.toLowerCase().trim()) !== -1){

												let weightType = null;
												let valCaptures = (new RegExp(/(\d*\.*\d*)\s*(\w*)/, "gmi")).exec(value)

												console.info('valCaptures', valCaptures);
												
												if(valCaptures[1])
													value = parseFloat(valCaptures[1].trim()).toFixed(2)
												if(valCaptures[2])
													weightType = valCaptures[2].trim()

												cpCalculated[lvl4.obj.id] = {
													"type": weightType,
													"name": "weight"
												}
												lvl4.obj.value = value;
											}else
												lvl4.obj.value = value.replace(/<br[/]*>/g,"\n");
										}
									})
								})
							})
					}
				}
			}

			setCalculatedFieldsDt(cpCalculated)

			console.log("cpCalculated", cpCalculated);
			console.log("dataSet", cpDataSet);
			
			// props.setGrpDataSetSelected(groupIdLvl1) 
			// props.setDataSet(cpDataSet)
			console.info('grpDataSetSelectedAAAA', groupIdLvl1)
			// setOriginalDataSet(JSON.parse(JSON.stringify(cpDataSet)))
			// if(!dataSet)
			setDataSet(cpDataSet)
			// We auto select the first group dataset element only if none were already selected
			// if(!grpDataSetSelected)
			setGrpDataSetSelected(groupIdLvl1)
			setHtmlDT(item.body_html)
		}
	}

	const emptyForm = () => {
		// Clear / Reset form
		console.warn('Cleaning form: ShopifyListing');
		setUpdateMode(initialState.updateMode)
		setImages(initialState.images)
		setImagesDropzone(initialState.imagesDropzone)
		setFilesDropzone(initialState.filesDropzone)
		setHtmlDT(initialState.htmlDT);
		//console.log("Cleaning test ", originlDataSet);
		
		let newDataSet = dataSetList && shopSelected? dataSetList[shopSelected.id] : initialState.dataSet
		setDataSet(newDataSet)
		setOriginalDataSet(newDataSet)
		setGrpDataSetSelected(newDataSet? newDataSet[0].obj.id : initialState.grpDataSetSelected)
		/* DO NOT AUTORISE THESE 2
		setGrpDataSetSelected(newDataSet[0].obj.id);
		setNavGrpSelected(newDataSet[0].childs[0].obj.id); 
		*/
		
		setMsgInfo(Object.assign({}, initialState.msgInfo))
		setKeyLinkedTo(keyLinkedTo + 1)
		setIsAutoCreated(initialState.isAutoCreated)

		setOpenModalVariantImage(false)
		// We need to do this for now to eraze the value from our fields sku/price/weight/qty
		let varSpecifics = []
		varSpecifics[1] = JSON.parse(JSON.stringify(defaultVariantSpecific))
		setVariantsSpecificsToSubmit(varSpecifics)
		setGlobalOptions(initialState.globalOptions)
		setNavGrpOptionsSelected(initialState.navGrpOptionsSelected)
		setVariantMode(initialState.variantMode)
		setProductOptions(initialState.productOptions)
		setSelectedVariant(initialState.selectedVariant)
		// setMpnList(initialState.mpnList)
	}

	const shopMultiCreateFileOptions = () => {
		if(!updateMode || !updateMode.list_id_store)	return []
		let output = []
		let spStores = stores.filter((o, i) => {
			if(o.id_marketplace == refConst.id_marketplace_shopify && o.id != shopSelected.id && updateMode.list_id_store.indexOf(o.id) !== -1)	return o
		});

		if(spStores.length > 0){
			spStores.forEach((o,i) => {
				output.push(<div key={`key_toggle_store_${o.id}`} className="custom-control custom-switch noselect mb-3 w-100 ml-3">
					<input type="checkbox" className="custom-control-input" id={`customSwitch${o.id}`} checked={alsoCreateFilesFor.indexOf(o.id) !== -1? true : false} onChange={(e) => {
						let cp = [...alsoCreateFilesFor]
						if(e.target.checked)
							cp.push(o.id)
						else
							cp.splice(cp.indexOf(o.id), 1)
						setAlsoCreateFilesFor(cp)
					}}/>
					<label className="custom-control-label" htmlFor={`customSwitch${o.id}`}>Also create for &quot;{o.name}&quot;</label>
				</div>)
			})
		}

		return output
	}
	
	const resetVariantError = () => {

		let msgInfoTp = msgInfo
		if(msgInfoTp && msgInfoTp.variantError){
			delete msgInfoTp.variantError;
			setMsgInfo(Object.assign({}, msgInfoTp))
		}
	}

	const loadHistory = (uid, callback) => {

		if(!uid)	return false

		ajaxPerso( {
			"api": "eio",
			"trigger": "get_history_insert",
			"id_marketplace": shopSelected.id,
			"uid": uid,
			'dateFrom': moment().local().format('YYYY-MM-DD 00:00:00'),
			'dateTo': moment().local().format('YYYY-MM-DD 23:59:59')
		}, callback);
	}

	const loadUserHistory = () => {

		loadHistory( props.getUser().uid, (r) => {
			if(!initialState._isMounted)	return false
			console.log("Fetched history", r);
			if(r.success){
				let updList = [],
					insList = []
				r.data.reverse().forEach(o => {
					if(o.type == "update"){
						updList.push(o)
					}else if(o.type == "insert"){
						insList.push(o)
					}
				})
				setHistory({
					updated: updList,
					inserted: insList,
				})
			}
		})
	}

	const loadItemData = (idItem, callback) => {

		if(!idItem)	return false
		if(!shopSelected || !shopSelected.id)	return false

		props.loading(true)

		ajaxPerso({
			"api": "shopify",
			"trigger": "request",
			"method": 'get',
			"page": 'products/' + idItem + '.json',
			"shop": shopSelected.id
		}, callback, function(jqXHR, textStatus, errorThrown) {
			if(!initialState._isMounted){
				props.loading(true)
				return false
			}
			props.info({error: textStatus})
			console.warn( textStatus );
		});
	}

	const loadItem = (idItem) => {

		if(!idItem)	return false

		let promises = []
		promises.push(new Promise(function(resolve) {
			loadItemData(idItem, (r) => {
				console.info('Fetch item data', r);
				resolve(r)
			})
		}))

		promises.push(new Promise(function(resolve) {
			loadItemMeta(idItem, (r) => {
				console.info('Fetch item meta', r);
				resolve(r)
			})
		}))

		Promise.all(promises).then((res) => {
			if(!initialState._isMounted){
				props.loading(true)
				return false
			}
			props.loading(false)

			let item = res[0]
			let metas = res[1]

			if(item.code === 200 && metas.code === 200){
				console.info('Data from the item updated.');
				item = item.body.product
				item.metafields = metas.body.metafields

				item.skuListReceived = []
				// This skuList is to know if we need to force the prefix later.
				item.variants.forEach(v => {
					if(v.sku.length > 0)
						item.skuListReceived.push(v.sku)
				})

				item.idStoreSelected = shopSelected.id
				
				setProductOptions(item.options)
				setUpdateMode(item)
				fetchIsAutoCreated({
					"sku": item.variants[0].sku,
					"id_marketplace": shopSelected.id,
				}, null, (r) => {
					setIsAutoCreated(r.found)
				})
			}else{
				// One or more requests weren't successfull, so we redirect back o the main shopify page.
				//<Redirect to="/shopify" />
				props.parent.history.push("/shopify");
				props.info({error: "we couln't load the item corresponding to the ID: " + idItem + ", Please make sure you've selected the correct SHOP"})
			}
		})
	}

	const loadItemMeta = (idItem, callback) => {

		if(!idItem)	return false
		
		props.loading(true)
		ajaxPerso( {
			"api": "shopify",
			"trigger": "request",
			"method": 'get',
			"page": 'products/' + idItem + '/metafields.json',
			"shop": shopSelected.id,
			'args': 'namespace=inventory'
		}, callback);
	}

	const searchRequest = (obj, callback) => {

		if(!obj)	return false
		
		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "searchEngine",
			"shop": shopSelected.id,
		}, obj), callback);
	}

	const fetchCountryList = (callback) => {

		props.loading(true)
		ajaxPerso({
			"api": "eio",
			"trigger": "getCountriesList"
		}, callback)
	}


	const showSelectedImg = () => {
		let imagesOut = []
		let imgList = binList.filter(o => {
			if(o.typeBin == 'img')	return o;
		})

		for (let i = 0; i < imgList.length; i++) {
			let obj = imgList.find(obj => {
				if(obj.pos === i)
					return obj
			})

			if(obj){
				imagesOut.push(<div className="p-3 text-center" key={obj.pos + "_imgLoaded"} style={{maxWidth: "150px"}}>
					<div className="d-flex flex-row justify-content-between align-items-center bg-dark text-white mb-3">
						<div className="pl-2 pr-2">{obj.pos + 1}</div>
						<button onClick={() => removeObj(obj.pos, 'img')}>x</button>
					</div>
					<img src={obj.isFromShopify? obj.src : window.URL.createObjectURL(obj)} className="hoverImg" style={{height: "50px"}}/>
					<div className="mt-3 d-flex justify-content-center align-items-center">
						<button onClick={() => moveBinObj(false, obj.pos, "img")} type="button" className={" mr-1 btn btn-secondary pointer"}>&lt;</button>
						<button onClick={() => moveBinObj(true, obj.pos, "img")} type="button" className={" ml-1 btn btn-secondary pointer"}>&gt;</button>
					</div>
				</div>)
			}
		}

		return <div className="p-3 d-flex flex-row flex-wrap justify-content-center">{imagesOut}</div>
	}

	const sectionSelectVariantImage = () => {

		if(!variantMode)	return ""

		let imgSrc = null
		if(updateMode && variantsSpecificsToSubmit[selectedVariant] && variantsSpecificsToSubmit[selectedVariant].valFilled.image_id){
			let imgId = variantsSpecificsToSubmit[selectedVariant].valFilled.image_id

			updateMode.images.forEach(v => {
				if(v.id == imgId){
					imgSrc = v.src
					return
				}
			})
		}

		return <div className="mt-3 border bg-light d-flex justify-content-center align-items-center p-2 ml-3 mr-3">
			{
				imgSrc? <span><img src={imgSrc} className="hoverImg" style={{maxWidth: "80px", maxHeight: "80px"}}/></span> : ""
			}
			<button type="button" className="btn btn-primary" onClick={() => setOpenModalVariantImage(true)}>Select Variant Image</button>
		</div>
	}


	const selectVariantImageModal = () => {
		/**
		 * The image need to be already saved since we need to use is shopify ID for the variant.
		 */
		let imgList = updateMode? updateMode.images : []

		console.info("variantsSpecificsToSubmitvariantsSpecificsToSubmit", variantsSpecificsToSubmit);

		props.modal({
			show: true,
			title: "Variant Image Selector",
			html: () => {
				return <div className="text-center font-weight-bold p-3 text-wrap" style={{maxHeight: "500px", overflowY: "auto"}}>
					<div className="alert alert-primary">
						Click on the picture representing the current variant
					</div>
					{
						imgList.map(img => {

							let currentIdImg = null
							if(variantsSpecificsToSubmit[selectedVariant] && variantsSpecificsToSubmit[selectedVariant].valFilled.image_id)
								currentIdImg = variantsSpecificsToSubmit[selectedVariant].valFilled.image_id

							return <div key={`${img.id}_imgVariantSelector`} className={"d-inline-block mb-3 p-2 mr-3 pointer " + (currentIdImg == img.id? "bg-primary" : "")} onClick={() => {
								// seSelectVariantImage(selectVariantImage == img.id? null : img.id)
								variantsSpecificsToSubmit[selectedVariant].valFilled.image_id = currentIdImg == img.id? null : img.id
								setRefreshView(refreshView+1)
							}}>
								<img src={img.src} style={{maxWidth: "180px", maxHeight: "180px"}}/>
							</div>
						})
					}
				</div>
			}
			,ok: {
				title: "Close",
				fnc: (popup, close) => {
					close()
					setOpenModalVariantImage(false)
				}
			},
			exit: (popup, close) => {
				close()
				setOpenModalVariantImage(false)
			}
		})
	}

	const showSelectedFile = () => {
		let objOut = []
		let fileList = binList.filter(o => {
			if(o.typeBin == 'file')	return o;
		})

		// console.info('fileList', fileList);
		
		for (let i = 0; i < fileList.length; i++) {
			let obj = fileList.find(obj => {
				if(obj.pos === i)
					return obj
			})

			if(obj){
				objOut.push(<div className="p-3 text-center" key={obj.pos + "_imgLoaded"} style={{maxWidth: "150px"}}>
					<div className="d-flex flex-row justify-content-between align-items-center bg-dark text-white mb-3">
						<div className="pl-2 pr-2">{obj.pos + 1}</div>
						<button onClick={() => removeObj(obj.pos, 'file')}>x</button>
					</div>
					<img src={pdfIconSmall} style={{height: "80px"}}/>
					<div style={{fontSize: "12px"}} className="text-break text-wrap">{obj.name? decodeURI(obj.name.split('.').slice(0, -1).join('.')) : null}</div>
					<div className="mt-3 d-flex justify-content-center align-items-center">
						<button onClick={() => moveBinObj(false, obj.pos, "file")} type="button" className={" mr-1 btn btn-secondary pointer"}>&lt;</button>
						<button onClick={() => moveBinObj(true, obj.pos, "file")} type="button" className={" ml-1 btn btn-secondary pointer"}>&gt;</button>
					</div>
				</div>)
			}
		}

		return <div className="p-3 d-flex flex-row flex-wrap justify-content-center">{objOut}</div>
	}

	const moveBinObj = (moveRight, currentPos, type) => {
		// let imgs = images.slice(0)
		console.log(currentPos);
		let cp = binList.slice(0)

		let objGrp = cp.filter(o => {
			if(o.typeBin == type)	return o
		})

		// Because Filter do not garranty any order, we need to sort the element in order to have the system working.
		objGrp.sort((obj1, obj2) => {
			if(obj1.pos > obj2.pos)
				return 1
			return -1
		})

		console.info('currentPos', currentPos + " " + objGrp[currentPos].pos);

		if(moveRight){
			// Move to the head line

			if(currentPos === objGrp.length-1){
				for (let i = 0; i < objGrp.length; i++)
					objGrp[i].pos = i + 1;
				objGrp[currentPos].pos = 0;
			}else{
				let saveVal = objGrp[currentPos+1].pos
				objGrp[currentPos+1].pos = objGrp[currentPos].pos
				objGrp[currentPos].pos = saveVal
			}

		}else{
			// Move to the end

			if(currentPos === 0){
				for (let i = objGrp.length-1; i >= 0; i--)
					objGrp[i].pos = i - 1;
				objGrp[currentPos].pos = objGrp.length-1;
			}else{
				let saveVal = objGrp[currentPos-1].pos
				objGrp[currentPos-1].pos = objGrp[currentPos].pos
				objGrp[currentPos].pos = saveVal
			}
		}

		cp.sort((obj1, obj2) => {
			if(obj1.typeBin == obj2.typeBin && obj1.pos > obj2.pos)
				return 1
			return -1
		})
		
		console.log(type + " final order", objGrp);
		// console.log(type + " final order2", cp);
		
		setBinList(cp);
	}

	const removeObj = (objPos, type) => {
		if(!objPos && objPos !== 0) return false

		let objBl = binList.slice(0),
			posFound = null

		// Find the position to delete
		objBl.forEach( (o,i) => {
			if(o.pos === objPos && o.typeBin == type){
				posFound = i
				return
			}
		})
		
		objBl.splice(posFound, 1)

		// Group per type each bin object
		let objGrouped = {}
		objBl.forEach(o => {
			if(!(o.typeBin in objGrouped))
				objGrouped[o.typeBin] = []
			objGrouped[o.typeBin].push(o)
		});

		// Sort each value per 'pos'
		Object.keys(objGrouped).forEach(t => {
			
			objGrouped[t] = objGrouped[t].sort((obj1, obj2) => {
				if(obj1.pos > obj2.pos)
					return 1
				return -1
			})
	
			objGrouped[t].forEach((o, index) => {
				o.pos = index
			})
		})
		
		setBinList(objBl);
	}

	const searchDeeper = (dataSetUnorganised, obj, data, data2) => {
		if(Object.keys(data).indexOf(obj.id) === -1)
			data[obj.id] = {obj: obj, childs: {}}

		let objFound = data2.find(o => {
			if(o.obj.id === obj.id)	return o
		})

		let objectA = objFound
		if(!objFound){
			data2.push({obj: obj, childs: []})
			objectA = data2[data2.length-1]
		}
		
		if(dataSetUnorganised.length > 0)
			dataSetUnorganised.forEach(obj2 => {
				if(obj.id === obj2.id_parent){
					searchDeeper(dataSetUnorganised, obj2, data[obj.id].childs, objectA.childs)
				}
			})
	}

	const organiseDataSet = (data) => {
		let dataSetOrganised = {}
		let dataSetOrganisedA = []

		data.forEach(obj => {
			if(parseInt(obj.level) === 2)
				searchDeeper(data, obj, dataSetOrganised, dataSetOrganisedA)
		})

		return dataSetOrganisedA
	}

	const updateDsData = (oLvl3, oLvl4, value) => {
		/* console.log("dsData", dsData);
		console.log("dsDoLvl4.obj", oLvl4.obj); */

		oLvl4.obj.value = value;

		/* let cp = Object.assign({}, dsData)
		cp[oLvl4.obj.id] = {
			value: value,
			position: oLvl4.obj.position
		};
		setDsData(cp) */
	}

	const loadModalFeatureSelection = (refresh) => {

		//console.info('refresh', refresh);

		let childs = [],
			dataSetOrganised = []

		if(dataSet){
			
			if(grpDataSetSelected){
				let objFound = dataSet.find(o => {
					if(o.obj.id === grpDataSetSelected)	return o
				})
	
				if(objFound)
					childs = objFound.childs
			}

			//console.log("childs", childs);

			childs = childs.sort((o1, o2) => {
				if(o1.obj.position > o2.obj.position)	return 1
				return -1
			})

			dataSetOrganised = dataSet.sort((o1, o2) => {
				if(o1.obj.title > o2.obj.title)	return 1
				return -1
			})
		}

		//console.log("TEST", dataSetOrganised[32])

		/* console.info('dataSetOrganised', dataSetOrganised);
		console.info('grpDataSetSelected', grpDataSetSelected);
		console.info('last', childs);
		console.info('navCtn', navCtn); */
		
		props.modal({
			key: "define_item_features",
			show: refresh? false : true,
			title: "Define item features",
			html: () => {
				return <div>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text" id="basic-addon1">Group</span>
						</div>
						<select className="custom-select" value={grpDataSetSelected} onChange={(e) => { 
							//setDsData([])
							setGrpDataSetSelected(e.target.value)
						}}>
							{
								dataSetOrganised.map((o) => {
									return <option key={o.obj.id+"_slcGrpDatSet"} value={ o.obj.id }>{ o.obj.title }</option>
								})
							}
						</select>
					</div>
					{
						<div>
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									{
										childs.map((o) => {
											let obj = o.obj
											
											return <a key={obj.id+"_keyGrpTitleNav"} onClick={() => { setNavGrpSelected(obj.id); }} className={
												(navGrpSelected === obj.id? "active" : "") + 
												(parseInt(obj.is_required)? " font-weight-bold" : "") + 
												" nav-item nav-link text-center"} id={obj.id + "_navBtn"} data-toggle="tab" href={"#" + obj.id + "_navCtn"} role="tab" aria-controls={obj.id + "_navCtn"} aria-selected={false}>
												<div>{obj.title}</div>
												{ parseInt(obj.is_required)? <span className="badge badge-pill badge-primary">Needed</span> : "" }
											</a>
										})
									}
								</div>
							</nav>
							<div className="tab-content" id="nav-tabContent">
								{
									childs.length > 0? childs.map((oLvl3) => {

										//console.info('Infoo', navGrpSelected + "===" +  oLvl3.obj.id);
										return <div key={oLvl3.obj.id+"_keyGrpCtnNav"} className={(navGrpSelected === oLvl3.obj.id? "active" : "") + " tab-pane fade show"} id={oLvl3.obj.id + "_navCtn"} role="tabpanel" aria-labelledby={oLvl3.obj.id + "_navBtn"}>
											<div className="container">
												{
													oLvl3.childs.length > 0? (() => {

														oLvl3.childs = oLvl3.childs.sort((o1, o2) => {
															if(o1.obj.position > o2.obj.position)	return 1
															return -1
														})

														return oLvl3.childs.map((oLvl4) => {
														
															//let realValue = ""
															/* for (let i = 0; i < dsData.length; i++) {
																for (let u = 0; u < dsData[i].childs.length; u++) {
																	if( dsData[i].childs[u].id === oLvl4.obj.id ){
																		realValue = dsData[i].childs[u].value || ""
																	}
																}
															} */

															/* for (const idData in dsData) {
																if (dsData.hasOwnProperty(idData) && idData === oLvl4.obj.id) {
																	realValue = dsData[idData];
																	break;
																}
															} */

															return <div key={oLvl4.obj.id+"_keyGrpCtnDataNav"} className="row mt-3">
																<div className="col-sm d-flex align-items-center">
																	{oLvl4.obj.title}
																	{oLvl4.obj.note? <span className="ml-3 tooltipNote" data-toggle="tooltip" title={oLvl4.obj.note}><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>:""}
																</div>
																<div className="col-sm">
																	{ (() => {
																		let output = null;
																		/* let value = ""
																		if(oLvl3.obj.id in dsData && oLvl4.obj.id in dsData[oLvl3.obj.id])
																			value = dsData[oLvl3.obj.id][oLvl4.obj.id] */

																		switch (oLvl4.obj.input_type) {
																		case "textarea":
																			output = <textarea type="text" placeholder={oLvl4.obj.title} defaultValue={oLvl4.obj.value || ""} className={(parseInt(oLvl4.obj.is_required)? "border-primary" : "") + " form-control"} onChange={(e) => {
																				updateDsData(oLvl3, oLvl4, e.target.value)
																			}}></textarea>
																			break;
																		case "countries":
																			
																			output = <span key={oLvl4.obj.value? oLvl4.obj.value : "-"}><Select
																				styles={styleList}
																				isClearable
																				onChange={(selectedOption) => {
																					updateDsData(oLvl3, oLvl4, selectedOption? selectedOption.value : null)
																					//setDataSet(dataSetOrganised.slice(0))
																				}}
																				options={countries.map((obj) => {
																					return {"value": obj? obj.country_name : '', "label": obj? obj.country_name + ' ('+obj.country_code+')' : '-'}
																				})}
																				//value={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
																				defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
																				//defaultInputValue={oLvl4.obj.value? oLvl4.obj.value : "-"}
																				isSearchable={true}
																				//isMulti={true}
																			/></span>

																			break;
																		case "prop65":

																			let urlWebsite = shopSelected.website

																			if(!urlWebsite || !("website" in shopSelected))
																				throw("No website element in shopSelected")

																			const result = /^(?:https?:\/\/)?([^/]+)\/?/i.exec(urlWebsite.trim());

																			if(!result || result.length < 2){
																				console.warn("[1] Please verify the shop name in Shipstation.");
																			}else{

																				console.info("urlWebsite", urlWebsite);

																				const domain = result[1];

																				let labelProp65 = 'Proposition 65 Warning',
																					valueProp65Html = `<a href="https://${domain}/pages/proposition-65" target="_blank">${labelProp65}</a>`,
																					labelOtherChoice = 'No Applicable'

																				let labelToShow = labelProp65;
																				if(!oLvl4.obj.value)
																					updateDsData(oLvl3, oLvl4, valueProp65Html)
																				else{
																					if(oLvl4.obj.value != valueProp65Html)
																						labelToShow = labelOtherChoice
																				}
																				
																				output = <span key={oLvl4.obj.value? oLvl4.obj.value : "-"}>
																					<Select
																						styles={styleList}
																						isClearable
																						onChange={(selectedOption) => {
																							updateDsData(oLvl3, oLvl4, selectedOption? selectedOption.value : null)
																						}}
																						options={[
																							/* {"value": false, "label": '-'}, */
																							{"value": valueProp65Html, "label": labelProp65},
																							{"value": labelOtherChoice, "label": labelOtherChoice},
																						]}
																						//value={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
																						defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": labelToShow}]}
																						//defaultInputValue={oLvl4.obj.value? oLvl4.obj.value : "-"}
																						isSearchable={true}
																						//isMulti={true}
																					/>
																				</span>
																			}

																			break;
																		case "packaging":
																			
																			output = <span key={oLvl4.obj.value? oLvl4.obj.value : "-"}>
																				<Select
																					styles={styleList}
																					isClearable
																					onChange={(selectedOption) => {
																						updateDsData(oLvl3, oLvl4, selectedOption? selectedOption.value : null)
																					}}
																					options={[
																						{"value": false, "label": '-'},
																						{"value": 'Retail', "label": 'Retail'},
																						{"value": 'Bulk', "label": 'Bulk'},
																					]}
																					//value={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
																					defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
																					//defaultInputValue={oLvl4.obj.value? oLvl4.obj.value : "-"}
																					isSearchable={true}
																					//isMulti={true}
																				/>
																			</span>

																			break;
																		case "brand":
																			//console.log("DEBUG", oLvl4.obj);

																			output = <span key={oLvl4.obj.value? oLvl4.obj.value : "-"}><Select
																				styles={styleList}
																				isClearable
																				onChange={(selectedOption) => {
																					updateDsData(oLvl3, oLvl4, selectedOption? selectedOption.value : null)
																					//setDataSet(dataSetOrganised.slice(0))
																				}}
																				options={brands.map((obj) => {
																					return {value: obj.name, label: obj.name}
																				})}
																				//value={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
																				defaultValue={[{"value": oLvl4.obj.value? oLvl4.obj.value : "-", "label": oLvl4.obj.value? oLvl4.obj.value : "-"}]}
																				isSearchable={true}
																				//isMulti={true}
																			/></span>
																			
																			break;
																		case "weight":
																			// console.log(calculatedFieldsDt);
																			let value = 0.0;
																			let valCaptures = (new RegExp(/(\d*\.*\d*)\s*(\w*)/, "gmi")).exec(oLvl4.obj.value)

																			if(valCaptures[1])
																				value = parseFloat(valCaptures[1].trim())
																			
																			output = <div key={value || ""} className="d-flex flex-row">
																				<input type="number" defaultValue={value || ""} placeholder={oLvl4.obj.title} className={(parseInt(oLvl4.obj.is_required) === 1? " w-50 border-primary" : "") + " form-control"} onChange={(e) => {
																					// Select a type of weight by default
																					if(value){
																						if(!calculatedFieldsDt[oLvl4.obj.id]){
																							let cpCalculated = Object.assign({}, calculatedFieldsDt)
																							cpCalculated[oLvl4.obj.id] = {
																								"type": "",
																								"name": "weight"
																							}
																							setCalculatedFieldsDt(cpCalculated)
																						}
																					}
																					updateDsData(oLvl3, oLvl4, e.target.value)
																				}}/>
																				<span className="w-50">
																					<Select
																						styles={styleList}
																						onChange={(selectedOption) => {
																							console.log(selectedOption);
																							
																							let cpCalculated = Object.assign({}, calculatedFieldsDt)
																							cpCalculated[oLvl4.obj.id] = {
																								"type": selectedOption.value,
																								"name": "weight"
																							}
																							setCalculatedFieldsDt(cpCalculated)
																						}}
																						options={[
																							{value: '', label: '-'},
																							{value: 'Pound', label: 'Pound'},
																							{value: 'Ounce', label: 'Ounce'},
																							{value: 'Kilogram', label: 'Kilogram'},
																							{value: 'Gram', label: 'Gram'}
																						]}
																						value={[{"value": calculatedFieldsDt[oLvl4.obj.id]? calculatedFieldsDt[oLvl4.obj.id].type : '', "label": calculatedFieldsDt[oLvl4.obj.id]? calculatedFieldsDt[oLvl4.obj.id].type : '-'}]}
																						isSearchable={true}
																					/>
																				</span>
																			</div>
																			break;
																	
																		default:
																			output = <div className="input-group">
																				<input key={oLvl4.obj.value || ""} type={["text", "number"].indexOf(oLvl4.obj.input_type) !== -1? oLvl4.obj.input_type : "text"} defaultValue={oLvl4.obj.value || ""} placeholder={oLvl4.obj.title} className={(parseInt(oLvl4.obj.is_required) === 1? "border-primary" : "") + " form-control"} onChange={(e) => {
																					updateDsData(oLvl3, oLvl4, e.target.value)
																				}}></input>
																				{ ( ["part number", "upc"].indexOf(oLvl4.obj.title.toLowerCase()) !== -1? <div className="input-group-append">
																					<button className="btn btn-outline-primary" onClick={() => {
																						if(oLvl4.obj.title.toLowerCase().indexOf("part number") !== -1)
																							updateDsData(oLvl3, oLvl4, $("#forMpn").val())
																						else if(oLvl4.obj.title.toLowerCase().indexOf("upc") !== -1)
																							updateDsData(oLvl3, oLvl4, $("#forUpc").val())

																						setDataSet(dataSetOrganised.slice(0))
																					}} title="Copy the value from the listing below">Copy</button>
																				</div> : "" ) }</div>
																			break;
																		}
																		return output;
																	})() }
																</div>
															</div>
														})
													})() : <div className="d-flex justify-content-center font-weight-bold m-3">empty</div>
												}
											</div>
										</div>
									}) : <div className="d-flex justify-content-center font-weight-bold m-3">empty</div>
								}
							</div>
						</div>
					}
				</div>
			}
			,ok: {
				title: "Validate",
				fnc: (popup, close) => {
					// Check that if a group required exist, all fields are filled
					let errors = [],
						errorRequired = [],
						success = false

					if(grpDataSetSelected){
						let listObjDataRequired = [] // {group{}, childs[]}
						
						let objFound = dataSetOrganised.find(o => {
							if(o.obj.id === grpDataSetSelected)	return o
						})
						
						console.warn('dataSetOrganised', dataSetOrganised);
						console.warn('objFound', objFound);
						console.warn('calculatedFieldsDt', calculatedFieldsDt);
						//console.warn('dsData', dsData);
						//return false;

						
						let listIdsGrpWithValue = [],
							listIdsLvl4 = []
						let htmlItemFeature = ''
						let htmlItemFeatureLine = ''
						/**
						 * Scrape the user value object 'dataSetOrganised' and make a id list of each id with real value
						 */
						let dsData2 = []
						objFound.childs.forEach(lvl3 => {

							lvl3.childs.forEach(lvl4 => {
								
								if(lvl4.obj.value){

									let parentObj = dsData2.find(o => {
										if(o.obj.id === lvl3.obj.id)	return o
									})
	
									if(!parentObj){
										let cp = Object.assign({}, lvl3)
										cp.childs = []
										dsData2.push(cp);
									}
									dsData2[dsData2.length-1].childs.push(lvl4);
								}
							})
						})

						console.log("dsData2", dsData2);


						dsData2.forEach(o => {
							o.childs = o.childs.sort((o1, o2) => {
								if(o1.obj.position > o2.obj.position) return 1
								return -1
							})
						})

						let dsDataSorted = dsData2.sort((o1, o2) => {
							if(o1.obj.position > o2.obj.position) return 1
							return -1
						})

						console.warn('dsDataSorted', dsDataSorted);



						for (let i = 0; i < dsDataSorted.length; i++) {
							htmlItemFeatureLine = ''
							let oLvl3 = dsDataSorted[i].obj
							let idLvl3 = dsDataSorted[i].obj.id

							for (let u = 0; u < dsDataSorted[i].childs.length; u++) {
								let value = dsDataSorted[i].childs[u].obj.value.toString()
								let oLvl4 = dsDataSorted[i].childs[u].obj
								let idLvl4 = dsDataSorted[i].childs[u].obj.id

								console.warn("value", value);
								
								if(value.trim().length > 0 && listIdsGrpWithValue.indexOf(idLvl3) === -1)
									listIdsGrpWithValue.push(idLvl3)
								if(value.trim().length > 0 && listIdsLvl4.indexOf(idLvl4) === -1)
									listIdsLvl4.push(idLvl4)

									
								if(value.trim().length > 0){

									htmlItemFeatureLine += `<div class="attribute">`+oLvl4.title+`</div>`

									// get some particuliar case from the data set like the weight et the dimensions
									console.log("calculatedFieldsDt", calculatedFieldsDt);
									
									let objCalculated = calculatedFieldsDt[oLvl4.id]? calculatedFieldsDt[oLvl4.id] : false
									if(objCalculated){
										if(objCalculated.name === "weight"){
											let listWeight = calculatedWeight(value, objCalculated.type)

											console.info('value', value);
											console.info('objCalculated.type', objCalculated.type);
											console.info('listWeight', listWeight);
											

											if(listWeight.data.length > 0){
												htmlItemFeatureLine += `<div class="attribute-value" data-idGrpData="`+oLvl4.id+`" data-grams="` + parseFloat(listWeight.grams).toFixed(2) + `" data-originalValue="` + value + `" data-originalType="` + (objCalculated.type? objCalculated.type : "") + `">`
												listWeight.data.forEach(obj => {
													htmlItemFeatureLine += `<div>` +  obj.value + (obj.type? " " + obj.type : "") + `</div>`
												})
												htmlItemFeatureLine += `</div>`
											}
										}

									}else
										htmlItemFeatureLine += `<div class="attribute-value" data-idGrpData="`+oLvl4.id+`">` + (oLvl4.input_type==="textarea"? value.replace(/\n/g,"<br/>") : value ) + `</div>`

								}
							}

							if(htmlItemFeatureLine.length > 0){
								htmlItemFeature += `<div class="product-specifications" data-idGrpDataSet="` + grpDataSetSelected + `">
									<div class="attribute-group" data-idGrp="`+oLvl3.id+`">
										<div class="attribute-group-name">`+oLvl3.title+`</div>`
								htmlItemFeature += htmlItemFeatureLine
								htmlItemFeature += `</div></div>`
							}
						}
						

						/**
						 * Scrall each nav from the group selected and make a list of required value
						 *  - a required nav has all fields in required mode
						 *  - if a no required nav has a value somewhere, we capture each required value from that nav
						 */
						for (let i = 0; i < objFound.childs.length; i++) {
							let child = objFound.childs[i]
							
							child.childs.forEach(oLvl4 => {
								if(parseInt(child.obj.is_required) || parseInt(oLvl4.obj.is_required) && listIdsGrpWithValue.indexOf(child.obj.id) !== -1){
									// Check only childs marked as required
									if(parseInt(oLvl4.obj.is_required)){
										let objDataRequired =  Object.assign({}, oLvl4.obj)
										delete objDataRequired.childs
	
										// Add the parent cleaned without childs to the child, so it's easier to retrieve it
										let parentObjCp = Object.assign({}, child.obj)
										delete parentObjCp.childs
										objDataRequired.parent = parentObjCp
	
										listObjDataRequired.push( objDataRequired )
									}
								}
							});
						}

						console.warn('listIdsGrpWithValue', listIdsGrpWithValue);
						console.warn('listIdsLvl4', listIdsLvl4);
						console.warn('listObjDataRequired', listObjDataRequired);
						
						let warningRequiredFieldShown = false
						listObjDataRequired.forEach(objDataRequired => {
							
							if(listIdsLvl4.indexOf(objDataRequired.id) === -1){
								errorRequired.push(objDataRequired.title+" missing.</br>")
							}
							if(!warningRequiredFieldShown && errorRequired.length > 0){
								errorRequired.unshift("All fields from the required group called <span class=\"font-weight-bold\">"+objDataRequired.parent.title + "</span></br>")
								warningRequiredFieldShown = true
							}
						})
						
						if(errorRequired.length === 0){
							success = true
							
							console.warn('htmlItemFeatureLine:::', htmlItemFeature);
							setHtmlDT(htmlItemFeature)
							close()
						}
						
						props.info({
							error: errorRequired.length > 0? errorRequired : false
						})
					}
				}
			}
		})
	}

	const calculatedWeight = (value, type) => {
		let linesOutput = [];
		let grams = false

		let dblVal = parseFloat(value.trim())

		// In the case whe nwe just have a number as weight, we just return back that number. (old bad listin EIO)
		if(!type){
			linesOutput = [
				{ "value": dblVal, "type": null },
			]
		}else{
			type = type.toLowerCase();
	
			if(type.toLowerCase() === "gram"){
				grams = dblVal;
			}else{
				["ounce", "oz"].forEach(val => {
					if(val.indexOf(type) !== -1)
						grams = dblVal * 28.35
				});
				["pound", "lb"].forEach(val => {
					if(val.indexOf(type) !== -1)
						grams = dblVal * 453.592
				});
				["kilogram", "kg"].forEach(val => {
					if(val.indexOf(type) !== -1)
						grams = dblVal * 1000
				});
			}

			console.log("type_calculated", type);
			console.log("grams_calculated", grams);

			if(grams * 28.35 > 16)
				linesOutput = [
					{ "value": grams / 1000, "type": "kg" },
					{ "value": grams / 453.592, "type": "lb" }
				]
			else
				linesOutput = [
					{ "value": grams, "type": "g" },
					{ "value": grams / 28.35, "type": "oz" }
				]
		}

		linesOutput.forEach((o, i) => {
			linesOutput[i].value = parseFloat(o.value).toFixed(2)
		});
			
		return {
			"data": linesOutput,
			"grams": grams
		}
	}

	/* const findInObj = (arrayS, key, valSearched) => {
		return arrayS.find((obj) => {
			if(obj[key] === valSearched)
				return obj
		})
	} */

	function searchEngine(searchedVal){
		/**
		 * We delay the search engine to search when the user is done writting and limitate the token use
		 */
		console.log(searchedVal);
		setSearchedOld(searchedVal)

		var timer = setTimeout(() => {
			setSearched(searchedVal)
			clearTimeout(timer);
		}, 500)
	}

	const loadAndshowForm = (shopName, argsForProps) => {
		
		let objShop = stores.find(obj => {
			if(obj.name === shopName)
				return obj
			return false;
		})

		if(objShop){
			return objShop.fnc(argsForProps)
		}
	}

	if(!props.securityClearance(props))	return "";
	console.info("storesList", refConst.stores);

	return ( // brand, title, country of origin, price, sku, quantity, UPC, weight (lbs, oz), description
		<div className="main ctnPage container">
			<div className="d-flex flex-row">
				<div className="mr-3">
					<button className="btn btn-secondary dropdown-manual-toggle text-light" type="button" id="dropdownHistory" onClick={(e) => {
						$(".dropdownHistory-menu").toggleClass("hide")
					}}>
						History
						<span className="badge badge-light ml-2 mr-1 tooltipActualise" {...{ "data-toggle": "tooltip", "title": `${history.updated.length} updated` }}>
							<FontAwesomeIcon icon={faEdit}  style={{fontSize: "15px", backgroundColor: "white", color: "black", marginRight: "5px"}}/>
							{history? history.updated.length : "0"}
						</span>
						<span className="badge badge-light ml-2 mr-1 tooltipActualise" {...{ "data-toggle": "tooltip", "title": `${history.inserted.length} inserted` }}>
							<FontAwesomeIcon icon={faPlusSquare}  style={{fontSize: "16px", color: "black", marginRight: "5px"}}/>
							{history? history.inserted.length : "0"}
						</span>
					</button>
					<div className="position-relative dropdownHistory-menu p-0 hide" aria-labelledby="dropdownHistory" style={{maxHeight: "280px"}}>
						<div className="position-absolute bg-light border" style={{
								top: "0px",
								left: "0px",
								zIndex: 9999
							}}>
							<div className="nav nav-tabs" id="nav-tabHistory" role="tablist" >
								{
									["updated", "inserted"].map(value => {
										return <a key={value+"_keyBtnHistoryNav"} onClick={() => { setNavHistoryGrpSelected(value); }} className={
												(navHistoryGrpSelected === value? "active" : "") + " nav-item nav-link text-center"
											} id={value + "_navBtnHistory"} data-toggle="tab" href={"#" + value + "_navCtnHistory"} role="tab" aria-controls={value + "_navCtnHistory"} aria-selected={false}>
											<div>{value}<span className="badge badge-light ml-2">{history[value].length}</span></div>
										</a>
									})
								}
							</div>
							<div className="tab-content" id="nav-tabHistoryContent">
							{
								["updated", "inserted"].map((value) => {

									return <div key={value + "_navCtnHistory"} id={value + "_navCtnHistory"} role="tabpanel" aria-labelledby={value + "_navBtnHistory"} className={(navHistoryGrpSelected === value? "active" : "") + " tab-pane fade show"} style={{
										minWidth: "300px",
										maxHeight: "350px",
										overflowY: "auto",
									}}>
										{
											history && history[value].length > 0? history[value].map(obj => {

												return <div key={obj.id} className="d-flex justify-content-between align-items-center mt-0 mb-0 p-2 border-bottom" style={{width: "auto"}}>
													<div className="mr-3" style={{fontSize: "11px"}}>
														{obj.shopify_sku? <div>{ obj.shopify_sku }</div> : ""}
														<div>{ obj.date }</div>
														{/*  obj.id_product? <NavLink to={ "/shopify/" + obj.id_product }>Open</NavLink> : '' */ }
													</div>
													{ obj.id_product? <span className="text-primary pointer" onClick={() => {

														let storeSelected = stores.find(o => {
															//console.log(o.api.toLowerCase() + " -- " + obj.store.toLowerCase());
															if(o.id === obj.store)
																return obj
															return false;
														})

														//console.log(storeSelected);
														if(storeSelected){
															setShopSelected(storeSelected)
														}
														
														$(".dropdownHistory-menu").toggleClass("hide")
														console.log(props.parent.history.push("/shopify/" + obj.id_product));

													}}>Open</span> : '' }
												</div>
											}) : <div className="d-flex justify-content-between align-items-center mt-0 mb-0 text-center p-3" style={{width: "auto"}}>Empty</div>
										}
									</div>
								})
							}
							</div>
						</div>
					</div>
				</div>
				<div className="input-group mb-4">
					<div className="input-group-prepend">
						<span className="input-group-text" id="basic-addon1">Website</span>
					</div>
					<select className="custom-select font-weight-bold" value={ JSON.stringify(shopSelected) } onChange={(e) => {
						$('.hasTooltip').tooltip('dispose') // Need to be affective before the rerender of the new form cause nodes will be different.
						store("shopSelected", JSON.parse(e.target.value))
						setShopSelected(JSON.parse(e.target.value))
					}}>
						{
							refConst.stores.map(obj => {
								if(obj.id_marketplace == 14)
									return <option key={obj.name} value={JSON.stringify(obj)}>{obj.name}</option>
							})
						}
					</select>
				</div>
			</div>
			<div>
				<div className="input-group mt-2 mb-5">
					<input onClick={() => {setProposalHidden(false)}} onChange={(e) => { searchEngine(e.target.value); }} value={searchedOld} type="search" className="form-control border-dark" style={{padding: "1.675rem 0.75rem", fontSize: "1.3em"}} placeholder="Search items by title" aria-label="Recipient's username" aria-describedby="button-search" />
					<div className="input-group-append">
						<span onClick={() => {
							searchEngine('');
							setProposalHidden(true)
						}} className={(searched.length === 0? "d-none" : "d-flex align-items-center btn btn-outline-secondary bg-info text-white")}>x</span>
						<button className="btn btn-outline-secondary bg-dark text-white" type="button" id="button-search" onClick={(e) => { initiateSearch() }}>Search</button>
					</div>
				</div>
				<div className={proposalHidden? "d-none" : ""  + " position-relative"}>
					<ul className="list-group position-absolute shadow w-100" style={{top: "-35px", left: "0px", zIndex: "9876", maxHeight: "200px", overflowX: "auto"}}>
						{
							searchResult.map(obj => {
								return <li key={obj.id+"_searchResult"} className="list-group-item">
									{
										obj.featuredImage? 
											<img src={obj.featuredImage.originalSrc} height="45px" width="45px" className="mr-3 fitCover"/> :
											<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-image-fill mr-3 fitCover" viewBox="0 0 16 16" style={{color: "#CCC"}}>
												<path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
											</svg>
									}
									<NavLink to={"/shopify/"+obj.id}>{obj.title}</NavLink>
								</li>
							})
						}
					</ul>
				</div>
			</div>
			{/* <div className={(multiVariantLock? "" : "d-none") + " mb-3 border bg-warning rounded text-center p-3 fw-bold text-wrap"}>
				This product has multiple variants, this tool is not ready yet for the variants feature, please update/delete variants on the Shopify admin page.
			</div> */}
			{
				updateMode? <div className="d-flex flex-row align-items-center mb-5">
					<NavLink to={"/shopify"}><button type="button" className="btn btn-lg btn-primary mr-3">Go to empty form</button></NavLink>
					<div className="d-flex flex-column">
						{
							shopSelected && shopSelected.account_name? 
								<div className="d-flex flex-column">
									<a target="_blank" rel="noopener noreferrer" href={ "https://" + shopSelected.account_name + "/admin/products/" + updateMode.id}>Open item on shopify admin</a>
									<a target="_blank" rel="noopener noreferrer" href={ "https://" + shopSelected.account_name + "/products/" + updateMode.handle}>Open item on web store</a>	
								</div>
								: ""
						}
					</div>
					<div className="input-group flex-nowrap ml-4">
						<div className="input-group-prepend">
							<span className="input-group-text" id="addon-wrapping">Title</span>
						</div>
						<input type="text" className="form-control font-weight-bold" value={updateMode.title} readOnly/>
					</div>
				</div>
					: ""
			}
			<div className={(smallWidthWindow? "flex-column" : "") + " row mb-5"}>
				<div className={(smallWidthWindow? "" : "col-sm")} style={{minWidth: "550px"}}>
					<FormShopifyListing
						{...props}
						title={title}
						setTitle={setTitle}
						loadedBinList={loadedBinList}
						setLoadedBinList={setLoadedBinList}
						alsoCreateFilesFor={alsoCreateFilesFor}
						binList={binList}
						setBinList={setBinList}
						setBinListDropzone={setBinListDropzone}
						images={images}
						setImages={setImages}
						setImagesDropzone={setImagesDropzone}
						files={images}
						// setFiles={setFiles}
						setFilesDropzone={setFilesDropzone}
						setMsgInfo={setMsgInfo}
						menu={menu}
						brands={brands}
						countries={countries}
						htmlDT={htmlDT}
						setHtmlDT={setHtmlDT}
						//setDsData={setDsData}
						setGrpDataSetSelected={setGrpDataSetSelected}
						msgInfo={msgInfo}
						// extendedBody={extendedBody}
						updateMode={updateMode}
						setUpdateMode={setUpdateMode}
						setProposalHidden={setProposalHidden}
						loadItem={loadItem}
						shopSelected={shopSelected}
						loadAndshowForm={loadAndshowForm}
						setNavGrpSelected={setNavGrpSelected}
						version={version}
						setVersion={setVersion}
						initialState={initialState}
						dataSet={dataSet}
						setDataSet={setDataSet}
						emptyForm={emptyForm}
						originlDataSet={originlDataSet}
						setCalculatedFieldsDt={setCalculatedFieldsDt}
						loadUserHistory={loadUserHistory}
						// multiVariantLock={multiVariantLock}
						selectedVariant={selectedVariant}
						setSelectedVariant={setSelectedVariant}
						// selectVariantImage={selectVariantImage}
						refreshView={refreshView}
						setRefreshView={setRefreshView}
						variantsSpecificsToSubmit={variantsSpecificsToSubmit}
						setVariantsSpecificsToSubmit={setVariantsSpecificsToSubmit}
						globalOptions={globalOptions}
						setGlobalOptions={setGlobalOptions}
						navGrpOptionsSelected={navGrpOptionsSelected}
						setNavGrpOptionsSelected={setNavGrpOptionsSelected}
						variantMode={variantMode}
						setVariantMode={setVariantMode}
						productOptions={productOptions}
						setProductOptions={setProductOptions}
						// mpnList={mpnList}
						// setMpnList={setMpnList}
						resetVariantError={resetVariantError}
						changeDatasetStore={changeDatasetStore}
						isAutoCreated={isAutoCreated}
						setIsAutoCreated={setIsAutoCreated}
					/>
				</div>
				<div className={(smallWidthWindow? "" : "col-sm") + " overflow-hidden columnImgShopify"}>
					{
						!isAutoCreated? <div>
							<div className="pl-4 pr-4 mb-4">
								<button type="button" className="btn btn-secondary btn-lg btn-block text-wrap" onClick={() => loadModalFeatureSelection(false)}>Select features</button>
							</div>
							<div className="nav nav-tabs" id="nav-tab" role="tablist">
								{
									menuRight.map((o) => {
										return <a key={o.title+"_keyGrpTitleNav"} onClick={() => { setNavGrpSelectedMenuRight(o.title); }} className={
											(navGrpSelectedMenuRight === o.title? "active" : "") + 
											" nav-item nav-link text-center"} id={o.title + "_navBtn"} data-toggle="tab" href={"#" + o.title + "_navCtn"} role="tab" aria-controls={o.title + "_navCtn"} aria-selected={false}>
											<div>{o.title}</div>
										</a>
									})
								}
							</div>
						</div> : ""	
					}
					<div className="tab-content" id="nav-tabContent">
						{
							menuRight.length > 0? menuRight.map((o) => {

								return <div key={o.title+"_keyGrpCtnNav"} className={(navGrpSelectedMenuRight === o.title? "active" : "") + " tab-pane fade show"} id={o.title + "_navCtn"} role="tabpanel" aria-labelledby={o.title + "_navBtn"}>
									<div className="mt-3">
										{o.body()}
									</div>
								</div>;
							}) : null
						}
					</div>
				</div>
			</div>
			<h6 className="position-fixed text-secondary" style={{right: "15px", bottom: "0px"}}>v{version}</h6>
		</div>

	);
}

export default ShopifyListing;