import React, { Component } from 'react';
import $ from 'jquery'; 
import Filter from './ctn/eBay/Filter.js'
import ItemsTable from './ctn/eBay/ItemsTable.js'
import Pagination from './ctn/eBay/Pagination.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faLessThanEqual } from '@fortawesome/free-solid-svg-icons'
import CatalogItems from './ctn/eBay/CatalogItems.js';
import FilterTags from './ctn/eBay/FilterTags.js';
import Coverage from './ctn/historySells/Coverage.js';
import MenuCatalog from './ctn/menuCatalog.js';
import CreateItem from './ctn/CreateItem.js';
import CreateShopifyClone from './ctn/CreateShopifyClone.js';
import ShopifyInventoryPolicy from './ctn/ShopifyInventoryPolicy.js';
import UpdateShopifyTags from './ctn/UpdateShopifyTags.js';
import DeleteItem from './ctn/DeleteItem.js';
import EbayImgUpdater from './ctn/EbayImgUpdater.js';
import EbayQtyChange from './ctn/EbayQtyChange';
import Select from 'react-select';
import {ajaxPerso, findWherePosted} from "../fnc.js"
import Export from './ctn/Export';

const refConst = require("../constants.js").v

const initialState = {
	pageLoaded: [false],
	items: [],
	presence: [],
	notInShop: false,

	brandSelected: '',
	packageSelected: '',
	tagSelected: null,

	stores: [],
	total_items: 0,
	itemsPresence: [],
	// itemsSelected: [5186073, 5186063],
	itemsSelected: [],
	limit_per_page: 50,
	current_page: 1,
	selectedStoreCoverage: "",
	error: null,
	success: null,
	warning: null,
	dataSheet: []
};

const initialValue = {
	previousItemReadyForStoreSelected: null,
	itemReadyForStoreSelected: null,
	policyShippingIdModal: null,
	policyPayementgIdModal: null,
	policyReturnIdModal: null
};

const initialRef = {
	'active': true,
	'search': '',
	'startAt': null,
	'qtyPerPage': 50,
	'qtyOnHand': null,
	'operatorQtyOnHand': '>',
	'cost': null,
	'operatorCost': '>'
};

class Catalog extends Component {

	constructor(props) {

		super(props);
		console.info('props', props);

		this._isMounted = false;
		this.policyShippingIdModal = initialValue.policyShippingIdModal;
		this.policyPayementgIdModal = initialValue.policyPayementgIdModal;
		this.policyReturnIdModal = initialValue.policyReturnIdModal;

		this.previousDataSheet = initialState.dataSheet

		this.previousItemReadyForStoreSelected = initialValue.previousItemReadyForStoreSelected;

		this.state = JSON.parse(JSON.stringify(initialState));

		this.basicStateTags = [{
			key: 'brandSelected',
			nameView: 'Vendor'
		}, {
			key: 'packageSelected',
			nameView: 'Package'
		},{
			key: 'tagSelected',
			nameView: 'Tags'
		},{
			key: 'active',
			nameView: 'Inactive'
		}]

		this.listDataSelect = [
			'brandSelected',
			'packageSelected',
			'tagSelected'
		]

		this.filtersList = ['search', 'startAt', 'qtyPerPage', 'qtyOnHand', 'operatorQtyOnHand', 'cost', 'operatorCost', 'active'];
		this.previousSku = initialRef.search
		this.filtersList.forEach(l => {
			this[l] = React.createRef()
		});
		
		this.formFilter = React.createRef()

		// Bind Allow access by "this" into the function
		this.fetchItems = this.fetchItems.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.clearResult = this.clearResult.bind(this);
		this.selectListAction = this.selectListAction.bind(this);
		this.updatePage = this.updatePage.bind(this);
		this.selectItems = this.selectItems.bind(this);
		this.setSelectedStoreCoverage = this.setSelectedStoreCoverage.bind(this);
		this.toggleNotInShop = this.toggleNotInShop.bind(this);
		this.setStateSafe = this.setStateSafe.bind(this);
		//console.warn(this.props.getLevel());
		//console.warn(this.props.getUser());

	}

	componentWillUnmount() {
		// this.initialState = this.state
		this._isMounted = false;
	}

	/* componentDidUpdate(prevProps){
		let pageLoaded = this.state.pageLoaded
		if(pageLoaded[0] === true){
			this.props.loading(false)
			// this.setStateSafe({ "pageLoaded" : initialState.pageLoaded.slice(0)})
		}

		if(this.isModalOpen){
			console.info('Updated');
			
			// this.modaleCreateItem(true);
			// this.previousItemReadyForStoreSelected = this.state.itemReadyForStoreSelected;
		}
	} */

	componentDidMount() {
		this._isMounted = true;

		if(!this.props.securityClearance(this.props))	return;

		this.getStore();
		this.updateItem();
	}

	componentDidUpdate(prevProps){
		console.info('prevProps', prevProps);
		if(JSON.stringify(this.previousDataSheet) !== JSON.stringify(this.state.dataSheet)){
			this.previousDataSheet = this.state.dataSheet;
			this.updateItem();
		}
	}

	/* persoSetState(obj, callback) {
		this.setStateSafe(obj, callback)
	}
 */
	setStateSafe() {
		if(this._isMounted)
			this.setState(...arguments)
	}

	groupFilters(){
		// Deal with reference to input
		var filterObjList = [];
		for (const key in this) {
			if (this.hasOwnProperty(key)) {
				if(this.filtersList.indexOf(key) !== -1){

					let newKeyName = this.basicStateTags.find(oF => {
						if(oF.key == key)	return oF
					})

					filterObjList.push({
						initialValue: initialRef[key],
						key: newKeyName? newKeyName.nameView : key,
						set: (val) => {
							if(this[key].current && this[key].current.nodeName.toLowerCase() == "input" && this[key].current.getAttribute("type").toLowerCase() == "checkbox")
								this[key].current.checked = val
							else
								this[key].current.value = val
							this.setStateSafe({}, this.updateItem())
						},
						get: () => {
							if(this[key].current && this[key].current.nodeName.toLowerCase() == "input" && this[key].current.getAttribute("type").toLowerCase() == "checkbox"){
								// console.info(`${key}` + ' -> this[key].current.checked ' + `[${this[key].current.checked}]`, this[key].current);
								return this[key].current.checked
							}else if(this[key] && this[key].current /* && this[key].current.value !== initialRef[key] */)
								return this[key].current.value
						}
					})
				}
			}
		}
		
		if(this.state.selectedStoreCoverage){
			
			let isStore = this.state.selectedStoreCoverage.idStore
			let statusStore = this.state.selectedStoreCoverage.status
			let storeW = this.state.stores.find(oS => {
				if(parseInt(oS.id) === isStore)	return  oS
			})

			if(storeW){
				filterObjList.push({
					initialValue: initialState.selectedStoreCoverage,
					key: 'Store',
					set: (val) => {
						this.setSelectedStoreCoverage(val)
					},
					get: () => {
						return storeW.name
					}
				})
			}
		}

		if(this.state.dataSheet && this.state.dataSheet.length != initialState.dataSheet.length){
			filterObjList.push({
				initialValue: initialState.dataSheet.length,
				key: 'Items from sheet',
				set: (val) => {
					this.setStateSafe({
						dataSheet: initialState.dataSheet.slice(0)
					}, () => {
						this.updateItem()
					})
				},
				get: () => {
					return this.state.dataSheet.length
				}
			})
		}

		this.listDataSelect.forEach(key => {
			// console.info('asdasdasd [' + key, JSON.stringify(initialState[key]))
			// console.info('asdasdasd [' + key, JSON.parse(JSON.stringify(initialState[key])))

			let newKeyName = this.basicStateTags.find(oF => {
				if(oF.key == key)	return oF
			})

			filterObjList.push({
				initialValue: JSON.parse(JSON.stringify(initialState[key])),
				key: newKeyName? newKeyName.nameView : key,
				set: (val) => {
					this.setStateSafe({
						[key]: val
					}, () => {
						this.updateItem()
					})
				},
				get: () => {
					return this.state[key]
				}
			})
		})

		// console.info('filterObjList', filterObjList);
		return filterObjList
	}

	setSelectedStoreCoverage(d){
		this.setStateSafe({
			selectedStoreCoverage: d,
			current_page: initialState.current_page
		}, () => {
			this.updateItem();
		})
	}
	toggleNotInShop(val){
		this.setStateSafe({
			notInShop: val,
		}, () => {
			this.updateItem();
		})
	}

	updateItem(e){
		if(e)
			e.preventDefault();

		let pageLoaded = this.state.pageLoaded

		this.fetchItems({
			trigger: "getItemsCatalog_v2",
		}, (r) => {
			console.info('Fetched items:', r);
			this.props.loading(false)

			if(r.success){

				r.data.forEach(item => {
					item.list_id_store = null
				})

				pageLoaded[0] = true;
				this.setStateSafe({
					"items": r.data,
					"total_items": r.total_items,
					"pageLoaded": pageLoaded,
				})

				
				findWherePosted({
					"skuList": r.data.map((o) => {
						return o.sku
					})
				}, (presenceResult) => {
					console.info("res_findWherePosted", presenceResult);
	
					if(presenceResult.success){

						r.data.forEach(item => {
							presenceResult.data.some(presence => {

								if(presence.sku == item.sku){
									item.list_id_store = presence.list_id_store
									return true
								}
							})
						})

						this.setStateSafe({
							"items": r.data,
						})
					}
				})

			}else if(r.error)
				this.props.info({"error": "Error while fetching items."})
		}/* , (r) => {
			console.info('Fetched presence:', r);
			if(r.success){
				pageLoaded[1] = true;
				this.setStateSafe({
					"presence": r.data,
					"pageLoaded": pageLoaded
				})
			}else if(r.error)
				this.props.info({"error": r.error})
		} */);
	}

	fetchItems(obj, callbackItem, callbackPresence) {

		let errors = [];
		console.info("this.state.notInShop", this.state.notInShop);
		if(this.state.notInShop && (!this.state.selectedStoreCoverage || !this.state.selectedStoreCoverage.idStore)){
			errors.push("Please select a store in the graph.")
		}

		if(errors.length > 0){
			this.props.info({"error": errors.join("<br>")})
		}else{

			console.log("fetchItems", obj);
			let fromNbr = "start" in obj? obj.start : this.state.current_page - 1
			// let nbrItem = "limit" in obj? obj.limit : this.qtyPerPage.current? this.qtyPerPage.current.value : this.state.limit_per_page
			let nbrItem = "limit" in obj? obj.limit : this.state.limit_per_page
			let whereList = [],
				addArguments = {}
			console.log('this.operatorQtyOnHand', this.operatorQtyOnHand);
			console.log('this.state.selectedStoreCoverage', this.state.selectedStoreCoverage);
			
	
			if(this.startAt.current && this.startAt.current.value)
				whereList.push(`date_created > '${this.startAt.current.value}'`)
			if(this.search.current && this.search.current.value.trim())
				addArguments.search = this.search.current.value.trim()
			if(this.qtyOnHand.current && this.qtyOnHand.current.value.trim())
				if(this.operatorQtyOnHand.current.value === "=" && this.qtyOnHand.current.value === "0")
					whereList.push(`qty_on_hand IS NULL`)
				else
					whereList.push(`qty_on_hand ${this.operatorQtyOnHand.current.value} ${this.qtyOnHand.current.value}`)
			if(this.cost.current && this.cost.current.value.trim())
				whereList.push(`cost ${this.operatorCost.current.value} ${this.cost.current.value}`)
			if(this.state.selectedStoreCoverage){
				if(this.state.selectedStoreCoverage.status)
					addArguments.status = this.state.selectedStoreCoverage.status == 'active'? 1: 0
				addArguments.shop = this.state.selectedStoreCoverage.idStore
			}
			if(this.state.dataSheet.length > 0)
				addArguments.skuList = this.state.dataSheet.map(o  => o.sku).join(',')
	
			if(this.state.brandSelected && this.state.brandSelected !== initialState.brandSelected)
				addArguments.vendor = this.state.brandSelected
	
				
			if(this.active.current){
				// console.info('this.active.current.checked', this.active.current.checked);
				addArguments.active = this.active.current.checked
			}
	
			if(this.state.packageSelected && this.state.packageSelected !== initialState.packageSelected)
				addArguments.package = this.state.packageSelected.value
	
			if(JSON.stringify(this.state.tagSelected) != JSON.stringify(initialState.tagSelected))
				addArguments.tag = this.state.tagSelected
	
			// We reset the page counted if we just started a search for a sku.
			if(addArguments.sku != this.previousSku){
				fromNbr = this.state.current_page - 1;
				nbrItem = this.state.limit_per_page;
				this.previousSku = addArguments.sku;
			}
			if(obj.idList){
				addArguments.idList = obj.idList
			}
			if(this.state.notInShop){
				addArguments.notInShop = true
			}
	
			// addArguments.skuList = "3M-7100165225,3M-7100197600,3M-7100165446"
			
			console.log("addArguments", addArguments);
			/* 
			return false; */
			
			this.props.loading(true)
			ajaxPerso( Object.assign({
				"api": "eio",
				"trigger": obj.trigger,
				"fromNbr": fromNbr * nbrItem,
				"nbrItem": nbrItem,
				"where": whereList,
				"active": true // We need to show if an item is active or inactive
			}, addArguments), callbackItem);
		}
	}

	getStore(obj){
		this.fetchStore(obj, (r) => {
			console.info('Fetched stores:', r);
			if(r.success)
				this.setStateSafe({
					"stores": r.data? r.data.map(o => {
						o.color = `rgb(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255})`
						return o
					}) : []
				})
		});
	}

	fetchStore(obj, callback) {
		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getStores"
		}), callback);
	}

	clearResult() {
		this.setStateSafe(JSON.parse(JSON.stringify(initialState)), () => {
			this.formFilter.current.reset()
			this.props.info({"success": "UI reinitialized with success."})
			this.getStore();
			this.updateItem();
		})
	}

	clearMsg() {
		this.setStateSafe({"error": false, "warning": false, "success": false})
	}

	selectListAction(e, typeName, objSelected) {
		e.preventDefault()
		let obj = {};
		
		obj[typeName] = objSelected;
		this.setStateSafe(obj)
	}

	updatePage(e, newPageNumber) {
		e.preventDefault()
		if(!newPageNumber)
			console.warn('Please indicate a page number positive.');
		this.setStateSafe({"current_page": newPageNumber}, () => {
			this.updateItem();
		})
	}

	selectItems(e, isFullSelect) {
		
		let itemId = parseInt(e.target.value),
			itemsSel = [],
			items = this.state.items
		
		if(typeof isFullSelect == "undefined"){
			itemsSel = this.state.itemsSelected
			if(itemsSel.indexOf(itemId) >= 0)
				itemsSel.splice(itemsSel.indexOf(itemId), 1)
			else
				itemsSel.push(itemId)
			this.setStateSafe({"itemsSelected": itemsSel})
		}else if(e.target.checked){
			this.props.modal({
				show: true,
				title: "Select Options",
				html: () => {
					return <div>
						Would you like to select all pages ? [{this.state.total_items} results]
					</div>
				}
				, ok: {
					title: "Validate",
					fnc: (popup, close) => {
						this.fetchItems({
							trigger: "getItemsCatalog_v2",
							start: 0,
							limit: this.state.total_items
						}, (r) => {
							this.props.loading(false)

							if(r.success){
								itemsSel = r.data.map((obj) => {
									return parseInt(obj.id)
								})
								this.setStateSafe({"itemsSelected": itemsSel})
							}
							close()
						}, {returnAll: true});
						//$('#mainModal').modal('hide')
					}
				}, return: {
					title: "Return",
					fnc: () => {
						itemsSel = items.map((obj) => {
							return parseInt(obj.id)
						})
						this.setStateSafe({"itemsSelected": itemsSel})
					}
				}
			});
		}else
			this.setStateSafe({"itemsSelected": itemsSel})
	}


	render() {
		// console.info('Store', this.state.selectedStoreCoverage);
		if(!this.props.securityClearance(this.props))	return "";

		return (
			<div className="main ctnPage">
				<Filter 
					clearResultCatalog={this.clearResult}
					{...this.props}
					vari={this.state}
					onSelectListAction={this.selectListAction}
					onClearResult={this.clearResult}
					onUpdateItem={this.updateItem}
					search={this.search}
					setStateSafe={this.setStateSafe}
					dataSheet={this.state.dataSheet}
					qtyPerPage={this.qtyPerPage}
					startAt={this.startAt}
					qtyOnHand={this.qtyOnHand}
					operatorQtyOnHand={this.operatorQtyOnHand}
					cost={this.cost}
					operatorCost={this.operatorCost}
					formFilter={this.formFilter}
					active={this.active}
					notInShop={this.notInShop}
					rightCtn={
						<Coverage 
							integrated={true}
							vari={this.state}
							{...this.props}
							stores={this.state.stores}
							toggleNotInShop={this.toggleNotInShop}
							selectedStore={this.state.selectedStoreCoverage}
							setSelectedStore={this.setSelectedStoreCoverage}
						/>
					}
				/>
				<MenuCatalog
					{...this.props}
					setStateSafe={this.setStateSafe}
					dataSheet={this.state.dataSheet}
					stores={this.state.stores}
					itemsSelected={this.state.itemsSelected}
				/>
				<div className={(this.state.avg? "" : "d-none") + " text-center text-white mx-auto p-2 h2 bg-primary"}>Average: {parseFloat(this.state.avg).toFixed(3)}</div>
				<div className="p-3 d-flex flex-row align-items-center">
					<span className="font-weight-bold">
						Shown page n°{this.state.current_page} 
						<u>
							<input type="number" min="10" max="250" className="form-control d-inline-block ml-3 mr-3 text-center" aria-describedby="Count Per Page" style={{width: "80px"}} value={this.state.limit_per_page} onChange={(e) => {
								this.setStateSafe({limit_per_page : e.target.value})
							}} onBlur={() => {
								if(this.state.limit_per_page != initialState.limit_per_page)
									this.updateItem()
							}}/>
							{this.state.items.length} of {this.state.total_items}
						</u>
					</span>
					<div className="d-flex flex-row align-items-center ml-5">
						{
							this.state.itemsSelected.length > 0? <button type="button" className="btn btn-dark mr-3" onClick={() => {
								this.setStateSafe({itemsSelected: initialState.itemsSelected.slice(0)})
							}}>Unselect all</button> : ''
						}
						<span className="font-weight-bold">Selected {this.state.itemsSelected.length}</span>
						<div className={"dropdown ml-3 " + (this.state.itemsSelected.length> 0? "" : "d-none")}>
							<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Action
							</button>
							<div className="dropdown-menu" style={{zIndex: 1070}} aria-labelledby="dropdownMenuButton">
								<Export
									className="dropdown-item position-relative overAndShow pointer"
									dataSheet={this.state.dataSheet}
									name={"Export"}
									{...this.props}
									fetchItems={this.fetchItems}
									total_items={this.state.total_items}
									itemsSelected={this.state.itemsSelected}
									stores={this.state.stores}
								/>
								<div className="dropdown-item position-relative overAndShow" href={null}>
									eBay
									<FontAwesomeIcon icon={faCaretRight} className="ml-3"/>
									<div className="position-absolute subDropDownRightSide d-none">
										<div className="position-fixed dropdown-item bg-white border p-0">
											{<ul className="list-group list-group-flush p-0 m-0">
												<EbayImgUpdater
													{...this.props}
													stores={this.state.stores}
													itemsSelected={this.state.itemsSelected}
													title="TMP IMG Corrector"
												/>
												<EbayQtyChange
													{...this.props}
													store={refConst.id_store_eBay_EIO}
													stores={this.state.stores}
													itemsSelected={this.state.itemsSelected}
													title="Bulk Quantity"
												/>
											</ul>}
										</div>
									</div>
								</div>
								<div className="dropdown-item position-relative overAndShow" href={null}>
									Shopify
									<FontAwesomeIcon icon={faCaretRight} className="ml-3"/>
									<div className="position-absolute subDropDownRightSide d-none">
										<div className="position-fixed dropdown-item bg-white border p-0">
											{<ul className="list-group list-group-flush p-0 m-0">
												<ShopifyInventoryPolicy
													{...this.props}
													stores={this.state.stores}
													loading={this.props.loading}
													itemsSelected={this.state.itemsSelected}
													title="Inventory Policy"
												/>
											</ul>}
										</div>
									</div>
								</div>
								<div className="dropdown-item position-relative overAndShow" href={null}>
									Create items
									<FontAwesomeIcon icon={faCaretRight} className="ml-3"/>
									<div className="position-absolute subDropDownRightSide d-none">
										<div className="position-fixed dropdown-item bg-white border p-0">
											{<ul className="list-group list-group-flush p-0 m-0">
												{
													<CreateItem
														{...this.props}
														loading={this.props.loading}
														stores={this.state.stores}
														storeFrom={refConst.id_store_eBay_EIO}
														storeTo={refConst.id_store_eio}
														itemsSelected={this.state.itemsSelected}
													/>
												}
												<CreateShopifyClone
													{...this.props}
													loading={this.props.loading}
													stores={this.state.stores}
													storeFrom={refConst.id_store_hausfTools}
													storeTo={refConst.id_store_eio}
													itemsSelected={this.state.itemsSelected}
												/>
												<CreateShopifyClone
													{...this.props}
													loading={this.props.loading}
													stores={this.state.stores}
													storeFrom={refConst.id_store_eio}
													storeTo={refConst.id_store_hausfTools}
													itemsSelected={this.state.itemsSelected}
												/>
											</ul>}
										</div>
									</div>
									
								</div>
								{<DeleteItem
									{...this.props}
									loading={this.props.loading}
									className="dropdown-item position-relative overAndShow pointer"
									info={this.props.info}
									modal={this.props.modal}
									stores={this.state.stores}
									itemsSelected={this.state.itemsSelected}
									callback={this.updateItem}
									title="Delete items"
								/>}
								{/*
									this.props.getLevel() === 'admin'? <div className="dropdown-item position-relative overAndShow" href={null}  onClick={(e) => this.endItemList(e)}>
										End Items
									</div> : false
								*/}
								<div className="dropdown-item position-relative overAndShow" href={null}>
									Change Tags
									<FontAwesomeIcon icon={faCaretRight} className="ml-3"/>
									<div className="position-absolute subDropDownRightSide d-none">
										<div className="position-fixed dropdown-item bg-white border p-0">
											{<ul className="list-group list-group-flush p-0 m-0">
												{
													<UpdateShopifyTags
														{...this.props}
														loading={this.props.loading}
														info={this.props.info}
														modal={this.props.modal}
														stores={this.state.stores}
														store={205618}
														itemsSelected={this.state.itemsSelected}
													/>
												}
												{
													<UpdateShopifyTags
														{...this.props}
														loading={this.props.loading}
														info={this.props.info}
														modal={this.props.modal}
														stores={this.state.stores}
														store={202487}
														itemsSelected={this.state.itemsSelected}
													/>
												}
											</ul>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FilterTags
					{...this.groupFilters()}
				/>
				<div className={this.state.items.length? "" : "d-none"}>
					<CatalogItems
						{...this.props}
						items={this.state.items}
						presence={this.state.presence}
						itemsSelected={this.state.itemsSelected}
						onSelectItems={this.selectItems}
						mainCheckbox={this.mainCheckbox}
						stores={this.state.stores}
					/>
					<Pagination 
						current_page={this.state.current_page}
						total_items={this.state.total_items}
						qtyPerPage={this.qtyPerPage}
						onUpdatePage={this.updatePage}
						limit_per_page={this.state.limit_per_page}
					/>
				</div>
			</div>
		);
	}
}

export default Catalog;
