// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { ajaxPerso, uniqueArray, handelize, fetchBrands, clipboard } from '../../../fnc.js'
import Select from 'react-select';
import subcatImgInfo from '../../../img/subCat_image_collection_hausoftools.png';

var initialState = {
	_isMounted: false,
	categoriesStoreTo: {},
	dataSet: [],
	result: null,
}

const refConst = require("../../../constants.js").v

const styleList = {
	container: (provided) => ({
		...provided,
		minWidth: "250px"
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

export default function AddBrandPageImage(props){

	const [result, setResult] = useState(initialState.result);
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [reloadReact, setReloadReact] = useState(0); // increment just to update the view sometimes
	const refInputImg = useRef(null);

	useEffect(() => {
		initialState._isMounted = true;
		console.info("TEST", props.shop);
		if(!props.shop)	return
		init()
		return () => initialState._isMounted = false
	}, [props.shop])

	useEffect(() => {
		$('[data-toggle="tooltip"]').tooltip({
			placement: "bottom"
		}).tooltip('update')
	})

	useEffect(() => {
		if(result && result.url)
			clipboard(result.url, props)
	}, [result])

	const init = () => {

	}

	const submitChangeReq = (formData, callback) => {
		
		ajaxPerso( formData, callback);
	}

	function getBase64(file) {
		return new Promise(function(resolve, reject) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result)
			};
			reader.onerror = function (error) {
				reject(error)
			};
		})
	}

	const submitImgChange = (e) => {
		e.preventDefault();

		// Format: subCatIcon_3m__hard-hats.png

		let errors = [];
		if(refInputImg.current.files.length != 1)
			errors.push("Please select one image only")

		console.info("refInputImgrefInputImg", refInputImg.current.files[0]);

		if(errors.length > 0){
			props.info({error: errors})
		}else{
			props.loading(true);

			var formData = new FormData();
			formData.append(`img`, refInputImg.current.files[0], refInputImg.current.files[0].name);
			formData.append("api", "shopify");
			formData.append("trigger", "manual_api_storage_img_replacer");
			formData.append("uid", props.getUser().uid);
			formData.append("shop", props.shop.id);
			formData.append("imgName", refInputImg.current.files[0].name);
			formData.append("retrieveUrl", true);

			ajaxPerso( formData, r => {

				console.info("Result from submitChangeReq:", r);
				props.loading(false);

				if(r.errors){
					props.info({error: JSON.stringify(r.errors)})
				}else if(r.insertResult && r.insertResult.length > 0){
					props.info({success: "Image added."})
					setResult(r.insertResult[0])
				}

			}, null, {
				contentType: false,
				processData: false,
				cache: false,
				async: true,
				// timeout: 60000,
			});
		}
	}

	console.info("propspropsprops", props);

	let hasStoreSelected = true,
		domain = null

	if(!props.shop || typeof props.shop != "object" || !("website" in props.shop))
		hasStoreSelected = false

	if(hasStoreSelected && props.shop){
		let urlWebsite = props.shop.website
	
		const result = /^(?:https?:\/\/)?([^/]+)\/?/i.exec(urlWebsite.trim());
	
		if(!result || result.length < 2)	throw("[3] Please verify the shop name in Shipstation.");
	
		domain = result[1];
	}

	return (<div className={"border radius m-3 p-3 w-100 bg-light " + ("className" in props? props.className : "")}>
		<form onSubmit={submitImgChange}>
			{
				domain? <div className="pb-3">
					Add a file to <b>{domain.substring(domain.lastIndexOf("//")+2)}</b> CDN
				</div> : ""
			}
			<div className="d-flex flex-row">
				<div className="custom-file ml-3">
					<input ref={refInputImg} type="file" className="custom-file-input" id="validatedCustomFile" required onChange={() => setReloadReact(reloadReact+1)}/>
					<label className="custom-file-label" htmlFor="validatedCustomFile">{ refInputImg.current && refInputImg.current.files.length === 1? refInputImg.current.files[0].name + " (" + (Math.round(refInputImg.current.files[0].size/1000)) + "Kb)" : "Choose file..." }</label>
				</div>
			</div>
			<div className="mt-3 text-center">
				<input type="submit" name="Change" className="btn btn-success" disabled={props.loading()}/>
			</div>
			{
				result && result.url? <div className="mt-3 smallText">
					URL: {result.url}
				</div> : ""
			}
		</form>
	</div>);
}