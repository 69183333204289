// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { ajaxPerso, fetchMenu, menuOrganized, fetchDataSet, clipboard, diff } from '../../../fnc'
import Menu from './Menu'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
// import logoStoreAmazon from '../../img/amazon_circle_perso.png';

var initialState = {
	_isMounted: false,
	categoriesStoreTo: [],
	dataSet: [],
}

const refConst = require("../../../constants.js").v


refConst.styleList.multiValueLabel = (styles, { data }) => ({
    ...styles,
    color: data.color,
})
refConst.styleList.multiValue = (styles, { data }) => {
	// console.info("datadata", data);
	return {
		...styles,
		backgroundColor: data.background,
		color: data.color,
	}
}
function ListView(props){

	const access = [
		"-MjVxK86GVBUKL5oNU66", // admin_access
	]
	const [categoriesStoreTo, setCategoriesStoreTo] = useState(initialState.categoriesStoreTo);
	const [dataSet, setDataSet] = useState(initialState.dataSet);
	const [updateView, setUpdateView] = useState(0);
	const [storeListForCreation, setStoreListForCreation] = useState([]);
	const [refreshView, setRefreshView] = useState(0);
	const [versionImgAdder] = useState(0.1);
	const refUrlImgAdder = useRef();


	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;

		props.setAutoCreateProduct(props.initialState.autoCreateProduct.slice(0));
		props.setMenu(props.initialState.menu.slice(0));

		init()
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		
		if(refConst.stores.length > 0)
			setStoreListForCreation(getFullStoreList())
		
		return () => initialState._isMounted = false
	}, [refConst.stores])

	const init = (e, o) => {
		fetchMenu( {
			"shop": refConst.id_store_eio
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched Categories for " + refConst.id_store_eio, r);
				let results = r.data
				let menu = menuOrganized(results)
	
				setCategoriesStoreTo(menu)
			}
		});

		fetchDataSet({
			"shop": refConst.id_store_eio,
			"level": 2,
			"justData": true
		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched dataset for " + refConst.id_store_eio, r);
				setDataSet(r.data)
			}
		})
	}

	const submitQtyPack = (e, o, pricesPerType, typeName) => {
		e.preventDefault()

		/* let qtyNode = document.getElementById(`${o.id}_button-form-input`)
		let unitSoldAsNode = document.getElementById(`${o.id}_select-form-input`)
		
		let qtySelected = parseInt(qtyNode.value) > 0? parseInt(qtyNode.value) : unitSoldAsNode.value */
		let qtySelected = typeName

		if(qtySelected) {
			// We make sure that this qty do not already exist.
			let found = o.variants.find(pqc => {
				if(pqc.qty == qtySelected) return pqc
			})
			// We need to find the next "Unit sold as" and use it as QTY
			/* let listPricingData = o.api_price_result.map(pd => {
				return pd.unit.name.toLowerCase()
			})
			console.info("listPricingData", listPricingData);
			// We need to get the last element that match a pricing data.
			let lastPosFound = null;
			o.variants.forEach(v => {
				let posFound = listPricingData.indexOf(v.qty)
				if(posFound !== -1){
					lastPosFound = posFound
				}
			})
			console.info("posfound:", lastPosFound);
			*/
			console.info(`submitQtyPack:`, found);
			if(!found)
				o.variants.push({
                    // "id_variant": null,
                    "qty": qtySelected,
                    "selling_price": {},
					"default_price": {}, // Just for the vview
                    "storeWantedOriginal": [],
					"active": true,
                    "createStatus": {
                        "created": null,
                        "msg": null,
                        "date_Created": null
                    },
                    "sku": `${o.sku}_${qtySelected}`,
                    "storeWanted": props.storeWantedForOurBrand
                })

			// Prefile the price in case there is a price option
			if(qtySelected){

				o.variants[o.variants.length-1].selected_saleable_unit = qtySelected

				updateVariantsDefaultPrices(
					pricesPerType
					, parseFloat(pricesPerType[qtySelected].currentPrice.listPrice)
					, parseFloat(pricesPerType[qtySelected].currentPrice.listPrice)
					, o.variants[o.variants.length-1]
					, false
				)
			}

			setRefreshView(refreshView+1)
		}
	}

	const getFullStoreList = (e, o) => {
		/**
			List all store accepted for creation
		 */
		let idWanted = [
			refConst.id_store_finale,
			refConst.id_store_hausfTools,
			refConst.id_store_eio,
			refConst.id_store_eBay_EIO,
		]
		return refConst.stores.filter(s => {
			if(idWanted.indexOf(s.id) !== -1)	return s
			return false
		})
	}

	const addImgUrlToProduct = (idAutoCreateProduct, urls, callback) => {
		// props.loading(true);

		if(!idAutoCreateProduct || !urls)
			return;

		/* console.info("Submit", JSON.stringify(data));
		return */

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "alter_auto_creator_product",
			"data": JSON.stringify([{
				"id": idAutoCreateProduct,
				"image_urls_added": JSON.stringify(urls)
			}])
		}, (r) => {
			console.info("Trigger addImgUrlToProduct", r);
			if(r.success && r.res.updated){
				props.info({success: "Product Img Updated"})
				refUrlImgAdder.current.value = ""
				if(callback)	callback(r)
			}else
				props.info({error: "Error while updating the product."})
		});
	}

	const updateVariantsDefaultPrices = (pricesPerType, currentDefaultPrice, newPrice, variant, isUpdate) => {
		

		if(newPrice && variant.storeWanted.length > 0){
			let changedSomething = false

			console.info("changedSomething--", `${currentDefaultPrice} - ${variant.selling_price} - ${newPrice}`);
			console.info("variant.storeWanted--", variant.storeWanted);
			
			variant.storeWanted.forEach(sWid => {

				if(
					sWid != refConst.id_store_finale && (
						// If no price was defined for this current store, we just add the default value
						(sWid in variant.selling_price && !variant.selling_price[sWid]) ||
						// If a value exist for this store, then we compare it to know if it needs to be changed
						(currentDefaultPrice == variant.selling_price[sWid] && currentDefaultPrice != newPrice || !variant.selling_price[sWid])
					)
				){
					changedSomething = true
					variant.selling_price[sWid] = newPrice
					console.info("new value ", newPrice);
				}
			})

			console.info("changedSomething", changedSomething);
			console.info("changedSomething2", variant);
			console.info("changedSomething3", newPrice);
			console.info("changedSomething4", pricesPerType);
			// debugger
			if(changedSomething || isUpdate)
				setRefreshView(refreshView+1)
		}
	}

	const filterValidData = () => {

		console.info("filterValidData", props.autoCreateProduct);

		let cp = JSON.parse(JSON.stringify(props.autoCreateProduct))
		let dataToSend = cp.filter(o => {

			delete o.product_feed_data;
			delete o.api_price_result;
			delete o.api_search_result;
			delete o.bcom_result;

			// we need to make sure to send only those with reviewed = 1
			if(o.reviewed)
				return o
			return false
		})

		return dataToSend
	}

	const paginationGrouped = () => {

		let groupHtmlOut = [],
			maxCtn = Math.ceil(props.itemsCountSelectedCat/props.pageSize)

		for (let i = 0; i < maxCtn; i++) {
			
			groupHtmlOut.push(<button key={`${i+1}_pagination`} className={(props.pageOpen == i? "active" : "") + " btn btn-outline-info mr-2"} onClick={() => {
				props.setPageOpen(i)
			}} title={`${i*props.pageSize} to ${i < maxCtn-1? (i+1)*props.pageSize : props.itemsCountSelectedCat}`}>
				{ i+1 }
			</button>)
		}
		return groupHtmlOut
	}

	const filterPerPagination = (productList) => {

		return productList.slice(props.pageOpen*props.pageSize, (props.pageOpen+1)*props.pageSize)
	}

	const viewListMeta = () => {
 
		let storeListIdToName = {}
		props.storeInfo.forEach(s => {
			storeListIdToName[s.storeId] = s.name;
		})

		let output = <div className="pl-4 pr-4 pb-4">
			{
				props.itemsCountSelectedCat > 0?
					<div className="p-3 bg-dark rounded" style={{
						overflowY: "auto",
					}}>
						{
							paginationGrouped()
						}
					</div> : ""
			}
			<div className="list-group overflow-auto">
				<table className="table" style={{marginBottom: "100px"}}>
					<thead>
						<tr>
							{/* <th className="position-sticky">id</th> */}
							{/* <th className="position-sticky text-center">
								<div className="d-flex align-items-center">
									{
										props.storeInfo.map(oP => {
											return <div key={`${oP.prop}_storeProp`} className={'text-center d-flex align-items-center justify-content-center'}>
												<img src={oP.img} className={''} style={{width: "25px", height: "25px"}}/>
											</div>
										})
									}
								</div>
							</th> */}
							{/* <th className="position-sticky">vendor</th> */}
							<th className="position-sticky">
								{
									props.itemsCountSelectedCat === 0? <div className="alert alert-danger" role="alert">
										This category has not product awaiting to create.
									</div> : 
									<span>
										info {filterPerPagination(props.autoCreateProduct).length} product
										<button type="button" className="btn btn-warning ml-3" onClick={() => {
											props.autoCreateProduct.forEach(o => {
												o.reviewed = false
											})
											setRefreshView(refreshView+1)
										}}>Unselect all</button>
										<button type="button" className="btn btn-success ml-3" onClick={() => {
											props.autoCreateProduct.forEach(o => {
												o.reviewed = true
											})
											setRefreshView(refreshView+1)
										}}>Select all</button>
									</span>
								}
							</th>
							{/* <th className="position-sticky text-center">Category</th> */}
							{/* <th className="position-sticky text-center">Add Pack Qty</th> */}
							{/* <th>mapping table</th> */}
						</tr>
					</thead>
					<tbody>
						{
							filterPerPagination(props.autoCreateProduct).map((o, posAcp)=> {

								console.info("autoCreateProduct", o);

								let autoC = props.autoCreate.find(a => {
									if(a.id == o.id_auto_creator) return a
								})

								if(!autoC)	return false;
								if(o.variants.length === 0 ||  o.variants[0].presenceCheckedResult === null)	return false;

								console.info('o', o);
								console.info('autoC', autoC);

								// Example: 7100130665: Visit 3M bCom to configure for price
								if(!("pricingData" in o.api_price_result) || o.api_price_result.pricingData.length === 0){
									o.api_price_result.pricingData = [];
									console.warn("Item with price condition:", o.api_price_result);
									if(o.api_price_result.pricing[0].code == "PRDT-0002"){
										o.discontinued = true
										o.active = false
									}
								}

								let collectionValue = null,
									datasetValue = null

								if(o.collection_shopify_eio)
									categoriesStoreTo.find(cs => {
										if(cs.last.name == o.collection_shopify_eio){
											collectionValue = {
												value: cs.last.name,
												label: cs.inline
											}
										}
									})

								if(o.dataset_shopify_eio)
									dataSet.find(dt => {
										if(parseInt(dt.id) == o.dataset_shopify_eio){
											datasetValue = {
												value: parseInt(dt.id),
												label: dt.title
											}
										}
									})


								let pricesPerType = {} // Just for faster access to the prices.
								
								o.api_price_result.pricingData.forEach(pd => {
									pricesPerType[pd.unit.name.toLowerCase()] = pd
									/* return {
										value: pd.unit.name.toLowerCase(),
										label: `[${pd.unit.name}] net: $${parseFloat(pd.currentPrice.netPrice).toFixed(2)}, list: $${parseFloat(pd.currentPrice.listPrice).toFixed(2)}`
									} */
								})

								if(o.variants.length > 0 && !("selected_saleable_unit" in o.variants[0])){
									o.variants[0].selected_saleable_unit = o.smallest_saleable_unit? o.smallest_saleable_unit : o.default_pricing_type
								}

								/**
								 * Filtered list of price option for the "Add variant list"
								 */
								let listQtyAlreadyIn = o.variants.map(v => {
									return v.qty
								})

								let listPriceOpAvailable = diff(Object.keys(pricesPerType), listQtyAlreadyIn)
																
								o.api_price_result.pricingData.some((po) => {
									let posFound = listPriceOpAvailable.indexOf(po.unit.name.toLowerCase())
									if(posFound !== -1){
										listPriceOpAvailable[posFound] = po
									}
								})
								/****************************************************** */

								console.info("listPriceOpAvailable", listPriceOpAvailable);
								console.info("listQtyAlreadyIn", listQtyAlreadyIn);
								console.info("pricesPerType", pricesPerType);
								console.info("o.variant.storeWanted", o.variants.storeWanted);
								console.info("o.variantso.variants", o.variants);
								// return
								if(o.variants.length > 0){
									
									console.info("o.variants[0].smallest_saleable_unit", o.variants[0].selected_saleable_unit);

									// We need to add the default price only if at least one value is missing from `selling_price`
									let listWithoutPrice = o.variants.filter(v => {
										if(Object.values(v.default_price).filter(val => {
											if(!val)	return val
										}).length > 0 || Object.values(v.default_price).length === 0)	return v
									})

									console.info("listWithoutPrice", listWithoutPrice);
									
									// Prefil the first 
									if(!o.discontinued && listWithoutPrice.length > 0 && (o.variants.length > 0 && o.variants[0].selling_price === null || JSON.stringify(o.variants[0].selling_price) == "{}"))
										updateVariantsDefaultPrices(
											pricesPerType
											, parseFloat(pricesPerType[o.variants[0].selected_saleable_unit].currentPrice.listPrice)
											, parseFloat(pricesPerType[o.variants[0].selected_saleable_unit].currentPrice.listPrice)
											, o.variants[0]
											, false
										)
								}

								/** --- Images from 3 sources
								 * For now we need to focus on the xlsx file because these picture are bigger.
								*/
								let feedImgPropName = "additional_pictures"
								let imageLink = null,
									imgCount = 0;
								if(o.product_feed_data[feedImgPropName]){
									imageLink = o.product_feed_data.files[feedImgPropName][0]
									imgCount = o.product_feed_data.files[feedImgPropName].length
								} else if(o.api_search_result && o.api_search_result.productSingleImageLinks && o.api_search_result.productSingleImageLinks.length > 0){
									imageLink = o.api_search_result.productSingleImageLinks[0].link
									imgCount = o.api_search_result.productSingleImageLinks.length
								}else if(o.image_urls_added && o.image_urls_added.length > 0){
									imageLink = o.image_urls_added[0]
									imgCount = o.image_urls_added.length
								}

								console.info("imageLinkimageLinkimageLink", o.product_feed_data[feedImgPropName]);
								console.info("imageLinkimageLinkimageLink2222", imageLink);

								return <tr key={`${o.id}_ctn_list_autoCreate`} style={{borderBottom: "2px solid orange"}}>
									{/* <td>{o.id}</td> */}
									{/* <td>
										<div className="cubeStoreCreation">
											<div className="d-flex align-items-center">
												{
													props.storeInfo.map(oP => {
														return <div key={`${oP.prop}_storeProp`} className={'text-center d-flex align-items-center justify-content-center'}>
															<FontAwesomeIcon icon={faCheckCircle} className={o[oP.prop]? '' : ''} style={{width: "25px", height: "25px"}} />
														</div>
													})
												}
											</div>
										</div>
									</td> */}
									{/* <td>
										{autoC.vendor}
										<div className="d-flex align-items-center justify-content-center">
											<input className={(o.s? `d-none` : ``) + ''} type="checkbox" defaultChecked={o.do_not_create? false : true} style={{width: "25px", height: "25px"}} onChange={(e) => {
												console.info('e', e.target.checked);
												o.do_not_create = !e.target.checked // do_not_create is a property from our SQL table
												props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
											}}/>
										</div>
									</td> */}
									<td>
										<div className="d-flex flex-row mt-2">
											<div className={
												"img-thumbnail rounded mr-3 d-flex justify-content-center " + 
												(!imageLink? "align-items-center" : "align-items-end")
											} style={{
												backgroundImage: "URL('"+imageLink+"')",
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'contain',
												height: "100px",
												width: "100px",
											}}>
												{!imageLink? <button className="btn btn-primary" onClick={() => {
													modalAddImg(false, o)
												}}>Add</button> : <span className="smallText bg-light" style={{opacity: 0.8}}>Image count: {imgCount}</span>}
												
											</div>
											<div className="rounded p-1 middeSizeText w-50">
												<div className={( o.reviewed? "bg-success" : "bg-warning") + " p-1 mb-2 border rounded text-center d-flex align-items-center justify-content-center"}>
													{props.reviewMode? <span className="font-weight-bold pr-3 align-middle">Reviewed Item</span> : ""}
													<span className="">
														<input className={'m position-relative pointer'} type="checkbox" checked={o? o.reviewed : false} style={{width: "28px", height: "28px", top: "3px"}} onChange={(e) => {
															console.info('e', e.target.checked);
															o.reviewed = e.target.checked? true : false
															setRefreshView(refreshView+1)
														}}/>
													</span>
												</div>
												{/* <div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Publish this item</span>
													<span className="d-table-cell">
														<input className={'m'} type="checkbox" checked={o? o.active : false} style={{width: "17px", height: "17px"}} onChange={(e) => {
															console.info('e', e.target.checked);
															o.active = e.target.checked? true : false
															setRefreshView(refreshView+1)
														}}/>
													</span>
												</div> */}
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">EIO Internal ID:</span>
													<span className="d-table-cell">
														{o.id}
													</span>
												</div>
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Manufacturer:</span>
													<span className="d-table-cell">
														{autoC.vendor}
														<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(`${autoC.vendor}`, props)}}/></button>
													</span>
												</div>
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Sku:</span>
													<span className="d-table-cell">
														{o.sku}
														<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(`${o.raw_sku}`, props)}}/></button>
													</span>
												</div>
												<div className="text-wrap">
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Title:</span>
													<span className="d-table-cell">
														{autoC.main_prop_mapping.title? o.product_feed_data[autoC.main_prop_mapping.title] : (o.api_search_result? o.api_search_result.name : "")}
														<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(autoC.main_prop_mapping.title? o.product_feed_data[autoC.main_prop_mapping.title] : (o.api_search_result? o.api_search_result.name : ""), props)}}/></button>
													</span>
												</div>
											</div>
										</div>
										<div className="smallText mb-3">
											{/* <div className="d-flex align-items-center p-2">
												<input id={`${o.id}_label_sku`} className={''} type="checkbox" defaultChecked={true} style={{width: "25px", height: "25px"}} onChange={(e) => {
													console.info('e', e.target.checked);
													// o.packQtyToCreate[s.storeId] = true
													props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
												}}/>
												<label htmlFor={`${o.id}_label_sku`} className="m-0 ml-2 noselect">{autoC.prefix + o.product_feed_data[autoC.sku_prop_name]}</label>
											</div> */}
											<div>
												{(() => {
													console.info('o.variants', o.variants);
													
													let varNodeList = o.variants.map((v, keyVar) => {

														let listStoreInfoAlreadyIn = props.storeInfo.filter(sI => {
															if(v.presenceCheckedResult.indexOf(sI.storeId) !== -1){
																return sI;
															}
														})

														let listSelected = [];
														v.storeWanted.forEach(idStoreWanted => {
															if(idStoreWanted)
																listSelected.push({
																	value: idStoreWanted,
																	label: storeListIdToName[idStoreWanted],
																	// background: Math.round(Math.random())? "#13DA25" : "#FF5733",
																	background: "#e18956",
																	color: "#FFFFFF"
																})
														})

														// If no store were selected yet, we need to fill up the variant with all the stores possible to create.
														/* if(listSelected.length === 0){
															props.storeInfo.forEach(validStore => {
																listSelected.push({
																	"value": validStore.storeId,
																	"label": validStore.name
																})
															})
														} */

														console.info("listSelectedlistSelected", listSelected);
														
														let foundPO = o.api_price_result.pricingData.filter((pO) => {
															console.info("SSAAA", pO);
															if(pO.unit.name.toLowerCase() == v.selected_saleable_unit)	return pO
														})
														
														console.info("foundPOfoundPO", foundPO);
														console.info("props.storeInfo", props.storeInfo);
														console.info("v.storeWanted", v.storeWanted);

														let finalVariantSku = o.sku
														if(o.variants.length > 0 && o.smallest_saleable_unit != v.qty)
															finalVariantSku = <span>{finalVariantSku}<span className="font-weight-bold">_{v.qty}</span></span>

														return <div key={`${v.qty}_${o.id}_qty_pack`} className="mt-3 border p-3 shadow-sm">
															
															<div className="d-flex align-items-center ">
																{<Select
																	isClearable
																	styles={refConst.styleList}
																	onChange={(selectedOption) => {

																		console.info('selectedOption', selectedOption);

																		if(!selectedOption)	selectedOption = []

																		// Make sure that Finale is always listed.
																		/* let finaleFound = selectedOption.find(sO => {
																			if(sO.value == refConst.id_store_finale)	return sO
																		})

																		if(!finaleFound){
																			selectedOption.push({
																				value: refConst.id_store_finale,
																				label: `Finale`
																			})
																		} */

																		if(selectedOption.length === 0)
																			v.storeWanted = [refConst.id_store_finale]
																		else{
																			v.storeWanted = selectedOption.map(sO => {
																				return sO.value
																			})
																		}

																		v.storeWanted.sort()

																		setRefreshView(refreshView+1)
																	}}
																	options={(() => {
																		return props.storeInfo.filter(sF => {
																			// return sF;
																			// if(v.isMainSku || sF.storeId != 5)	return sF
																			if(v.storeWantedOriginal && v.storeWantedOriginal.indexOf(sF.storeId) != -1)	return sF
																		}).map(sObj => {
																			return {
																				value: sObj.storeId,
																				label: `${sObj.name}`
																			}
																		})
																	})()}
																	value={listSelected}
																	placeholder={"Store To Create"}
																	isSearchable={true}
																	isMulti={true}
																/>}
																<div className="pl-3 pr-1">
																	{
																		listStoreInfoAlreadyIn.map((sIAlreadyIn) => {
																			return <img
																				key={`${sIAlreadyIn.storeId}_listIcon`}
																				src={sIAlreadyIn.img}
																				width="40px"
																				height="40px"
																				className="ml-1 mr-1"
																				title={`exist in ${sIAlreadyIn.name}`}
																				data-toggle="tooltip"
																				data-html="true"
																			/>
																		})
																	}
																</div>
															</div>
															<div className="d-flex align-items-center mt-3 flex-wrap">
																{
																	props.storeInfo.sort((obj1, obj2) => {
																		if(obj1.pos > obj2.pos)
																			return 1
																		return -1
																	}).filter(sF => {
																		// if(v.isMainSku || sF.storeId != 5)	return sF
																		if(v.storeWanted.indexOf(sF.storeId) != -1)	return sF
																	}).map((sObj, posStore) => {

																		if([refConst.id_store_finale].indexOf(sObj.storeId) === -1)
																			return <div key={`${sObj.storeId}_${o.id}_manual_price`} className="input-group mr-2 ml-2 mb-3" style={{width: "400px"}}>
																				<div className="input-group-prepend">
																					<span className="input-group-text priceField" id="basic-addon2">$ {sObj.name}</span>
																				</div>
																				<input type="number" className="form-control" value={sObj.storeId in v.selling_price? v.selling_price[sObj.storeId] : ""} placeholder="Sell Price" onChange={e => {
																					v.selling_price[sObj.storeId] = parseFloat(e.target.value)
																					setRefreshView(refreshView+1)
																				}}/>
																			</div>
																	})
																}
															</div>
															<div className="d-flex align-items-center mt-3">
																<input id={`${keyVar}_label_sku_generated`} className={'ml-3'} type="checkbox" checked={v? v.active : false} style={{width: "25px", height: "25px"}} onChange={(e) => {
																	console.info('e', e.target.checked);
																	console.info('packQtyToCreate', v);
																	v.active = e.target.checked? true : false
																	setRefreshView(refreshView+1)
																}}/>
																<label htmlFor={`${keyVar}_label_sku_generated`} className="m-0 ml-2 noselect" style={{fontSize: "16px"}}>
																	{finalVariantSku}
																</label>
																<button type="button" className="ml-2 btn btn-sm btn-dark pointer text-light" data-toggle="tooltip" title="Copy the value in your clipboard">
																	<FontAwesomeIcon className="" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(`${o.sku}${v.qty? '_' : ''}${v.qty}`, props)}}/>
																</button>
																<button type="button" className="ml-2 btn btn-danger btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Remove this variant" onClick={() => {
																	o.variants.splice(keyVar, 1)
																	setRefreshView(refreshView+1)
																}}>
																	<FontAwesomeIcon className="" icon={faTrashAlt} />
																</button>
															</div>
															{
																foundPO.length > 0? <div className="p-2 ml-3 mt-2 border rounded border-info border-info">
																	<div><span className="font-weight-bold mr-3">current net price:</span><span>${foundPO[0].currentPrice.netPrice}</span></div>
																	<div><span className="font-weight-bold mr-3">current list price:</span><span>${foundPO[0].currentPrice.listPrice}</span></div>
																	<div><span className="font-weight-bold mr-3">minOrderInc:</span><span>{foundPO[0].minOrderInc}</span></div>
																	<div><span className="font-weight-bold mr-3">minOrderQty:</span><span>{foundPO[0].minOrderQty}</span></div>
																</div> : ""
															}
														</div>
													})

													if(listPriceOpAvailable.length > 0)
														varNodeList.push(
															<div key={`${o.id}_section_new_pack`} className="mt-4 border p-3 position-relative">
																
																<div className="position-absolute d-flex align-items-center" style={{top: "-14px", backgroundColor: "white"}}>
																	<FontAwesomeIcon icon={faPlusSquare} style={{backgroundColor: "white", color: "rgb(212, 212, 212)", fontSize: "25px"}}/>
																	<span className="ml-2">New variant</span>
																</div>

																<div>*Smallest Saleable Unit: <span className="font-weight-bold">{o.smallest_saleable_unit? o.smallest_saleable_unit : "Not defined"}</span></div>
																
																<form onSubmit={(e) => {
																	e.preventDefault()
																	console.info("SSSSSEEEE", e);
																	submitQtyPack(e, o, pricesPerType)
																}} className="d-flex justify-content-center p-3">
																	<div className="">
																		{/* onChange={(e) => { 

																			let value = e.currentTarget.value
																			console.info('selectedOption_value', value);
																			
																		}}> */}
																		{
																			listPriceOpAvailable.map(po => {
																				let isSmallestUnit = o.smallest_saleable_unit == po.unit.name.toLowerCase()
																				return <button
																					key={`${po.unit.name}_${o.id}`}
																					className="btn btn-outline-secondary mr-3 p-3 pl-5 pr-5" value={po.unit.name.toLowerCase()} onClick={(e) => {
																						submitQtyPack(e, o, pricesPerType, po.unit.name.toLowerCase())
																				}} style={{
																					border: (isSmallestUnit? "4" : "1") + "px solid" 
																				}}>
																					<div style={{fontSize: "20px", fontWeight: "bold"}}>{isSmallestUnit? "*" : ""}{po.unit.name}</div>
																					<div>MOQ {po.minOrderQty} of {po.conversion} piece{(po.minOrderQty*po.conversion > 1? 's' : '')}</div>
																					<div>Cost ${parseFloat(po.currentPrice.netPrice).toFixed(2)}</div>

																				</button>
																			})
																		}
																	</div>
																</form>
															</div>)
														
													return varNodeList
												})()}
											</div>
										</div>
									</td>
									{/* <td className="text-center">
										<Select
											isClearable
											styles={refConst.styleList}
											className="ml-3 text-left"
											onChange={(selectedOption) => {

												o.collection_shopify_eio = selectedOption? selectedOption.value : null
												setUpdateView(updateView+1)

												console.info("TESTTT:", o.product_feed_data);
												console.info("TESTTT1:", autoC.category_prop_name);
												console.info("TESTTT2:", selectedOption.value);
												console.info("TESTTT3:", o.product_feed_data[autoC.category_prop_name]);

												if(o.product_feed_data && autoC.category_prop_name in o.product_feed_data)
													props.submitAutoCreatorMemory(autoC.id, o.product_feed_data[autoC.category_prop_name], selectedOption.value, "category", (r) => {
														console.info("Response submitAutoCreatorMemory:", r);
														if(r.error){
															props.info({error: r.error})
														}else{
															console.info("submitAutoCreatorMemory `category` success.");
														}
													})
											}}
											options={(() => {
												let valueReturn = []
												categoriesStoreTo.forEach(m => {
													valueReturn.push( {
														value: m.last.name,
														label: m.inline
													})
												})
												return valueReturn
											})()}
											value={collectionValue}
											isSearchable={true}
											isMulti={false}
											placeholder={"Master Collection EIO"}
										/>

										<Select
											isClearable
											styles={refConst.styleList}
											className="ml-3 mt-3 text-left"
											onChange={(selectedOption) => {
												o.dataset_shopify_eio = selectedOption? selectedOption.value : null
												setUpdateView(updateView+1)

												if(o.product_feed_data && autoC.category_prop_name in o.product_feed_data)
													props.submitAutoCreatorMemory(autoC.id, o.product_feed_data[autoC.category_prop_name], selectedOption.value, "dataset", (r) => {
														console.info("Response submitAutoCreatorMemory:", r);
														if(r.error){
															props.info({error: r.error})
														}else{
															console.info("submitAutoCreatorMemory `dataset` success.");
														}
													})
											}}
											options={(() => {
												return dataSet.map(dt => {
													return {
														value: parseInt(dt.id),
														label: dt.title
													}
												})
											})()}
											value={datasetValue}
											isSearchable={true}
											isMulti={false}
											placeholder={"Level 2 Dataset EIO"}
										/>
									</td> */}
									{/* <td>
										<div>
											<form onSubmit={(e) => {
												submitQtyPack(e, o)
											}}>
												<div className="input-group" style={{width: "150px"}}>
													<input type="number" id={`${o.id}_button-form-input`} className="form-control" placeholder="Qty" aria-label="Recipient's username" aria-describedby={`${o.id}_button-form-pack`}/>
													<div className="input-group-append">
														<button type="submit" className="btn btn-outline-secondary" id={`${o.id}_button-form-pack`}>Add</button>
													</div>
												</div>
											</form>
										</div>
									</td> */}
									{/* <td><button type="button" className="btn btn-outline-primary">Table</button></td> */}
								</tr>
							})
						}
					</tbody>
				</table>
				<div className="fixed-bottom text-center mb-1" style={{
					height: "1px"
				}}>
					<button type="button" className="bg-primary d-flex align-items-center justify-content-center text-center btn btn-lg btn-block d-inline-block text-white mx-auto position-relative" disabled={props.loading()} style={{width: "50%", opacity: '0.8', bottom: "50px"}} onClick={() => {
						
						let dataToSend = filterValidData()

						/* console.info('Submit_all_data', dataToSend);
						return */
							
						submitAutoCreateComplete(dataToSend, (r) => {
							//props.loading(false);

							props.reloadData()

							props.msgAlert(
								r.errors && r.errors.length > 0? <div>{r.errors}</div> : null, 
								r.success.length > 0? <div>{dataToSend.length} products validated</div> : null, 
							);

							console.info('Res submitAutoCreateComplete', r);
							
						})
					}}>
						<span>Finalize - [{filterValidData().length} Items]</span>
						<img className="ml-4" style={{height: "35px"}}></img>
					</button>
				</div>
			</div>
		</div>

		return <div className="mt-3" style={{
			overflowY: "auto",
		}}>{output}</div>;
	}

	const submitAutoCreateComplete = (data, callback) => {
		// props.loading(true);

		console.info("Submit_all_data", data);
		// return

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "autoCreate_complete_data",
			"uid": props.getUser().uid,
			"data": JSON.stringify(data)
		}, callback);
	}

	let modalAddImg = (refresh, autoCreateProductObj) => {

		console.info("autoCreateProductObj", autoCreateProductObj);

		props.modal({
			show: refresh? false : true,
			title: <span>Add a picture <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{versionImgAdder}</h6></span>,
			options: {
				// width: "modal-xl"
			},
			html: (popup) => {

				return <div>

					<div>
						<p className="h5">Add an image by URL</p>
						<div className="alert alert-primary" role="alert">
							Idea: you can upload your picture in our Shopify File system and copy/paste the URL in here
						</div>
						<div className="alert alert-primary" role="alert">
							You can add multiple URL separated with a comma.
						</div>
						<form onSubmit={(e) => {
							e.preventDefault()
							console.info("refUrlImgAdder", refUrlImgAdder.current.value);
							let urls = refUrlImgAdder.current.value.split(",").map(url => {
								return url.trim()
							});
							addImgUrlToProduct(
								autoCreateProductObj.id,
								urls,
								(r) => {
									autoCreateProductObj.image_urls_added = urls
									setRefreshView(refreshView+1)
								}
							)
						}}>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text" id="basic-addon1">Image</span>
								</div>
								<input type="text" className="form-control" placeholder="URL1, URL2..." ref={refUrlImgAdder}/>
								<div className="input-group-append">
									<button className="btn btn-outline-secondary" type="submit">Submit URL</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			/* , ok: {
				title: "Create Clones",
				fnc: (popup, close) => {
					props.loading(true)

					// popup.modal('hide')
				}
			} */ /* : false */, return: {
				title: "Exit",
				fnc: (popup, close) => {
					close()
				}
			}
		});
	}

	if(!props.securityClearance(props, access))	return "";
		
	return (<div className={("className" in props? props.className : "") + " d-flex flex-row"}>
		{/* <div className="alert alert-primary w-100 text-wrap mt-4" role="alert">
			Create or Update an A+ content, A+ represents a section on the website that highlights a product per its type and brand.
		</div> */}
		<Menu {...props}/>
		{ viewListMeta() }
	</div>);
}

export default ListView;