// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import {ajaxPerso} from "../fnc.js"
const refConst = require("../constants.js").v

const listInfrasctructures = [
	{
		type: "Server PowerPanel Business",
		access: "http://10.10.0.2:3052/local/login",
		info: <div>
			<ul>
				<li>admin</li>
				{/* <li>eioadmin1!</li> */}
			</ul>
		</div>
	},
	{
		type: "Server PowerPanel Business",
		access: "http://10.10.0.161:3052/local/login",
		info: <div>
			<ul>
				<li>admin</li>
				{/* <li>eioadmin1!</li> */}
			</ul>
		</div>
	},
	{
		type: "NAS used for backup",
		access: "http://truenas.local/",
		info: <div>
			<ul>
				<li>root</li>
				{/* <li>eioadmin1!</li> */}
			</ul>
		</div>
	},
	{
		type: "VCenter",
		access: "https://vsphere.local/",
		info: <div>
			<ul>
				<li>administrator</li>
				{/* <li>pass8Vcenter@</li> */}
			</ul>
		</div>
	},
	{
		type: "ESXI VSPHERE",
		access: "https://10.10.0.150/",
		info: <div>
			<ul>
				<li>root</li>
				{/* <li>pass8Vcenter@</li> */}
			</ul>
		</div>
	},
	{
		type: "Ajenti",
		access: "http://10.10.0.152:8000",
		info: <div>
			<ul>
				<li>root</li>
				{/* <li>pass8Vcenter@</li> */}
			</ul>
		</div>
	},
	{
		type: "eBay Token Generator",
		access: "https://api.ehub.work/token.php",
		info: <div>
			
		</div>
	}
]

export default function Infrastructure(props){

	const [cronTask, setCronTask] = useState([]);
	const [submited, setSubmited] = useState(false);

	useEffect(() => {
		// loadCrontask()
	}, [])

	if(!props.securityClearance(props))	return "";

	return (
		<div className="main ctnPage">
			{
				listInfrasctructures && listInfrasctructures.length > 0? <div className="table-responsive-md">
					<table className="table">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Type</th>
								<th scope="col">Access</th>
								<th scope="col">Info</th>
							</tr>
						</thead>
						<tbody>
							{
								listInfrasctructures.map((o,i)=> {
									return <tr key={`${o.call}_${i}`} className="">
										<td className="mr-3">{i+1}</td>
										<td>{o.type}</td>
										<td><a href={o.access} target="_blank" rel="noreferrer">{o.access}</a></td>
										<td>{o.info}</td>
									</tr>
								})
							}
						</tbody>
					</table></div>
					: ''
			}
		</div>

	);
}