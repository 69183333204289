// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import Loading from './Loading';
import Select from 'react-select';
import { capitalizeFLetter, fetchItemGlobalInfo } from '../../fnc'
import { utils, writeFile } from 'xlsx';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import moment from 'moment';

const refConst = require("../../constants.js").v

var initialState = {
	selected: null,
	reqDone: null, // [not execute => null, executed but not done: false, req executed and done: true]
	items: [],
}

var specialVar = {
	_isMounted: false
}

let stickyHeader = (item) => {
	// console.info('itemitemitemitem', item);
	
	return <span className="mr-2">{item.column.name}</span>;
}

const defaultColumnProperties = {
	resizable: true,
	headerRenderer: stickyHeader
}

export default function Export(props){

	const [selected, setSelected] = useState(initialState.selected);
	const [version, setVersion] = useState("0.2.0");
	const [items, setItems] = useState(Object.assign(initialState.items, {}));
	const [reqDone, setReqDone] = useState(initialState.reqDone);
	const [refreshView, setRefreshView] = useState(0);

	useEffect(() => {
		specialVar._isMounted = true
		return () => specialVar._isMounted = false
	}, [])

	useEffect(() => {
		modal(true);
	}, [items, selected, refreshView])

	useEffect(() => {

		if(!items || items.length === 0 || items.length > 0 && ("ebay_price" in items[0]))	return;

		console.info("Start Item Search");
		fetchItemGlobalInfo({
			"id_product_list": props.itemsSelected.join(',')
		}, (r) => {
			console.info('fetchItemGlobalInfo', r);
			let listPricesProp = [
				"ebay_price",
				"amazon_price",
				"haus_price",
				"eio_price",
				"walmart_price",
				"amazon_us_eio_price",
				"amazon_us_gott_price"
			]
			if(r && r.length > 0){
				let listIdPrdToPrices = {}
				r.forEach((o) => {
					listIdPrdToPrices[o.id] = {}
					listPricesProp.forEach((p) => {
						if(p in o){
							listIdPrdToPrices[o.id][p] = o[p] > 0? parseFloat(o[p]) : 0
						}
					})
				})
				console.info("listIdPrdToPrices", listIdPrdToPrices);

				// Add the prices to the items
				// let itemsTmp = items.original.slice(0)
				items.forEach((o) => {
					if(o.id in listIdPrdToPrices){
						o = Object.assign(o, listIdPrdToPrices[o.id])
					}else o = Object.assign(o, listIdPrdToPrices[o.id])
				})

				setItems(items.slice(0))
			}
		})
		
	}, [items])

	let init = () => {
		
		if(props.itemsSelected.length === 0){
			props.info({error: "Please select some items to export."})
			return;
		}

		setReqDone(false)
		resetPage()

		props.fetchItems({
			trigger: "getItemsCatalog_v2",
			start: 0,
			limit: props.total_items,
			idList: props.itemsSelected.join(',')
		}, (r) => {
			console.info('rrrrrr', r);

			console.info('fetchEioCatalogItems', r);
			props.loading(false)
			setReqDone(true)

			if(r.success){
				let storePerId = {};
				props.stores.forEach(s => {
					if(s.active)
						storePerId[s.id] = s.name;
				});

				// We add a column per store name with the value 1 if existing so that it can be easy to sort in excel.
				r.data.map(o => {
					// o.list_id_store = Array.isArray(o.list_id_store)? o.list_id_store.join(", ") : ''

					/* if("list_id_store" in o)
						for (const store_id in storePerId) {
							if (Object.hasOwnProperty.call(storePerId, store_id)) {
								const store_name = storePerId[store_id];
								
								let posId = o.list_id_store.indexOf(parseInt(store_id))
								o[store_name] = posId !== -1? 1 : 0
								if(posId > -1){
									o[`${store_name} - price`] = o.list_price_store[posId]
								}
							}
						} */
				})
			}

			setItems(r.success? r.data : initialState.items.slice(0))
		});

		/* fetchEioCatalogItems({
			idList: props.itemsSelected.join(',')
		}, (r) => {
			console.info('fetchEioCatalogItems', r);
			setReqDone(true)
			setItems(r.success? {
				original: r.data,
				grid: r.data.map(o => {
					return Object.values(o)
				})
			} : Object.assign(initialState.items, {}))
		}) */

		modal(false);
	}

	/* const ExpensiveFormatter = ({ isScrolling }) => {
		if (isScrolling) {
			return <div>is scrolling</div>;
		}

		const items = [...Array(1000).keys()].map(i => ({
			name: `Page ${i}`,
			uv: getRandom(0, 4000),
			pv: getRandom(0, 4000),
			amt: getRandom(0, 4000)
		})).slice(0, 50);

		return (
			<AreaChart
				width={500}
				height={50}
				data={items}
				margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
			>
				<Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
			</AreaChart>
		);
	}; */

	let columns = (numberCols) => {
		let columnsOut = [];

		[...Array(numberCols).keys()].map(i => {
			const column = {
				key: `col${i}`,
				name: `col${i}`
			};
			/* if (i === 3) {
				column.formatter = ExpensiveFormatter;
				column.width = 500;
			} */
			columnsOut.push(column);
		});

		return columnsOut;
	}

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	

	let addGrid = () => {
		
		if(items.length === 0)	return;

		return <DataGrid
			headerRenderer={stickyHeader}
			/* className='rdg-light' */
			/* columns={columns(Object.keys(items[0]).length)} */
			columns={Object.keys(items[0]).map(k => (
				Object.assign({
					key: k,
					name: k
				}, defaultColumnProperties)
			))}
			rows={items}
		/>
	}

	let modal = (refresh) => {
		props.modal({
			show: refresh? false : true,
			title: <span>Export Report <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6></span>,
			html: (popup) => {

				return reqDone !== true? <Loading loading={true} centered={true}/> : <div>
					<div>
						<div className="container">
							{ addGrid() }
						</div>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}
			, ok: {
				title: "Download",
				fnc: (popup, close) => {
					props.loading(true)
					dlFile()
					// resetPage()
					// popup.modal('hide')
				}
			}, return: {
				title: "Close",
				fnc: (popup, close) => {
					resetPage()
					close()
				}
			}
		});
	}

	/* let fetchPriceRules = (callback) => {
		ajaxPerso( {
			"api": "shopify",
			"trigger": "getPriceRules",
			"shop": props.store
		}, callback);
	} */

	/* let getEbayitems = () => {
		fetchPriceRules((r) => {
			if(!specialVar._isMounted)	return false
			if(r.success){
				console.info('Fetched prices rules', r);
				
				setPricesRules(r.data)
			}else{
				props.info({"error": r.error})
			}
		})
	} */

	/* let fetchItemsPerPromo = (callback) => {
		ajaxPerso( {
			"api": "shipstation",
			"trigger": "getSaledForPromoCode",
			"code": selected
		}, callback);
	} */

	let dlFile = () => {
		
		props.loading(false)
		
		var book  = utils.book_new(),
			sheet = utils.json_to_sheet(items)

		utils.book_append_sheet(book, sheet, `Data`);

		writeFile(book, `Export_Tool-ehub_${moment().local().format('YYYY-MM-DD hh:mm:ss')}.xlsx`)
	}
	
	return (<div className={"className" in props? props.className : 'list-group-item list-group-item-action p-2 text-center'} onClick={() => init(false)}>{
		props.name
	}</div>);
}